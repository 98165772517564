<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>系统管理</el-breadcrumb-item>
              <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <!-- 在线人员 -->
        <div class="staffHeader">
          <!-- <h3 class="staffDetailTitle"><i></i><span>人员详情</span></h3>
          <div class="staffheadercx">
            <div class="staffCx">
              <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="" prop="">
                  <el-input v-model="ruleForm.staffName" placeholder="请输入登陆名称"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" class="newQuery" size="small" @click="deptUserList(1)">查询</el-button>
                  <el-button type="info" size="small" @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div> -->
          
          <div class="staffTable">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%">
              <el-table-column
                prop=""
                label="会话编号"
                width="400">
                <template slot-scope="scope">
                  <div class="tokenCode" >
                    <div v-for="(tokenId,i) in scope.row.tokenId">
                      {{tokenId}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="userName"
                label="登录名称"
                width="">
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="昵称"
                width="">
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-popconfirm
                    title="您是否确认停用，停用后不可恢复？"
                    @confirm="deleteStaff(scope.row)">
                    <template #reference>
                      <el-button class="newBtn_delRed" plain size="mini">强制停用</el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <div class="footer_paging" style="clear:both!important;">
          <div class="footer_totalNum" style="clear:both!important;">
            <div class="footerPaging" style="clear:both!important;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
              </el-pagination>
            </div>
          </div>
        </div> -->
       </template>   
    </PocCardTable>
  </div>
</template>

<script>
import { $http } from '@/api/http';
import Api from "@/api";
import config from '@/api/config';
export default {
  name: 'staffList',
  data() {
    return {
      qdoptions:[],
      ruleForm:{
        staffName:'',//登陆名称
      },
      tableData: [],//人员列表
      // 分页
      pageNum: 1,//当前页
      pageSize: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      userId:'',
      userName:'',

    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1  
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    // this.getChannelList()
    this.deptUserList() //人员列表展示
  },
  methods: {
    //获取渠道列表
    // getChannelList(){
    //   let that = this
    //   $http.axios.get("/api/system/user/getChannelList").then(function(response) {
    //     if(response.data.code == 200){
    //       that.qdoptions = response.data.data
    //       that.deptUserList();//  部门人员列表展示
    //     } else {
    //       that.$message({
    //         showClose: true,
    //         message: response.data.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },

    //重置
    // resetForm(ruleForm) {
    //   this.$refs[ruleForm].resetFields();
    //   this.$router.go(0)
    // },

    //人员列表
    deptUserList() {
      let that = this
      $http.axios.get("/api/operationManagement/index/onlineUsers").then(function(response) {
        if(response.data.code == 200){
          that.tableData = response.data.data
        }else{
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //强制停用
    deleteStaff(row){
      let that = this,params = {}
      params = {
        'tokenIds':String(row.tokenId)
      }
      $http.axios.post("/api/operationManagement/index/forceLogout",params).then(function(response) {
        if(response.data.code == 200){
          that.deptUserList()
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
        }else{
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.deptUserList()
    },

  }
}
</script>
<style lang="scss" scoped>
  .staffheadercx{
    margin-bottom:20px;
  }
  .staffCx{
    width:80%;
  }
  .staffCx .el-form-item{
    display:inline-block;
    vertical-align:middle;
    width:18%;
    margin:0 1%;
  }
  .staffCx .el-form-item__content{
    margin:0!important;
  }
  .staffTable .el-table .cell{
    text-align:center;
  }
  .staffTable{
    margin-top:20px;
    max-height:600px;
    overflow: scroll;
  }
  .staffDetailTitle{
    border-bottom:1px solid #F1F7F5;
    height:50px;
    line-height:50px;
    margin:0;
    margin-bottom:20px;
  }
  .staffDetailTitle span{
    font-size:14px;
    color:#333;
    font-weight:500;
  }
  .staffDetailTitle i {
    display:inline-block;
    vertical-align:middle;
    height:18px;
    width:3px;
    background:#48916F;
    margin-right:10px;
  }

</style>

