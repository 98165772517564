<template>
  <div class="hl_breadCrumb plan-tabs" style="margin-bottom:20px;margin-top:10px;">
    <breakTitle :titleData="breakTitle"></breakTitle>
    <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane 
            v-for="(item,index) in tabsList" 
            :key="index"
            :label="item.label" 
            :name="item.name"
            :ref="item.name">
            <component :is='item.content' :newVue = "newVue"></component>
          </el-tab-pane>
        </el-tabs>
    </template>
  </div>
</template>
<script>
import Api from "@/api";
import config from '@/api/config';
import breakTitle from "./breakHeader";//面包屑导航
import adPlanApple from "./adPlanApple";//广告系列
import adTeamApple from "./adTeamApple";//广告组
// import advert from "./advert"//广告
export default {
  name: "planTabs",
  data() {
    return {
      newVue:this,
      activeName: 'adPlanApple',//记得修改成adPlan
      breakTitle:['计划管理','账户选择','广告系列'],
      tabIndex:null,
      tabsList:[{label:"广告系列",name:"adPlanApple",objIdType:10,content:adPlanApple},
        {label:"广告组",name:"adTeamApple",objIdType:20,content:adTeamApple},
        // {label:"广告",name:"advert",objIdType:30,content:advert}
      ],//tabs动态加载的组件内容


    }
  },
  components:{
    breakTitle,//面包屑导航
    adPlanApple,//广告系列
    adTeamApple,//广告组
    // advert//广告
  },
  mounted(){
    this.tabIndex = Number(this.$route.query.tabIndex)
    if(this.tabIndex == 1){
      this.activeName = 'adTeamApple'
      this.$refs.adTeamApple[0].$children[0].grouplistFn()
      this.$refs.adTeamApple[0].$children[0].$refs.zdy.allWords(20)
    }else{
      this.$refs.adPlanApple[0].$children[0].getAccountPlanListFn()
      this.$refs.adPlanApple[0].$children[0].$refs.zdy.allWords(10)
    }


    console.log(this)
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index)
      if(tab.index == '0'){
        this.$refs.adPlanApple[0].$children[0].getAccountPlanListFn()
        this.$refs.adPlanApple[0].$children[0].$refs.zdy.allWords(10)
        console.log(this)
      }else if(tab.index == '1'){
        if(typeof event == 'number'){
          this.$refs.adTeamApple[0].$children[0].planIds = event
        }
        this.activeName = 'adTeamApple'
        this.$refs.adTeamApple[0].$children[0].grouplistFn()
        this.$refs.adTeamApple[0].$children[0].$refs.zdy.allWords(20)
      }
      // else{
      //   if(typeof event == 'number'){
      //   this.$refs.advert[0].$children[0].adGroupIds = event
      //   }
      //   this.activeName = 'advert'
      //   this.$refs.advert[0].$children[0].v3groupListFn()
      //   this.$refs.advert[0].$children[0].$refs.zdy.allWords(30)
      // }
    },
  }
}
</script>
<style lang="scss" scoped>
    .el-tabs__item.is-active {color: #6e7c7c;
      font-size: 16px;
      font-weight: 700;}
      .el-tabs__header{
        margin:15px 0 0;
      }
</style>