<template>
    <div class="authority common-page newcommon-page">
      <!-- <planHeader :breakTitle="breakTitle"></planHeader> -->
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-input v-model = "adName" clearable placeholder="请输入广告名称查找"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "adId" 
                    clearable           
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入广告ID查找"></el-input>
                  </el-form-item>

                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选广告状态" v-model="channel">
                      <el-option
                        v-for="(item,i) in qdoptions.auditStatusSet"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  
                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选择推广目标" v-model="channelm">
                      <el-option
                        v-for="(item,i) in qdoptions.extensionTypesList"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选择推广场景" v-model="channeln">
                      <el-option
                        v-for="(item,i) in qdoptions.flowScenesList"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选择推广流量" v-model="channelo">
                      <el-option
                        v-for="(item,i) in qdoptions.extensionFlowsList"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>                  
                  <el-form-item label="">
                    <div class="block">
                      <el-date-picker
                        v-model="value1"
                        type="daterange"
                        style="width:240px;"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "v3groupListFn(page,adName,adId,channel,channelm,channeln,channelo,value1)" >查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
            </div>
            <div class="clearfix">
              <div class="fL MBOTTOM">
                <el-button type="primary" size="small" @click="creatPlan('CreatPlan','30')">新建广告</el-button>
                <!-- <el-button type="primary" size="small">新建广告</el-button> -->
                <!-- <el-button type="primary" size="small">修改状态</el-button> -->
                <el-dropdown trigger="click" @command = "changeStatusList">
                  <el-button type="primary" size="small" @click="updateOppoSet('planStatus')">
                    修改状态
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <el-button :disabled="copyBtnDis" type="primary" size="small" @click="updateOppoSet('copy')">批量复制</el-button> -->
              </div>
              <div class="fR MBOTTOM">
                <!-- <el-button type="danger" size="small">更多筛选</el-button> -->
                <!-- <el-button type="danger" size="small">数据筛选</el-button> -->
                <el-button type="success" size="small" @click = "zdyFn">自定义列</el-button>
                <el-button type="warning" size="small" @click = "exportAdvert">下载报表</el-button>
              </div>
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  @cell-click="clickcell"
                  @selection-change="handleSelectionChange">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <template v-for="(item, index) in titleList">
                      <el-table-column
                      :key="index"
                      :prop="item.prop"
                      :label="item.name"
                      :align="item.align || 'center'"
                      :show-overflow-tooltip="item.overHidden || true">
                        <template slot-scope="scope">
                          <!-- // 这里通过插槽实现自定义列 -->
                          <slot
                            v-if="item.slot"
                            :name="scope.column.property"
                            :row="scope.row"
                            :$index="scope.$index"/>
  
                          <div v-else                                   
                          :disabled = "scope.row.disabledIs"
                          >
                        <span v-if='scope.column.property == "status2" || scope.column.property == "priceSwitch" || scope.column.property =="startSwitch" || scope.column.property == "warningSwitch"'>
                              {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                          <i v-if = 'scope.column.property =="startSwitch" ||scope.column.property == "priceSwitch"'
                            :class = "{'isSwitch':scope.column.property =='startSwitch'||scope.column.property =='priceSwitch','active':(scope.column.property =='startSwitch'||scope.column.property =='priceSwitch')&&scope.row[scope.column.property]==0}"></i>
                            </span>
                            <span v-else>{{ scope.row[scope.column.property] }}</span>
                          </div> 
                          <template v-if='item.prop=="status2"'>
                            <el-switch
                              v-model="scope.row.status2"
                              class="switchStyle"
                              :active-value="0"  
                              :inactive-value="1"
                              active-color="#48916F"
                              inactive-color="#EAEFED"
                              @change = "changeStatus(scope.row.status2,scope)"
                              >
                            </el-switch>
                          </template>
                        </template>
                      </el-table-column>
                    </template>
                  <el-table-column 
                    fixed = 'right'
                    align="center"
                    width='220'
                    :show-overflow-tooltip="true"
                    label="操作">
                    <template slot-scope="scope">
                      <el-button class="solid-tag-success" data-type = 'edit' plain size="mini">编辑</el-button> 
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row.adId)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="clearfix">
              <!-- <div class="allOperation fL">
                <div class="totalNum fL">共<i>50</i>条</div>
                <el-select v-model="value" placeholder="批量操作">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div> -->
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleSizeChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
              <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="30%">
              <span class="el-icon-loading uploading" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <creatCopyDialog :visible.sync="copyShowDialog" :tableDataList = 'tableDataList' :params = "params" :copyCreatList = "copyCreatList" :pubVue = "pubVue"></creatCopyDialog>
      <earlyWarningDialog :visible.sync="showDialog"></earlyWarningDialog>
      <zdy :visible.sync="showzdyDialog" ref = 'zdy' :pubVue="pubVue" :typeName = 30></zdy>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import earlyWarningDialog from '@/views/dialog/earlyWarningDialog'
  import zdy from './zdy'
  import creatCopyDialog from "@/views/dialog/creatCopyDialog";//批量设置弹框
  export default {
    components: {
      earlyWarningDialog,
      zdy,
      creatCopyDialog
    },
    props:{
      newVue:Object,
    },
    data() {
      return {
        showDialog: false,
        showzdyDialog:false,
        copyShowDialog:false,

        pubVue: this,//空vue，用于子组件之间传值
        exprtVisible:false,//
        uploadingShow:false,//确认导出弹框

        warningOpenList:[],//['开启','关闭']
        qdoptions:{auditStatusSet:[{text:'审核中',id:'0'},{text:'审核拒绝',id:'2'}],
                  extensionTypesList:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],
                  flowScenesList:[{text:'全流量场景',id:'1'},{text:'精选推广',id:'2'},{text:'安装有礼',id:'3'},{text:'开机必备',id:'4'},{text:'搜索',id:'5'},{text:'信息流',id:'6'},{text:'Banner',id:'7'},{text:'开屏',id:'8'},{text:'插屏',id:'9'},{text:'原生',id:'10'},{text:'激励视频',id:'11'},{text:'投类型',id:'12'}],
                  extensionFlowsList:[{text:'全流量',id:'1'},{text:'自有流量',id:'2'},{text:'联盟流量',id:'3'}]},//选择状态
        titleList:[{name:'广告名称',prop:'adName'},
        {name:'投放开关',prop:'status2'},
        {name:'广告ID',prop:'adId'},
        {name:'广告组状态',prop:'showStatusName'},
        {name:'预警状态',prop:'warningSwitch'}],//title名称
        showStatusList:{status2:[''],
                      priceSwitch:['是','否'],
                      startSwitch:['是','否'],
                      warningSwitch:['开启','关闭','未设置']},

        adName:'',//请输入计划名称查找
        adId:'',//请输入计划ID查找
        channel:'',//请选择状态
        channelm:'',//推广流量
        channelo:'',//推广目标
        channeln:'',//推广场景
        value1:[],
        params:{},
       tableData:[],
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dataList:'',
        groupsIds:[],
        copyBtnDis:false,//批量复制按钮是否可点击
        copyCreatList:[],
        tableDataList:[],
        myHeaders: {Authorization:''},
        fileList:{},
        adGroupIds:null,
      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.params.userId  = this.userId
      this.params.userName  = this.userName
      this.params.deptId = this.deptId
      this.params.ownerId = Number(this.$route.query.id)
      this.params.ownerName =this.$route.query.name
      this.getId()
      this.fileList={
        "ownerId":this.params.ownerId,
        "operaType":30,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
    },
    methods: {
      //弱提示
      alertMsgFn(msg,type){
        let _this = this
        _this.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
      getId(){
        this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
        this.uploadId = this.$route.params && this.$route.params.id
        console.log(this.uploadId)
      },
      zdyFn(){
        this.$refs.zdy.generateTimes(30)
      },
      exportAdvert(){
        this.exprtVisible = true
      },
      //文件上传成功时的钩子
      onSuccess(response, file, fileList, i) {
        let that = this,dataSource =[],keywords = []
        // let video = []
        console.log(response)
        if(response.code==200){
          that.v3groupListFn()
        
        }else{
          that.$message({
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }
        this.$refs.upload.clearFiles()
      },
      //下载报表
      downLoadTable(){
        let _this = this,params ={},date = new Date().getTime(),myDate = new Date()
        let Time2 = myDate.toISOString().slice(0,10)//推荐 yyyy-MM-dd
        //延伸  取yyyyMMdd
        let Time3 = Time2.replace(/[^0-9]/ig,'').substring(0,8)
        params = {'ownerId':_this.params.ownerId,
                  'deptId':_this.deptId,
                  'userId':_this.userId,
                  'userName':_this.userName,
                  'beginTime':_this.value1[0]||Time3,
                  'endTime':_this.value1[1]||Time3},
            
        _this.uploadingShow = true
        $http.axios.post("/api/advertisement/ad/v1/v3export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
          _this.exprtVisible = false 
          //添加{responseType: 'blob'}  导出文件以下代码都是
          let blob = response.data
          let reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = e => {
            let a = document.createElement('a')
            a.download = "广告报表"+myDate+".xls"
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            _this.uploadingShow = false
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
      },
      creatPlan(url,type){
        // this.$router.push("/plan-management/"+routerTo)
        this.$router.push({
          path: url,
          query: {
            ownerId: this.params.ownerId,
            ownerName:this.params.ownerName,
            type:type
          }
        })
      },
      // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
      clickcell(row, column, cell, event){
      console.log(row);
      console.log(column);
      console.log(cell);
      console.log(event);
      let yjType = event.target.parentNode.dataset.type
      if(column.property=='rebateBal'||column.property=='cashCost'||column.property=='cashCost'){
        console.log(666)
      }
      if(column.type=="selection"){
          this.$refs.multipleTable.toggleRowSelection(row)
      }
      // if(yjType == 'qtBtn'){
      //   this.goPlan('/plan-management/SetPricess',row.planId,row.planName)
      // }else 
      if(yjType == 'delBtn'){
        // "accountId": 0,
        // "deptId": 0,
        // "planId": 0,
        // "userId": 0,
        // "userName": ""


        // let text = "您是否确认删除，删除后不可恢复？"
        // this.$confirm(text,"提示",{
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        // }).then(()=>{
        //   //确认，将改变后的状态值赋给绑定的itemValue
        // this.deletePlanFn(row.adGroupId)
        // }).catch(()=>{
        //   //取消，将对立状态值赋给itemValue
          
        // })

        // this.deletePlanFn(row.adGroupId)

      }else if(yjType == 'edit'){
        this.$router.push({
          path: 'editAdCreat',
          query: {
            ownerId: this.params.ownerId,
            ownerName:this.params.ownerName,
            "planId":row.planId ,//计划ID
            "planName":row.planName,//计划名称
            "adGroupId":row.adGroupId,//广告组ID
            "adGroupName":row.adGroupName,//广告组名称
            "extensionType":row.extensionType,//推广目的ID
            "extensionTypeName":row.extensionTypeName,//推广名称
            "flowScene":row.flowScene,//
            "flowSceneName":row.flowSceneName,//
            "extensionFlow":row.extensionFlow,//
            "extensionFlowName":row.extensionFlowName,//
            'type':30
          }
        })
      }
    }, 
    // 分页
    handleSizeChange(val) {
      let _this = this
      console.log(666)
      // _this.$set(_this.params,'page',val)
      _this.findAll(val)
    },
    //分页方法
    findAll (page) {
      //获取数据接口（调用封装的接口）
      this.v3groupListFn(page,this.adName,this.adId,this.channel,this.channelm,this.channeln,this.channelo,this.value1)
    },
    // 批量启停设置
    updateOppoSet(type){
      let _this = this
      let groupsIds = []//声明一个空数组
      //  _this.ownerIdList = [1100018542,1100018078]
        // that.getzdyData 去重
        _this.groupsIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          groupsIds.push(v)
        }
      })
    
      if(_this.groupsIds.length!=0){
        // _this.params.groupsIds = _this.groupsIds
        _this.btnIsFn(_this.params,type)
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
      },
      // 按钮判断
      btnIsFn(params,type){
        let _this = this
        if(type == 'startStop'){
          _this.timeDialog = true
        }else if(type == 'planStatus'&&_this.planStatus){
            _this.warningOpenList = ['开启','关闭']
        }else if(type == 'dayLimt'){

        }else if(type == 'copy'&&_this.planStatus){
          _this.copyShowDialog = true
          _this.newVue.$refs.adTeam[0].$children[0].grouplistFn(1,100)
          setTimeout(function(){
            _this.tableDataList = _this.newVue.$refs.adTeam[0].$children[0].tableData
          },3000)

        }

      },  
      //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
      warmIs(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
        // /adPlan/v1/checkPlanDailyMoneySet
        let _this = this
        let isTrue = _this.updateOppoSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        if(isTrue){
            _this.yjCheck(type,url)     
        }
      },
      yjCheck(type,url,adPlanId,dayBudget){
        let _this = this,messageContent,params = []
        if(type == 'warningOpen'){
          params = [_this.params.ownerId]
        }else if(type == 'warningPrice'){
          _this.groupsIds.forEach(obj=>{
            params.push(obj.adGroupId)
          })            
          }else{
          _this.groupsIds.forEach(obj=>{
            params.push(obj)
          })
        }

        $http.axios.post("/api/advertisement"+url,params).then(res=> {
          let content = []
          if(res.data.code==200){
            content = res.data.content
            if(content == null){
              if(type == 'dayLimit'){
                this.showSingleDialog = true
              }else{
                  _this.setCjWarningDialogShow = true//记得删掉
                  _this.$refs.setCj.getAdGroupWarningDetailFn()//记得删掉
                messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
              
                _this.$message({
                  showClose: true,
                  message: messageContent,
                  type: 'error'
                })
              }
                
              }else{
                if(type == 'dayLimit'){
                //   let msgContent =  content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
                //   _this.$message({
                //   showClose: true,
                //   message: msgContent,
                //   type: 'error'
                // })

                  let text = content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
                  this.$confirm(text,"提示",{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                  }).then(()=>{
                    this.showSingleDialog = true
                  }).catch(()=>{
                    
                  })
                }else if(type == 'warningPrice'){
                  // _this.warningOpenList = ['开启','关闭']
                  _this.setCjWarningDialogShow = true
                  _this.$refs.setCj.getAdGroupWarningDetailFn()
                }else if(type == 'yjBtn'){
                  _this.showDialogIs = true
                  _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.params.ownerId,type)
                }
              }
            }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
            }
          }).catch(error=>{
            let messageContent = error.message || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })        
          }) 
        },      
      changeStatusList(command){
        console.log(command)
        let _this = this,adGroupName = [],text = ''
        if(command == 1){
          _this.yulanList.forEach((obj,i)=>{
            if(_this.startSwitch[i]==0){
              adGroupName.push(obj.adGroupName)
            }
          })
          if(adGroupName.length>0){
            text = adGroupName.join('、')+"广告组，有正在执行的启停计划，确认暂停"+adGroupName.join('、')+"广告组后启停计划也将关闭"
            this.$confirm(text,"提示",{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(()=>{
              this.updateOppoAdStatusFn(command)
            }).catch(()=>{
              //取消，将对立状态值赋给itemValue
              _this.warningOpenList = []
            })         
          }else{
            this.updateOppoAdStatusFn(command)
          }
        }else{
          this.updateOppoAdStatusFn(command)
        }

        
      }, 
     
      //选择行
      handleSelectionChange(val){
        let _this = this
        let groupsIds = [],yulanList = [],objList = [],startSwitch = [],senceList = []
        // _this.limitIs = false
        console.log(val)
        _this.copyCreatList = []

        val.forEach((obj,i,arr)=>{
          let cur = obj.status2
          groupsIds.push(obj.adId)
          yulanList.push({ 'adGroupName':obj.adGroupName,'adGroupId':obj.adGroupId,'planName':obj.planName,'planId':obj.planId,'price':obj.price,'planStatus':obj.status2})
          senceList.push({'extensionType':obj.extensionType,'flowScene':obj.flowScene})
          // if(obj.dayBudget == '不限'){
          //   _this.limitIs = true
          // }
          _this.copyCreatList.push({
            // 'extensionTypeName':obj.extensionTypeName,
          // 'extensionFlowName':obj.extensionFlowName,
          // 'flowSceneName':obj.flowSceneName,
          'adId':obj.adId,
          'adName':obj.adName})
          objList[cur] = cur
          // if(objList.length==0){
          //   objList[i] = cur
          // }else{
            // objList[cur] = cur
          // }
        })
        objList.forEach((obj,i)=>{
            if(objList.length == 2&&objList[0]!=undefined){
            _this.planStatus = false
            _this.warningOpenList = []
            _this.$message({
                showClose: true,
                message: "选择的计划状态不一致，选择同一种计划状态后才可操作",
                type: 'error'
              })        
            }else{
            _this.planStatus = true
          }
        })
          _this.groupsIds = groupsIds
          _this.startSwitch = startSwitch
          _this.yulanList = yulanList
          if(val.length == 0){
            _this.warningOpenList = []
            _this.planStatus = false

          }
        
        
      _this.copyIsTrueFn(senceList)
    },    
    //批量复制判断
    copyIsTrueFn(copyList){
      let _this = this
      copyList.forEach((v,i,arr)=>{
        let extensionType = arr[i+1]?arr[i+1].extensionType : v.extensionType
        let flowScene = arr[i+1]?arr[i+1].flowScene : v.flowScene
        if(v.extensionType !=extensionType || v.flowScene != flowScene){
          _this.copyBtnDis = true
            // return
            // break // 语法报错
            throw new error // 主动去抛出一个错误
        }else{
          _this.copyBtnDis = false 
        }
      })
    },
    //重置
    resetForm(){
        let _this = this
        _this.clearFn()
        _this.v3groupListFn()
       
      },
      clearFn(){
        let _this = this
        _this.adId = ''
        _this.adName = ''
        _this.channel = ''
        _this.channelm = ''
        _this.channeln = ''
        _this.channelo = ''
        _this.value1 = ''
      },
    //按钮开关
    getBrandList(){},
    // 自适应表格列宽
    flexColumnWidth(str, tableData, flag = 'max') {
      // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
      // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
      // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
      let columnContent = ''
      if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
        return
      }
      if (!str || !str.length || str.length === 0 || str === undefined) {
        return
      }
      if (flag === 'equal') {
        // 获取该列中第一个不为空的数据(内容)
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str].length > 0) {
            columnContent = tableData[i][str]
            break
          }
        }
      } else {
        // 获取该列中最长的数据(内容)
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str] === null) {
            return
          }
          const now_temp = tableData[i][str]
          const max_temp = tableData[index][str]
          if (now_temp.length >= max_temp.length) {
            index = i
          }
        }
        columnContent = tableData[index][str].toString()
      }
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 0
      for (const char of columnContent) {
        if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 8
        } else if (char >= '\u4e00' && char <= '\u9fa5') {
          // 如果是中文字符，为字符分配18个单位宽度
          flexWidth += 18
        } else {
          // 其他种类字符，为字符分配15个单位宽度
          flexWidth += 15
        }

      }
      if (flexWidth < 120) {
        // 设置最小宽度
        flexWidth = 120
      }
      return flexWidth
    },

    // "adIds": "",
    // "adNameLike": "",
    // "auditStatusSet": "",
    // "beginTime": "",
    // "deptId": 0,
    // "endTime": "",
    // "extensionTypes": "",
    // "globalSpecIds": "",
    // "ownerId": 0,
    // "page": 0,
    // "pageCount": 0,
    // "showStatusSet": "",
    // "userId": 0,
    // "userName": ""

    // 广告列表
    v3groupListFn(page,adName,adId,channel,channelm,channeln,channelo,value1){
      let _this = this,params,pagetTxt,price,ocpc,deepOcpc
      pagetTxt = {'pageCount' : _this.pageCount,
              'page' : page || 1,
              'adNameLike':adName,
              'adIds':adId,
              'extensionFlows':channelo,//流量
              'extensionTypes':channelm,//目标
              'auditStatusSet':channel,//状态
              'flowScenes':channeln,//场景
              'adGroupIds':_this.adGroupIds
            }
      if(value1 != undefined&&value1 != ''){
        pagetTxt.beginTime = value1[0]
        pagetTxt.endTime = value1[1]
      }
      
      params = Object.assign({},_this.clone(_this.params), pagetTxt)  
      $http.axios.post("/api/advertisement/ad/v1/v3groupList",params).then(res=> {
        if(res.data.code==200){
          if(res.data.content.items != null){
            _this.tableData = res.data.content.items
            _this.tableData.forEach((obj,i)=>{
              if(obj.warningSwitch == null){
                _this.tableData[i].warningSwitch = 2
              }
            })
          }
          _this.totalRow = res.data.content.totalCount
          _this.clearFn()
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
    
    //修改状态
    // /ad/v1/updateOppoAdStatus
    updateOppoAdStatusFn(command,groupsIds){
      // "deptId": 0,
      // "groupsIds": [],
      // "ownerId": 0,
      // "status2": "",
      // "userId": 0,
      // "userName": ""
      let _this = this,type,params,params1
      groupsIds = groupsIds||_this.groupsIds
      params1={'adIds':groupsIds,
        'status2' : command.toString(),}
      params = Object.assign({},_this.clone(_this.params), params1)
      // $http.axios.post("/api/advertisement/ad/v1/updateOppoAdStatus",params).then(res=> {//测试
      // $http.axios.post("/api/advertisement/ad/v1/updateOppoAdStatus",params).then(res=> {//测试

      $http.axios.post("/api/advertisement/ad/v1/updateOppoAdStatus",params).then(res=> {//本地
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
          type = "success"
          _this.v3groupListFn()
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // 删除
    deletePlanFn(planId){
      // "adIds": [],
      // "deptId": 0,
      // "ownerId": 0,
      // "userId": 0,
      // "userName": ""        
      let _this = this,params = {},type = ''
      params ={"delList":[planId],
              "deptId":_this.params.deptId,
              "ownerId":_this.params.ownerId,
              "userId": _this.params.userId,
              "userName": _this.params.userName} 
      $http.axios.post("/api/advertisement/ad/v1/batchAd",params).then(res=> {
      // $http.axios.post("/api/advertisement/ad/v1/batchAd",params).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.v3groupListFn(this.page)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
  }
  }
  </script>
  
  <style lang="scss" scoped>
    
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */

     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
        margin-right: 15px;
        font-size: 13px;
        color: #606266;
      }
      .allOperation .totalNum i{
          font-style: initial;
        }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */

    /* 操作按钮样式开始 */
    /* .planeBtn,.offBtn,.delBtn{
      display: inline-block;
      margin: 0 10px;
    } */
    /* 操作按钮样式结束 */
    .MBOTTOM{
      margin-bottom:20px;
    }
    .MBOTTOM button{margin-right: 10px;}
    #uploadBtn{
      float: right;
    }
    .el-button.el-button--primary{
      margin: 0 10px 0 0!important;
    }
    /* 新版 */
  .newcommon-page{
    padding:0!important;
  }
  .newcommon-page .el-card.is-always-shadow{
    box-shadow: none!important;
    border:none!important;
  }
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  </style>
