<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    title="确认添加新计划"
  >
    <div class="demo-image clearfix">
      <div class="block" v-for="(fit,i) in fits" :key="i" @click="chooseThisFn(i,fit)" :class="i == index? 'active': ''">
        <el-image
          style="width: 100px; height: 100px"
          :src="fit.imgUrl"
          :fit="fit.id"></el-image>
        <span class="demonstration">{{ fit.mtName }}</span>

      </div>
    </div>
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm">{{$t('dc:确认')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
    <!-- <dayLimitDialog :visible.sync="showDialog" :params = 'params'></dayLimitDialog> -->

  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';


export default {
  name: "accountDialog",

  props: {
    childrenVue: {
      type: Object
    },
    type:String,
    visible: {
      type: Boolean,
      default: false
    },
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number,
    params:Object,
    
    msgContent:String

  },

  data() {
    return {
      // optionsm:{channelList:[{text:'oppo',id:'0'},{text:'vivo',id:'2'},{text:'应用宝',id:'3'},{text:'华为',id:'4'},{text:'小米',id:'5'},{text:'今日头条',id:'6'}]},//选择状态
      optionsm:{},//部门名称
      childrenObject:{},
      loading: false,
      showDialog:false,
      show: this.visible,
      currentTypeConfig: {},
      formData: {
        name: "CHen",
        email: "asdasd",
        brand: "",
        password: ""
      },
      channel:'',
      value:'0',
      rules: {},
      fits:[],
      index:null,
      firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',
      ownerId:null,
      globalSpecId:null,
      deptId:null,
      materialId:null,
      num:null,//第几个创意
      loadingIs:false,//素材是否渲染完成
      typeInfo:null,//规格是视频还是图片
      sc:{},
      //   productname: [{ required: true, message: this.$t("dc:请输入") }],
      //   accountName: [{ validator: accountNameRoute, trigger: ["blur", "change"] }],
      //   module: [{ required: true, message: this.$t("dc:请输入") }],
      //   accountCode: [ { validator: accountCodeRoute, trigger: ["blur", "change"] }]
      // }
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted(){
    console.log('imgDialog')
    this.ownerId = Number(this.$route.query.ownerId)
  },
  methods: {
    onConfirm() {
      this.showDialog = true
      this.show = false
      console.log(this.showDialog)
      this.childrenVue.openFullScreen2()
      this.getMidFn(this.globalSpecId,this.materialId)

    },
    onCancel() {
      this.show= false;
    },
    chooseThisFn(i,e){
      let _this = this
      _this.index = i;
      _this.globalSpecId = e.materialType,
      _this.materialId = e.id,
      _this.typeInfo = e.type
      // imgMats
      // _this.childrenVue.sc ={'imgUrl':e.imgUrl,'videoUrl':e.videoUrl?e.videoUrl+_this.firstWord:e.imgUrl,'logoUrl':e.logoUrl,'id':e.id,'imgList':e.url.imgList} 
      _this.sc ={'imgUrl':e.imgUrl,'videoUrl':e.videoUrl?e.videoUrl+_this.firstWord:e.imgUrl,'logoUrl':e.logoUrl,'id':e.id,'imgList':e.url.imgList} 
      _this.sc['imgMats'] = [{'materialUrl': e.imgUrl}]
      // _this.childrenVue.addScInfo[_this.globalSpecId][_this.num] =Object.assign({},_this.clone(_this.childrenObject), _this.childrenVue.sc)
      // _this.childrenVue.addScInfo[_this.globalSpecId].push(this.childrenVue.sc)

    },

     // {
      //   "channlId": 1,//渠道id
          // "copyWriting": "",//素材描述
          // "costStatus": 0,//消耗状态
          // "createUserName": "",//创建人id
          // "demandName": "",//工单名称
          // "demandNum": "",//主工单编号
          // "endTime": "",
          // "makerGroupId": 0,
          // "materialTag": "",
          // "materialType": 0,//素材类型 1图文2视频
          // "mtChannel": 0,
          // "mtName": "",//素材名称，弹框搜索素材用
          // "page": "",
          // "pageCount": "",
          // "specId": 0,//oppo规格id 必传
          // "startTime": "",
          // "status": 0,
          // "userId": 0,// 必传    
      // }
      listFn(specId,userId,deptId,num){
        // /material/list
        let _this = this, params = {}
        _this.num = num
        params = {
          "channlId": 1,//渠道id->现在需要，后期不需要等楚莨消息
          "specId":specId,//_this.specId,//oppo规格id 必传
          "userId": userId,// 必传   
          "deptId":deptId,
          "status":2,   
        }
        _this.globalSpecId = specId
        $http.axios.post("/api/operationManagement/material/list",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          if(res.data.data.items != null){
            _this.fits = res.data.data.items
            // _this.childrenVue.addScInfo[specId].push(_this.fits)
            _this.fits.forEach(obj=>{
              if(obj.type == 2){
                obj.imgUrl = obj.url.imgUrl
              }else if(obj.type == 1){
                obj.imgUrl=obj.url.imgList[0]
              }
              obj.logoUrl = obj.url.logoUrl
              obj.videoBgUrl = obj.url.videoBgUrl
              obj.videoUrl = obj.url.videoUrl
            })
            
            console.log(_this.fits)
          }
          
        }else{
          _this.childrenVue.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.childrenVue.alertMsgFn(messageContent,"error")
      })
      },
      getMidFn(globalSpecId,materialId){
        let _this = this, params = {},data = {},childrenObject = {},addScInfoChildren = {}
        params = {
          "globalSpecId":globalSpecId,//渠道id
          "materialId":materialId,//_this.specId,//oppo规格id 必传
          "ownerId": 1000092281//_this.ownerId,// 必传   
          }
        $http.axios.post("/api/operationManagement/material/getMid",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code=='200'){
         
            _this.childrenVue.videoHIs = true


          if(res.data.data!= null){
            let iNum =_this.num
            _this.sc['imgMats'][0]['mid'] =  res.data.data.imgs
            if(_this.typeInfo == 1){
              data = {'brandLogoImgId':Number(res.data.data.brandLogo),'imgMatIds':res.data.data.imgs,'status':0,'dynamicCr':0}
            }else if(_this.typeInfo == 2){
              data = {'brandLogoImgId':Number(res.data.data.brandLogo),'videoImgId':Number(res.data.data.videoAcImg),'videoMatIds':Number(res.data.data.video),'status':0,'dynamicCr':0}
            }
            if(_this.type == 'edit'){
              _this.childrenVue.editListStart[0][globalSpecId][iNum]  =Object.assign({},_this.clone(_this.childrenVue.editListStart[0][globalSpecId][iNum]),_this.sc, data)
              _this.childrenVue.sc  =_this.childrenVue.editListStart[0][globalSpecId][iNum]
              childrenObject = _this.childrenVue.editListStart[0][globalSpecId][iNum]
            }else{
              _this.childrenVue.addScInfo[globalSpecId][iNum]  =Object.assign({},_this.clone(_this.childrenVue.addScInfo[globalSpecId][iNum]),_this.sc, data)
              _this.childrenVue.sc =_this.childrenVue.addScInfo[globalSpecId][iNum]
              childrenObject = _this.childrenVue.addScInfo[globalSpecId][iNum]
            }
            // _this.childrenVue.addMaterialList()//测试
            _this.childrenObject = childrenObject
            console.log(_this.childrenVue.addScInfo)
          }
          
        }else{
          _this.childrenVue.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"success")
        }
         _this.childrenVue.openFullScreen2().close();

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.childrenVue.alertMsgFn(messageContent,"error")
      })      }
  
  }
};
</script>
<style lang="scss" scoped>
.block{
  /* float: left; */
  display:inline-block;
  vertical-align:middle;
  text-align: center;
  margin-right: 20px;
  border:1px solid transparent;
  box-sizing: border-box;
}
.demonstration{
  display: block;
}
.active{
  border:1px solid #2fc29b
}
</style>

