<template>
  <div class="page-response">
    <!-- <img class="page-logo" :src="imgLogin" /> -->
    <div class="page-planet">
      <!-- <Planet type="404" :infos="[`It look's like you're lost...`]"></Planet> -->
      <Planet type="404" :infos="pageConfig.infos"></Planet>
    </div>
    <div class="page-action">
      <!-- <img :src="imgSmallRocket" class="page-action-gif" /> -->
      <div class="page-action-goto">
        <!-- <el-link href="/" type="primary">
          Go to homepage -->
        <el-link :href="pageConfig.link" type="primary">
          {{ pageConfig.linkTxt }}
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>
// import imgLogin from './assets/log.png';
import imgSmallRocket from './assets/小火箭.gif';
import Planet from './Planet';
export default {
  components: { Planet },
  data() {
    return {
      // imgLogin,
      imgSmallRocket,
      pageConfig: {
        infos: [`It look's like you're lost...`],
        link: '/',
        linkTxt: 'Go to homepage'
      }
    };
  },
  created() {
    // 获取STATUS_PAGE状态码页面的配置，如果没有就采用默认配置
    const statusPageConfig = this.$localStorage.getItem('STATUS_PAGE');
    if (statusPageConfig && statusPageConfig['404']) {
      this.pageConfig = statusPageConfig['404'];
    }
  }
};
</script>

<style lang="scss" scoped>
.page-response {
  position: relative;
  background: #000;
  height: 100%;
  .page-logo {
    width: 94px;
    height: 80px;
    position: absolute;
    top: 24px;
    left: 24px;
  }
  .page-planet {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 606px;
    width: 606px;
  }
  .page-action {
    position: absolute;
    bottom: 18%;
    margin: 0 auto;
    color: #fff;
    width: 100%;
    .page-action-gif {
      display: block;
      margin: 0 auto;
      height: 20px;
    }
    .page-action-goto {
      width: 100%;
      text-align: center;
      padding-top: 12px;
      a {
        font-size: 16px;
      }
    }
  }
}
</style>
