<template>
  <div class="authority common-page">
     <PocCardTable>
      <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>计划管理</el-breadcrumb-item>
              <el-breadcrumb-item>账户选择</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" v-if="deptId != 329" @click="toOperateList">操作记录</el-button>
            <el-button type="primary" size="small" v-if="deptId != 329" @click="batchAccount">批量分配</el-button>
          </div>
        </div>
        <div class="chanelTab" style="position: relative;">
          <span class="channel_vivo" :class="item.id==8?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=8&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="" prop="">
                  <el-input v-model="customerName" placeholder="请输入广告主名称或广告主ID"></el-input>
                </el-form-item>
              </el-form> 
              <el-button type="primary" size="small" class="newQuery" @click="appleAccountList(1)">查询</el-button>
              <el-button type="info" size="small" plian @click="reset">重置</el-button>
              <!-- <div style="float:right;"> 
                <el-select placeholder="亚太时区" v-model="showTIme" @change="changeShowTime">
                  <el-option
                    v-for="(item,i) in showTImeOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <div style="display: inline-block;vertical-align: middle;margin-left:15px;">
                  <span v-if="asiaTime">
                    亚太时间：{{formattedDate}}
                  </span>
                  <span v-if="utcTime">
                    UTC时间：{{formattedUTCDate}}
                  </span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="materiaContent">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%;cursor: pointer;">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                prop="ownerName"
                label="广告主名称"
                width="200"
                fixed>
                <template slot-scope="scope">
                  <span v-if="deptId == 329">{{scope.row.ownerName}}</span>
                  <span v-else class="ownerNameColor">{{scope.row.ownerName}}</span>
                  <!-- <span v-else class="ownerNameColor" @click="enterAdvertPlan(scope.row)">{{scope.row.ownerName}}</span> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="ownerId"
                label="广告主ID"
                fixed>
              </el-table-column>
              <el-table-column
                prop="balance"
                label="余额"
                width="120">
                  <template slot-scope="{}" slot="header">
                    <!-- 表头名称 -->
                    <span>余额</span>
                    <el-tooltip class="item" effect="dark" placement="top">
                        <i class="el-icon-warning" style="font-size:16px;padding-left:10px;color:#999;"></i>
                        <!-- 悬停提示内容 -->
                        <div slot="content">
                          余额=昨日余额-今日消耗
                        </div>
                    </el-tooltip>
                </template>
                <!-- 表格内容 -->
                <template slot-scope="obj">
                  余额=昨日余额-今日消耗
                </template>
                <template slot-scope="scope" >
                  <span>¥{{scope.row.balance}}</span>
                  <!-- <el-popover
                    placement="right"
                    width="70"
                    :ref="`popover-${scope.$index}`"
                    trigger="click">
                      <el-input v-model="accountUpdateForm.balance" ></el-input>
                      <div class="inputBtn" style="text-align:right;">
                        <el-button class="el-icon-check" style="margin:0;" @click="handleConfirmSure(scope,1);handleConfirm(scope)"></el-button>
                        <el-button class="el-icon-close" @click="handleConfirm(scope)" style="margin:0;"></el-button>
                      </div>
                      <i class="el-icon-edit" v-if="deptId != 329" slot="reference" @click="editBalance(scope)" style="padding-left:10px;color:#409eff;" ></i>
                  </el-popover> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="lastRecharge"
                label="最近充值"
                width="100">
                <template slot-scope="scope" >
                  <span>¥{{scope.row.lastRecharge}}</span>
                  <!-- <el-popover
                    placement="right"
                    width="70"
                    :ref="`popover-${scope.$index}`"
                    trigger="click">
                      <el-input v-model="accountUpdateForm.recharge" ></el-input>
                      <div class="inputBtn" style="text-align:right;">
                        <el-button class="el-icon-check" style="margin:0;" @click="handleConfirmSure(scope,2);handleConfirm(scope)"></el-button>
                        <el-button class="el-icon-close" @click="handleConfirm(scope)" style="margin:0;"></el-button>
                      </div>
                      <i class="el-icon-edit" v-if="deptId != 329" slot="reference" @click="editrRecharge(scope)" style="padding-left:10px;color:#409eff;" ></i>
                  </el-popover> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="cost"
                width="120"
                label="今日消耗">
                <template slot-scope="scope">
                  ¥{{fun(scope.row.cost)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="yesterdayCost"
                width="120"
                label="昨日消耗">
                <template slot-scope="scope">
                  ¥{{fun(scope.row.yesterdayCost)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="balanceUtc"
                width="120"
                label="UTC余额">
                <template slot-scope="scope">
                  ¥{{fun(scope.row.balanceUtc)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="costUtc"
                width="120"
                label="UTC今日消耗">
                <template slot-scope="scope">
                  ¥{{fun(scope.row.costUtc)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="yesterdayCostUtc"
                width="120"
                label="UTC昨日消耗">
                <template slot-scope="scope">
                  ¥{{fun(scope.row.yesterdayCostUtc)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="预警"
                width="100">
                <template slot-scope="{}" slot="header">
                  <!-- 表头名称 -->
                  <span>预警</span>
                  <el-tooltip class="item" effect="dark" placement="top">
                      <i class="el-icon-warning" style="font-size:16px;padding-left:10px;color:#999;"></i>
                      <!-- 悬停提示内容 -->
                      <div slot="content">
                        影响账户及下属预警执行、今日昨日消耗同步
                      </div>
                  </el-tooltip>
              </template>
              <!-- 表格内容 -->
              <template slot-scope="obj">
                影响账户及下属预警执行、今日昨日消耗同步
              </template>
                <template slot-scope="scope" >
                  <!-- {{scope.row.status}} -->
                  <el-switch
                    v-model="scope.row.status"
                    active-color="#2F674D"
                    inactive-color="#EAEFED"
                    :active-value="0"
                    :inactive-value="1"
                    v-if="deptId == 329"
                    disabled>
                  </el-switch>
                  <el-switch
                    v-model="scope.row.status"
                    active-color="#2F674D"
                    inactive-color="#EAEFED"
                    :active-value="0"
                    :inactive-value="1"
                    v-else
                    @change="changeStatus(scope.row.status,scope)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                prop="payModel"
                label="支付模式">
              </el-table-column>
              <el-table-column
                prop=""
                label="预警详情">
                <template slot-scope="scope">
                  <div v-if="deptId == 329">
                    <el-button class="newBtn_hollowgreen" v-if="scope.row.warnInfos != null">详情>></el-button>
                    <el-button class="newBtn_orang" v-else>未设置</el-button>
                  </div>
                  <div v-else>
                    <el-button class="newBtn_hollowgreen" v-if="scope.row.warnInfos != null" @click="warningDetail(scope.row)">详情>></el-button>
                    <el-button class="newBtn_orang" v-else @click="accountWarn(scope.row)">未设置</el-button>
                  </div>
                  
                </template>
              </el-table-column>

              <el-table-column
                prop="deptName"
                width="120"
                label="部门">
              </el-table-column>
              <el-table-column
                prop="mainUserName"
                width="120"
                label="主优化师">
              </el-table-column>
              <el-table-column
                prop="assistUserNames"
                width="200"
                label="辅优化师">
              </el-table-column>
              <el-table-column
                prop="updater"
                width="120"
                label="更新人">
              </el-table-column>
              <el-table-column
                prop=""
                label="更新时间"
                width="200px">
                <template slot-scope="scope">{{scope.row.updateTime}}</template>
                <!-- <template slot-scope="scope">{{scope.row.updateTime | dateTimeFormat}}</template> -->
              </el-table-column>
              <el-table-column 
                fixed = 'right'
                align="center"
                width='100'
                :show-overflow-tooltip="true"
                label="操作">
                <template slot-scope="scope">
                  <!-- deptId是329的时候不显示广告系列和分配按钮 -->
                  <div v-if="deptId == 329"></div>
                  <div v-else>
                    <el-button class="newBtn_green" plain size="mini" @click="changeAllocation(scope.row)">分配</el-button>
                  </div>
                </template>>
              </el-table-column>
            </el-table>
          </div>
           <!-- 分配和变更账户 -->
           <el-dialog
           title="分配账户"
           :visible.sync="dialogAccountVisible"
           :show-close = "false"
           :close-on-click-modal = "false"
           width="400px"
           height="400px"
           center
           class="distribDialog">
           <div class="dialogIcon"><img :src='distributionIcon'></div>
           <el-form :model="accountForm" ref="accountForm" :rules="rules">
             <el-form-item label="分配渠道" :label-width="distribWidth">
               <el-input v-model="accountForm.qdvalueHx" class="disabledChannel" autocomplete="off" :disabled="true"></el-input>
             </el-form-item>
             <el-form-item label="分配部门" prop="accountDept" :label-width="distribWidth">
               <el-cascader
                 v-model="accountForm.accountDept"
                 :options="options"
                 :props="props"
                 ref="cascaderAddr"
                 :show-all-levels="false"
                 placeholder="请选择分配部门"
                 style="width:300px;"
                 @change="deptTreeDetail">
               </el-cascader>
             </el-form-item>
             <el-form-item label="主优化师" prop="accountMain" :label-width="distribWidth">
               <el-select
                 v-model="accountForm.accountMain" 
                 value-key="userId" 
                 filterable 
                 @change="changeMainUserId"
                 style="width:300px;"
                 placeholder="请选择主分配优化师">
                   <el-option
                   v-for="(item,i) in accountOptOption"
                   :key="i"
                   :label="item.string"
                   :value="item.userId">
                 </el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="辅优化师" prop="accountAuxiliary" :label-width="distribWidth">
               <el-select
                 v-model="accountForm.accountAuxiliary"
                 value-key="userId"
                 @visible-change="$forceUpdate()" 
                 filterable 
                 multiple 
                 @change="changeAssistUserIds"
                 style="width:300px;"
                 placeholder="请选择辅分配优化师">
                   <el-option
                   v-for="(item,i) in accountOptOption"
                   :key="i"
                   :label="item.string"
                   :value="item">
                 </el-option>
               </el-select>
             </el-form-item>
           </el-form>
           <div slot="footer" class="dialog-footer">
             <el-button class="newDialogCancel" type="primary" @click="distributeCancel">取消分配</el-button>
             <el-button type="primary" class="newDialog" @click="distributeSure">确定分配</el-button>
           </div>
          </el-dialog>
          <!-- 预警详情 -->
          <el-dialog
            title="预警详情"
            append-to-body
            :visible.sync="dialogWarningDetail"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center
            class="warnningDialog"
            width="820px">
            <div class="dialogIcon"><img :src='warningIcon'></div>
            <div class="warnTableData">
              <div style="text-align:right;margin-bottom:15px;margin-top:-35px;">
                <el-button type="primary" size="mini" @click="accountWarnDialog">预警设置</el-button>
              </div>
              <el-table
                ref="multipleWarnTable"
                :data="warnInfosTableData"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                tooltip-effect="dark">
                <el-table-column
                  prop=""
                  label="观察指标和执行条件"
                  width="200"
                  fixed>
                  <template slot-scope="scope">
                    <div class="warnInfosList" v-for="(item,i) in scope.row.infos">
                      <span>
                        <i v-if="item.target == 1">余额</i>
                        <i v-else-if="item.target == 2">消耗</i>
                      </span>
                      <span>
                        <i v-if="item.op == 1">>=</i>
                        <i v-else-if="item.op == 2"><=</i>
                      </span>
                      <span>{{item.val}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="operate"
                  label="操作符">
                  <template slot-scope="scope">
                    <span v-if="scope.row.operate == 1">与</span>
                    <span v-else>或</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="startTime"
                  label="开始时间">
                </el-table-column>
                <el-table-column
                  prop="endTime"
                  label="结束时间">
                </el-table-column>
                <el-table-column
                  prop="executeStrategy"
                  label="执行策略">
                  <template slot-scope="scope">
                    <span v-if="scope.row.executeStrategy == 1">无</span>
                    <span v-else-if="scope.row.executeStrategy == 2">停投</span>
                    <span v-else-if="scope.row.executeStrategy == 3">调日预算</span>
                    <span v-else-if="scope.row.executeStrategy == 4">调CPA出价</span>
                    <span v-else>调点击出价</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="状态"
                  width="120">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      :active-value="0"
                      :inactive-value="1"
                      @change="changeWarnStatus(scope.row)">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="warnCount"
                  label="预警次数">
                </el-table-column>
                <el-table-column
                  prop="priority"
                  label="优先级">
                </el-table-column>
                <el-table-column
                  prop=""
                  label="创建时间"
                  width="180">
                  <template slot-scope="scope">{{scope.row.createTime | dateTimeFormat}}</template>
                </el-table-column>
                <el-table-column
                  prop="updater"
                  label="更新人">
                </el-table-column>
                <el-table-column
                  prop=""
                  label="更新时间"
                  width="180">
                  <template slot-scope="scope">
                    <span v-if="scope.row.updateTime == null">暂无</span>
                    <span v-else>{{scope.row.updateTime | dateTimeFormat}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="操作"
                  width="100"
                  fixed="right">
                  <template slot-scope="scope">
                    <el-button class="newBtn_darkOrange" size="mini" @click="warnEdit(scope.row)"><i class="el-icon-edit" style="padding-right:3px;font-weight:700;"></i>修改</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button class="newDialogCancel" type="primary" @click="dialogWarningDetail = false">取消保存</el-button>
              <el-button class="newDialog" type="primary" @click="dialogWarningDetail = false">确定保存</el-button>
            </span>
          </el-dialog>
          <!-- 预警 -->
          <el-dialog 
            title="预警设置" 
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center
            class="warnningForm"
            :visible.sync="dialogFormVisible">
            <div class="dialogIcon"><img :src='warningIcon'></div>
            <el-form 
              :model="budgetRuleForm"
              :rules="budgetRules"
              ref="budgetRuleForm">
              <div>
                <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
                  <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="warnWidth">
                    <el-select
                        v-model="obj.target">
                        <el-option
                          v-for="(item,i) in newObserveTargetOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select> 
                  </el-form-item>
                  <el-form-item label="执行条件" prop="" :label-width="warnWidth">
                    <el-select
                      v-model="obj.op"
                      style="width:120px;">
                      <el-option
                        v-for="(item,i) in newSymbolOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select> 
                    <el-input 
                      v-model.number="obj.val"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:280px;margin-left:20px;"
                      placeholder="请输入预警数值">
                    </el-input>
                  </el-form-item>
                  <div class="deleteBtn">
                    <div v-if="j>0" size="small" @click="removeRowpl(j)">
                      <i class="deleteBtnicon el-icon-error"></i>
                    </div>
                  </div>
                </div>
                <div @click="changeChecked" class="addConditions">
                  <i class="el-icon-plus"></i>新增执行条件
                </div>
              </div>
              <span class="other">其他设置</span>
              <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="warnWidth">
                <el-select
                  v-model="budgetRuleForm.warningPerson"
                  multiple
                  value-key="userId"
                  @change="changeWarning"
                  style="width:300px;">
                  <el-option
                    v-for="(item,i) in warningPersonOption"
                    :key="i"
                    :label="item.string"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="warnWidth">
                <el-select
                  v-model="budgetRuleForm.newOperator">
                  <el-option
                    v-for="(item,i) in newOperatorOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> 
              </el-form-item>
              <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="warnWidth">
                <el-time-picker
                  is-range
                  v-model="budgetRuleForm.warningTime"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  format="HH:mm"
                  :default-time="['00:00', '23:59']"
                  style="width:160px;"
                  @change="changeTimes">
                </el-time-picker>
              </el-form-item>
              <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="warnWidth">
                <el-input v-model="budgetRuleForm.warnNumTimes"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width:180px;"
                  placeholder="请输入预警次数">
                </el-input>
              </el-form-item>
              <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="warnWidth">
                <el-input v-model="budgetRuleForm.priority"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width:180px;"
                  placeholder="请输入优先级">
                </el-input>
              </el-form-item>
              <el-form-item label="执行策略" class="otherItem" :label-width="warnWidth">
                <el-radio class="otherItemradio" v-model="radio" label="1">无</el-radio>
                <el-radio class="otherItemradio" v-model="radio" label="2" @change="changePriceStatus">停止投放</el-radio>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button class="newDialogCancel" type="primary" @click="accountWarningCancel">取消保存</el-button>
              <el-button class="newDialog" type="primary" @click="accountWarning">确定保存</el-button>
            </div>
          </el-dialog>
          <!-- 修改预警 -->
          <el-dialog 
            title="预警修改" 
            width="600"
            center
            class="warnningForm"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="dialogEditVisible">
            <div class="dialogIcon"><img :src='warningIcon'></div>
            <el-form 
              :model="budgetRuleForm"
              :rules="budgetRules"
              ref="budgetRuleForm">
              <div>
                <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
                  <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="warnWidth">
                    <el-select
                        v-model="obj.target">
                        <el-option
                          v-for="(item,i) in newObserveTargetOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select> 
                  </el-form-item>
                  <el-form-item label="执行条件" prop="" :label-width="warnWidth">
                    <el-select
                      v-model="obj.op"
                      style="width:120px;">
                      <el-option
                        v-for="(item,i) in newSymbolOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select> 
                    <el-input v-model.number="obj.val"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:280px;margin-left:20px;"
                      placeholder="请输入预警数值">
                    </el-input>
                  </el-form-item>
                  <div class="deleteBtn">
                    <div v-if="j>0" size="small" @click="removeRowpl(j)">
                      <i class="deleteBtnicon el-icon-error"></i>
                    </div>
                  </div>
                </div>
                <div @click="changeChecked" class="addConditions">
                  <i class="el-icon-plus"></i>新增执行条件
                </div>
              </div>
              <span class="other">其他设置</span>
              <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="warnWidth">
                <el-select
                  v-model="budgetRuleForm.warningPerson"
                  multiple
                  value-key="userId"
                  @change="changeWarning"
                  style="width:300px;">
                  <el-option
                    v-for="(item,i) in warningPersonOption"
                    :key="i"
                    :label="item.string"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="warnWidth">
                <el-select
                  v-model="budgetRuleForm.newOperator">
                  <el-option
                    v-for="(item,i) in newOperatorOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> 
              </el-form-item>
              <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="warnWidth">
                <el-time-picker
                  is-range
                  v-model="budgetRuleForm.warningTime"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  format="HH:mm"
                  style="width:160px;"
                  @change="changeTimes">
                </el-time-picker>
              </el-form-item>
              <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem"  :label-width="warnWidth">
                <el-input v-model="budgetRuleForm.warnNumTimes"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width:180px;"
                  placeholder="请输入预警次数">
                </el-input>
              </el-form-item>
              <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="warnWidth">
                <el-input v-model="budgetRuleForm.priority"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width:180px;"
                  placeholder="请输入优先级">
                </el-input>
              </el-form-item>
              <el-form-item label="是否清除当日预警次数" class="otherItem" label-width="150px">
                <el-switch
                  v-model="budgetRuleForm.clearHistory"
                  active-color="#13ce66"
                  inactive-color="#ccc"
                  :active-value="1"
                  :inactive-value="2">
                </el-switch>
              </el-form-item>
              <el-form-item label="执行策略" class="otherItem otherItemRight" :label-width="warnWidth">
                <el-radio v-model="radio" label="1">无</el-radio>
                <el-radio v-model="radio" label="2" @change="changePriceStatus">停止投放</el-radio>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" class="newDialogCancel" @click="dialogEditVisible = false">取消保存</el-button>
              <el-button type="primary" class="newDialog" @click="accountWarningEdit">确定保存</el-button>
            </div>
          </el-dialog>

          <!-- 分页 -->
          <div class="clearfix">
            <div class="allOperation fL">
            </div>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
              </el-pagination>
            </div>
          </div>
       </template>
    </PocCardTable>
  </div>
</template>

<script>
import distributionIcon from '@/assets/img/distributionIcon.png';
import warningIcon from '@/assets/img/warningIcon.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {

  data() {
    return {
      distributionIcon,
      warningIcon,
      pubVue:this,
      showSingleDialog:false,
      uploadDialogIs:false,//批量上传弹框
      showDialog: false,//预警设置弹框
      showDialogIs: false,//提示已经设置过的弹框
      nowIs:false,
      setData:{},//账户余额(cashBal)、今日消耗(cashCost)、计划日预算(cashDayBudget)设置回显
      userContent:{},//登录用户信息&弹框默认
      params:{},//入参
      qdoptions:{channelList:[{text:'oppo',id:'3'}],
                userStatuList:[{text:'全部状态',id:'0'},{text:'未设置',id:'1'},{text:'预警开启中',id:'3'},{text:'预警已关闭',id:'2'}]},//选择状态
      channel:'',
      channeln:'',
      customerName:'',//搜索框--客户名称
      show:false,
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      userId:'',//用户ID
      userName:'',
      deptId:'',
      ownerId:'',
      ownerIdList:[],//选中的用户
      disabledIs: false,//switch是否可操作
      operNum:0,
      yulanList:[],
      planType:10,
      dialogTitle:'批量日限额',
      myHeaders: {Authorization:''},
      fileList:{},
      operaType:10,
      warmSetType:'oppo',//如果是VIVO传1
      accountSelectionListShow:true,//用户是否有权限
      channelTitleList:[],//权限名称列表
      tableData:[],
      props:{
        value:'id',
        label:'label',
        children:'children',
        emitPath:false,
        checkStrictly: true
      },
      dialogAccountVisible:false,//批量分配账户的弹窗
      accountForm:{//批量分配账户的表单
        qdvalueHx:'',//分配渠道
        accountDept:'',//分配部门
        accountMain:'',//主分配优化师
        accountAuxiliary:[],//辅助优化师

      },
      rules:{//批量分配账户的验证规则
        accountDept: [
          { required: true, message: '请选择分配部门', trigger: 'change' }
        ],
        accountMain:[
          { required: true, message: '请选择主分配优化师', trigger: 'change' }
        ],
        accountAuxiliary:[
          { required: true, message: '请选择辅分配优化师', trigger: 'change' }
        ],
      },
      formLabelWidth:'150px',
      accountOptOption:[],
      materialIdsPara:[],//选中的账户
      options: [],//分配部门的tree
      appleChannle:'',
      appleOwnerIdList:'',//分配的时候入参的ownerid集合
      distributeDeptId:'',//分配的时候的部门id
      mainUserId:'',//分配的时候的主优化师
      assistUserIds:'',//分配的时候的辅优化师
      dialogAccountUpdate:false,//账户更新弹框
      accountUpdateForm:{//账户更新弹框表单
        balance:'',//余额
        recharge:'',//最近充值
        status:'',//状态
      },
      accountUpdaterules:{//账户更新弹框表单-校验

      },
      formLabelWidth1:'60px',
      appleOwnerIdUpdate:'',//更新的时候入参的ownerid
      appleStatus:'',//更新的时候入参的status
      //预警
      dialogFormVisible:false,//预警预算弹窗
      budgetRules:{},//验证规则
      radio: '1',
      priceRadio:'3',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      warningPersonOption:[],//预警人的集合
      warnOwnerId:'',//预警的ownerId
      changeWarningPerson:'',//选中的预警人id
      changeWarningPersonName:'',//选中的预警人name
      dialogWarningDetail:false,//预警详情的弹框
      dialogEditVisible:false,//预警修改弹框
      warnInfosTableData:[],//预警详情表格
      warnInfosTableData1:false,//预警详情表格
      warnDeptId:'',//修改预警时候的部门id
      warningId:'',//修改预警id
      row:{},
      inputVisible:false,
      disableLow:false,
      disableHigh:false,
      warnEigth:[],
      warnNine:[],
      budgetRuleForm:{
        condition:{
          min:'',
          max:''
        },
        observeTarget:'1',//观察指标
        warningPerson:[],//预警人
        clearHistory:'',//是否清除预警
        // 新
        newObserveTarget:[],//观察指标-新
        newSymbol:'',//执行条件中的符号，大于等于，小于等于
        newCondition:'',//执行条件中的num
        newOperator:'',//操作符
        warnNumTimes:'',//预警次数
        priority:'',//优先级
        warningTime:[new Date(0,0,0), new Date()],//操作时间
        // warningTime:[new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],//操作时间
      },
      newObserveTargetOption:[{//观察指标集合-新
        value:1,
        label:'余额'
      },{
        value:2,
        label:'消耗'
      }],
      newSymbolOption:[{//执行条件中的符号
        value:1,
        label:'>='
      },{
        value:2,
        label:'<='
      }],
      newOperatorOption:[{//操作符
        value:1,
        label:'与'
      },{
        value:2,
        label:'或'
      }],
      newWarnStartTime:'',
      newWarnEndTime:'',
      warnParams:{//确定预警-入参
        infos:[{
          target:'',
          op:'',
          val:''
        }]
      },
      showTIme:'',//显示的时区
      showTImeOption:[{
        value:1,
        label:'亚太时区'
      },{
        value:2,
        label:'UTC'
      }],
      asiaTime:true,//亚太时区显示
      utcTime:false,//utc时间隐藏
      date:new Date(),
      utcDate:'',//utc时间
      utcTimestamp:'',
      distribWidth:'80px',
      warnWidth:'80px',



    }
  },

  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId

    this.budgetRuleForm.clearHistory = 1
    if(this.budgetRuleForm.observeTarget == '1'){
      this.disableLow = true
      this.disableHigh = false
    }
    this.warnDeptId = 328
    this.budgetRuleForm.newOperator = 1,//预警的操作符默认1-与
    this.budgetRuleForm.warnNumTimes = 3
    this.budgetRuleForm.priority = 1
    this.showTIme = 1//默认显示亚太时区
    var _this=this;
    this.timer=setInterval(function(){
      _this.date=new Date();
      
      const localDate = new Date()
      const utcTimestamp = localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000
      this.utcDate = new Date(utcTimestamp).toISOString()
      // console.log(this.utcDate)
    },1000);

   
    this.getuserChannel()
    this.getFpyjList328()
    this.getFpyjList329()
    // this.getUTCtime()

  },
  computed: {
    formattedDate: function() {
      var date = this.date;
      var year = date.getFullYear();
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      var day = date.getDate().toString().padStart(2, '0');
      var hours = date.getHours().toString().padStart(2, '0');
      var minutes = date.getMinutes().toString().padStart(2, '0');
      var seconds = date.getSeconds().toString().padStart(2, '0');
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },

    formattedUTCDate: function() {
      let a = this.utcDate
      console.log(this.utcDate)
      let date = new Date(a);
      let YY = date.getFullYear();
      let MM = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours()
      let mm = date.getMinutes()
      let ss = date.getSeconds()
      MM = MM < 10 ? ('0' + MM) : MM;
      dd = dd < 10 ? ('0' + dd) : dd;
      hh = hh < 10 ? ('0' + hh) : hh;
      mm = mm < 10 ? ('0' + mm) : mm;
      ss = ss < 10 ? ('0' + ss) : ss;
      return YY + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss;
    },

  },

  methods: {
    //获取用户的权限
    getuserChannel(){
      let that = this
      $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userId).then(function(response) {
        if(response.data.data!=undefined&&response.data.data.length!=0){
          that.channelTitleList = response.data.data
          that.accountSelectionListShow = true
          that.appleAccountList()
          that.appleChannle = response.data.data[0].id
          that.accountForm.qdvalueHx = response.data.data[0].name
        }else{
          that.accountSelectionListShow = false
          that.$message({
            showClose: true,
            message: "您的操作权限不足",
            type: 'error'
          })
        }
      })
    },

    //channel的tab切换
    clickChangeChannel(channelId){
      let channelNameList = {3:'planManagement.AccountSelection',4:'VivoAccountManagement.AccountSelectionVivo',5:'MiAccountManagement.AccountSelectionMi',6:'HwAccountManagement.AccountSelectionHw',7:'YybAccountManagement.AccountSelectionYyb'}
      this.$router.push({name:channelNameList[channelId]})
    },

    fun(val){
　　  return Number(val).toFixed(2);
　　 },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this,appleOwnerIdList = []
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e.id)
        appleOwnerIdList.push(e.ownerId)
      })
      that.appleOwnerIdList = appleOwnerIdList.toString()
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选需要分配的账户',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },

    //重置
    reset(){
      this.$router.go(0)
    },

    changeObserveCost(){
      this.disableLow = true
      this.disableHigh = false
      this.budgetRuleForm.condition.min = ''
      this.budgetRuleForm.condition.max = ''
    },
    changeObserveBalance(){
      this.disableLow = false
      this.disableHigh = true
      this.budgetRuleForm.condition.min = ''
      this.budgetRuleForm.condition.max = ''
    },

    //apple账户list
    appleAccountList(){
      let _this = this,params = {}
      params={
        'pageIndex' : _this.page|| 1,
        'pageSize': _this.pageCount,
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'query':_this.customerName//搜名称或id
      }
      $http.axios.post("/api/apple/account/list",params).then(res=> {
        if(res.data.code==200){
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //批量分配账户
    batchAccount(){
      this.chooseTableFn('dialogAccountVisible')
      this.getDeptTree()
    },

    //获取分配中的分配部门下拉框
    getDeptTree(){
      let that = this
      $http.axios.get("/api/system/dept/treeselect?channelId="+that.appleChannle).then(function(response) {
        if(response.data.code == 200){
          that.options = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取批量分配账户的分配部门id
    deptTreeDetail(event){
      this.getFpList(event)
      this.distributeDeptId = event
    },

    //change主分配优化师
    changeMainUserId(event){
      this.mainUserId = event
    },

    //change辅助优化师的集合
    changeAssistUserIds(event){
      let _this = this,assistUserIds = []
      event.forEach(function(val,i){
        assistUserIds.push(val.userId)
      })
      _this.assistUserIds = assistUserIds.toString()
    },

    //获取分配人员列表
    getFpList(detpId){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId="+detpId).then(function(response) {
        if(response.data.code == 200){
          that.accountOptOption = response.data.data
          // that.warningPersonOption = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    getFpyjList328(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
        if(response.data.code == 200){
          that.warnEigth = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    getFpyjList329(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
        if(response.data.code == 200){
          that.warnNine = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //确定分配
    distributeSure(){
      let _this = this,params={}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerIds':_this.appleOwnerIdList,
        'distributeDeptId':_this.distributeDeptId,
        'mainUserId':_this.mainUserId,
        'assistUserIds':_this.assistUserIds
      }
      $http.axios.post("/api/apple/account/distribute",params).then(function(response) {
        if(response.data.code == 200){
          _this.dialogAccountVisible = false
          _this.appleAccountList()
          _this.distributeCancel()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //取消分配弹窗
    distributeCancel(){
      this.dialogAccountVisible = false
      this.accountForm.accountDept = ''//部门
      this.accountForm.accountMain = ''//主分配优化师
      this.accountForm.accountAuxiliary = []//辅分配优化师
    },

    //变更分配账户
    changeAllocation(row){
      this.dialogAccountVisible = true
      let _this = this,assistUserIds = []
      _this.getDeptTree()
      _this.getFpList(row.deptId)
      if(row.assistUserIds != null){
        assistUserIds = row.assistUserIds.split(",")
        console.log(assistUserIds)
        assistUserIds.forEach(function(item,index){
          _this.accountForm.accountAuxiliary.push({'userId':item})
        })
        _this.assistUserIds = row.assistUserIds
      }
      if(row.mainUserId != null){
        console.log(String(row.mainUserId))
        _this.accountForm.accountMain = String(row.mainUserId)
        _this.mainUserId = row.mainUserId
      }
      
      _this.accountForm.accountDept = Number(row.deptId)
      _this.appleOwnerIdList = String(row.ownerId)
      _this.distributeDeptId = row.deptId
      // _this.mainUserId = row.mainUserId
      // _this.assistUserIds = row.assistUserIds

    },

    //账户更新
    accountUpdate(row){
      this.dialogAccountUpdate = true
      this.accountUpdateForm.recharge = row.lastRecharge
      if(row.status == 0){
        this.accountUpdateForm.status = true
      }else{
        this.accountUpdateForm.status = false
      }
      this.appleOwnerIdUpdate = row.ownerId
      this.appleStatus = row.status
    },

    //change状态
    changeStatus(event,scope){
      this.appleStatus = Number(event)
      console.log(event)
      console.log(scope)
      // if(event.status == true){
      //   this.appleStatus = 0
      // }else{
      //   this.appleStatus = 1
      // }
      let _this = this,params = {}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':scope.row.ownerId,
        'status':Number(event)
      }
      $http.axios.post("/api/apple/account/update",params).then(function(response) {
        if(response.data.code == 200){
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //账户更新-确定
    accountUpdateSure(){
      let _this = this,params={}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.appleOwnerIdUpdate,
        'balance':_this.accountUpdateForm.balance,
        'lastRecharge':_this.accountUpdateForm.recharge,
        'status':_this.appleStatus
      }
      $http.axios.post("/api/apple/account/update",params).then(function(response) {
        if(response.data.code == 200){
          _this.dialogAccountUpdate = false
          _this.appleAccountList()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警预算
    changePriceStatus(value){
      if(value== 2){
        this.pricetof = false
        this.pricetof01 = false
        this.pricetof02 = true
      }else{
        this.pricetof = true
        this.pricetof01 = true
        this.pricetof02 = true
      }
    },

    changetod(val){
      if(val == 3){
        this.pricetof01 = false
        this.pricetof02 = true
      }else{
        this.pricetof01 = true
        this.pricetof02 = false
      }
    },

    //预警弹框
    accountWarn(row){
      this.dialogFormVisible = true
      this.getFpList(row.deptId)
      this.warnOwnerId = row.ownerId
      this.getFpyjList328()
      this.getFpyjList329()
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      console.log(this.warningPersonOption)
    },

    accountWarnDialog(){
      this.dialogFormVisible = true
      this.getFpList()
      // this.accountWarningCancel()
      this.budgetRuleForm.condition.min = ''
      this.budgetRuleForm.condition.max = ''
      // this.budgetRuleForm.clearHistory = 1
    },

    //change预警人
    changeWarning(event){
      let changeWarningPerson = []
      event.forEach(function(val,i){
        changeWarningPerson.push(val.userId)
      })
      this.changeWarningPerson = changeWarningPerson.toString()
    },

    //取消预警弹框
    accountWarningCancel(){
      this.budgetRuleForm.warningPerson = []//预警人
      this.radio = '1'//执行策略
      this.budgetRuleForm.newOperator = 1//操作符
      this.budgetRuleForm.warnNumTimes = 3//预警次数
      this.budgetRuleForm.priority = 1//优先级
      this.dialogFormVisible = false
    },

    //预警确定
    accountWarning(){
      let _this = this
      _this.warnParams.userId = _this.userId
      _this.warnParams.userName = _this.userName
      _this.warnParams.deptId = _this.deptId
      _this.warnParams.ownerId = _this.warnOwnerId
      _this.warnParams.dataId = Number(_this.warnOwnerId) //账户id
      _this.warnParams.type = 1 //1-系列组 2-系列 3-广告组
      _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
      _this.warnParams.warnId = _this.changeWarningPerson//预警人id
      _this.newWarnStartTime = _this.getDate(_this.budgetRuleForm.warningTime[0])
      _this.newWarnEndTime = _this.getDate(_this.budgetRuleForm.warningTime[1])
      _this.warnParams.startTime = _this.newWarnStartTime//开始时间
      _this.warnParams.endTime = _this.newWarnEndTime//结束时间
      _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
      _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
      _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
      
      $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
        if(response.data.code == 200){
          _this.dialogFormVisible = false
          _this.appleAccountList()
          _this.accountWarningCancel()
          _this.dialogWarningDetail = false
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警详情
    warningDetail(row){
      console.log(row)
      this.row = row
      this.dialogWarningDetail = true
      this.warnInfosTableData = row.warnInfos
      // this.warnDeptId = row.deptId
      this.warnOwnerId = row.ownerId
      this.getFpList(row.detpId)
      this.getFpyjList328()
      this.getFpyjList329()
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      console.log(this.warningPersonOption)
    },

    //预警修改
    warnEdit(row){
      // console.log(row)
      this.budgetRuleForm.clearHistory = 1
      this.dialogEditVisible = true
      let warnId = [],warnName = [],_this = this,infos = []
      infos = row.infos//获取观察指标和执行条件
      console.log(infos)
      warnId = row.warnId.split(",")
      _this.budgetRuleForm.warningPerson = []
      //回显数据
      _this.warningPersonOption.forEach(function(val,i){
        warnId.forEach(function(item,index){
          if(val.userId == item){
            _this.budgetRuleForm.warningPerson.push({'userId':item})
          }
        })
      })//预警人
      _this.warnParams.infos = []
      infos.forEach(function(obj,index){
        console.log(obj)
        _this.warnParams.infos.push({
          target:obj.target,
          op:obj.op,
          val:obj.val
        })
      })//观察指标和执行条件
      _this.budgetRuleForm.newOperator = row.operate //操作符
      //开始结束时间回显
      const date = new Date();
      let nowDate = ''
      nowDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;
      _this.budgetRuleForm.warningTime[0] = new Date(nowDate+" "+row.startTime+":00")///开始结束时间
      _this.budgetRuleForm.warningTime[1] = new Date(nowDate+" "+row.endTime+":00")///开始结束时间
      //开始结束时间传参
      _this.newWarnStartTime = row.startTime///开始结束时间
      _this.newWarnEndTime = row.endTime///开始结束时间
      _this.radio = String(row.executeStrategy) //执行策略
      _this.changeWarningPerson = row.warnId
      _this.warningId = row.id
      _this.budgetRuleForm.warnNumTimes = row.warnCount//预警次数
      _this.budgetRuleForm.priority = row.priority//优先级
      
    },

    //预警修改
    accountWarningEdit(){
      let _this = this
      _this.warnParams.userId = _this.userId
      _this.warnParams.userName = _this.userName
      _this.warnParams.deptId = _this.deptId
      _this.warnParams.ownerId = _this.warnOwnerId
      _this.warnParams.dataId = Number(_this.warnOwnerId) //账户id
      _this.warnParams.type = 1 //1-系列组 2-系列 3-广告组
      _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
      _this.warnParams.warnId = _this.changeWarningPerson//预警人id
      _this.warnParams.startTime = _this.newWarnStartTime//开始时间
      _this.warnParams.endTime = _this.newWarnEndTime//结束时间
      _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
      _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
      _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
      _this.warnParams.clearHistoryPermitsFlag = Number(_this.budgetRuleForm.clearHistory) //是否清空预警次数
      _this.warnParams.id = Number(_this.warningId)//修改时候的id
      $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
        if(response.data.code == 200){
          _this.dialogEditVisible = false
          _this.appleAccountList()
          _this.dialogWarningDetail = false
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改预警状态
    changeWarnStatus(row){
      console.log(row)
      let _this = this,params={}
      params={
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.warnOwnerId,
        'dataId':Number(_this.warnOwnerId),//账户id
        'type':1,//1-系列组 2-系列 3-广告组
        'infos':row.infos,//监控条件明细
        'executeStrategy':row.executeStrategy,//执行策略
        'warnId':row.warnId,//预警人
        'startTime':row.startTime,//开始时间
        'endTime':row.endTime,//结束时间
        'operate':row.operate,
        'executeRate':row.executeRate,
        'executeDiff':row.executeDiff,
        'clearHistoryPermitsFlag':1,//修改时候传1-暂时
        'warnCount':row.warnCount,//预警次数 默认3
        'priority':row.priority,//优先级
        'id':Number(row.id),//修改时候的id
        'status':Number(row.status),
      }
      $http.axios.post("/api/apple/warn/insertOrUpdate",params).then(function(response) {
        if(response.data.code == 200){
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //进入广告系列
    enterAdvertPlan(row){
      this.$router.push({path:'/plan-management/adPlanApple', query: { id: row.ownerId, name:row.ownerName }})

    },

    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.appleAccountList()
    },

    //余额弹框
    inputBoxShow(row){
      console.log(row)
      console.log(this.$refs.popoverRef)
      // console.log(index)
      // this.inputVisible = true
    },

    //pop弹框消失
    inputBoxHide(){
      console.log(this.$refs.popoverRef)
      // this.$refs.popoverRef.doClose()
    },

    editrRecharge(scope){
      this.accountUpdateForm.recharge = scope.row.lastRecharge
    },

    editBalance(scope){
      this.accountUpdateForm.balance = scope.row.balance
    },

    handleConfirm(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose()
      scope._self.$refs[`popover-${scope.$index}`].showPopper = false
    },

    handleConfirm() {
      document.body.click() // 模拟点击页面其它部分关掉弹层，因为该页面列表使用懒加载导致doClose无效
    },

    handleConfirmSure(scope,index) {
      // accountUpdateForm.balance
      let _this = this,params={}
      if(index == 1){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':scope.row.ownerId,
          'balance':_this.accountUpdateForm.balance,
        }
      }else if(index == 2){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':scope.row.ownerId,
          'lastRecharge':_this.accountUpdateForm.recharge,
        }
      }
      $http.axios.post("/api/apple/account/update",params).then(function(response) {
        if(response.data.code == 200){
          scope._self.$refs[`popover-${scope.$index}`].doClose()
          scope._self.$refs[`popover-${scope.$index}`].showPopper = false
          _this.appleAccountList()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //跳转到操作记录页面
    toOperateList(){
      this.$router.push({path:'/plan-management/adOperateApple'})
    },

    //预警-选择观察指标-新增
    changeChecked(){
      // this.warnParams.infos.push({})
      this.warnParams.infos.push({
        target:'',
        op:'',
        val:''
      })
    },

    //预警-选择观察指标-删除
    removeRowpl(index) {
      if (index >= 0) {
        this.warnParams.infos.splice(index, 1);
      }
    },

    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return h + ':' + m;
      }
      return ''
    },

    //change预警-开始结束时间
    changeTimes(val){
      this.budgetRuleForm.warningTime = val
      this.newWarnStartTime = this.getDate(this.budgetRuleForm.warningTime[0])
      this.newWarnEndTime = this.getDate(this.budgetRuleForm.warningTime[1])
    },

    //change时区
    changeShowTime(){
      if(this.showTIme == 1){
        this.asiaTime = true
        this.utcTime = false
      }else{
        this.asiaTime = false
        this.utcTime = true
      }
    },

    //utc时间
    getUTCtime(){
      const localDate = new Date()
      const utcTimestamp = localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000
      this.utcDate = new Date(utcTimestamp).toISOString()
      console.log(this.utcDate)
    }

  
  }
}
</script>

<style lang="scss" scoped>
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #F1F7F5;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }
  .priceStyle{
    display: block;
    margin:10px 0;
  }
  .detail{
    color:#409eff;
  }
  .warnDetail{
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #ddd;
  }
  .warnDetailList{
    font-size:0;
    margin:5px 0;
    padding-left:100px;
  }
  .warnDetailList label,.warnDetailList p{
    display: inline-block;
    vertical-align: middle;
    font-size:14px;
    margin:0;
  }
  .warnDetailList label{
    width:100px;
    text-align:right;
    padding-right:20px;
  }
  .warningEdit,.warningenEdit{
    display:block;
    width:15px;
    height:15px;
    border-radius: 50%;
    background:#67c23a;
    cursor: pointer;
  }
  .warningenEdit{
    background:#ddd;
    cursor:text;
  }
  /*操作记录的style*/
  .operateList{
    position: absolute;
    top:0;
    right:0;
  }
  /*点击名称进入下一个列表*/
  .ownerNameColor{
    color:#2F674D;
    font-weight:500;
  }
  /*预警-新*/
  .newAddfpStyle{
    position: relative;
  }
  .newReduceBtnStyle{
    position:absolute;
    top:50%;
    right:0;
    transform: translate(0,-50%);
  }
  .warnInfosList span i{
    font-style: normal;
    font-weight:500;
  }
  /*新版*/
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:260px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  /*新预警*/
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  
</style>