<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>计划管理</el-breadcrumb-item>
          <el-breadcrumb-item>OPPO</el-breadcrumb-item>
          <el-breadcrumb-item>渠道</el-breadcrumb-item>
          <el-breadcrumb-item>调价展示</el-breadcrumb-item>
          <el-breadcrumb-item>修改调价</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="setPrice">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <span class="baseTitle">基础信息</span>
                    <el-form-item label="客户名称" prop="customerName">
                        <el-input v-model="ruleForm.customerName" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="计划名称" prop="planName">
                        <el-input v-model="ruleForm.planName" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="广告组名称" prop="advertName">
                        <el-input v-model="ruleForm.advertName" placeholder=""></el-input>
                    </el-form-item>
                    <span class="baseTitle">调价设置</span>
                    <el-form-item label="调价状态" prop="priceStatus">
                        <el-select v-model="ruleForm.priceStatus" placeholder="请选择调价状态" filterable>
                            <el-option
                              v-for="(item,i) in priceStatusOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="调价类型" prop="priceType">
                        <el-select v-model="ruleForm.priceType" placeholder="请选择调价类型" filterable>
                            <el-option
                              v-for="(item,i) in priceTypeOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="调价方式" prop="priceMode">
                        <el-select v-model="ruleForm.priceMode" placeholder="请选择调价方式" filterable>
                            <el-option
                              v-for="(item,i) in priceModeOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="修改金额" prop="editPrice">
                        <el-input v-model="ruleForm.editPrice" placeholder="请输入调价金额或者百分比"></el-input>
                    </el-form-item>
                    <el-form-item label="出价上限" prop="priceTop">
                        <el-input v-model="ruleForm.priceTop" placeholder="请输入出价上限值"></el-input>
                    </el-form-item>
                    <el-form-item label="出价下限" prop="priceBotoom">
                        <el-input v-model="ruleForm.priceBotoom" placeholder="请输入出价下限值"></el-input>
                    </el-form-item>
                    <el-form-item label="执行方式" prop="executionMode">
                        <el-select v-model="ruleForm.executionMode" placeholder="请选择执行方式" filterable @change="secondMode">
                            <el-option
                              v-for="(item,i) in executionModeOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item prop="executionMode" v-show="modeTime">
                        <el-checkbox v-model="checked" @change="checkTime">设置执行时间</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                        <el-button type="info" size="small" plain>取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'SetPrice',
    data() {
      return {
        ruleForm: {
            customerName: '',
            planName:'',
            advertName:'',
            priceStatus:'',
            priceType:'',
            priceMode:'',
            editPrice:'',
            priceTop:'',
            priceBotoom:'',
            executionMode:''
        },
        rules: {
            priceStatus:[
                { required: true, message: '请选择调价状态', trigger: 'change' }
            ],
            priceType:[
                { required: true, message: '请选择调价类型', trigger: 'change' }
            ],
            priceMode:[
                { required: true, message: '请选择调价方式', trigger: 'change' }
            ],
            editPrice:[
                { required: true, message: '请输入调价金额或者百分比', trigger: 'blur' }
            ],
            priceTop:[
                { required: true, message: '请输入出价上限值', trigger: 'blur' } 
            ],
            priceBotoom:[
                { required: true, message: '请输入出价下限值', trigger: 'blur' }
            ],
            executionMode:[
                { required: true, message: '请选择执行方式', trigger: 'change' }
            ]
        },
        priceStatusOptions:[//调价状态
            {
                name:'开启中',
                id:1
            },
            {
                name:'暂停中',
                id:2
            }
        ],
        priceTypeOptions:[//调价类型
            {
                name:'百分比调价',
                id:1
            },
            {
                name:'具体值调价',
                id:2
            }
        ],
        priceModeOptions:[//调价方式
            {
                name:'统一上调',
                id:1
            },
            {
                name:'统一下调',
                id:2
            },
            {
                name:'自定义',
                id:3
            }
        ],
        executionModeOptions:[//执行方式
            {
                name:'立即执行',
                id:1
            },
            {
                name:'设置执行日期',
                id:2
            }
        ],
        checked:false,//是否选中设置时间
        modeTime:false,//设置时间的显示隐藏





        
      }
    },
    computed: {
       
    },
    mounted() {
    },
    methods: {
      submitForm(ruleForm) {
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      //执行方式---设置时间
      secondMode(val){
        console.log(val)
        if(val == 2){
            this.modeTime = true
        }else{
            this.modeTime = false
        }
      },

      //change是否选中执行日期
      checkTime(val){
        console.log(val)
        if(val == true){
            console.log(1111)
        }else{
            console.log(22222)
        }
      },
  




    }
  }
  </script>
  
  <style lang="scss" scoped>
      .baseTitle{
          font-weight:700;
          display:block;
          font-size:14px;
          margin:10px 0 20px;
      }
      .setPrice .el-input{
          width:260px;
      }
  </style>
