<template>
  <div class="menu-left">
    <el-scrollbar style="height: calc(100% - 32px);" class="poc-scrollbar-default">
      <el-menu
        :default-active="menuActive"
        :collapse="isCollapse"
        class="menu-list"
      >
        <el-submenu v-for="item in menuOptions" :key="item.id" :index="item.id">
          <template slot="title">
            <i class="poc-icon-primary poc-size14" :class="item" style="margin-right: 4px;"></i>
            <span>{{item.name}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="chlid in item.children"
              :key="chlid.id"
              :index="chlid.id"
              @click="onJump(chlid)">
              {{chlid.name}}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
    <div class="menu-left-operation">
      <div class="menu-left-btn" @click="onCollapse">
        <i class="poc-icon-primary poc-size16 menu-left-icon" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api';

/**
 * 菜单遍历的递归方法
 * @param {Object} menu 菜单对象
 * @param {String} path url地址
 * @param {Function} callback 找到对应菜单的回调，处理对应路由菜单选中
 */
const findResult = (menu, path, callback) => {
  let isFind = (menu.url && path.includes(menu.url));
  if (isFind) {
    callback(menu);
  } else {
    if (menu.children && menu.children.length) {
      for (let i = 0; i < menu.children.length; i++) {
        isFind = findResult(menu.children[i], path, callback);
        if (isFind) break;
      }
    }
  }
  return isFind;
};

export default {
  name: 'MenuLeft',

  components: {},

  data() {
    return {
      isCollapse: false,
      menuActive: '1-1',
    };
  },

  computed: {
    // 菜单配置
    menuOptions() {
      return [
        {
          id: '1',
          name: this.$t('dc:账号管理'),
          // icon: 'el-icon-view',
          icon: 'iconfont icon-oneui-data',
          children: [
            {
              id: '1-1',
              name: this.$t('dc:账号维护'),
              icon: '',
              url: '/auth-management/account-list',
            }
          ],
        },
        {
          id: '2',
          name: this.$t('dc:权限管理'),
          icon: 'el-icon-view',
          children: [
            {
              id: '2-1',
              name: this.$t('dc:品牌定义'),
              icon: '',
              url: '/operation-access/product-list',
            },
            {
              id: '2-2',
              name: this.$t('dc:权限定义'),
              icon: '',
              url: '/operation-access/access-list',
            },
            {
              id: '2-3',
              name: this.$t('dc:角色定义'),
              icon: '',
              url: '/operation-access/role-list',
            },
          ],
        },
      ];
    },
  },

  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        for (let i = 0; i < this.menuOptions.length; i++) {
          let isFind = findResult(this.menuOptions[i], to.path, (menu) => {
            this.menuActive = menu.id;
          });
          if (isFind) break;
        }
      }
    }
  },

  methods: {
    // 显示，隐藏菜单
    onCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 跳转
    onJump(menu) {
      if (this.$route.path !== menu.url) {
        this.$router.push(menu.url);
      }
    },
  },
}
</script>

<style lang="scss">
.menu-left {
  height: 100%;

  .menu-left-operation {
    height: 32px;
    padding: 0 $MP8;
    overflow: hidden;

    .menu-left-btn {
      position: relative;
      height: 100%;
      cursor: pointer;
      border-top: 1px solid $poc-border-color;
    }

    .menu-left-icon {
      position: absolute;
      top: 8px;
      right: calc(50% - 8px);
      font-size: 16px;
      color: $--color-primary;
    }
  }

  .menu-list {
    width: 240px;
    min-height: 500px;
    border-right: 0!important;

    &.el-menu--collapse {
      width: 58px;
    }
  }

  .el-submenu {
    text-align: left;

    &.is-active .el-submenu__title {
      color: $--color-primary;
    }

    .el-menu-item.is-active {
      /* background: $poc-color-primary-deputy; */
      border-right: 4px solid $--color-primary;

      a {
        color: $--color-primary;
      }
    }

    .el-menu-item-group {
      background: $base-bg-color;
    }
  }
}
</style>
