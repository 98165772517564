<template>
  <el-dialog
    :visible.sync="show"
    width="400px"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    center
    :show-close = "false"
    title="设置日限额预警">
    <div class="dialogIcon"><img :src='warningIcon'></div>
    <el-form
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules">
    <el-form-item label="预警开关" label-width="80px">
      <el-switch
        v-model="formData.warningSwitch"
        active-value="0"
        inactive-value="1"
        active-color="#48916F"
        inactive-color="#EAEFED">
      </el-switch>    
    </el-form-item>
      <el-form-item label="最高值" prop="maxNum" label-width="80px">
        <el-input
          v-model.trim="formData.maxNum" 
          placeholder="请输入限制金额"   
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          @blur="salaryChange(formData.maxNum,$event)"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="最低值" prop="minNum" label-width="80px">
        <el-input
          v-model.trim="formData.minNum" 
          placeholder="请输入限制金额"   
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          @blur="salaryChange(formData.minNum,$event)"
          clearable>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import warningIcon from '@/assets/img/warningIcon.png';
  import { $http } from '@/api/http';
export default {
  
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // type: Number,
    // ownerId:Number,
    pubVue:Object,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      warningIcon,
      formData: {
          maxNum: '',
          minNum: '',
          warningSwitch:null
        },
      rules: {
        maxNum: [
          { required: true, message: '请输入最高值', trigger: 'blur' },
        ],
        minNum: [
          { required: true, message: '请输入最低值', trigger: 'blur' }
        ],
        
      },
      optionsm:{},//部门名称
      loading: false,
      show: this.visible,
      currentTypeConfig: {},
      channel:'',
      setData:{},//回显值
      setPriceCommon:{
        "ownerId": 0,
        "ownerName": "",
        "type": 0,
        "userId": 0,
        "userName": "",
        "deptId": 0,
      },//addAccountBalanceSet公共入参
      accounParams:{
        "moneySwitch": 0,
        "warningMoneyLow": 0,
	      "warningMoneyTop": 0
      },//addAccountBalanceSet入参账户部分
      costParams:{
        "costSwitch": 0,
        "costLow": 0,
        "costTop": 0,
      },//addAccountBalanceSet入参消耗部分
      dayParams:{
        "budgetSwitch": 0,
        "dayBudgetLow": 0,
        "dayBudgetTop": 0,
      },//addAccountBalanceSet入参日预算部分
      setP:[['moneySwitch','warningMoneyTop','warningMoneyLow'],
            ['costSwitch','costTop','costLow'],
            ['budgetSwitch','dayBudgetTop','dayBudgetLow']],//添加接口的入参名整理
      addOrSet:false,//判断是添加还是修改true为修改；false为添加
      infoId:'',//详情接口的id
      dayDisabled:false,//日预算是否可操作
      upCode:'',
      addCode:'',
      setCode:'',
      adPlanId:'',
      adPlanName:'',
      dayBudgetPrice:'',
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
  },
  methods: {
    
    onConfirm(formName) {
      let _this = this
      if(_this.dayBudgetPrice == '不限'){
        _this.dayBudgetPrice = null
      }
      _this.setPriceCommon={'userId' : _this.userContent.userId,'userName' : _this.userContent.userName,
      'deptId': _this.userContent.deptId,
      'ownerName' : _this.userContent.ownerName,
      'ownerId' : _this.userContent.ownerId,
      'adPlanName' : _this.adPlanName,
      'adPlanId' : _this.adPlanId,
      'dayBudget' :_this.dayBudgetPrice != '不限'? Number(_this.dayBudgetPrice):null}
      // _this.setPriceCommon[0].type = _this.userContent.type
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if(200>=parseInt(_this.formData.maxNum)||200>=parseInt(_this.formData.minNum)){
          //   let messageContent ='预警最高值和最低值不能小于等于200'
          //     _this.$message({
          //       showClose: true,
          //       message: messageContent,
          //       type: 'error'
          //     })
          //   } else{
              _this.addOrSetFn(_this.infoId)
            // }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
      
    },
    onCancel() {
      this.show= false;
      this.formData= {
          maxNum: '',
          minNum: '',
          warningSwitch:null
        }
    },
    addOrSetFn(){
      let _this = this,params = [] ,params1
      if(Number(_this.formData.maxNum)<Number(_this.formData.minNum)){
        let messageContent = '请调整最高值，最高值不能<或=最低值'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })
      }else{
        params1={'warningSwitch': _this.formData.warningSwitch,'warningTop' : _this.formData.maxNum,'warningLow' : _this.formData.minNum}
        params.push(Object.assign({},_this.clone(_this.setPriceCommon), params1))
        if(_this.addOrSet){
          	// "accountWarningMesgId": 0,预警信息表id ->不传
            // "adPlanId": 0,广告计划id
            // "adPlanName": "",广告计划名称
            // "dayBudget": 0,日预算
            // "deptId": 0,部门id
            // "ownerId": 0,账户id
            // "ownerName": "",账户名称
            // "userId": 0,
            // "userName": "",
            // "warningLow": 0,预警最低值
            // "warningSwitch": 0,预警开关(1关 0开 )不传
            // "warningTop": 0预警最高值
            if(_this.formData.maxNum>_this.setPriceCommon.dayBudget){
              _this.setAccDayBudgetFn(params)
            }else{
              _this.$message({
                showClose: true,
                message: '预警最高值不能小于等于当前日预算',
                type: 'error'
              }) 
            }
        }else{
          _this.addUpdateDailyMoneyPlanFn(params)
        }
      }
    },
    //最高最低值改变
    salaryChange(num,e){
        this[num] = e.target.value
        console.log(num,e)
    },
   
    //获取计划预警详情
    setDialogContentFn(ownerId,adPlanId,adPlanName,dayBudgetPrice){
      let _this = this
          _this.adPlanName = adPlanName
          _this.adPlanId = adPlanId
      $http.axios.post("/api/vivo/adPlan/v1/getAdPlanDetail",{'ownerId':ownerId,'adPlanId':adPlanId}).then(res=> {
        if(res.data.code==200){
          if(res.data.data!=null){
            _this.setData = res.data.data
            _this.infoId = _this.setData.id
            _this.setData.warningTop == null? _this.formData.maxNum = _this.setData.warningTop :  _this.formData.maxNum =  _this.setData.warningTop
            _this.setData.warningLow == null? _this.formData.minNum = _this.setData.warningLow :  _this.formData.minNum =  _this.setData.warningLow
            _this.formData.warningSwitch = _this.setData.warningSwitch==null?'1':_this.setData.warningSwitch.toString()
            _this.addOrSet = true
          }else{
            this.formData= {
              maxNum: '',
              minNum: '',
              warningSwitch:null
            }
            _this.addOrSet = false
           
         }
            

        }
        console.log(_this.formData.warningSwitch)
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //批量新增广告计划预警信息
    addUpdateDailyMoneyPlanFn(params){
      let _this = this
      $http.axios.post("/api/vivo/adPlan/v1/addUpdateDailyMoneyPlan",params).then(res=> {
          if(res.data.code==200){
            _this.pubVue.$refs.multipleTable.clearSelection();
            _this.pubVue.getAccountPlanListFn()
            _this.pubVue.adPlanIdList = []
            _this.maxNum = ''
            _this.minNum = ''            
            if(params.type == 3){
                _this.show= false;
                
            }else{
              _this.show= false;
            }
            let messageContent =res.data.msg|| '添加成功'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'success'
            })
          
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //重置
    resetFormFn(){
      _this.$refs.formData.resetFields();//重置表单
    },
    // 修改广告计划预警信息
    setAccDayBudgetFn(params){
      let _this = this
      
        $http.axios.post("/api/vivo/adPlan/v1/upAdPlanWarning",params).then(res=> {
          if(res.data.code!=200){
            let messageContent =res.data.msg
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })
          }else{
            _this.show = false
            this.maxNum = ''
            this.minNum = ''
            _this.pubVue.getAccountPlanListFn()

          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
          
    },
    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeDay(event){
      console.log(event)
        event?this.dayDisabled = true:this.dayDisabled = false
        if(event){
          this.accDayBudget = ''
        }
    },
  }
};
</script>
<style lang="scss" scoped>
.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
.el-checkbox{
  margin: 15px;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
.bottomStyle{
  margin-top:30px;
  text-align:center;
}
</style>

