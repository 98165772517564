<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/auth-management/homePage' }">首页管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/userList' }">用户列表</el-breadcrumb-item>
          <el-breadcrumb-item><span @click="goback" style="font-weight:700;cursor: pointer;">用户详情</span></el-breadcrumb-item>
          <el-breadcrumb-item>账户修改</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
          <span class="role_headerChecked role_headerChecked_user">账户修改</span>
          <div class="userdetail_list">
            <h3><i></i>账户基本信息</h3>
            <ul class="userList_baseInfo">
              <li>
                <label>客户ID</label>
                <span>{{detailData.accountId}}</span>
              </li>
              <li>
                <label>客户名称</label>
                <span>{{detailData.accountName}}</span>
              </li>
              <li>
                <label>优化师</label>
                <!-- <span>{{detailData.userName}}</span> -->
                <span v-for="item in detailData.userList" style="padding:0 4px;">{{item.username}}</span>
              </li>
              <li>
                <label>渠道名称</label>
                <span>{{detailData.channel}}</span>
              </li>
              <li>
                <label>公司名称</label>
                <span>{{detailData.accountName}}</span>
              </li>
              <li>
                <label>优化经理</label>
                <span>{{detailData.userLeader}}</span>
              </li>
              <!-- <li>
                <label>投放状态</label>
                <span>{{detailData.userLeader}}</span>
              </li> -->
              <li>
                <label>优化总监</label>
                <!-- <span>{{detailData.userLeader}}</span> -->
                <span>刘智惠</span>
              </li>
              
            </ul>
            <!-- <h3><i></i>产品基本配置</h3>
            <ul class="userList_baseInfo">   
              <li>
                <label>日报启停</label>
                <el-switch
                  v-model="daySwtich"
                  class="switchStyle"
                  :active-value="2"  
                  :inactive-value="1"
                  
                  active-color="#13ce66"
                  inactive-color="#ccc"
                  @change = "changeStatus()"
                  >
                </el-switch>
                <label>时报启停</label>
                <el-switch
                  v-model="hourSwitch"
                  class="switchStyle"
                  :active-value="2"  
                  :inactive-value="1"
                  
                  active-color="#13ce66"
                  inactive-color="#ccc"
                  @change = "changeStatus()"
                  >
                </el-switch>
              </li>
              <li>
                <label>时报时间点</label>
                  <el-select v-model="value0" multiple placeholder="请选择时报时间点">
                  <el-option
                    v-for="(item,i) in options"
                    :key="i"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              
              </li>
              <li>
                <label>时报模板</label>
                <el-select v-model="HourTempId" filterable placeholder="" @change="changeChannel($event)">
                    <el-option
                    v-for="(item,i) in hourData"
                    :key="i"
                    :label="item.tempName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </li>
              <li>
                <label>日报日期</label>
                <el-select v-model="value2" multiple filterable placeholder="请选择日报日期">
                    <el-option
                    v-for="(item,i) in weekList"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </li>
              <li>
                <label>日报模板</label>
               
                <el-select v-model="dayTempId" filterable placeholder="" @change="changeChannel($event)">
                    <el-option
                    v-for="(item,i) in dayData"
                    :key="i"
                    :label="item.tempName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </li>
              <li>
                <label>发送人</label>
                <el-select v-model="sender_value" filterable placeholder="" @change="changeFps">
                    <el-option
                    v-for="(item,i) in distributionOptions"
                    :key="i"
                    :label="item.string"
                    :value="item.userId">
                  </el-option>
                </el-select>
              </li>
              <li>
                <label>抄送人</label>
                <el-select v-model="value1" multiple filterable :placeholder="cc_values"  @change="changeCsr($event)">
                  <el-option
                    v-for="(item,i) in cc_options"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>

                <el-button type="text" @click="dialogFormVisible_add = true" style="padding-left:10px;">添加</el-button>
                <el-dialog title="添加抄送人" :visible.sync="dialogFormVisible_add">
                  <el-form :model="addForm">
                    <el-form-item label="人员名称" :label-width="formLabelWidth">
                      <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入添加人员姓名" style="width:100%!important;"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱地址" :label-width="formLabelWidth">
                      <el-input v-model="addForm.email" autocomplete="off" placeholder="请输入添加邮箱地址" style="width:100%!important;"></el-input>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible_add = false">取 消</el-button>
                    <el-button type="primary" @click="csrSure">确 定</el-button>
                  </div>
                </el-dialog>
              </li>
              <li>
                <label>销售人员</label>
                <el-input v-model="salesmanName" placeholder=""></el-input>
              </li>
            </ul> -->
          </div>
          <div class="preservationBtns">
            <!-- <el-button type="primary" @click="sure_usermodify">保存</el-button> -->
            <el-button @click="cancel_usermodify">返回</el-button>
          </div>  
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
      <accessDialog :visible.sync="showDialog" ></accessDialog>
    </div>
  </template>
  
  <script>
  import accessDialog from '@/views/dialog/accountDialog';
  import echarts from  "echarts";
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'authority',
    components: {
      accessDialog
    },
    data() {
      return {
        value1:[],
        value0:[],//时报时间点
        value2:[],//日报时间点
        showDialog: false,
        userProductName:'',
        productNameOptions:[],
        distributionOptions:[],
        projectName:'',
        projectNameOptions:[],
        qdvalue:'',
        qdoptions:[],
        regionyhs:'',
        sender_value:'',
        sender_options:[],
        cc_value:[],
        cc_options:[],
        salesmanName:'',
        dialogFormVisible_add: false,
        addForm: {
          name: '',
          email:''
        },
        formLabelWidth: '120px',
        detailId:'',
        detailData:{},
        channelIdChecked:'',
        csrParams:{
          accountId:'',
          email:'',
          userName:''
        },
        userListParams:{
          userId:''
        },
        pageNum:'1',
        pageSize:'500',
        usermodifyParams:{
          copyUser:'',//
          dept:'',//
          deptName:'',//
          id:'',//
          lookChannel:'',//
          lookUser:'',//
          lookUserDeptId:'',//
          productId:'',//
          productName:'',//
          projectId:'',//
          projectName:'',//
          saleUser:'',//
          sendUser:'',//
          userId:''//
        },
        copyUserString:[],
        getProductId:'',
        detailSaleUser:'',
        detailCopyUser:'',
        detailSendUser:'',
        detailLookChannel:'',
        detailProjectName:'',
        detailProductName:'',
        detailDatas:'',
        cc_values:'',
        cc_valueses:[],
        daySwtich:0,// 0,1关，1开
        hourSwitch:0,
        HourTempId:null,
        dayTempId:null,
        options:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
        weekList:[{name:'周一',id:1},{name:'周二',id:2},{name:'周三',id:3},{name:'周四',id:4},{name:'周五',id:5},{name:'周六',id:6},{name:'周日',id:7}],
        hourData:[],
        dayData:[],
        pageCount:'',
        detailuserList:''
      }
    },
    computed: {
       
    },
    mounted() {
      this.getId()
      this.getDetail()
      // this.getLookPerson()
      this.getChannelList()
      this.getFpList()
      this.getUserList()
      this.getProduct()
      // this.getProject()
      this.getTimeList(1)
      this.getTimeList(2)
    },
    methods: {
      getId(){
        this.detailId = this.$route.params && this.$route.params.userId
        console.log(this.detailId)
        this.userDetailId = String(this.detailId)
        // this.userDetailId = "71754670438"
        console.log(this.userDetailId)
      },

      //获取详情回显
      getDetail(){
        let that = this
        // $http.axios.get("/api/system/accountUser/"+that.userDetailId).then(function(response) {
        $http.axios.get("/api/channel/accountUser/"+that.userDetailId).then(function(response) {
          if(response.data.code == 200){
            that.detailData = response.data.data
            that.userProductName = that.detailData.productName
            that.getProductId = that.detailData.productId
            that.projectName = that.detailData.projectName
            that.qdvalue = that.detailData.lookChannel
            that.regionyhs = that.detailData.deptName+'-'+that.detailData.lookUser+'-'+that.detailData.userId
            that.sender_value = 'OPPO-'+that.detailData.deptName+'-'+that.detailData.sendUser+'-'+that.detailData.sendUserId
            if(that.detailData.daySwtich == 0 || that.detailData.daySwtich == 1){
              that.daySwtich = 0
            }
            if(that.detailData.hourSwitch == 0 || that.detailData.hourSwitch == 1){
              that.hourSwitch = 0
            }
            let ccPeople = that.detailData.copyUserList
            that.cc_valueses =[]
            ccPeople.forEach(function(e,i){
              that.cc_valueses.push(e.name)
              that.cc_values = String(that.cc_valueses)
              console.log(that.cc_values)
            })
            console.log(that.detailData.copyUser)
            that.salesmanName = that.detailData.saleUser
            that.usermodifyParams.copyUser = that.detailData.copyUser
            console.log(that.usermodifyParams.copyUser)
            // that.usermodifyParams.lookChannel = that.detailData.lookChannel
            that.usermodifyParams.lookUser = that.detailData.lookUserId
            that.usermodifyParams.lookUserDeptId = that.detailData.lookUserDeptId
            that.usermodifyParams.productId = that.detailData.productId
            that.usermodifyParams.productName = that.detailData.productName
            that.usermodifyParams.projectId = that.detailData.projectId
            that.usermodifyParams.projectName = that.detailData.projectName
            that.usermodifyParams.saleUser = that.detailData.saleUser
            that.usermodifyParams.sendUser = that.detailData.sendUserId
            that.usermodifyParams.userId = that.detailData.userId
            that.usermodifyParams.deptName = that.detailData.deptName
            that.usermodifyParams.dept = that.detailData.dept
            that.usermodifyParams.id = that.detailData.id


            if(that.detailData.lookChannel == '今日头条'){
              that.channelIdChecked = '1'
              that.usermodifyParams.lookChannel = '1'
            }else if(that.detailData.lookChannel == '快手'){
              that.channelIdChecked = '2'
              that.usermodifyParams.lookChannel = '2'
            }else{
              that.channelIdChecked = ''
            }
            
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

       //获取渠道列表
       getChannelList(){
        let that = this
        $http.axios.get("/api/system/user/getChannelList").then(function(response) {
          if(response.data.code == 200){
            that.qdoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取channel的id
      changeChannel(event){
        this.channelIdChecked = event
        this.usermodifyParams.lookChannel = String(event)
        this.regionyhs = ''
        this.getLookPerson()
      },

      //可查看的人员
      getLookPerson(){
        let that = this
        console.log(that.channelIdChecked)
        $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId="+that.channelIdChecked).then(function(response) {
          if(response.data.code == 200){
            that.sender_options = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      staffFocus(){
        console.log(this.channelIdChecked)
        if(this.channelIdChecked == ''){
          this.$message('请选择查看渠道');
        }else{
          this.getLookPerson()
        }
      },

       //获取分配人员列表
       getFpList(){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
          if(response.data.code == 200){
            that.distributionOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      // //获取用户列表
      getUserList(){
        let that = this
        // let ccPeople = []
        $http.axios.get("/api/channel/accountUser/optionSelectCopyUser").then(function(response) {
        //$http.axios.get("/aki/accountUser/optionSelectCopyUser").then(function(response) {
          if(response.data.code == 200){
            that.cc_options = response.data.data
            // that.cc_options = []
            // ccPeople.forEach(function(e,i){
            //   that.cc_options.push(e.name)
            //   console.log(that.cc_options)
            // })
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //添加抄送人
      csrSure(){
        let that = this
        that.dialogFormVisible_add = false
        // that.csrParams.accountId = '71754670438'
        // that.csrParams.accountId = String(that.userDetailId)
        // that.csrParams.email = that.addForm.email
        // that.csrParams.userName = that.addForm.name
        let userName = that.addForm.name
        let email = that.addForm.email
        $http.axios.get("/api/system/accountUser/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
        // $http.axios.get("/aki/accountUser/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
          if(response.data.code == 200){
            console.log(response)
            that.getUserList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //项目名称下拉框
      getProject(){
        let that = this
        $http.axios.get("/api/operationManagement/materialoperation/getallProject?productId="+that.getProductId).then(function(response) {
          if(response.data.code == 200){
            console.log(response)
            that.projectNameOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
        
      },

      //客户名称下拉框
      getProduct(){
        let that = this
        $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
          if(response.data.code == 200){
            that.productNameOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取抄送人ids
      changeCsr(val){
        console.log(val)
        this.copyUserString = []
        this.copyUserString.push(val)
        console.log(this.copyUserString)
        this.usermodifyParams.copyUser = String(this.copyUserString)
        console.log(this.usermodifyParams.copyUser)
        // console.log(event)
        // this.cc_value = []
        // this.cc_value.push(event)
      },

      //获取发送人的部门详情
      changeFps(event){
        this.distributionOptions.forEach(obj=>{
          if(obj.userId == event){
            this.usermodifyParams.dept = String(obj.deptId)
            this.usermodifyParams.deptName = String(obj.depyName)
            //this.usermodifyParams.userId = String(obj.userId)
            this.usermodifyParams.sendUser = String(obj.userId)
          }
        })
      },

      //获取可查看人员
      changeFpsList(event){
        console.log(event)
        this.regionyhs = event.string
        this.usermodifyParams.lookUser = String(event.userId)
        this.usermodifyParams.lookUserDeptId = String(event.deptId)
      },

      //获取客户名称详情
      changeProduct(event){
        this.userProductName = event.productName
        this.usermodifyParams.productId = String(event.id)
        this.usermodifyParams.productName = event.productName
        this.getProductId = event.id
        this.projectName = ''
        this.getProject()
      },

      //获取项目名称详情
      changeProject(event){
        this.projectName = event.projectName
        this.usermodifyParams.projectId = String(event.id)
        this.usermodifyParams.projectName = event.projectName
      },
      focusProject(){
        console.log(1)
        console.log(this.getProductId)
        if(this.getProductId == null){
          this.$message('请选择客户名称');
        }else{
          this.getProject()
        }
      },
      //日报、时报模板
      getTimeList(templateType){
      let that = this,timeParams = {}
      timeParams.pageCount = that.pageSize
      //查询非必传条件
      timeParams.channelId = 3
      timeParams.templateType = templateType
      $http.axios.post("/api/operationManagement/dayReportTemplate/queryBylimit",timeParams).then(res=> {
        if(res.data.code == 200){
          if(templateType == 1){
            that.hourData = res.data.data.items
          }else if(templateType == 2){
            that.dayData = res.data.data.items

          }
          that.totalRow = res.data.data.totalCount
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

      //保存修改
      sure_usermodify(){
        let that = this
        that.usermodifyParams.id = String(that.userDetailId)
        that.usermodifyParams.saleUser = that.salesmanName
        that.usermodifyParams.productName = that.userProductName
        that.usermodifyParams.daySwtich = that.daySwtich
        that.usermodifyParams.hourSwitch = that.hourSwitch
        that.usermodifyParams.daysOfWeek = that.value2
        that.usermodifyParams.hoursOfDay = that.value0
        that.usermodifyParams.dayTempId = that.dayTempId
        that.usermodifyParams.hourTempId = that.HourTempId
        console.log(that.usermodifyParams.productName)
        // that.usermodifyParams.copyUser = String(that.cc_value)
        // $http.axios.put("/api/system/account",that.usermodifyParams).then(function(response) {
        $http.axios.put("/api/channel/account",that.usermodifyParams).then(function(response) {
          if(response.data.code == 200){
            that.$router.back()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //取消修改
      cancel_usermodify(){
        this.$router.back()
      },
      goback(){
        this.$router.back()
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .role_headerChecked{
      color:#6e7c7c;
      font-size:16px;
      font-weight:700;
    }
    .role_headerChecked_user {
      display:block;
      padding-bottom:20px;
    }
    .userdetail_list{
      padding:0 15px;
      box-sizing:border-box;
    }
    .userdetail_list h3{
      margin:0;
      margin-bottom:10px;
      font-size:14px;
    }
    .userdetail_list h3 i{
      display:inline-block;
      vertical-align:initial;
      width:5px;
      height:12px;
      background:rgb(255, 119, 22);
      margin-right:6px;
    }
    .userdetail_list ul{
      font-size:0;
    }
    .userdetail_list ul li{
      margin:0;
      padding:0;
      display:inline-block;
      vertical-align:middle;
      font-size:14px;
      width:33.3%;
      box-sizing:border-box;
      padding:0 10px;
      margin:10px 0;
      line-height:22px;
    }
    .userdetail_list ul li label{
      padding-right:20px;
    }
    .userdetail_list ul li .el-input{
      width:50% !important;
    }
    .preservationBtns{
      text-align:center;
      margin-top:40px;
    }
    .preservationBtns .el-button{
      padding:10px 25px;
    }
  </style>
