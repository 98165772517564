
<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft">
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>内容运营</el-breadcrumb-item>
              <el-breadcrumb-item>日报模板库</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-upload
              ref="upload"
              :headers="myHeaders"
              action="/api/dataReport/reportModel/uploadExcel"
              accept=".xlsx"
              :limit='1'
              :on-success= "onSuccess"
              :show-file-list='false'>
              <el-button slot="trigger" size="small" type="primary"> 新建模板</el-button>
            </el-upload>
            <el-button
              slot="trigger"
              size="small"
              type="primary"
              @click="uploadTip"
              >模板表头字段说明</el-button
            >
          </div>
        </div>
        <el-dialog
          :visible.sync="showTip"
          width="740px"
          :close-on-click-modal="false"
          title="时报模板说明">
          <div class="showtipWrap">
            <el-collapse v-model="activeNames">
              <el-collapse-item title="账户" name="1">
                <ul class="showtipStyle">
                  <li>日期</li><li>广告系列组ID</li><li>广告系列组名称</li><li>国家地区</li>
                  <li>余额</li><li>支出</li><li>曝光次数</li><li>点击次数</li><li>下载次数</li><li>新下载</li>
                  <li>重新下载</li><li>开启LAT的安装数</li><li>关闭LAT的安装数</li><li>点击率</li><li>下载率</li>
                  <li>ECPM均价</li><li>CPT均价</li><li>CPA均价</li>
                </ul>
              </el-collapse-item>
              <el-collapse-item title="广告系列" name="2">
                <ul class="showtipStyle">
                  <li>日期</li><li>广告系列组ID</li><li>广告系列组名称</li><li>国家地区</li>
                  <!-- <li>开始日期</li>
                  <li>结束日期</li>
                  <li>广告系列剩余天数</li> -->
                  <li>生命周期预算</li><li>广告系列ID</li><li>广告系列名称</li>
                  <li>广告展示位置</li><li>每日预算</li><li>支出</li><li>曝光次数</li>
                  <li>点击次数</li><li>下载次数</li><li>新下载</li>
                  <li>重新下载</li><li>开启LAT的安装数</li><li>关闭LAT的安装数</li>
                  <li>点击率</li><li>下载率</li><li>ECPM均价</li>
                  <li>CPT均价</li><li>CPA均价</li>
                </ul>
              </el-collapse-item>
              <el-collapse-item title="广告组" name="3">
                <ul class="showtipStyle">
                  <li>日期</li><li>广告系列组ID</li><li>广告系列组名称</li><li>国家地区</li>
                  <li>开始日期</li><li>结束日期</li><li>广告组ID</li><li>广告组名称</li>
                  <!-- <li>搜索匹配</li> -->
                  <li>支出</li><li>曝光次数</li><li>点击次数</li><li>下载次数</li>
                  <li>新下载</li><li>重新下载</li><li>开启LAT的安装数</li><li>关闭LAT的安装数</li><li>点击率</li><li>下载率</li>
                  <li>ECPM均价</li><li>CPT均价</li><li>CPA均价</li>
                </ul>
              </el-collapse-item>
              <el-collapse-item title="关键词" name="4">
                <ul class="showtipStyle">
                  <li>日期</li><li>广告系列组ID</li><li>广告系列组名称</li><li>国家地区</li>
                  <li>广告系列ID</li><li>广告组ID</li><li>广告组名称</li><li>关键词ID</li>
                  <li>关键词名称</li><li>匹配类型</li><li>搜索词</li><li>点击出价</li><li>建议出价范围</li>
                  <li>支出</li><li>曝光次数</li><li>点击次数</li><li>下载次数</li><li>新下载</li><li>重新下载</li>
                  <li>开启LAT的安装数</li><li>关闭LAT的安装数</li><li>点击率</li>
                  <li>下载率</li><li>CPT均价</li><li>CPA均价</li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </div>
          
          <div style="margin:20px auto;text-align:center;">
            <el-button slot="trigger" size="middle" style="margin-right:10px;" @click="showTip = false" type="primary">确 定</el-button>
          </div>
        </el-dialog>
        <div class="chanelTab">
          <span class="channel_vivo" :class="item.id==8?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=8&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>

        <div class="chanelTabSecondary">
          <span @click="goToTimes">时报</span>
          <span class="channeSelActive">日报</span>
        </div>

        <div class="content">
            <div class="materialHead">
              <div class="materialHBtns clearfix distribDialog">
                <el-form :inline="true" ref="ruleForm" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-input v-model = "dayName" clearable placeholder="请输入模板名称"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "adId" 
                    clearable           
                    placeholder="请输入广告主名称查找"></el-input>
                  </el-form-item>

                  <el-form-item label="">
                    <el-input v-model = "sendName" clearable placeholder="请输入接收人"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "copyName" clearable placeholder="请输入抄送人"></el-input>
                  </el-form-item>
                  
            <el-form-item label="">
              <el-select
                clearable
                placeholder="请选择报表开关状态"
                v-model="channelm"
              >
                <el-option label="开启报表状态" value="0">开</el-option>
                <el-option label="关闭报表状态" value="1">关</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="
                  listModelFn(page,dayName,adId,sendName,copyName,channelm)
                "
                >查询</el-button
              >
              <el-button type="info" size="small" @click="resetForm"
                >重置</el-button
              >
            </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="clearfix">
              <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :cell-class-name="tableCellClassName"
                @cell-click="clickcell">
                <el-table-column
                  width="170px"
                  show-overflow-tooltip
                  label="编号">
                  <template v-slot="scope">
                    {{ scope.row.id}}
                    <el-tooltip class="item" effect="dark"  placement="right-end" v-if="scope.row.fileSizeWarnStatus">
                      <div slot="content" v-html="scope.row.fileSizeWarnStatus == 1 ? '文件大小目前已超过10MB，请尽快处理！' : '文件大小目前已超过18MB，目前已进行强制停用！'"></div>
                      <i class="el-icon-warning" style="font-size: 14px" :style="{color: scope.row.fileSizeWarnStatus == 1 ? '#cbb54c' : '#b32e20'}"></i>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  width="120px"
                  prop="name"
                  show-overflow-tooltip
                  label="名称">
                </el-table-column>
                <el-table-column
                  width="120px"
                  prop="title"
                  show-overflow-tooltip
                  label="文件名">
                </el-table-column>
                <el-table-column
                  width="80px"
                  label="状态">
                  <template v-slot="scope">
                    <el-switch
                      v-model="scope.row.status"
                      class="switchStyle"
                      :active-value="0" 
                      :inactive-value="1"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      @change="getBrandList(scope.row, 'status')">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  width="90px"
                  label="Excel">
                  <template v-slot="scope">
                    <el-switch
                      v-model="scope.row.excelFlag"
                      class="switchStyle"
                      :active-value="1" 
                      :inactive-value="0"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      @change="getBrandList(scope.row, 'excelFlag')">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  width="80px"
                  label="图片">
                  <template v-slot="scope">
                    <el-switch
                      v-model="scope.row.imgFlag"
                      class="switchStyle"
                      :active-value="1" 
                      :inactive-value="0"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      @change="getBrandList(scope.row, 'imgFlag')">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  width="240px"
                  v-for="(item,index) in titleList" :key="index"
                  :prop="item.prop"
                  :label="item.name"
                  show-overflow-tooltip>
                  <!-- 抄送人 -->
                  <template v-if='item.prop=="copySenderName"' v-slot="scope">
                    <span v-if="scope.row.copySenderName == null">-</span>
                    <span v-else>{{scope.row.copySenderName}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="110px"
                  prop="fileSize"
                  show-overflow-tooltip
                  label="文件大小(kb)">
                </el-table-column>
                <el-table-column
                  width="200px"
                  show-overflow-tooltip
                  label="广告主名称">
                  <template v-slot="scope">
                    <span v-for="(obj,j) in scope.row.excelConfig">
                      <span v-for="val in obj">
                        <span v-for="items in val.owners">
                          {{items.ownerName}}
                        </span>
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column 
                  fixed = 'right'
                  width='340'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <!-- <el-button class="newBtn_garyLightBlue" data-type = 'infoBtn' plain size="mini">详情</el-button> -->
                    <el-button class="newBtn_darkRed" data-type = 'xiuGai' style="margin-left:2px;" plain size="mini">修改</el-button>
                    <el-button class="newBtn_darkRed" data-type = 'xiazai' style="margin-left:2px;" plain size="mini">下载</el-button>
                    <el-button class="newBtn_darkRed" data-type = 'bufa' style="margin-left:2px;" plain size="mini">补发</el-button>
                    <!-- <el-button class="newBtn_Yellow" data-type = 'sendBtn' plain size="mini">重发抄送人</el-button> -->
                    <el-dropdown @command="handleCommand($event,scope.row)">
                      <el-button class="el-dropdown-link newBtn_Yellow" data-type = 'sendBtn' plain size="mini">重发</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">全部发送</el-dropdown-item>
                        <el-dropdown-item command="2">接收人</el-dropdown-item>
                        <el-dropdown-item command="3">抄送人</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button class="newBtn_darkRed" data-type = 'zhuanrang' style="margin-left:2px;" plain size="mini">转让</el-button>
                    <el-popconfirm
                      title="您是否确认删除报表模板，删除后数据源中数据将不再累加，且无法恢复？"
                      @confirm="deletePlanFn(scope.row.id)">
                      <template #reference>
                        <el-button class="newBtn_orang" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              </div>
            </div>
            <!-- 转让 -->
            <el-dialog
              :visible.sync="transferenceDialog"
              width="300px"
              :close-on-click-modal="false"
              :showClose="false"
              title="转让">
              <el-select v-model="transferencePerson" @change="changeTrans" filterable placeholder="请选择转让人" >
                <el-option
                  v-for="(item,i) in warningPersonOption"
                  :key="i"
                  :label="item.string"
                  :value="item">
                </el-option>
              </el-select>
              <div class="transferenceBtns">
                <el-button type="primary" size="middle" @click='transferenceSure'>确定</el-button>
                <el-button size="middle" @click='cancleDialog'>取消</el-button>
              </div>
            </el-dialog>
             <!-- 补发 -->
             <el-dialog
              :visible.sync="reissueDialog"
              width="480px"
              :close-on-click-modal="false"
              :showClose="false"
              title="补发">
              <el-date-picker
                v-model="reissuevalue"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <div style="color: red; margin-top: 20px; line-height: 1.2">温馨提示：
                <div>由于数据补发时间较长，请您耐心等待，请勿重复补发，造成文件损坏！</div>
              </div>
              <div class="transferenceBtns">
                <el-button type="primary" size="middle" @click='reissueSure'>确定</el-button>
                <el-button size="middle" @click='canclereissueDialog'>取消</el-button>
              </div>
            </el-dialog>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleSizeChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
        </div>
      </template>
  </PocCardTable>
    <dayTimeDialog :pubVue = "pubVue" :visible.sync="dayTimeDialogIs" :path="path" :type = "type" :keywords="keywords" :keywordsIs="keywordsIs" :dataSource="dataSource" :dataSourceIs="dataSourceIs" :title="title" ref="dayTime"></dayTimeDialog>
    <dayInfoDialog :visible.sync="dayInfoDialogIs"  :type = "type" :title="title" ref="dayInfo"></dayInfoDialog>
    <dayTimeXDialog :pubVue = "pubVue" :visible.sync="dayTimeXDialogIs"  :type = "type" :title="title" ref="dayTimeXDialog" :keywordsIs="keywordsIs" :dataSourceIs="dataSourceIs"></dayTimeXDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import dayTimeDialog from '@/views/dialog/dayTimeAsa'
import dayTimeXDialog from '@/views/dialog/dayTimeXAsaDialog'
import dayInfoDialog from '@/views/dialog/dayInfoAsaDialog'

export default {
  name: 'Daily',
  components:{
    dayTimeDialog,
    dayInfoDialog,
    dayTimeXDialog

  },
  data() {
    return {
      pubVue:this,
      fileList:[],
      publicPath: process.env.VUE_APP_BASENAME,
      dayTimeDialogIs:false,
      dayInfoDialogIs:false,
      dayTimeXDialogIs:false,
      // tableData:[{
      //   "id": 10,
      //   "channelId": 3,
      //   "reportNum": "D1639036020322",
      //   "reportName": "9号-5",
      //   "isNeedYesterday": 1,
      //   "type": 1,
      //   "reportSwitch": 2,
      //   "time": null,
      //   "adType": 10,
      //   "title": "{时间}{渠道}{代理公司}你",
      //   "senderName": "OPPO-华北-杨洋-272",
      //   "sender": "272",
      //   "copyUserName": "亨利嘉业超级管理员,刘楚莨",
      //   "copyUser": "1,4",
      //   "excelConfig": "{\"dataSource\":{\"数据源\":[1000092281],\"数据源 (2)\":[1000092281]},\"keyword\":{\"关键词\":[1000092281]}}",
      //   "account": "HL-小鹿中医-商店/信息流",
      //   "path": "D:\\uploadSource\\1f363335-d196-4857-8e89-bbaa9ece7647.xlsx",
      //   "imgSwtich": 2,
      //   "excelSwitch": 2,
      //   "status": 2,
      //   "deptId": 298,
      //   "userId": 306
      // }],
      tableData:[],

      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数


      title:'日报',
      dataSource:[],
      keywords:[],
      dataSourceIs:false,
      keywordsIs:false,
      path:'',
      type:1,

      dayName:'',
      sendName:'',
      copyName:'',
      adId:'',
      channelm:'',
      titleList:[
        // {name:'编号',prop:'id'},
        // {name:'报表开关',prop:'excelFlag'},
        // {name:'模板名称',prop:'name'},
        // {name:'广告主名称',prop:'excelConfig'},
        {name:'接收人',prop:'senderName'},
        {name:'抄送人',prop:'copySenderName'}],
      myHeaders: {Authorization:''},
      accountSelectionListShow:true,//用户是否有权限
      channelTitleList:[],//权限名称列表
      showTip:false,
      activeNames:['1'],
      transferenceDialog:false,
      warnNine:[],
      warnEigth:[],
      warningPersonOption:[],
      transferencePerson:'',
      getIds:'',
      changeUserId:'',
      changeDeptId:'',
      reissueDialog:false,
      reissuevalue:[],
      reissueId:'',

    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptName = this.$sessionStorage.getItem(config.DEPTNAME) 
    this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    this.channelTitleList = this.$sessionStorage.getItem('channelTitleList') 
    this.channelTitleList!=undefined?this.accountSelectionListShow = true:this.accountSelectionListShow = false
    this.listModelFn()
    this.getFpyjList328()
    // this.getFpyjList329()
    this.getId()
  },
  methods: {
    
    //channel的tab切换
    clickChangeChannel(channelId){
      let channelNameList = {3:'authManagement.Daily',4:'VivoAccountManagement.DailyVivo',5:'MiAccountManagement.DailyMi',6:'HwAccountManagement.DailyHw'}
      this.$router.push({name:channelNameList[channelId]})

    },
    //获取分配人员列表
    getFpyjList328(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
        if(response.data.code == 200){
          that.warnEigth = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    getFpyjList329(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
        if(response.data.code == 200){
          that.warnNine = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    goFaq(){
      let route = this.$router.resolve({ path: '/auth-management/FaqVivo', query: {  id: 28,channelId:4 } });
      window.open(route.href, '_blank');  
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
      console.log(this.uploadId)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    // "reportId": [],
	  // "reportSwitch": 0
    //按钮开关
    getBrandList(row, name){
      let _this = this,params = {}
      params = {
        'userName':_this.userName,
        'userId':_this.userId,
        'deptId':_this.deptId,
        'id':row.id,
      }
      params[name] = row[name]
      $http.axios.post("/api/dataReport/reportModel/edit",params).then(res=> {
        if(res.data.code == 200){
          _this.listModelFn()
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
          row[name] = row[name] == 1 ? 0 : 1
        }
      }).catch(() => {
        row[name] = row[name] == 1 ? 0 : 1
      })
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,dataSource =[],keywords = []
      // let video = []
      console.log(response)
      if(response.code==200){
        that.dayTimeDialogIs = true
        if(response.data.dataSource.length !=0){
          that.dataSourceIs = true
        }
        if(response.data.kwDataSource.length !=0){
          that.keywordsIs = true
        }
        response.data.dataSource.forEach((element,i) => {
          dataSource.push({'sheetName':element,'timeZone': 'Asia'})
        });
        response.data.kwDataSource.forEach((element,i) => {
          keywords.push({'sheetName':element})
        });
        that.dataSource = dataSource
        that.keywords = keywords
        that.path = response.data.path
       
      }else{
        if(response.data == {}||response.data == null ||response.data == ''){
          that.$message({
            duration:0,
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }else{
          that.$message({
            duration:0,
            showClose: true,
            message: response.data,
            type: 'error'
          })
        }
        // that.$message({
        //   duration:0,
        //   showClose: true,
        //   message: response.data,
        //   type: 'error'
        // })
      }
       this.$refs.upload.clearFiles()
    },
    tableCellClassName({row, column, rowIndex, columnIndex}){
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index=rowIndex;
      column.index=columnIndex;
    },
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      console.log(row);
      console.log(column);
      console.log(cell);
      console.log(event);
      let yjType = event.target.parentNode.dataset.type
      if(yjType == "infoBtn"){
        this.dayInfoDialogIs = true
        this.$refs.dayInfo.getuserChannel(row.id)

      }else if(yjType == "xiuGai"){
        this.dayTimeXDialogIs = true
        this.$refs.dayTimeXDialog.getuserChannel(row.id)
      }else if(yjType == 'sendBtn'){
        // this.pushFn(row.id,row.reportNum)
      }else if(yjType == 'xiazai'){
        this.downLoad(row.id)
      }else if(yjType == 'zhuanrang'){//转让
        this.transference(row.id)
      }else if(yjType == 'bufa'){//补发
        this.reissue(row)
      }
      
    },
    //转让
    transference(id){
      this.getIds = id
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      console.log(this.warningPersonOption)
      this.transferenceDialog = true
    }, 
    //取消转让弹框
    cancleDialog(){
      this.warningPersonOption = ''
      this.transferenceDialog = false
    },
    changeTrans(val){
      console.log(val)
      this.changeUserId = val.userId
      this.changeDeptId = val.deptId
    },
    //确认转让弹框
    transferenceSure(){
      let _this = this,params = {}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'id':_this.getIds,
        'newUserId':_this.changeUserId,
        'newDeptId':_this.changeDeptId
      }
      $http.axios.post("/api/dataReport/reportModel/edit",params).then(res=> {
        if(res.data.code == 200){
          _this.transferenceDialog = false
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
          _this.listModelFn()
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //格式化时间 返回年月日 
    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = (val.getDate()-1) < 10 ? '0' + (val.getDate()-1) : (val.getDate()-1);
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //补发
    reissue(item){
      if (item.status !== 0) {
        this.$alert('请确认操作的模板状态为已开启！', '提示', {
          confirmButtonText: '确定',
          type:'warning',
        });
        return
      }
      this.reissueDialog = true
      this.reissueId = item.id
    let nowTimes = Date.now();
    let oldTimes = Date.now();
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//昨天的时间
    this.reissuevalue = [oldDate, nowDate];
    },
    //取消补发
    canclereissueDialog(){
      this.reissueDialog = false
    },
    //确定补发
    reissueSure(){
      let _this = this
      $http.axios.get("/api/dataReport/reportSend/manualReport?ids="+_this.reissueId+"&sDate="+_this.reissuevalue[0]+"&eDate="+_this.reissuevalue[1]).then(res=> {
        if(res.data.code == 200){
          _this.reissueDialog = false
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //下载
    downLoad(id){
      console.log(id)
      let _this = this
      $http.axios.get("/api/dataReport/reportModel/queryImgUrlById?id="+id).then(res=> {
        if(res.data.code == 200){
          window.location.href = res.data.data
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 重新发送抄送人
    handleCommand(e,row){
      console.log(e)
      console.log(row.id)

      let _this = this,params = {}
      params = {
        'userName':_this.userName,
        'userId':_this.userId,
        'deptId':_this.deptId,
        "type":Number(e),
        "ids":row.id
      }
      $http.axios.post("/api/dataReport/reportSend/rePush",params).then(res=> {
        if(res.data.code == 200){
          _this.$message({
            showClose: true,
            message: '已重新发送，请注意查收',
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // pushFn(id,reportNum){
    //   let _this = this
    //   $http.axios.get("/api/dataReport/model/push?id="+id+'&type=2').then(res=> {
    //     if(res.data.code == 200){
    //       _this.$message({
    //         showClose: true,
    //         message: reportNum+'已重新发送给抄送人，请注意查收',
    //         type: 'success'
    //       })
    //     }  else {
    //       _this.$message({
    //         showClose: true,
    //         message: res.data.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },
    // tab跳转
    goToDaily(){
      this.$router.replace('/auth-management/Daily')
    },
    // tab跳转
    goToTimes(){
      this.$router.replace('/auth-management/TimesAsa')
    },
    //点击修改进入修改页面
    editTimes(row){
      console.log(row)
      this.$router.push({name:'authManagement.TimesEdit',params:{id:row.id}})
    },

    //列表 /vivoReport/listModel
    listModelFn(page,dayName,adId,sendName,copyName,channelm){
      let _this = this,params = {}
      params = {
        'userName':_this.userName,
        'userId':_this.userId,
        'deptId':_this.deptId,
        'channelId':8,
        'type':_this.type,
        'pageIndex':page||1,
        'pageSize':10,
        'ownerName':adId,//查询广告主名称
        'name':dayName,//报表名称
        'status':channelm,//报表状态
        'senderName':sendName,//发送人姓名
        'copySenderName':copyName,//抄送人姓名
        
    }
      $http.axios.post("/api/dataReport/reportModel/listModel",params).then(res=> {
        if(res.data.code == 200){
          _this.tableData = res.data.data.infos
          _this.totalRow = res.data.data.pageInfo.rowCount//分页总条

        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //删除按钮
    deletePlanFn(e){
      let _this = this,params = {}
      params = {
        'userName':_this.userName,
        'userId':_this.userId,
        'deptId':_this.deptId,
        'id':e,
        'status':2
      }
      $http.axios.post("/api/dataReport/reportModel/edit",params).then(res=> {
        if(res.data.code == 200){
          _this.listModelFn()
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //头部表单重置
    resetForm() {
      this.dayName=''
      this.sendName=''
      this.copyName=''
      this.adId=''
      this.channelm=''
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    //清空自定义列弹框选中项
    clearWrap(){
      this.checkedCities = []
      this.handCheckList = []
    },
   // 分页
    handleSizeChange(val) {
      
      this.findAll(val)
    },
    
    //分页方法
    findAll (val) {
      //获取数据接口（调用封装的接口）
      this.listModelFn(val)
    },

    //模板说明
    uploadTip(){
      this.showTip = true
    },


  }
}
</script>

<style lang="scss" scoped>
  .daily_library{
    margin-bottom:20px;
  }
  .daily_library span{
    padding-right:20px;
    font-size:14px;
    cursor: pointer;
  }
  .daily_library .timesTab{
    font-size:16px;
    font-weight:700;
    color:#6e7c7c;
  }
  .MBOTTOM{
    margin: 10px 0;
  }
  .upload-demo{
    float: left;
  }
  #app>a{
    line-height: 30px;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#66856d;
    border-bottom:2px solid #66856d;
  }


  /*新新新*/
  .chanelTabSecondary{
    margin-bottom:20px;
  }
  .chanelTabSecondary span{
    display: inline-block;
    vertical-align: middle;
    font-size:12px;
    padding:0 20px;
    height:30px;
    line-height:30px;
    background:#ECF3F0;
    color:#82A69A;
    margin-right:20px;
    cursor: pointer;
    border-radius: 30px;
  }
  .chanelTabSecondary span:hover,.chanelTabSecondary span.channeSelActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:170px;
    background:#F5F7F6;
    border:none;
  }
  .hl_headerRight,.hl_headerLeft{
    display: inline-block;
    vertical-align: middle;
  }
  .hl_headerRight{
    text-align:right;
    width:50%;
  }
  .hl_headerLeft{
    width:48%;
  }
  .showtipWrap{
    padding:0 20px;
    box-sizing: border-box;
  }
  .showtipStyle{
    font-size:0;
  }
  .showtipStyle li {
    font-size:12px;
    display: inline-block;
    vertical-align: middle;
    width:120px;
    height:30px;
    line-height: 30px;
    border:1px solid #ddd;
    text-align:center;
  }
  .transferenceBtns{
    margin:20px auto 10px;
    text-align:center;
  }
</style>
