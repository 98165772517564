<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>广告计划</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-dropdown trigger="click" @command = "changeStatusAll">
                <el-button type="primary" size="small" @click="updateSet('modifyState')">修改状态</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='item.openId'>{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" size="small" @click="updateSet('startStop')">批量启停设置</el-button>
              <el-button type="primary" size="small" @click="goPlan('/yybAccount-management/StartStopYyb')">启停计划列表页面</el-button>
            </div>
          </div>  

          <div class="chanelTab">
            <span class="channel_vivo channelActive" >计划</span>
            <span class="channel_vivo" @click="clickChangeChannel('advertYyb')">广告</span>
          </div>
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="ruleForm" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "kw" clearable placeholder="请输入计划名称"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select v-model="taskStatus" clearable placeholder="请选择计划状态" >
                      <el-option
                        v-for="(item,i) in taskStatusOptions"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "getListFn(page)">查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
            </div>
            <div class="clearfix">             
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  style=" cursor:pointer;"
                  :data="tableData"
                  tooltip-effect="dark"
                  @cell-click="clickcell"
                  :cell-style="setRowStyle"
                  @selection-change='handleSelectionChange'
                  >
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <template >
                    <el-table-column
                    v-for="(item, index) in titleList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :sortable = "item.prop == 'bid'||item.prop == 'viewSumFormat'||item.prop == 'clickSumFormat'||item.prop == 'downloadSumFormat'||item.prop == 'accurateCostFormat'||item.prop == 'ctrFormat'||item.prop == 'cpcFormat'||item.prop == 'downloadRatioFormat'||item.prop == 'costPerDownloadFormat'||item.prop == 'ecpmFormat'?true:false"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                          <!-- <div v-else :disabled = "scope.row.disabledIs"> -->
                        <div v-else>
                          <span v-if=' scope.column.property =="openStopPlanIds"'>
                            {{ showStatusList[scope.column.property][Number(Boolean(scope.row[scope.column.property]))] }}
                            <i v-if = 'scope.column.property =="openStopPlanIds"'
                              :class = "{'isSwitch':scope.column.property =='openStopPlanIds','active':(scope.column.property =='openStopPlanIds')&&scope.row[scope.column.property]!=0}"></i>
                          </span>
                          <span v-else-if='item.prop=="statusTypeIs"'>
                            <template>
                              <el-switch
                                v-model="scope.row.statusTypeIs"
                                class="switchStyle"
                                :active-value="1"  
                                :inactive-value="0"
                                active-color="#48916F"
                                inactive-color="#EAEFED"
                                @change = "changeStatusOnly(scope.row.statusTypeIs,scope)">
                              </el-switch>
                            </template>
                          </span>
                          <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </div> 
                      </template>
                    </el-table-column>
                  </template>
                  
                </el-table>
              </div>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>

            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :planName = "planName" :pubVue="pubVue" :command = "command+''" :scope = "scope" :typeName = "'plan'"></alertStartStopDialog>
      <startStopSetDialog :visible.sync="startStopSetDialog" :objIdType = 1 :userContent = 'userContent' :yulanList = "yulanList" :page = "page" :pubVue="pubVue"></startStopSetDialog>
      <startStopDialog ref = 'startStopDialogIs' :visible.sync="startStopDialog" :page = "page" :pubVue="pubVue"></startStopDialog>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import startStopSetDialog from '@/views/dialog/startStopSetAppDialog'
  import startStopDialog from '@/views/dialog/startStopAppDialog'//列表->启停计划->是：点击查看启停详情列表弹框
  import alertStartStopDialog from '@/views/dialog/alertStartStopHwDialog'
  import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')

  export default {
    components: {
      startStopSetDialog,//批量启停设置
      startStopDialog,//启停计划列表弹框
      alertStartStopDialog,
    },
    props:{
      newVue:Object,
    },
    data() {
      return {
        pubVue: this,//空vue，用于子组件之间传值
        startStopSetDialog:false,
        startStopDialog:false,
        alertStartStopDialogIs:false,
        modifyState:false,//修改状态下拉列表是否展示
        warningOpenList:[],//['开启','关闭']
        titleList:[
          {name:'推广计划名称',prop:'adPlanName'},
          {name:'状态',prop:'status'},
          {name:'下载率',prop:'downloadRate'},
          {name:'曝光量',prop:'view'},
          {name:'APP下载完成量',prop:'download'},

          {name:'投放开关',prop:'statusTypeIs'},//启停状态 ON-启动 SUSPEND-暂停 (0-未暂停 1-已暂停)
          {name:'启停计划',prop:'openStopPlanIds'},//是否启停启停:1-是 0-否
        ],
        showStatusList:{
          openStopPlanIds:['否','是'],//启停
        },
        planName:'',//请输入计划名称查找
        kw:'',//请输入计划名称/计划ID查找
        tableData:[],
       
        value:'',//批量操作绑定值
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        userId:'',//用户ID
        userName:'',//用户名
        deptId:'',//
        select:false,//判断是否选中列表中的任务
        userContent:{},//登录用户信息:用户名-userName;用户ID-userId;账户名-ownerName;账户ID-ownerId;部门ID-deptId
        adPlanIdList:[],
        closePlanList:[],//校验是否有存在的启停计划
        command:'',
        scope:{},
        validDate:null,//当前时间
        value1: [formatDate(new Date()),formatDate(new Date())],//时间
        taskStatus:'',//状态
        taskStatusOptions:[{
          value:'AD_STATUS_NORMAL',
          label:'有效'
        },{
          value:'AD_STATUS_SUSPEND',
          label:'暂停'
        }],
        yulanList : [],//批量启停页面adPlanId,adPlanName信息

        DN:'yyb'//域名:Domain Name的缩写
      }
    },
   
    mounted() {
      //用户ID
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      //用户名
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      //部门ID
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      //账户ID

      this.userContent.userId = this.userId
      this.userContent.userName = this.userName
      this.userContent.deptId = this.deptId
      this.userContent.ownerId = this.$route.query.id
      this.userContent.ownerName = this.$route.query.name
      
      this.getListFn()

    },
    methods: {
      
      //tab切换
      clickChangeChannel(url){
        this.$router.push({
          path: '/YybAccount-management/'+url,
          query: {
            id: this.userContent.ownerId,
            name:this.userContent.ownerName,
          }
        })
      },
      // 页面跳转
      goPlan(url,query){//
        let _this = this,query1 =  {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName}
        query = Object.assign({},_this.clone(query), query1)
        this.$router.push({
          path: url,
          query: query
        })
      },
      setRowStyle(row, column, rowIndex, columnIndex) {
        if(row.columnIndex == 1){
          return 'color: #2F674D' 
        }else{
          return 'color: #606266' 
        }
      },
      
      // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
      clickcell(row, column, cell, event){
        this.adPlanIdList = [{'adPlanId':row.adPlanId,'status':row.status}]
        if(column.property=="openStopPlanIds"){//openStopPlanIds//列表->启停计划->是：点击查看启停详情列表弹框
          this.ssDetailParams = {}
          if(row.openStopPlanIds != 0){
            this.ssDetailParams={userId:this.userId,
                                userName:this.userName,
                                deptId:this.deptId,
                                pageIndex:1,
                                pageSize:50,
                                planIds:row.openStopPlanIds.toString()}
            this.startStopDialog = true
            this.$refs.startStopDialogIs.timeSlotDetail(this.ssDetailParams,'yyb')
          }
        }

      }, 
       // 分页
      handleSizeChange(val) {
        let _this = this
        _this.pageCount = val
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        this.findAll(val)
      },
      //分页方法
      findAll (page) {
        //获取数据接口（调用封装的接口）
        this.page = page
        this.getListFn(page)
      },
     
      // 选择行
      handleSelectionChange(val,row){
        let _this = this
        let  yulanList = [],adPlanIdList = [],objList = [],numStr =0 ,num = 0
        
        val.forEach((obj,i,arr)=>{
          let cur = obj.statusTypeIs
          adPlanIdList.push({'adPlanId':obj.adPlanId,'status':obj.status})

          
          if(obj.budget =='不限'){
            numStr++
          }else{
            num++
          }
          yulanList.push({ 'ownerId':this.userContent.ownerId,'adPlanName':obj.adPlanName,'adPlanId':obj.adPlanId})//upPriceStatus:调价状态;status2:计划状态
          objList[cur] = cur
        })
        _this.adPlanIdList = adPlanIdList
        if(val.length == 0){
          _this.warningOpenList = []
          _this.modifyState = false
        }else{
          _this.modifyState = true
        }
        this.yulanList = yulanList
      },
      
      //重置
      resetForm(){
        let _this = this
        _this.value1 = [formatDate(new Date()),formatDate(new Date())],//时间
        _this.taskStatus = ''//计划状态
        _this.kw = ''//计划名称
        _this.getListFn()
        // _this.$router.go(0)
      },

      //任务列表
      getListFn(page){
        //获取查询有权限的所有帐号接口
        let _this = this,params,pagetTxt,listData
        pagetTxt = {
          //必填项
          'pageSize' : _this.pageCount,
          'pageIndex' : page || 1,
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.userContent.ownerId,
          // 搜索条件
          'name':_this.kw,//计划名称
          'configStatus':_this.taskStatus,//计划状态
        }
        if(_this.value1 != undefined&&_this.value1 != []){
          pagetTxt.sDate = _this.value1[0]
          pagetTxt.eDate = _this.value1[1]
        }
        
        params = Object.assign({},_this.clone(_this.userContent), pagetTxt)//之前是params，因为里面有groupId所以换成了userContent
        $http.axios.post("/api/"+_this.DN+"/adPlan/list",params).then(res=> {
          if(res.data.code==200){
            listData = res.data.data//列表数据
            if(listData.infos.length != 0){
              // 数据修改，余额、消耗、日预算初始值如果为空的状态
              listData.infos.forEach((obj,i) => {     
                if(obj.status=="AD_STATUS_NORMAL"){
                  _this.$set(obj,'status','开启中')
                  // 启停状态 ON-启动 SUSPEND-暂停
                  _this.$set(obj,'statusTypeIs',1)

                }else if((obj.status=="AD_STATUS_SUSPEND")){
                  _this.$set(obj,'status','暂停中')
                  _this.$set(obj,'statusTypeIs',0)

                }
            });
           
            _this.tableData = listData.infos
            _this.totalRow = listData.pageInfo.rowCount
            }else{
              _this.tableData = []
              _this.totalRow = 0
            }
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })    
          }

        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })

      },
      //行内状态修改
      changeStatusOnly(value,scope){
        let _this = this,DN = this.DN
        //类型（1-广告计划 3-广告）
        //先把值赋为原来的值，保证swich状态不变
        this.tableData[scope.$index].statusTypeIs = value == 0?1:0//需求更改暂时注释
        $http.axios.get("/api/"+DN+"/openStop/valid?ids="+scope.row.adPlanId+"&type=1").then(res=> {
          if(res.data.code==200){
            let response = res.data
           if(response.data!= null){
              _this.planName = response.data
              _this.command = value
              _this.scope = scope
              _this.alertStartStopDialogIs = true
              _this.validDate = formatDate(new Date())
           }else{
            this.tableData[scope.$index].statusTypeIs = value
            this.updateStatusFn(scope.row.statusTypeIs,scope,'继续')//需求更改暂时打开
           }
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //批量修改状态
      changeStatusAll(command){
        let _this = this,planName = [],text = ''
          _this.closePlanList.forEach((obj,i)=>{
              planName.push(obj)
          })
          if(planName.length>0){
            _this.planName = planName.join(',')
            _this.command = command
            _this.alertStartStopDialogIs = true     
          }else{
            this.updateStatusFn(command,'','继续')
          }
      },  
       //计划状态设置
       updateStatusFn(command,scope,status){
        let commandType ,adPlanIdList = [],DN = this.DN
        Number(command)?commandType="ON":commandType="SUSPEND"
        let _this = this,type,params,planIdsString,msg
        _this.adPlanIdList.forEach(obj=>{
          adPlanIdList.push(obj.adPlanId)
        })
        planIdsString = adPlanIdList.join(',')
        if(status == '继续'){
          params = {
            'ownerId':this.$route.query.id,
            'adPlanIds':planIdsString,
            'status':commandType,
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'validDate':new Date().getTime()//当前时间
          }
        }else{
          params = {
            'ownerId':this.$route.query.id,
            'adPlanIds':planIdsString,
            'status':commandType,
            'extendFlag':1,//不继续的时候传此参数
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'validDate':new Date().getTime()//当前时间
          }      
        }
        $http.axios.post("/api/"+DN+"/adPlan/batch/openStop",params).then(res=> {
          _this.warningOpenList = []
          _this.modifyState = false
          _this.$refs.multipleTable.clearSelection();          
          _this.adPlanIdList = []
          if(res.data.code==200){
            let messageContent =res.data.msg
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'success'
            }) 

          }else{
            if(res.data.data!=null){
              errorNum = eval('(' + res.data.data.failedTasks + ')').length
              totalNum = res.data.data.subTaskIds.split(',').length
              successNum = totalNum-errorNum
              if(errorNum>0&&errorNum==1){
                _this.open('<p>操作失败</p><p>您所选择的任务，当前任务状态下不支持启用。</p>')
              }else{
                _this.open('<p>批量处理任务结果</p><p>成功：'+successNum+'，失败：'+errorNum+'</p><p>操作失败的原因是由于您所选择的任务，当前任务状态下不支持启用。</p>')
              }
            }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type:  'error'
              })
            }
          }
          _this.getListFn(_this.page)

        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
       // 批量启停设置
       updateSet(type){
        let _this = this
        let adPlanIdList = [],statusList = []//声明一个空数组
        _this.adPlanIdList.forEach(function(v,i,arr){
          let bool = arr.indexOf(v.adPlanId,i+1)
          if(bool === -1){
            adPlanIdList.push(v.adPlanId)
          }
          if(v.status != '执行'&&v.status != '暂停'){
            statusList.push(v.status)
          }
        })
        if(_this.adPlanIdList.length!=0){
          _this.warningOpenList = []
          
          _this.btnIsFn(type,adPlanIdList)
          return true
        }else{
          _this.$message({
              showClose: true,
              message: "请勾选数据",
              type: 'error'
            })
          }
          
      },
      // 按钮判断
      btnIsFn(type,adPlanIdList){
      let _this = this
      if(type == 'startStop'){
        _this.startStopSetDialog = true

      }else if(type == 'modifyState'&&_this.modifyState){
          _this.checkOpenCloseFn(adPlanIdList)
          _this.warningOpenList = [{name:'开启',openId:1},{name:'关闭',openId:0}]
      }
    },
    //校验当日是否存在启停计划
    checkOpenCloseFn(adPlanIdList){
      adPlanIdList=adPlanIdList.join(',')
        let _this = this,planName,DN = this.DN,response
        _this.closePlanList = []
        $http.axios.get("/api/"+DN+"/openStop/valid?ids="+adPlanIdList+"&type=1").then(res=> {
        if(res.data.code==200){
          response = res.data
          if(response.data!= null){
            _this.planName = response.data
            _this.validDate = formatDate(new Date())
            planName = response.data.split(',')
            planName.forEach(obj=>{
              _this.closePlanList.push(obj)
            })
          }else{
          _this.closePlanList = []
          }
        }
      }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
    }
  }
  </script>
  <style lang="scss" scoped>
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */
     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
      font-style: initial;
    }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */
    .MBOTTOM{
      margin-bottom:10px;
    }
    .el-button--small{
      margin-left: 10px;
    }
    .line.el-col.el-col-2{
      text-align: center;
    }
    .isSwitch{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background-color: #ccc;
      vertical-align:middle;
      margin-left: 5px;
    }
    .active{
      background-color: #13ce66;
    }
    #uploadBtn{
      float: right;
    }
    .el-button.el-button--primary{
      margin: 0 10px 0 0!important;
    }
    .el-table .el-table__row {
      cursor: pointer!important;
    }
    /* tab切换 */
    .daily_library{
      margin-bottom:20px;
    }
    .daily_library span{
      padding-right:20px;
      font-size:14px;
      cursor: pointer;
    }
    .daily_library .timesTab{
      font-size:16px;
      font-weight:700;
      color:#6e7c7c;
    }
    .adTeamEditBtn{
      padding-left:10px;
      color:#333;
      cursor: pointer;
      display:none;
    }
    .el-table .el-table__body tr:hover td .adTeamEditBtn{
      display:inline-block;
    }
    .editDayLimit{
      width:80%;
      margin:0 auto;
    }
    .editDayLimit .editDayLimitText,.editDayLimit span{
      display: inline-block;
      vertical-align:middle;
    }
    .editDayLimit .editDayLimitText{
      width:80%;
    }
    .editDayLimit span{
      width:18%;
      margin-left:1%;
    }
    .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .statusOtherBtnwei{
      padding:3px 8px;
      background:rgba(230,162,60,.1);
      color:#E6A23C;
    }
    .uploadIcon{
      font-size:40px;
      display:block;
      text-align:center;
    }
    /* tab切换当前样式 */
    .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }
  .warningIcon{
    font-size:14px;
  }
  /* ::v-deep为深度操作符，可以穿透到子组件*/
  
  ::v-deep .el-table {
    display: flex;
    flex-direction: column;
  }
   /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  </style>
