<template>
  <el-dialog
    :visible.sync="show"
    width="400px"
    :close-on-click-modal="false"
    :show-close = "false"
    center
    class="account-detail-dialog el-dialog__titles"
    @close = 'onCancel'
    :title="title">
    <div class="dialogIcon"><img :src='accountPrice'></div>
    <el-form
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules">
      <el-form-item v-if="!checked" label="当日是否继续执行">
        <el-switch
          v-model="nowIsValue"
          active-value="0"  
          inactive-value="1"
          active-color="#48916F"
          inactive-color="#EAEFED">
        </el-switch>
      </el-form-item>
      <div class="accountList">
        <el-tooltip :content="'Switch value: ' + switchValue" placement="top">
          <el-form-item :label="label" prop="">
            <el-switch
              v-model="switchValue"
              active-color="#48916F"
              inactive-color="#EAEFED"
              active-value="0"
              inactive-value="1">
            </el-switch>
          </el-form-item>
        </el-tooltip>
      </div>
      <div class="accountList">
        <el-form-item label="最高值" prop="maxNum" label-width="50px">
          <el-input
            v-model="formData.maxNum" 
            placeholder="请输入限制金额"   
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            @blur="salaryChange(formData.maxNum,$event)"
            clearable>
          </el-input>
        </el-form-item>
      </div>
      <div class="accountList">
        <el-form-item label="最低值" prop="minNum" label-width="50px">
          <el-input
            v-model="formData.minNum" 
            placeholder="请输入限制金额"   
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            @blur="salaryChange(formData.minNum,$event)"
            clearable>
          </el-input>
        </el-form-item>  
      </div>
    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <!-- {{$t('dc:取消保存')}} -->
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import accountPrice from '@/assets/img/accountPrice.png';
  import { $http } from '@/api/http';
  import Qs from 'qs';
export default {
  
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    nowIs:Boolean,
    pubVue:Object,
    // type: Number,
    // ownerId:Number,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      accountPrice,
      nowIsValue:'1',
      formData: {
          maxNum: '',
          minNum: '',
        },
      rules: {
        maxNum: [
          { required: true, message: '请输入最高值', trigger: 'blur' },
        ],
        minNum: [
          { required: true, message: '请输入最低值', trigger: 'blur' }
        ],
        
      },
      title:'',//标题
      label:'',//预警设置label文案
      titleList:['vivo账户余额','vivo账户今日消耗','vivo账户日预算'],
      // optionsm:{channelList:[{text:'oppo',id:'0'},{text:'vivo',id:'2'},{text:'应用宝',id:'3'},{text:'华为',id:'4'},{text:'小米',id:'5'},{text:'今日头条',id:'6'}]},//选择状态
      optionsm:{},//部门名称
      loading: false,
      show: this.visible,
      currentTypeConfig: {},
      channel:'',
      switchValue:'1',
      setData:{},//回显值
      setPriceCommon:{
        "ownerId": 0,
        "ownerName": "",
        "type": 0,
        "userId": 0,
        "userName": "",
        "deptId": 0,
      },//addAccountBalanceSet公共入参
      accounParams:{
        "moneySwitch": 0,
        "warningMoneyLow": 0,
	      "warningMoneyTop": 0
      },//addAccountBalanceSet入参账户部分
      costParams:{
        "costSwitch": 0,
        "costLow": 0,
        "costTop": 0,
      },//addAccountBalanceSet入参消耗部分
      dayParams:{
        "budgetSwitch": 0,
        "dayBudgetLow": 0,
        "dayBudgetTop": 0,
      },//addAccountBalanceSet入参日预算部分
      setP:[['moneySwitch','warningMoneyTop','warningMoneyLow'],
            ['costSwitch','costTop','costLow'],
            ['budgetSwitch','dayBudgetTop','dayBudgetLow']],//添加接口的入参名整理
      // addOrSet:false,//判断是添加还是修改
      infoId:'',//详情接口的id
      accDayBudget:'',//设置日预算
      dayDisabled:true,//日预算是否可操作
      checked:true,
      upCode:'',
      addCode:'',
      setCode:'',
      // formType:null,//区分账户余额，消耗，日预算
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
  },
  methods: {
    
    onConfirm(formName) {
      let _this = this
      _this.setPriceCommon.userId = _this.userContent.userId
      _this.setPriceCommon.userName = _this.userContent.userName
      _this.setPriceCommon.deptId = _this.userContent.deptId
      _this.setPriceCommon.ownerName = _this.userContent.ownerName
      _this.setPriceCommon.ownerId = _this.userContent.ownerId
      _this.setPriceCommon.type = _this.userContent.type
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(_this.setPriceCommon.type == 3){
            if(_this.checked || (!_this.checked&&_this.accDayBudget!='')){
              if(parseInt(_this.accDayBudget)<200){
              let messageContent ='日预算金额不能小于200'
                _this.$message({
                  showClose: true,
                  message: messageContent,
                  type: 'error'
                })
            } else{
              _this.addOrSetFn(_this.setPriceCommon.type,_this.infoId)
            }
            }else{
              let messageContent = '日预算额不能为空'
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }

                   
          }else{
            _this.addOrSetFn(_this.setPriceCommon.type,_this.infoId)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
      
    },
    onCancel() {
      this.show= false;
      this.formData={maxNum : '',
      minNum : ''}
      this.switchValue = '1'

    },
    addOrSetFn(type){
      let _this = this,params ,params1,paramsArrA 
      if(Number(_this.formData.maxNum)<Number(_this.formData.minNum)||Number(_this.formData.maxNum)==Number(_this.formData.minNum)){
        let messageContent = '请调整最高值，最高值不能<或=最低值'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })
      }else{
        paramsArrA = _this.setP[type-1]
        params1={[paramsArrA[0]]: parseInt(_this.switchValue),[paramsArrA[1]] : _this.formData.maxNum,[paramsArrA[2]] : _this.formData.minNum}
        params = Object.assign({},_this.clone(_this.setPriceCommon), params1)
        console.log(params)
        console.log(_this.setPriceCommon)
        console.log(params1)
        // if(_this.addOrSet){
          _this.addAccountBalanceSetFn(params)
        // }else{
        //   _this.upAccountBalanceSetFn(params,_this.infoId)
        // }
        if(type == 3&&!_this.checked){
          _this.setAccDayBudgetFn(Number(!_this.nowIsValue))
        }
      }
    },
    //最高最低值改变
    salaryChange(num,e){
        this[num] = e.target.value
        console.log(num,e)
    },
    // 获取部门名称列表***后台回显页面先不写
    getNameList(){
      let _this = this
      $http.axios.get("/api/system/dept/list").then(res=> {
        if(res.data.code==200){
          console.log(res)
        }
          

      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //获取账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)的设置
    setDialogContentFn(ownerIdList,type){
      let _this = this
      this.title = this.titleList[type-1]
      this.label = "设置" + this.titleList[type-1] + "预警"

      $http.axios.post("/api/vivo/account/v1/getAccountBalanceSetDetail",{'ownerId':ownerIdList,'type':type}).then(res=> {
        if(res.data.code==200){
          if(res.data.data != null){
            _this.setData = res.data.data
            _this.infoId = _this.setData.id
            // _this.setData.top == null? _this.formData.maxNum = _this.setData.top :  _this.formData.maxNum =  _this.setData.top/100000
            // _this.setData.low == null? _this.formData.minNum = _this.setData.low :  _this.formData.minNum =  _this.setData.low/100000
            _this.setData.top == null? _this.formData.maxNum = _this.setData.top :  _this.formData.maxNum =  _this.setData.top
            _this.setData.low == null? _this.formData.minNum = _this.setData.low :  _this.formData.minNum =  _this.setData.low
          // if(_this.setData.top == null && _this.setData.low == null){
            // _this.addOrSet = false
          // }else{
            // _this.addOrSet = false
          // }
           res.data.data.objectSwitch==null?_this.switchValue = '1':_this.switchValue=res.data.data.objectSwitch.toString() 
            // _this.userContent.switchValue = _this.switchValue = _this.setData.objectSwitch.toString() 
            // _this.setData.objectSwitch=_this.setData.objectSwitch.toString()          
            }else{
          // _this.addOrSet = true
        }
         
        }
        console.log(_this.userContent.switchValue)
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //添加账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)的设置
    addAccountBalanceSetFn(params){
      let _this = this
      $http.axios.post("/api/vivo/account/v1/addAndUpdtateAccountBalanceSet",params).then(res=> {
          if(res.data.code==200){
            _this.pubVue.$refs.multipleTable.clearSelection();
            // _this.pubVue.getAccountPlanListFn()
            // _this.pubVue.adPlanIdList = []
            if(params.type == 3){
              if(_this.checked){
                _this.show= false;
              }
            }else{
              _this.show= false;
            }
            let messageContent =res.data.msg|| '添加成功'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'success'
            })
            _this.onCancel()
          
        }else{
          let messageContent =res.data.msg|| '添加失败'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //修改账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)的设置
    upAccountBalanceSetFn(params,id,status){
      let _this = this
      params.id = id
      // params.status = status
      $http.axios.post("/api/vivo/account/v1/upAccountBalanceSet",params).then(res=> {
        if(res.data.code==200){
          // _this.show= false;
          if(params.type == 3){
            if(_this.checked){
              _this.show= false;
            }
          }else{
            _this.show= false;
          }
          let messageContent =res.data.msg|| '修改成功'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'success'
          })
            _this.onCancel()
        }else{
          let messageContent =res.data.msg|| '修改失败'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
    //重置
    resetFormFn(){
      _this.$refs.formData.resetFields();//重置表单
    },
    
    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
/* .el-checkbox{
  margin: 15px 0;
} */
.el-form-item.el-tooltip.is-no-asterisk.el-form-item--mini .el-form-item__content{
  text-align: left!important;
  margin: 0;
}
.el-checkbox.is-checked,.el-checkbox{
  float: left;
}
.el-form-item__content .nowLimit{
    display: inline-block;
    text-align: right;
    width: 150px;
    font-size: 12px;
    margin-right: 10px;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
.account-detail-dialog .el-dialog__titles{
  font-size:18px;
  color:#000;
  font-weight:500!important;
}
.accountList{
  margin:10px 0;
  background:#F5F7F6;
  border-radius: 4px;
  padding:6px 12px;
  box-sizing: border-box;
}
.el-form-item--mini.el-form-item{
  margin:0;
}
::v-deep .accountList .el-input--mini .el-input__inner{
  background:#F5F7F6;
  border:none;
}
</style>

