/**
 * 辅助table，简化操作
 */
export default {
  name: 'TableMixin',

  data() {
    return {
      // loading状态
      loading: false,
      // 需要过滤的条件，在实际组件中需要根据实际情况进行覆盖
      filterConfig: {},
      // 分页配置
      pageConfig: {
        sort: [],
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    };
  },

  computed: {
    currentPage: {
      get() {
        return this.pageConfig.pageNum;
      },
      set(n) {
        this.pageConfig.pageNum = n;
      }
    }
  },

  methods: {
    /**
     * 切换每页最大数
     */
    pageSizeChange(param) {
      this.pageConfig.pageSize = param;
      this.pageConfig.currentPage = 1;
      this.queryApi();
    },
    /**
     * 切换页
     */
    pageChange() {
      this.queryApi();
    },
    /**
     * 排序监听,目前只支持一个排序
     */
    sortChange() {
      // 目前后端只支持一个排序条件
      this.pageConfig.sort = [];
      this.pageConfig.currentPage = 1;
      this.queryApi();
    },
    /**
     * 使用该mixin的组件需要实现该方法做table的基础查询
     */
    queryApi() {}
  }
};
