<template>
  <el-dialog
    :visible.sync="show"
    width="50%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="日预算"
  >
    <el-form
    style="margin:0 auto;margin-left:10px;"
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData"
    >
      <el-button type="primary" size="small" @click = "noLimitFn(false)" >不限制</el-button>
      <el-button type="primary" size="small" @click = "noLimitFn(true)"  >自定义</el-button>
      <el-form-item :rules="numberlimitM" prop="scan_limit" v-if='inputBtn'>
        <el-input clearable v-model="formData.scan_limit" placeholder="请输入日预算" @blur="numIn"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('formData')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';

export default {
  

  props: {
    pubVue: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    },
    yulanList:Array,
    limitIs:Boolean,
    // type: Number,
    // ownerId:Number,
    userContent:Object,//登录用户信息
    
    max: Number
  },
  data() {
    return {
      formData: {
        scan_limit:'',
        },
      rules: {
      maxNum: [
        { required: true, message: '请输入最高值', trigger: 'blur' },
      ],
      minNum: [
        { required: true, message: '请输入最低值', trigger: 'blur' }
      ]},
      show: this.visible,
      loading: false,
      numberlimitM: { validator: this.checkMoneyM, trigger: 'change' },
      tableData:[],
      numInArr:[],
      inputBtn:false,
      dayLimit:0,

    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
     this.pubVue.$on('SHOW_DIALOG', data => {
      console.log('方法监听', data)
      this.show = data.showDialog
    })
    
  },
  methods: {
    checkMoneyM (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    },
    numIn(){
       let _this = this,val = _this.formData.scan_limit
        _this.numInArr = []
        _this.numInArr.push(Object.assign({},{num:val+'.00',sendPrice:val}))
      console.log(_this.tableData)
    },
    noLimitFn(data){
      let _this = this
      if(data){
        _this.inputBtn = true
        _this.dayLimit = 0
      }else{
        _this.inputBtn = false
        _this.dayLimit = 1
      }
    },
    onConfirm(formName) {
      let _this = this
       _this.$refs[formName].validate((valid) => {
          if (valid&&Number(_this.formData.scan_limit)>200) {
              console.log('成功2')
              console.log('选中')
              _this.updateOppoDayBudgetThreeFn(_this.dayLimit)
    
          } else {
             _this.$message({
              showClose: true,
              message: '请查看网络，稍后再试',
              type: 'error'
            })
            return false;
          }
        });
    },
    onCancel() {
      this.show= false;
      this.formData.scan_limit = ''
      this.tableData = []
      this.numInArr = []
      this.value1 = []

      console.log('关闭')
    },
    addOrSetFn(type){
      
    },
    
    //保存-立即执行接口	"dayBudget": 0,日预算 (dayLimit=1 时dayBudget必填 单位（分）)
                  // "dayLimit": "",0 不限制 1 限制
                  // "deptId": 0,
                  // "ownerId": 0,
                  // 'planName':''
                  // "planIds": [],
                  // "userId": 0,
                  // "userName": ""
    updateOppoDayBudgetThreeFn(dayLimit){
    // /adPlan/v1/updateOppoDayBudgetThree
    let _this = this,params = []
      params.push({'dayBudget':Number(_this.formData.scan_limit)*100,'dayLimit':dayLimit,
      'deptId':_this.userContent.deptId,'ownerId':_this.userContent.ownerId,
      'planName':_this.yulanList.taskName,'planId':_this.yulanList.taskId,'userId':_this.userContent.userId,'userName':_this.userContent.userName})


    $http.axios.post("/api/advertisement/adPlan/v1/updateOppoDayBudgetThree",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.pubVue.$refs.multipleTable.clearSelection();
          _this.pubVue.getAccountPlanListFn()
          _this.pubVue.adPlanIdList = []
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
</style>

