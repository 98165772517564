<template>
  <div>
    PocColor
  </div>
</template>

<script>
export default {
  name: 'PocColor',

  props: {
    name: String
  }
};
</script>
