<template>
  <div class="password">
   <el-card class="box-card">
        <div slot="header" class="box-card-header">
            <h4>Change Password</h4>
        </div>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        :rules="rulesList"
        hide-required-asterisk
        label-width="260px"
        label-position="right"
        class="demo-dynamic"
        style="width: 580px;margin:50px auto;margin-left:100px;margin-bottom:0;"
      >
        <el-form-item label-width="260px" label="Account Name" prop="accountname" class="marginB50">
          <el-input v-model="dynamicValidateForm.accountname" maxlength="100" show-word-limit></el-input>
          <span v-show="showName" class="el-form-item__error fontS12">Please input valid format;</span>
        </el-form-item>
        <el-form-item label-width="260px" prop="password" label="New Password" class="marginB50">
          <el-input show-password v-model="dynamicValidateForm.password" maxlength="100" show-word-limit></el-input>
          <span v-show="showPossword" class="el-form-item__error fontS12">Only support number. capital and lower-case letter</span>
        </el-form-item>
        <el-form-item label-width="260px" prop="confirmPassword" label="Confirm the New Password" class="marginB50">
            <el-input show-password v-model="dynamicValidateForm.confirmPassword" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;margin-right:-180px;">
          <el-button type="primary" @click="submitForm('dynamicValidateForm')">Confirm</el-button>
        </el-form-item>
      </el-form>
   </el-card>
   <passwordDialog :visible.sync="showDialog" :title="title" :content="content" @confrom="confrom"></passwordDialog>
  </div>
</template>

<script>
import passwordDialog from '@/views/dialog/usuallyDialog'
export default {
  name: "PassWord",
  components: {
    passwordDialog
  },
  data() {
    var nameRule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input valid format;"));
        this.showName = false;
      } else if (!new RegExp("[A-Z]").test(value)) {
        this.showName = false;
        callback(new Error("Please input valid format;"));
      } else {
        this.showName = true;
        callback();
      }
    };
    var passwordRule = (rule, value, callback) => {
      var Regx = /^[A-Za-z0-9]*$/;
      if (value === "") {
        callback(new Error("Please input valid format;"));
        this.showPossword = false;
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false;
        callback(new Error("Only support number. capital and lower-case letter;"));
      } else {
        this.showPossword = true;
        callback();
      }
    };
    var confirmPass = (rule, value, callback) => {
       var Regx = /^[A-Za-z0-9]*$/;
      if (value === "") {
        callback(new Error("Please input valid format;"));
        this.showPossword = false;
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false;
        callback(new Error("Only support number. capital and lower-case letter;"));
      } else if(value !== this.dynamicValidateForm.password) {
        this.showPossword = false;
        callback(new Error("The new password and password to confirm do not matchl;"));
      }else {
        this.showPossword = true;
        callback();
      }
    }
    return {
      dynamicValidateForm: {
        accountname: "",
        password: "",
        confirmPassword: ""
      },
      rulesList: {
        accountname: [ { validator: nameRule, trigger: ["blur", "change"] }],
        password: [
         { validator: passwordRule, trigger: ["blur", "change"] }
        ],
        confirmPassword: [  { validator: confirmPass, trigger: 'blur' }]
      },
      showName: true,
      showPossword: true,
      showDialog: true,
      title:'Confirm',
      content: 'Do you confirm to change the password?'
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    confrom() {
      console.log('password')
    }
  }
};
</script>

<style lang="scss">
.password{
  // .el-form-item__error{
  //   top:-20px
  // }
  .box-card {
    width: 930px;
    margin: 2% auto;
    margin-bottom: 0;
    &-header{
      display: flex;
      justify-content: space-between;
    }
    .el-card__header {
      border: none;
      padding: 30px 40px 0;
    }
    .el-form-item__label {
      font-size: 18px;
    }
    .redFont .el-input__inner,.redFont .el-input__count-inner{
      color: red;
    }
  }
  .fontS12{
    font-size: 12px;
  }
}
.password .el-form-item__error {
  height: 50px;
  line-height: 50px;
}
.password .el-form-item__error.fontS12 {
  color: #ccc;
}
.password .el-form-item.marginB50 {
  margin-bottom: 50px;
}
</style>
