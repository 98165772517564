<template>
  <el-dialog
    :visible.sync="show"
    width="400px"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    title="预警推送"
    center
    :show-close = "false">
    <div class="dialogIcon"><img :src='otherWarning'></div>
    <el-form :model="distribution_form" class="distributionAccount">
      <div class="warningList">
        <el-tooltip :content="'Switch value: ' + value" placement="top">
          <el-form-item label="默认预警" prop="" label-width="90px">
            <el-switch
              v-model="value"
              active-color="#48916F"
              inactive-color="#EAEFED"
              active-value="0"
              inactive-value="1">
            </el-switch>
          </el-form-item>
        </el-tooltip>
      </div>

      <div class="warningList">
        <el-form-item label="" :label-width="formLabelWidth">
          <span class="warningListName"><i>*</i><span>部门名称</span></span>
          <el-cascader
            v-model="fpDeptValue"
            :options="deptTree"
            :props="props"
            clearable
            ref="cascaderAddr"
            :show-all-levels="false"
            placeholder="请选择分配部门"
            @change="deptTreeDetail">
          </el-cascader>
        </el-form-item>
      </div>

      <div class="warningList">
        <el-form-item label="" :label-width="formLabelWidth">
          <span class="warningListName"><i>*</i><span>人员名称</span></span>
          <el-select v-model="distribution_form.regionshi" clearable placeholder="请选择分配优化师" @change="changeFn(distribution_form.regionshi)">
            <el-option
              v-for="(item,i) in distributionOptions"
              :key="i"
              :label="item.string"
              :value="item.userId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="warningList">
        <el-form-item label="" :label-width="formLabelWidth">
          <span class="warningListName">抄送人</span>
          <el-select v-model="value1" multiple filterable placeholder="请选择抄送人"  @change="changeCsr($event)">
            <el-option
              v-for="(item,i) in cc_options"
              :key="i"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel" type="primary" class="newDialogCancel">取消保存</el-button>
      <el-button type="primary" class="newDialog" @click="addAccountWarningMesgFn(warmSetType)">确定保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import otherWarning from '@/assets/img/otherWarning.png';
  import { $http } from '@/api/http';
  import config from '@/api/config';
export default {
  name: "earlyWarningDialog",

  props: {
    pubVue:Object,
    userContent:Object,
    warmSetType:String,//判断是OPPO还是VIVO
    visible: {
      type: Boolean,
      default: false
    },
    ownerIdList:{
      type: Array
    },
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      otherWarning,
      // optionsm:{channelList:[{text:'oppo',id:'0'},{text:'vivo',id:'2'},{text:'应用宝',id:'3'},{text:'华为',id:'4'},{text:'小米',id:'5'},{text:'今日头条',id:'6'}]},//选择状态
      optionsm:{},//部门名称
      loading: false,
      show: this.visible,
      currentTypeConfig: {},
      formData: {
        name: "CHen",
        email: "asdasd",
        brand: "",
        password: ""
      },
      channel:'',
      value:'2',
      rules: {},
      dialogFormVisible: false,
      distribution_form: {
        name: '',
        regionzu: '',
        regionshi: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        userProductName:'',
        projectName:''
      },
      fpDeptValue:'',
      formLabelWidth: '0',
      deptTree:[],
      props:{
        value:'id',
        label:'label',
        children:'children',
        emitPath:false,
        checkStrictly: true
      },
      value1:[],
      cc_values:'',
      distributionOptions:[],//分配优化师
      projectNameOptions:[],
      channelIdNew:'',
      channelId:'',
      disAccountParams:{
        id:'',
        dept:'',
        deptName:'',
        userId:'',
        lookChannel:'',
        lookUserDeptId:'',
        lookUser:''
      },
      bigFpParams:{
        deptId:'',
        deptName:'',
        ids:'',
        userId:'',
        productName:'',
        productId:'',
        projectName:'',
        projectId:'',
      },
      usermodifyParams:{
        copyUser:'',//
        dept:'',//
        deptName:'',//
        id:'',//
        lookChannel:'',//
        lookUser:'',//
        lookUserDeptId:'',//
        productId:'',//
        productName:'',//
        projectId:'',//
        projectName:'',//
        saleUser:'',//
        sendUser:'',//
        userId:''//
      },      
      cc_options:[],
      //   productname: [{ required: true, message: this.$t("dc:请输入") }],
      //   accountName: [{ validator: accountNameRoute, trigger: ["blur", "change"] }],
      //   module: [{ required: true, message: this.$t("dc:请输入") }],
      //   accountCode: [ { validator: accountCodeRoute, trigger: ["blur", "change"] }]
      // }
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted(){
    console.log(this.pubVue)
    this.getDeptTree()
    this.getuserChannel()
    this.getUserList()
  },
  methods: {
    changeFn(id){
      console.log(id)
    },
    onConfirm() {},
    onCancel() {
      this.show= false;
      this.value1 = '';
      this.fpDeptValue = '';
      this.distribution_form.regionshi = '';

      this.pubVue.$refs.multipleTable.clearSelection();
    },

    // 获取部门名称列表***后台回显页面先不写
    getNameList(){
      let _this = this
        $http.axios.get("/api/system/dept/list").then(res=> {
          if(res.data.code==200){
            console.log(res)
          }
        })
    },

    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取批量分配中的分配部门下拉框
    getDeptTree(){
      let that = this
      $http.axios.get("/api/system/dept/treeselect?channelId="+that.channelIdNew).then(function(response) {
        if(response.data.code == 200){
          that.deptTree = response.data.data
          that.deptTreefp = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },    
    //获取批量分配账户的分配部门id
    deptTreeDetail(event){
      this.$nextTick(() => {
        let thsAreaCode = this.$refs['cascaderAddr'].presentText
        this.bigFpParams.deptName = thsAreaCode
      })
      let that = this
      that.bigFpParams.deptId = String(event)
      that.deptIdNew = event
      that.getFpList()
    },
    
    //获取分配人员列表
    getFpList(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId="+that.deptIdNew).then(function(response) {
        if(response.data.code == 200){
          that.distributionOptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },      
    //批量分配---确定
    bigSureFp(){
      let that = this
      that.bigFpParams.ids = String(that.bigFpids)
      // that.bigFpParams.userId = '1'
      $http.axios.post("/api/channel/account/editBatch",that.bigFpParams).then(function(response) {
        if(response.data.code == 200){
          that.dialogFormVisible = false
          // that.getAccountList()
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    //修改后的关联到user的channel
    getuserChannel(){
      let that = this
      that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
      $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
        if(response.data.data!=undefined&&response.data.data.length!=0){
          that.qdoptions = response.data.data
          that.channelIdNew = response.data.data[0].id
          that.channelId = response.data.data[0].id
          // that.getAccountList()
          // that.qdoptionsfp = response.data.data
        }
      })
    },
    //获取用户列表
    getUserList(){
      let that = this
      // let ccPeople = []
      $http.axios.get("/api/system/user/optionSelectCopyUser").then(function(response) {
        if(response.data.code == 200){
          that.cc_options = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    //获取抄送人ids
    changeCsr(val){
      console.log(val)
      this.copyUserString = []
      this.copyUserString.push(val)
      console.log(this.copyUserString)
      this.usermodifyParams.copyUser = String(this.copyUserString)
      console.log(this.usermodifyParams.copyUser)
      // console.log(event)
      // this.cc_value = []
      // this.cc_value.push(event)
    },
    // [
    //   {
    //     "createUser": 0,
    //     "createUserName": 0,
    //     "departmentId": 0,
    //     "departmentName": "",
    //     "ownerId": 0,
    //     "userId": 0,
    //     "userIds": "",
    //     "userName": 0,
    //     "warningSwitch": 0
    //   }
    // ]
    //添加预警部门信息
    addAccountWarningMesgFn(warmSetType){
      if(warmSetType=='oppo'){
        this.oppo()
      }else if(warmSetType=='vivo'){
        if(this.fpDeptValue == ''){
          this.$message({
            showClose: true,
            message: '请填写必填项',
            type: 'error'
          })
        }else if(this.distribution_form.regionshi == ''){
          this.$message({
            showClose: true,
            message: '请填写必填项',
            type: 'error'
          })
        }else{
          this.vivo()
        }
       
      }
      
    },
    oppo(){
      let _this = this,params = []
      // _this.ownerIdList = ['1100018542']
      _this.ownerIdList.forEach((obj,i)=>{
        params.push({
          'createUser':_this.userContent.userId||'',
          'createUserName':_this.userContent.userName||'',
          'departmentId':_this.fpDeptValue,
          'departmentName':_this.bigFpParams.deptName||'',
          'ownerId':Number(obj)||obj,
          'userId':Number(_this.distribution_form.regionshi),
          'userIds':_this.value1.toString()||'',
          'userName':_this.bigFpParams.deptName||'',
          'warningSwitch':Number(_this.value)
        })
      })
      $http.axios.post("/api/advertisement/account/v1/addAccountWarningMesg",params).then(function(response) {
        if(response.data.code == 200){
          _this.onCancel()
          _this.pubVue.oppoAccountList()//获取账户列表
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })    
    },
    vivo(){
      let _this = this,params = []
      // _this.ownerIdList = ['1100018542']
      _this.ownerIdList.forEach((obj,i)=>{
        params.push({
          'createUser':_this.userContent.userId||'',
          'createUserName':_this.userContent.userName||'',
          'departmentId':_this.fpDeptValue,
          'departmentName':_this.bigFpParams.deptName||'',
          'ownerId':obj,
          'userId':Number(_this.distribution_form.regionshi),
          'userIds':_this.value1.toString()||'',
          'userName':_this.bigFpParams.deptName||'',
          'warningSwitch':Number(_this.value)
        })
      })
        $http.axios.post("/api/vivo/account/v1/addAccountWarningMesg",params).then(function(response) {
          console.log(111111)
          if(response.data.code == 200){
            _this.onCancel()
            _this.pubVue.oppoAccountList()//获取账户列表
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })    
      
      
    } 
  }
};
</script>
<style lang="scss" scoped>
.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
/* .el-form-item__content span{
  display: inline-block;
  width: 75px;
  text-align: right;
  margin-right:15px;
} */
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
.warningListName{
  display: inline-block;
  width:75px;
  font-size:12px;
  color:#330000;
  text-align: right;
  margin-right:15px;
}
.warningListName i{
  width:5px;
  display: inline-block;
  vertical-align: middle;
  margin-right:5px;
  color:red;
}
.warningListName span{
  width:50px;
  display: inline-block;
  vertical-align: middle;
}
::v-deep .warningList .el-input--mini .el-input__inner{
  width:200px;
}
.warningList{
  width:100%;
  padding:10px;
  border-radius:4px;
  background:#F5F7F6;
  margin:5px 0;
  box-sizing: border-box;
}
::v-deep .warningList .el-form-item{
  margin:0!important;
}
</style>

