/**
 * 常量配置
 */
export default {
  // token的key值
  USER_TOKEN_KEY: 'Authorization',
  // token在cookie中的key
  COOKIE_TOKEN: 'login_token',
  // userid
  USERID: 'userId',
  // userid在cookie
  COOKIE_USERID: 'userIdCookie',
  // userid
  USERNAME: 'username',
  // userid在cookie
  COOKIE_USERNAME: 'usernameCookie',
  SETTIME:'setTime',
  COOKIE_TIME:'setTimeCookie',
  DEPTID: 'deptId',
  // userid在cookie
  COOKIE_DEPTID: 'deptIdCookie',
  // userid
  ROLES: 'roles',
  // userid在cookie
  COOKIE_ROLES: 'rolesCookie',
  // 项目code，用于sso鉴权    ProductB/Data Center
  PROJECT_CODE: 'SSO',
  //nickName登录用户中文名
  NICKNAME:'nickName',
  //deptName登录用户中文名
  DEPTNAME:'deptName',
  // response返回值中的状态码
  RESPONSE_STATUS: {
    // 成功，正常
    SUCCESS: 0,
    // 文件模版错误
    FILE_TEMPLATE_ERROR: 1,
    // 用户未登录
    NEED_LOGIN: 100000,
    // 用户不存在
    USER_EXIST: 100011,
    // 不合法参数
    ILLEGAL_ARGUMENT: 100012,
    // 无访问权限
    NOT_AUTH: 100013,
    // 其它错误
    OTHER: 999999,
  },
}