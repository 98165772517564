<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="hl_headers sticky">
          <div class="hl_headerLeft">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="hl_headerLeftDrowp"
            >
              <el-breadcrumb-item>运营管理</el-breadcrumb-item>
              <el-breadcrumb-item>需求工单</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button
              class="el-button--primary"
              type="primary"
              size="small"
              @click="waterMark('creatFn')"
              v-if="typeFlag == 2"
              >创建</el-button
            >
            <el-button
              class="el-button--primary"
              type="primary"
              size="small"
              @click="transferBth"
              v-if="typeFlag == 2"
              >转让</el-button
            >
            <el-button
              class="el-button--primary"
              type="primary"
              size="small"
              @click="pljs"
              v-if="demandFlag == 2"
              >接收</el-button
            >
          </div>
        </div>
        <div class="search_container">
          <el-form
            :inline="true"
            :model="materiaForm"
            ref="materiaForm"
          >
            <el-form-item prop="showQdList" >
              <!-- v-if="demandFlagShow" -->
              <el-select
                v-model="materiaForm.showQdList"
                placeholder="请选择渠道"
                @change="channelChange($event)"
              >
                <el-option
                  v-for="(item, i) in materiaChannelOptions"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="picOrvideo" v-if="demandFlagShow">
              <el-select
                v-model="materiaForm.picOrvideo"
                placeholder="请选择工单类型"
                @change="changePicVideo"
              >
                <el-option
                  v-for="(item, i) in picOrvideoOptions"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item prop="changeChannel" v-if="demandFlag == 2">
              <el-select
                v-model="materiaForm.changeChannel"
                placeholder="请选择渠道"
                @change="changeSonOrder"
               
              >
                <el-option
                  v-for="(item, i) in materiaChannelOptions"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="showScstatus">
              <el-select
                v-model="materiaForm.showScstatus"
                filterable
                clearable
                placeholder="请选择工单状态"
                @change="statusChange($event)"
              >
                <el-option
                  v-for="(item, i) in materiaStatusOptions"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="selectName">
              <el-input
                v-model="materiaForm.selectName"
                clearable
                placeholder="请输入需求人员/账户名称/工单编号/工单名称"
                style="width:300px!important;"
              ></el-input>
            </el-form-item> 
            <!-- <el-form-item prop="changeDemandname" v-if="demandFlag == 2">
              <el-input
                v-model="materiaForm.changeDemandname"
                placeholder="请输入工单名称"
              ></el-input>
            </el-form-item> -->
            <el-form-item prop="upStatus" v-if="demandFlag == 2">
              <el-select
                v-model="materiaForm.upStatus"
                filterable
                clearable
                placeholder="请选择上传状态"
                @change="changeUpStatus"
              >
                <el-option
                  v-for="(item, i) in upStatusOptions"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="hideenTheForm" v-if="showTheForm">
              <i class="el-icon-arrow-right" @click="showList"></i>
            </div>
            <div class="showTheForm" v-else>

              <!-- <el-form-item label="" prop="mXname">
                <el-input
                  v-model="materiaForm.mXname"
                  placeholder="请输入需求人员"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="accName">
                <el-input
                  v-model="materiaForm.accName"
                  placeholder="请输入账户名称"
                ></el-input>
              </el-form-item> -->

              <el-form-item prop="mproduceGroup" v-if="demandFlag == 1">
                <el-select
                  v-model="materiaForm.mproduceGroup"
                  filterable
                  clearable
                  placeholder="请选择制作组名称"
                >
                  <el-option
                    v-for="(item, i) in mproduceGroupOptions"
                    :key="i"
                    :label="item.groupName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="mPaccount">
                <el-select
                  v-model="materiaForm.mPaccount"
                  filterable
                  clearable
                  placeholder="请选择制作材料的账户"
                  @change="accountChange"
                >
                  <el-option
                    v-for="(item, i) in mPaccountOptions"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="mDate">
                <el-date-picker
                  v-model="materiaForm.mDate"
                  type="daterange"
                  range-separator="-"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <i class="el-icon-arrow-up" @click="hiddenList"></i>
            </div>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="getDemandOrder(1)"
                >查询</el-button
              >
              <el-button
                type="info"
                size="small"
                @click="resetForm('materiaForm')"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="materiaContent" v-if="demandFlag == 1">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :max-height="screenHeight"
            style="width: 100%; margin-bottom: 20px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" fixed="left">
            </el-table-column>
            <el-table-column
              prop="name"
              label="工单编号"
              width="120"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.row.demandNum }}</template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="工单名称"
              width="200"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.row.demandName }}</template>
            </el-table-column>
            <el-table-column prop="name" label="提交日期" width="100">
              <template slot-scope="scope">{{
                scope.row.createTime | dateFormat
              }}</template>
            </el-table-column>
            <el-table-column label="需求日期" width="180">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.demandCycle"
                  :key="i"
                  style="display: inline-block; margin: 0 2px"
                >
                  <span v-if="item == 1">周一</span>
                  <span v-else-if="item == 2">周二</span>
                  <span v-else-if="item == 3">周三</span>
                  <span v-else-if="item == 4">周四</span>
                  <span v-else-if="item == 5">周五</span>
                  <span v-else-if="item == 6">周六</span>
                  <span v-else-if="item == 7">周日</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="截止时间" width="120">
              <template slot-scope="scope">{{
                scope.row.deadline | dateFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="需求人员" width="120">
              <template slot-scope="scope">{{
                scope.row.submitUserName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="需求总量" width="100">
              <template slot-scope="scope">{{
                scope.row.sumNeedNums
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="制作组" width="120">
              <template slot-scope="scope">{{
                scope.row.makerGroupName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="账户名称" width="180">
              <template slot-scope="scope">{{ scope.row.acountName }}</template>
            </el-table-column>
            <el-table-column prop="name" label="资源位置" width="120">
              <template slot-scope="scope">
                <span
                  v-for="(item, i) in scope.row.data"
                  :key="i"
                  style="display: block"
                  >{{ item.resourceBits }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="name" label="素材限制" width="120">
              <template slot-scope="scope">
                <span
                  v-for="(item, i) in scope.row.data"
                  :key="i"
                  style="display: block"
                  >{{ item.formatSize }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="name" label="素材备注" width="160">
              <template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  v-if="scope.row.materialDesc"
                >
                  <div v-html="scope.row.materialDesc">
                    {{ scope.row.materialDesc }}
                  </div>
                  <div
                    slot="reference"
                    v-html="scope.row.materialDesc"
                    class="zhugongdan reference"
                  ></div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="工单状态" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.opStatus == -1">待分配</span>
                <span v-if="scope.row.opStatus == 1">已分配待完成</span>
                <span v-if="scope.row.opStatus == 2">已完成</span>
                <span v-if="scope.row.opStatus == 3">已关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="300" fixed="right">
              <template slot-scope="scope">
                <span v-if="typeFlag == 1">
                  <el-button
                    class="solid-tag-instructions"
                    v-if="scope.row.opStatus == -1"
                    @click="distribute(scope.row)"
                    >指定</el-button
                  >
                  <el-button
                    class="solid-tag-disabled"
                    type="info"
                    size="mini"
                    v-else
                    >已指定</el-button
                  >
                  <el-button
                    class="solid-tag-harmful"
                    v-if="scope.row.opStatus != 2 && scope.row.opStatus != 3"
                    @click="refuseFn(scope.row.id)"
                    >拒绝</el-button
                  >
                </span>
                <span v-else></span>
                <el-button
                  class="solid-tag-warning"
                  v-if="scope.row.opStatus == -1 && typeFlag == 2"
                  @click="remindMe(scope.row.id)"
                  >提醒</el-button
                >
                <el-button
                  class="solid-tag-success"
                  @click="videoDetail(scope.row.id)"
                  >详情</el-button
                >
                <el-button
                  class="solid-tag-warning"
                  v-if="typeFlag == 2"
                  @click="transferClick(scope.row.id)"
                  >转让</el-button
                >
                <!-- <span v-if='typeFlag==2'>
                    <el-button v-if="scope.row.modifyFlag==1"  class="newBtn_Yellow" plain size="mini" @click="waterMark('reviseFn',scope.row.id)">复制</el-button>
                  </span> -->
                <span v-if="typeFlag == 2 && demandFlag == 1">
                  <el-button
                    class="solid-tag-error"
                    plain
                    size="mini"
                    @click="waterMark('reviseFn', scope.row.id)"
                    >复制</el-button
                  >
                  <el-button
                    class="solid-tag-instructions"
                    v-if="scope.row.opStatus == -1 && judgeChannel == 5"
                    @click="editDemandOrder('editFn', scope.row.id)"
                    >修改</el-button
                  >
                  <!-- <el-button  class="newBtn_red" plain size="mini" style="margin-left:3px;" v-if="scope.row.opStatus==-1&&judgeChannel == 5" @click="deleteDemand">删除</el-button> -->
                  <el-button
                    class="solid-tag-error"
                    v-if="scope.row.opStatus == -1 && judgeChannel == 5"
                    @click="deleteDemand(scope.row.id)"
                    >删除</el-button
                  >
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 指定人员弹框 -->
        <el-dialog
          title="指定人员"
          class="icon_custom_popup designated_personnel_dialog"
          :visible.sync="distributeVisible"
          width="500px"
          :close-on-click-modal="false"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_specify.png" />
          </div>
          <el-form :model="dpersonalForm" :inline="true" label-width="70px">
            <div
              class="element_contant"
              v-for="(val, index) in fpParams.desDtos"
              :key="index"
            >
              <div class="close" v-if="index > 0" @click="removeRow(index)">
                <i class="deleteBtnicon el-icon-error"></i>
              </div>
              <el-form-item label="素材">
                <!-- <el-select v-model="val.detailId" style="width:260px;" filterable placeholder="请选择素材"> -->
                <el-select
                  v-model="val.detailId"
                  filterable
                  placeholder="请选择"
                  @change="changeDistribute($event, index, val)"
                >
                  <el-option
                    v-for="(item, i) in distributeData"
                    :key="i"
                    :label="
                      item.resourceBits + item.size + '(' + item.needNum + ')'
                    "
                    :value="item.id"
                    :disabled="item.opStatus != -1"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数量">
                <el-input
                  v-model.number="val.needNum"
                  type="number"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="分配人员">
                <!-- <el-select v-model="val.userId" filterable placeholder="请选择分配人员"> -->
                <el-select
                  v-model="val.userIdList"
                  multiple
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, i) in distributePeoplesOptions"
                    :key="i"
                    :label="item.nickName"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-button type="primary" class="add" @click="newAddListBtn"
              >+ 新增</el-button
            >
          </el-form>
          <div slot="footer">
            <el-button
              @click="distributeFalse"
              >取 消</el-button
            >
            <el-button type="primary" @click="distributeb"
              >分 配</el-button
            >
          </div>
        </el-dialog>
        <div class="materiaContent" v-if="demandFlag == 2">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :max-height="screenHeight"
            style="width: 100%"
            row-key="id"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" fixed="left">
            </el-table-column>
            <el-table-column
              prop="name"
              label="工单编号"
              width="180"
              fixed="left"
            >
              <template slot-scope="scope">{{
                scope.row.demandDetailNum
              }}</template>
            </el-table-column>
            <el-table-column
              prop="acountChannel"
              label="渠道名称"
              width="120"
              fixed="left">
              <template slot-scope="scope">
                <span v-if="scope.row.acountChannel == 3">OPPO</span>
                <span v-else-if="scope.row.acountChannel == 4">VIVO</span>
                <span v-else-if="scope.row.acountChannel == 5">小米</span>
                <span v-else-if="scope.row.acountChannel == 6">华为</span>
                <span v-else-if="scope.row.acountChannel == 7">应用宝</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="工单名称"
              width="200"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.row.demandName }}</template>
            </el-table-column>
            <el-table-column prop="name" label="工单状态" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.opStatus == -1">待分配</span>
                <span v-else-if="scope.row.opStatus == 1">待完成</span>
                <span v-else-if="scope.row.opStatus == 2">等待审核</span>
                <span v-else-if="scope.row.opStatus == 3">运营审核通过</span>
                <span v-else-if="scope.row.opStatus == 4">运营审核拒绝</span>
                <span v-else-if="scope.row.opStatus == 5">已完成</span>
                <span v-else-if="scope.row.opStatus == 6">客户审核拒绝</span>
                <span v-else-if="scope.row.opStatus == 7">待接收</span>
                <span v-else-if="scope.row.opStatus == 8">已关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="提交时间" width="120">
              <template slot-scope="scope">{{
                scope.row.createTime | dateFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="需求日期" width="180">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.demandCycle"
                  :key="i"
                  style="display: inline-block; margin: 0 2px"
                >
                  <span v-if="item == 1">周一</span>
                  <span v-if="item == 2">周二</span>
                  <span v-if="item == 3">周三</span>
                  <span v-if="item == 4">周四</span>
                  <span v-if="item == 5">周五</span>
                  <span v-if="item == 6">周六</span>
                  <span v-if="item == 7">周日</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="截止时间" width="120">
              <template slot-scope="scope">{{
                scope.row.deadline | dateFormat
              }}</template>
            </el-table-column>
            <el-table-column label="需求人员" width="120">
              <template slot-scope="scope">{{
                scope.row.submitUserName
              }}</template>
            </el-table-column>
            <el-table-column label="账户名称" width="200">
              <template slot-scope="scope">{{ scope.row.acountName }}</template>
            </el-table-column>
            <el-table-column label="资源位置" width="180">
              <template slot-scope="scope">{{
                scope.row.resourceBits
              }}</template>
            </el-table-column>
            <el-table-column label="需求数量" width="80">
              <template slot-scope="scope">{{ scope.row.needNum }}</template>
            </el-table-column>
            <el-table-column label="素材限制" width="180">
              <template slot-scope="scope">{{ scope.row.size }}</template>
            </el-table-column>
            <el-table-column label="素材备注" width="200">
              <template slot-scope="scope" v-if="scope.row.materialDesc">
                <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  v-if="scope.row.materialDesc"
                >
                  <div v-html="scope.row.materialDesc">
                    {{ scope.row.materialDesc }}
                  </div>
                  <span
                    slot="reference"
                    v-html="scope.row.materialDesc"
                    class="zhugongdan"
                    >{{ scope.row.materialDesc }}</span
                  >
                </el-popover>
              </template>
              <!-- <template slot-scope="scope">{{scope.row.materialDesc}}</template> -->
            </el-table-column>
            <el-table-column label="指定人员" width="200">
              <template slot-scope="scope"
                ><span v-html="scope.row.makerUserName"></span
              ></template>
              <!-- <template slot-scope="scope"><div v-html="scope.row.MakerUserName"></div></template> -->
            </el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <span style="padding-right: 10px">
                  <span>
                    <el-button
                    class="solid-tag-success"
                      size="mini"
                      plain
                      v-if="scope.row.opStatus == 7"
                      @click="uploadForm(scope.row.id)"
                      >接收</el-button
                    >
                  </span>
                  <span v-if="typeFlag == 1">
                    <!-- <span>
                        <el-button class="newBtn_red" size="mini" plain v-if="scope.row.opStatus == 1||scope.row.opStatus == 2||scope.row.opStatus == 3||scope.row.opStatus == 4||scope.row.opStatus == 6" @click="upload(scope.row)">上传</el-button>
                      </span> -->
                    <span v-if="scope.row.materialId == 1">
                      <el-button
                        class="solid-tag-instructions"
                        v-if="
                          scope.row.opStatus == 1 ||
                          scope.row.opStatus == 2 ||
                          scope.row.opStatus == 3 ||
                          scope.row.opStatus == 4 ||
                          scope.row.opStatus == 6
                        "
                        @click="upload(scope.row)"
                        >上传</el-button
                      >
                    </span>
                    <span v-else-if="scope.row.materialId == 2">
                      <el-button
                      class="solid-tag-warning"
                        v-if="
                          scope.row.opStatus == 1 ||
                          scope.row.opStatus == 2 ||
                          scope.row.opStatus == 3 ||
                          scope.row.opStatus == 4 ||
                          scope.row.opStatus == 6
                        "
                        @click="upload(scope.row)"
                        >上传</el-button
                      >
                    </span>
                    <el-button
                      class="solid-tag-harmful"
                      v-if="scope.row.opStatus != 5 && scope.row.opStatus != 8"
                      @click="refuseFn(scope.row.id)"
                      >拒绝</el-button
                    >
                  </span>
                  <span v-else></span>
                  <el-button
                    class="newBtn_darkBlue"
                    size="mini"
                    plain
                    v-if="scope.row.opStatus == -1"
                    >待分配（视频部门有权分配）</el-button
                  >
                </span>
                <span>
                  <el-button
                    class="newBtn_garyWhite"
                    size="mini"
                    plain
                    v-if="scope.row.stopFlag == 1 && scope.row.opStatus != 5"
                    @click="demandStstu(scope.row.id)"
                    >结束工单</el-button
                  >
                  <el-button
                    type="text"
                    v-if="scope.row.stopFlag == 2"
                  ></el-button>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 转让 -->
        <el-dialog
          title=""
          :visible.sync="transferDialog"
          width="400px"
          class="icon_custom_popup"
          :destroy-on-close="true"
          :show-close="false"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
            <el-form-item label="转让人：" prop="transferName">
              <el-select 
                v-model="ruleForm.transferName" 
                placeholder="请选择转让人"
                value-key="userId"
              >
                <el-option
                  v-for="(item, i) in transferOptions"
                  :key="i"
                  :label="item.string"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancelTransfer('ruleForm')">取 消</el-button>
            <el-button type="primary" @click="sureTransfer('ruleForm')">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 正在载入 -->
        <el-dialog
          title=""
          :visible.sync="loadingDialogVisible"
          width="20%"
          :destroy-on-close="true"
          :show-close="false"
          :close-on-click-modal="false"
          class="loadingBg">
          <span class="loading">
            <span>正在载入...</span>
            <i class="el-icon-loading"></i>
          </span>
       </el-dialog>
        <div class="footerPaging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageCount"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.totalRow"
          >
          </el-pagination>
        </div>
      </template>
      <template slot="headerGroup"> </template>
      <refuseReasonDialog
        :visible.sync="refuseIs"
        :type="type"
        :typeName="typeName"
        :id="id"
        :pubVue="pubVue"
      ></refuseReasonDialog>
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import { accessUserId } from "@/api/http";
import { accessDeptId } from "@/api/http";
import refuseReasonDialog from "@/views/dialog/refuseReasonDialog";
export default {
  name: "demandOrder",
  components: {
    refuseReasonDialog,
  },
  data() {
    return {
      loadingDialogVisible:true,//正在载入的弹框
      pubVue: this,
      refuseIs: false,
      showTheForm: true, //表头是否显示全部表单
      materiaChannelValue: "", //头部渠道搜索value
      mStatusValue: "", //选择素材状态下拉框的value
      materiaChannelOptions: [],
      materiaForm: {
        //头部查询表单的prop
        showQdList: "", //选择渠道
        showScstatus: "", //选择素材状态
        showScstatuss: "",
        mPname: "",
        // mXname: "",
        mPaccount: "",
        mZtype: "",
        mPosition: "",
        mDate: [],
        mproduceGroup: "",
        // accName: "",
        changeDemandname: "",
        upStatus: "",
        picOrvideo: "", //视频或图文选择
        changeChannel:'',
        selectName:'',
      },
      picOrvideoOptions: [
        {
          id: 1,
          name: "图文",
        },
        {
          id: 2,
          name: "视频",
        },
      ],
      upStatusOptions: [
        {
          value: 1,
          label: "未完成上传",
        },
        {
          value: 2,
          label: "已完成上传",
        },
      ],
      materiaStatusOptions: [
        {
          //查询表单的选择素材状态
          value: -1,
          label: "待分配",
        },
        {
          value: 1,
          label: "已分配",
        },
        {
          value: 2,
          label: "已完成",
        },
        {
          value: 3,
          label: "已拒绝",
        },
      ],
      materiaTypeOptions: [
        {
          //查询表单的选择素材资源类型
          value: "选项1",
          label: "联盟",
        },
        {
          value: "选项2",
          label: "富媒体",
        },
        {
          value: "选项3",
          label: "信息流",
        },
      ],
      materiaPositionOptions: [
        {
          //查询表单的选择资源位
          value: "选项1",
          label: "1",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      materiaTabOptions: [
        {
          //查询表单的选择素材标签
          value: "选项1",
          label: "1",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      materiaUserOptions: [
        {
          //查询表单的选择制作人
          value: "选项1",
          label: "1",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      materiaAccountOptions: [
        {
          //查询表单的选择账户名称
          value: "选项1",
          label: "1",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      tableData: [],
      multipleSelection: [],
      // 分页
      page: 1, //当前页
      pageCount: 40, //每页大小
      totalRow: 0, //总条数
      totalPage: 0, //总页数
      demandParams: {
        //工单列表
        page: "",
        pageCount: "",
        channel: "",
        userId: "",
        deptId: "",
        deadline: "",
        startime: "",
        acountChannel: "",
        acountId: "",
        submitUserName: "",
        opStatus: "",
        makerGroupId: "",
        accountName: "",
      },
      queryDemandParams: {
        //查询工单
        pages: "",
        pageCount: "",
        channel: "",
        userId: "",
        deptId: "",
        deadline: "",
        startime: "",
        acountChannel: "",
        acountId: "",
        submitUserName: "",
        opStatus: "",
      },
      distributeVisible: false,
      formLabelWidth: "120px",
      distributeData: [],
      checkList: [],
      distributePeoples: "",
      distributePeoplesOptions: [],
      distributeMaterial: "",
      isShowPeople: false,
      materStaffIds: [],
      materStaffNames: [],
      fpStaff: "",
      fpParams: {
        demandId: "",
        userId: "",
        userName: "",
        desDtos: [
          {
            detailId: "",
            needNum: "",
            userIdList: [],
          },
        ],
      },
      mPaccountOptions: [],
      mproduceGroupOptions: [],
      demandFlag: "", //1:主工单；2:子工单->要求树结构(可收缩的结构)
      accountUserId: "",
      demandListData: [],
      uploadParams: {
        demandDetailIds: [],
        userId: "",
      },
      typeName: "工单",
      type: null,
      typeFlag: "", //2&&modifyFlag:1 修改按钮可编辑；1-隐藏按钮
      id: null,
      remindMeParams: {
        demandId: "",
      },
      remindDialog: false,
      showPeopleValue: [],
      leftName: "",
      rightName: "",
      leftId: "",
      dpersonalForm: {},
      distributeNum: "",
      getchannelId: "",
      screenHeight: window.innerHeight - 336,
      judgeChannel: "", //渠道权限的判断
      demandFlagShow: true,
      deptId: "",
      distributeDetailId: "", //指定的素材规格
      sjDeptId: "", //设计人员的deptid
      videoZuName: "", //视频组名称
      roleKey: "", //获取角色
      deptIds: "",
      picOrvideoValue:'',
      transferDialog:false,
      ruleForm:{
        transferName:''
      },
      rules:{
        transferName: [{ required: true, message: '请选择转让人', trigger: 'change' }],
      },
      transferOptions:[],
      transferId:[]
    };
  },
  mounted() {
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
    this.materiaForm.upStatus = 1;
    console.log(this.materiaForm.picOrvideo)
    this.getuserChannel();
    this.getAccountList();
    this.getRole();
    if (this.deptId == 308) {
      this.materiaForm.picOrvideo = 1;
    } else if (this.deptId == 299) {
      this.materiaForm.picOrvideo = 1;
    } else if (this.deptId == 309) {
      this.materiaForm.picOrvideo = 1;
    } else if (this.deptId == 314) {
      this.materiaForm.picOrvideo = 1;
    } else if (this.deptId == 313) {
      this.materiaForm.picOrvideo = 2;
    } else {
      this.materiaForm.picOrvideo = '';
    }
    // this.demandParams.materialType = this.materiaForm.picOrvideo
    // this.$sessionStorage.setItem('picOrvideoValue',this.materiaForm.picOrvideo)
    console.log( this.materiaForm.picOrvideo,22222)
    

    
    // this.materiaForm.picOrvideo = 1
    // this.demandParams.materialType = this.materiaForm.picOrvideo;
      // that.demandParams.channel = that.materiaForm.showQdList
    // this.$sessionStorage.setItem('picOrvideoValue',this.materiaForm.picOrvideo)
  },
  methods: {
    getRole() {
      let that = this,
        deptId,
        parentId;
      $http.axios.get("/api/system/user/getInfo").then(function (response) {
        if (response.data.code == 200) {
          that.roleKey = response.data.user;
          // console.log(that.roleKey);
          parentId = that.roleKey.dept.parentId;
          that.deptIds = that.roleKey.deptId;
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: "error",
          });
        }
      });
    },

    refuseFn(id) {
      this.refuseIs = true;
      this.id = id.toString();
      this.type = this.demandFlag;
    },
    //头部表单重置
    resetForm(materiaForm) {
      this.$refs[materiaForm].resetFields();
      this.$router.go(0);
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.submitUserName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
        return (
          restaurant.acountName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelect(item) {
      // console.log(item);
    },

    // 跳转视频素材工单
    videoDetail(e) {
      this.$router.push({ path: "/auth-management/VideoMaterialList/" + e });
    },
    //跳转创建工单页面
    waterMark(funC, demandId) {
      // this.$router.push({ path: '/auth-management/SetupOrder', query: { funC: funC,demandId:demandId }})
      this.$router.push({
        path: "/auth-management/SetupOrder",
        query: { funC: funC, demandId: demandId, channel: this.judgeChannel },
      });
    },
    editDemandOrder(funC, demandId) {
      this.$router.push({
        path: "/auth-management/SetupOrder",
        query: { funC: funC, demandId: demandId, channel: this.judgeChannel },
      });
    },
    //表头点击向右三角显示
    showList() {
      this.showTheForm = false;
    },
    //表头点击向上三角隐藏
    hiddenList() {
      this.showTheForm = true;
    },

    //修改后的关联到user的channel
    getuserChannel() {
      let that = this;
      // console.log(that.demandFlag);
      let userChannel = String(
        that.$sessionStorage.getItem(config.USERID) || 1
      );
      $http.axios
        .get("/api/system/user/getChannelByUser?userId=" + userChannel)
        .then(function (response) {
          if (response.data.data.length == 0) {
            that.getDemandOrder();
            // console.log(that.demandFlag);
          } else {
            that.materiaChannelOptions = response.data.data;
            that.materiaForm.showQdList = response.data.data[0].id;
            that.demandParams.channel = response.data.data[0].id;
            that.materiaForm.changeChannel = response.data.data[0].id;
            that.getchannelId = String(response.data.data[0].id);
            that.judgeChannel = response.data.data[0].id;
            
            // that.$sessionStorage.setItem('channelID',response.data.data[0].id)

            that.getDemandOrder();
            that.produceGroupList();
            that.getAccountList();
            // console.log(that.demandFlag);
            if (that.demandFlag == 1) {
              that.demandFlagShow = true;
            } else {
              that.demandFlagShow = false;
            }
          }
        });
    },

    //制作组无参下拉框
    produceGroupList() {
      let that = this;
      $http.axios
        .get(
          "/api/operationManagement/materialDemand/channelgrouplist?channelId=" +
            that.judgeChannel
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.mproduceGroupOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //结束工单
    demandStstu(i) {
      let that = this;
      that.stopParams.demandDetailId = i;
      that.stopParams.userId = that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/stopdemand",
          that.stopParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.getDemandOrder();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //change上传状态
    changeUpStatus(val) {
      this.demandParams.upStatus = this.materiaForm.upStatus;
    },
    // changeSonOrder(val){
    //   this.demandParams.acountChannel = val
    //   this.getDemandOrder(1)
    // },

    //获取需求工单列表
    getDemandOrder(page) {
      this.loadingDialogVisible = true
      let that = this
      if (page == 1) {
        that.page = 1;
      }
      // that.picOrvideoValue = that.$sessionStorage.getItem('picOrvideoValue')
      // that.materiaForm.showQdList = that.$sessionStorage.getItem('channelID')
      // that.materiaForm.picOrvideo = that.picOrvideoValue
      that.demandParams.pages = that.page;
      that.demandParams.pageCount = that.pageCount;
      that.demandParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      that.demandParams.deptId = that.$sessionStorage.getItem(config.DEPTID);
      if (that.materiaForm.mDate != null) {
        that.demandParams.deadline = that.materiaForm.mDate[1];
        that.demandParams.startime = that.materiaForm.mDate[0];
      } else {
        that.demandParams.startime = "";
        that.demandParams.deadline = "";
      }
      // that.demandParams.submitUserName = that.materiaForm.mXname;
      that.demandParams.makerGroupId = that.materiaForm.mproduceGroup;
      // that.demandParams.accountName = that.materiaForm.accName;
      that.demandParams.demandName = that.materiaForm.changeDemandname;
      that.demandParams.upStatus = that.materiaForm.upStatus;
      if(that.$sessionStorage.getItem('picOrvideoValue') == null){
        that.demandParams.materialType = that.materiaForm.picOrvideo;
      }else{
        that.materiaForm.picOrvideo = that.$sessionStorage.getItem('picOrvideoValue');
        that.demandParams.materialType = that.materiaForm.picOrvideo
      }
      if(that.$sessionStorage.getItem('channelID') == null){
        that.demandParams.channel = that.materiaForm.showQdList
      }else{
        that.materiaForm.showQdList = that.$sessionStorage.getItem('channelID')
        that.demandParams.channel = that.materiaForm.showQdList
      }

      that.demandParams.keyword = that.materiaForm.selectName //新加的搜索，还没有入参名称

      $http.axios
        .post("/api/operationManagement/materialDemand/list", that.demandParams)
        .then((res) => {
          // $http.axios.post("/lcl/materialDemand/list",that.demandParams).then(res=> {
          if (res.data.code == 200) {
            that.loadingDialogVisible = false
            // that.tableData = res.data.data.items
            let obj = {},
              newArr = [],
              tableData = [];
            res.data.data.items.forEach((item, $index) => {
              if (item.demandDetailNum != undefined) {
                that.$set(
                  item,
                  "demandDetailNumName",
                  item.demandDetailNum.split("-")[0]
                );

                if (!obj[item.demandDetailNumName]) {
                  tableData.push(item);
                  obj[item.demandDetailNumName] = true;
                } else {
                  newArr.push(item);
                }
              } else {
                that.tableData = res.data.data.items;
              }
            });
            tableData.forEach((ele) => {
              let children = [];
              newArr.forEach((obj) => {
                if (ele.demandDetailNumName == obj.demandDetailNumName) {
                  children.push(obj);
                }
              });
              ele["children"] = children;
            });
            // that.tableData = tableData
            tableData.length == 0
              ? (that.tableData = res.data.data.items)
              : (that.tableData = tableData);
            // console.log(that.tableData);
            that.demandFlag = res.data.data.demandFlag;
            that.typeFlag = res.data.data.typeFlag;
            if (that.demandFlag == 1) {
              that.demandFlagShow = true;
              that.totalRow = res.data.data.totalCount; //主工单显示的总条数
            } else {
              that.demandFlagShow = false;
              // that.totalRow = that.tableData.length//子工单显示的总条数
              that.totalRow = res.data.data.totalCount; //子工单显示的总条数
            }
          } else {
            that.loadingDialogVisible = false
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //选中的渠道
    channelChange(event) {
      // this.demandParams.channel = event;
      this.getchannelId = String(event);
      this.judgeChannel = event;
      this.$sessionStorage.setItem('channelID',event)
      this.produceGroupList();
      this.getAccountList();
      this.getDemandOrder()
    },
    changePicVideo(val) {
      // this.demandParams.materialType = this.materiaForm.picOrvideo;
      this.$sessionStorage.setItem('picOrvideoValue',val)
      this.getDemandOrder();
    },
    //查询素材状态
    statusChange(event) {
      this.demandParams.opStatus = event;
    },
    //查询素材账户
    accountChange(val) {
      this.demandParams.acountId = val;
    },

    //获取账户名称下拉列表
    getAccountList() {
      let that = this;
      that.judgeChannel = that.materiaForm.showQdList
      that.accountUserId = that.$sessionStorage.getItem(config.USERID) || 1;
      if (that.judgeChannel == 3) {
        $http.axios
          .get(
            "/api/channel/accountUser/selectList?userId=" + that.accountUserId +"&type=1"
          )
          .then(function (response) {
            that.mPaccountOptions = response.data;
          });
      } else if (that.judgeChannel == 4) {
        $http.axios
          .get("/api/viat/accountUser/selectList?userId=" + that.accountUserId)
          .then(function (response) {
            that.mPaccountOptions = response.data;
          });
      } else if (that.judgeChannel == 5) {
        $http.axios
          .get("/api/xmat/accountUser/selectList?userId=" + that.accountUserId)
          .then(function (response) {
            that.mPaccountOptions = response.data;
          });
      }
    },

    //批量接收
    pljs() {
      let that = this;
      that.uploadParams.demandDetailIds = [];
      let checkedSelection = that.$refs.multipleTable.selection;
      checkedSelection.forEach(function (e, i) {
        that.uploadParams.demandDetailIds.push(e.id);
      });
      that.uploadParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/acpectdemand",
          that.uploadParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData.opStatus == 1;
            that.getDemandOrder();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //获取人员信息
    getStaffDetail() {
      let that = this;
      if (that.videoZuName.indexOf("视频组") != -1) {
        $http.axios
          .get(
            "/api/system/user/selectList?pageNum=" +
              that.page +
              "&pageSize=" +
              that.pageCount +
              "&deptId=313"
          )
          .then(function (response) {
            that.distributePeoplesOptions = response.data.rows;
            that.sjDeptId = 313;
          });
      } else {
        if (that.judgeChannel == 3) {
          //oppo
          $http.axios
            .get(
              "/api/system/user/selectList?pageNum=" +
                that.page +
                "&pageSize=" +
                that.pageCount +
                "&deptId=308"
            )
            .then(function (response) {
              that.distributePeoplesOptions = response.data.rows;
              that.sjDeptId = 308;
            });
        } else if (that.judgeChannel == 4) {
          //vivo
          $http.axios
            .get(
              "/api/system/user/selectList?pageNum=" +
                that.page +
                "&pageSize=" +
                that.pageCount +
                "&deptId=309"
            )
            .then(function (response) {
              that.distributePeoplesOptions = response.data.rows;
              that.sjDeptId = 309;
            });
        } else if (that.judgeChannel == 5) {
          //mi
          $http.axios
            .get(
              "/api/system/user/selectList?pageNum=" +
                that.page +
                "&pageSize=" +
                that.pageCount +
                "&deptId=314"
            )
            .then(function (response) {
              that.distributePeoplesOptions = response.data.rows;
              that.sjDeptId = 314;
            });
        }
      }
    },

    changeDistribute(event, index, val) {
      // console.log(event);
      // console.log(val);
      // console.log(index);
      // val.detailId = event
      this.distributeDetailId = event;
    },

    //点击指定回显数据
    distribute(e) {
      let that = this;
      // console.log(e);
      that.videoZuName = e.makerGroupName;
      that.distributeVisible = true;
      that.getStaffDetail(); //点击指定，弹出弹框时调用指定的设计人员接口数据
      that.fpParams.demandId = e.id;
      that.distributePeoples = "";
      that.checkList = [];
      that.materStaffNames = [];
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/todistribution?demandId=" +
            e.id
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.distributeData = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //指定人员弹框中选中的素材
    checkedDistribute(val) {
      // console.log(val);
      let that = this;
      that.materStaffIds = [];
      that.materStaffNames = [];
      // console.log(that.checkList);
      that.checkList.forEach(function (e, i) {
        that.materStaffIds.push(e.id);
        that.materStaffNames.push(
          e.resourceBits + e.size + "(" + e.needNum + ")"
        );
      });
    },

    //指定人员的分配人员
    changeFpStaff(val) {
      // console.log(val);
      let that = this;
      that.isShowPeople = true;
    },

    //新增分配工单
    newAddListBtn() {
      if (this.distributeDetailId == "") {
        this.fpParams.desDtos.push({
          detailId: "",
          needNum: "",
          userIdList: [],
        });
      } else {
        this.fpParams.desDtos.push({
          detailId: this.distributeDetailId,
          needNum: "",
          userIdList: [],
        });
      }
    },

    //删除分配工单
    removeRow(index) {
      if (index >= 0) {
        this.fpParams.desDtos.splice(index, 1);
        // this.distributeDetailId =''
      }
    },

    // changeUserList(event,index){
    //   this.fpParams.desDtos[index].userList.push(event)
    // },

    //点击分配接口
    distributeb() {
      let that = this;
      that.fpParams.userId = that.$sessionStorage.getItem(config.USERID) || 1;
      that.fpParams.userName = that.$sessionStorage.getItem(config.USERNAME);
      that.fpParams.deptId = that.sjDeptId;

      $http.axios
        .post(
          "/api/operationManagement/materialDemand/desDeamndWithNum",
          that.fpParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            // console.log(res);
            that.distributeVisible = false;
            that.getDemandOrder();
            that.fpParams.desDtos = [
              {
                detailId: "",
                needNum: "",
                userId: "",
              },
            ];
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //取消指定窗口
    distributeFalse() {
      this.distributeVisible = false;
      this.fpParams.desDtos = [
        {
          detailId: "",
          needNum: "",
          userId: "",
        },
      ];
    },

    //点击接收状态变化
    uploadForm(e) {
      let that = this;
      that.uploadParams.demandDetailIds = [];
      that.uploadParams.demandDetailIds.push(e);
      that.uploadParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/acpectdemand",
          that.uploadParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData.opStatus == 1;
            setTimeout(() => {
              that.getDemandOrder();
            }, 1000);
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //点击上传跳转页面
    upload(row) {
      if (row.acountChannel == 1 || row.acountChannel == 2) {
        this.$router.push({
          path: "/auth-management/UploadMaterial/" + row.id,
        });
      } else {
        this.$router.push({
          path: "/auth-management/UploadMaterialPicWait/" + row.id,
          query: { status: 111 },
        }); //vivo渠道的，用oppo的渠道id进行的测试，因为vivo还没有工单
      }
    },

    //主工单列表提醒按钮
    remindMe(i) {
      let that = this;
      this.$alert(
        `<div><img class="icon" src="${require("@/assets/img/warningIcon.png")}" /><div class="text_tip">您确定要提醒吗？</div></div>`,
        "提醒",
        {
          center: true,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          customClass: "icon_custom_popup",
          beforeClose: (action, instance, done) => {
            if (action == "confirm") {
              instance.confirmButtonLoading = true;
              that.remindMeParams.demandId = i;
              $http.axios
                .post(
                  "/api/operationManagement/materialDemand/warmGroupLeader",
                  that.remindMeParams
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    done();
                    instance.confirmButtonLoading = false;
                  } else {
                    that.$message({
                      showClose: true,
                      message: res.data.msg,
                      type: "error",
                    });
                    instance.confirmButtonLoading = false;
                  }
                })
                .catch(() => {
                  instance.confirmButtonLoading = false;
                });
            } else {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },

    // 分页
    handleSizeChange(val) {
      this.pageCount = val;
      var params = {
        page: this.page,
        pageCount: this.pageCount,
      };
      this.findAll(params);
    },
    handleCurrentChange(val) {
      this.page = val;
      var params = {
        page: this.page,
        pageCount: this.pageCount,
      };
      this.findAll(params);
    },
    //分页方法
    findAll(params) {
      this.getDemandOrder();
    },

    //表格中选中和未选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //删除工单--小米渠道待分配的状态下有删除按钮
    deleteDemand(id) {
      let that = this;
      this.$alert(
        `<div><img class="icon" src="${require("@/assets/img/deleteIcon.png")}" /><div class="text_tip">您确定要删除此条工单吗？</div></div>`,
        "删除",
        {
          center: true,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          customClass: "icon_custom_popup",
          beforeClose: (action, instance, done) => {
            if (action == "confirm") {
              instance.confirmButtonLoading = true;
              $http.axios
                .get(
                  "/api/operationManagement/materialDemand/deleteDeamand?deamandId=" +
                    id
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    that.getDemandOrder();
                    done();
                    instance.confirmButtonLoading = false;
                  } else {
                    that.$message({
                      showClose: true,
                      message: res.data.msg,
                      type: "error",
                    });
                    instance.confirmButtonLoading = false;
                  }
                })
                .catch(() => {
                  instance.confirmButtonLoading = false;
                });
            } else {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },

     //转让人
     getTransferList(){
      let _this = this
      console.log(_this.materiaForm.showQdList,99999)
      $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId="+_this.materiaForm.showQdList).then(function(response) {
        if(response.data.code == 200){
          _this.transferOptions = response.data.data
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    transferBth(){
      let _this = this, selection = [], length = '', transferId = []
      length = this.$refs.multipleTable.selection.length
      selection = this.$refs.multipleTable.selection
      console.log(this.$refs.multipleTable)
      if(length == 0){
        _this.$message({
          showClose: true,
          message: '请勾选工单',
          type: 'warning'
        })
      }else{
        this.transferDialog = true
        this.getTransferList()
        selection.forEach(item => {
          transferId.push(item.id)
        })
        _this.transferId = transferId
      }
    },
    transferClick(id){
      this.transferDialog = true
      this.getTransferList()
      let transferId = []
      transferId.push(id)
      this.transferId = transferId
    },
    cancelTransfer(ruleForm){
      this.$refs[ruleForm].resetFields()
      this.transferDialog = false
      this.$refs.multipleTable.clearSelection();
    },
    sureTransfer(ruleForm){
      let _this = this, 
      params = {
        'demandIds': _this.transferId,
        'toUserDeptId': Number(_this.ruleForm.transferName.deptId),
        'toUserId': Number(_this.ruleForm.transferName.userId),
        'toUserName': _this.ruleForm.transferName.string
      }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          $http.axios.post("/api/operationManagement/materialDemand/transfer", params).then(function(response) {
            if(response.data.code == 200){
              _this.getDemandOrder(1)
              _this.cancelTransfer('ruleForm')
            } else {
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          return false;
        }
      });
    }



  },
};
</script>

<style lang="scss" scoped>
.materialHchannel,
.materialHBtns {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
.materialHchannel {
  width: 30%;
}
.materialHBtns {
  width: 70%;
}
.materiaContent {
  margin-top: 25px;
}
.showTheForm,
.hideenTheForm {
  display: inline-block;
  vertical-align: top;
}
.showTheForm {
  position: relative;
}
.showTheForm i {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -11px;
  font-size: 22px;
  color: rgb(36, 132, 168);
  cursor: pointer;
}
.hideenTheForm i {
  font-size: 22px;
  vertical-align: middle;
  color: rgb(36, 132, 168);
  cursor: pointer;
  margin: 0 20px;
}
.showPeoples li {
  font-size: 0;
  margin: 10px 0;
}
.showPeoples .showPeoplesleft,
.showPeoples .showPeoplesRight {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  font-size: 14px;
  padding: 0 5px;
  box-sizing: border-box;
}
.showPeoples .showPeoplesRight span {
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}
.lilist {
  margin: 10px 0;
}
.showPeoples .showPeoplesRight span:first-child {
  width: 90px;
}
.zhugongdan {
  height: 67px;
  /* overflow:hidden!important;
    text-overflow:ellipsis!important ;
    white-space: nowrap!important; */

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.demandTable {
  width: 100%;
  overflow-y: scroll;
}
/* .materiaContent{
    position: relative;
    overflow: auto;
  }
  .el-table {
    position: absolute; 
    width: auto; 
    max-width: none;
    overflow: auto;
  } */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
.el-card {
  overflow: visible;
}
// .reference {
//   img {
//     width: 100%;
//   }
// }

.designated_personnel_dialog {
  .element_contant {
    justify-content: space-between;

    .el-form-item:not(:last-child) {
      width: 49% !important;
    }
  }
}
.loading{
  text-align:center;
}
.loading span,.loading i{
  display: block;
}
.loading span{
  font-size:20px;
  font-weight:700;
  color:#fff;
}
.loading i{
  margin-top:10px;
  font-size:60px;
  /* color: #409eff; */
  color:#fff;
}
/* .loadingBg{
  margin-top:200px;
} */
::v-deep .loadingBg .el-dialog{
  margin-top:200px;
  background:rgba(255,255,255,0)!important;
  box-shadow: none;
}
</style>
