<template>
    <div class="authority common-page">
        <PocCardTable>
          <template slot="headerTitle">
            <div class='hl_header'>
              <div class="hl_breadCrumb hl_headerLeft" >
                <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                  <el-breadcrumb-item :to="{ path: '/vivoAccount-management/AccountSelectionVivo' }">计划管理</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/vivoAccount-management/AccountSelectionVivo' }">账户选择</el-breadcrumb-item>
                  <el-breadcrumb-item>广告计划</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <div class="hl_headerRight">
                <el-dropdown trigger="click" @command = "changeStatusList" style="margin-left:10px;">
                  <el-button type="primary" size="small" @click="updateOppoSet('planStatus')">
                    修改状态
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button type="primary" size="small" @click = "zdyFn">自定义列</el-button>
                <el-button type="primary" size="small" @click = "exportAdvert">下载报表</el-button>
              </div>
            </div> 

            <div class="chanelTab">
              <span class="channel_vivo" @click="clickChangeChannel('adPlanVivo')">广告计划</span>
              <span class="channel_vivo" @click="clickChangeChannel('adTeamVivo')">广告组</span>
              <span class="channel_vivo channelActive">广告</span>
            </div>      
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-input v-model = "adPlanNameOrID" clearable oninput="value=value.replace(/^\.+|[^\d.]/g,'')" placeholder="请输入广告计划ID搜索"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "adGroupNameOrID" clearable oninput="value=value.replace(/^\.+|[^\d.]/g,'')" placeholder="请输入广告组ID搜索"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "adNameOrID" clearable placeholder="请输入广告名称搜索"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "adId" 
                    clearable           
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入广告ID搜索"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选择广告状态" v-model="advertStatus">
                      <el-option
                        v-for="(item,i) in qdoptions.advertStatusOption"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>   
                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选广告创意" v-model="channel">
                      <el-option
                        v-for="(item,i) in qdoptions.genType"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>             
                  <el-form-item label="">
                    <div class="block">
                      <el-date-picker
                        v-model="value1"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "v3groupListFn(page,adPlanNameOrID,adGroupNameOrID,adNameOrID,adId,advertStatus,channel,value1)" >查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
            </div>
            <div class="clearfix">
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  id="nextBtn"
                  row-key="id"
                  :default-expand-all = 'false'
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  @cell-click="clickcell"
                  @selection-change="handleSelectionChange">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column 
                    width='200'
                    label="广告名称">
                    <template slot-scope="scope">
                      <div v-if="scope.row.name!=undefined">
                        <span class="adTeamName">{{scope.row.name}}</span>
                        <i class="el-icon-edit adTeamEditBtn" @click="editAdTeamName(scope)"></i> 
                      </div>
                      <div v-else class="demo-image__preview">
                        <span v-if="scope.row.videoFullUrl == null">
                          <el-image 
                            style="width: 100px; height: 100px"
                            :src="scope.row.imgsUrl" 
                            :preview-src-list="scope.row.imgsFullUrl">
                          </el-image>
                        </span>
                        <span v-else>
                          <!-- 视频 -->
                          <img slot="reference" :src="scope.row.imgsFullUrl" style="width:50%;cursor: pointer" @click="showVideo(scope.row)">
                        </span>
                        
                      </div>
                    </template>
                  </el-table-column>
                  <template v-for="(item, index) in titleList">
                      <el-table-column
                      :key="index"
                      :prop="item.prop"
                      :label="item.name"
                      :align="item.align || 'center'"
                      :show-overflow-tooltip="item.overHidden || true">
                        <template slot-scope="scope">
                          <!-- // 这里通过插槽实现自定义列 -->
                          <slot
                            v-if="item.slot"
                            :name="scope.column.property"
                            :row="scope.row"
                            :$index="scope.$index"/>
  
                          <div v-else                                   
                          :disabled = "scope.row.disabledIs"
                          >
                        <span v-if='scope.column.property == "pauseState" || scope.column.property == "priceSwitch" || scope.column.property =="startSwitch" || scope.column.property == "genType"'>
                              {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                          <i v-if = 'scope.column.property =="startSwitch" ||scope.column.property == "priceSwitch"'
                            :class = "{'isSwitch':scope.column.property =='startSwitch'||scope.column.property =='priceSwitch','active':(scope.column.property =='startSwitch'||scope.column.property =='priceSwitch')&&scope.row[scope.column.property]==0}"></i>
                            </span>
                            <span v-else>{{ scope.row[scope.column.property] }}</span>
                          </div> 
                          <template v-if='item.prop=="pauseState"'>
                            <el-switch
                              v-model="scope.row.pauseState"
                              class="switchStyle"
                              :active-value="0"  
                              :inactive-value="1"
                              active-color="#48916F"
                              inactive-color="#EAEFED"
                              @change = "changeStatus(scope.row.pauseState,scope)"
                              >
                            </el-switch>
                          </template>
                        </template>
                      </el-table-column>
                    </template>
                    <el-table-column 
                    prop='statusDesc'
                    width='160'
                    label="投放状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.statusDesc == '已暂停'" class="statusOtherBtn ">{{scope.row.statusDesc}}</span>
                      <span v-else class="statusBtn">{{scope.row.statusDesc}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="zqtime"
                    label="创建时间"
                    width="120">
                    <template slot-scope="scope">{{scope.row.createDate | dateFormat}}</template>
                  </el-table-column>
                  <el-table-column 
                    fixed = 'right'
                    align="center"
                    width='100'
                    :show-overflow-tooltip="true"
                    label="操作">
                    <template slot-scope="scope">
                      <el-button class="newBtn_darkRed" plain size="mini" v-if="scope.row.statusDesc=='审核不通过'" @click="changeMaterial(scope.row)">更换素材</el-button>
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row.id)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
              <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="280px">
              <span class="el-icon-loading uploading uploadIcon" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>

            <!-- 视频播放 -->
            <el-dialog
              title=""
              :visible.sync="centerDialogVisible"
              width="100%"
              ref="videoBtn"
              center
              :before-close="handleDialogClose"
              class="mineBg">
              <span style="text-align:center;display:block;">
                <video :src="videoUrlShow" autoplay controls controlslist="nodownload" ref="videoPouse" style="width:60%;margin:0 auto;"></video>
              </span>
            </el-dialog>

             <!-- 修改广告名称 -->
             <el-dialog title="修改广告名称" :visible.sync="editNameVisible" width="40%">
              <el-form :model="editNameForm">
                <el-form-item label="广告名称" label-width="80px">
                  <el-input v-model="editName"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editNameVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditName">确 定</el-button>
              </div>
            </el-dialog>

            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <creatCopyDialog :visible.sync="copyShowDialog" :tableDataList = 'tableDataList' :params = "params" :copyCreatList = "copyCreatList" :pubVue = "pubVue"></creatCopyDialog>
      <earlyWarningDialog :visible.sync="showDialog"></earlyWarningDialog>
      <zdyVivo :visible.sync="showzdyDialog" ref = 'zdyVivo' :pubVue="pubVue" :typeName = "typeName"></zdyVivo>
      <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :advertName = "advertName" :pubVue="pubVue" :command = "command+''" :typeName = "'advert'"></alertStartStopDialog>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import earlyWarningDialog from '@/views/dialog/earlyWarningDialog'
  import zdyVivo from './zdyVivo'
  import creatCopyDialog from "@/views/dialog/creatCopyDialog";//批量设置弹框
  import startLimitSingleVivoDialog from '@/views/dialog/startLimitSingleVivoDialog'
  import alertStartStopDialog from '@/views/dialog/alertStartStopDialog'


  import Qs from 'qs'
  export default {
    components: {
      earlyWarningDialog,
      zdyVivo,
      creatCopyDialog,
      startLimitSingleVivoDialog,
      alertStartStopDialog
    },
    props:{
      newVue:Object,
    },
    data() {
      return {
        showDialog: false,
        showzdyDialog:false,
        copyShowDialog:false,
        alertStartStopDialogIs:false,

        pubVue: this,//空vue，用于子组件之间传值
        exprtVisible:false,//
        uploadingShow:false,//确认导出弹框

        warningOpenList:[],//['开启','关闭']
        qdoptions:{
          // genType:[{text:'所有创意',id:'2'},{text:'自定义创意',id:'0'},{text:'程序化创意',id:'1'}],
          genType:[{text:'自定义广告',id:0},{text:'程序化广告',id:1}],
          extensionTypesList:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],
          flowScenesList:[{text:'全流量场景',id:'1'},{text:'精选推广',id:'2'},{text:'安装有礼',id:'3'},{text:'开机必备',id:'4'},{text:'搜索',id:'5'},{text:'信息流',id:'6'},{text:'Banner',id:'7'},{text:'开屏',id:'8'},{text:'插屏',id:'9'},{text:'原生',id:'10'},{text:'激励视频',id:'11'},{text:'投类型',id:'12'}],
          extensionFlowsList:[{text:'全流量',id:'1'},{text:'自有流量',id:'2'},{text:'联盟流量',id:'3'}],
          advertStatusOption:[{text:'所有数据',id:0},{text:'已删除',id:1},{text:'已暂停',id:2},{text:'广告组暂停',id:3},{text:'投放中',id:4}]
        },//选择状态
        titleList:[
          // {name:'广告名称',prop:'name'},
          {name:'开关',prop:'pauseState'},
          {name:'广告ID',prop:'id'},
          {name:'所属广告组名称',prop:'groupName'},
          {name:'所属广告组ID',prop:'groupId'},
          {name:'所属广告计划名称',prop:'campaignName'},
          {name:'所属广告计划ID',prop:'campaignId'},
          {name:'广告生成类型',prop:'genType'},//广告生成类型 0:自定义广告 1:程序化广告
          // {name:'创意图',prop:'imgsFullUrl'}
          // {name:'状态',prop:'statusDesc'},
        ],//title名称
        showStatusList:{pauseState:[''],
                        genType:['自定义广告','程序化广告']},

        adName:'',//请输入创意名称查找
        adId:'',//请输入创意ID查找
        channel:'',//请选择状态
        channelm:'',//推广流量
        channelo:'',//推广目标
        channeln:'',//推广场景
        value1:[],
        params:{},
       tableData:[],
       
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dataList:'',
        advertIds:[],
        copyBtnDis:false,//批量复制按钮是否可点击
        copyCreatList:[],
        tableDataList:[],
        myHeaders: {Authorization:''},
        fileList:{},
        adGroupIds:null,
        typeName:3,
        editNameVisible:false,
        editNameForm:{},
        editName:'',
        advertName:'',
        advertStatus:'',//广告状态
        adPlanNameOrID:'',//广告计划
        adGroupNameOrID:'',//广告组
        adNameOrID:'',//广告
        command:'',
        closeGroupList:'',
        creativeNameList:[],//判断是创意
        createIds:[],//创意ID列表
        materialAgain:false,
        videoUrlShow:'',
        centerDialogVisible:false,

      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.params.userId  = this.userId
      this.params.userName  = this.userName
      this.params.deptId = this.deptId
      this.params.ownerId = this.$route.query.id
      this.params.ownerName =this.$route.query.name
      this.type =this.$route.query.type
      this.getId()
      this.fileList={
        "ownerId":this.params.ownerId,
        "operaType":30,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
      if(this.type==undefined){
        this.v3groupListFn(1)
      }else{
        this.v3groupListFn(1,'',this.type)
      }
      this.$refs.zdyVivo.allWords(3)
    },
    methods: {
      //弱提示
      alertMsgFn(msg,type){
        let _this = this
        _this.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
      //tab切换
      clickChangeChannel(url){
        this.$router.push({
          path: '/vivoAccount-management/'+url,
          query: {
            id: this.params.ownerId,
            name:this.params.ownerName,
          }
        })
      },
      load(tree, treeNode, resolve) {
        setTimeout(() => {
          resolve([
            {
              id: 31,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }, {
              id: 32,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }
          ])
        }, 1000)
    },
      getId(){
        this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
        this.uploadId = this.$route.params && this.$route.params.id
        // console.log(this.uploadId)
      },
      zdyFn(){
        this.$refs.zdyVivo.generateTimes(3)
      },
      exportAdvert(){
        this.exprtVisible = true
      },
      //文件上传成功时的钩子
      onSuccess(response, file, fileList, i) {
        let that = this,dataSource =[],keywords = []
        // let video = []
        // console.log(response)
        if(response.code==200){
          that.v3groupListFn()
        
        }else{
          that.$message({
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }
        this.$refs.upload.clearFiles()
      },
      //下载报表
      downLoadTable(){
        let _this = this,params ={},date = new Date().getTime(),myDate = new Date()
        let Time2 = myDate.toISOString().slice(0,10)//推荐 yyyy-MM-dd
        //延伸  取yyyyMMdd
        let Time3 = Time2.replace(/[^0-9]/ig,'').substring(0,8)
        params = {
          'ownerId':_this.params.ownerId,
          'deptId':_this.deptId,
          'userId':_this.userId,
          'userName':_this.userName,
          'startDate':_this.value1[0]||Time2,
          'endDate':_this.value1[1]||Time2,
          //查询条件  
          'adGroupId':_this.adGroupNameOrID,
          'adName':_this.adNameOrID,
          'adPlanId':_this.adPlanNameOrID,
          'adIds':_this.adId,
          // 'genType':Number(_this.channel),//状态
          // 'status':Number(_this.advertStatus)
          'genType':_this.channel,//状态
          'status':_this.advertStatus
        },
            
        _this.uploadingShow = true
        $http.axios.post("/api/vivo/advertising/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        // $http.axios.post("/guanjia/advertising/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
          _this.exprtVisible = false 
          //添加{responseType: 'blob'}  导出文件以下代码都是
          let blob = response.data
          let reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = e => {
            let a = document.createElement('a')
            a.download = "广告报表"+myDate+".xls"
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            _this.uploadingShow = false
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
      },
      creatPlan(url,type){
        // this.$router.push("/plan-management/"+routerTo)
        this.$router.push({
          path: url,
          query: {
            ownerId: this.params.ownerId,
            ownerName:this.params.ownerName,
            type:type
          }
        })
      },
      // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
      clickcell(row, column, cell, event){
      // console.log(row);
      // console.log(column);
      // console.log(cell);
      // console.log(event);
      let yjType = event.target.parentNode.dataset.type
      if(column.property=='rebateBal'||column.property=='cashCost'||column.property=='cashCost'){
        // console.log(666)
      }
      if(column.type=="selection"){
          this.$refs.multipleTable.toggleRowSelection(row)
      }
      // if(yjType == 'qtBtn'){
      //   this.goPlan('/plan-management/SetPricess',row.planId,row.planName)
      // }else 
      if(yjType == 'delBtn'){
        // "accountId": 0,
        // "deptId": 0,
        // "planId": 0,
        // "userId": 0,
        // "userName": ""


        // let text = "您是否确认删除，删除后不可恢复？"
        // this.$confirm(text,"提示",{
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        // }).then(()=>{
        //   //确认，将改变后的状态值赋给绑定的itemValue
        // this.deletePlanFn(row.adGroupId)
        // }).catch(()=>{
        //   //取消，将对立状态值赋给itemValue
          
        // })

        // this.deletePlanFn(row.adGroupId)

      }else if(yjType == 'edit'){
        this.$router.push({
          path: 'editAdCreat',
          query: {
            ownerId: this.params.ownerId,
            ownerName:this.params.ownerName,
            "planId":row.planId ,//计划ID
            "planName":row.planName,//计划名称
            "adGroupId":row.adGroupId,//广告组ID
            "adGroupName":row.adGroupName,//广告组名称
            "extensionType":row.extensionType,//推广目的ID
            "extensionTypeName":row.extensionTypeName,//推广名称
            "flowScene":row.flowScene,//
            "flowSceneName":row.flowSceneName,//
            "extensionFlow":row.extensionFlow,//
            "extensionFlowName":row.extensionFlowName,//
            'type':30
          }
        })
      }
    }, 
     // 分页
     handleSizeChange(val) {
        let _this = this
        _this.pageCount = val
        if(this.type==undefined){
          _this.findAll(_this.page)
        }else{
          _this.findAll(_this.page,this.type)
        }
      },
      handleCurrentChange(val) {
        this.page = val
        if(this.type==undefined){
          _this.findAll(_this.page)
        }else{
          _this.findAll(_this.page,this.type)
        }
      },
    //分页方法
    findAll (page,adGroupNameOrID) {
      //获取数据接口（调用封装的接口）
      // this.v3groupListFn(page,this.adName,this.adId,this.channel,this.channelm,this.channeln,this.channelo,this.value1)
      adGroupNameOrID = adGroupNameOrID|| this.adGroupNameOrID
      this.v3groupListFn(page,this.adPlanNameOrID,adGroupNameOrID,this.adNameOrID,this.adId,this.advertStatus,this.channel,this.value1)
    },
    // 批量启停设置
    updateOppoSet(type){

      let _this = this
      let advertIds = []//声明一个空数组
      //  _this.ownerIdList = [1100018542,1100018078]
        // that.getzdyData 去重
        _this.advertIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          advertIds.push(v)
        }
      })
      
      if(_this.advertIds.length!=0|| _this.createIds.length!=0){
        _this.params.advertIds = _this.advertIds
        _this.params.createIds = _this.createIds
        _this.btnIsFn(_this.params,type)
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
      },
      // 按钮判断
      btnIsFn(params,type){
        let _this = this
        if(type == 'startStop'){
          _this.timeDialog = true
        }else if(type == 'planStatus'&&_this.planStatus){
          _this.warningOpenList = ['开启','关闭']
        }else if(type == 'dayLimt'){

        }else if(type == 'copy'&&_this.planStatus){
          _this.copyShowDialog = true
          _this.newVue.$refs.adTeam[0].$children[0].grouplistFn(1,100)
          setTimeout(function(){
            _this.tableDataList = _this.newVue.$refs.adTeam[0].$children[0].tableData
          },3000)

        }

      },  
      //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
      warmIs(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
        // /adPlan/v1/checkPlanDailyMoneySet
        let _this = this
        let isTrue = _this.updateOppoSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        if(isTrue){
            _this.yjCheck(type,url)     
        }
      },
      yjCheck(type,url,adPlanId,dayBudget){
        let _this = this,messageContent,params = []
        if(type == 'warningOpen'){
          params = [_this.params.ownerId]
        }else if(type == 'warningPrice'){
          _this.advertIds.forEach(obj=>{
            params.push(obj.adGroupId)
          })            
          }else{
          _this.advertIds.forEach(obj=>{
            params.push(obj)
          })
        }

        $http.axios.post("/api/advertisement"+url,params).then(res=> {
          let content = []
          if(res.data.code==200){
            content = res.data.content
            if(content == null){
              if(type == 'dayLimit'){
                this.showSingleDialog = true
              }else{
                  _this.setCjWarningDialogShow = true//记得删掉
                  _this.$refs.setCj.getAdGroupWarningDetailFn()//记得删掉
                messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
              
                _this.$message({
                  showClose: true,
                  message: messageContent,
                  type: 'error'
                })
              }
                
              }else{
                if(type == 'dayLimit'){
                //   let msgContent =  content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
                //   _this.$message({
                //   showClose: true,
                //   message: msgContent,
                //   type: 'error'
                // })

                  let text = content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
                  this.$confirm(text,"提示",{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                  }).then(()=>{
                    this.showSingleDialog = true
                  }).catch(()=>{
                    
                  })
                }else if(type == 'warningPrice'){
                  // _this.warningOpenList = ['开启','关闭']
                  _this.setCjWarningDialogShow = true
                  _this.$refs.setCj.getAdGroupWarningDetailFn()
                }else if(type == 'yjBtn'){
                  _this.showDialogIs = true
                  _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.params.ownerId,type)
                }
              }
            }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
            }
          }).catch(error=>{
            let messageContent = error.message || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })        
          }) 
        }, 
        
      //广告
      changeStatus(value,scope) {
        //先把值赋为原来的值，保证swich状态不变
        // console.log(value)
        // console.log(scope)
        // console.log(scope.$index)
        let _this = this,params = {},advertIdsValue,groupName = []

        if(scope.row.title!=undefined){
          this.createIds=[scope.row.id]
          this.tableData[scope.$index-1].children.forEach((obj,i,arr)=>{
            if(obj.id==scope.row.id){
              // obj.pauseState = value == 0?1:0//需求更改暂时注释
              // obj.pauseState = value 
            }
          })
          advertIdsValue = String(this.createIds)
          this.updateOppoAdFn(scope.row.pauseState,[scope.row.id],scope)//需求更改暂时打开


        }else{
          this.advertIds=[scope.row.id]
          // this.tableData[scope.$index].pauseState = value == 0?1:0//需求更改暂时注释
          advertIdsValue = String(this.advertIds)
          // this.tableData[scope.$index].pauseState = value
          this.updateOppoAdAdvetStatusFn(scope.row.pauseState,[scope.row.id],scope)//需求更改暂时打开
        }
     
    },   
   
    changeStatusList(command){
      let _this = this,createIds,advertIds,text = ''
      createIds = _this.createIds
      advertIds = _this.advertIds
      if(createIds.length!=0){
        this.updateOppoAdFn(command)
      }
      if(advertIds.length!=0){
        this.updateOppoAdAdvetStatusFn(command)
      }
    }, 
      //选择行
      handleSelectionChange(val){
        let _this = this
        let advertIds = [],createIds = [],yulanList = [],objList = [],startSwitch = [],senceList = []
        // _this.limitIs = false
        // console.log(val)
        _this.copyCreatList = []

        val.forEach((obj,i,arr)=>{
          let cur = obj.pauseState
          if(obj.title!=undefined){
            createIds.push(obj.id)
          }else{
            advertIds.push(obj.id)

          }
          // console.log(createIds,advertIds)

          yulanList.push({ 'adGroupName':obj.adGroupName,'adGroupId':obj.adGroupId,'planName':obj.campaignName,'planId':obj.campaignId,'price':obj.price,'planStatus':obj.pauseState})
          senceList.push({'extensionType':obj.extensionType,'flowScene':obj.flowScene})
          // if(obj.dayBudget == '不限'){
          //   _this.limitIs = true
          // }
          _this.copyCreatList.push({
            // 'extensionTypeName':obj.extensionTypeName,
          // 'extensionFlowName':obj.extensionFlowName,
          // 'flowSceneName':obj.flowSceneName,
          'adId':obj.id,
          'adName':obj.name})
          objList[cur] = cur
          // if(objList.length==0){
          //   objList[i] = cur
          // }else{
            // objList[cur] = cur
          // }
        })
        // objList.forEach((obj,i)=>{
        //     if(objList.length == 2&&objList[0]!=undefined){
        //     _this.planStatus = false
        //     _this.warningOpenList = []
        //     _this.$message({
        //         showClose: true,
        //         message: "选择的计划状态不一致，选择同一种计划状态后才可操作",
        //         type: 'error'
        //       })        
        //     }else{
        //     _this.planStatus = true
        //   }
        // })
          _this.planStatus = true
          _this.advertIds = advertIds
          _this.createIds = createIds
          _this.startSwitch = startSwitch
          _this.yulanList = yulanList
          if(val.length == 0){
            _this.warningOpenList = []
            _this.planStatus = false

          }
        
        
      _this.copyIsTrueFn(senceList)
    },    
    //批量复制判断
    copyIsTrueFn(copyList){
      let _this = this
      copyList.forEach((v,i,arr)=>{
        let extensionType = arr[i+1]?arr[i+1].extensionType : v.extensionType
        let flowScene = arr[i+1]?arr[i+1].flowScene : v.flowScene
        if(v.extensionType !=extensionType || v.flowScene != flowScene){
          _this.copyBtnDis = true
            // return
            // break // 语法报错
            throw new error // 主动去抛出一个错误
        }else{
          _this.copyBtnDis = false 
        }
      })
    },
    //重置
    resetForm(){
        let _this = this
        _this.clearFn()
        _this.v3groupListFn()
       
      },
      clearFn(){
        let _this = this
        _this.adPlanNameOrID = ''
        _this.adGroupNameOrID = ''
        _this.adNameOrID = ''
        _this.adId = ''
        _this.advertStatus = ''
        _this.channel = ''
        _this.value1 = ''
      },
    //按钮开关
    getBrandList(){},
    // 自适应表格列宽
   

    // "adGroupId": 0,
    // "adIds": [],
    // "adName": "",
    // "adPlanId": 0,
    // "genType": 0,
    // "ownerId": "",
    // "status": 0,
    // "userId": 0,
    // "userName": "",
    // "deptId": 0,
    // "pageIndex": 0,
    // "pageSize": 0,
    // "endDate": "",
    // "startDate": ""

    // 广告列表
    // v3groupListFn(page,adName,adId,channel,channelm,channeln,channelo,value1){
    v3groupListFn(page,adPlanNameOrID,adGroupNameOrID,adNameOrID,adId,advertStatus,channel,value1){
      let _this = this,params,pagetTxt,price,ocpc,deepOcpc,adIds = [],tableData = [],tableData1 = []
      _this.tableData = []

      pagetTxt = {
        'pageSize' : _this.pageCount,
        'pageIndex' : page || 1,
        'adGroupId':adGroupNameOrID,
        'adName':adNameOrID,
        'adPlanId':adPlanNameOrID,
        'adIds':adId,
        // 'genType':Number(channel),//状态
        // 'status':Number(advertStatus)
        'genType':channel,//状态
        'status':advertStatus
      }
      if(value1 != undefined&&value1 != ''){
        pagetTxt.startDate = value1[0]
        pagetTxt.endDate = value1[1]
      }
      
      params = Object.assign({},_this.clone(_this.params), pagetTxt)  
      // $http.axios.post("/api/vivo/advertising/list",params).then(res=> {///guanjia
      $http.axios.post("/api/vivo/advertising/list",params).then(res=> {///guanjia
        if(res.data.code==200){
          if(res.data.data.list != null){
              res.data.data.list.forEach((obj,i)=>{
              if(obj.warningSwitch == null){
                obj.warningSwitch = 2
              }
              _this.creativeListFn(obj,obj.id,i,[])
            })
            // _this.creativeListFn(res.data.data.list[0],res.data.data.list[0].id)
          }
          _this.totalRow = res.data.data.pageInfo.rowCount
          // _this.clearFn()
          // _this.tableData.sort(function(a, b){return a.id - b.id})
          // console.log(_this.tableData)
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },

    // /creative/list
    creativeListFn(data,advertisementIds,i,tableData){
      // _this.tableData = res.data.data.list
      let _this = this,params
      _this.tableData = []
      params = {'advertiserId':_this.params.ownerId,
                'advertisementIds':advertisementIds}
      $http.axios.post("/api/vivo/creative/list",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {///guanjia
        if(res.data.code==200){
          if(res.data.data.list.length!=0){
            if(data.id==res.data.data.list[0].advertisementId){
              data.children = res.data.data.list
              data.children.forEach(obj=>{
                if(obj.imgsFullUrl!=null){
                  obj.imgsUrl = obj.imgsFullUrl.split(',')[0]
                  obj.imgsFullUrl = obj.imgsFullUrl.split(',')
                }
              })
            }
          }
          // data.forEach(obj=>{
            // if(data.id==res.data.data.list[0].advertisementId){
            //   data.children = res.data.data.list
            //   data.children.forEach(obj=>{
            //     obj.imgsUrl = obj.imgsFullUrl.split(',')[0]
            //     obj.imgsFullUrl = obj.imgsFullUrl.split(',')
            //   })
            // }
          // })
          // console.log(res.data.data.list)
          // console.log(data)
          _this.tableData.push(data)
          _this.tableData.sort(function(a, b){return a.id - b.id})
          // console.log(_this.tableData)
        }
      })
    },
    //广告启停
    updateOppoAdAdvetStatusFn(command,advertIds,scope){
      let _this = this,type,params,advertIdsString
      advertIds = advertIds||_this.advertIds
      advertIdsString = String(advertIds)
        params = {
          'advertiserId':this.$route.query.id,
          'ids':advertIdsString,
          'startAndStop':command
        }      
      $http.axios.post("/api/vivo/advertising/startAndStop",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.advertIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
        // _this.v3groupListFn(this.page)
          type = "success"
        }else{
          type = "error"
          this.tableData[scope.$index].pauseState = command?0:1
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //创意启停
    updateOppoAdFn(command,createIds,scope){
      let _this = this,type,params,createIdsString
      createIds = createIds||_this.createIds
      createIdsString = String(createIds)
        params = {
          'advertiserId':this.$route.query.id,
          'ids':createIdsString,
          'startAndStop':command
        }      
      $http.axios.post("/api/vivo/creative/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.advertIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
        // _this.v3groupListFn(this.page)
          type = "success"
        }else{
          type = "error"
          this.tableData[scope.$index].pauseState = command?0:1
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //修改状态
    // /ad/v1/updateOppoAdStatus
    updateOppoAdStatusFn(command,advertIds){
      // "deptId": 0,
      // "advertIds": [],
      // "ownerId": 0,
      // "status2": "",
      // "userId": 0,
      // "userName": ""
      let _this = this,type,params,params1
      advertIds = advertIds||_this.advertIds
      params1={'adIds':advertIds,
        'status2' : command.toString(),}
      params = Object.assign({},_this.clone(_this.params), params1)
      $http.axios.post("/api/advertisement/ad/v1/updateOppoAdStatus",params).then(res=> {//本地
        _this.warningOpenList = []
        _this.planStatus = false
        _this.advertIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
          type = "success"
          _this.v3groupListFn()
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // 删除
    deletePlanFn(planId){     
      let _this = this,params = {},type = ''
      params ={
        'advertiserId':String(_this.$route.query.id),
        'ids':String(planId)
      } 
      $http.axios.post("/api/vivo/advertising/dels",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.v3groupListFn(this.page)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },

    //修改广告组名称
    editAdTeamName(item){
      // console.log(item)
      this.editId = String(item.row.id)
      if(item.column.label == '广告名称'){
        this.editNameVisible = true
        this.editName = item.row.name
      }
    },

    //修改广告计划名称
    sureEditName(){
      let that = this, params = {}
      params = {
        'advertiserId':String(that.$route.query.id),
        'id':that.editId,
        'name':that.editName,
        // 'type':1
      }
      $http.axios.post("/api/vivo/advertising/editNameById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      // $http.axios.post("/guanjia/advertising/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          that.editNameVisible = false
          that.v3groupListFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //点击更换素材跳转页面
    changeMaterial(row){
      if(row.videoFullUrl == null){
        this.$router.push({name:'VivoAccountManagement.ChangeMaterialListPic',
        query:{
          advertiseId:this.$route.query.id,//广告主id
          id:row.id,//创意id
          materialNormId:row.normId,//创意无聊规格id
          placeType:row.placeType,//展现形式
          virtualPositionId:row.virtualPositionId,//虚拟广告位id
          advertId:row.advertisementId,//广告id
          name:row.advertisementName,//广告名称
          ownerName:this.$route.query.name,//广告主name
          appName:row.appName,//应用名
          productName:row.productName,//产品名
          subTitle:row.subTitle,//副标题
          title:row.title,//标题
          groupId:row.groupId,//广告组id
        }})
      }else{
        this.$router.push({name:'VivoAccountManagement.ChangeMaterialList',
        query:{
          advertiseId:this.$route.query.id,//广告主id
          id:row.id,//创意id
          materialNormId:row.normId,//创意无聊规格id
          placeType:row.placeType,//展现形式
          virtualPositionId:row.virtualPositionId,//虚拟广告位id
          advertId:row.advertisementId,//广告id
          name:row.advertisementName,//广告名称
          ownerName:this.$route.query.name,//广告主name
          appName:row.appName,//应用名
          productName:row.productName,//产品名
          subTitle:row.subTitle,//副标题
          title:row.title,//标题
          groupId:row.groupId,//广告组id
        }})
      }
      
    },
    //根据id弹出视频
    showVideo(row){
      console.log(row)
      this.centerDialogVisible = true
      this.videoUrlShow = row.videoFullUrl
      this.$nextTick(() => {
        console.log(this.$refs.videoPouse)
        this.$refs.videoPouse.play()
      })
    },
    handleDialogClose(){
      this.centerDialogVisible = false
      this.$refs.videoPouse.pause()
    },


  }
  }
  </script>
  
  <style lang="scss" scoped>
    
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */

     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
        margin-right: 15px;
        font-size: 13px;
        color: #606266;
      }
      .allOperation .totalNum i{
          font-style: initial;
        }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */

    /* 操作按钮样式开始 */
    /* .planeBtn,.offBtn,.delBtn{
      display: inline-block;
      margin: 0 10px;
    } */
    /* 操作按钮样式结束 */
    .MBOTTOM{
      margin-bottom:10px;
    }
    .MBOTTOM button{margin-right: 10px;}
    #uploadBtn{
      float: right;
    }
    .el-button.el-button--primary{
      margin: 0 10px 0 0!important;
    }
    .adTeamEditBtn{
      padding-left:10px;
      color:#333;
      cursor: pointer;
      display:none;
    }
    .el-table .el-table__body tr:hover td .adTeamEditBtn{
      display:inline-block;
    }
    .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .uploadIcon{
      font-size:40px;
      display:block;
      text-align:center;
    }
    
    .el-table td div{
      display: inline;
    }
    ::v-deep .mineBg{
     .el-dialog {
      background:none!important;
      box-shadow:none!important;
      .el-dialog__headerbtn .el-dialog__close{
        color:#fff;
        font-size:24px;
      }
    }
  }
  /* tab切换当前样式 */
  .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }
   /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  .footerBottom{
    padding-bottom:30px;
  }
  ::v-deep .dateTab .el-tabs__nav{
    margin-left:14px;
  }
  ::v-deep .dateTab .el-tabs__item{
    background:#ECF3F0!important;
    padding:0!important;
    padding:0 20px!important;
    margin:0 10px;
    border-radius:4px;
    color:#2F674D;
    font-weight:500;
  }
  ::v-deep .dateTab .el-tabs__item.is-active{
    background:#48916F!important;
    color:#fff;
  }
  </style>
