
// 计划
import AccountSelection from '@/views/auth/planManage/accountSelection';
import AccountSelectionApple from '@/views/auth/planManage/accountSelectionApple';
import AccountSelectionGaode from '@/views/auth/planManage/accountSelectionGaode';
import AdvertisingPlan from '@/views/auth/planManage/advertisingPlan';
import UploadPage from '@/views/auth/planManage/uploadPage';
import UploadPageGaode from '@/views/auth/planManage/uploadPageGaode';
import CreatPlan from '@/views/auth/planManage/creatNewPlan';
import PriceModify from '@/views/auth/planManage/modifyPrice/PriceModify';
import PriceModifyPlan from '@/views/auth/planManage/modifyPrice/PriceModifyPlan';
import PriceModifyGroup from '@/views/auth/planManage/modifyPrice/PriceModifyGroup';
import SetPrice from '@/views/auth/planManage/modifyPrice/SetPrice';
import EditPrice from '@/views/auth/planManage/modifyPrice/EditPrice';
import StartStop from '@/views/auth/planManage/modifyPrice/StartStop';
import StartStopAdvert from '@/views/auth/planManage/modifyPrice/StartStopAdvert';
import SetPricess from '@/views/auth/planManage/modifyPrice/SetPricess';
import EditPricess from '@/views/auth/planManage/modifyPrice/EditPricess';
import SetAdgroupPrice from '@/views/auth/planManage/modifyPrice/SetAdgroupPrice';
import EditAdgroupPrice from '@/views/auth/planManage/modifyPrice/EditAdgroupPrice';
import AdgroupTeamEdit from '@/views/auth/planManage/AdgroupTeamEdit';
import EditAdCreat from '@/views/auth/planManage/editAdCreat';
import MaterialClassify from '@/views/auth/operateManage/MaterialClassify';
import CopyPage from '@/views/auth/planManage/copyPage';
import AdPlanErdai from '@/views/auth/planManage/adPlanErdai';
import CopyPageEd from '@/views/auth/planManage/copyPageEd';
import AdvertisingPlanApple from '@/views/auth/planManage/advertisingPlanApple';//苹果计划
import AdPlanApple from '@/views/auth/planManage/adPlanApple';//苹果广告系列
import AdTeamApple from '@/views/auth/planManage/adTeamApple';//苹果广告组
import AdOperateApple from '@/views/auth/planManage/adOperateApple';//苹果操作记录
import AdKeywordApple from '@/views/auth/planManage/adKeywordApple';//苹果关键词
import AdKeywordNegApple from '@/views/auth/planManage/adKeywordNegApple';//苹果否定关键词
import AdKeywordSelectApple from '@/views/auth/planManage/adKeywordSelectApple';//苹果搜索词
import CreateAdvertPlan from '@/views/auth/planManage/createAdvertPlan';//苹果新建广告系列
import CreateAdvertTeam from '@/views/auth/planManage/createAdvertTeam';//苹果新建广告组
import EditAdvertPlan from '@/views/auth/planManage/editAdvertPlan';//苹果修改广告系列
import EditAdvertGroup from '@/views/auth/planManage/editAdvertGroup';//苹果修改广告组
import AdvertApple from '@/views/auth/planManage/advertApple';//苹果广告
import CreateAdvert from '@/views/auth/planManage/createAdvert';//苹果新建广告
import PriceModifyGroupPrice from '@/views/auth/planManage/modifyPrice/PriceModifyGroupPrice';


export default {
  routes: [
    {
      path: 'accountSelection',//账户选择
      name: 'planManagement.AccountSelection',
      component: AccountSelection
    },
    {
      path: 'accountSelectionApple',//苹果账户列表
      name: 'planManagement.AccountSelectionApple',
      component: AccountSelectionApple
    },
    {
      path: 'accountSelectionGaode',//账户选择-高德
      name: 'planManagement.AccountSelectionGaode',
      component: AccountSelectionGaode
    },
    {
      path: 'adgroupTeamEdit',//编辑广告组
      name: 'planManagement.AdgroupTeamEdit',
      component: AdgroupTeamEdit
    },
    {
      path: 'advertisingPlan/',//广告计划
      name: 'planManagement.AdvertisingPlan',
      component: AdvertisingPlan
    },
    {
      path: 'uploadPage/',//上传列表
      name: 'planManagement.UploadPage',
      component: UploadPage
    },
    {
      path: 'uploadPageGaode/',//上传列表
      name: 'planManagement.UploadPageGaode',
      component: UploadPageGaode
    },
    {
      path: 'creatPlan',//创建计划
      name: 'planManagement.CreatPlan',
      component: CreatPlan
    },
    {
      path: 'priceModify',//调价计划展示页--账户
      name: 'planManagement.PriceModify',
      component: PriceModify
    },
    {
      path: 'priceModifyPlan',//调价计划展示页--计划
      name: 'planManagement.PriceModifyPlan',
      component: PriceModifyPlan
    },
    {
      path: 'priceModifyGroup',//调价计划展示页--广告组
      name: 'planManagement.PriceModifyGroup',
      component: PriceModifyGroup
    },
    {
      path: 'priceModifyGroupPrice',//调价广告组转化出价展示页--广告组
      name: 'planManagement.PriceModifyGroupPrice',
      component: PriceModifyGroupPrice
    },
    {
      path: 'setPrice',//调价设置页面
      name: 'planManagement.SetPrice',
      component: SetPrice
    },
    {
      path: 'editPrice',//调价设置修改页面
      name: 'planManagement.EditPrice',
      component: EditPrice
    },
    {
      path: 'startStop',//启停计划展示页
      name: 'planManagement.StartStop',
      component: StartStop
    },
    {
      path: 'startStopAdvert',//启停计划展示页--广告组
      name: 'planManagement.StartStopAdvert',
      component: StartStopAdvert
    },
    {
      path: 'setPricess',//计划创建--启停
      name: 'planManagement.SetPricess',
      component: SetPricess
    },
    {
      path: 'editPricess',//修改计划创建--启停
      name: 'planManagement.EditPricess',
      component: EditPricess
    },
    {
      path: 'setAdgroupPrice',//广告组创建--启停
      name: 'planManagement.SetAdgroupPrice',
      component: SetAdgroupPrice
    },
    {
      path: 'editAdgroupPrice',//广告组创建--启停
      name: 'planManagement.EditAdgroupPrice',
      component: EditAdgroupPrice
    },
    {
      path: 'adgroupTeamEdit',//广告组创建--编辑回显
      name: 'planManagement.AdgroupTeamEdit',
      component: AdgroupTeamEdit

    },
    {
      path: 'editAdCreat',//广告创建--编辑回显
      name: 'planManagement.EditAdCreat',
      component: EditAdCreat

    },
    {
      path: 'materialClassify',//素材分类排行榜
      name: 'planManagement.MaterialClassify',
      component: MaterialClassify
    },
    {
      path: 'copyPage/',//复制计划记录
      name: 'planManagement.CopyPage',
      component: CopyPage
    },
    {
      path: 'adPlanErdai/',//二代广告计划
      name: 'planManagement.AdPlanErdai',
      component: AdPlanErdai
    },
    {
      path: 'copyPageEd/',//复制计划记录
      name: 'planManagement.CopyPageEd',
      component: CopyPageEd
    },
    {
      path: 'advertisingPlanApple/',//苹果计划
      name: 'planManagement.AdvertisingPlanApple',
      component: AdvertisingPlanApple
    },
    {
      path: 'adPlanApple/',//苹果广告系列
      name: 'planManagement.AdPlanApple',
      component: AdPlanApple
    },
    {
      path: 'adTeamApple/',//苹果广告组
      name: 'planManagement.AdTeamApple',
      component: AdTeamApple
    },
    {
      path: 'adOperateApple/',//苹果操作记录
      name: 'planManagement.AdOperateApple',
      component: AdOperateApple
    },
    {
      path: 'adKeywordApple/',//苹果关键词
      name: 'planManagement.AdKeywordApple',
      component: AdKeywordApple
    },
    {
      path: 'adKeywordNegApple/',//苹果否定关键词
      name: 'planManagement.AdKeywordNegApple',
      component: AdKeywordNegApple
    },
    {
      path: 'adKeywordSelectApple/',//苹果搜索词
      name: 'planManagement.AdKeywordSelectApple',
      component: AdKeywordSelectApple
    },
    {
      path: 'createAdvertPlan/',//苹果新建广告系列
      name: 'planManagement.CreateAdvertPlan',
      component:CreateAdvertPlan
    },
    {
      path: 'editAdvertPlan/',//苹果修改广告系列
      name: 'planManagement.EditAdvertPlan',
      component:EditAdvertPlan
    },
    {
      path: 'editAdvertGroup/',//苹果修改广告组
      name: 'planManagement.EditAdvertGroup',
      component:EditAdvertGroup
    },
    {
      path: 'createAdvertTeam/',//苹果新建广告组
      name: 'planManagement.CreateAdvertTeam',
      component:CreateAdvertTeam
    },
    {
      path: 'advertApple/',//苹果广告
      name: 'planManagement.AdvertApple',
      component: AdvertApple
    },
    {
      path: 'createAdvert/',//苹果新建广告
      name: 'planManagement.CreateAdvert',
      component:CreateAdvert
    },
    
    
    
  ],
};
