<template>
  <div class="authority common-page newcommon-page">
    <!-- <planHeader :breakTitle="breakTitle"></planHeader> -->
     <PocCardTable>
       <template slot="headerTitle">
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <el-input v-model = "groupName" clearable placeholder="请输入广告组名称查找"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "nameId" 
                  clearable           
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="请输入广告组ID查找"></el-input>
                </el-form-item>
               
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择状态" v-model="channel">
                    <el-option
                      v-for="(item,i) in qdoptions.userStatuList"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择推广目的" v-model="channelm">
                    <el-option
                      v-for="(item,i) in qdoptions.goal"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择推广流量" v-model="channeln">
                    <el-option
                      v-for="(item,i) in qdoptions.sceneList"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择推广场景" v-model="channelo">
                    <el-option
                      v-for="(item,i) in qdoptions.sceneList"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择投放模式" v-model="channelp">
                    <el-option
                      v-for="(item,i) in qdoptions.modelList"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <div class="block">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      style="width:240px;"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" class="newQuery" size="small" @click = "grouplistFn(page,'',groupName,nameId,channel,channelm,channeln,channelo,channelp,value1)">查询</el-button>
                  <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
          </div>
          <div class="clearfix">
            <div class="fL MBOTTOM">
              <el-button type="primary" size="small"  @click="creatPlan('CreatPlan','20')">新建广告组</el-button>
              <el-dropdown trigger="click" @command = "changeStatusList">
                <el-button type="primary" size="small" @click="updateOppoSet('planStatus')">
                  修改状态
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button type="primary" size="small" @click="warmIs('warningPrice',url[0])"></el-button> -->
              <!-- <el-dropdown trigger="click" @command = "showAddAdGroupWarningFn"> -->
              <el-button type="primary" size="small" @click="warmIs('warningPrice',url[1])">
                  出价预警
              </el-button>
              
              <el-dropdown trigger="click" @command = "upAdPlanWarningSwitchFn">
                <el-button type="primary" size="small" @click="warmIs('warningOpen',url[1])">
                  预警开关
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> 
              <el-button type="primary" size="small" @click="warmIs('dayLimit',url[2])">批量调价</el-button>
              <el-button type="primary" size="small" @click="warmIs('conversionBid',url[2])">批量调整转化出价</el-button>
              <el-button type="primary" size="small" @click="goPlan('/plan-management/priceModifyGroup')">调价页面</el-button>
              <el-button type="primary" size="small" @click="updateOppoSet('startStop')">批量启停设置</el-button>
              <el-button type="primary" size="small" @click="goPlan('/plan-management/StartStop')">设置启停页面</el-button>
            </div>
            <div class="fR MBOTTOM">
                <!-- <el-button type="danger" size="small">更多筛选</el-button> -->
                <!-- <el-button type="danger" size="small">数据筛选</el-button> -->
                <el-button type="success" size="small" @click = "zdyFn">自定义列</el-button>
                <el-button type="warning" size="small" @click = "exportTeam">下载报表</el-button>
              </div>
            
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @cell-click="clickcell"
                :cell-style="setRowStyle"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <template v-for="(item, index) in titleList">
                    <el-table-column
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>

                        <div v-else                                   
                        :disabled = "scope.row.disabledIs"
                        >
                      <span v-if='scope.column.property == "status2" 
                      || scope.column.property == "priceSwitch" 
                      || scope.column.property =="ocpcPriceSwitch"
                      || scope.column.property =="startSwitch" 
                      || scope.column.property == "warningSwitch"'>
                            {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                        <i v-if = 'scope.column.property =="startSwitch" 
                        ||scope.column.property == "priceSwitch"
                        ||scope.column.property =="ocpcPriceSwitch"'
                        
                          :class = "{'isSwitch':scope.column.property =='startSwitch'
                          ||scope.column.property =='priceSwitch'
                          ||scope.column.property =='ocpcPriceSwitch',
                          'active':(scope.column.property =='startSwitch'
                          ||scope.column.property =='priceSwitch'
                          ||scope.column.property =='ocpcPriceSwitch')
                          &&scope.row[scope.column.property]==0}"></i>
                          </span>
                          <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </div> 
                        <template v-if='item.prop=="status2"'>
                          <el-switch
                            v-model="scope.row.status2"
                            class="switchStyle"
                            :active-value="0"  
                            :inactive-value="1"
                            active-color="#48916F"
                            inactive-color="#EAEFED"
                            @change = "changeStatus(scope.row.status2,scope)"
                            >
                          </el-switch>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='360'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button class="solid-tag-success" data-type = 'editBtn' plain size="mini">编辑</el-button>
                    <el-button class="solid-tag-harmful" data-type = 'qtBtn' plain size="mini">启停</el-button>
                    <el-button class="solid-tag-warning" data-type = 'tjBtn' plain size="mini">调价</el-button>
                    <el-button class="solid-tag-instructions" data-type = 'warningPriceSingle' plain size="mini">出价预警</el-button>
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deletePlanFn(scope.row.adGroupId)">
                      <template #reference>
                        <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <!-- <div class="allOperation fL">
              <div class="totalNum fL">共<i>50</i>条</div>
              <el-select v-model="value" placeholder="批量操作">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div> -->
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
            <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="30%">
              <span class="el-icon-loading uploading" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <earlyWarningDialog ref="earlyWarningDialogIs" :visible.sync="showDialog" :pubVue = 'pubVue'></earlyWarningDialog>
    <timeDialog :visible.sync="timeDialog" :objIdType = 20 :yulanList = "yulanList" :userContent = 'userContent' :pubVue="pubVue"></timeDialog>
    <zdy :visible.sync="showzdyDialog" ref = 'zdy' :pubVue="pubVue" :typeName = 20></zdy>
    <startLimitSingleDialog ref = 'dayLimitDom' :visible.sync="showSingleDialog" :nowIs = "nowIs" :yulanList = "yulanList" :userContent = 'userContent' :planType = "planType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleDialog>
    <startConversionBidDialog ref = 'conversionBidDom' :visible.sync="showConversionBidDialog" :nowPriceIs = "nowPriceIs" :ocpcdeSwitchOr = "ocpcdeSwitchOr" :yulanList = "yulanList" :userContent = 'userContent' :planType = "planPriceType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startConversionBidDialog>

    <startStopDialog ref = 'startStopDialogIs' :visible.sync="startStopDialog" :pubVue="pubVue"></startStopDialog>
    <tiaojiaDialog ref = 'tiaojiaDialog' :visible.sync="tiaojiaDialogIs" :pubVue="pubVue"></tiaojiaDialog>
    <setCjWarningDialog ref = 'setCj' :visible.sync="setCjWarningDialogShow" :yulanList = "yulanList" :groupsIds = 'groupsIds' :userContent = 'userContent' :pubVue="pubVue"></setCjWarningDialog>
    <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :groupName = "groupName" :pubVue="pubVue" :command = "command+''" :typeName = "'team'"></alertStartStopDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import earlyWarningDialog from '@/views/dialog/earlyWarningDialog';
import timeDialog from '@/views/dialog/timeDialog'
import startLimitSingleDialog from '@/views/dialog/startLimitSingleDialog'
import setCjWarningDialog from '@/views/dialog/setCjWarningDialog'
import zdy from './zdy'
import startStopDialog from '@/views/dialog/startStopPageDialog'
import tiaojiaDialog from '@/views/dialog/tiaojiaDialog'
import alertStartStopDialog from '@/views/dialog/alertStartStopDialog'
import startConversionBidDialog from '@/views/dialog/startConversionBidDialog'
export default {
  components: {
    earlyWarningDialog,
    timeDialog,
    zdy,
    startLimitSingleDialog,
    setCjWarningDialog,
    startStopDialog,
    tiaojiaDialog,
    alertStartStopDialog,
    startConversionBidDialog,
  },
  props:{
    newVue:Object,
  },
  data() {
    return {
      pubVue: this,//空vue，用于子组件之间传值
      showDialog: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showzdyDialog:false,
      showSingleDialog:false,
      showConversionBidDialog:false,
      nowIs : false,
      nowPriceIs:false,
      content:[],
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      qdoptions:{goal:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],//extensionTypes-推广目标列表
                userStatuList:[{text:'全部推广状态',id:'0'},{text:'启动中',id:'1'},{text:'暂停中',id:'2'},{text:'余额不足',id:'3'},{text:'达到账户日预算',id:'4'},{text:'达到计划日预算',id:'5'}],//选择状态
                sceneList:[{text:'全部流量场景',id:'1'},{text:'自有流量',id:'2'},{text:'联盟流量',id:'3'}],//extensionFlows-推广流量列表
                modelList:[{text:'普通投放',id:'0'},{text:'跟随投放',id:'1'}],
                flowScenesList:[{text:'全流量场景',id:'1'},{text:'精选推广',id:'2'},{text:'安装有礼',id:'3'},{text:'开机必备',id:'4'},{text:'搜索',id:'5'},{text:'信息流',id:'6'},{text:'Banner',id:'7'},{text:'开屏',id:'8'},{text:'插屏',id:'9'},{text:'原生',id:'10'},{text:'激励视频',id:'11'},{text:'联盟通投',id:'12'}],
},//adServingType-投放类型列表
      titleList:[{name:'广告组名称',prop:'adGroupName'},
      {name:'投放开关',prop:'status2'},// 0开启 1关闭
      {name:'广告组ID',prop:'adGroupId'},
      {name:'已设置调价',prop:'priceSwitch'},
      {name:'已设置转化出价调价',prop:'ocpcPriceSwitch'},
      {name:'已设置启停',prop:'startSwitch'},//0是，1否
      {name:'预警状态',prop:'warningSwitch'},
      {name:'预警出价',prop:'warningPrice'},
      {name:'广告组状态',prop:'showStatusName'},
      {name:'基础出价',prop:'price'}],//title名称
      showStatusList:{status2:[''],
                      priceSwitch:['是','否'],
                      startSwitch:['是','否'],
                      warningSwitch:['开启','关闭','未设置'],
                      ocpcPriceSwitch:['是','否'],
                      startSwitch:['是','否'],
                      warningSwitch:['开启','关闭','未设置']},
      planName:'',//请输入计划名称查找
      nameId:'',//请输入计划ID查找
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      tableData:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:'',
      planType:30,
      planPriceType:40,
      dialogTitle:'批量调价',
      userContent:{},//登录用户信息&弹框默认
      url:['/adGroup/v1/checkGroupWarning',// 出价预警-adGroupIdList 广告组ID
          '/account/v1/checkAccountWarning',//预警是否开启 -ownerIdList     账户ID     
          '/adGroup/v1/checkGroupModifyPriceSet'],//是否设置过定时调价计划-groupIds 广告组ID
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      // closeGroupList:[],
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      ocpcPrice:[],//转化出价
      ocpcdeSwitch:'',
      ocpcdeSwitchOr:false,
    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.params.userId  = this.userId
    this.params.userName  = this.userName
    this.params.deptId = this.deptId
    this.params.ownerId = Number(this.$route.query.id)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.userContent.ownerId = Number(this.$route.query.id)
    this.userContent.ownerName = this.$route.query.name
    console.log(this.myHeaders)
    this.getId()
    this.fileList={
        "ownerId":this.params.ownerId,
        "operaType":20,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
  },
  methods: {
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
      console.log(this.uploadId)
    },
    zdyFn(){
      this.$refs.zdy.generateTimes(20)
    },
    exportTeam(){
      this.exprtVisible = true
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,dataSource =[],keywords = []
      // let video = []
      console.log(response)
      if(response.code==200){
        that.grouplistFn(this.page)
       
      }else{
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'error'
        })
      }
       this.$refs.upload.clearFiles()
    },
    //下载报表
    downLoadTable(){
      let _this = this,params ={},date = new Date().getTime(),myDate = new Date()
      let Time2 = myDate.toISOString().slice(0,10)//推荐 yyyy-MM-dd
      //延伸  取yyyyMMdd
      let Time3 = Time2.replace(/[^0-9]/ig,'').substring(0,8)
      params = {'ownerId':_this.params.ownerId,
                'deptId':_this.deptId,
                'userId':_this.userId,
                'userName':_this.userName,
              'beginTime':_this.value1[0]||Time3,
            'endTime':_this.value1[1]||Time3},
      _this.uploadingShow = true
      $http.axios.post("/api/advertisement/adGroup/v1/v3export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        _this.exprtVisible = false 
        //添加{responseType: 'blob'}  导出文件以下代码都是
        let blob = response.data
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = e => {
          let a = document.createElement('a')
          a.download = "广告组报表"+myDate+".xls"
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          _this.uploadingShow = false
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
    creatPlan(url,type){
      // this.$router.push("/plan-management/"+routerTo)
      this.$router.push({
        path: url,
        query: {
          ownerId: this.userContent.ownerId,
          ownerName:this.userContent.ownerName,
          type:type
        }
      })
    },    
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      console.log(row);
      console.log(column);
      console.log(cell);
      console.log(event);
      row.ownerId
      let yjType = event.target.parentNode.dataset.type
        this.userContent.adGroupId = row.adGroupId
        this.userContent.adGroupName = row.adGroupName
        this.userContent.adPlanName = row.planName
        this.userContent.adPlanId = row.planId
        this.userContent.showStatus = row.showStatus
      if(yjType == 'warningPriceSingle'){
        this.groupsIds = [row.adGroupId]
        // this.userContent.dayBudget = row.dayBudget
        this.yjCheck('warningPriceSingle',this.url[1],row)
        
      }else if(yjType == 'editBtn'){
        this.goPlan('/plan-management/AdgroupTeamEdit',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.adGroupId,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'qtBtn'){
        this.goPlan('/plan-management/SetAdgroupPrice',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.adGroupId,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'tjBtn'){
        this.goPlan('/plan-management/SetPrice',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.adGroupId,
          adGroupName:row.adGroupName
        })
      }
      if(column.property=="adGroupName"){
        this.goPlan('/plan-management/advertisingPlan')
        this.newVue.handleClick({'index':'2'},row.adGroupId)
      }
      if(column.property=="startSwitch"){
          let params = {"adGroupId": row.adGroupId}
          this.ssDetailParams = {}
          if(row.startSwitch == 0){
            this.ssDetailParams={currentPage:1,
                                      deptId:this.deptId,
                                      etime:"",
                                      pageSize:50,
                                      status:"",
                                      stime:"",
                                      openClosePlanId:"",
                                      userId:this.userId,
                                      userName:this.userName}
            this.getOpenCloseIdFn(params)
          }
        }
        if(column.property =="priceSwitch"){
          this.tiaojiaDialogIs = true

          this.$refs.tiaojiaDialog.getTimeSlotDetail(row.planId,this.planType,this.userContent)

        }
        if(column.property =="ocpcPriceSwitch"){
          this.tiaojiaDialogIs = true
          this.$refs.tiaojiaDialog.getTimeSlotDetail(row.planId,40,this.userContent)
          console.log(this.userContent)
        }
    },
    getOpenCloseIdFn(params){
        let _this = this, type = ''
        $http.axios.post("/api/advertisement/adPlan/v1/getOpenCloseId",params).then(res=> {
          if(res.data.code==200&res.data.content!=null){
            _this.startStopDialog = true
            _this.ssDetailParams.openClosePlanId=res.data.content
            _this.$refs.startStopDialogIs.timeSlotDetail(_this.ssDetailParams)
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
    // 启停页面跳转
    goPlan(url,query){//
      let _this = this,query1 =  {
            id: this.userContent.ownerId,
            name:this.userContent.ownerName}
        query = Object.assign({},_this.clone(query), query1)
        this.$router.push({
            path: url,
            query: query
          })
    }, 
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },

    // 分页
    handleSizeChange(val) {
      let _this = this
      console.log(666)
      _this.page = val
      // _this.$set(_this.params,'page',val)
      _this.findAll(val)
    },
    handleCurrentChange(val) {
      this.page = val
      // var params = {
      //   page: this.page,
      //   pageCount: this.pageCount
      // }
      this.findAll(val)
    },    
    //分页方法
    findAll (page) {
      //获取数据接口（调用封装的接口）
      this.page = page
      this.grouplistFn(page,'',this.groupName,this.nameId,this.channel,this.channelm,this.channeln,this.channelo,this.channelp,this.value1)
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let groupsIds = [],yulanList = [],objList = [],startSwitch = []
      // _this.limitIs = false
      console.log(val)
      val.forEach((obj,i,arr)=>{
        let cur = obj.status2
        groupsIds.push(obj.adGroupId)
        startSwitch.push(obj.startSwitch)
        yulanList.push({ 'adGroupName':obj.adGroupName,'adGroupId':obj.adGroupId,'planName':obj.planName,'planId':obj.planId,'price':obj.price,'upPriceStatus':0})

        // if(obj.dayBudget == '不限'){
        //   _this.limitIs = true
        // }
        objList[cur] = cur
        _this.ocpcPrice.push(obj.ocpcPrice)//转化出价
        _this.ocpcdeSwitch = Number(obj.ocpcPriceSwitch)
        if(_this.ocpcdeSwitch == 1){
          _this.ocpcdeSwitchOr = false
        }else if(_this.ocpcdeSwitch == 0){
          _this.ocpcdeSwitchOr = true
        }
        // if(objList.length==0){
        //   objList[i] = cur
        // }else{
        //   objList[cur] = cur
        // }
      })
      console.log(yulanList)
      objList.forEach((obj,i)=>{
        if(objList.length == 2&&objList[0]!=undefined){
        _this.planStatus = false
        _this.warningOpenList = []
        _this.$message({
            showClose: true,
            message: "选择的计划状态不一致，选择同一种计划状态后才可操作",
            type: 'error'
          })        
        }else{
          _this.planStatus = true
        }        
      })
      _this.groupsIds = groupsIds
      _this.startSwitch = startSwitch
      _this.yulanList = yulanList
      console.log(_this.yulanList)
      if(val.length == 0){
        _this.warningOpenList = []
        _this.planStatus = false

      }
    },
      //重置
      resetForm(){
        let _this = this
        _this.clearFn()
        _this.grouplistFn()
        
      },
      clearFn(){
        let _this = this
        _this.nameId = ''
        _this.groupName = ''
        _this.channel = ''
        _this.channelm = ''
        _this.channeln = ''
        _this.channelo = ''
        _this.value1 = ''
      },
    //按钮开关
    getBrandList(){},
    // 自适应表格列宽
    flexColumnWidth(str, tableData, flag = 'max') {
      // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
      // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
      // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
      let columnContent = ''
      if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
        return
      }
      if (!str || !str.length || str.length === 0 || str === undefined) {
        return
      }
      if (flag === 'equal') {
        // 获取该列中第一个不为空的数据(内容)
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str].length > 0) {
            columnContent = tableData[i][str]
            break
          }
        }
      } else {
        // 获取该列中最长的数据(内容)
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str] === null) {
            return
          }
          const now_temp = tableData[i][str]
          const max_temp = tableData[index][str]
          if (now_temp.length >= max_temp.length) {
            index = i
          }
        }
        columnContent = tableData[index][str].toString()
      }
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 0
      for (const char of columnContent) {
        if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 8
        } else if (char >= '\u4e00' && char <= '\u9fa5') {
          // 如果是中文字符，为字符分配18个单位宽度
          flexWidth += 18
        } else {
          // 其他种类字符，为字符分配15个单位宽度
          flexWidth += 15
        }
      }
      if (flexWidth < 120) {
        // 设置最小宽度
        flexWidth = 120
      }
      return flexWidth
    },
   
    //广告组列表
    grouplistFn(page,pageCount,groupName,nameId,channel,channelm,channeln,channelo,channelp,value1){
      // "adGroupIds": "",
      // "adGroupNameLike": "",
      // "adServingType": "",投放模式 0普通；1跟随
      // "beginTime": "",
      // "deptId": 0,
      // "endTime": "",
      // "extensionFlows": "",推广流量1-全流量场景；2-自有流量；3-联盟流量
      // "extensionTypes": "",推广目标1-链接推广；2-应用推广；3-快应用推广
      // "offset": 0,
      // "ownerId": 0,
      // "page": 0,
      // "pageCount": 0,
      // "planIds": "",
      // "showStatusSet": "",
      // "userId": 0,
      // "userName": "",
      // "flowScenes":"", 流量场景1-全部流量场景；2-精选推广；3-安装有礼；4-开机必备；5-搜索；6-信息流；7-Banner；8-开屏；9-插屏；10-原生；11-激励视频；12-联盟通投
      let _this = this,params,pagetTxt,price,ocpc,deepOcpc
      pagetTxt = {'pageCount' : pageCount|| 10,
              'page' : page || 1,
              'adGroupNameLike':groupName,
              'adGroupIds':nameId,
              'flowScenes':channelo,//adServingType
              'extensionTypes':channelm,//extensionTypes
              'showStatusSet':channel,
              'extensionFlows':channeln,
              'planIds':_this.planIds,
              'adServingType':channelp}//   extensionFlows
      if(value1 != undefined&&value1 != ''){
        pagetTxt.beginTime = value1[0]
        pagetTxt.endTime = value1[1]
      }
      
      params = Object.assign({},_this.clone(_this.params), pagetTxt)      
      $http.axios.post("/api/advertisement/adGroup/v1/grouplist",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          if(res.data.content.items != null){
              _this.tableData = res.data.content.items
      
              _this.tableData.forEach((obj,i)=>{
                if(obj.priceSwitch == null){
                  _this.tableData[i].priceSwitch = 1
                }
                if(obj.startSwitch == null){
                  _this.tableData[i].startSwitch = 1
                }
                if(obj.ocpcPriceSwitch == null){
                  _this.tableData[i].ocpcPriceSwitch = 1
                }
                if(obj.warningSwitch == null){
                  _this.tableData[i].warningSwitch = 2
                }
                if(obj.priceLow == null){
                  _this.tableData[i].priceLow = 1
                }
                if(obj.priceTop == null){
                  _this.tableData[i].priceTop = 2
                }
                if(obj.priceLow<obj.priceTop){
                    price = obj.priceLow+"<"+obj.priceTop
                }else{
                  price = ''
                }
                if(obj.ocpcLow<obj.ocpcTop){
                    ocpc = obj.ocpcLow+"<"+obj.ocpcTop
                }else{
                  ocpc = ''
                }
                if(obj.deepOcpcLow<obj.deepOcpcTop){
                    deepOcpc = obj.deepOcpcLow+"<"+obj.deepOcpcTop
                }else{
                  deepOcpc = ''
                }
                if(obj.ocpcPrice != "-"){
                  obj.ocpcPrice = obj.ocpcPrice/100
                }else{
                  obj.ocpcPrice = obj.ocpcPrice
                }
                // _this.tableData[i].warningPrice ="基础出价:"+obj.priceLow<obj.priceTop?obj.priceLow+"<"+obj.priceTop:''+";转化出价:"+obj.ocpcLow<obj.ocpcTop?obj.ocpcLow+"<"+obj.ocpcTop:''+";深度转化出价:"+obj.deepOcpcLow<obj.deepOcpcTop?obj.deepOcpcLow+"<"+obj.deepOcpcTop:''
                _this.tableData[i].warningPrice ="基础出价:"+price+";转化出价:"+ocpc+";深度转化出价:"+deepOcpc
                _this.totalRow = res.data.content.totalCount
              })
          }else{
            _this.tableData = []
            _this.totalRow = 0

          }
          _this.clearFn()
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
    //预警出价
    upAdPlanWarningSwitchFn(command){
      let _this = this,type,params

      params={'adGroupId':_this.groupsIds,
        'warningType' : command,//预警开关(0开 1关 )
        "deptId": _this.params.deptId,
        "userId": _this.params.userId,
        "userName": _this.params.userName}
      

      $http.axios.post("/api/advertisement/adGroup/v1/upGroupWarSwitch",params).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();          
        if(res.data.code==200){
          _this.grouplistFn(_this.page)
          type = "success"
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //V3.0批量暂停/启动广告组OPPO
    updateOppoAdGroupStatusFn(command,status,groupsIds){//updateOppoAdGroupStatus
      // "deptId": 0,
      // "groupsIds": [],
      // "ownerId": 0,
      // "status2": "",
      // "userId": 0,
      // "userName": ""
      let _this = this,type,params,params1
      groupsIds = groupsIds||_this.groupsIds
      params1={'groupsIds':groupsIds,
        'status2' : command.toString()||_this.command.toString(),
        'status' : status||0}
      params = Object.assign({},_this.clone(_this.params), params1)
      $http.axios.post("/api/advertisement/adGroup/v1/updateOppoAdGroupStatus",params).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
        _this.grouplistFn(this.page)
          type = "success"
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // 批量启停设置
    updateOppoSet(type){
      let _this = this
      let groupsIds = []//声明一个空数组
      //  _this.ownerIdList = [1100018542,1100018078]
        // that.getzdyData 去重
        _this.groupsIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          groupsIds.push(v)
        }
      })
      
      if(_this.groupsIds.length!=0){
        _this.params.groupsIds = _this.groupsIds
        _this.btnIsFn(_this.params,type)
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
    // 按钮判断
    btnIsFn(params,type){
      let _this = this
      if(type == 'startStop'){
        _this.timeDialog = true
      }else if(type == 'planStatus'&&_this.planStatus){
            _this.checkOpenCloseGroup(params.groupsIds)//需求更改暂时注释
          _this.warningOpenList = ['开启','关闭']
      }else if(type == 'dayLimt'){

      }

    },  
    //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      // /adPlan/v1/checkPlanDailyMoneySet
      console.log(type)
      // if(type == 'warningPrice'){
      //   this.checkoutSetIs(type)
      //   // this.setCjWarningDialogShow = true
      // }else{
        let _this = this
        let isTrue = _this.updateOppoSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        if(isTrue){
            _this.yjCheck(type,url)     
        }
      // }
     
    },
    yjCheck(type,url,row,adPlanId){
      let _this = this,messageContent,idList = [],params = null,yulanList = []
      if(type == 'warningOpen'){
        params = []
        params = [_this.userContent.ownerId]
      }else if(type == 'dayLimit'){
        _this.groupsIds.forEach(obj=>{
          idList.push(obj)
        }) 
        params = {}
        params = {"idList":idList,"planType":_this.planType}      
      }else if(type == 'conversionBid'){
        _this.groupsIds.forEach(obj=>{
          idList.push(obj)
        }) 
        params = {}
        params = {"idList":idList,"planType":_this.planPriceType}  //广告组转化出价40
      }
      // else if(type == 'warningPriceSingle' || type == 'warningPrice'){
      //   params = []
      //   params.push(_this.params.ownerId)
      // }
      else if(type == 'warningPriceSingle'){
        console.log(row)
        yulanList.push({ 'adGroupName':row.adGroupName,'adGroupId':row.adGroupId,'planName':row.planName,'planId':row.planId,'price':row.price,'upPriceStatus':0})
        params = []
        params.push(_this.params.ownerId)
        _this.yulanList = yulanList
      }else if(type == 'warningPrice'){
        params = []
        params.push(_this.params.ownerId)
      }
      else{
      _this.groupsIds.forEach(obj=>{
        params = []
        params.push(obj)
      })
    }

      $http.axios.post("/api/advertisement"+url,params).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.content
          if(content == null){
            if(type == 'dayLimit'){
              this.showSingleDialog = true
            }else if(type == 'conversionBid'){//转化出价
              console.log(_this.ocpcPrice.indexOf("-"))
              if(_this.ocpcPrice.indexOf("-") == -1){
                _this.showConversionBidDialog = true
                // _this.showSingleDialog = true
                _this.content = null
                _this.ocpcdeSwitchOr = false
                console.log(_this.ocpcdeSwitchOr)
              }else{
                _this.$message({
                  showClose: true,
                  message: '没有转化出价不允许设置',
                  type: 'error'
                })
              }
            }else{
              messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
            
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }
              
            }else{
              if(type == 'dayLimit'){
              //   let msgContent =  content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
              //   _this.$message({
              //   showClose: true,
              //   message: msgContent,
              //   type: 'error'
              // })

              let text = content.toString()+'广告组已经设置过调价执行计划是否确认添加新计划'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                this.showSingleDialog = true
                this.nowIs = true
              }).catch(()=>{
                
              })
              }else if(type == 'conversionBid'){//转化出价
              let text = content.toString()+'广告组已经设置过转化出价'
              if(_this.ocpcPrice.indexOf("-") == -1){
                this.$confirm(text,"提示",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(()=>{
                  this.showConversionBidDialog = true
                  // _this.showSingleDialog = true
                  this.nowPriceIs = true
                  _this.ocpcdeSwitchOr = true
                  console.log(_this.ocpcdeSwitchOr)
                }).catch(()=>{})
              }else{
                _this.$message({
                  showClose: true,
                  message: '没有转化出价不允许设置',
                  type: 'error'
                })
              }
            }else if(type == 'warningPrice'){
                console.log(type)
                _this.checkoutSetIs()
                _this.warmType = type
                _this.warmTypeBtn = ''
              }else if(type == 'warningPriceSingle'){
                console.log(type)
                _this.warmTypeBtn = type
                _this.warmType = ''
                // _this.warningOpenList = ['开启','关闭']
                _this.setCjWarningDialogShow = true
                // if(adPlanId){
                  _this.$refs.setCj.getAdGroupWarningDetailFn()
                  
                // }
              }else if(type == 'yjBtn'){
                _this.showDialogIs = true
                _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.userContent.ownerId,type)
              }else if(type == 'warningOpen'){
                _this.warningOpenList = ['开启','关闭']
              }
            }
          }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      checkoutSetIs(){
        let _this = this,params = [],url = _this.url[0]
         _this.groupsIds.forEach(obj=>{
          params.push(obj)
        }) 
        $http.axios.post("/api/advertisement"+url,params).then(res=> {
          if(res.data.code == 200){
            if(res.data.content == null){
                _this.setCjWarningDialogShow = true
            }else{
              let text = res.data.content.toString()+'广告组已经设置过出价预警是否修改原有出价'
              _this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                _this.setCjWarningDialogShow = true
                // _this.$refs.setCj.getAdGroupWarningDetailFn()
                //上边这行代码是金子注释的，因为在批量出价预警的时候没有回显
              }).catch(()=>{
                
              })
            }
          }
        
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      checkOpenCloseGroup(params){
      let _this = this
      $http.axios.post("/api/advertisement/adGroup/v1/checkOpenCloseGroup",params).then(res=> {
        if(res.data.code==200){
          if(res.data.content!= ''){
            // res.data.content.forEach(obj=>{
            //   _this.closeGroupList.push(obj)
            // })
            _this.closeGroupList = res.data.content
          }else{
          // _this.closeGroupList = []
          _this.closeGroupList = ''
          }

        }
      }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
    changeStatus(value,scope) {
      //先把值赋为原来的值，保证swich状态不变
      this.groupsIds=[scope.row.adGroupId]
      let _this = this
      this.tableData[scope.$index].status2 = value == 0?1:0//需求更改暂时注释
      $http.axios.post("/api/advertisement/adGroup/v1/checkOpenCloseGroup",this.groupsIds).then(res=> {
        if(res.data.code==200){
          if(res.data.content!= ''){
            _this.groupName = res.data.content
            _this.command = value
            _this.alertStartStopDialogIs = true   
          }else{
          this.tableData[scope.$index].status2 = value
          this.updateOppoAdGroupStatusFn(scope.row.status2,0,[scope.row.adGroupId])//需求更改暂时打开
          }

        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      }) 
    },
    changeStatusList(command){
       console.log(command)
        // let _this = this,groupName = [],text = ''
        //   _this.closeGroupList.forEach((obj,i)=>{
        //       groupName.push(obj)
        //   })
        let _this = this,groupName = '',text = ''
        groupName = _this.closeGroupList
          if(groupName!=''){
            // _this.groupName = groupName.join('、')
            _this.groupName = groupName
            _this.command = command
            _this.alertStartStopDialogIs = true     
          }else{
            this.updateOppoAdGroupStatusFn(command)
          }

      
    }, 
         
    // 删除
    deletePlanFn(planId){
    // "ownerId": 0,
    // "adGroupIdList": [],
    // "userId": 0,
    // "userName": ""
      let _this = this,params = {},type = ''
      params = {"ownerId": _this.userContent.ownerId,
        "adGroupIdList": [planId],
        "userId": _this.userContent.userId,
        "userName": _this.userContent.userName}
      $http.axios.post("/api/advertisement/adGroup/v1/deleteOppoGroup",params).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.grouplistFn(this.page)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 1){
        // return 'color: #67C23A' 
        return 'color: #2F674D' 
      }else{
          return 'color: #606266' 
      }
    }
  },
//   
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:20px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  /* 新版 */
  .newcommon-page{
    padding:0!important;
  }
  .newcommon-page .el-card.is-always-shadow{
    box-shadow: none!important;
    border:none!important;
  }
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
</style>
