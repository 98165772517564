<template>
    <div class="authority common-page">
      <PocCardTable>
        <template slot="headerTitle">
          <h2 class="faq">创建FAQ</h2>
          <div>
            <el-input v-model="faqTitle" placeholder="请输入问题" class="question"></el-input>
            <!-- <quill-editor
              ref="myQuillEditor"
              v-model="content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />  -->
            <quill-editor
              ref="myQuillEditor"
              v-model="content"
              :options="editorOption"
              @change="onEditorBlur($event)"
            /> 
            <div class="footer-btn">
              <el-button type="primary" size="small" @click="submitQuestion">确定</el-button>
              <el-button type="primary" size="small" @click="goFaqList">返回</el-button>
            </div>
          </div>
        </template>
      </PocCardTable>
    </div>
  </template>
  
 
<script>
import { quillEditor } from 'vue-quill-editor'
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'FaqQuestions',
  components: {
    quillEditor
  },
  // toolbarOptions,
  data() {
    return {
      faqTitle:'',//标题
      content:'',
      editorOption:{},
      faqAnswer:[],
      questionId:'',
    }
  },
  mounted() {
    this.questionId = this.$route.query.id
  },
  methods: {
    //失去焦点事件
    onEditorBlur(quill) {
      this.faqAnswer = quill.html
    },

    //返回
    goFaqList(){
      this.$router.go(-1)
    },

    //确定
    submitQuestion(){
      let that = this
      let question = that.faqTitle
      $http.axios.post("/api/system/faq/createfaq?channelId=3&type="+that.questionId+"&question="+question+"&answer="+that.faqAnswer).then(res=> {
        this.$router.go(-1)
      })
    },










  }
}
</script>

<style lang="scss" scoped>
  .faq{
    font-size:18px;
    font-weight:700;
  }
  .question{
    margin-bottom:20px;
  }
  .footer-btn{
    margin-top:30px;
  }
</style>
