export default {
  "dc:数据中心": "Media SPD",
  "dc:请输入": "Please input",
  "dc:请输入名称": "Please input name",
  "dc:请选择": "Please select",
  "dc:新增": "Add",
  "dc:退出": "LOGOUT",
  "dc:刷新": "Refresh",
  "dc:删除": "Delete",
  "dc:计算": "Calculating",
  "dc:详情": "Detail",
  "dc:下载": "Download",
  "dc:上传": "Upload",
  "dc:确认": "Confirm",
  "dc:取消": "Cancel",
  "dc:保存成功": "Save success.",
  "dc:上传成功": "Upload success.",
  "dc:登录": "Sign Up",
  "dc:保存": "Save",
  "dc:加载中": "Loading",
  "dc:运行中": "Runing",
  "dc:返回": "Back",
  "dc:删除成功": "Delete success.",
  "dc:查看": "View",
  "dc:操作": "Action",
  "dc:编辑": "Edit",

  // 权限
  "dc:产品名": "Product Name",
  "dc:权限": "Access Name",
  "dc:模块": "Module",
  "dc:权限code": "Access Code",
  "dc:添加权限": "Add Access",

  "dc:账号名称": "Account Name",
  "dc:账号管理": "Account Approval",
  "dc:账号维护": "Account Maintaining",
  "dc:账号关键词": "Input Keywords",
  "dc:账号状态": "Account Status",
  "dc:品牌": "Brand",
  "dc:邮箱": "E-mail",
  "dc:添加品牌": "Register",
  "dc:权限管理": "Operation Access",
  'dc:品牌定义': "Product Definition",
  "dc:权限定义": "Access Definition",
  "dc:角色定义": "Role Definition",
  "dc:编码": "Code",
  "dc:网络地址": "URL",
};