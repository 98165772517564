<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    :title="'生成'+title+'模板'"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm">
      <el-form-item label="模板名称" prop="name">
        <el-input v-model="ruleForm.name" style="width:40%" placeholder="请输入模板名称"></el-input>
      </el-form-item>
      <el-form-item label="报表名称" prop="excelName">
        <el-input v-model="ruleForm.excelName" style="width:40%" clearable placeholder="请输入发送报表名称" oninput="" @clear='handleClear'></el-input>
        <div>
          <span class="addWord" v-for="(item,i) in cibaoList" :key='i' @click="addWordsFn(item)">[{{item}}]</span>
        </div>
      </el-form-item>
      <el-form-item label="报表维度" prop="adType">
        <el-select v-model="ruleForm.adType" style="width:40%" placeholder="请选择报表维度">
          <!-- <el-option label="账户" value="40"></el-option> -->
          <el-option label="任务" value="1"></el-option>
          <el-option label="子任务" value="2"></el-option>
          <!-- <el-option label="创意" value="50"></el-option> -->
        </el-select>
      </el-form-item>
      <div v-if="dataSourceIs">
        <span>数据源sheet</span>
        <div v-for="(item,i) in dataSource" :key = i style="margin:10px 0">
          <span class="sourceClass"><i class="redTip">*</i>{{item.sheetName}}</span>
          <el-cascader 
            :props="props"
            ref="tableDataList"
            style="width:40%"
            filterable
            v-model="ruleForm.sourceName[i]"
            placeholder = "请选择账户/应用"
            @change="changeFn($event,i,'data')"
            ></el-cascader>
            <!-- <el-cascader
              v-model="$item"
              :options="tableDataList"
              clearable
              :props="{ expandTrigger: 'hover',multiple:true }"
              @visible-change="showFn($event,i,'data')"
              @change="handleChange"></el-cascader> -->
        </div>
        <!-- <div v-for="(item,i) in dataSource" :key = i>
          <el-form-item  prop="sourceName">
            <span class="sourceClass"><i class="redTip">*</i>{{item.sheetName}}</span>
            <el-select  v-model="ruleForm.sourceName[i]" value-key = "name" style="width:40%" multiple placeholder="请选择账户" @change="changeFn($event,i,'data')">
              <el-option :label="item1.name" :value="item1" v-for="(item1,j) in tableDataList" :key=j></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="App" prop="appList">
          <el-select v-model="ruleForm.appList[i]" value-key = "name" style="width:40%" multiple filterable placeholder="请选择App" @change="">changeFn($event,i,'appId')
            <el-option :label="item1.name" :value="item1" v-for="(item1,j) in appList[i]" :key=j>{{item1.name}} </el-option>
          </el-select>
        </el-form-item> 
        </div> -->
      
     </div>

        <div v-if="keywordsIs">
          <span>关键字sheet</span>
          <div v-for="(item,i) in keywords" :key = i style="margin:10px 0">
          <span class="sourceClass"><i class="redTip">*</i>{{item.sheetName}}</span>
          <el-cascader 
            :props="props"
            ref="tableDataListKey"
            style="width:40%"
            filterable
            v-model="ruleForm.KeyName[i]"
            value-key = "name"
            placeholder = "请选择账户/应用"
            @change="changeFn($event,i,'keyword')"
            ></el-cascader>

            <!-- <el-form-item prop="KeyName">
              <span class="sourceClass"><i class="redTip">*</i>{{item.sheetName}}</span>
              <el-select v-model="ruleForm.KeyName[i]" value-key = "name" style="width:40%" multiple placeholder="请选择账户" @change="changeFn($event,i,'keyword')">
                <el-option :label="item1.name" :value="item1" v-for="(item1,j) in tableDataList" :key=j></el-option>
              </el-select>
            </el-form-item>  
            <el-form-item label="App" prop="appList">
            <el-select v-model="appList" value-key = "name" style="width:40%" multiple filterable placeholder="请选择App" @change="changeFn($event,i,'appId')">
              <el-option :label="item1.name" :value="item1" v-for="(item1,j) in appList" :key=j></el-option>
            </el-select>
          </el-form-item>       -->
        </div> 
      </div>
       <!-- <el-form-item label="App" prop="appList">
          <el-select v-model="ruleForm.appList" value-key = "name" style="width:40%" multiple filterable placeholder="请选择App" @change="changeFn($event,j,'appId')">
            <el-option :label="item1.name" :value="item1" v-for="(item1,j) in appList" :key=j></el-option>
          </el-select>
        </el-form-item>  -->
      <el-form-item label="接收人" prop="sendUser">
        <!-- <label>接收人</label> -->
        <el-select v-model="ruleForm.sendUser" style="width:40%" value-key ="string" multiple filterable placeholder="请选择接收人" @visible-change="focusFn" @change="changeFn($event,'','sendUser')">
            <el-option
            v-for="(item,i) in distributionOptions"
            :key="i"
            :label="item.string"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>      
      <el-form-item label="抄送人" prop="copyUser">
        <!-- <label>抄送人</label> -->
        <el-select v-model="ruleForm.copyUser" style="width:40%" multiple value-key ="name" filterable placeholder="请选择抄送人" @visible-change = "visibleChangeFn"  @change="changeFn($event)">
          <el-option
            v-for="(item,i) in cc_options"
            :key="i"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
        <el-button type="text" @click="dialogFormVisible_add = true" style="padding-left:10px;">添加</el-button>
         <el-dialog title="添加抄送人" :visible.sync="dialogFormVisible_add" append-to-body :modal-append-to-body="false">
            <el-form :model="addForm">
              <el-form-item label="人员名称" :label-width="formLabelWidth">
                <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入添加人员姓名" style="width:100%!important;"></el-input>
              </el-form-item>
              <el-form-item label="邮箱地址" :label-width="formLabelWidth">
                <el-input v-model="addForm.email" autocomplete="off" placeholder="请输入添加邮箱地址" style="width:100%!important;"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible_add = false">取 消</el-button>
              <el-button type="primary" @click="csrSure">确 定</el-button>
            </div>
          </el-dialog>
      </el-form-item>
      <el-form-item v-if="type == 2" label="接收方式" prop="type">
        <el-checkbox-group v-model="ruleForm.type">
          <el-checkbox label="Excel" name="type"></el-checkbox>
          <el-checkbox label="图片" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="type==2" label="是否拉取零点数据" prop="resource">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio label="是" value="1"></el-radio>
          <el-radio label="否" value="0"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="type == 2" label="时报时间点" prop="timeSlot">
        <el-select v-model="ruleForm.timeSlot" multiple filterable placeholder="请选择时报时间点">
          <el-option
            v-for="(item,i) in timeLimitList"
            :key="i"
            :label="item.time"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('ruleForm')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import getUserList from '@/utils/getUserList'//获取用户列表

export default {
  
  // components:{
  //   dayTimeUnit
  // },
  props: {
    pubVue:Object,
    title:String,
    visible: {
      type: Boolean,
      default: false
    },
    type:Number,
    dataSourceIs:Boolean,
    keywordsIs:Boolean,
    dataSource:Array,
    keywords:Array,
    path:String,
    
  },
  data() {
    return {
      selectOptins:[],
      show: this.visible,
      loading: false,
      ruleForm: {
        name: '',
        excelName:'',
        adType: '',
        type: [],
        resource: '',
        sourceName:[],
        KeyName:[],
        sendUser:[],
        appList:[],
        copyUser:[],
        timeSlot:[]
      },
      addForm: {
        name: '',
        email:''
      },
      timeLimitList:[{'time':'10:00','id':'10'},{'time':'10:30','id':'10.5'},{'time':'11:00','id':'11'},{'time':'11:30','id':'11.5'},
      {'time':'12:00','id':'12'},{'time':'12:30','id':'12.5'},{'time':'13:00','id':'13'},{'time':'13:30','id':'13.5'},{'time':'14:00','id':'14'},{'time':'14:30','id':'14.5'},{'time':'15:00','id':'15'},{'time':'15:30','id':'15.5'},
      {'time':'16:00','id':'16'},{'time':'16:30','id':'16.5'},{'time':'17:00','id':'17'},{'time':'17:30','id':'17.5'},{'time':'18:00','id':'18'},{'time':'18:30','id':'18.5'},{'time':'19:00','id':'19'},{'time':'19:30','id':'19.5'},
      {'time':'20:00','id':'20'},{'time':'20:30','id':'20.5'},{'time':'21:00','id':'21'},{'time':'21:30','id':'21.5'},{'time':'22:00','id':'22'},{'time':'22:30','id':'22.5'},{'time':'23:00','id':'23'},{'time':'23:30','id':'23.5'}],
      formLabelWidth: '120px',
      dialogFormVisible_add: false,
      tableDataList:[],
      cc_options:[],
      value1:[],
      distributionOptions:[],
      appList:[],
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        excelName:[{required: true, message: '请输入发送报表名称', trigger: 'blur' }],
        adType: [{ required: true, message: '请选择报表维度', trigger: 'change' }],
        type: [{ type: 'array', required: true, message: '请至少选择一个接收方式', trigger: 'change' }],
        resource: [{ required: true, message: '请选择是或者否', trigger: 'change' }],
        sendUser:[{ required: true, message: '请选择接收人', trigger: 'change'}],
        appList:[{ required: true, message: '请选择App', trigger: 'change'}],
        sourceName: [{ required: true, message: '请选择账户', trigger: 'change' }],
        KeyName:[{ required: true, message: '请选择账户', trigger: 'change'}]
      },
      cibaoList:['代理公司','渠道','报表类型','日期','时段'],
      wordsA : [],
      cascaderProps: {
        label: 'name',
        value: 'id',
        children: 'child'
      }
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
      if(v){
        // this.getList()
      }
    },
    visible(v) {
      this.show = v;

    },
    
  },
  
  mounted(){
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    this.cc_options = this.$sessionStorage.getItem('optionSelectCopyUser')
    
    console.log(this.pubVue.sizeType)

  },
  computed:{
     props(){
      return {
        lazy: true,
        
        lazyLoad :this.lazyLoad,
        // checkStrictly: true ,
        multiple: true,
      }
        
      },
  },
  methods: {

    handleChange(value) {
        console.log(value);
      },

    showFn(e,i,type){
        console.log(e,i,type);
    },
    async lazyLoad (node, resolve) {
      let _this = this
      
      const { level } = node;
      let nodes = []
      if(level!=1){
        _this.getList().then(res=>{
          nodes = res
          nodes.forEach(item => {
            item.value= item.id,
            item.label= item.name
          }) 
          resolve(nodes);        
        })
          
    
      }else{
        _this.getAppList(node.data.value).then(result => {

          nodes = result;
  //         nodes = [ {
  //   "appId" : "C102521543",
  //   "allianceAppId" : "102521543",
  //   "name" : "Badam Live",
  //   "icon" : "https://appimg.dbankcdn.com/application/icon144/f30bb3300369458aa25adcaa680327e6.png",
  //   "pkgName" : "com.badambiz.live",
  //   "intro" : "بادام بىۋاستە تارقىتىش (جىبو) سۇپىسى بولسا بادام شىركىتى يېڭىدىن ئىشلەپ چىقارغان شىنجاڭلىقلارنىڭ ئۆزىنىڭ تالانتىنى نامايەن قىلىش سۇپىسى بولۇپ ، بۇ يەردە سىز تالانتىڭىزنى جارى قىلالايسىز، دوستلىشالايسىز، ھەقسىز چولپانلارنىڭ ئويۇنلىرى كۆرەلەيسىز، تالانىتلىقلار بىلەن ، چولپانلار بىلەن دوستلىشىپ بىللە سۈرئەتلىك پاراڭلىشالايسىز، تىزدىن چۈشۈرۈپ سىناپ بىقىڭ \n\nBadam Live以音视频为载体的互动娱乐APP，您可以在Badam Live观看精彩、好赞的维语才艺直播，也可以开播向其他人展示自己的才艺，还可以参与多种互动玩法赢取奖励！\n\nBadam Live bolsa uygurqa biwasta tarkitix supisi bolup , koznak kixmix baxlan nur aynak kino oyun katarlik applarga ohximaydu,siz buyarda nahxa usul koralaysiz,qolpankar bilan dostlixalaysiz,bu,badamda qolpan bolalaysiz, tizdin badam live ni quxurup jibo kilixni baxlag .",
  //   "oneLevelId" : "13",
  //   "secondLevelId" : "23",
  //   "promoteRegions" : [ "CN" ]
  // },{
  //   "appId" : "2C102521543",
  //   "allianceAppId" : "2102521543",
  //   "name" : "2Badam Live",
  //   "icon" : "https://appimg.dbankcdn.com/application/icon144/f30bb3300369458aa25adcaa680327e6.png",
  //   "pkgName" : "com.badambiz.live",
  //   "intro" : "بادام بىۋاستە تارقىتىش (جىبو) سۇپىسى بولسا بادام شىركىتى يېڭىدىن ئىشلەپ چىقارغان شىنجاڭلىقلارنىڭ ئۆزىنىڭ تالانتىنى نامايەن قىلىش سۇپىسى بولۇپ ، بۇ يەردە سىز تالانتىڭىزنى جارى قىلالايسىز، دوستلىشالايسىز، ھەقسىز چولپانلارنىڭ ئويۇنلىرى كۆرەلەيسىز، تالانىتلىقلار بىلەن ، چولپانلار بىلەن دوستلىشىپ بىللە سۈرئەتلىك پاراڭلىشالايسىز، تىزدىن چۈشۈرۈپ سىناپ بىقىڭ \n\nBadam Live以音视频为载体的互动娱乐APP，您可以在Badam Live观看精彩、好赞的维语才艺直播，也可以开播向其他人展示自己的才艺，还可以参与多种互动玩法赢取奖励！\n\nBadam Live bolsa uygurqa biwasta tarkitix supisi bolup , koznak kixmix baxlan nur aynak kino oyun katarlik applarga ohximaydu,siz buyarda nahxa usul koralaysiz,qolpankar bilan dostlixalaysiz,bu,badamda qolpan bolalaysiz, tizdin badam live ni quxurup jibo kilixni baxlag .",
  //   "oneLevelId" : "13",
  //   "secondLevelId" : "23",
  //   "promoteRegions" : [ "CN" ]
  // } ]
          nodes.forEach(item => {
            item.value= item.allianceAppId,
            item.label= item.name,
            item.leaf= level >= 1
          });
          resolve(nodes);
          
          

        }) 
        }





        },       
        
    
    visibleChangeFn(e){
      if(e){
        this.cc_options = this.$sessionStorage.getItem('optionSelectCopyUser')
      }

    },


    //添加抄送人
    csrSure(){
      let that = this
      that.dialogFormVisible_add = false
      // that.csrParams.accountId = '71754670438'
      // that.csrParams.accountId = String(that.userDetailId)
      // that.csrParams.email = that.addForm.email
      // that.csrParams.userName = that.addForm.name
      let userName = that.addForm.name
      let email = that.addForm.email
      $http.axios.get("/api/system/user/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
        if(response.data.code == 200){
          getUserList().then(res => {
            that.cc_options = res
          })

        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onConfirm(formName) {

      let _this = this,sourceIs = false,keyIs = false,sourceNum = 0,keyNum = 0
        _this.$refs[formName].validate((valid) => {
          if(valid){
            console.log('成功2')
            console.log('选中')
            console.log(_this.ruleForm)
            if(_this.dataSourceIs){
              _this.ruleForm.sourceName.forEach((obj,i,arr)=>{
           
                if(obj.length==0){
                  sourceIs = false
                  sourceNum--
                }else{
                  sourceNum++
                  if(sourceNum ==arr.length){
                    sourceIs = true
                  }
                }
              })
            }else{
              sourceIs = true
            }
            
          if(_this.keywordsIs){
            _this.ruleForm.KeyName.forEach((obj,i,arr)=>{
            
              if(obj.length==0){
                keyIs = false
                keyNum--
              }else{
                keyNum++
                if(keyNum ==arr.length){
                  keyIs = true
                }
                
              }
            })
          }else{
            keyIs = true
          }
          
           if(!sourceIs||!keyIs){
              _this.$message({
                showClose: true,
                message: '请选择账户',
                type: 'error'
              })
           }else{
             if(_this.type==2){
              if(_this.ruleForm.timeSlot.length == 0){
                _this.$message({
                  showClose: true,
                  message: '请选择时报接收时间',
                  type: 'error'
                })
              }else{
                _this.addModelFn(_this.dayLimit)
              }
            }else{
              _this.addModelFn(_this.dayLimit)
            }
            
          }
        }
        });
        console.log(_this.ruleForm)

    },
    onCancel() {
      this.show= false;
      this.$refs.ruleForm.resetFields()
      if(this.$refs.tableDataList!=undefined){
        this.$refs.tableDataList.forEach(obj=>{
          obj.$refs.panel.clearCheckedNodes()
        })
      }
      if(this.$refs.tableDataListKey!=undefined){
        this.$refs.tableDataListKey.forEach(obj=>{
          obj.$refs.panel.clearCheckedNodes()
        })
      }
      this.pubVue.listModelFn()
    },
    addOrSetFn(type){
      
    },
    handleClear(){
      this.ruleForm.excelName = ''
      this.wordsA = []

    },
    focusFn(e){
      if(e){
        this.getFpList()
      }
    },
    changeFn(event,i,type){
      let _this = this,ownersList = [],ownersListKey = [],appIds = [],$event
      $event = event
      
      console.log(event,i)
      console.log(this.dataSource)
      console.log(this.keywords)
      if(type == 'data'|| type == 'keyword'){
        // let eleObj = $event.length!=0?this.$refs.tableDataList[i].getCheckedNodes()[0].label:''
          _this.ruleForm.appList = $event
          if($event.length!=0&&$event[0].length==1){
            _this.$message({
                  showClose: true,
                  message: '请选择应用',
                  type: 'error'
                })
          }else{
            $event.forEach(obj=>{
              appIds = obj.slice(1).concat()
              if(type == "data"&&$event.length != 0){
                let eleParentObj = $event.length!=0?_this.$refs.tableDataList[i].getCheckedNodes()[0].label:''
                ownersList.push({"ownerId":obj[0],"ownerName":eleParentObj,"appId":appIds})
                _this.dataSource[i].owners = ownersList
              }else if(type == "keyword"&&$event.length != 0){
                let eleParentObjKey = $event.length!=0?_this.$refs.tableDataListKey[i].getCheckedNodes()[0].label:''
                ownersListKey.push({"ownerId":obj[0],"ownerName":eleParentObjKey,"appId":appIds})
                _this.keywords[i].owners = ownersListKey
              } 

            })
          }
          

        
      }
      console.log(this.dataSource)
      console.log(this.keywords)
    },
    //获取分配人员列表
    getFpList(){
      let _this = this
      // $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
      $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId=6").then(function(response) {
        if(response.data.code == 200){
          _this.distributionOptions = response.data.data
          // _this.distributionOptions.forEach((obj,i)=>{
          //   if(obj.userId == _this.userId){
          //     _this.ruleForm.sendUser.push(obj)
              
          //   }
          // })
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    
    //获取菜单列表
    async getList() {
      console.log(1)
      let that = this
      let myoption = await $http.axios.get("/api/hwat/accountUser/selectList?userId="+that.userId).then(function(response) {
      console.log(response)
      if(response.status==200){
        let tableDataList = response.data
            tableDataList.map((item)=>{
              item.value = item.id
              item.label = item.name
            })
         return tableDataList 
        }
      })
      return myoption
    },
    //获取app列表
    async getAppList(ownerId) {
      let myData = await $http.axios.get("/api/hwReport/reportModel/listApp?ownerId="+ownerId).then(response=> {
      console.log(response)
      if(response.data.code==200){
        let appList = response.data.data
        return appList
        }
      })
      return myData
    },
    //添加
    addModelFn(){
      let _this = this,params = {},excelFlag = 0,imgSwtich = 0,sendUser = {"string":[],"id":[]},copyUser = {"string":[],"id":[]}
      let times = [],excelConfig = {}

      times = _this.ruleForm.timeSlot.toString()
      if(_this.ruleForm.type.length>0){
        if(_this.ruleForm.type[0]=="图片"){
          imgSwtich = 1
        } else if(_this.ruleForm.type[0]=="Excel"){
          excelFlag = 1
        }
        if(_this.ruleForm.type.length == 2){
          excelFlag = 1
          imgSwtich = 1
        }
      }else{
        if(_this.type == 1){
          excelFlag = 1
          imgSwtich = 1
        }
      }
      _this.ruleForm.sendUser.forEach(obj=>{
        sendUser.string.push(obj.string)
        sendUser.id.push(obj.userId)
      })
      _this.ruleForm.copyUser.forEach(obj=>{
        copyUser.string.push(obj.name)
        copyUser.id.push(obj.id)
      })
      if(_this.dataSource.length!=0){
        excelConfig.dataSource = _this.dataSource
      }
      if(_this.keywords.length!=0){
        excelConfig.kwDataSource = _this.keywords
      }
      params = {
        "adType":_this.ruleForm.adType,
        // "channelId":6,
        "copySender": copyUser.id.toString(),
	      "copySenderName": copyUser.string.toString(),
        "excelConfig": excelConfig,
        "excelFlag": excelFlag,
        "imgFlag": imgSwtich,
        "zeroFlag": _this.ruleForm.resource=="是"?1:0,
        "title": _this.ruleForm.excelName,//报表名称，词包
        "path": _this.path,
        "name": _this.ruleForm.name,//模板名称
        "sendFlag": 1,
        "sender": sendUser.id.toString(),
        "senderName": sendUser.string.toString(),
        "time": times,
        "type": _this.type,
        "userId": _this.userId,
        "userName": _this.userName,
        "sizeType":_this.pubVue.sizeType,
        "deptId": _this.deptId,
      }
      $http.axios.post("/api/hwReport/reportModel/addModel",params).then(res=> {
        if(res.data.code == 200){
          _this.onCancel()
        }else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    addWordsFn(val){
      let _this = this,wordsA = []
      // _this.ruleForm.excelName = ''
      if(_this.wordsA.length!= 0){
        _this.wordsA.forEach((obj,i,arr)=>{
          console.log(obj)
          if(val == obj){
            arr.splice(i,1)
            
          }
          
        //  _this.ruleForm.excelName +='{'+arr[i]+'}'

        })
        _this.wordsA.push(val)
        wordsA = _this.wordsA
        // wordsA.forEach(obj=>{
        _this.ruleForm.excelName +='{'+val+'}'
      // })
      }else{
        console.log(val)
        _this.ruleForm.excelName +='{'+val+'}'
        _this.wordsA.push(val)
      }
      
      
    },
    
    
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
.addWord{
  cursor: pointer;
  display: inline-block;
  margin: 5px 8px;
  font-size: 12px;
  color: #ccc;
}
.redTip{
      color: #F56C6C;
    margin-right: 4px;

}
.el-form-item__content span.sourceClass{
  font-size: 12px!important;
    margin-left: -130px;
    margin-right: 10px;
    width: 120px;
    display: inline-block;
    text-align: right;
}
</style>

