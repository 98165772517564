
<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class="hl_headers sticky" style="margin-bottom: 0">
          <div class="hl_headerLeft">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="hl_headerLeftDrowp"
            >
              <el-breadcrumb-item>内容运营</el-breadcrumb-item>
              <el-breadcrumb-item>日报模板库</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-upload
              ref="upload"
              :headers="myHeaders"
              action="/api/miReport/newReport/uploadExcel"
              accept=".xlsx"
              :limit="1"
              :on-success="onSuccess"
              :show-file-list="false"
            >
              <el-button slot="trigger" size="small" type="primary"
                >+ 新建模板</el-button
              >
            </el-upload>
            <!-- <el-link :href="`${publicPath}/dayRule.html`" target='_blank' type='primary'>日报时报 系统使用说明</el-link> -->
            <!-- <router-link tag="a" target="_blank" to="/auth-management/Faq?id=28">日报时报 系统使用说明</router-link> -->
            <el-button type="primary" size="small" @click="goFaq"
              >日报时报 系统使用说明</el-button
            >
          </div>
        </div>
        <div class="line_function_contant">
          <div
            :class="item.id == 5 ? 'active' : ''"
            v-for="(item, i) in channelTitleList"
            :key="i"
            @click="item.id != 5 && clickChangeChannel(item.id)"
          >
            {{ item.name.toUpperCase() }}
          </div>
        </div>
        <div class="radius_function_contant">
          <div @click="goToTimes">时报</div>
          <div class="active">日报</div>
        </div>
        <div class="search_container">
          <el-form :inline="true" ref="ruleForm">
            <el-form-item label="">
              <el-input
                v-model="dayName"
                clearable
                placeholder="请输入模板名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="adId"
                clearable
                placeholder="请输入广告主名称查找"
              ></el-input>
            </el-form-item>

            <el-form-item label="">
              <el-input
                v-model="sendName"
                clearable
                placeholder="请输入接收人"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="copyName"
                clearable
                placeholder="请输入抄送人"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                clearable
                placeholder="请选择报表开关状态"
                v-model="channelm"
              >
                <el-option label="开启报表状态" value="2">开</el-option>
                <el-option label="关闭报表状态" value="1">关</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="
                  listModelFn(page, dayName, adId, sendName, copyName, channelm)
                "
                >查询</el-button
              >
              <el-button type="info" size="small" @click="resetForm()"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="materiaContent">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :cell-class-name="tableCellClassName"
            @cell-click="clickcell"
          >
            <el-table-column
              v-for="(item, index) in titleList"
              :key="index"
              :prop="item.prop"
              :label="item.name"
              :width="item.width"
              show-overflow-tooltip
            >
              <template v-if="item.prop == 'reportSwitch'" v-slot="scope">
                <el-switch
                  v-model="scope.row.reportSwitch"
                  :active-value="2"
                  :inactive-value="1"
                  @change="getBrandList(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="280" label="操作">
              <template>
                <el-button
                  class="solid-tag-success"
                  data-type="infoBtn"
                  plain
                  size="mini"
                  >详情</el-button
                >
                <el-button
                  class="solid-tag-instructions"
                  data-type="xiuGai"
                  plain
                  size="mini"
                  >修改</el-button
                >
                <el-button
                  class="solid-tag-warning"
                  data-type="sendBtn"
                  plain
                  size="mini"
                  >重发抄送人</el-button
                >
                    <el-button
                  class="solid-tag-error"
                      data-type="delBtn"
                      plain
                      size="mini"
                      >删除</el-button
                    >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footerPaging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleSizeChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageCount"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.totalRow"
          >
          </el-pagination>
        </div>
      </template>
    </PocCardTable>
    <dayTimeDialog
      :pubVue="pubVue"
      :visible.sync="dayTimeDialogIs"
      :path="path"
      :type="type"
      :keywords="keywords"
      :keywordsIs="keywordsIs"
      :dataSource="dataSource"
      :dataSourceIs="dataSourceIs"
      :title="title"
      ref="dayTime"
    ></dayTimeDialog>
    <dayInfoDialog
      :visible.sync="dayInfoDialogIs"
      :type="type"
      :title="title"
      ref="dayInfo"
    ></dayInfoDialog>
    <dayTimeXDialog
      :pubVue="pubVue"
      :visible.sync="dayTimeXDialogIs"
      :type="type"
      :title="title"
      ref="dayTimeXDialog"
      :keywordsIs="keywordsIs"
      :dataSourceIs="dataSourceIs"
    ></dayTimeXDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import dayTimeDialog from "@/views/dialog/dayTimeMi";
import dayTimeXDialog from "@/views/dialog/dayTimeXMiDialog";
import dayInfoDialog from "@/views/dialog/dayInfoMiDialog";

export default {
  name: "Daily",
  components: {
    dayTimeDialog,
    dayInfoDialog,
    dayTimeXDialog,
  },
  data() {
    return {
      pubVue: this,
      fileList: [],
      publicPath: process.env.VUE_APP_BASENAME,
      dayTimeDialogIs: false,
      dayInfoDialogIs: false,
      dayTimeXDialogIs: false,
      // tableData:[{
      //   "id": 10,
      //   "channelId": 3,
      //   "reportNum": "D1639036020322",
      //   "reportName": "9号-5",
      //   "isNeedYesterday": 1,
      //   "type": 1,
      //   "reportSwitch": 2,
      //   "time": null,
      //   "adType": 10,
      //   "title": "{时间}{渠道}{代理公司}你",
      //   "senderName": "OPPO-华北-杨洋-272",
      //   "sender": "272",
      //   "copyUserName": "亨利嘉业超级管理员,刘楚莨",
      //   "copyUser": "1,4",
      //   "excelConfig": "{\"dataSource\":{\"数据源\":[1000092281],\"数据源 (2)\":[1000092281]},\"keyword\":{\"关键词\":[1000092281]}}",
      //   "account": "HL-小鹿中医-商店/信息流",
      //   "path": "D:\\uploadSource\\1f363335-d196-4857-8e89-bbaa9ece7647.xlsx",
      //   "imgSwtich": 2,
      //   "excelSwitch": 2,
      //   "status": 2,
      //   "deptId": 298,
      //   "userId": 306
      // }],
      tableData: [],

      // 分页
      page: 1, //当前页
      pageCount: 10, //每页大小
      totalRow: 0, //总条数
      totalPage: 0, //总页数

      title: "日报",
      dataSource: [],
      keywords: [],
      dataSourceIs: false,
      keywordsIs: false,
      sizeType: 0, //文件大小标识 4-大于18M 3-大于10M 2-大于5M 1-小于等于5M
      path: "",
      type: 1,

      dayName: "",
      sendName: "",
      copyName: "",
      adId: "",
      channelm: "",
      titleList: [
        { name: "编号", prop: "reportNum", width: "130px" },
        { name: "报表开关", prop: "reportSwitch", width: "80px" },
        { name: "模板名称", prop: "reportName" },
        { name: "广告主名称", prop: "account" },
        { name: "接收人", prop: "senderName" },
        { name: "抄送人", prop: "copyUserName" },
      ],
      myHeaders: { Authorization: "" },
      accountSelectionListShow: true, //用户是否有权限
      channelTitleList: [], //权限名称列表
    };
  },
  computed: {},
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
    this.userName = this.$sessionStorage.getItem(config.USERNAME);
    this.deptName = this.$sessionStorage.getItem(config.DEPTNAME);
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
    this.channelTitleList = this.$sessionStorage.getItem("channelTitleList");
    this.channelTitleList != undefined
      ? (this.accountSelectionListShow = true)
      : (this.accountSelectionListShow = false);

    this.listModelFn();
    this.getId();
  },
  methods: {
    //channel的tab切换
    clickChangeChannel(channelId) {
      let channelNameList = {
        3: "authManagement.Daily",
        4: "VivoAccountManagement.DailyVivo",
        6: "HwAccountManagement.DailyHw",
        8: "authManagement.DailyAsa",
      };
      this.$router.push({ name: channelNameList[channelId] });
    },

    goFaq() {
      let route = this.$router.resolve({
        path: "/auth-management/FaqVivo",
        query: { id: 28, channelId: 5 },
      });
      window.open(route.href, "_blank");
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getId() {
      this.myHeaders.Authorization =
        "Bearer " + this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
      this.uploadId = this.$route.params && this.$route.params.id;
      console.log(this.uploadId);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // "reportId": [],
    // "reportSwitch": 0
    //按钮开关
    getBrandList(row) {
      let _this = this,
        params = {};
      params = {
        reportId: [row.id],
        reportSwitch: row.reportSwitch,
      };
      console.log(row); //api/miReport
      $http.axios
        .post("/api/miReport/newReport/switchReport", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.listModelFn();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,
        dataSource = [],
        keywords = [];
      // let video = []
      console.log(response);
      if (response.code == 200) {
        that.dayTimeDialogIs = true;
        if (response.data.dataSource.length != 0) {
          that.dataSourceIs = true;
        }
        if (response.data.keywords.length != 0) {
          that.keywordsIs = true;
        }
        response.data.dataSource.forEach((element, i) => {
          dataSource.push({ name: element });
        });
        response.data.keywords.forEach((element, i) => {
          keywords.push({ name: element });
        });
        that.dataSource = dataSource;
        that.keywords = keywords;
        that.path = response.data.path;
        that.sizeType = response.data.sizeType;
      } else {
        that.$message({
          duration: 0,
          showClose: true,
          message: response.msg,
          type: "error",
        });
      }
      this.$refs.upload.clearFiles();
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event) {
      console.log(row);
      console.log(column);
      console.log(cell);
      console.log(event);
      let yjType = event.target.parentNode.dataset.type;
      if (yjType == "infoBtn") {
        this.dayInfoDialogIs = true;
        this.$refs.dayInfo.getuserChannel(row.id);
      } else if (yjType == "xiuGai") {
        this.dayTimeXDialogIs = true;
        this.$refs.dayTimeXDialog.getuserChannel(row.id);
      } else if (yjType == "sendBtn") {
        this.pushFn(row.id, row.reportNum);
      } else if (yjType == "delBtn") {
        this.deletePlanFn(row.id);
      }
    },
    // 重新发送抄送人
    pushFn(id, reportNum) {
      let _this = this;
      $http.axios
        .get("/api/miReport/model/push?id=" + id + "&type=2")
        .then((res) => {
          if (res.data.code == 200) {
            _this.$message({
              showClose: true,
              message: reportNum + "已重新发送给抄送人，请注意查收",
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    // tab跳转
    goToDaily() {
      this.$router.replace("/auth-management/Daily");
    },
    // tab跳转
    goToTimes() {
      // this.$router.replace('/vivoAccount-management/TimesVivo')
      this.$router.replace("/miAccount-management/TimesMi");
    },
    //点击修改进入修改页面
    editTimes(row) {
      console.log(row);
      this.$router.push({
        name: "authManagement.TimesEdit",
        params: { id: row.id },
      });
    },

    //列表 /vivoReport/listModel
    listModelFn(page, dayName, adId, sendName, copyName, channelm) {
      let _this = this,
        params = {};
      params = {
        pageNum: page || 1,
        pageSize: 10,
        reportName: dayName,
        ownerNameLike: adId,
        senderNameLike: sendName,
        copyUserNameLike: copyName,
        reportSwitch: channelm,
        type: _this.type,
      };
      $http.axios
        .post("/api/miReport/newReport/listModel", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.tableData = res.data.data.items;
            _this.totalRow = res.data.data.totalCount; //分页总条数
            console.log(666);
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    //删除按钮
    deletePlanFn(e) {
      let that = this,
        ids = [];
      ids.push(e);
      this.$alert(
        `<div><img class="icon" src="${require("@/assets/img/deleteIcon.png")}" /><div class="text_tip">您是否确认删除报表模板，删除后数据源中数据将不再累加，且无法恢复？</div></div>`,
        "删除",
        {
          center: true,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          customClass: "icon_custom_popup",
          beforeClose: (action, instance, done) => {
            if (action == "confirm") {
              instance.confirmButtonLoading = true;
              $http.axios
                .get("/api/miReport/newReport/delModel?ids=" + ids)
                .then((res) => {
                  if (res.data.code == 200) {
                    that.listModelFn();
                    done();
                    instance.confirmButtonLoading = false;
                  } else {
                    that.$message({
                      showClose: true,
                      message: res.data.msg,
                      type: "error",
                    });
                    instance.confirmButtonLoading = false;
                  }
                })
                .catch(() => {
                  instance.confirmButtonLoading = false;
                });
            } else {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },

    //头部表单重置
    resetForm() {
      this.dayName = "";
      this.sendName = "";
      this.copyName = "";
      this.adId = "";
      this.channelm = "";
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    //清空自定义列弹框选中项
    clearWrap() {
      this.checkedCities = [];
      this.handCheckList = [];
    },
    // 分页
    handleSizeChange(val) {
      this.findAll(val);
    },

    //分页方法
    findAll(val) {
      //获取数据接口（调用封装的接口）
      this.listModelFn(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  overflow: visible;
}
</style>







