<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="5%"
    :close-on-click-modal="false"
    class="role-detail-dialog">
    <div slot="title" class="header-box">
      <span class="rddd-title">Detail</span>
      <!-- <span class="iconfont poc-icon-primary poc-size16 icon-oneui-download" @click="onDownload"></span> -->
    </div>
    <div class="roleDetail-box">
      <h3>Access</h3>
      <div class="roleDetail-box-rawdata">
        <span>Rawdata_Upload</span>
        <span>Rawdata_Download</span>
      </div>
      <div  class="roleDetail-box-select">
        <el-select v-model="value" placeholder="请选择" style="width:160px" class="marginR30">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="请选择" style="width:160px" class="marginR30">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="请选择" style="width:160px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
         <PocTable
            :data="tableData"
            :current-page.sync="pageConfig.pageNum"
            :total="pageConfig.total"
            max-height="454"
            @size-change="pageSizeChange"
            @page-current-change="pageChange">
            <el-table-column
              v-for="item in columnConfig"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :min-width="120"
              show-overflow-tooltip>
            </el-table-column>
          </PocTable>
      </div>
    </div>
  
    <span slot="footer"></span>
  </el-dialog>
</template>

<script>
import Api from '@/api';

export default {
  name: 'RawDataDetailDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rawData: Object,
  },

  data() {
    return {
      show: this.visible,
      columnConfig: [{
        name: 'hehe',
        key: 'name'
      },
      {
        name: 'date',
        key: 'date'
      },{
        name: 'address',
        key: 'address'
      }],
      pageConfig: {
        pageNum: 1,
        total: 20
      },
      tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }],
       options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: ''
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },

  methods: {
    /**
     * 下载
     */
    onDownload(row) {
    },
    pageSizeChange() {},
    pageChange() {}
  },
}
</script>

<style lang="scss">
.role-detail-dialog {
  .el-dialog__body{
    margin-top: 0;
  }
  .roleDetail-box{
    h3{
      text-align: left;
      padding: 0 15px;
      margin: 0;
    }
    &-rawdata{
      padding: 0 15px;
      display: flex;
    }
    &-select{
      padding: 15px 15px 35px;
      text-align: left;
    }
  }
  // .header-box {
  //   display: flex;
  //   align-items: center;
  // }

  // .el-dialog .el-dialog__body {
  //   padding-top: 0;
  // }

  // .rddd-title {
  //   margin-right: $MP16;
  // }
  .maringT0{
    margin-top: 0;
  }
  .marginR30{
    margin-right: 30px;
  }
}
</style>
