<template>
  <div class="poc-planet">
    <div class="planet-bg">
      <img :src="BackgroundRing1" />
      <img class="stars" :src="BackgroundStars2" />
      <img :src="BackgroundRing1" />
      <img class="rate1" :src="BackgroundWhirlpool3" />
      <img class="rate3" :src="BackgroundPlanet4" />
      <img class="rate2" :src="BackgroundPlanet5" />
      <img class="rate1" :src="BackgroundPlanet6" />
    </div>
    <div class="planet-title">
      <div>{{ this.type.toString()[0] }}</div>
      <div>{{ this.type.toString()[2] }}</div>
    </div>
    <div class="planet-info">
      <div v-for="(item, index) in infos" :key="index" :class="index ? 'vice' : 'main'">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import BackgroundRing1 from './assets/底纹1圆环.png';
import BackgroundStars2 from './assets/底纹2星星.png';
import BackgroundWhirlpool3 from './assets/底纹3漩涡.png';
import BackgroundPlanet4 from './assets/底纹4大星球.png';
import BackgroundPlanet5 from './assets/底纹5中星球.png';
import BackgroundPlanet6 from './assets/底纹6小星球.png';
export default {
  name: 'Planet',
  props: {
    type: {
      type: [String, Number],
      require: true
    },
    infos: {
      type: Array,
      default: () => ['Server error!', 'Please contact the administrator.']
    }
  },
  data() {
    return {
      BackgroundRing1,
      BackgroundStars2,
      BackgroundWhirlpool3,
      BackgroundPlanet4,
      BackgroundPlanet5,
      BackgroundPlanet6
    };
  }
};
</script>

<style lang="scss">
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes flashing {
  from {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}

.poc-planet {
  position: relative;
  // height: 856px;
  // width: 856px;
  height: 100%;
  width: 100%;
  color: #fff;
  .planet-bg {
    width: 100%;
    height: 100%;
    // transform: scale(0.7);
    .rate1 {
      animation: rotate 50s linear infinite;
    }
    .rate2 {
      animation: rotate 60s linear infinite;
    }
    .rate3 {
      animation: rotate 85s linear infinite;
    }
    .stars {
      animation: flashing 3s linear infinite;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .planet-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    * {
      font-size: 148px;
      font-family: 'tradeGothicLHExtended', Fantasy !important;
    }
  }
  .planet-info {
    position: absolute;
    top: 68%;
    left: 0;
    width: 100%;
    text-align: center;
    .main {
      font-size: 36px;
    }
    .vice {
      font-size: 18px;
    }
  }
  .planet-txt {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 100%;
    .planet-txt-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      transform: translate(50%, 0);
      div {
        font-size: 148px;
        font-family: 'tradeGothicLHExtended', Fantasy !important;
      }
    }
    .planet-txt-info {
      text-align: center;
      padding: 0;
      .main {
        font-size: 36px;
        display: block;
      }
      .vice {
        font-size: 18px;
        display: block;
      }
    }
  }
}
</style>
