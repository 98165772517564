import { $http } from './http';
import config from './config';

const { VUE_APP_BASEURL } = process.env;

export default {
  /**
   * hl登录
   */
  loginHl({ username,password }) {
    return $http.post(`/api/auth/login`, {
      username, password
    });
  },

  /**
   * 权限-添加部门-add
   */
  manageQx(params) {
    return $http.put(`/api/system/dept/add`, params);
  },
   /**
   * 权限-部门列表
   */
  manageHqlist(params) {
    return $http.get(`/api/system/dept/treeselect`,params);
  },

   /**
   * 权限-删除部门
   */
  deleteDeptList() {
    return $http.get(`/api/system/dept`)
  },

  /**
   * 权限-部门人员列表
   */
  // sysUserList() {
  //   return $http.post(`/api/system/user/list`);
  // },

   /**
   * 权限-角色列表
   */
  roleList() {
    return $http.get(`/api/system/role/list`);
  },

  /**
   * 权限-选择角色下拉框
   */
  roleSelect() {
    return $http.get(`/api/system/role/optionselect`);
  },

   /**
   * 权限-增加角色
   */
  roleAdd(params) {
    return $http.post(`/api/system/role`,params);
  },

   /**
   * 权限-职位列表获取
   */
  postList() {
    return $http.get(`/api/system/post/list`);
  },

    /**
   * 权限-权限职位新增
   */
  postAdd(params) {
    return $http.post(`/api/system/post`,params);
  },

   /**
   * 权限-权限菜单列表获取
   */
  menuList() {
    return $http.get(`/api/system/menu/treeselect`);
  },

  /**
   * 权限-权限新增菜单
   */
  addMenuList(params) {
    return $http.post(`/api/system/menu`),params;
  },


  menulistAdd(params) {
    return $http.post(`/api/system/menu`,params);
  },

    /**
   * 权限-权限日志列表
   */
  logList() {
    return $http.get(`/api/system/log/list`);
  },

    /**
   * 权限-权限查询日志列表
   */
  // selectLogList(params) {
  //   return $http.get(`/api/system/log/list`,params);
  // },
  
  /**
   * 权限-权限导出日志列表
   */
  exportLogList(params) {
    return $http.post(`/api/system/log/export`,params);
  },

  /**
   * 权限-权限添加人员
   */
  addStaff(params) {
    return $http.post(`/api/system/user`,params);
  },

  /**
   * 权限-权限职位管理获取下拉列表
   */
  selectPost() {
    return $http.get(`/post/optionselect`);
  },







 



  // login({password, username,callbackUrl,appName }) {
  //   return $http.post(`${VUE_APP_BASEURL}/security-back/api/v1/cus/acc-login`, {
  //     password, username,callbackUrl,appName
  //   });
  // },
  /**
   * 登出
   */
  logout() {
    return $http.post(`${VUE_APP_BASEURL}/security-back/api/v1/cus/logout`, { project: config.PROJECT_CODE });
  },
  /**
   * 跳转Lionid登录
   */
  goLionid() {
    return `${VUE_APP_BASEURL}/security-back/api/v1/sso/gwLogin`
  }
};
