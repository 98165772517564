<template>
    <div class="authority common-page newcommon-page">
      <!-- <planHeader :breakTitle="breakTitle"></planHeader> -->
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="ruleForm" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-input v-model = "planName" clearable placeholder="请输入计划名称查找"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "planId" 
                    clearable           
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入计划ID查找"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select  clearable placeholder="请选择推广目的" v-model="channel">
                      <el-option
                        v-for="(item,i) in qdoptions.goal"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select clearable placeholder="请选择状态" v-model="channeln">
                      <el-option
                        v-for="(item,i) in qdoptions.userStatuList"
                        :key="i"
                        :label="item.text"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <div class="block">
                      <el-date-picker
                        v-model="value1"
                        type="daterange"
                        style="width:240px;"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "getAccountPlanListFn(page,planName,planId,channel,channeln,value1)">查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
            </div>
            <div class="clearfix">
              <div class="fL MBOTTOM">
                <el-button type="primary" size="small" @click="creatPlan('CreatPlan','10')">新建计划</el-button>
                <!-- <el-button type="primary" size="small" >计划状态</el-button> -->

                <el-dropdown trigger="click" @command = "changeStatusList">
                  <el-button type="primary" size="small" @click="updateOppoSet('planStatus')">
                    计划状态
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-button type="primary" size="small" @click="warmIs('dayLimit',url[1])">批量日限额</el-button>
                <!-- <el-button type="primary" size="small" >预警开关</el-button> -->
                <el-dropdown trigger="click" @command = "upAdPlanWarningSwitchFn">
                  <el-button type="primary" size="small" @click="warmIs('warningOpen',url[0])">
                      预警开关
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>                
                <el-button type="primary" size="small" @click="updateOppoSet('startStop')">批量启停设置</el-button>
                <el-button type="primary" size="small" @click="goPlan('/plan-management/StartStop')">设置启停页面</el-button>
                <el-button type="primary" size="small" @click="copyPlanBtn">复制计划</el-button>
                <el-button type="primary" size="small" @click="copyData">复制计划记录</el-button>
              </div>
              <!-- 复制计划弹框 -->
              <div class="copyPlan">
                <el-dialog 
                  title="复制广告计划"
                  :visible.sync="dialogCopyPlan" 
                  center
                  :show-close = "false"
                  class="copyPlanDialog"
                  width="1000px">
                  <div class="dialogIcon"><img :src='suCaiIcon'></div>
                  <el-form :model="ruleFormCopy" :rules="rulesCopy" ref="ruleFormCopy">
                    <el-form-item label="复制内容" class="required transferBox" :label-width="formLabelWidth">
                      <el-form-item label="广告计划" prop="copyAdplan" label-width="80px">
                        <el-input v-model="ruleFormCopy.copyAdplan" :disabled="true" autocomplete="off" class="copyPlanLength"></el-input>
                      </el-form-item>
                      <!-- 广告组 -->
                      <el-form-item label="广告组" label-width="80px">
                        <el-transfer
                          style="text-align: left; display: inline-block"
                          v-model="ruleFormCopy.copyAdGroup"
                          filterable
                          @mouseover.native="addTitle"
                          :render-content="renderFunc"
                          :titles="['未选中', '已选中']"
                          :format="{
                            noChecked: '${total}',
                            hasChecked: '${checked}/${total}'
                          }"
                          @change="handleChangeGroup"
                          :data="groupDataDetail">
                        </el-transfer>
                      </el-form-item>
                      <!-- 广告 -->
                      <el-form-item label="广告" label-width="80px">
                          <el-transfer
                          style="text-align: left; display: inline-block"
                          v-model="ruleFormCopy.copyAdadvert"
                          filterable
                          @mouseover.native="addTitle"
                          :render-content="renderFunc"
                          :titles="['未选中', '已选中']"
                          :format="{
                            noChecked: '${total}',
                            hasChecked: '${checked}/${total}'
                          }"
                          @change="handleChangeAdvert"
                          :data="advertDataDetail">
                        </el-transfer>
                      </el-form-item>
                    </el-form-item>
                    <el-form-item label="复制份数" prop="copyNum" :label-width="formLabelWidth">
                      <span class="copyNumber">为每个计划复制
                        <el-input 
                          v-model="ruleFormCopy.copyNum" 
                          class="copyInput" 
                          autocomplete="off" 
                          :min="1"
                          :max="10"
                          placeholder="范围：1～10">
                        </el-input>份
                      </span>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer footerLast">
                    <el-button type="primary" class="newDialogCancel" @click="cancleCopyPlan">取消保存</el-button>
                    <el-button type="primary" class="newDialog" @click="sureCopyPlan('ruleFormCopy')">确认保存</el-button>
                  </div>
                </el-dialog>
              </div>
              <div class="fR MBOTTOM">
                <!-- <el-button type="danger" size="small" >数据筛选</el-button> -->
                <el-button type="success" size="small" @click = "zdyFn">自定义列</el-button>
                <el-button type="warning" size="small" @click = "exportPlan">下载报表</el-button>
              </div>
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  style=" cursor:pointer;"
                  :data="tableData"
                  tooltip-effect="dark"
                  @cell-click="clickcell"
                  :cell-style="setRowStyle"
                  @selection-change="handleSelectionChange">
                  <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="planName"
                  label="广告计划名称"
                  width="200">
                </el-table-column>
                  <template v-for="(item, index) in titleList">
                    <el-table-column
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else                                   
                        :disabled = "scope.row.disabledIs">
                          <span v-if='scope.column.property == "status2" || scope.column.property =="startSwitch" || scope.column.property == "warningSwitch"'>
                            {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                            <i v-if = 'scope.column.property =="startSwitch"'
                              :class = "{'isSwitch':scope.column.property =='startSwitch','active':scope.column.property =='startSwitch'&&scope.row[scope.column.property]==0}"></i>
                          </span>
                          <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </div> 
                        <template v-if='item.prop=="status2"'>
                          <el-switch
                            v-model="scope.row.status2"
                            class="switchStyle"
                            :active-value="0"  
                            :inactive-value="1"
                            active-color="#48916F"
                            inactive-color="#EAEFED"
                            @change = "changeStatus(scope.row.status2,scope)">
                          </el-switch>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='220'
                  :show-overflow-tooltip="true"
                  label="操作">
                    <template slot-scope="scope">
                      <el-button class="solid-tag-success" data-type = 'qtBtn' plain size="mini">启停</el-button>
                      <el-button class="solid-tag-warning" data-type = 'yjBtn' plain size="mini">预警</el-button>
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row.planId)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                        </template>
                      </el-popconfirm>
                  </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="clearfix">
              <!-- <div class="allOperation fL">
                <div class="totalNum fL">共<i>50</i>条</div>
                <el-select v-model="value" placeholder="批量操作">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div> -->
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
              <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="30%">
              <span class="el-icon-loading uploading" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <!-- <dayLimitDialog  :visible.sync="showDialog" :params = 'userContent' :yulanList = "yulanList" :limitIs = "limitIs" :pubVue="pubVue"></dayLimitDialog> -->
      <dayLimitSingleDialog :visible.sync="showSingleDialog" :userContent = 'userContent' :yulanList = 'yulanList' :limitIs = "limitIs" :pubVue="pubVue"></dayLimitSingleDialog>
      <startLimitSingleDialog :visible.sync="showDialog" :nowIs="nowIs" :yulanList = "yulanList" :dialogTitle="dialogTitle" :limitIs = "limitIs" :userContent = 'userContent' :planType = "planType" :pubVue="pubVue"></startLimitSingleDialog>
      <earlyWarningSetDialog ref = 'earlyWarningDialog' :visible.sync="showEarlyWarningSetDialog" :userContent = 'userContent' :pubVue = 'pubVue'></earlyWarningSetDialog>
      <zdy :visible.sync="showzdyDialog" ref = 'zdy' :pubVue="pubVue" :typeName = 10></zdy>
      <timeDialog :visible.sync="timeDialog" :objIdType = 10 :userContent = 'userContent' :yulanList = "yulanList" :pubVue="pubVue"></timeDialog>
      <startStopDialog ref = 'startStopDialogIs' :visible.sync="startStopDialog" :pubVue="pubVue"></startStopDialog>
      <tiaojiaDialog ref = 'tiaojiaDialogIs' :visible.sync="tiaojiaDialog" :planType = "planType" :pubVue="pubVue"></tiaojiaDialog>
      <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :planName = "planName" :pubVue="pubVue" :command = "command+''" :typeName = "'plan'"></alertStartStopDialog>
    </div>
  </template>
  
  <script>
  import suCaiIcon from '@/assets/img/suCaiIcon.png';
  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import dayLimitDialog from '@/views/dialog/dayLimitDialog'
  import earlyWarningSetDialog from '@/views/dialog/earlyWarningSetDialog'
  import dayLimitSingleDialog from '@/views/dialog/dayLimitSingleDialog'
  import startLimitSingleDialog from '@/views/dialog/startLimitSingleDialog'
  import zdy from './zdy'
  import timeDialog from '@/views/dialog/timeDialog'
  import startStopDialog from '@/views/dialog/startStopPageDialog'
  import tiaojiaDialog from '@/views/dialog/tiaojiaDialog'
  import alertStartStopDialog from '@/views/dialog/alertStartStopDialog'
  export default {
    components: {
      // dayLimitDialog,
      earlyWarningSetDialog,
      zdy,
      dayLimitSingleDialog,
      startLimitSingleDialog,
      timeDialog,
      startStopDialog,
      tiaojiaDialog,
      alertStartStopDialog,
    },
    props:{
      newVue:Object,
    },
    data() {
      const generateData = _ => {
        const data = [];
        for (let i = 1; i <= 15; i++) {
          data.push({
            key: i,
            label: `备选项 ${ i }`,
            disabled: i % 4 === 0
          });
        }
        return data;
      };
      return {
        suCaiIcon,
        pubVue: this,//空vue，用于子组件之间传值
        showDialog:false,
        nowIs:false,
        showEarlyWarningSetDialog:false,
        showSingleDialog:false,
        showzdyDialog:true,
        timeDialog:false,
        startStopDialog:false,
        alertStartStopDialogIs:false,
        tiaojiaDialog:false,
        planStatus:false,//计划状态下拉列表是否展示
        exprtVisible:false,//
        uploadingShow:false,//确认导出弹框
        planType:20,
        dialogTitle:'批量日限额',
        // userContent:{},//登录用户信息&弹框默认
        qdoptions:{goal:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],
                  userStatuList:[{text:'启动中',id:'0'},{text:'暂停中',id:'1'},{text:'余额不足',id:'2'},{text:'达到账户日预算',id:'3'},{text:'达到计划日预算',id:'4'}]},//选择状态
        warningOpenList:[],//['开启','关闭']
        value1: [],//时间
        tableOption:[],
        title:'',
        titleList:[
        // {name:'广告计划名称',prop:'planName'},
        {name:'投放开关',prop:'status2'},// 0开启 1关闭
        {name:'广告计划ID',prop:'planId'},
        {name:'计划日限额',prop:'dayBudget'},
        {name:'已设置启停',prop:'startSwitch'},//0是，1否
        {name:'计划状态',prop:'showStatusName'},//启动中-0 暂停中-1 广告组暂停-2 计划暂停-3
        {name:'预警状态',prop:'warningSwitch'},//null未设置 0开启 1关闭
        {name:'预警日预算',prop:'dayWarning'}],
        showStatusList:{
                        status2:[''],
                        startSwitch:['是','否'],
                        warningSwitch:['开启','关闭','未设置']},
        planName:'',//请输入计划名称查找
        planId:'',//请输入计划ID查找
        channel:'',//请选择推广目的
        channeln:'',//请选择状态
        channelm:'',//请选择预警状态
        // tableData: [{"startSwitch":null,
        // "warningSwitch":null,
        // "warningTop":null,
        // "dayBudget":"412.00",
        // "warningLow":null,
        // "planName":"412412",
        // "planId":200124067,
        // "showStatus":0,
        // "status2":0},
        // {"startSwitch":null,
        // "warningSwitch":null,
        // "warningTop":null,
        // "dayBudget":"不限",
        // "warningLow":null,
        // "planName":"412412",
        // "planId":200124067,
        // "showStatus":0,
        // "status2":0}],
        tableData:[],
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dataList:'',
        userId:'',//用户ID
        userName:'',//用户名
        deptId:'',//
        params:{},//入参
        userContent:{},//登录用户信息&弹框默认
        yulanList:[],//批量日限额预览

        disabledIs: false,//switch是否可操作
        warmType:false,//判断是否设置过预警消息推送
        url:['/account/v1/checkAccountWarning',
            '/adGroup/v1/checkGroupModifyPriceSet'],//预警是否开启、是否设置过定时调价计划
        msgContent:'',//确认添加新计划
        msgContent1:'',//确认投放开关关闭/计划状态关闭
        limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
        itemValue:'',
        startSwitch:'',
        ssDetailParams:[],
        typeName:'',
        adPlanIdList:[],
        dayLimitIs:false,
        closePlanList:'',//校验是否有存在的启停计划
        // closePlanList:[],//校验是否有存在的启停计划
        myHeaders: {Authorization:''},
        fileList:{},
        command:'',
        dialogCopyPlan:false,
        ruleFormCopy:{
          copyAdplan:'',//广告计划
          copyAdGroup:[],//广告组
          copyAdadvert:[],//广告
          content:'',
          copyNum:''//复制数量
        },
        rulesCopy:{
          copyNum:[
          { required: true, message: '请输入1～10之间的数字', trigger: 'blur' },
          { min: 1, max: 10, message: '数值需在1-10之间', trigger: 'blur' }
          ]
          // name: [
          //   { required: true, message: '请输入活动名称', trigger: 'blur' },
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          // ],
          // region: [
          //   { required: true, message: '请选择活动区域', trigger: 'change' }
          // ],
        },
        formLabelWidth:'120px',
        planVal:[],
        adGroupIdString:'',
        adGroupIds:[],//广告组IDs
        advertIds:[],//广告IDs
        copyPlanId:'',
        //穿梭框
        groupData: [],//广告组
        groupDataDetail:[],//广告组
        advertData: [],//广告
        advertDataDetail: [],//广告
        copyParams:{//确定复制计划入参
          deptId:'',
          userId:'',
          userName:'',
          ownerId:'',
          ownerName:'',
          planId:'',
          planName:'',
          copyNumber:'',
          copyGroupDtoList:[
            // {
            //   copyAdTwoDtoList:[]
            // }
            // {
            //   copyAdTwoDtoList:[{
            //     adId:'',
            //     adName:''
            //   }],
            //   groupId:'',
            //   groupName:''
            // }
          ]
        },
        renderFunc(h, option) {
          return <span>{ option.label }</span>;
        },
        copyAdTwoDtoList:[]

      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.params.userId  = this.userId
      this.userContent.userId = this.userId
      this.params.userName  = this.userName
      this.userContent.userName = this.userName
      this.params.deptId = this.deptId
      this.userContent.deptId = this.deptId
      // this.params.pageCount = this.pageCount
      // this.params.page = this.page

      this.params.ownerId = Number(this.$route.query.id)
      // this.userContent.ownerId = Number(this.$route.params.id)
      this.userContent.ownerId = Number(this.$route.query.id)
      this.userContent.ownerName = this.$route.query.name
      console.log(this.titleList)
      // this.getAccountPlanListFn(this.page)
      // this.getId()
      // this.fileList={
      //   "ownerId":this.params.ownerId,
      //   "operaType":10,
      //   "userId":this.userId,
      //   "userName":this.userName,
      //   "deptId":this.deptId
      // }
     
    },
    
    methods: {
      getId(){
        this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
        this.uploadId = this.$route.params && this.$route.params.id
        console.log(this.uploadId)
      },
      zdyFn(){
        this.$refs.zdy.generateTimes(10)
      },
      exportPlan(){
        this.exprtVisible = true
      },
      //文件上传成功时的钩子
      onSuccess(response, file, fileList, i) {
        let that = this,dataSource =[],keywords = []
        // let video = []
        console.log(response)
        if(response.code==200){
          that.getAccountPlanListFn()
        
        }else{
          that.$message({
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }
        this.$refs.upload.clearFiles()
      },
      //下载报表
      downLoadTable(){
        let _this = this,params ={},date = new Date().getTime(),myDate = new Date()
        let Time2 = myDate.toISOString().slice(0,10)//推荐 yyyy-MM-dd
        //延伸  取yyyyMMdd
        let Time3 = Time2.replace(/[^0-9]/ig,'').substring(0,8)
        params = {'ownerId':_this.params.ownerId,
                  'deptId':_this.deptId,
                  'userId':_this.userId,
                  'userName':_this.userName,
                'beginTime':_this.value1[0]||Time3,
              'endTime':_this.value1[1]||Time3},
            
        _this.uploadingShow = true
        $http.axios.post("/api/advertisement/adPlan/v1/v3export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
            _this.exprtVisible = false 
            //添加{responseType: 'blob'}  导出文件以下代码都是
            let blob = response.data
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = e => {
              let a = document.createElement('a')
              a.download = "计划报表"+myDate+".xls"
              a.href = e.target.result
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              _this.uploadingShow = false
            }
        
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
      },
      //弱提示
      alertMsgFn(msg,type){
        Vue.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
       // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
       clickcell(row, column, cell, event){
        let yulanList = []
        console.log(row);
        console.log(column);
        console.log(cell);
        console.log(event.target);
        console.log(event.target.parentNode.dataset.type)
        let yjType = event.target.parentNode.dataset.type
        if(yjType == 'yjBtn'){
          this.adPlanIdList = [row.planId]
          yulanList.push({'planName':row.planName,'planId':row.planId,'price':row.dayBudget})
          // this.userContent.adPlanName = row.planName
          // this.userContent.adPlanId = row.planId
          // this.userContent.dayBudget = row.dayBudget

          this.yjCheck('yjBtn',this.url[0],row.planId,row.planName,row.dayBudget)  
        }else if(yjType == 'qtBtn'){
          this.goPlan('/plan-management/SetPricess',{
            planId:row.planId,
            planName:row.planName,
          })
        }
        if(column.property=="planName"){
          this.goPlan('/plan-management/advertisingPlan')
          this.newVue.handleClick({'index':'1'},row.planId)
        }
        if(column.property == "dayBudget"){
          this.adPlanIdList = [row.planId]

          this.yjCheck('dayLimit',this.url[1],row.planId,row.planName,'dayBudget')  
          yulanList.push({'planName':row.planName,'planId':row.planId,'dayBudget':row.dayBudget})
          // this.userContent.adPlanName = row.planName
          // this.userContent.adPlanId = row.planId

        }
        if(column.type=="selection"){
            this.$refs.multipleTable.toggleRowSelection(row)
        }
        if(column.property=="startSwitch"){
          let params = {"planId": row.planId}
          this.ssDetailParams = {}
          if(row.startSwitch == 0){
            this.ssDetailParams={currentPage:1,
                                      deptId:this.deptId,
                                      etime:"",
                                      pageSize:50,
                                      status:"",
                                      stime:"",
                                      openClosePlanId:"",
                                      userId:this.userId,
                                      userName:this.userName}
            this.getOpenCloseIdFn(params)
          }
        }
        this.yulanList = yulanList
      }, 
       // 分页
      handleSizeChange(val) {
        let _this = this
        console.log(666)
        _this.pageCount = val
        // _this.$set(_this.params,'page',val)
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        // var params = {
        //   page: this.page,
        //   pageCount: this.pageCount
        // }
        this.findAll(val)
      },
      //分页方法
      findAll (page) {
        //获取数据接口（调用封装的接口）
        this.page = page
        this.getAccountPlanListFn(page,this.planName,this.planId,this.channel,this.channeln,this.value1)
      },
      //选择行
      handleSelectionChange(val){
        console.log(val)
        let _this = this
        let  yulanList = [],objList = [],startSwitch = [],numStr =0 ,num = 0
        _this.limitIs = false
        _this.adPlanIdList = []
        val.forEach((obj,i,arr)=>{
          let cur = obj.status2
          _this.adPlanIdList.push(obj.planId)
          startSwitch.push(obj.startSwitch)
          if(obj.dayBudget == '不限'){
            _this.limitIs = true
          }
          if(obj.dayBudget =='不限'){
            numStr++
            // break // 语法报错
            // throw error // 主动去抛出一个错误
          }else{
            num++
          }
          yulanList.push({ 'adGroupName':obj.adGroupName,'adGroupId':obj.adGroupId,'planName':obj.planName,'planId':obj.planId,'upPriceStatus':0,'price':obj.dayBudget})//upPriceStatus:调价状态;status2:计划状态
          objList[cur] = cur
        })
        if(val.length == num || val.length ==numStr){
          _this.dayLimitIs = true
        }else{
          _this.dayLimitIs = false
          _this.$message({
            showClose: true,
            message: "选择的计划日限额不一致，选择同一种计划日限额后才可操作",
            type: 'error'
          })
        }
        objList.forEach((obj,i)=>{
          if(objList.length == 2&&objList[0]!=undefined){
          _this.planStatus = false
          _this.warningOpenList = []
          _this.$message({
              showClose: true,
              message: "选择的计划状态不一致，选择同一种计划状态后才可操作",
              type: 'error'
            })        
          }else{
            _this.planStatus = true
          }        
        })
        _this.startSwitch = startSwitch
        _this.yulanList = yulanList
        if(val.length == 0){
          _this.warningOpenList = []
          _this.planStatus = false
        }

        //只能选择一行
        _this.planVal = val

      },
     
      // 批量日限额取消limitIs为false

      // 批量启停设置
      updateOppoSet(type){
        let _this = this
        let planIdList = []//声明一个空数组
        
        _this.adPlanIdList.forEach(function(v,i,arr){
          let bool = arr.indexOf(v,i+1)
          if(bool === -1){
            planIdList.push(v)
          }
        })
        if(_this.adPlanIdList.length!=0){
          _this.params.adPlanIdList = _this.adPlanIdList
          _this.btnIsFn(_this.params,type)
          return true
        }else{
          _this.$message({
              showClose: true,
              message: "请勾选数据",
              type: 'error'
            })
          }
      },
      // 按钮判断
      btnIsFn(params,type){
        console.log(params)
        let _this = this
        if(type == 'startStop'){
          _this.timeDialog = true

        }else if(type == 'planStatus'&&_this.planStatus){
            // _this.changeStatus()
            _this.checkOpenClosePlanFn(params.adPlanIdList)//需求更改暂时注释
            _this.warningOpenList = ['开启','关闭']
        }else if(type == 'dayLimt'){

        }

      },
      changeStatus(value,scope) {
        console.log(scope.row.status2)
        //先把值赋为原来的值，保证swich状态不变
        this.adPlanIdList=[scope.row.planId]
        let _this = this
        this.tableData[scope.$index].status2 = value == 0?1:0//需求更改暂时注释
        $http.axios.post("/api/advertisement/adPlan/v1/checkOpenClosePlan",this.adPlanIdList).then(res=> {
          if(res.data.code==200){
           if(res.data.content!= ''){
              _this.planName = res.data.content
              _this.command = value
              _this.alertStartStopDialogIs = true   
           }else{
            this.tableData[scope.$index].status2 = value
            this.updateOppoAdPlanStatusFn(scope.row.status2)//需求更改暂时打开
           }

          }
        }).catch(error=>{
            let messageContent =error.message || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })        
          })    
      },
      
      checkOpenClosePlanFn(params){
        console.log(params)
      let _this = this
      $http.axios.post("/api/advertisement/adPlan/v1/checkOpenClosePlan",params).then(res=> {
        if(res.data.code==200){
          if(res.data.content!= ''){
            // res.data.content.forEach(obj=>{
            //   _this.closePlanList.push(obj)
            // })
            _this.closePlanList = res.data.content
          }else{
          // _this.closePlanList = []
            _this.closePlanList = ''
          }
        }
      }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },

      changeStatusList(command){
        console.log(command)
        // let _this = this,planName = [],text = ''
          // _this.closePlanList.forEach((obj,i)=>{
          //     planName.push(obj)
          // })
          let _this = this,planName = '',text = ''
          planName = _this.closePlanList
          // if(planName.length>0){
            if(planName!=''){
              // _this.planName = planName.join('、')
              _this.planName = _this.closePlanList
            _this.command = command
            _this.alertStartStopDialogIs = true     
          }else{
            this.updateOppoAdPlanStatusFn(command)
          }
      },      
      // 自适应表格列宽
      flexColumnWidth(str, tableData, flag = 'max') {
          // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
          // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
          // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
          let columnContent = ''
          if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
            return
          }
          if (!str || !str.length || str.length === 0 || str === undefined) {
            return
          }
          if (flag === 'equal') {
            // 获取该列中第一个不为空的数据(内容)
            for (let i = 0; i < tableData.length; i++) {
              if (tableData[i][str].length > 0) {
                columnContent = tableData[i][str]
                break
              }
            }
          } else {
            // 获取该列中最长的数据(内容)
            let index = 0
            for (let i = 0; i < tableData.length; i++) {
              if (tableData[i][str] === null) {
                return
              }
              const now_temp = tableData[i][str]
              const max_temp = tableData[index][str]
              if (now_temp.length >= max_temp.length) {
                index = i
              }
            }
            columnContent = tableData[index][str].toString()
          }
          // 以下分配的单位长度可根据实际需求进行调整
          let flexWidth = 0
          for (const char of columnContent) {
            if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
              // 如果是英文字符，为字符分配8个单位宽度
              flexWidth += 8
            } else if (char >= '\u4e00' && char <= '\u9fa5') {
              // 如果是中文字符，为字符分配18个单位宽度
              flexWidth += 18
            } else {
              // 其他种类字符，为字符分配15个单位宽度
              flexWidth += 15
            }
          }
          if (flexWidth < 120) {
            // 设置最小宽度
            flexWidth = 120
          }
          return flexWidth
        },
    
      creatPlan(url,type){
        // this.$router.push("/plan-management/"+routerTo)
        this.$router.push({
        path: url,
        query: {
          ownerId: this.userContent.ownerId,
          ownerName:this.userContent.ownerName,
          type:type
        }
      })
      },
      
      //重置
      resetForm(){
        let _this = this
        _this.planId = ''
        _this.planName = ''
        _this.channel = ''
        _this.channeln = ''
        _this.value1 = ''
        _this.getAccountPlanListFn()
      },

      //计划列表
      getAccountPlanListFn(page,planName,palnId,channel,channeln,value1){
         //获取查询有权限的所有帐号接口
        let _this = this,params,pagetTxt
        pagetTxt = {'pageCount' : _this.pageCount,
                'page' : page || 1,
                'planNameLike':planName,
                'planids':palnId,
                'extensionTypes':channel,
                'showStatusSet':channeln}
        if(value1 != undefined&&value1 != ''){
          pagetTxt.beginTime = value1[0]
          pagetTxt.endTime = value1[1]
        }
        
        params = Object.assign({},_this.clone(_this.userContent), pagetTxt)//之前是params，因为里面有groupId所以换成了userContent

        
        $http.axios.post("/api/advertisement/adPlan/v1/getAccountPlanList",params).then(res=> {
          _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
          if(res.data.code==200){
            if(res.data.content.items != null){
              // 数据修改，余额、消耗、日预算初始值如果为空的状态
              res.data.content.items.forEach(element => {
                if(element.warningLow == null){
                  element.dayWarning = "--"
                }else{
                  element.dayWarning = Number(element.warningLow)/100+'<'+Number(element.warningTop)/100
                }
              if(element.status2 == null){
                element.status2 = 1
              }
              if(element.startSwitch == null){
                element.startSwitch = 1
              }
              if(element.warningSwitch == null){
                element.warningSwitch = 2
              }
          });
            _this.tableData = res.data.content.items
            console.log(_this.tableData)
            _this.totalRow = res.data.content.totalCount
            }else{
              _this.tableData = []
            _this.totalRow = 0
            }
            _this.planId = ''
            _this.planName = ''
            _this.channel = ''
            _this.channeln = ''
            _this.value1 = ''
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })    
          }

        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })

      },
      //批量启停
      // updateOppoAdGroupStatusFn(params){
      //   let _this = this,type
      //   $http.axios.post("/api/advertisement/adGroup/v1/updateOppoAdGroupStatus",params).then(res=> {
      //     _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
      //     _this.adPlanIdList = []
      //     _this.$refs.multipleTable.clearSelection();          
      //     if(res.data.code==200){
      //       type = "success"
      //       _this.adPlanIdList = []
      //       _this.$refs.multipleTable.clearSelection();          
      //       _this.getAccountPlanListFn()
      //     }else{
      //       type = "error"
      //     }
      //       let messageContent =res.data.msg || '请查看网络，稍后再试'
      //       _this.$message({
      //         showClose: true,
      //         message: messageContent,
      //         type: type
      //       })  
      //   }).catch(error=>{
      //     let messageContent =error.message || '请查看网络，稍后再试'
      //     _this.$message({
      //       showClose: true,
      //       message: messageContent,
      //       type: 'error'
      //     })        
      //   })
      // },
      
      //预警开关
      upAdPlanWarningSwitchFn(command){
        let _this = this,type,params,params1

        params1={'adPlanIdList':_this.adPlanIdList,
          'warningSwitch' : command,}
        params = Object.assign({},_this.clone(_this.userContent), params1)//之前是params，因为里面有groupId所以换成了userContent

        $http.axios.post("/api/advertisement/adPlan/v1/upAdPlanWarningSwitch",params).then(res=> {
          _this.warningOpenList = []
          _this.planStatus = false
          if(res.data.code==200){
            _this.adPlanIdList = []
            _this.$refs.multipleTable.clearSelection();          
            _this.getAccountPlanListFn(this.page)
            type = "success"

          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //计划状态设置
      updateOppoAdPlanStatusFn(command,status){
        let _this = this,type,params,params1

        params1={'planIds':_this.adPlanIdList,
          'status2' : command.toString()||_this.command.toString(),
        'status':status||0}
        params = Object.assign({},_this.clone(_this.userContent), params1)//之前是params，因为里面有groupId所以换成了userContent
        $http.axios.post("/api/advertisement/adPlan/v1/updateOppoAdPlanStatus",params).then(res=> {
          _this.warningOpenList = []
          _this.planStatus = false
          _this.$refs.multipleTable.clearSelection();          
          _this.adPlanIdList = []
          if(res.data.code==200){
            _this.getAccountPlanListFn(_this.page)
            type = "success"
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      
      
      // /adPlan/v1/deletePlan删除
      deletePlanFn(planId){
        {
          // "accountId": 0,
          // "deptId": 0,
          // "planId": 0,
          // "userId": 0,
          // "userName": ""
}
        let _this = this,params = {},type = ''
        params = {"accountId": _this.userContent.ownerId,
          "deptId": _this.userContent.deptId,
          "planId": planId,
          "userId": _this.userContent.userId,
          "userName": _this.userContent.userName}
        $http.axios.post("/api/advertisement/adPlan/v1/deletePlan",params).then(res=> {
          if(res.data.code==200){
            type = "success"
            _this.getAccountPlanListFn(this.page)
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
      warmIs(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
        // /adPlan/v1/checkPlanDailyMoneySet
        let _this = this
        let isTrue = _this.updateOppoSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        if(isTrue){
            _this.yjCheck(type,url)     
        }
      },
      yjCheck(type,url,adPlanId,adPlanName,dayBudget){
        let _this = this,messageContent,params = null,idList = []
        if(type == 'warningOpen' || type =='yjBtn'){
          params = []
          params = [_this.userContent.ownerId]
        }else if(type == 'dayLimit'){
          if( !_this.dayLimitIs){
            return false
          }
            _this.adPlanIdList.forEach(obj=>{
            idList.push(obj)
          }) 
          params = {}
          params = {"idList":idList,"planType":_this.planType}      
      }
      $http.axios.post("/api/advertisement"+url,params).then(res=> {
          let content = []
          if(res.data.code==200){
            content = res.data.content
            if(content == null){
              if(type == 'dayLimit'){
                if(dayBudget == 'dayBudget'){
                  this.showSingleDialog = true
                }else{
                  _this.showDialog = true
                }

              }else{

                messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
              
                _this.$message({
                  showClose: true,
                  message: messageContent,
                  type: 'error'
                })
              }
                
              }else{
                if(type == 'dayLimit'){

                  let text = content.toString()+'计划已经设置过日限额执行计划是否确认添加新计划'
                  this.$confirm(text,"提示",{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                  }).then(()=>{
                    _this.showDialog = true
                    _this.nowIs = true
                  }).catch(()=>{
                    
                  })

                  // _this.msgContent =  content.toString()+'计划已经设置过日限额执行计划是否确认添加新计划'
                }else if(type == 'warningOpen'){
                  _this.warningOpenList = ['开启','关闭']
                }else if(type == 'yjBtn'){
                  _this.showEarlyWarningSetDialog = true
                  // _this.$refs.earlyWarningDialog.setDialogContentFn(_this.userContent.ownerId,setType)
                  // _this.$refs.earlyWarningDialog.setDialogContentFn(_this.userContent.ownerId,adPlanId,adPlanName,dayBudgetPrice)
                  _this.$refs.earlyWarningDialog.setDialogContentFn(_this.userContent.ownerId,adPlanId,adPlanName)
                }
              }
            }else{
                _this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
                })
            }
          }).catch(error=>{
            let messageContent = error.message || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })        
          }) 
      },
      // 启停页面跳转
      goPlan(url,query){//
        let _this = this,query1 =  {
            id: this.userContent.ownerId,
            name:this.userContent.ownerName}
        query = Object.assign({},_this.clone(query), query1)
        this.$router.push({
            path: url,
            query: query
          })
        // this.$router.push("/plan-management/"+urlList[num]+"/"+id+"/"+name)
      },
      //???
      getOpenCloseIdFn(params){
        let _this = this, type = ''
        $http.axios.post("/api/advertisement/adPlan/v1/getOpenCloseId",params).then(res=> {
          if(res.data.code==200&res.data.content!=null){
            _this.startStopDialog = true
            _this.ssDetailParams.openClosePlanId=res.data.content
            _this.$refs.startStopDialogIs.timeSlotDetail(_this.ssDetailParams)
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
      // /adPlan/v1/v3export下载报表
      v3exportFn(){
        let _this = this, type = ''
        $http.axios.post("/api/advertisement/adPlan/v1/v3export",params).then(res=> {
          if(res.data.code==200&res.data.content!=null){
            type = "success"
            
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
      setRowStyle(row, column, rowIndex, columnIndex) {
        if(row.columnIndex == 1){
          return 'color: #2F674D' 
        }else{
            return 'color: #606266' 
        }
      },

      //复制计划
      copyPlanBtn(){
        if(this.planVal.length == 1){
          this.dialogCopyPlan = true
        }else{
          this.$message({
            showClose: true,
            message: "请选择一条数据进行复制",
            type: 'error'
          })  
          this.dialogCopyPlan = false
        }
        let _this = this
        _this.planVal.forEach(function(val,i){
          _this.ruleFormCopy.copyAdplan = val.planName
          _this.copyPlanId = val.planId
        })
        _this.grouplistFn()
        _this.advertData = []

      },

      //穿梭框
      handleChange(value, direction, movedKeys) {
        // console.log(value, direction, movedKeys);
      },

      //广告组
      adGroupData() {
        const data = this.groupData
        this.groupDataDetail= []
        data.forEach((obj,i)=>{
          this.groupDataDetail.push({
            key:obj.adGroupId,
            label:obj.adGroupName,
          })
        })
        // console.log(this.groupDataDetail)
      },
      //广告
      advertsData() {
        const data = this.advertData
        this.advertDataDetail = []
        data.forEach((obj,i)=>{
          this.advertDataDetail.push({
            key:obj.adId,
            label:obj.adName,
          })
        })
        // console.log(this.advertDataDetail)
      },

      //广告组列表
      grouplistFn(){
        let _this = this,params,pagetTxt,adGroupId = []
        pagetTxt = {
                'pageCount' : _this.pageCount|| 50,
                'page' : _this.page || 1,
                'deptId':_this.deptId,
                'userId':_this.userId,
                'userName':_this.userName,
                'planIds':_this.copyPlanId//广告计划Id
              }
        params = Object.assign({},_this.clone(_this.params), pagetTxt)      
        $http.axios.post("/api/advertisement/adGroup/v1/grouplist",params).then(res=> {
          if(res.data.code==200){
            _this.groupData = res.data.content.items
            _this.adGroupIds = []
            res.data.content.items.forEach(function(val,i){
              adGroupId.push(val.adGroupId)
              _this.adGroupIds.push(val.adGroupId)
            })
            _this.adGroupData()
            
          }else{
            _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.alertMsgFn(messageContent,"error")
        })
      },

      // 广告列表
      v3groupListFn(){
        let _this = this,params,pagetTxt
        pagetTxt = {
                'pageCount' : _this.pageCount|| 50,
                'page' : _this.page || 1,
                'deptId':_this.deptId,
                'userId':_this.userId,
                'userName':_this.userName,
                'adGroupIds':_this.adGroupIdString//广告组Id
              }  
        params = Object.assign({},_this.clone(_this.params), pagetTxt)  
        $http.axios.post("/api/advertisement/ad/v1/v3groupList",params).then(res=> {
          if(res.data.code==200){
            _this.advertData = res.data.content.items
            // console.log(_this.advertData) 
            _this.advertIds = []
            res.data.content.items.forEach(function(val,i){
              _this.advertIds.push(val.adId)
            })
            _this.advertsData()
          }else{
            _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.alertMsgFn(messageContent,"error")
        })
      },

      //取消复制弹框
      cancleCopyPlan(){
        this.ruleFormCopy.copyAdGroup = []
        this.ruleFormCopy.copyAdadvert = []
        this.ruleFormCopy.copyNum = ''
        this.advertDataDetail = []
        this.dialogCopyPlan = false
      },

      //change广告组
      handleChangeGroup(value){
        this.adGroupIdString = value.toString()
        this.v3groupListFn()
        let _this = this
        _this.copyParams.copyGroupDtoList = []
        _this.groupDataDetail.forEach(function(val,i){
          value.forEach(function(obj,index){
            if(obj == val.key){
              _this.copyParams.copyGroupDtoList.push({'groupId':val.key,'groupName':val.label,'copyAdTwoDtoList':[]})
            }
          })
        })
      },

      elimination (arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.key) && res.set(arr.key));
      },

      //change广告
      handleChangeAdvert(value){
        let _this = this,copyAdTwoDtoList = []
        _this.advertDataDetail.forEach(function(val,i){
          value.forEach(function(obj,index){
            if(obj == val.key){
              _this.copyParams.copyGroupDtoList.forEach(function(v,item){
                // copyAdTwoDtoList.push({'adId':val.key,'adName':val.label})
                // v.copyAdTwoDtoList = _this.elimination(copyAdTwoDtoList)
                v.copyAdTwoDtoList.push({'adId':val.key,'adName':val.label})
              })
            }
          })
        })
      },

      //确定复制计划
      sureCopyPlan(ruleFormCopy){
        if (this.ruleFormCopy.copyAdGroup.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择广告组 '
          });
          return false;
        }
        if (this.ruleFormCopy.copyAdadvert.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择广告 '
          });
          return false;
        }
        this.$refs[ruleFormCopy].validate((valid) => {
          if (valid) {
            let _this = this,params = {} 
            _this.copyParams.deptId = _this.deptId,
            _this.copyParams.userId = _this.userId,
            _this.copyParams.userName = _this.userName,
            _this.copyParams.copyNumber = _this.ruleFormCopy.copyNum,
            _this.copyParams.ownerId = _this.userContent.ownerId,
            _this.copyParams.ownerName = _this.userContent.ownerName,
            _this.copyParams.planId = _this.copyPlanId,
            _this.copyParams.planName = _this.ruleFormCopy.copyAdplan,
            console.log(_this.copyParams.copyGroupDtoList)
            $http.axios.post("/api/advertisement/copy/plan",_this.copyParams).then(res=> {
              if(res.data.code==200){
                _this.getAccountPlanListFn()
                _this.dialogCopyPlan = false
                _this.advertData = []
                _this.advertDataDetail = []
                // _this.ruleFormCopy.copyAdadvert = []
                _this.cancleCopyPlan()
              }else{
                _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
              }
            }).catch(error=>{
              let messageContent =error.message || '请查看网络，稍后再试'
              _this.alertMsgFn(messageContent,"error")
            })
          } 
        })
      },

      //复制计划记录跳转
      copyData(){
        this.$router.push({name:'planManagement.CopyPage',query:{ownerId:Number(this.$route.query.id)}})
      },

      addTitle(e) {
        // console.log(e)
        const target = e.target
        if (target.title) return
        target.title = target.innerText
      },


    }
  }
  </script>
  
  <style lang="scss" scoped>
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */

     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
        margin-right: 15px;
        font-size: 13px;
        color: #606266;
      }
      .allOperation .totalNum i{
          font-style: initial;
        }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */

    /* 操作按钮样式开始 */
    /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
      display: inline-block;
      margin: 0 10px;
    } */
    /* 操作按钮样式结束 */
    .MBOTTOM{
      margin-bottom:20px;
    }
    .el-button--small{
      margin-left: 10px;
    }
    .line.el-col.el-col-2{
      text-align: center;
    }
    
    .isSwitch{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background-color: #ccc;
      vertical-align:middle;
      margin-left: 5px;
    }
    .active{
      background-color: #13ce66;
    }
    #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .el-table .el-table__row {
    cursor: pointer!important;
  }
  .copyInput{
    width:120px;
    margin:0 10px;
  }
  ::v-deep .copyInput .el-input__inner{
    border-radius: 30px;
  }
  .copyPlanLength{
    width:300px;
  }
  ::v-deep .required .el-form-item__label:before{
    content: '*';
    color: #FF4949;
    margin-right: 4px;
  }
  ::v-deep .transferBox .el-transfer-panel{
    width: 260px !important;;
  }
  /* 新版 */
  .newcommon-page{
    padding:0!important;
  }
  .newcommon-page .el-card.is-always-shadow{
    box-shadow: none!important;
    border:none!important;
  }
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .copyPlanDialog .el-dialog__body{
    margin:0!important;
    padding:0!important;
  }
  .footerLast{
    margin-bottom:20px;
  }
  </style>
