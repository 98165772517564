<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>计划管理</el-breadcrumb-item>
          <el-breadcrumb-item>OPPO</el-breadcrumb-item>
          <el-breadcrumb-item>渠道</el-breadcrumb-item>
          <el-breadcrumb-item>启停展示</el-breadcrumb-item>
          <el-breadcrumb-item>创建启停</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="setPrice">
                <el-form :model="ruleForm" :rules="rules"  label-width="150px" class="demo-ruleForm">
                    <span class="baseTitle">基础信息</span>
                    <el-form-item label="账户ID" prop="customerName">
                        <el-input v-model="ruleForm.customerName" :disabled="true" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="计划名称" prop="planName">
                        <el-input v-model="ruleForm.planName" :disabled="true" placeholder=""></el-input>
                    </el-form-item>
                    <span class="baseTitle">设置启停</span>
                    <el-form-item label="计划启停设置">
                        <el-form-item label="" prop="dateChecked">
                            <el-checkbox v-model="ruleForm.dateChecked" @change="dateSet">设置执行日期</el-checkbox>
                            <div class="checkedTime" v-if="setDatevalueShow">
                              <el-date-picker
                                v-model="setDatevalue"
                                type="daterange"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                :start-placeholder="start"
                                :end-placeholder="end">
                            </el-date-picker>
                            </div>
                        </el-form-item>
                        <el-form-item label="" prop="timeChecked">
                            <el-checkbox v-model="ruleForm.timeChecked" @change="setCarryTime">设置执行时间</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                          <timeUnit ref="timeStrArray" :rowUnit1="rowUnit1" v-if="timeUnitShow"></timeUnit>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                        <el-button type="info" size="small" plain >取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 计划启停的设置执行日期 -->
            <!-- <el-dialog title="执行日期" :visible.sync="setDateVisible">
                <el-date-picker
                    v-model="setDatevalue"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="setDateCommit">确 定</el-button>
                    <el-button @click="setDateCancel">取 消</el-button>
                </div>
            </el-dialog> -->
            <!-- 设置执行时间 -->
            <el-dialog title="执行日期" :visible.sync="setTimeVisible">
                
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="setTimeCommit">确 定</el-button>
                    <el-button @click="setTimeCancel">取 消</el-button>
                </div>
            </el-dialog>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import TimeUnit from '../../TimeUnit.vue';

  export default {
    name: 'SetPrice',
    components:{
      timeUnit:TimeUnit
    },
    data() {
      return {
        userId:'',
        userName:'',
        deptId:'',
        ruleForm: {
          customerName:'',
          planName:'',
            dateChecked:'',//设置执行日期
            timeChecked:'',//设置执行时间
        },
        rules: {
            dateChecked:[
                { required: true, message: '请设置执行日期', trigger: 'change' }
            ]
        },
        radioOptions:[{//单选
            label:'设置执行日期',
            value:'1'
        },{
            label:'立即执行',
            value:'2'
        }],
        setDateVisible:false,//计划启停的设置执行日期
        setDatevalue:[],//计划启停的设置执行日期----日期选择
        setDatevalueString:'',
        setDatevalueShow:false,//计划启停的设置执行日期----日期选择展示
        setTimeVisible:false,//计划执行设置执行日期
        setTimevalue:'',//计划执行设置执行日期----日期选择
        setTimevalueShow:false,//计划执行设置执行日期----日期选择展示
        setTimevalueString:'',
        editId:'',//上一级获取的详情列表id
        listId:'',//上一级获取的列表id
        returnParams:{//回显入参
          userId:'',
          userName:'',
          deptId:'',
          openClosePlanId:'',//列表的id
          timeId:''//详情的id
        },
        editPriceData:{},//回显数据
        carryOutDate:[],//回显数据中的dateList
        start:'',
        end:'',
        timeUnitShow:true,//执行时间表
        timeWeek:[],//返回的执行时间
        rowUnit1:[],
        timeContent:[],
        timeSection:[],
        timeStr:[],
        beginDay:0,
  		  beginTime:0,


        
      }
    },
    computed: {
       
    },
    mounted() {
      console.log(this.$refs)
      console.log(this.$refs.timeStrArray)
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.getId()//上一级带过来的参数方法
      this.returnShow()//回显接口
    },
    methods: {
      submitForm(ruleForm) {
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      //计划启停设置---设置执行日期
      dateSet(val){
        if(val==true){
            this.setDatevalueShow = true
            this.start = '开始时间'
            this.end = '结束时间'
        }else{
            this.setDatevalueShow = false
            this.setDatevalue = []
        }
      },

      //计划启停设置---设置执行日期--取消
      setDateCancel(){
        this.setDateVisible = false
        this.ruleForm.dateChecked = false
      },
      //计划启停设置---设置执行日期--确定
      setDateCommit(){
          if(this.setDatevalue == []){
            this.$message('请选择日期')
          }else{
            this.setDateVisible = false
            this.setDatevalueShow = true
            this.setDatevalueString = String(this.setDatevalue[0]+'至'+this.setDatevalue[1])
          } 
      },

      //计划执行设置--选择
      changeRadio(val){
        if(val == 1){
            this.setTimeVisible = true
            this.setTimevalue = ''
        }else{
            this.setTimevalueShow = false
        }
      },
      //计划执行设置---设置执行日期--取消
      setTimeCancel(){
        this.setTimeVisible = false
        this.ruleForm.radio = ''
      },
      //计划执行设置---设置执行日期--确定
      setTimeCommit(){
        if(this.setTimevalue == ''){
            this.$message('请选择日期')
        }else{
            this.setTimeVisible = false
            this.setTimevalueShow = true
            this.setTimevalueString = String(this.setTimevalue[0]+'至'+this.setTimevalue[1])
        }
      },

      //上一级获取参数
      getId(){
        this.editId = this.$route.query.id
        this.listId = this.$route.query.listId
      },

      //修改启停回显接口
      returnShow(){
        let that = this
        that.returnParams.userId = that.userId
        that.returnParams.userName = that.userName
        that.returnParams.deptId = that.deptId
        that.returnParams.openClosePlanId = that.listId
        that.returnParams.timeId = that.editId

        $http.axios.post("/api/advertisement/adPlan/v1/getOpenClosePlanDetail",that.returnParams).then(res=> {
          if(res.data.code == 200){
            that.editPriceData = res.data.content
            that.ruleForm.customerName = that.editPriceData.planId
            that.ruleForm.planName = that.editPriceData.planName
            that.carryOutDate = res.data.content.getOpenClosePlanDetailMesList
            that.timeWeek = []
            that.carryOutDate.forEach(function(e,i){
              if(e.startTime&&e.endTime != ''||null){
                that.ruleForm.dateChecked = true
                that.setDatevalueShow = true
                that.setDatevalue[0] = e.startTime
                that.setDatevalue[1] = e.endTime
                that.start = e.startTime
                that.end = e.endTime
              }
              that.timeWeek = e.getOpenClosePlanWeekDetailDtoList
              if(that.timeWeek.length != 0){
                that.timeUnitShow = true
                that.ruleForm.timeChecked = true
                // that.parseIntFn("11:00~12:30",1)
               
                that.timeWeek.forEach(function(val,index){
                  that.parseIntFn(val.executeHourt,val.week)
                  // for (let x = dayStart; x < dayEnd+1; x++) {
                  //   for (let y = start; y < end+1; y++) {
                  //       if(this.rowUnit[x][y].class == null) {
                  //         this.rowUnit[x][y].class = 'ui-selected'
                  //         this.timeContent[x].arr.push(this.rowUnit[x][y].timeData)
                  //       }
                  //     }
                  //   }
                  

                  // that.$refs.timeStrArray.handleMouseUp(index,val.week-1)
                  // this.rowUnit[x][y].class = 'ui-selected'
                  // this.timeContent[x].arr.push(this.rowUnit[x][y].timeData)
                  // that.$refs.timeStrArray.rowUnit.push({class:'ui-selected',timeData:val.week-1})
                  that.timeContent[val.week-1].arr.push(that.rowUnit1[index].timeData)
                  console.log(that.timeContent)
                  that.timeStr[val.week-1] = val.executeHourt
                  // console.log(that.$refs.timeStrArray.timeStr)

                })
                // console.log(that.$refs.timeStrArray)
              }
            })
            

            

          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      parseIntFn(executeHourt,weeks){
        let hour = executeHourt.split("~")
        let week = weeks-1
        let hourArr = []
        hour.forEach(function(e,i){
          if(e.split(':')[1]>0){
            hourArr.push(parseInt(e.split(':')[0])*2+1)
          }else{
            hourArr.push(parseInt(e.split(':')[0])*2)
          }
        })
        this.timeFn(hourArr,week)
      },

      timeFn(hourArr,week){
        let day = week-1
        let newHourArr = []
        for (let i = 0; i < 7; i++) {
          let arr = []
          for (let j = 0; j < 48; j++) {
            arr.push({class:null,timeData:j})         
          }
          this.rowUnit1.push(arr)
          this.timeContent.push({arr:[]})
          this.timeSection.push([])
          this.timeStr.push('')
        }
        this.rowUnit1.forEach(function(e,i){
          if(i>=hourArr[0]&&i<hourArr[1]){
            e.class = 'ui-selected'
            // //选时间段
            // let that = this
            // let iHour = i
            // let begin = 0
            // let start = begin <= iHour ? begin : iHour //x轴 起点
            // let length = Math.abs(begin - iHour)
            // let end = start + length           //x轴 终点
            // let dayStart = 0<= day ? 0 : day //y轴 起点
            // let dayLength = Math.abs(0 - day)
            // let dayEnd = dayStart + dayLength                         //y轴 终点
            // for (let x = dayStart; x < dayEnd+1; x++) {
            //   for (let y = start; y < end+1; y++) {
            //     if(e[x][y].class == null) {
            //       e[x][y].class = 'ui-selected'
            //       that.timeContent[x].arr.push(e[x][y].timeData)                
            //     }
            //   }
            // }
            newHourArr.push(i)
            // arr.push({class:null,timeData:j})
          }else{
            e.class = null
          }  
        })

        console.log(hourArr,week)
       
        let iHour
        newHourArr.forEach(function(val,index){
          console.log(val)
          let iHour = val
          let begin = 0
          let start = begin <= iHour ? begin : iHour //x轴 起点
          let length = Math.abs(begin - iHour)
          let end = start + length           //x轴 终点
        })
        

        
        // console.log(this.rowUnit1)
      },

      //执行时间
      setCarryTime(){
        let that = this
        if(that.ruleForm.timeChecked == true){
          that.timeUnitShow = true
          console.log(that.$refs)
        }else{
          that.timeUnitShow = false
        }
      },
      




    }
  }
  </script>
  
  <style lang="scss" scoped>
      .baseTitle{
          font-weight:700;
          display:block;
          font-size:14px;
          margin:10px 0 20px;
      }
      .setPrice .el-input{
          width:260px;
      }
      .errorTip{
          margin-left:150px;
      }
      .byted-weektime .calendar .calendar-table tr .ui-selected{
        background:#6e7c7c;
      }
      .ui-selected{
        background:#6e7c7c;
      }
  </style>
