<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
              <el-breadcrumb-item>广告</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" @click="createAdvert" style="float:right;">创建广告</el-button>
            <el-button type="primary" size="small" style="float:right;margin-right:10px;" @click="zdyFn">自定义列</el-button>
          </div>
        </div>
        <div class="chanelTab">
          <span class="channel_oppo" @click="clickChangeChannel(1)">广告系列</span>
          <span class="channel_vivo"  @click="clickChangeChannel(2)">广告组</span>
          <span class="channel_vivo" @click="clickChangeChannel(3)">关键词</span>
          <span class="channel_vivo channelActive">广告</span>
        </div>

          <div class="materialHead">
            <div class="materialHBtns clearfix queryLeft">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <el-date-picker
                    v-model="dateValue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="dateStyle"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "advertName" clearable placeholder="请输入广告名称" @blur="blurGroupName"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "advertId" 
                  clearable           
                  @blur="blurGroupId"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="请输入广告ID"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-select 
                    placeholder="请选择广告组"
                    v-model="adGroupChoose"
                    clearable
                    @change="changeAdGroupChoose"
                    >
                    <el-option
                      v-for="(item,i) in adGroupChooseOption"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-button class="newQuery" type="primary" size="small" @click = "advertListFn">查询</el-button>
                  <el-button type="info" size="small" plian @click="resetForm">重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
            <div class="listDetail queryRight">
              <span style="margin-right:10px;">
                计划系列名称：
                <el-select 
                  :placeholder="campaignName"
                  @change="changeAdplanLabel"
                  value-key="id"
                  v-model="adplanLabel">
                  <el-option
                    v-for="(item,i) in adplanModelOption"
                    :key="i"
                    :label="item.name"
                    :value="item">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{ item.id }}</span>
                  </el-option>
                </el-select>
              </span>
              <span>
                <span v-if="changeSupplySources == ''">
                  <i v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else-if="supplySources == 'APPSTORE_SEARCH_TAB'">投放位置：搜索标签</i>
                </span>
                <span v-else>
                  <i v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else-if="changeSupplySources == 'APPSTORE_SEARCH_TAB'">投放位置：搜索标签</i>
                </span>
              </span>
            </div>

          </div>
          <div class="clearfix">
              <div class="materiaContent" >
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  style=" cursor:pointer;"
                  tooltip-effect="dark"
                  @sort-change="handlesortChange">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="metadata.adName"
                    label="广告名称"
                    width="220"
                    fixed>
                  </el-table-column>
                  <el-table-column
                    prop="metadata.adDisplayStatus"
                    label="状态"
                    min-width="200px"
                    fixed>
                    <template slot-scope="scope" >
                      <el-switch
                        v-model="scope.row.metadata.adDisplayStatus"
                        active-color="#48916F"
                        inactive-color="#EAEFED"
                        active-value="ACTIVE"
                        inactive-value="INVALID||ON_HOLD||PAUSED||REMOVED"
                        @change="changeListStatus(scope.row)">
                        <!-- INVALID, ON_HOLD, PAUSED, REMOVED -->
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    :show-overflow-tooltip="true"
                    label="状态原因"
                    width="200">
                    <template slot-scope="scope" >
                      <span v-if="scope.row.metadata.adServingStateReasons == 'AD APPROVAL PENDING'"> 广告审批待定&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'AD APPROVAL REJECTED'"> 广告批准被拒绝&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'CREATIVE SET INVALID'"> 创意集无效&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'CREATIVE SET UNSUPPORTED'"> 创意集不受支持&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'DELETED BY USER'"> 被用户删除&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'PAUSED BY SYSTEM'"> 系统暂停&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'PAUSED BY USER'"> 由用户暂停&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'PRODUCT PAGE DELETED'"> 产品页面已删除&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'PRODUCT PAGE HIDDEN'"> 产品页面隐藏&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'PRODUCT PAGE INCOMPATIBLE'"> 产品页面不兼容&emsp;</span>
                      <span v-else-if="scope.row.metadata.adServingStateReasons == 'PRODUCT PAGE INSUFFICIENT ASSETS'"> 产品页面资产不足</span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                  <template v-for="(item, index) in titleList">
                    <el-table-column
                      :key="index"
                      :prop="item.prop"
                      :label="item.name"
                      width="170px"
                      sortable>
                    </el-table-column>
                  </template>
                  <el-table-column 
                    fixed = 'right'
                    align="center"
                    width='80'
                    :show-overflow-tooltip="true"
                    label="操作">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>
          <!-- 没有自定义页面时候的弹框 -->
          <el-dialog
            title=""
            :visible.sync="customDialog"
            width="400px"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            >
            <div class="dialogIcon"><img :src='noAdvert'></div>
            <div class="customWrap">
              <h3>没有可以使用的自定产品页面</h3>
              <p>要在默认广告的基础上创建自定广告，你需要在 App Store Connect 中设置自定产品页面。自定产品页面将决定你的广告素材，以及用户点击后将转到哪个页面。可将其用于确保广告素材与广告组主题或受众协调一致。</p>
            </div>
            <div class="adverOk">
              <el-button class="newDialog" type="primary" size="middle" @click="customDialog = false">确定</el-button>
            </div>
          </el-dialog>
          <!-- 正在载入 -->
          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <zdyApple :visible.sync="showzdyDialog" ref = 'zdyApple' :pubVue="pubVue"></zdyApple>
  </div>
</template>

<script>
import noAdvert from '@/assets/img/noAdvert.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import zdyApple from './zdyApple'

export default {
  components: {
    zdyApple
  },
  data() {
    return {
      noAdvert,
      pubVue: this,//空vue，用于子组件之间传值
      showzdyDialog:false,
      showDialog: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showSingleDialog:false,
      nowIs : false,
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      planName:'',//请输入计划名称查找
      nameId:'',//请输入计划ID查找
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      // 分页
      page: 0,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:'',
      planType:30,
      dialogTitle:'批量调价',
      userContent:{},//登录用户信息&弹框默认
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      // closeGroupList:[],
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      //苹果渠道广告组
      tableData:[],
      formLabelWidth:'150px',
      adSeries:'',
      adSeriesOption:[{
        label:'广告系列1',
        value:1
      },{
        label:'广告系列2',
        value:2
      }],
      adGroupApple:'',
      adGroupAppleOption:[{
        label:'广告组1',
        value:1
      },{
        label:'广告组2',
        value:2
      }],
      pickerOptions: {
        shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue:[],//搜索时间
      adplanStatu:'',//搜索状态
      adplanStatuOption:[{//状态选项
        value:1,
        label:'全部状态'
      },{
        value:2,
        label:'开启中'
      },{
        value:3,
        label:'暂停中'
      },{
        value:4,
        label:'挂起中'
      }],
      adplanUseStatu:'',//搜索启用状态
      adplanUseStatuOption:[{//启用状态选项
        value:1,
        label:'所有'
      },{
        value:2,
        label:'已启用'
      },{
        value:3,
        label:'已移除'
      }],
      adplanLabelOption:[{//启用状态选项
        value:1,
        label:'全部'
      }],
      dialogFormVisible:false,//预警预算弹窗
      radio: '1',
      priceRadio:'1',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      materialIdsPara:[],//选中的广告组
      ownerId:'',
      ownerName:'',
      //预警
      dialogFormVisible:false,//预警预算弹窗
      budgetRuleForm:{
        condition:{
          min:'',
          max:''
        },
        observeTarget:'1',//观察指标
        warningPerson:[],//预警人
        // 新
        clearHistory:'',
        newObserveTarget:[],//观察指标-新
        newSymbol:'',//执行条件中的符号，大于等于，小于等于
        newCondition:'',//执行条件中的num
        newOperator:'',//操作符
        warnNumTimes:'',//预警次数
        priority:'',//优先级
        warningTime:[new Date(0,0,0), new Date()],//操作时间
      },
      budgetRules:{},
      radio: '1',
      priceRadio:'1',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      warningPersonOption:[],//预警人的集合
      warnOwnerId:'',//预警的ownerId
      changeWarningPerson:'',//选中的预警人id
      changeWarningPersonName:'',//选中的预警人name
      dialogWarningDetail:false,//预警详情的弹框
      dialogEditVisible:false,//预警修改弹框
      warnInfosTableData:[],//预警详情表格
      warnDeptId:'',//修改预警时候的部门id
      warningId:'',//修改预警id
      row:[],
      dialogAccountUpdate:false,//账户更新弹框
      accountUpdateForm:{//账户更新弹框表单
        balance:'',//余额
        recharge:'',//最近充值
        status:'',//状态
        currency:'',//货币
      },
      accountUpdaterules:{//账户更新弹框表单-校验

      },
      currencyOption:[{//货币
        value:'RMB',
        label:'RMB'
      },{
        value:'AUD',
        label:'AUD'
      },{
        value:'CAD',
        label:'CAD'
      },{
        value:'EUR',
        label:'EUR'
      },{
        value:'GBP',
        label:'GBP'
      },{
        value:'JPY',
        label:'JPY'
      },{
        value:'MXN',
        label:'MXN'
      },{
        value:'NZD',
        label:'NZD'
      },{
        value:'RUB',
        label:'RUB'
      },{
        value:'USD',
        label:'USD'
      }],
      formLabelWidth1:'60px',
      appleOwnerIdUpdate:'',//更新的时候入参的ownerid
      appleStatus:'',//更新的时候入参的status
      // ownerId:'7774110',//广告系列组id
      // ownerName:'钢构宝',//广告系列组name
      // planId:'1430552493',//广告系列id
      // planName:'广告系列名称',//广告系列name
      groupId:'1430948813',//广告组id
      groupName:'广告组名称',//广告组name
      executeRate:'',//预警-执行比率
      executeDiff:'',//预警-执行增减差额
      exNum:'',
      exPercent:'',
      exRadio:'',
      exRadioto:'',
      ownerId:'',
      ownerName:'',
      campaignId:'',//广告系列id
      campaignName:'',//广告系列name
      localSpend:'',//默认
      dOrder:'',//默认排序
      listParams:{
        selector:{
          conditions:[],
          orderBy:[
          // {field:'localSpend',sortOrder:'ASCENDING'}
          ]
        }
      },//列表入参
      defaultBidAmountGroup:'',//默认最高出价
      cpaGoalGroup:'',//cpa出价
      adGroupName:'',//搜索广告组名称
      loadingDialogVisible:true,//正在载入的弹框
      supplySources:'',
      appId:'',
      budget:'',//广告系列的每日预算
      // totalBudget:'',//广告系列的总预算
      warnEigth:[],
      warnNine:[],
      adplanModelOption:[],//获取广告系列下拉列表
      adplanLabel:'',//搜索广告系列名称
      //预警
      newWarnStartTime:'',
      newWarnEndTime:'',
      warnParams:{//确定预警-入参
        infos:[{
          target:'',
          op:'',
          val:''
        }]
      },
      pricetofAll:true,//调日预算的all选框
      pricetofNumber:true,//调日预算--输入数值行
      pricetofPercent:true,//调日预算--输入百分比行
      executeValue:'',//change调日预算的值
      executeRate:'',//执行比率 下调10% => -0.1
      executeDiff:'',//执行增减差额 下调100 => -100
      percentDetail:'',
      numberDetail:'',
      newObserveTargetOption:[{//观察指标集合-新
        value:2,
        label:'消耗'
      },{
        value:3,
        label:'平均CPT'
      },{
        value:4,
        label:'平均CPA'
      },{
        value:5,
        label:'曝光'
      },{
        value:6,
        label:'点击'
      },{
        value:7,
        label:'安装'
      },{
        value:8,
        label:'点击率'
      },{
        value:9,
        label:'转化率'
      }],
      newSymbolOption:[{//执行条件中的符号
        value:1,
        label:'>='
      },{
        value:2,
        label:'<='
      }],
      newOperatorOption:[{//操作符
        value:1,
        label:'与'
      },{
        value:2,
        label:'或'
      }],
      adGroupId:'',
      adGroupName:'',
      changeSupplySources:'',//change计划系列名称改变投放位置
      advertName:'',//查询广告名称
      advertId:'',//查询广告id
      customDialog:false,//自定义弹框
      adGroupChoose:'',//搜索广告组
      adGroupChooseOption:[],//搜索广告组全集
      titleList:[],

    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    this.campaignId = this.$route.query.campaignId
    this.campaignName = this.$route.query.campaignName
    this.adGroupId = this.$route.query.adGroupId
    this.adGroupName = this.$route.query.adGroupName
    this.supplySources = this.$route.query.supplySources
    this.appId = this.$route.query.appId
    this.budget = this.$route.query.budget

    this.warnDeptId = 328//开发组的，苹果的是328
    this.accountUpdateForm.currency = 'RMB'
    this.adplanLabel = this.campaignName

    let nowTimes = Date.now();
    let oldTimes = Date.now();
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//今天的时间
    this.dateValue = [oldDate, nowDate];
    this.localSpend = 'localSpend'
    this.dOrder = 'DESCENDING'
    this.budgetRuleForm.newOperator = 1,//预警的操作符默认1-与
    this.budgetRuleForm.clearHistory = 1
    this.budgetRuleForm.warnNumTimes = 3
    this.budgetRuleForm.priority = 1
    this.advertListFn()
    this.getAdplanList()
    this.getAllGroup()

  },
  methods: {
    //tab切换
    clickChangeChannel(val){
      if(val == 1){
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName }})
      }else if(val == 2){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
      }else if(val == 3){
        console.log(this.adGroupId)
        if(this.adGroupId == undefined){
          if(this.changeSupplySources == ''){
            this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget}})
          }else{
            this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget}})
          }
        }else{
          if(this.changeSupplySources == ''){
            this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
              adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.supplySources,appId:this.appId,budget:this.budget}})
          }else{
            this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
              adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget}})
          }
        }
      }
    },

     //格式化时间 返回年月日 
     getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //保留两位小数
    fun(val){
　　  return Number(val).toFixed(2);
　　 },

    //率
    funny(val){
      let num = ''
      num = val*100
      return num.toFixed(2);   
　　 },

    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.advertListFn()
    },

    //重置
    resetForm(){
      this.$router.go(0)
    },

    //change搜索条件
    blurGroupName(){
      this.field = 'adName'
    },
    blurGroupId(){
      this.field = 'adId'
    },
    changeAdGroupChoose(val){
      // this.field = 'adGroupId'
      console.log(val)
    },

    //change广告系列展示广告列表
    changeAdplanLabel(item){
      this.loadingDialogVisible = true
      let _this = this,groupId=''
      item.supplySources.forEach(function(val,i){
        _this.changeSupplySources = val
      })
      console.log(_this.changeSupplySources)
      _this.listParams = {
        'startTime':_this.dateValue[0],//开始日期
        'endTime':_this.dateValue[1],//结束日期
        // 'groupBy':[],
        'returnGrandTotals':true,
        'returnRecordsWithNoMetrics':true,
        'returnRowTotals':true,
        'timeZone':'ORTZ',
        'selector':{
          'conditions':[],
          'orderBy':[],
          'pagination':{
            'limit':_this.pageCount,
            'offset':_this.page
          }
        }
      }
      _this.listParams.selector.orderBy.push({
        'field':_this.localSpend,
        'sortOrder':_this.dOrder
      })
       //获取广告组id
       if(this.adGroupId != undefined){
        groupId = this.adGroupId
        _this.listParams.selector.conditions.push({
          'field':'adGroupId',
          'operator':'EQUALS',
          'values':[groupId]
        })
      } 
      $http.axios.post("/api/apple/ad/getAds/"+_this.ownerId+"/"+item.id+"/"+_this.appId,_this.listParams).then(res=> {
        if(res.data.code==200){
          console.log(res.data.data)
          _this.tableData = res.data.data.data.reportingDataResponse.row
          _this.totalRow = res.data.data.pagination.totalResults
          _this.loadingDialogVisible = false

          _this.campaignId = item.id
          _this.campaignName = item.name
          _this.tableData.forEach((obj,i)=>{
            //广告来源
            if(obj.metadata.productPageId == null){
              obj.metadata.productPageId = '默认产品页面'
            }else{
              obj.metadata.productPageId = '自定产品页面'
            }
            //广告ID
            if(obj.metadata.adId < 0){
              obj.metadata.adId = '-'
            }else{
              obj.metadata.adId = obj.metadata.adId
            }
            //自定义产品页面名称
            if(obj.metadata.productPageId == null){
              obj.metadata.productPageName = '-'
            }else{
              obj.metadata.productPageName = obj.metadata.productPageName
            }
            //支出
            if(obj.total.localSpend!=null){
              obj.total.localSpend.amount = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
            }
            //平均每次点击费用
            if(obj.total.avgCPT!=null){
              obj.total.avgCPT.amount = '¥'+(Number(obj.total.avgCPT.amount).toFixed(2))
            }
            //平均每次转化费用
            if(obj.total.avgCPA!=null){
              obj.total.avgCPA.amount = '¥'+(Number(obj.total.avgCPA.amount).toFixed(2))
            }
            //点击率
            if(obj.total.ttr!=null){
              obj.total.ttr = ((Number(obj.total.ttr)*100).toFixed(2))+'%'
            }
            //转化率
            if(obj.total.conversionRate!=null){
              obj.total.conversionRate = ((Number(obj.total.conversionRate)*100).toFixed(2))+'%'
            }
          })
          _this.getAllGroup()
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          }) 
        }
      })
    },
   
    //广告列表
    advertListFn(){
      let _this = this,groupId='',conditions = [],orderBy = []
      _this.listParams.startTime = _this.dateValue[0]//开始日期
      _this.listParams.endTime = _this.dateValue[1]//结束日期
      _this.listParams.returnGrandTotals = true
      _this.listParams.returnRecordsWithNoMetrics = true
      _this.listParams.returnRowTotals = true
      _this.listParams.timeZone = 'ORTZ'
      _this.listParams.selector = { 
        'pagination':{
          'limit':_this.pageCount,
          'offset':_this.page
        }
      }
      orderBy.push({
        'field':_this.localSpend,
        'sortOrder':_this.dOrder
      })
      if(_this.advertName != ''){
        if(_this.planName.length<=2){
          conditions.push({'field':'adName','operator':'IN','values':[_this.advertName]})//判断一下字符大小--小于等于两个字符
        }else{
          conditions.push({'field':'adName','operator':'STARTSWITH','values':[_this.advertName]})//判断一下字符大小--大于两个字符
        }
      }
      if(_this.advertId != ''){
        if(_this.planName.length<=2){
          conditions.push({'field':'adId','operator':'IN','values':[_this.advertId]})//判断一下字符大小--小于等于两个字符
        }else{
          conditions.push({'field':'adId','operator':'STARTSWITH','values':[_this.advertId]})//判断一下字符大小--大于两个字符
        }
      }
      //获取广告组id
      if(this.adGroupId != undefined){
        groupId = this.adGroupId
        conditions.push({
          'field':'adGroupId',
          'operator':'EQUALS',
          'values':[groupId]
        })
      } else{
        if(_this.adGroupChoose != ''){
          conditions.push({'field':'adGroupId','operator':'EQUALS','values':[_this.adGroupChoose]})
        }
      }
      _this.listParams.selector.orderBy = orderBy
      _this.listParams.selector.conditions = conditions

      $http.axios.post("/api/apple/ad/getAds/"+_this.ownerId+"/"+_this.campaignId+"/"+_this.appId,_this.listParams).then(res=> {
        if(res.data.code==200){
          console.log(res.data.data)
          _this.tableData = res.data.data.data.reportingDataResponse.row
          _this.totalRow = res.data.data.pagination.totalResults
          _this.loadingDialogVisible = false
          _this.tableData.forEach((obj,i)=>{
            //广告来源
            if(obj.metadata.productPageId == null){
              obj.metadata.productPageId = '默认产品页面'
            }else{
              obj.metadata.productPageId = '自定产品页面'
            }
            //广告ID
            if(obj.metadata.adId < 0){
              obj.metadata.adId = '-'
            }else{
              obj.metadata.adId = obj.metadata.adId
            }
            //自定义产品页面名称
            if(obj.metadata.productPageId == null){
              obj.metadata.productPageName = '-'
            }else{
              obj.metadata.productPageName = obj.metadata.productPageName
            }
            //支出
            if(obj.total.localSpend!=null){
              obj.total.localSpend.amount = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
            }
            //平均每次点击费用
            if(obj.total.avgCPT!=null){
              obj.total.avgCPT.amount = '¥'+(Number(obj.total.avgCPT.amount).toFixed(2))
            }
            //平均每次转化费用
            if(obj.total.avgCPA!=null){
              obj.total.avgCPA.amount = '¥'+(Number(obj.total.avgCPA.amount).toFixed(2))
            }
            //点击率
            if(obj.total.ttr!=null){
              obj.total.ttr = ((Number(obj.total.ttr)*100).toFixed(2))+'%'
            }
            //转化率
            if(obj.total.conversionRate!=null){
              obj.total.conversionRate = ((Number(obj.total.conversionRate)*100).toFixed(2))+'%'
            }
          })
          _this.userSetCustom()
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          }) 
        }
      })
    },

    //排序
    handlesortChange(column) {
      let _this = this,orderBy = [],localSpend = '',columnOrder = ''
      columnOrder = column.prop
      _this.orderByList.forEach((val,i)=>{
        if(columnOrder.indexOf(val.orderBy)!=-1){
          _this.localSpend = val.orderBy
        }
      })
      if(column.order == 'descending'){
        this.dOrder = 'DESCENDING'
        orderBy.push({'field':this.localSpend,'sortOrder':this.dOrder})
      }else if(column.order == 'ascending'){
        this.dOrder = 'ASCENDING'
        orderBy.push({'field':this.localSpend,'sortOrder':this.dOrder})
      }
      this.listParams.selector.orderBy = orderBy
      this.advertListFn()     
    },

    //用户已经设置的自定义列
    userSetCustom(){
      let _this = this,titleList = [],columnName = ''
      $http.axios.post("/api/apple/CustomColumn/getColumnsByOrgId?customType=3&orgId="+Number(_this.ownerId)).then(res=> {
        if(res.data.code == 200){
          titleList = res.data.data
          _this.orderByList = res.data.data
          _this.titleList = []
          if(res.data.data != null){
            titleList.forEach((val,i)=>{
              _this.titleList.push({'name':val.name,'prop':val.columnNameLink}) 
            })
          }
          
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取计划系列数据
    getAdplanList(){
      let _this = this
      $http.axios.get("/api/apple/adPlan/getCampaignList/"+_this.ownerId).then(res=> {
        if(res.data.code==200){
          _this.adplanModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

             
    // 删除
    deletePlanFn(row){
      console.log(row)
      let _this = this
      $http.axios.post("/api/apple/ad/deleteAd/"+row.metadata.orgId+"/"+row.metadata.campaignId+"/"+row.metadata.adGroupId+"/"+row.metadata.adId).then(res=> {
        if(res.data.code==200){
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })  
          _this.advertListFn()
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })  
        }   
      })
    },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e.id)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选广告组',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },

    //change列表状态
    changeListStatus(row){
      console.log(row)
      let _this = this,params={},status = ''
      // ACTIVE -- 开关开
      // INVALID, ON_HOLD, PAUSED, REMOVED--开关关
      if(row.metadata.adGroupServingStatus == 'ACTIVE'){//状态 ENABLED：启用 PAUSED：暂停 建议传暂停
        status = 'ENABLED'
      }else{
        status = 'PAUSED'
      }
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'adIdList':[row.metadata.adId],
        'campaignId':row.metadata.campaignId,
        'groupId':row.metadata.adGroupId,
        'orgId':row.metadata.orgId,
        'status':status
      }
      $http.axios.post("/api/apple/ad/updateAdStatus",params).then(function(response) {
        if(response.data.code == 200){
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
          _this.advertListFn()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //批量修改状态
    batchEditStatus(command){
      // adGroupChoose
      if(this.adGroupChoose == ''){
        this.$message({
          message: '请在搜索条件中选择广告组之后修改状态',
          type: 'warning'
        });
      }else{
        console.log(command)
        let _this = this,params = {},adIdList = [],status = ''
        // ACTIVE -- 开关开
        // INVALID, ON_HOLD, PAUSED, REMOVED--开关关
        if(command == 1){//状态 ENABLED：启用 PAUSED：暂停 建议传暂停
          status = 'ENABLED'
        }else{
          status = 'PAUSED'
        }
        let sendttSelection = _this.$refs.multipleTable.selection
        sendttSelection.forEach(function(e,i){
          adIdList.push(e.metadata.adId)
        })
        console.log(adIdList)

        if(sendttSelection.length == 0){
          _this.$message({
            message: '请勾选广告',
            type: 'warning'
          });
        }else{

          params = {
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'adIdList':adIdList,
            'campaignId':_this.campaignId,
            'groupId':_this.adGroupChoose,
            'orgId':_this.ownerId,
            'status':status
          }
          $http.axios.post("/api/apple/ad/updateAdStatus",params).then(function(response) {
            if(response.data.code == 200){
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: 'success'
              })
              // _this.adGroupChoose = ''
              _this.advertListFn()
            } else {
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        }
      }
      
    },

    //创建广告
    createAdvert(){
      let _this = this
      _this.loadingDialogVisible = true
      $http.axios.get("/api/apple/adGroup/getproductPage/"+_this.ownerId+'/'+_this.appId).then(res=> {
        if(res.data.code==200){
          _this.loadingDialogVisible = false
          if(res.data.data == null){
            //没有数据的时候的弹框
            _this.customDialog = true
          }else{
            //有数据的时候跳转页面
            if(_this.changeSupplySources == ''){
              _this.$router.push({path:'/plan-management/createAdvert', query: { id: _this.ownerId, name:_this.ownerName ,campaignId:_this.campaignId,campaignName:_this.campaignName,
              supplySources:_this.supplySources,appId:_this.appId,budget:_this.budget}})
            }else{
              _this.$router.push({path:'/plan-management/createAdvert', query: { id: _this.ownerId, name:_this.ownerName ,campaignId:_this.campaignId,campaignName:_this.campaignName,
              supplySources:_this.changeSupplySources,appId:_this.appId,budget:_this.budget}})
            }
          }
        }
        else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          }) 
        }   
      })
    },

    //获取广告组下拉
    getAllGroup(){
      let _this = this
      $http.axios.get("/api/apple/adGroup/getAllGroup/"+_this.ownerId+"/"+_this.campaignId).then(res=> {
        if(res.data.code==200){
          _this.adGroupChooseOption = res.data.data
        }
        else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          }) 
        }   
      })
    },

    //自定义
    zdyFn(){
      this.$refs.zdyApple.generateTimes(3,this.ownerId)
    },





  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .priceStyle{
    display: block;
    margin:10px 0;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
    font-size:14px;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#66856d;
    border-bottom:2px solid #66856d;
  }
  .detail{
    color:#409eff;
  }
  .loading{
    text-align:center;
  }
  .loading span,.loading i{
    display: block;
  }
  .loading span{
    font-size:20px;
    font-weight:700;
    color:#fff;
  }
  .loading i{
    margin-top:10px;
    font-size:60px;
    /* color: #409eff; */
    color:#fff;
  }
  /* .loadingBg{
    margin-top:200px;
  } */
  ::v-deep .loadingBg .el-dialog{
    margin-top:200px;
    background:rgba(255,255,255,0)!important;
    box-shadow: none;
  }
  .listDetail span{
    display: inline-block;
    vertical-align:middle;
    font-size:12px;
    color:#666;
    /* margin-right:20px; */
  }
  .listDetail span i{
    font-style: normal;
  }
  /*点击名称进入下一个列表*/
  .ownerNameColor{
    color:#409eff;
  }
  /*预警-新*/
  .newAddfpStyle{
    position: relative;
  }
  .newReduceBtnStyle{
    position:absolute;
    top:50%;
    right:0;
    transform: translate(0,-50%);
  }
  .warnInfosList span i{
    font-style: normal;
  }
  .customWrap h3{
    font-size:18px;
    font-weight:700;
    margin:0;
    text-align:center;
  }
  .customWrap p{
    line-height:30px;
    padding:10px 25px;
    text-align:left;
  }
  .customWrap{
    margin-bottom:20px;
  }
  .adverOk{
    text-align:center;
  }
  .queryLeft,.queryRight{
    display: inline-block;
    vertical-align: middle;
  }
  .queryLeft{
    width:65%;
  }
  .queryRight{
    width:35%;
    text-align:right;
  }
  ::v-deep .dateStyle.el-range-editor.el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .dateStyle.el-range-editor .el-range-input{
    background:#F5F7F6!important;
  }
  ::v-deep .queryLeft .el-input--mini .el-input__inner{
    width:160px;
    border:none;
    background:#F5F7F6;
  }
  ::v-deep .queryLeft .el-button + .el-button{
    margin-left:0!important;
  }
  ::v-deep .queryLeft .el-form-item{
    margin-bottom:0!important;
  }
  .materialHead{
    margin-bottom:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
</style>
