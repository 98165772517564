<template>
  <div class="hl_breadCrumb plan-tabs" style="margin-bottom:20px;margin-top:10px;background:#fff;padding:20px;box-sizing: border-box;">
    <BreakTitle :titleData="breakTitle"></BreakTitle>
    <PartOne class="planTabs"></PartOne>
  </div>
</template>
<script>
import Api from "@/api";
import BreakTitle from "./breakHeader";//面包屑导航
import PartOne from "./creatNewPlanPage/partOne";//新建计划页的第一块
export default {
  name: "planTabs",
  data() {
    return {
      // activeName: 'newPlan',
      // breakTitle:['计划管理','账户选择','广告计划']
      breakTitle:['计划管理','账户选择','广告计划','创建计划']

    }
  },
  components:{
    BreakTitle,//面包屑导航
    PartOne//新建计划页的第一块
  },
  methods: {
    handleClick(tab, event) {
      // this.$store.commit('handleClick', tab.name)//
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-tabs__item.is-active {
    color: #6e7c7c;
    font-size: 16px;
    font-weight: 700;
    margin-bottom:10px;
  }
  .el-tabs__header{
    margin:15px 0 0;
  }
  .planTabs .el-tabs__item{
    padding:0!important;
    margin-right:15px!important;
  }
</style>