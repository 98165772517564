<template>
  <div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane 
        v-for="(item,index) in tabsListPart" 
        :key="index"
        :label="item.label" 
        :name="item.name">
        <component :is='item.content' :newPlanVue = "newPlanVue" ref="child"></component>
      </el-tab-pane>
    </el-tabs>
    <!-- 跟金子分享的小知识 -->
    <partTwo v-if="teamShowIs" :newPlanVue = "newPlanVue" ref="teamPage"></partTwo>
    <adCreativity v-if="creatShowIs" :newPlanVue = "newPlanVue" :key="creatInfo.adGroupId"></adCreativity>
    
  </div>

</template>
<script>
import hadPlan from "./havePlan";//选择已有计划
import nPlan from "./newPlan";//新建计划
import partTwo from "./partTwo";//新建计划
import adCreativity from "./adcreativity";//广告创意

export default {
  name: "planTabs",
  data() {
    return {
      newPlanVue:this,//新建的vue
      activeName: 'hadPlan',
      tabsListPart:[{label:"选择已有计划",name:"hadPlan",content:hadPlan},
        {label:"新建计划",name:"nPlan",content:nPlan},],//tabs动态加载的组件内容
      teamShowIs:false,//广告组是否显示
      creatShowIs:false,//创建是否显示
      teamInfo:{},//获取计划id跟推广Id
      creatInfo:{},//获取广告组Id、推广id、流量场景、
      tableData:[],
      options:[],
      states:[],
    }
  },
  components:{
    hadPlan,//选择已有计划
    nPlan,//新建计划
    partTwo,//广告组
    adCreativity,//新建计划
  },
  methods: {
    handleClick(tab, event) {
      let _this = this
      _this.teamShowIs = false
      _this.creatShowIs = false
      // this.$store.commit('handleClick', tab.name)//
    }
  }
}
</script>