<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/auth-management/consumptionSum' }">运营管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/consumptionSum' }">消耗汇总</el-breadcrumb-item>
          <el-breadcrumb-item>素材管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialTab">
                <span @click="mProduct">客户</span>
                <span @click="mProject">项目</span>
                <span class="materialTabCurrent">标注</span>
              </div>
              <div class="materialHBtns">
                <el-form :inline="true" :model="materiaForm" ref="materiaForm" class="demo-form-inline">
                  <el-form-item>
                    <el-button type="primary" size="small" @click="newTagDialog">新建</el-button>
                  </el-form-item>
                </el-form>
                
              </div>
              <el-dialog title="新建标签" :visible.sync="dialogFormVisible" width="20%">
                <el-form :model="form">
                  <el-form-item label="" >
                    <el-input v-model="form.newtagName" autocomplete="off" placeholder="请输入标签名称"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false">取 消</el-button>
                  <el-button type="primary" @click="newTag">确 定</el-button>
                </div>
                
              </el-dialog>
            </div>
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="序号"
                  type="index"
                  :index="indexMethod"
                  width="120">
                  <!-- <template slot-scope="scope">{{ scope.row.taggingid }}</template> -->
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="标注"
                  width="400">
                  <template slot-scope="scope">{{ scope.row.taggingName }}</template>
                </el-table-column>
                <el-table-column 
                  prop="name"
                  label="素材数量"
                  width="200">
                  <template slot-scope="scope">
                    <span style="color:cadetblue;cursor: pointer;" @click="tagNumDetail(scope.row)">{{ scope.row.materialCount }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="操作">
                  <template slot-scope="scope">
                    <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deleteTag(scope.row.taggingid)">
                      <template #reference>
                        <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
              </el-pagination>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'materiaList',
    data() {
      return {
        materiaChannelOptions: [{//头部渠道搜索选项
          value: '选项1',
          label: 'OPPO'
        }, {
          value: '选项2',
          label: 'VIVO'
        }, {
          value: '选项3',
          label: '快手'
        }, {
          value: '选项4',
          label: '今日头条'
        }],
        materiaForm:{//头部查询表单的prop
          mStatus:'',
          mType:'',
          mPosition:''
        },
        materiaTypeOptions: [{//查询表单的选择账户名称
          value: '选项1',
          label: '待审核'
        }, {
          value: '选项2',
          label: '运营审核通过'
        }, {
          value: '选项3',
          label: '运营审核拒绝'
        }, {
          value: '选项4',
          label: '客户审核拒绝'
        }, {
          value: '选项4',
          label: '可使用'
        }],
        materiaPositionOptions:[{//查询表单的选择运营人员
          value: '选项1',
          label: '联盟'
        }, {
          value: '选项2',
          label: '富媒体'
        }, {
          value: '选项3',
          label: '信息流'
        }],
        tableData: [],
        multipleSelection: [],
         // 分页
         page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        totalNum:'',
        tagParams:{
          channel:'',
          page:'',
          pageCount:'',
          userId:''
        },
        dialogFormVisible:false,
        form:{
          newtagName:''
        },
        newtagParams:{
          name:'',
          userId:''
        },
        deleteParams:{
          id:''
        },
        dialogTableVisible:false,
        materialParams:{//列表参数
          channlId: '1',
          page: '',
          pageCount: '',
          userId: ''
        },
        gridData:[],
        newMaterialParams:{
          materialIds:[],
          newTaggingId:'',
          taggingId:'',
          userId:''
        }
        




      }
    },
    computed: {
       
    },
    mounted() {
      this.getuserChannel()
    },
    // created(){
    //   var params = {
    //     page: this.page,
    //     pageCount: this.pageCount
    //   }
    //   this.findAll(params)
    // },
    methods: {
      //头部表单重置
      resetForm(materiaForm) {
        this.$refs[materiaForm].resetFields();
        this.$router.go(0)
      },
      //点击查询按钮
      onSubmit() {
        console.log('submit!');
      },
      //打开新建标签弹框
      addNewTab() {
  //       this.$refs.materiaDialog.openDialog(false)   
      },
      //tab跳转
      mProduct(){
        this.$router.push('/auth-management/MaterialProduct')
      },
      mProject(){
        this.$router.push('/auth-management/MaterialProject')
      },
      // mTags(){
      //   this.$router.push('/auth-management/MaterialTags')
      // },
      indexMethod(index) {
        return index+1;
      },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        let userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
          if(response.data.data.length!=0){
            that.tagParams.channel = response.data.data[0].id
            that.getTagList()
          }
        })
      },

      //获取标注列表
        getTagList(){
          let that = this
          that.tagParams.page = that.page
          that.tagParams.pageCount = that.pageCount
          that.tagParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
          $http.axios.post("/api/operationManagement/materialoperation/getTaggingList",that.tagParams).then(function(response) {
            if(response.data.code == 200){
              that.tableData = response.data.data.items
              that.totalRow = response.data.data.totalCount
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        },

        //新建标签
        newTagDialog(){
          this.dialogFormVisible = true
        },

        //新建标签--确定
        newTag(){
          let that = this
          that.dialogFormVisible = false
          that.newtagParams.name = that.form.newtagName
          that.newtagParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
          $http.axios.post("/api/operationManagement/materialoperation/addtagging",that.newtagParams).then(function(response) {
            if(response.data.code == 200){
              that.getTagList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        },

        //点击进入相应的标注列表
        tagNumDetail(row){
          console.log(row)
          this.$router.push({path:'/auth-management/MaterialAddTag/'+row.taggingid})
        },

        //删除
        deleteTag(i){
          let that = this
          $http.axios.get("/api/operationManagement/materialoperation/deltagging?id="+i).then(function(response) {
            if(response.data.code == 200){
              that.getTagList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        },
        



         // 分页
      handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageCount = val
      var params = {
        page: this.page,
        pageCount: this.pageCount
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.page = val
      var params = {
        page: this.page,
        pageCount: this.pageCount
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getTagList()
    },

      //表格中选中和未选中
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materiaContent{
      margin-top:25px;
    }
    .materialTab{
      font-size:14px;
      color:#6e7c7c;
      margin-bottom:20px;
    }
    .materialTabCurrent{
      color:#333;
      font-size:16px;
    }
    .materialTab span{
      display:inline-block;
      vertical-align:middle;
      margin-right:10px;
      cursor: pointer;
    }
    .materialHBtns{
      text-align: right;
      margin-bottom: -30px;
    }

  </style>
