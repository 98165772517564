<template>
  <div class="authority common-page">
    <div class="groupStyle">
      <h2 class="groupTitle">广告组</h2>
      <el-form :model="sizeForm" :rules="rules" ref="sizeForm" :label-position="labelPosition" label-width="110px">
        <div class="flowPart">
          <span class="title fW">流量</span>
          <el-form-item label="推广流量" prop="resourceFlow">
            <el-radio-group v-model="sizeForm.resourceFlow" size="small" @change="changePopu" disabled>
              <el-radio-button v-for="(item,index) in adTeamtabsListPart"
              :key="index"
              :label="item.value"
              :name="item.label">
              {{item.label}}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="流量场景" prop="flowScene">
            <el-radio-group v-model="sizeForm.flowScene" size="small" @change="changePriceType" disabled>
              <el-radio-button v-for="(item,index) in flowYytgZyll"
              :key="index"
              :label="item.value"
              :name="item.name">
              {{item.name}}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div class="one-one">
            <span class="title fW">目标</span>
            <div v-if="newApply">
              <el-form-item label="应用ID" prop="target">
                <el-select v-model="sizeForm.target" value-key="appId" ref="shuruValue" @change="changeTarget" @keyup.enter.native="targetIds" filterable style="width:350px;" disabled>
                  <el-option
                    v-for="(item,i) in targetOption"
                    :key="item.appId"
                    :label="item.appName"
                    :value="item">
                    <span style="float: left">{{ item.appId }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.appName }}</span>
                  </el-option>
                </el-select>
                <div class="targetBox" v-show="targetShow">
                  <div class="targetBox_img">
                    <img :src="targetData.iconUrl">
                  </div>
                  <div class="targetBox_name">{{targetData.appName}}</div>
                </div>
              </el-form-item>
              <el-form-item label="推广落地页" prop="landingPage" v-if="landingPages">
                <el-select v-model="sizeForm.landingPage" @change="changeLandingPage">
                  <el-option
                    v-for="(item,i) in landingPageOption"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-select v-model="landingSecond">
                  <el-option
                    v-for="(item,i) in landingSecondOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="推广落地页" prop="landingPage" v-if="landingPages2">
                <el-select v-model="sizeForm.landingPage" @change="changeLandingPage2">
                  <el-option
                    v-for="(item,i) in landingPageOption2"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-select v-model="landingSecond" value-key="pageId" v-if="optionOne" @change="changeNewPage11">
                  <el-option
                    v-for="(item,i) in landingSecondOption2"
                    :key="i"
                    :label="item.pageName"
                    :value="item">
                    <span >{{ item.pageId }}</span> |
                    <span style="color: #8492a6; font-size: 13px">{{ item.pageName }}</span>
                  </el-option>
                </el-select>
                <el-select v-model="landingSecond" value-key="h5Id" v-if="optionTwo" @change="changeNewPage">
                  <el-option
                    v-for="(item,i) in landingSecondOption3"
                    :key="i"
                    :label="item.h5Name"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="下载并打开" v-if="openClose">
                <el-switch
                  v-model="ocPrice"
                  active-color="#13ce66"
                  inactive-color="#9f9f9f"
                  active-value="1"
                  inactive-value="0"
                  @change="changeopenOrclose">
                </el-switch>
              </el-form-item>
            </div>
            <div v-if="newApplyTwo">
              <el-form-item label="网页地址" prop="netPage" >
                <el-select v-model="sizeForm.netPage" @change="changeNet">
                  <el-option
                    v-for="(item,i) in netPageOption"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-input v-model="netLink" v-if="netLinkShow" placeholder="请输入内容" style="width:300px;"></el-input>
                <el-select v-model="landingSecond" value-key="pageId" v-if="netLinkShowTwo" @change="changeNetChildren">
                  <el-option
                    v-for="(item,i) in netPageSecondOption"
                    :key="i"
                    :label="item.pageName"
                    :value="item">
                    <span >{{ item.pageId }}</span> |
                    <span style="color: #8492a6; font-size: 13px">{{ item.pageName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Deeplink链接" prop="deepl" >
                <el-input v-model="deepLinkValue" placeholder="请输入内容" style="width:300px;"></el-input>
              </el-form-item>
            </div>
            <directional
              v-bind:sizeForm="sizeForm"
              ref="deriectRefs"
              v-if="showResource" 
              :pubVue = "pubVue"
              :extensionFlow = "extensionFlow" 
              :flowScene = "flowScene"
              :extensionType = "extensionType"
              ></directional>
            <div v-if="zntlya">
              <span class="title fW">智能拓量</span>
              <el-form-item label="智能拓量" prop="brainPower">
                <el-switch
                  v-model="sizeForm.brainPower"
                  active-color="#13ce66"
                  inactive-color="#9f9f9f"
                  active-value="2"
                  inactive-value="0"
                  @change="changeZntl">
                </el-switch>
              </el-form-item>
            </div>
            <span class="title fW">排期</span>
            <el-form-item label="排期" prop="scheduling">
              <el-date-picker
                v-model="sizeForm.scheduling"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd "
                value-format="yyyyMMdd"
                :picker-options="pickerOptions"
                @change="changeDateSlot">
              </el-date-picker>
              <div class="setTime">
                <el-checkbox v-model="sizeForm.setTimeCheckbox" @change="setCarryTime">设置投放时间</el-checkbox>
              </div>
            </el-form-item>
            <el-form-item>
              <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
            </el-form-item>
            <span class="title fW">出价</span>
            <el-form-item label="投放模式" v-if="launchMode">
              <el-radio-group v-model="sizeForm.deliveryMode" size="small" @change="changeLaunchMode" disabled>
                <el-radio-button  v-for="(item,index) in deliveryModeList"
                :key="index"
                :label="item.value"
                :name="item.name">
                {{item.label}}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="出价方式" prop="priceType">
              <el-radio-group v-model="sizeForm.priceType" size="small" @change="priceTypeChange">
                <el-radio-button v-for="(item,index) in priceTypeOption"
                :key="index"
                :label="item.id"
                :name="item.name">
                {{item.name}}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <div v-if="typePriceShow">
              <el-form-item label="基础出价" prop="basePrice" >
                <el-input v-model="sizeForm.basePrice" :rules="numberlimitM" :placeholder="lowPrice" style="width:300px;" @blur="changeMoney"></el-input>
              </el-form-item>
              <el-form-item label="转化类型" prop="conversion" style="margin-top:20px;">
                <el-select v-model="sizeForm.conversion" @change="changeZHTypeNew">
                  <el-option
                    v-for="(item,i) in conversionOption"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="转化出价" prop="zhPrice" >
                <el-input v-model="sizeForm.zhPrice" :placeholder="zhcjPrice" style="width:300px;" @blur="changezhMoney"></el-input>
              </el-form-item>
            </div>
            <div v-if="deepZh">
              <el-form-item label="深度转化投放" >
                <el-switch
                  v-model="deepZhValue"
                  active-color="#13ce66"
                  inactive-color="#9f9f9f"
                  active-value="2"
                  inactive-value="0"
                  @change="changeDeepZhValue">
                </el-switch>
                <div v-if="deepShow" style="margin-left:-110px;margin-top:20px;">
                  <el-form-item label="深度转化类型" prop="deepZhType" >
                    <el-select v-model="sizeForm.deepZhType" @change="changeDeeplx">
                      <el-option
                        v-for="(item,i) in deepsionOption"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="深度转化出价" prop="deepzhPrice" >
                    <el-input v-model="sizeForm.deepzhPrice" :placeholder="deepzhcjPrice" style="width:300px;" ></el-input>
                  </el-form-item>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="跟随应用" prop="followApplication" v-show="launch2_one">
              <el-select v-model="targetNew" placeholder="请输入应用名称" @change="changeTargetNew" ref="gsshuruValue" @keyup.enter.native="gstargetIds" filterable :filter-method="dataFilter">
                <el-option
                  v-for="(item,i) in targetNewOption"
                  :key="i"
                  :label="item.appName"
                  :value="item.appId"
                  :disabled="item.disabled">
                </el-option>
              </el-select>
              <ul class="launchBox"> 
                <li v-for="(item,index) in targetNewData" :key="index">
                  <div class="targetBox_img">
                    <img :src="item.iconUrl">
                  </div>
                  <div class="targetBox_name">{{item.appName}}</div>   
                  <i class="closeApp" @click="closeAppList(index)">X</i>
                </li>              
              </ul>
            </el-form-item>
            <el-form-item label="基础出价" prop="basePrice" v-if="launchPrice">
              <el-input v-model="sizeForm.basePrice" :rules="numberlimitM" :placeholder="lowPrice" style="width:300px;" @blur="changeMoney"></el-input>
            </el-form-item>
            <el-form-item label="动态出价" v-if="launch2_price">
              <el-switch
                v-model="dynamicPriceValue"
                active-color="#13ce66"
                inactive-color="#9f9f9f"
                active-value="1"
                inactive-value="0"
                @change="changeTrendsPrice">
              </el-switch>
              <el-form-item label="转化类型" prop="conversion_cpd" v-if="conversionShow" style="margin-top:20px;">
                <el-select v-model="sizeForm.conversion_cpd" @change="changeZHType">
                  <el-option
                    v-for="(item,i) in conversionOption_cpd"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
            <el-form-item label="跟随最高出价" prop="highestBid" v-if="launch2_one">
              <el-input v-model="sizeForm.highestBid" :placeholder="gensuilower" style="width:300px;" @blur="changegsMoney"></el-input>
            </el-form-item>
            <keyWord v-bind:sizeForm="sizeForm" ref="keyWordRef" v-if="keyWords"></keyWord>
            <span class="title fW">广告组名称</span>
            <el-form-item label="广告组名称" prop="adTeamName">
              <el-input v-model="sizeForm.adTeamName" style="width:300px;"></el-input>
            </el-form-item>
          </div>

        </div>
        <el-form-item style="margin-top:50px;">
          <el-button type="primary" size="small" @click="submitFn">保存并关闭</el-button>
          <el-button type="info" plain size="small" @click="goPlanPage">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import keyWord from "./adGroupKeyWord";//关键词
import directional from "./adGroupDirectional";//定向
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import TimeUnit from '../TimeUnit.vue';

export default {
  name: 'adgroupTeamEdit',
  components:{
    keyWord,
    directional,
    timeUnit:TimeUnit
  },
  data() {
    return {
      numberlimitM: { validator: this.checkMoneyM, trigger: 'change' },
      pubVue:this,
      creatSucess:true,//新建广告组，保存，取消按钮是否显示
      activeName:"ownFlowPart",
      adTeamtabsListPart:[{//推广流量
        label:'自有流量',
        value:'2'
      },{
        label:'联盟流量',
        value:'3'
      }],
      deliveryModeList:[{ //投放方式
        label:'普通投放',
        value:0
      },{
        label:'跟随投放',
        value:1
      }],
      priceTypeOption:[],//出价方式
      sizeForm: {
        netPage:'',
        resourceFlow: '',//流量类型
        target:'',//应用ID
        scheduling:[],//排期
        setTimeCheckbox:'',//设置投放时间
        downloadSwitch:'',//打开并下载开关
        landingPage:'',//推广落地页——流量场景->信息流
        flowScene:'',//流量场景
        deliveryMode:'',//投放模式
        basePrice:'',//基础出价
        followApplication:'',//跟随应用
        highestBid:'',//跟随最高出价
        adTeamName:'',//广告组名称
        searchKeyWord:'',//搜索关键字
        payPrice:'',//关键词里的出价
        regionKey:'',//匹配模式
        directe:'',//人群包定向
        feDirecte:'',//人群包排除
        local:'',//定向-地域
        choosedDirRegion:'',//选择定向包
        localTransfer:'',//定向设置-人口属性-按城市
        network:'',//网络
        modelPrice:'',//机型价格
        appInstall:'',//APP安装
        userActive:'',//用户活跃
        userActiveInterest:'',//用户行为兴趣
        shieldChange:'',//屏蔽已转化用户
        shieldChecked:'',//另存为定向包
        brainPower:'',//智能拓量
        brainPowerList:[],//智能拓量穿梭框
        deepLink:'',//Deeplink链接
        checkboxGroupPrice:[],//按机型价格-一定是数组
        checkboxGroupNetwork:[],//按网络-一定是数组
        checkboxGroupYear:[],//按年龄-一定是数组
        activeDay:'',//用户活跃
        unActiveDay:'',//非活跃
        conversion:'',//转化类型
        resourceFlows:'',//定向
        address:'',//地域
        sex:'',//性别
        ages:'',//年龄
        zhPrice:'',//转化出价
        deriectName:'',//定向包名称
        priceType:'',
        conversion_cpd:'',
        deepZhType:'',//深度转化类型
        deepzhPrice:'',//深度转化出价
      },
      netPageOption:[],
      netPageSecondOption:[],
      rules: {
        deepzhPrice:[
          { required: true, message: '请输入深度转化出价', trigger: 'blur' }
        ],
        deepZhType:[
          { required: true, message: '请选择深度转化类型', trigger: 'change' }
        ],
        deriectName:[
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        netPage:[
          { required: true, message: '请选择网页地址', trigger: 'change' }
        ],
        resourceFlow: [
          { required: true, message: '请选择流量类型', trigger: 'blur' }
        ],
        target: [
          { required: true, message: '请输入应用ID', trigger: 'blur' }
        ],
        conversion:[
        { required: true, message: '请选择转化类型', trigger: 'change' }
        ],
        landingPage: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        flowScene:[
          { required: true, message: '请选择流量场景', trigger: 'blur' }
        ],
        deliveryMode:[
          { required: true, message: '请选择投放模式', trigger: 'blur' }
        ],
        basePrice:[
          { required: true, message: '请输入最低价', trigger: 'blur' }
        ],
        priceType:[
          { required: true, message: '请选择出价方式', trigger: 'change' }
        ],
        highestBid:[
          { required: true, message: '请输入最低价', trigger: 'blur' }
        ],
        followApplication:[
          { required: true, message: '请输入最低价', trigger: 'blur' }
        ],
        zhPrice:[
          { required: true, message: '请输入最低价', trigger: 'blur' }
        ],
        adTeamName:[
          { required: true, message: '请输入广告组名称', trigger: 'blur' }
        ]
      },//校验规则提示语
      // radio:1,
      targetOption:[],//应用ID的下拉数据
      targetData:{},//change应用ID之后后台返回的数据
      labelPosition:'left',//表单中的label左对齐
      targetShow:false,//应用IDchange后的图片和name显示
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },//日期快捷键
      timeUnitShow:true,//设置执行时间
      getPriceInfo:{},//获取出价的金额上限下限
      launch2_one:false,//跟随投放才有的
      dynamicPriceValue:'',//动态出价
      conversionOption:[],//转化类型
      conversionOption_cpd:[],//只有cpd下边动态出价打开时，有这个转化出价
      conversionShow:false,//出价类型的显示
      launch2_price:true,//动态出价显示
      landingSecond:'',//推广落地页联动
      landingPageOption:[],
      landingSecondOption:[
        {
          value:'0',
          label:'默认模版'
        }
      ],
      landingPageOption2:[],
      landingSecondOption2:[],
      appid:'',
      landingPages:true,
      launchMode:true,
      keyWords:false,
      landingPages2:false,
      pageTypeParams:'',
      optionOne:true,
      optionTwo:false,
      landingSecondOption3:[],
      ownerId:'',//获取的ownerId
      showResource:false,//定向
      zntl:false,//智能拓量
      addParams:{ //保存入参
        adGroupId: '', //广告组id
        adGroupName: '', //广告组name
        adSearchPremiumKwSaveDTOList: [ //关键字
          // {
          //   categoryId: '',
          //   deleteFlag: '',//编辑才有--新建是在数组外
          //   keyword: '',
          //   kwPackageId: '',//编辑才有--新建没有
          //   matchType: '',
          //   price: '',
          //   source: ''
          // }
        ],
        appId: '',//应用id
        autoOpenFlag: '',//下载并打开
        beginTime: '',//推广开始时间
        billingType: '',//出价方式
        dayLimit: '',//推广日期限制
        deepOcpcPrice: '',//深度ocpc转化出价
        deepOcpcType: '',//深度ocpc转换类型
        deepUrl: '',//直达链接
        deptId: '',//部门id
        endTime: '',//推广截止日期
        extensionFlow: '',//推广流量
        extensionType: '',//推广目的
        flowScene: '',//流量场景
        //快应用
        instantAppId: '',//快应用id
        instantAppUrl: '',//快应用落地页
        linkDeskFlag: '',//是否打开快应用并添加到桌面
        //快应用结束
        ocpcOptmType: '',//区分ocpc单出价和双出价
        ocpcPrice: '',//目标转化出价
        ocpcType: '',//目标转化类型
        ownerId: '',//账户id
        pageId: '',//落地页id
        pageType: '',//落地页建站类型
        pageUrl: '',//h5落地页链接
        planId: '',//计划id
        price: '',//基础出价
        smartExpandType: '',//智能拓量
        targetId: '',//定向id
        timeLimit: '',//推广时段限制
        timeSet: '',//推广时间段
        userId: '',//用户id
        userName: '',//用户name
        // adSearchPremiumKwSaveDTOList: [{
        //   categoryId: '',
        //   keyword: '',
        //   matchType: '',
        //   price: '',
        //   source: ''
        // }],
        //deleteFlag: '',//新建是在数组外
        dynamicPrice: '',//动态出价入参，新建有用到，编辑没有此参数
        // smartExpandBreakthrough: '',//智能拓量--新建也没有用到
        // targetROI: '',//目标ROI系数*1000000--新建也没有用到
        // targetSource: '',//定向来源--新建也没有用到
        // adServingType:'',//投放模式 新建有，编辑没有此参数，在页面中是不可编辑的选项
        ocpcOptmType:'',//深度转化开关，0是关，2是开
        // deepOcpcType:'',
        deepOcpcPrice:''
      },
      userId:'',
      userName:'',
      deptId:'',
      planId:'',
      appId:'',
      targetNewData:[],
      targetNewOption:[],
      targetNew:'',
      applistshow:true,
      launchPrice:true,
      typePriceShow:false,
      ocPrice:'',
      flowYytgZyll:[],
      targetIdIs:false,//判断是否新建定向
      extensionFlow:null,//
      flowScene:null,//
      yingyongID:true,
      deeplinkShow:false,
      // teamInfo:this.newTeamVue.newPlanVue.teamInfo,
      extensionType:null,//
      lowPrice:'最低出价1.5元',
      zhcjPrice:'最低出价1.2元',
      deepzhcjPrice:'最低出价1.2元',
      gensuilower:'最低出价1.5元',
      ocpcTYPE:'',//获取不同价格
      newApply:true,//目标那一块的显示问题
      newApplyTwo:false,
      netLink:'',
      netLinkShow:false,
      netLinkShowTwo:true,
      deepLinkValue:'',
      kindOfPrices:{},
      zntlya:false,
      lowPriceShow:'', //基础出价
      gslowPriceShow:'', //跟随出价
      zhlowPriceShow:'', //转化出价
      adGroupId:'',//广告组id
      editParams:{ //编辑回显接口入参
        accountId:'',
        adGroupId:''
      },
      editData:{},//编辑回显接口出参
      openClose:false,//下载并打开
      deepZhValue:'',//深度转化
      deepsionOption:[],//深度转化类型集合
      deepZh:false,//深度转化投放的展示
      deepShow:false,//打开深度转化投放显示
      priceba:''

    }
  },
  
  mounted() {
    console.log(this.sizeForm)
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.ownerId = Number(this.$route.query.id)
    this.ownerName = this.$route.query.name
    this.planId = this.$route.query.planId
    this.adGroupId = this.$route.query.adGroupId
    this.getEditDetail()//编辑回显接口

    // this.extensionType = this.teamInfo.extensionType
    this.getApplyId()//应用id
    this.getPriceList()//出价列表
    this.changeExtensionFlow = 2
    this.extensionFlow = 2
    this.sizeForm.deliveryMode = 0 //投放模式默认
    this.addParams.adServingType = 0
    if(this.sizeForm.deliveryMode == 0){
      this.launch2_one = false
    }  
    // console.log(this.$refs.timeStrArray.timeContent) 
  },
  
  methods: {
    dateFormat(){
      const dt = new Date(new Date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')

      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },  

    //编辑回显接口
    getEditDetail(){
      let that = this
      that.editParams.accountId = that.ownerId
      that.editParams.adGroupId = Number(that.adGroupId)
      $http.axios.post("/api/advertisement/adGroup/v1/getOppoGroupDetail",that.editParams).then(res=> {
      // $http.axios.post("/guanjia/adGroup/v1/getOppoGroupDetail",that.editParams).then(res=> {
        if(res.data.code == 200){
          that.editData = res.data.content
          that.extensionType = that.editData.extensionType //推广目的
          that.addParams.extensionType = that.extensionType
          that.sizeForm.resourceFlow = that.editData.extensionFlow //推广流量
          that.extensionFlow = that.editData.extensionFlow //推广流量
          that.addParams.extensionFlow = that.editData.extensionFlow
          that.sizeForm.flowScene = that.editData.flowScene //流量场景
          that.changeFlowScene = that.editData.flowScene //流量场景
          that.flowScene = that.editData.flowScene //流量场景
          that.addParams.flowScene = that.changeFlowScene
          that.sizeForm.adTeamName = that.editData.adGroupName //广告组名称
          that.sizeForm.landingPage = String(that.editData.pageType)//落地页-前
          // that.landingSecond = String(that.editData.pageId) //落地页-后
          that.addParams.pageType = that.editData.pageType
          that.addParams.pageId = that.editData.pageId
          that.addParams.pageUrl = that.editData.pageUrl
          if(that.editData.extensionType == 1){
            that.sizeForm.netPage = String(that.editData.pageType) //网页地址-前
            that.pageTypeParams = that.editData.pageType
          }
          //判断落地页
          if(that.editData.pageType == 9){
            that.landingPages = true
            that.landingPages2 = false
            that.addParams.pageId = 0
            that.landingSecond = '默认模版'
          }else if(that.editData.pageType == 4||that.editData.pageType == 12){
            that.landingPages = false
            that.landingPages2 = true
            that.optionTwo = false
            that.optionOne = true
            that.getLandingPage()
          }else if(that.editData.pageType == 11){
            that.landingPages = false
            that.landingPages2 = true
            that.optionTwo = true
            that.optionOne = false
            that.getLandingMode()
          }
          //判断落地页结束
          that.$set(that.sizeForm,'scheduling',[ String(that.editData.beginTime),String(that.editData.endTime)]) //设置开始结束日期
          if(that.editData.beginTime == 0 && that.editData.endTime == 0){
            that.addParams.dayLimit = 0
          }else{
            that.addParams.dayLimit = 1
            that.addParams.beginTime = that.editData.beginTime //开始日期
            that.addParams.endTime = that.editData.endTime //结束日期
          }
          that.sizeForm.deliveryMode = String(that.editData.adServingType) //投放模式
          if(that.editData.adServingType == 1){
            that.launch2_one = true
            that.launch2_price = false
          }
          that.sizeForm.priceType = String(that.editData.billingType) //出价方式
          that.addParams.billingType = that.editData.billingType
          if(that.editData.billingType == 5){
            that.typePriceShow = true //有基础出价和转化类型和转化出价
            that.launchPrice = false //基础出价
          }else{
            that.typePriceShow = false
            that.launchPrice = true
          }
          that.sizeForm.basePrice = that.editData.price/100 //基础出价
          that.dynamicPriceValue = String(that.editData.dynamicPrice) //动态出价
          that.addParams.dynamicPrice = that.editData.dynamicPrice
          if(that.editData.dynamicPrice == 1){
            that.conversionShow = true //如果动态出价为开，转化类型显示
            that.getChangeType_cpd()
            that.sizeForm.conversion_cpd = String(that.editData.ocpcType) //转化类型 
          }
          that.getChangeType()
          that.sizeForm.conversion = String(that.editData.ocpcType) //转化类型
          that.addParams.ocpcType = that.editData.ocpcType
          that.sizeForm.target = String(that.editData.appId) //应用ID
          //时间段
          let timeListArr = []
          if(that.editData.timeSet == []){
            that.sizeForm.setTimeCheckbox = false
            that.timeUnitShow = false
            that.addParams.timeLimit = 0
          }else{
            that.sizeForm.setTimeCheckbox = true
            that.$refs.timeStrArray.weekDateList = false
            that.timeUnitShow = true
            that.addParams.timeLimit = 1
            if(that.editData.timeLimit == 1){
              let indexArr = []
              that.editData.timeSet.forEach(function(obj,i){
                if(obj[i].length > 0){
                  indexArr.push(i)
                }
                timeListArr.push({arr:obj[i]})
              })
              indexArr.forEach(function(e,i){
                that.editData.timeSet[e][e].forEach((obj,j)=>{
                  that.$refs.timeStrArray.rowUnit[e][obj].class = 'ui-selected'
                })
              })
            } 
          }
          that.$refs.timeStrArray.timeContent = timeListArr
          //时间段结束
          that.sizeForm.highestBid = that.editData.followMaxPrice/100 //跟随最高出价
          that.sizeForm.brainPower = String(that.editData.smartExpandType) //智能拓量
          that.sizeForm.zhPrice = that.editData.ocpcPrice/100 //转化出价
          that.ocPrice = String(that.editData.autoOpenFlag) //下载并打开
          that.deepZhValue = String(that.editData.ocpcOptmType)//深度转化投放
          if(that.editData.ocpcOptmType == 2){
            that.deepShow = true
          }
          that.addParams.ocpcOptmType = that.editData.ocpcOptmType
          that.sizeForm.deepzhPrice = that.editData.deepOcpcPrice/100 //深度转化出价
          // that.sizeForm.deepZhType = String(that.editData.deepOcpcType)//深度转化类型
          that.getDeepsionOption()
          that.addParams.deepOcpcType = that.editData.deepOcpcType
          that.deepLinkValue = that.editData.deepUrl //Deeplink链接
          that.addParams.deepUrl = that.editData.deepUrl
          // that.addParams.targetId = that.editData.targetId




          //一些展示隐藏的判断
          if(that.extensionFlow == 2 && that.changeFlowScene == 3){ //自有流量-安装有礼
            that.launch2_price = false //动态出价
          }
          if(that.extensionFlow == 2 && that.changeFlowScene == 4){ //自有流量-开机必备
            that.landingPages = false //推广落地页
          }
          if(that.extensionFlow == 2 && that.changeFlowScene == 5){ //自有流量-搜索
            that.launchMode = false //投放模式
          }
          if(that.extensionFlow == 2 && that.changeFlowScene == 6 ||that.extensionFlow == 3 ){ //自有流量-信息流
            that.landingPages2 = true //推广落地页
            that.landingPages = false //推广落地页
            that.showResource = true //定向
            that.zntlya = true //智能拓量
            that.launch2_price = false //动态出价
            that.launchMode = false //投放模式
            that.addParams.smartExpandType = that.editData.smartExpandType //智能拓量的入参
            // if(that.editData.pageType == 11){
            //   that.openClose = true
            //   that.addParams.autoOpenFlag = that.autoOpenFlag
            // }else{
            //   that.openClose = false
            // }
          }
          if(that.extensionFlow == 2 && that.changeFlowScene == 6&&that.editData.pageType == 11){
            that.openClose = true//下载并打开
            that.addParams.autoOpenFlag = that.autoOpenFlag
          }else{
            that.openClose = false//下载并打开
          }
          if(that.extensionFlow == 3&&that.changeFlowScene == 11&& that.editData.billingType == 5){
            that.deepZh = true//深度转化投放
          }else{
            that.deepZh = false//深度转化投放
          }       
          if(that.extensionFlow == 2&&that.changeFlowScene == 5){
            that.keyWords = true//关键字
          }

          

          that.getScreen()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    goPlanPage(){
      this.$router.push({
        path: '/plan-management/advertisingPlan',
        query: {
          id: this.ownerId,
          name:this.ownerName,
          tabIndex:1
        }
      })
    },

    //change推广流量
    changePopu(val){
      this.changeExtensionFlow = Number(val)
      this.extensionFlow = this.changeExtensionFlow
      this.getScreen()
      this.sizeForm.landingPage = ''
      this.landingSecond = ''
    },
    //流量场景
    getScreen(){
      let that = this
      let ownerId = that.ownerId//oppo正式站的ownerId
      console.log(that.extensionType) 
      $http.axios.get("/api/advertisement/preaddgroup/typeFLOW_SCENEList?ownerId="+ownerId+"&extensionFlow="+that.extensionFlow+"&extensionType="+that.extensionType).then(res=> {
        if(res.data.code == 200){
          if(res.data.content.length != 0){
            that.flowYytgZyll = res.data.content
            // that.sizeForm.flowScene = res.data.content[0].value //流量场景默认
            // that.addParams.flowScene = res.data.content[0].value
            // that.changeFlowScene = res.data.content[0].value
            // that.flowScene = res.data.content[0].value
            if(that.extensionType ==1){
              that.newApply = false
              that.newApplyTwo = true
              that.addParams.dynamicPrice = 2
            }
            if(that.extensionType ==1 && that.changeFlowScene == 5){
              that.showResource = true
              that.launch2_price = false
            }
            that.getPriceType()
            that.kindOfPage() 
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //应用id接口
    getApplyId(){
      let that = this
      let ownerId = that.ownerId//oppo正式站的ownerId 
      $http.axios.get("/api/advertisement/preaddgroup/listHistoryAppInfo?ownerId="+ownerId).then(res=> {
        if(res.data.code == 200){
          that.targetOption = res.data.content
          that.targetNewOption = res.data.content
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change应用ID
    changeTarget(val){
      this.addParams.appId = val.appId
      this.targetShow = true
      this.targetData = val
    },
    targetIds(){
      let that = this
      let ownerId = that.ownerId
      let appid = that.$refs.shuruValue.query
      $http.axios.get("/api/advertisement/preaddgroup/AppInfo?appid="+appid+'&ownerId='+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          that.targetOption.push(res.data.data)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    gstargetIds(){
      let that = this
      let ownerId = that.ownerId
      let appid = that.$refs.gsshuruValue.query
        $http.axios.get("/api/advertisement/preaddgroup/AppInfo?appid="+appid+'&ownerId='+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          that.targetNewOption.push(res.data.data)     
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }) 
    },

    changeTargetNew(val){
      this.targetModel()
      let addIds = []
      addIds.push(val)
      this.addParams.followappidlist = String(addIds) //跟随应用id
    },
    
    targetModel(){
      let that = this
      that.appid = that.targetNew
      let ownerId = that.ownerId
      let num = 0
      $http.axios.get("/api/advertisement/preaddgroup/AppInfo?appid="+that.appid+'&ownerId='+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          if(that.targetNewData.length==0){
            that.targetNewData.push(res.data.data)
          }else{
            that.targetNewData.forEach((e,i)=>{
              if(e.appId == res.data.data.appId){
                num = 1
              }
            })
            if(num == 0){
              that.targetNewData.push(res.data.data)
            } 
          }
        } else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //设置执行时间显示弹框
    setCarryTime(){
      let that = this
      if(that.sizeForm.setTimeCheckbox == true){
        that.timeUnitShow = true
        that.addParams.timeLimit = 1
        that.$refs.timeStrArray.weekDateList = false
      }else{
        that.timeUnitShow = false
        that.addParams.timeLimit = 0
      }
    },

    //change流量场景
    changePriceType(val){
      this.changeFlowScene = Number(val)
      this.flowScene = this.changeFlowScene
      this.addParams.flowScene = this.changeFlowScene
      this.typePriceShow = false
      this.getPriceType()
      this.kindOfPrice()
      this.sizeForm.landingPage = ''
      this.landingSecond = ''
      // this.kindOfPage() 
      if(val == 2){
        this.launch2_price = true
        this.landingPages = true
        this.launchMode = true
        this.keyWords = false
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.zntlya = false
        this.deepZh = false
      }else if(val == 3){
        this.launch2_price = false
        this.landingPages = true
        this.launchMode = true
        this.keyWords = false
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.addParams.dynamicPrice = 2
        this.zntlya = false
        this.deepZh = false
      } else if(val == 4){
        this.launch2_price = true
        this.landingPages = false
        this.launchMode = true
        this.keyWords = false
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.zntlya = false
        this.deepZh = false
      } else if(val == 5){
        this.launch2_price = true
        this.landingPages = true
        this.launchMode = false
        this.keyWords = true
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.zntlya = false
        this.launch2_one = false
        this.deepZh = false
      } else if(val == 6){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.addParams.dynamicPrice = 2
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.launch2_one = false
        this.deepZh = false
      }else if(val == 7){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.addParams.dynamicPrice = 2
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.deepZh = false
      }else if(val == 8){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
      }else if(val == 9){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
      }else if(val == 10){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
      }else if(val == 11){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
      }else if(val == 12){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
      }
      if(this.extensionType ==1 && this.extensionFlow == 2 && val == 6){
        this.zntlya = true
        this.addParams.smartExpandType = 0
      }
      if(this.extensionType ==1 && this.extensionFlow == 3){
        this.zntlya = true
        this.addParams.smartExpandType = 0
      }   

    },

    //获取出价方式
    getPriceType(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=BILLING_TYPE").then(res=> {
        if(res.data.code == 200){
          if(res.data.content.length != 0){
            that.priceTypeOption = res.data.content
            // that.sizeForm.priceType = res.data.content[0].id
            // that.addParams.billingType = res.data.content[0].id
            // if(res.data.content[0].id == 1){
            //   that.launch2_price = true
            //   that.addParams.dynamicPrice = 0
            // }else{
            //     that.launch2_price = false
            //     that.addParams.dynamicPrice = 2
            //   }
            // if(that.extensionType == 2 && that.extensionFlow == 2 && that.changeFlowScene == 3){
            //   that.launch2_price = false
            //   that.addParams.dynamicPrice = 2
            // }
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })

    },

    //出价列表接口（控制输入最大最小值）
    getPriceList(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/groupPrice?ownerId="+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          that.getPriceInfo = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change投放模式
    changeLaunchMode(val){
      if(val == 1){
        this.launch2_one = true
        this.launch2_price = false
        this.addParams.adServingType = 1
      }else{
        this.launch2_one = false
        this.launch2_price = true
        this.addParams.adServingType = 0
      }
    },

    dataFilter(val) {
        this.targetNew = val;
        if (val) { //val存在
          this.targetNewOption = this.targetOption.filter((item) => {
            if (!!~item.appName.indexOf(val) || !!~item.appName.toUpperCase().indexOf(val.toUpperCase())) {
              return true
            }
          })
        } else { //val为空时，还原数组
          this.targetNewOption = this.targetOption;
        }
      
    },

    closeAppList(i){
      this.targetNewData.splice(i,1)
    },

    //change动态出价
    changeTrendsPrice(val){
      if(val == 1){
        this.conversionShow = true
        this.getChangeType()
        this.addParams.dynamicPrice = 1 //开启
        this.getChangeType_cpd()
      } else if(val == 0) {
        this.conversionShow = false
        this.addParams.dynamicPrice = 0 //关闭
        this.addParams.ocpcType = ''
      }
    },

    //转化类型
    getChangeType(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=OCPC_TYPE").then(res=> {
        if(res.data.code == 200){  
          that.conversionOption = []        
          res.data.content.forEach(function(e,i){
            if(e.id != 4){
              that.conversionOption.push(e)
            }
          })
          if(res.data.content.length != 0){
            // that.sizeForm.conversion = res.data.content[0].name
            // that.addParams.ocpcType = res.data.content[0].id
            // that.ocpcTYPE = res.data.content[0].id
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    getChangeType_cpd(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&extensionFlow="+that.extensionFlow+"&type=DYNAMIC_PRICE").then(res=> {
        if(res.data.code == 200){
          that.conversionOption_cpd = res.data.content
          if(res.data.content.length != 0){
            that.sizeForm.conversion_cpd = res.data.content[0].name
            that.addParams.ocpcType = res.data.content[0].id
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //各个出价的价格
    kindOfPrice(){
      let that = this
      let ownerId = that.ownerId
      if(that.ocpcTYPE == ''){
        that.ocpcTYPE = 0
      }
      $http.axios.get("/api/advertisement/preaddgroup/groupPrice?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&ocpcType="+that.ocpcTYPE).then(res=> {
        if(res.data.code == 0){
          that.kindOfPrices = res.data.data
          that.lowPrice = "最低出价"+res.data.data.floorBid/100+"元"
          that.zhcjPrice = "最低出价"+res.data.data.oFloorBid/100+"元"
          that.gensuilower = "最低出价"+res.data.data.fFloorBid/100+"元"
          that.lowPriceShow = res.data.data.floorBid/100 //基础出价最低
          that.gslowPriceShow = res.data.data.fFloorBid/100 //跟随出价最低
          that.zhlowPriceShow = res.data.data.oFloorBid/100 //转化出价最低
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     //各个落地页
     kindOfPage(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=PAGE_TYPE&extensionFlow="+that.extensionFlow).then(res=> {
        if(res.data.code == 200){
          that.landingPageOption = res.data.content
          that.landingPageOption2 = res.data.content
          that.netPageOption = res.data.content
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change落地页类型
    changeLandingPage(val){
      console.log(val)
      this.pageTypeParams = val
      this.addParams.pageType = Number(val)
      // if(this.landingPages == false){
      //   this.addParams.pageType = 9 //落地页为应用落地页时为9
      // }
      if(val == 9){
        this.landingSecond = '默认模版'
        this.addParams.pageId = 0
      }
      this.getLandingPage()
    },

    changeNet(val){
      if(val == 0){
        this.netLinkShow = true
        this.netLinkShowTwo = false
        this.addParams.pageUrl = this.netLink
      }else{
        this.netLinkShowTwo = true
        this.netLinkShow = false
      }
      this.pageTypeParams = val
      this.getLandingPage()
      
    },

    //推广落地页的后边联动下拉框
    getLandingPage(){
      let that = this
      let ownerId = that.ownerId
      that.pageTypeParams = that.editData.pageType
      $http.axios.get("/api/advertisement/preaddgroup/sparkPageNewlist?ownerId="+ownerId+"&pageType="+that.pageTypeParams).then(res=> {
        if(res.data.code == 0){
          that.landingSecondOption2 = res.data.data
          that.netPageSecondOption = res.data.data
          that.landingSecondOption2.forEach(function(e,i){
            if(e.pageId == that.editData.pageId){
              that.landingSecond = e.pageName
            }
          })
          that.netPageSecondOption.forEach(function(e,i){
            if(e.pageId == that.editData.pageId){
              that.landingSecond = e.pageName
            }
          })
          if(res.data.data == []){
            that.landingSecondOption = that.landingSecondOption
          }else{
            that.landingSecondOption = res.data.data
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //推广落地页的模板接口
    getLandingMode(){
      let that = this
      let ownerId = that.ownerId
      let extensionFlow = that.extensionFlow
      let flowScene = that.changeFlowScene
      $http.axios.get("/api/advertisement/preaddgroup/h5Templatelist?ownerId="+ownerId+"&extensionFlow="+extensionFlow+"&flowScene="+flowScene).then(res=> {
        if(res.data.code == 0){
          that.landingSecondOption3 = res.data.data
          that.landingSecondOption3.forEach(function(e,i){
            console.log(e)
            if(e.h5Id == that.editData.pageId){
              that.landingSecond = e.h5Name
            }
          })
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //不同场景change
    changeLandingPage2(val){
      this.openClose = false
      this.addParams.pageType = Number(val)
      this.pageTypeParams = val
      if(val == 4){//萤火虫建站
        this.landingSecond = ''
        this.optionTwo = false
        this.optionOne = true
        this.getLandingPage()
      } else if(val == 11){//萤火虫模板
        this.landingSecond = ''
        this.optionTwo = true
        this.optionOne = false
        this.getLandingMode()
      }else if(val == 12){//互动落地页
        this.landingSecond = ''
        this.optionTwo = false
        this.optionOne = true
        this.getLandingPage()
      }
    },
    changeNewPage(val){
      console.log(val)
      this.openClose = true
      this.addParams.pageId = val.h5Id
      this.addParams.pageUrl = val.h5Url
    },
    changeNewPage11(val){
      // this.addParams.pageId = Number(val.pageId)//之前是不传的，现在待确认
      this.addParams.pageUrl = val.pageUrl
    },
    changeNetChildren(val){
      this.addParams.pageUrl = val.pageUrl
    },
    changeopenOrclose(val){
      this.addParams.autoOpenFlag = val
    },

    //智能拓量开启关闭
    changeZntl(val){
      console.log(this.sizeForm.brainPower)
      this.addParams.smartExpandType = Number(val)
    },

    //change排期日期
    changeDateSlot(val){
      console.log(val)
      if(val == null){
        this.addParams.dayLimit == 0
      }else{
        this.addParams.dayLimit == 1
        this.addParams.beginTime = val[0] //开始日期
        this.addParams.endTime = val[1] //结束日期
      } 
    },

    //出价方式
    priceTypeChange(val){
      this.priceba = val
      // if(this.extensionFlow == 3&&this.changeFlowScene == 11&&this.priceba == 5){
      //   this.deepZh = true
      // }else{
      //   this.deepZh = false
      // }
      this.addParams.billingType = Number(val) //出价方式
      if(val == 1){
        this.launch2_price = true
      }else{
        this.launch2_price = false
        this.addParams.dynamicPrice = 2
      }
      if(val == 5){
        this.getChangeType()
        this.typePriceShow = true
        this.launchPrice = false
      }else{
        this.typePriceShow = false
        this.launchPrice = true
      }
    },

    //转化类型
    changeZHType(val){
      this.addParams.ocpcType = val
      this.ocpcTYPE = val
    },

    changeZHTypeNew(val){
      this.addParams.ocpcType = val
      this.ocpcTYPE = val
      if(this.extensionType == 2&&this.extensionFlow == 3&&this.changeFlowScene == 11 && val == 2){
        this.deepZh = true
      }else{
        this.deepZh = false
      }
    },
    submitFn(){
      let that = this  
      // that.onSubmit()   
      if(that.showResource == true){
        if(that.$refs.deriectRefs.xchange == 2){
          that.$refs.deriectRefs.submitDirection()
        }else if(that.$refs.deriectRefs.xchange == 1){
          that.addParams.targetId = that.$refs.deriectRefs.xtargetId
          that.onSubmit(that.$refs.deriectRefs.xtargetId)
        }else{
          that.onSubmit()
        }
      }else{
        that.onSubmit()
      }
      
    },
    //保存
    onSubmit(targetId) {
      // console.log(this.$refs.timeStrArray.timeContent)
      //关键词
      let that = this
      if(that.keyWords == true){
        let addTableDatas = this.$refs.keyWordRef.addTableData
        let arr = []
        addTableDatas.forEach(function(e,i){
          console.log(e)
          if(e.type == 'ggc'){
            arr.push(
              {
                // categoryId: '',
                keyword: e.bidword,
                matchType: e.matchType,
                price: e.exprice*100,
                source: '1'
              }
            )
          }else if(e.type == 'lmcb'){
            arr.push(
              {
                categoryId: e.kwId,
                keyword: e.kwName,
                matchType: '3',
                price: e.exprice*100,
                source: '2'
              }
            )
          }else if(e.type == 'sdtj'){
            arr.push(
              {
                // categoryId: e.kwId,
                keyword: e.bidword1,
                matchType: e.matchType,
                price: e.exprice*100,
                source: '5'
              }
            )
          }else{
            arr.push(
              {
                categoryId: e.categoryId,
                keyword: e.keyword,
                matchType: e.matchType,
                price: e.price,
                source: e.source
              }
            )
          }
        })
        that.addParams.adSearchPremiumKwSaveDTOList = arr
      }
      
      //每个表单必填
      that.addParams.planId = that.planId
      that.addParams.adGroupId = Number(that.adGroupId)
      that.addParams.ownerId = that.ownerId
      that.addParams.userId = that.userId
      that.addParams.userName = that.userName
      that.addParams.deptId = that.deptId,
      that.addParams.adGroupName = that.sizeForm.adTeamName //广告组名称
      //本表单必填（应用推广-自有流量-精选推荐-普通投放）
      // that.addParams.extensionFlow = that.changeExtensionFlow //推广流量
      // that.addParams.flowScene = that.changeFlowScene //流量场景
      if(that.sizeForm.scheduling == null||that.sizeForm.scheduling[0] == 0&&that.sizeForm.scheduling[1] == 0){
        that.addParams.dayLimit = 0
      }else{
        that.addParams.dayLimit = 1
      }
      // that.addParams.dayLimit = 0 //0-不限制推广日期1-限制推广日期，当为1时beginTime和endTime必传
      if(that.timeUnitShow == true){
        let timeString = that.$refs.timeStrArray.timeContent
        let slot = {}
        timeString.forEach(function(e,i){
          let week=i+1
          slot[week]=String(e.arr)
        })
        that.addParams.timeSet = JSON.stringify(slot) //时间段设置
      }
      if(that.launchMode == false){
        that.addParams.price = ''
      }
      that.addParams.price = that.sizeForm.basePrice*100 //基础出价
      // that.addParams.extensionType = that.extensionType, //推广目标
      that.addParams.followMaxPrice = that.sizeForm.highestBid*100 //跟随最高出价
      that.addParams.ocpcPrice = that.sizeForm.zhPrice*100
      that.addParams.deepOcpcPrice = that.sizeForm.deepzhPrice*100//深度转化出价
      that.addParams.targetId = targetId || ''
      // that.addParams.targetId = that.editData.targetId || targetId
      that.addParams.deepUrl = that.deepLinkValue
      $http.axios.post("/api/advertisement/adGroup/v1/editGroup",that.addParams).then(res=> {
      // $http.axios.post("/guanjia/adGroup/v1/editGroup",that.addParams).then(res=> {
        if(res.data.code == 200&&res.data.content.ret == 0){
          console.log(res)
          that.$router.push({
            path: '/plan-management/advertisingPlan',
            query: {
              id: that.ownerId,
              name:that.ownerName,
            }
          })
          //let creatInfo
          // that.newTeamVue.newPlanVue.creatShowIs = true
          // creatInfo={
          //   "planId":res.data.content.planid || that.planId,//计划ID
          //   "planName":res.data.content.planname,//计划名称
          //   "adGroupId":res.data.content.adgroupid,//广告组ID
          //   "adGroupName":res.data.content.adgroupname,//广告组名称
          //   "extensionType":res.data.content.extensiontype,//推广目的ID
          //   // "extensionTypeName":res.data.content.extensionTypeName,//推广名称
          //   "flowScene":res.data.content.flowscene,//
          //   // "flowSceneName":res.data.content.flowSceneName,//
          //   "extensionFlow":res.data.content.extensionflow,//
          //   // "extensionFlowName":res.data.content.extensionFlowName,//
          //   }


            // that.creatSucess = false
            // // that.newTeamVue.newPlanVue.creatInfo = creatInfo
            // that.$message({
            //   message: 'success',
            //   type: 'success'
            // });

        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      that.goPlanPage()
    },

    checkMoneyM (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    },

    //基础出价判断
    changeMoney(){
      if(this.sizeForm.basePrice < this.lowPriceShow){
        this.$message({
          message: '不能低于'+this.lowPriceShow,
          type: 'warning'
        })
        this.sizeForm.basePrice = ''
      }
    },
    //跟随出价判断
    changegsMoney(){
      if(this.sizeForm.highestBid < this.gslowPriceShow){
        this.$message({
          message: '不能低于'+this.gslowPriceShow,
          type: 'warning'
        })
        this.sizeForm.highestBid = ''
      }
    },
    //转化出价判断
    changezhMoney(){
      if(this.sizeForm.zhPrice < this.zhlowPriceShow){
        this.$message({
          message: '不能低于'+this.zhlowPriceShow,
          type: 'warning'
        })
        this.sizeForm.zhPrice = ''
      }
    },

    //深度转化类型
    getDeepsionOption(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&extensionFlow="+that.extensionFlow+"&type=DEEP_OCPC_TYPE").then(res=> {
        if(res.data.code == 200){
          that.deepsionOption = res.data.content
          that.deepsionOption.forEach(function(e,i){
            if(e.id == that.editData.deepOcpcType){
              that.sizeForm.deepZhType = e.name
            }
          })
          if(res.data.content.length!=0){
            that.sizeForm.deepZhType = res.data.content[0].name
            that.addParams.deepOcpcType = res.data.content[0].id
          }
          
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //深度转化
    changeDeepZhValue(val){
      this.getDeepsionOption()
      if(val == 2){
        this.deepShow = true
      }else{
        this.deepShow = false
      }
      this.addParams.ocpcOptmType = val
    },

    //深度转化类型
    changeDeeplx(val){
      this.addParams.deepOcpcType = val
    }




  }
}
</script>
<style lang="scss" scoped>
  .el-radio-group .el-radio--medium.is-bordered{
    padding: 0 10px;
  }
 .el-radio-group .el-radio--mini.is-bordered{
    padding:0 8px;
    height: auto;
    line-height: 30px;
  }
  .el-form-item--mini .el-form-item__content{
    /* 需要跟这个宽度一致label-width="90px" */
    float: left;/* 对dayLimitDialog页面有影响 */
    margin-left: 0;
  }
  .el-input{
    width: auto;
  }
  .partTitle{
    display:block;
    font-size: 18px;
    margin-bottom:20px;
  }
  .title{
    display: block;
    margin-bottom:15px;
    font-size: 16px;
  }
  .targetBox{
    margin-top:15px;
  }
  .targetBox_img,.targetBox_name{
    display:inline-block;
    vertical-align:middle;
    margin-right:15px;
  }
  .targetBox_img{
    width:50px;
    height:50px;
  }
  .targetBox_img img{
    width:100%;
  }
  .launchBox{
    width:50%;
    padding:30px 20px;
    border:1px solid #ddd;
    border-radius:5px;
    margin-top:20px;
  }
  .launchBox li {
    position: relative;
    display:inline-block;
    vertical-align:middle;
    padding:10px 20px;
  }
  .launchBox li .closeApp{
    position:absolute;
    top:-10px;
    right:0;
    font-style:normal;
    font-size:10px;
    width:14px;
    height:14px;
    border-radius:50%;
    border:1px solid #ddd;
    color:#ddd;
    text-align:center;
    line-height:14px;
    cursor: pointer;
  }
  .newtransfer{
    margin:30px 0 10px;
  }
  .groupTitle{
    font-size:18px;
    font-weight:700;
  }
  .groupStyle{
    background:#fff;
    padding:30px;
  }
</style>