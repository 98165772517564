import ProductList from '@/views/operation/ProductList';
import AccessList from '@/views/operation/AccessList';
import RoleList from '@/views/operation/RoleList'
export default {
  routes: [
    {
      path: 'product-list',
      name: 'OperationAccess.ProductList',
      component: ProductList,
    },
    {
      path: 'access-list',
      name: 'OperationAccess.AccessList',
      component: AccessList,
    },
    {
      path: 'role-list',
      name: 'OperationAccess.RoleList',
      component: RoleList,
    },
  ],
};
