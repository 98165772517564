import Page401 from '../components/status-page/401';
import Page404 from '../components/status-page/404';
import Page500 from '../components/status-page/500';

/**
 * 通用router
 */
export let CommonRouter = [
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404',
    component: Page404
  },
  {
    path: '/401',
    name: '401',
    component: Page401
  },
  {
    path: '/500',
    name: '500',
    component: Page500
  }
];
