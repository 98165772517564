<template>
    <div class="authority common-page">
      <!-- <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/plan-management/adPlanApple' }">广告系列</el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
       <PocCardTable>
         <!-- 修改广告系列 -->
        <template slot="headerTitle" v-if="showCreatePlan">
          <div class='hl_header'>
            <div class='hl_breadCrumb hl_headerLeft'>
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
                <el-breadcrumb-item >广告系列</el-breadcrumb-item>
                <el-breadcrumb-item >修改广告系列</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
         
          <div class="createAll">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
              <h2 class="createTitle createAdverts">
                <span>修改广告系列</span>
              </h2>
              <el-form-item label="选取一个App" prop="appName">
                <el-select
                  v-model="ruleForm.appName"
                  filterable
                  remote
                  reserve-keyword
                  :disabled="true"
                  placeholder="请输入AppID或者App名称查找"
                  :remote-method="remoteMethod"
                  :loading="loading">
                  <el-option
                    v-for="(item,i) in appDetail"
                    :key="i"
                    :label="item.appName"
                    :value="item.adamId">
                    <span style="float: left">{{ item.appName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.developerName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择广告投放位置" prop="adPlanPosition">
                <el-select v-model="ruleForm.adPlanPosition" :disabled="true" placeholder="请选择广告系列组" @change="changePosition">
                  <el-option
                    v-for="(item,i) in adPlanPositionOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择国家/地区" prop="adPlanCountry">
                <el-select v-model="ruleForm.adPlanCountry" :disabled="true" placeholder="请选择选择国家/地区">
                  <el-option
                    v-for="(item,i) in adPlanCountryOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="广告系列名称" prop="adPlanName">
                <el-input v-model="ruleForm.adPlanName" placeholder="请输入广告系列名称"></el-input>
              </el-form-item>
              <!-- <el-form-item label="预算" prop="">
                <el-radio v-model="bdgetRadio" label="1" @change="changeEAndz">每日和生命周期</el-radio>
                <el-radio v-model="bdgetRadio" label="2" @change="changeE">每日</el-radio>
              </el-form-item> -->
              <div class="budgetOrder">
                <span>预算与订单</span>
              </div>
              <el-form-item label="每日预算" prop="adPlanBudget">
                <div class="adPlanBudgetDiv">
                  <!-- <span>每日</span> -->
                  <el-input v-model="ruleForm.adPlanEveryBudget" ></el-input>
                </div>
                <!-- <div class="adPlanBudgetDiv" v-if="everyDayHide">
                  <span>生命周期</span>
                  <el-input v-model="ruleForm.adPlanBudget" ></el-input>
                </div> -->
              </el-form-item>
              <el-form-item label="结算选项" prop="adPlanSettlement">
                <el-radio v-model="ruleForm.adPlanSettlement" :disabled="true" label="1">标准账单</el-radio>
              </el-form-item>
              <el-form-item label="广告主或产品" prop="adPlanProduct">
                <el-input v-model="ruleForm.adPlanProduct" placeholder="请输入广告主或产品"></el-input>
              </el-form-item>
              <el-form-item label="订单号" prop="adPlanOrder">
                <el-input v-model="ruleForm.adPlanOrder" placeholder="AppID(广告系列组)"></el-input>
              </el-form-item>
              <div class="budgetOrder">
                <span>联系方式</span>
              </div>
              <el-form-item label="主要联系人姓名" prop="adPlanContactName">
                <el-input v-model="ruleForm.adPlanContactName" placeholder="请输入主要联系人姓名"></el-input>
              </el-form-item>
              <el-form-item label="主要联系人电子邮箱" prop="adPlanContactEmail">
                <el-input v-model="ruleForm.adPlanContactEmail" placeholder="请输入主要联系人电子邮箱"></el-input>
              </el-form-item>
              <el-form-item label="结算联系人电子邮箱" prop="adPlanSetContactEmail">
                <el-input v-model="ruleForm.adPlanSetContactEmail" placeholder="请输入结算联系人电子邮箱"></el-input>
              </el-form-item>
              <div class="budgetOrder">
                <span>日期设置</span>
              </div>
              <el-form-item label="开始日期" prop="setStartDateTime">
                <el-date-picker
                  v-model="ruleForm.setStartDateTime"
                  type="datetime"
                  :disabled="true"
                  placeholder="请选择开始日期时间"
                  align="right"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束日期（可选）" prop="">
                <el-date-picker
                  v-model="ruleForm.setEndDateTime"
                  type="datetime"
                  placeholder="请选择结束日期时间"
                  align="right"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>

              <!-- <el-form-item label="广告系列否定关键词(可选)" prop="" v-if="changePosition2">
                <el-button type="primary" plain size="small" class="negKeywords" @click="addNegKeyword">
                  <i class="el-icon-plus"></i>添加
                </el-button>
                <div class="negWrap" v-if="negWrapShow">
                  已添加{{negKeywordNum}}个否定关键词
                </div>
              </el-form-item> -->
              <!-- <el-form-item label="广告" prop="">
                <el-radio v-model="advertRadio" label="1" @change="changeAdDefault">默认产品页面</el-radio>
                <el-radio v-model="advertRadio" v-if="changePosition2" label="2" @change="changeAdCustom">自定义</el-radio>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" class="iconTipContent">
                    使用 App Store 产品页面中的元数据和广告素材资源自动为你创建默认图片或文字广告。带图片的广告使用前几个截屏和 app 预览。 除了任何广告素材集以外，默认广告随时会投放，以确保获得最佳效果。
                  </div>
                  <i class="el-icon-question iconTip"></i>
                </el-tooltip>
                <div class="showAdCustom" v-if="showCustom">
                  <h3>没有可以使用的自定产品页面</h3>
                  <p>要创建广告变体，请首先在 App Store Connect 中创建自定产品页面。</p>
                </div>
                <div class="showAdCustom" v-if="showAdCustom">
                  <h3>1个自定产品页面可以使用</h3>
                  <p>点击"创建广告"按钮，即可从你的自定产品页面选项中进行选择，并查看广告预览</p>
                  <el-button type="primary" size="middle" @click="createAdvert">创建广告</el-button>
                </div>
              </el-form-item> -->
            </el-form>

            <!-- 创建广告 -->
            <el-dialog
              title="创建广告"
              :visible.sync="createAdVisible"
              width="70%">
              <el-form :model="createAdForm" ref="createAdForm" :rule="createAdRule" class="createAdvertStyle">
                <el-form-item label="">
                  <el-input v-model="createAdForm.adName" placeholder="请输入广告名称"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model="createAdForm.adSelect" placeholder="搜索"></el-input>
                  <div class="advertSelect"></div>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="createAdVisible = false">取 消</el-button>
                <el-button type="primary" @click="createAdVisible = false">确 定</el-button>
              </div>
            </el-dialog>

            <!-- 广告系列添加否定关键词弹框 -->
            <el-dialog 
              title="添加否定关键词" 
              width="50%"
              :visible.sync="dialogFormVisible">
              <div class="negKeywordWrap">
                <span class="addNegKeywords">已添加（<i>{{negKeywordNum}}</i>）</span>
                <div class="enterKeywords">
                  <el-input v-model="enterKeywords" class="enterKeywordsInput" placeholder="添加否定关键词，多词用逗号分隔"></el-input>
                  <el-button type="primary" class="enterKeywordsBtn" size="mini" @click="addNegKw">添加</el-button>
                </div>
                <div class="batchBtns">
                  <el-button type="primary" size="mini" @click="batchMatchType">批量修改匹配类型</el-button>
                  <el-button type="primary" size="mini" @click="batchDel">批量删除</el-button>
                </div>
                <!-- 批量修改匹配类型 -->
                <el-dialog 
                  title="批量修改匹配类型" 
                  width="30%"
                  append-to-body
                  :visible.sync="dialogEditMatchType">
                  <div class="editTypeChoose">
                    <el-select v-model="batchMateType" placeholder="请选择匹配类型">
                      <el-option
                        v-for="(item,i) in mateTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="editTypeBtns">
                    <el-button @click="editMatchTypeFalse">取 消</el-button>
                    <el-button type="primary" @click="editMatchType">确 定</el-button>
                  </div>
                </el-dialog>
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  class="inner"
                  @selection-change="handleSelectionChange"
                  tooltip-effect="dark">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="text"
                    label="否定关键词">
                  </el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="匹配类型"
                    width="150">
                    <template slot-scope="scope" >
                      <el-select v-model="scope.row.matchType">
                        <el-option
                          v-for="(item,i) in mateTypeOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row)">
                        <template #reference>
                          <i class="el-icon-delete-solid"></i>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="negKeywordsLast">
                  已过滤重复否定关键词 <i>{{negKeywordsLast01}}</i> 个
                  过滤特殊字符否定关键词 <i>{{negKeywordsLast02}}</i> 个
                </div> -->
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button size="middle" @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" size="middle" @click="negKeyWordSure">确 定</el-button>
              </div>
            </el-dialog>

            <!-- <div class="createAdvertBtns">
              <el-button size="middle" @click="cancelCreateAdvert">取 消</el-button>
              <el-button type="primary" size="middle" @click="editPlan">保存</el-button>
            </div> -->
            <div class="createAdvertBtns">
              <el-button class="newDialogCancel" type="primary" size="middle" @click="cancelCreateAdvert">取消保存</el-button>
              <el-button class="newDialog" type="primary" size="middle" @click="editPlan">确定保存</el-button>
            </div>
          </div>
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>

  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import TimeUnitApple from '@/views/auth/planManage/TimeUnitApple'

  export default {
    components:{
      TimeUnitApple
    },
    data() {
      return {
        ruleForm:{//表单
          adPlanGroup:'',//广告系列组
          appName:'',//选取一个app
          adPlanPosition:'',//投放位置
          adPlanCountry:'',//国家/地区
          adPlanName:'',//广告系列名称
          // adPlanBudget:'',//预算
          adPlanEveryBudget:'',//每日预算
          adPlanSettlement:'1',//结算选项
          adPlanProduct:'',//广告主或产品
          adPlanOrder:'',//订单号
          adPlanContactName:'',//主要联系人姓名
          adPlanContactEmail:'',//主要联系人电子邮箱
          adPlanSetContactEmail:'',//结算联系人电子邮箱
          setStartDateTime:'',//开始日期
          setEndDateTime:'',//结束日期
        },
        rules:{//验证规则
          adPlanGroup: [
            { required: true, message: '请选择广告组系列', trigger: 'change' }
          ],
          appName: [
            { required: true, message: '请输入AppID或者App名称', trigger: 'blur' },
          ],
          adPlanPosition: [
            { required: true, message: '请选择广告投放位置', trigger: 'change' }
          ],
          adPlanCountry: [
            { required: true, message: '请选择国家/地区', trigger: 'change' }
          ],
          adPlanName: [
            { required: true, message: '请输入广告系列名称', trigger: 'blur' },
          ],
          // adPlanBudget: [
          //   { required: true, message: '总预算不能为空', trigger: 'blur' },
          //   { pattern: /^\d+(\.\d+)?$/, required: true, message: "总预算只能是数字", trigger: "blur" },
          //   { 
          //     pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
          //     required: true,
          //     message: "总预算至少为¥0.01 且不超过¥140,000,000.00",
          //     trigger: "blur" 
          //   }
          // ],
          adPlanEveryBudget: [
            { required: true, message: '总预算不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "总预算只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "总预算至少为¥0.01 且不超过总预算",
              trigger: "blur" 
            }
          ],
          adPlanProduct: [
            { required: true, message: '请输入广告主或产品', trigger: 'blur' },
          ],
          adPlanOrder: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
          ],
          adPlanContactName: [
            { required: true, message: '请输入主要联系人姓名', trigger: 'blur' },
          ],
          adPlanContactEmail: [
            { pattern:  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              required: true, message: '请输入主要联系人电子邮箱', trigger: 'blur' },
          ],
          adPlanSetContactEmail: [
            { pattern:  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              required: true, message: '请输入结算联系人电子邮箱', trigger: 'blur' },
          ],
          setStartDateTime:[
            { required: true, message: '请选择开始时间', trigger: 'change' },
          ],
        },
        adPlanPositionOption:[{
          // value:'SEARCH ',
          value:'APPSTORE_SEARCH_RESULTS',
          label:'搜索结果'
        },{
          // value:'DISPLAY',
          value:'APPSTORE_SEARCH_TAB',
          label:'"搜索"标签'
        }],//广告投放位置
        adPlanCountryOption:[{
          value:'CN',
          label:'中国大陆'
        }],//国家/地区
        dialogFormVisible:false,//添加否定关键词弹框
        negKeywordNum:0,//添加否定关键词数量
        enterKeywords:'',//添加否定关键词-输入框
        tableData:[],//添加否定关键词-表格
        mateType:'',//匹配类型
        batchMateType:'',//批量匹配类型
        mateTypeOption:[{
          value:'BROAD',
          label:'广泛匹配'
        },{
          value:'EXACT',
          label:'完全匹配'
        }],
        negKeywordsTable:'',
        negKeywordsLast01:0,
        negKeywordsLast02:0,
        materialIdsPara:[],//批量选择
        dialogEditMatchType:false,//批量修改匹配类型
        batchMateTypeAll:[],//选择否定关键词集合
        negKw:[],//广告系列选中的否定关键词
        advertRadio:'1',//广告计划的广告单选
        showCustom:false,//自定义的没有操作
        showAdCustom:false,//自定义的有创建广告的操作
        createAdVisible:false,//创建广告弹框
        createAdForm:{//创建广告表单
          adName:'',//广告名称
          adSelect:'',//搜索
        },
        createAdRule:{//创建广告验证

        },
        showCreatePlan:true,//新建广告系列
        //广告组
        showCreateTeam:false,//新建广告组
        AdPlanAppName:'',//新建广告组中显示的投放app
        haveBudget:'',//新建广告组中显示的预算
        haveEveryBudget:'',//新建广告组中显示的每日预算
        haveNegKeyword:'',//新建广告组中显示的否定关键词
        teamRuleForm:{//新建广告组的表单
          groupName:'',//广告组名称
          groupDefault:'',//默认最高出价
          groupCost:'',//目标每次获客成本
          groupMate:false,//自动将我的广告与相关搜索相匹配
          audienceRadio:'1',//广告组选择受众
          groupDevice:'',//设备
          customType:'',//客户类型
          sexType:'',//性别
          ageSlot:'',//年龄段
          groupPosition:[],//地理位置
          setStartDateTime:'',//开始时间
          setEndDateTime:'',//结束时间
        },
        teamRules:{//新建广告组的验证规则
          groupName: [
            { required: true, message: '请输入词组名称', trigger: 'blur' },
          ],
          groupDefault:[
            { required: true, message: '默认最高出价不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "默认最高出价只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "默认点击最大出价最少¥0.01 且不超过每日预算",
              trigger: "blur" 
            }
          ],
          groupCost:[
            // { required: true, message: '默认最高出价不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "目标每次获客成本只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "最大出价至少为¥0.01",
              trigger: "blur" 
            }
          ],
        },
        dialogFormTeamVisible:false,//广告组的否定关键词
        adAudience:true,//选择特定受众
        adAllAudience:false,//触达所有符合条件的用户
        groupDeviceOption:[{//广告组设备
          value:1,
          label:'iPhone'
        }],
        customTypeOption:[{
          value:1,
          label:'不限'
        },{
          value:2,
          label:'新用户'
        }],
        sexTypeOption:[{
          value:1,
          label:'不限'
        },{
          value:2,
          label:'男'
        },{
          value:3,
          label:'女'
        }],
        ageSlotOption:[
          {value:1,label:'不限'},{value:2,label:'18'},{value:3,label:'19'},{value:4,label:'20'},{value:5,label:'21'},
          {value:6,label:'22'},{value:7,label:'23'},{value:8,label:'24'},{value:9,label:'25'},{value:10,label:'26'},
          {value:11,label:'27'},{value:12,label:'28'},{value:13,label:'29'},{value:14,label:'30'},{value:15,label:'31'},
          {value:16,label:'32'},{value:17,label:'33'},{value:18,label:'34'},{value:19,label:'35'},{value:20,label:'36'},
          {value:21,label:'37'},{value:22,label:'38'},{value:23,label:'39'},{value:24,label:'40'},{value:25,label:'41'},
          {value:26,label:'42'},{value:27,label:'43'},{value:28,label:'44'},{value:29,label:'45'},{value:30,label:'46'},
          {value:31,label:'47'},{value:32,label:'48'},{value:33,label:'49'},{value:34,label:'50'},{value:35,label:'51'},
          {value:36,label:'52'},{value:37,label:'53'},{value:38,label:'54'},{value:39,label:'55'},{value:40,label:'56'},
          {value:41,label:'57'},{value:42,label:'58'},{value:43,label:'59'},{value:44,label:'60'},{value:45,label:'61'},
          {value:46,label:'62'},{value:47,label:'63'},{value:48,label:'64'}
        ],
        groupPositionOption:[{
          value:1,
          label:'1'
        },{
          value:2,
          label:'2'
        }],
        groupPosition:'',//选中的地理位置展示
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        checkedEndDateTime:false,//是否选择结束时间
        setEndDateTimeShow:false,//结束时间是否显示
        teamKeywordsVisible:false,//广告组的添加关键词
        keywordNum:0,//广告组添加关键词左侧的推荐数
        teamKeywordNum:0,//广告组添加关键词右侧的添加数
        residueKeywordNum:5000,//广告组添加关键词右侧的剩余数
        kwLefttableData:[],//广告组添加关键词左侧的推荐表格
        kwRighttableData:[],//广告组添加关键词右侧的添加表格
        recommendKeywords:'',//搜索推荐关键词
        addKeywords:'',//添加关键词
        dialogEditPrice:false,//批量修改默认最高出价
        editPrice:'',//批量修改默认最高出价的价格
        batchkwInfo:[],//关键词批量修改的时候选择行的集合
        dialogEditkwMatchType:false,//关键词批量修改匹配类型
        batchkwMateType:'',//关键词批量修改匹配类型
        sureKw:[],//广告组选中的关键词
        changePosition2:true,//投放位置为搜索标签的时候隐藏
        userId:'',
        userName:'',
        deptId:'',
        pageIndex:1,
        pageCount:10,
        ownerId:'',
        ownerName:'',
        appDetail:[],//查找app详情
        adamId:'',//appId
        negWrapShow:false,//广告系列添加了多少否定关键词
        searchResult:'',//搜索结果
        searchTab:'',//搜索标签
        loading:false,
        appList:[],
        campaignId:'',
        campaignName:'',
        adPlanDetailData:{},//回显信息
        appName:'',
        appId:'',
        bdgetRadio:'1',//修改计划系列的时候预算的每日和生命周期隐藏还是显示
        everyDayHide:true,//修改计划系列的时候预算的生命周期隐藏还是显示
        updateParams:{},


      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.campaignId = this.$route.query.campaignId
      this.campaignName = this.$route.query.campaignName
      this.appName = this.$route.query.appName
      this.appId = this.$route.query.appId
      // 7774110
      this.ownerName = this.$route.query.name

      this.mateType = 'BROAD'
      this.teamRuleForm.groupDevice = 1
      this.teamRuleForm.customType = 1
      this.teamRuleForm.sexType = 1
      this.teamRuleForm.ageSlot = 1
      this.getAdPlanDetail()//获取回显信息
      // this.getAppDetail()
    },
    
    methods: {
      //选中素材的materialIds
      chooseTableFn(visible){
        let that = this
        let sendttSelection = that.$refs.multipleTable.selection
        that.materialIdsPara = []
        sendttSelection.forEach(function(e,i){
          that.materialIdsPara.push(e.id)
        })
        if(sendttSelection.length == 0){
          that.$message({
            message: '请进行勾选',
            type: 'warning'
          });
          that[visible]=false
        }else{
          that[visible]=true
          return true
        }
      },

      //添加否定关键词-弹框显示
      addNegKeyword(){
        this.dialogFormVisible = true
      },

      //添加否定关键词弹框中的添加btn
      addNegKw(){
        let enterKeywords = [],_this = this
        enterKeywords = _this.enterKeywords.split(',')
        _this.tableData = []
        enterKeywords.forEach(function(obj,index){
          _this.tableData.push({'text':obj,'matchType':'BROAD'})
        })
        //去重
        let obj={}
        this.tableData=this.tableData.reduce((item,next)=>{
          obj[next.text]?'':obj[next.text]=true&&item.push(next)
          return item
        },[])

        _this.enterKeywords = ''
        _this.negKeywordNum =  _this.tableData.length
      },

      //添加否定关键词弹框中的单个删除
      deletePlanFn(row){
        let _this = this
        _this.tableData.forEach(function(obj,i){
          if(obj.text == row.text){
            _this.tableData.splice(i,1)
          }
          // _this.addNegKw()
        })
        _this.negKeywordNum =  _this.tableData.length
      },

      //添加否定关键词弹框中的选择表格
      handleSelectionChange(val){
        this.batchMateTypeAll = val
      },

      //添加否定关键词弹框中的批量修改匹配类型
      batchMatchType(){
        this.chooseTableFn('dialogEditMatchType')
      },

      //添加否定关键词弹框中的批量修改匹配类型-确定
      editMatchType(){
        let _this = this
        _this.batchMateTypeAll.forEach(function(val,i){
          val.matchType = _this.batchMateType
        })
        _this.dialogEditMatchType = false
        _this.batchMateType = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //添加否定关键词弹框中的批量修改匹配类型-取消
      editMatchTypeFalse(){
        this.batchMateType = ''
        this.dialogEditMatchType = false
        this.$refs.multipleTable.clearSelection()
      },

      //批量删除
      batchDel(){
        if(this.$refs.multipleTable.selection.length == 0){
          this.$message({
            message: '请进行勾选',
            type: 'warning'
          });
        }else{
          let _this = this,
          selection = this.$refs.multipleTable.selection,
          data = []
          _this.tableData.map((el, index) => {
            if (!selection.find(item => item.text == el.text)) {
                data.push(_this.tableData[index]);
            }
          })
          _this.tableData = data
        }
      },

      //否定关键词-确定
      negKeyWordSure(){
        this.negKw = this.tableData
        this.dialogFormVisible = false
        console.log(this.negKw)
        this.negWrapShow = true
      },

      //广告change自定义
      changeAdCustom(){
        this.showAdCustom = true
      },

      //广告change默认
      changeAdDefault(){
        this.showAdCustom = false
      },

      //创建广告
      createAdvert(){
        this.createAdVisible = true
      },

      //取消新建广告计划
      cancelCreateAdvert(){
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName}})
      },

      //新建广告组


      //添加否定关键词弹框中的添加btn
      addKw(){
        let addKeywords = [],_this = this
        addKeywords = _this.addKeywords.split(',')
        addKeywords.forEach(function(obj,index){
          _this.kwRighttableData.push({'text':obj,'matchType':'BROAD','chujia':1})
        })
        //去重
        let obj={}
        this.kwRighttableData=this.kwRighttableData.reduce((item,next)=>{
          obj[next.text]?'':obj[next.text]=true&&item.push(next)
          return item
        },[])

        _this.addKeywords = ''
        _this.teamKeywordNum =  _this.kwRighttableData.length
        _this.residueKeywordNum = 5000-(_this.kwRighttableData.length)
      },

      //change批量修改关键词的匹配类型
      batchKwMatchType(){
        this.chooseTableFn('dialogEditkwMatchType')
      },

      //关键词的批量删除
      batchKwDel(){
        if(this.$refs.multipleTable.selection.length == 0){
          this.$message({
            message: '请进行勾选',
            type: 'warning'
          });
        }else{
          let _this = this,
          selection = this.$refs.multipleTable.selection,
          data = []
          _this.kwRighttableData.map((el, index) => {
            if (!selection.find(item => item.text == el.text)) {
                data.push(_this.kwRighttableData[index]);
            }
          })
          _this.kwRighttableData = data
        }
      },

      //批量修改默认最高出价
      batchKwPrice(){
        this.chooseTableFn('dialogEditPrice')
      },

      //change关键词的表格
      handleSelectionChangekw(val){
        this.batchkwInfo = val
      },

      //批量修改默认出价
      editPriceSure(){
        let _this = this
        _this.batchkwInfo.forEach(function(obj,index){
          obj.chujia = _this.editPrice
        })
        _this.dialogEditPrice = false
        _this.editPrice = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //批量修改默认最高值取消
      editPriceFalse(){
        this.dialogEditPrice = false
        this.editPrice = ''
        this.$refs.multipleTable.clearSelection()
      },

      //批量修改关键词的匹配类型
      editkwMatchType(){
        let _this = this
        _this.batchkwInfo.forEach(function(val,i){
          val.matchType = _this.batchkwMateType
        })
        _this.dialogEditkwMatchType = false
        _this.batchkwMateType = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //批量修改关键词的匹配类型
      editkwMatchTypeFalse(){
        this.batchkwMateType = ''
        this.dialogEditkwMatchType = false
        this.$refs.multipleTable.clearSelection()
      },

      //添加关键词弹框中的单个删除
      deleteTeamFn(row){
        let _this = this
        _this.kwRighttableData.forEach(function(obj,i){
          if(obj.text == row.text){
            console.log(row.text)
            _this.kwRighttableData.splice(i,1)
          }
        })
        _this.teamKeywordNum =  _this.kwRighttableData.length
      },

      //广告组中的关键词确定
      keyWordSure(){
        this.sureKw = this.kwRighttableData
        this.teamKeywordsVisible = false
      },

      //change投放位置的显示隐藏
      changePosition(val){
        this.searchTab = val
        if(val == 'APPSTORE_SEARCH_RESULTS'){
          this.changePosition2 = true
          this.searchResult = 'SEARCH'
        }else if(val == 'APPSTORE_SEARCH_TAB'){
          this.changePosition2 = false
          this.searchResult = 'DISPLAY'
        }
      },

      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.appDetail = this.appList.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.appDetail = [];
        }
      },

      //change预算
      changeEAndz(val){
        if(this.bdgetRadio == '1'){
          this.everyDayHide = true
        }else{
          this.everyDayHide = false
        }
      },
      changeE(val){
        if(this.bdgetRadio == '1'){
          this.everyDayHide = true
        }else{
          this.everyDayHide = false
        }
      },

      //回显接口
      getAdPlanDetail(){
        let _this = this
        $http.axios.get("/api/apple/adPlan/getCampaignDetail/"+_this.ownerId+"/"+_this.campaignId).then(function(response) {
          if(response.data.code == 200){
            _this.adPlanDetailData = response.data.data.data
            console.log(_this.adPlanDetailData)
            // _this.ruleForm.appName = _this.appName//app名称
            _this.ruleForm.appName = _this.appName//app名称
            _this.adPlanDetailData.supplySources.forEach(function(val,index){
              _this.ruleForm.adPlanPosition = val
            })//广告投放位置
            _this.adPlanDetailData.countriesOrRegions.forEach(function(val,index){
              _this.ruleForm.adPlanCountry = val
            })//选择国家
            _this.ruleForm.adPlanName = _this.adPlanDetailData.name//广告系列名称
            if(_this.adPlanDetailData.dailyBudgetAmount!=null){
              _this.ruleForm.adPlanEveryBudget = _this.adPlanDetailData.dailyBudgetAmount.amount//每日预算
            }else{
              _this.ruleForm.adPlanEveryBudget = ''
            }
            // if(_this.adPlanDetailData.budgetAmount!=null){
            //   _this.ruleForm.adPlanBudget = _this.adPlanDetailData.budgetAmount.amount//预算
            // }else{
            //   _this.ruleForm.adPlanBudget = ''
            // }
            _this.ruleForm.adPlanProduct = _this.adPlanDetailData.locInvoiceDetails.clientName//广告主或产品
            _this.ruleForm.adPlanOrder = _this.adPlanDetailData.locInvoiceDetails.orderNumber//订单号
            _this.ruleForm.adPlanContactName = _this.adPlanDetailData.locInvoiceDetails.buyerName//联系人
            _this.ruleForm.adPlanContactEmail = _this.adPlanDetailData.locInvoiceDetails.buyerEmail//联系人邮箱
            _this.ruleForm.adPlanSetContactEmail = _this.adPlanDetailData.locInvoiceDetails.billingContactEmail//结算联系人邮箱
            _this.ruleForm.setStartDateTime = _this.adPlanDetailData.startTime//开始时间
            _this.ruleForm.setEndDateTime = _this.adPlanDetailData.endTime//结束时间
            
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //保存修改计划系列
      editPlan(){
        let _this = this
        // if(_this.bdgetRadio == '2'){
        //   if(_this.adPlanDetailData.dailyBudgetAmount!=null){
        //     _this.updateParams.budgetAmount = {
        //       'amount':_this.adPlanDetailData.dailyBudgetAmount.amount,//预算
        //       'currency':'RMB'
        //     }
        //   }
        // }else{
        //   _this.updateParams.budgetAmount = {
        //     'amount':_this.ruleForm.adPlanBudget,//预算
        //     'currency':'RMB'
        //   }
        // }
        _this.updateParams.deptId = _this.deptId
        _this.updateParams.userId = _this.userId
        _this.updateParams.userName = _this.userName
        _this.updateParams.name = _this.ruleForm.adPlanName
        // _this.updateParams.budgetAmount = {
        //   'amount':_this.ruleForm.adPlanBudget,//预算
        //   'currency':'RMB'
        // }
        _this.updateParams.campaignId = _this.campaignId
        _this.updateParams.dailyBudgetAmount = {
          'amount':_this.ruleForm.adPlanEveryBudget,//每日预算
          'currency':'RMB'
        }
        _this.updateParams.startTime = _this.ruleForm.setStartDateTime
        _this.updateParams.endTime = _this.ruleForm.setEndDateTime
        _this.updateParams.orgId = _this.ownerId
        _this.updateParams.locInvoiceDetails = {
          'billingContactEmail':_this.ruleForm.adPlanSetContactEmail,//结算联系人电子邮箱
          'buyerEmail':_this.ruleForm.adPlanContactEmail,//主要联系人电子邮箱
          'buyerName':_this.ruleForm.adPlanContactName,//主要联系人姓名
          'clientName':_this.ruleForm.adPlanProduct,//广告主或产品
          'orderNumber':_this.ruleForm.adPlanOrder,//订单号
        }

        // this.ruleForm.adPlanBudge = ''
        // this.updateParams.budgetAmount = {}
        // params = {
        //   'deptId':_this.deptId,
        //   'userId':_this.userId,
        //   'userName':_this.userName,
        //   'name':_this.ruleForm.adPlanName,
        //   'budgetAmount':{
        //     'amount':_this.ruleForm.adPlanBudget,//预算
        //     'currency':'RMB'
        //   },
        //   'campaignId':_this.campaignId,//计划系列id
        //   'dailyBudgetAmount':{
        //     'amount':_this.ruleForm.adPlanEveryBudget,//每日预算
        //     'currency':'RMB'
        //   },
        //   'startTime':_this.ruleForm.setStartDateTime,
        //   'endTime':_this.ruleForm.setEndDateTime,
        //   'orgId':_this.ownerId,//账户id
        //   'locInvoiceDetails':{
        //     'billingContactEmail':_this.ruleForm.adPlanSetContactEmail,//结算联系人电子邮箱
        //     'buyerEmail':_this.ruleForm.adPlanContactEmail,//主要联系人电子邮箱
        //     'buyerName':_this.ruleForm.adPlanContactName,//主要联系人姓名
        //     'clientName':_this.ruleForm.adPlanProduct,//广告主或产品
        //     'orderNumber':_this.ruleForm.adPlanOrder,//订单号
        //   }
        // }

        $http.axios.post("/api/apple/adPlan/updateCampaigns",_this.updateParams).then(res=> {
          if(res.data.code==200){
            console.log(res)
            _this.$router.push({path:'/plan-management/adPlanApple', query: { id: _this.ownerId, name:_this.ownerName }})
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            }) 
          }   
        })
      }

    }
  }
  </script>
  
  <style lang="scss" scoped>
    .createTitle{
      font-size:16px;
    }
    ::v-deep .createAll .el-input__inner,::v-deep .createAll .el-input--mini{
      width:400px!important;
    }
    ::v-deep .createAll .el-form-item__label{
      width:240px!important;
      padding-left:100px;
    }
    ::v-deep .createAll .el-form-item__error{
      left:200px;
    }
    .iconTip{
      padding-left:10px;
    }
    .iconTipContent{
      width:240px;
      padding:10px;
      line-height:22px;
    }
    .negKeywords{
      width:400px;
      height:30px;
    }
    .addNegKeywords i {
      font-style: normal;
    }
    .enterKeywords{
      width:100%;
      margin:10px 0 20px;
    }
    .enterKeywords .enterKeywordsInput,.enterKeywords .enterKeywordsBtn{
      display: inline-block;
    }
    .enterKeywords .enterKeywordsInput{
      width:90%;
    }
    .enterKeywords .enterKeywordsBtn{
      width:10%;
      height:30px;
    }
    .negKeywordsLast{
      margin-top:15px;
      font-size:12px;
    }
    .negKeywordsLast i{
      font-style: normal;
    }
    ::v-deep .createAll .inner .el-input__inner, ::v-deep .createAll .inner .el-input--mini{
      width:100px!important;
    }
    .batchBtns{
      margin-bottom:15px;
    }
    .editTypeChoose{
      text-align:center;
    }
    .editTypeBtns{
      margin-top:30px;
      text-align:center;
    }
    .showAdCustom{
      width:370px;
      padding:50px 35px;
      margin:15px 0 0 200px;
      background-color: #f5f5f7;
      box-sizing: border-box;
      text-align:center;
    }
    :v-deep .createAll .createAdvertStyle .el-input__inner,::v-deep .createAll .createAdvertStyle .el-input--mini{
      width:100%!important;
    }
    .createAdvertBtns{
      padding-left:140px;
      margin-top:50px;
    }
    /* 新建广告组 */
    .haveAdPlan{
      border:1px solid #ddd;
      padding:15px;
      font-size:14px;
    }
    .haveAdPlanList02 .interval{
      padding:0 20px;
    }
    .haveAdPlanList02{
      border-top:1px solid #ddd;
      padding-top:10px;
      margin-top:10px;
    }
    ::v-deep .createAdTeamKeyword .el-input__inner,::v-deep .createAdTeamKeyword .el-input--mini{
      width:400px;
    }
    ::v-deep .createAdTeamKeyword .el-form-item__label{
      width:300px!important;
      padding-left:100px;
    }
    ::v-deep .adPlanBudgetDiv .el-input__inner,::v-deep .adPlanBudgetDiv .el-input--mini{
      width:200px!important;
    }
    ::v-deep .createAdTeamKeyword .el-form-item__error{
      left:200px;
    }
    .startEnd{
      font-size:12px;
    }
    .teamKeyword .el-icon-search{
      font-size:16px;
    }
    .teamKeywordLeft,.teamKeywordRight{
      display:inline-block;
      vertical-align:top
    }
    .teamKeywordLeft{
      width:40%;
      box-sizing: border-box;
      border-right:1px solid #ddd;
      padding-right:20px;
    }
    .teamKeywordRight{
      width:59%;
      padding-left:20px;
      box-sizing: border-box;
    }
    ::v-deep .teamKeywordLeft .el-input__inner,::v-deep .teamKeywordLeft .el-input--mini{
      width:300px;
    }
    ::v-deep .innerKw .el-input__inner,::v-deep .innerKw .el-input--mini{
      width:110px;
    }
    .adPlanBudgetDiv{
      display: inline-block;
      vertical-align: middle;
      margin-right:20px;
      /* width:30%; */
    }
    .adPlanBudgetDiv span{
      display: block;
      font-size:12px;
    }
    /*客户端-新版*/
    .createAdverts{
      padding-bottom:10px;
      border-bottom:1px solid #F1F7F5;
      margin-bottom:20px;
    }
    .createAdverts span{
      font-size:14px;
      font-weight:700;
      color:#333;
      padding-left:13px;
      border-left:3px solid #48916F;
    }
    ::v-deep .createAll .el-input--mini .el-input__inner{
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .createAdTeamKeyword .el-input--mini .el-input__inner{
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .createAdTeamKeyword .el-switch.is-checked .el-switch__core{
      background:#48916F!important;
      border:none!important;
    }
    .budgetOrder{
      width:40%;
      height: 40px;
      line-height:40px;
      background: linear-gradient(270deg, #FFFFFF 0%, #F2F8F6 100%);
      padding-left:20px;
      margin-bottom:15px;
    }
    .budgetOrder span{
      font-size:14px;
      color:#65A487;
      font-weight:400;
    }
    ::v-deep .createAll .el-form-item--mini .el-form-item__label{
      color:#333!important;
    }
    
    


  </style>
