<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/plan-management/adPlanApple' }">广告系列</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
        <template slot="headerTitle">
          <div class="createAll">
            <div class="haveAdPlan">
              
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <h2 class="createTitle">创建广告组</h2>
              <el-form-item label="选取一个App" prop="appName">
                <el-input v-model="ruleForm.appName" placeholder="请输入AppID或者App名称查找"></el-input>
              </el-form-item>
              <el-form-item label="选择广告投放位置" prop="adPlanPosition">
                <el-select v-model="ruleForm.adPlanPosition" placeholder="请选择广告系列组">
                  <el-option
                    v-for="(item,i) in adPlanPositionOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择国家/地区" prop="adPlanCountry">
                <el-select v-model="ruleForm.adPlanCountry" placeholder="请选择选择国家/地区">
                  <el-option
                    v-for="(item,i) in adPlanCountryOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="广告系列名称" prop="adPlanName">
                <el-input v-model="ruleForm.adPlanName" placeholder="请输入广告系列名称"></el-input>
              </el-form-item>
              <el-form-item label="预算" prop="adPlanBudget">
                <el-input v-model="ruleForm.adPlanBudget" placeholder="设置后只可增加，不能减少">¥</el-input>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" class="iconTipContent">
                    你愿意在广告系列上花费的金额，这是广告系列初始设置过程中必须完成的一个步骤。你可以随时增加你的预算。在你的广告系列启动日期之后，将无法减少预算。但你可以将其暂停，用较低的预算创建一个新的广告系列。 提示：包含每日预算。 你可以设置每日预算，在广告系列投放期间合理支出预算。
                  </div>
                  <i class="el-icon-question iconTip"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="每日预算" prop="adPlanEveryBudget">
                <el-input v-model="ruleForm.adPlanEveryBudget" placeholder="不能大于总预算"></el-input>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" class="iconTipContent">
                    每日预算是你希望在一个月内每天花费在广告系列上的平均金额。你的每月支出不会超过每日预算乘以一个日历月的平均天数。在有机会获得更多下载次数的日子里，你的支出可能会超出每日预算。
                    你的广告系列会持续根据每日预算金额进行每月支出，除非你暂停、移除广告系列或设置结束日期。如果你设置了结束日期，则你支出的费用不会超过广告系列总天数乘以每日预算。
                    你可以随时调整每日预算。
                  </div>
                  <i class="el-icon-question iconTip"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="结算选项" prop="adPlanSettlement">
                <el-radio v-model="ruleForm.adPlanSettlement" label="1">标准账单</el-radio>
              </el-form-item>
              <el-form-item label="广告主或产品" prop="adPlanProduct">
                <el-input v-model="ruleForm.adPlanProduct" placeholder="请输入广告主或产品"></el-input>
              </el-form-item>
              <el-form-item label="订单号" prop="adPlanOrder">
                <el-input v-model="ruleForm.adPlanOrder" placeholder="AppID(广告系列组)"></el-input>
              </el-form-item>
              <el-form-item label="主要联系人姓名" prop="adPlanContactName">
                <el-input v-model="ruleForm.adPlanContactName" placeholder="请输入主要联系人姓名"></el-input>
              </el-form-item>
              <el-form-item label="主要联系人电子邮箱" prop="adPlanContactEmail">
                <el-input v-model="ruleForm.adPlanContactEmail" placeholder="请输入主要联系人电子邮箱"></el-input>
              </el-form-item>
              <el-form-item label="结算联系人电子邮箱" prop="adPlanSetContactEmail">
                <el-input v-model="ruleForm.adPlanSetContactEmail" placeholder="请输入结算联系人电子邮箱"></el-input>
              </el-form-item>
              <el-form-item label="广告系列否定关键词(可选)" prop="">
                <el-button type="primary" plain size="small" class="negKeywords" @click="addNegKeyword">
                  <i class="el-icon-plus"></i>添加
                </el-button>
              </el-form-item>
              <el-form-item label="广告" prop="">
                <el-radio v-model="advertRadio" label="1" @change="changeAdDefault">默认产品页面</el-radio>
                <el-radio v-model="advertRadio" label="2" @change="changeAdCustom">自定义</el-radio>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" class="iconTipContent">
                    使用 App Store 产品页面中的元数据和广告素材资源自动为你创建默认图片或文字广告。带图片的广告使用前几个截屏和 app 预览。 除了任何广告素材集以外，默认广告随时会投放，以确保获得最佳效果。
                  </div>
                  <i class="el-icon-question iconTip"></i>
                </el-tooltip>
                <div class="showAdCustom" v-if="showCustom">
                  <h3>没有可以使用的自定产品页面</h3>
                  <p>要创建广告变体，请首先在 App Store Connect 中创建自定产品页面。</p>
                </div>
                <div class="showAdCustom" v-if="showAdCustom">
                  <h3>1个自定产品页面可以使用</h3>
                  <p>点击"创建广告"按钮，即可从你的自定产品页面选项中进行选择，并查看广告预览</p>
                  <el-button type="primary" size="middle" @click="createAdvert">创建广告</el-button>
                </div>
              </el-form-item>
            </el-form>

            <!-- 创建广告 -->
            <el-dialog
              title="创建广告"
              :visible.sync="createAdVisible"
              width="70%">
              <el-form :model="createAdForm" ref="createAdForm" :rule="createAdRule" class="createAdvertStyle">
                <el-form-item label="">
                  <el-input v-model="createAdForm.adName" placeholder="请输入广告名称"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model="createAdForm.adSelect" placeholder="搜索"></el-input>
                  <div class="advertSelect"></div>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="createAdVisible = false">取 消</el-button>
                <el-button type="primary" @click="createAdVisible = false">确 定</el-button>
              </div>
            </el-dialog>

            <!-- 添加否定关键词弹框 -->
            <el-dialog 
              title="添加否定关键词" 
              width="50%"
              :visible.sync="dialogFormVisible">
              <div class="negKeywordWrap">
                <span class="addNegKeywords">已添加（<i>{{negKeywordNum}}</i>）</span>
                <div class="enterKeywords">
                  <el-input v-model="enterKeywords" class="enterKeywordsInput" placeholder="添加否定关键词，多词用逗号分隔"></el-input>
                  <el-button type="primary" class="enterKeywordsBtn" size="mini" @click="addNegKw">添加</el-button>
                </div>
                <div class="batchBtns">
                  <el-button type="primary" size="mini" @click="batchMatchType">批量修改匹配类型</el-button>
                  <el-button type="primary" size="mini" @click="batchDel">批量删除</el-button>
                </div>
                <!-- 批量修改匹配类型 -->
                <el-dialog 
                  title="批量修改匹配类型" 
                  width="30%"
                  append-to-body
                  :visible.sync="dialogEditMatchType">
                  <div class="editTypeChoose">
                    <el-select v-model="batchMateType" placeholder="请选择匹配类型">
                      <el-option
                        v-for="(item,i) in mateTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="editTypeBtns">
                    <el-button @click="editMatchTypeFalse">取 消</el-button>
                    <el-button type="primary" @click="editMatchType">确 定</el-button>
                  </div>
                </el-dialog>
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  class="inner"
                  @selection-change="handleSelectionChange"
                  tooltip-effect="dark">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="text"
                    label="否定关键词">
                  </el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="匹配类型"
                    width="150">
                    <template slot-scope="scope" >
                      <el-select v-model="scope.row.matchType">
                        <el-option
                          v-for="(item,i) in mateTypeOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row)">
                        <template #reference>
                          <i class="el-icon-delete-solid"></i>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="negKeywordsLast">
                  已过滤重复否定关键词 <i>{{negKeywordsLast01}}</i> 个
                  过滤特殊字符否定关键词 <i>{{negKeywordsLast02}}</i> 个
                </div> -->
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="negKeyWordSure">确 定</el-button>
              </div>
            </el-dialog>

            <div class="createAdvertBtns">
              <el-button size="middle" @click="cancelCreateAdvert">取 消</el-button>
              <el-button type="primary" size="middle" @click="nextCreateAdvert">下一步</el-button>
            </div>

          </div>
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>

  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';

  export default {
    data() {
      return {
        ruleForm:{//表单
          adPlanGroup:'',//广告系列组
          appName:'',//选取一个app
          adPlanPosition:'',//投放位置
          adPlanCountry:'',//国家/地区
          adPlanName:'',//广告系列名称
          adPlanBudget:'',//预算
          adPlanEveryBudget:'',//每日预算
          adPlanSettlement:'1',//结算选项
          adPlanProduct:'',//广告主或产品
          adPlanOrder:'',//订单号
          adPlanContactName:'',//主要联系人姓名
          adPlanContactEmail:'',//主要联系人电子邮箱
          adPlanSetContactEmail:'',//结算联系人电子邮箱

        },
        rules:{//验证规则
          adPlanGroup: [
            { required: true, message: '请选择广告组系列', trigger: 'change' }
          ],
          appName: [
            { required: true, message: '请输入AppID或者App名称', trigger: 'blur' },
          ],
          adPlanPosition: [
            { required: true, message: '请选择广告投放位置', trigger: 'change' }
          ],
          adPlanCountry: [
            { required: true, message: '请选择国家/地区', trigger: 'change' }
          ],
          adPlanName: [
            { required: true, message: '请输入广告系列名称', trigger: 'blur' },
          ],
          adPlanBudget: [
            { required: true, message: '总预算不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "总预算只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "总预算至少为¥0.01 且不超过¥140,000,000.00",
              trigger: "blur" 
            }
          ],
          adPlanEveryBudget: [
            { required: true, message: '总预算不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "总预算只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "总预算至少为¥0.01 且不超过总预算",
              trigger: "blur" 
            }
          ],
          adPlanProduct: [
            { required: true, message: '请输入广告主或产品', trigger: 'blur' },
          ],
          adPlanOrder: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
          ],
          adPlanContactName: [
            { required: true, message: '请输入主要联系人姓名', trigger: 'blur' },
          ],
          adPlanContactEmail: [
            { pattern:  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              required: true, message: '请输入主要联系人电子邮箱', trigger: 'blur' },
          ],
          adPlanSetContactEmail: [
            { pattern:  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              required: true, message: '请输入结算联系人电子邮箱', trigger: 'blur' },
          ],

          
        },
        adPlanGroupOption:[{
          value:1,
          label:'钢构宝'
        },{
          value:2,
          label:'钢构宝2'
        }],//广告系列组的集合
        adPlanPositionOption:[{
          value:1,
          label:'搜索结果'
        },{
          value:2,
          label:'"搜索"标签'
        }],//广告投放位置
        adPlanCountryOption:[{
          value:1,
          label:'中国大陆'
        }],//国家/地区
        dialogFormVisible:false,//添加否定关键词弹框
        negKeywordNum:0,//添加否定关键词数量
        enterKeywords:'',//添加否定关键词-输入框
        tableData:[],//添加否定关键词-表格
        // {
        //   text:'',
        //   matchType:''
        // }
        mateType:'',//匹配类型
        batchMateType:'',//批量匹配类型
        mateTypeOption:[{
          value:'BROAD',
          label:'广泛匹配'
        },{
          value:'EXACT',
          label:'完全匹配'
        }],
        negKeywordsTable:'',
        negKeywordsLast01:0,
        negKeywordsLast02:0,
        materialIdsPara:[],//批量选择
        dialogEditMatchType:false,//批量修改匹配类型
        batchMateTypeAll:[],//选择否定关键词集合
        negKw:[],//广告系列选中的否定关键词
        advertRadio:'1',//广告计划的广告单选
        showCustom:false,//自定义的没有操作
        showAdCustom:false,//自定义的有创建广告的操作
        createAdVisible:false,//创建广告弹框
        createAdForm:{//创建广告表单
          adName:'',//广告名称
          adSelect:'',//搜索
        },
        createAdRule:{//创建广告验证

        },




      }
    },
    mounted() {
      this.mateType = 'BROAD'
    },
    
    methods: {
      //选中素材的materialIds
      chooseTableFn(visible){
        let that = this
        let sendttSelection = that.$refs.multipleTable.selection
        that.materialIdsPara = []
        sendttSelection.forEach(function(e,i){
          that.materialIdsPara.push(e.id)
        })
        if(sendttSelection.length == 0){
          that.$message({
            message: '请进行勾选',
            type: 'warning'
          });
          that[visible]=false
        }else{
          that[visible]=true
          return true
        }
      },

      //添加否定关键词-弹框显示
      addNegKeyword(){
        this.dialogFormVisible = true
      },

      //添加否定关键词弹框中的添加btn
      addNegKw(){
        let enterKeywords = [],_this = this
        enterKeywords = _this.enterKeywords.split(',')
        enterKeywords.forEach(function(obj,index){
          _this.tableData.push({'text':obj,'matchType':'BROAD'})
        })
        //去重
        let obj={}
        this.tableData=this.tableData.reduce((item,next)=>{
          obj[next.text]?'':obj[next.text]=true&&item.push(next)
          return item
        },[])

        _this.enterKeywords = ''
        _this.negKeywordNum =  _this.tableData.length
      },

      quchong(){
        const res = new Map()
        let _this = this
        var newArr = _this.tableData.filter(function(element,index){
          console.log(element,index)
          return _this.tableData.indexOf(element) === index;
          // !res.has(_this.tableData.text)
        })
        console.log(newArr)
      },

      //添加否定关键词弹框中的单个删除
      deletePlanFn(row){
        let _this = this
        _this.tableData.forEach(function(obj,i){
          if(obj.text == row.text){
            _this.tableData.splice(i,1)
          }
          _this.addNegKw()
        })
        _this.negKeywordNum =  _this.tableData.length
      },

      //添加否定关键词弹框中的选择表格
      handleSelectionChange(val){
        this.batchMateTypeAll = val
      },

      //添加否定关键词弹框中的批量修改匹配类型
      batchMatchType(){
        this.chooseTableFn('dialogEditMatchType')
      },

      //添加否定关键词弹框中的批量修改匹配类型-确定
      editMatchType(){
        let _this = this
        _this.batchMateTypeAll.forEach(function(val,i){
          val.matchType = _this.batchMateType
        })
        _this.dialogEditMatchType = false
        _this.batchMateType = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //添加否定关键词弹框中的批量修改匹配类型-取消
      editMatchTypeFalse(){
        this.batchMateType = ''
        this.dialogEditMatchType = false
        this.$refs.multipleTable.clearSelection()
      },

      //批量删除
      batchDel(){
        if(this.$refs.multipleTable.selection.length == 0){
          this.$message({
            message: '请进行勾选',
            type: 'warning'
          });
        }else{
          let _this = this,
          selection = this.$refs.multipleTable.selection,
          data = []
          _this.tableData.map((el, index) => {
            if (!selection.find(item => item.text == el.text)) {
                data.push(_this.tableData[index]);
            }
          })
          _this.tableData = data
        }
      },

      //否定关键词-确定
      negKeyWordSure(){
        this.negKw = this.tableData
        this.dialogFormVisible = false
        console.log(this.negKw)
      },

      //广告change自定义
      changeAdCustom(){
        this.showAdCustom = true
      },

      //广告change默认
      changeAdDefault(){
        this.showAdCustom = false
      },

      //创建广告
      createAdvert(){
        this.createAdVisible = true
      },

      //取消新建广告计划
      cancelCreateAdvert(){
        this.$router.push({path:'/plan-management/adPlanApple'})
      },

      //下一步-进入广告组
      nextCreateAdvert(){
        this.$router.push({path:'/plan-management/adTeamApple'})
      }
     





    }
  }
  </script>
  
  <style lang="scss" scoped>
    .createTitle{
      font-size:16px;
    }
    ::v-deep .createAll .el-input__inner,::v-deep .createAll .el-input--mini{
      width:400px!important;
    }
    ::v-deep .createAll .el-form-item__label{
      width:300px!important;
      padding-left:100px;
    }
    ::v-deep .createAll .el-form-item__error{
      left:200px;
    }
    .iconTip{
      padding-left:10px;
    }
    .iconTipContent{
      width:240px;
      padding:10px;
      line-height:22px;
    }
    .negKeywords{
      width:400px;
      height:30px;
    }
    .addNegKeywords i {
      font-style: normal;
    }
    .enterKeywords{
      width:100%;
      margin:10px 0 20px;
    }
    .enterKeywords .enterKeywordsInput,.enterKeywords .enterKeywordsBtn{
      display: inline-block;
    }
    .enterKeywords .enterKeywordsInput{
      width:90%;
    }
    .enterKeywords .enterKeywordsBtn{
      width:10%;
      height:30px;
    }
    .negKeywordsLast{
      margin-top:15px;
      font-size:12px;
    }
    .negKeywordsLast i{
      font-style: normal;
    }
    ::v-deep .createAll .inner .el-input__inner, ::v-deep .createAll .inner .el-input--mini{
      width:100px!important;
    }
    .batchBtns{
      margin-bottom:15px;
    }
    .editTypeChoose{
      text-align:center;
    }
    .editTypeBtns{
      margin-top:30px;
      text-align:center;
    }
    .showAdCustom{
      width:370px;
      padding:50px 35px;
      margin:15px 0 0 200px;
      background-color: #f5f5f7;
      box-sizing: border-box;
      text-align:center;
    }
    :v-deep .createAll .createAdvertStyle .el-input__inner,::v-deep .createAll .createAdvertStyle .el-input--mini{
      width:100%!important;
    }
    .createAdvertBtns{
      text-align:center;
      margin-top:40px;
    }
    
    


  </style>
