<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>系统日志</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
        <div class="departmentHeader">
          <span class="role_headerTitles">系统日志</span>
          <div class="journal_query" style="margin-top:20px;">
            <el-form :model="ruleForm" :inline="true" ref="ruleForm" class="demo-ruleForm">
              <el-form-item label="" lable-width="100%" prop="journalvalue">
                <el-select v-model="ruleForm.journalvalue" clearable filterable placeholder="可输入选择操作人">
                  <el-option
                    v-for="(item,i) in options"
                    :key="i"
                    :label="item.userName"
                    :value="item.userName"
                   >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="journalvalueType">
                <el-select v-model="ruleForm.journalvalueType" filterable clearable placeholder="请选择事件类型">
                  <el-option
                    v-for="(item,index) in type_options"
                    :key="index"
                    :label="item.label"
                    :value="item.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="logDataValue">
                <el-date-picker
                  v-model="ruleForm.logDataValue"
                  type="daterange"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="" prop="describeValue">
                <!-- <el-input v-model="ruleForm.describeValue" placeholder="请输入筛选条件"></el-input> -->
                <el-autocomplete
                  class="inline-input"
                  v-model="ruleForm.describeValue"
                  value-key="describe"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入筛选条件"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="getLogList(1)">查询</el-button>
                <el-button type="info" size="small" plain @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="journaTable">
          <el-table
            :data="journaTableData"
            style="width: 100%">
            <el-table-column
              prop="date"
              label="操作时间"
              width="200">
              <template slot-scope="scope">{{scope.row.createTime | dateFormat}}</template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="操作人"
              width="200">
              <template slot-scope="scope">{{scope.row.userName}}</template>
            </el-table-column>
            <el-table-column
              prop="title"
              label="部门职位"
              width="260">
              <template slot-scope="scope">{{scope.row.post}}</template>
            </el-table-column>
            <el-table-column
              prop="record"
              label="操作记录">
              <template slot-scope="scope">{{scope.row.describe}}</template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="footer_paging">
            <div class="footer_totalNum">
              <!-- <el-popconfirm
                title="确定全部导出吗？"
                @confirm="exportAll">
              <template #reference>
                <el-button>全部导出</el-button>
                </template>
              </el-popconfirm> -->
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>

       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <accessDialog :visible.sync="showDialog" ></accessDialog>
  </div>
</template>

<script>
import accessDialog from '@/views/dialog/accountDialog'
import Api from "@/api";
import config from '@/api/config';
import {$http} from '@/api/http';

export default {
  name: 'authority',
  components: {
    accessDialog
  },
  data() {
    return {
      showDialog: false,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        conditions:'',
        journalvalue:'',
        journalvalueType:'',
        logDataValue:'',
        describeValue:''
      },
      options: [],
        // journalvalue: '',
        type_options: [{
          type: '1',
          label: '账户抓取'
        }, {
          type: '2',
          label: '账户分配'
        }, {
          type: '3',
          label: '客户记录'
        }, {
          type: '4',
          label: '渠道数据'
        }, {
          type: '5' ,
          label: '账户数据'
        }, {
          type: '6' ,
          label: 'imei导出'
        }, {
          type: '7' ,
          label: '日报记录'
        }, {
          type: '8' ,
          label: '图文素材'
        }, {
          type: '9' ,
          label: '视频素材'
        }, {
          type: '10' ,
          label: '素材需求'
        }, {
          type: '11' ,
          label: '广告上传'
        }, {
          type: '12' ,
          label: '广告预警'
        }, {
          type: '13' ,
          label: '广告调价'
        }, {
          type: '14' ,
          label: '广告启停'
        }, {
          type: '15' ,
          label: '员工关系'
        }],
        // describeValue:'',
        // journalvalueType: '',
        journaTableData: [],
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        // logDataValue:'',// 时间选择器
        logParams:{ //查询日志参数
          params:{
            beginTime:'',
            endTime:''
          },
          type:'',
          userName:'',
          // describe:''
        },
        exportParams:{// 导出日志
          createTime:'',
          userName:'',
          post:'',
          describe:''
        },
        exportData:[],
    }
  },
  computed: {
     
  },
  mounted() {
    this.getLogList()
  },
  // created() {
  //   var params = {
  //     pageNum: this.pageNum,
  //     pageSize: this.pageSize
  //   }
  //   this.findAll(params)
  // },
  methods: {
    //重置
    resetForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
      // this.getLogList()
      this.$router.go(0)
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      this.getLogList()
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.journaTableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.describe.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleSelect(item) {
      console.log(item);
    },

    // 获取日志列表
    getLogList(page) {
      let that = this
      if(page == 1){
        that.pageNum = 1
      }
      that.logParams.params.beginTime = that.ruleForm.logDataValue[0]
      that.logParams.params.endTime = that.ruleForm.logDataValue[1]
      // that.logParams.type = Number(that.journalvalueType)
      that.logParams.type = that.ruleForm.journalvalueType
      that.logParams.userName = that.ruleForm.journalvalue
      that.logParams.describe = that.ruleForm.describeValue
      $http.axios.post("/api/channel/log/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.logParams).then(function(response) {
        if(response.data.code == 200){
          that.journaTableData = []
          that.journaTableData = response.data.rows
          that.options = that.journaTableData
          that.totalRow = response.data.total//分页总条数
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //查询结果
    selectresult(){
      let that = this
      that.logParams.params.beginTime = that.ruleForm.logDataValue[0]
      that.logParams.params.endTime = that.ruleForm.logDataValue[1]
      // that.logParams.type = Number(that.journalvalueType)
      that.logParams.type = that.ruleForm.journalvalueType
      that.logParams.userName = that.ruleForm.journalvalue
      that.logParams.describe = that.ruleForm.describeValue
      $http.axios.post("/api/channel/log/list?pageNum="+that.pageNum+"&pageSize="+that.pageSize,that.logParams).then(function(response){
        if(response.data.code == 200){
          that.journaTableData = []
          that.totalRow = response.data.total//分页总条数
          that.journaTableData = response.data.rows
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //导出日志
    // exportAll(){
    //   let that = this
    //   Api.user.exportLogList(that.exportParams).then(res => {
    //     that.exportData = that.journaTableData
    //     that.exportData.forEach(function(item,index){
    //       that.exportParams.createTime = that.exportData[index].createTime
    //       that.exportParams.userName = that.exportData[index].userName
    //       that.exportParams.post = that.exportData[index].post
    //       that.exportParams.describe = that.exportData[index].describe
    //       // console.log(that.exportParams.userName)
    //     })
        
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.role_headerTitles {
  font-size:16px;
  font-weight:700;
  color:#6e7c7c;
  padding-bottom:14px;
}
.departmentHeader{
  margin-bottom:20px;
}
.el-form-item{
  display:inline-block;
  vertical-align:top;
  /* width:20%; */
}
.journal_query .el-form-item__content{
  margin-left:0 !important;
  margin-right:10px!important;
}
.footer_paging{
  margin:20px 0 4px;
}
.footer_totalNums{
  font-size:12px;
  color:#666;
  padding-left:5px;
  padding-right:20px;
}
.footerPaging {
  float:right;
}

</style>
