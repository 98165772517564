<template>
  <div class="authority common-page">
    <!-- <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/plan-management/adOperateApple' }">操作记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/plan-management/adOperateApple' }">操作记录</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <div class="chanelTab">
          <span class="operateTile" >操作记录</span>
        </div>
        
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="" prop="">
                  <el-date-picker
                    v-model="recordDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select placeholder="请选择操作类型" v-model="recordType" @change="changeOptype">
                    <el-option
                      v-for="(item,i) in recordTypeOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select placeholder="请选择类型" v-model="recordAdType">
                    <el-option
                      v-for="(item,i) in recordAdTypeOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-input v-model = "opRecords" clearable placeholder="请输入操作记录" ></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-input v-model = "opRecordsPerson" clearable placeholder="请输入操作人" ></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" class="newQuery" size="small" @click="getOperateList(1)">查询</el-button>
                  <el-button type="info" size="small" plian @click="reset">重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
          </div>
          <div class="materiaContent">
            <el-table
              ref="multipleTable"
              :data="tableData"
              style=" cursor:pointer;"
              tooltip-effect="dark">
              <el-table-column
                prop=""
                label="操作时间"
                width="200">
                <template slot-scope="scope">{{scope.row.createTime | dateTimeFormat}}</template>
              </el-table-column>
              <el-table-column
                prop="operater"
                label="操作人"
                width="120">
              </el-table-column>
              <el-table-column
                prop="type"
                label="操作类型"
                width="120">
              </el-table-column>
              <el-table-column
                prop="adType"
                label="操作位置"
                width="180">
              </el-table-column>
              <el-table-column
                prop=""
                label="账户分配"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.accountDistributeDetail == null">-</span>
                  <span v-else class="detailOp" @click="accountDistribution(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="账户修改"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.accountUpdateDetail == null">-</span>
                  <span v-else class="detailOp" @click="accountEdit(scope.row)">详情>></span>
                </template>
              </el-table-column>

              <el-table-column
                prop=""
                label="广告系列修改"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.adPlanUpdateDetail == null">-</span>
                  <span v-else class="detailOp" @click="adPlanEdit(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="广告组修改"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.adGroupUpdateDetail == null">-</span>
                  <span v-else class="detailOp" @click="adGroupEdit(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="关键词修改"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.kwUpdateDetail == null">-</span>
                  <span v-else class="detailOp" @click="kwEdit(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="否定关键词修改"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.negativeKwUpdateDetail == null">-</span>
                  <span v-else class="detailOp" @click="negKwEdit(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="关键词删除"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.kwDelDetail == null">-</span>
                  <span v-else class="detailOp" @click="kwDelete(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="否定关键词删除"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.negativeKwDelDetail == null">-</span>
                  <span v-else class="detailOp" @click="negKwDelete(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="预警"
                width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.warnDetail == null">-</span>
                  <span v-else class="detailOp" @click="warnningDetail(scope.row)">详情>></span>
                </template>
              </el-table-column>
              <el-table-column
                prop="result"
                label="操作结果"
                fixed='right'
                width="120"
                center>
                <template slot-scope="scope">
                  <div v-if="(scope.row.result.indexOf('成功') != -1) == true">
                    执行成功
                  </div>
                  <div v-else>
                    <el-tooltip class="item" effect="dark" placement="right-end">
                      <div slot="content" v-html="scope.row.result"></div>
                      <div class="fail">
                        失败<i class="el-icon-warning-outline" style="font-size:16px;"></i>
                      </div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 账户修改 -->
          <el-dialog title="账户修改操作记录" width="40%" :visible.sync="dialogAccountEdit">
            <div class="detailWrap">
              <div class="detailList">
                <label>余额：</label>
                <span>{{accountEditData.balance}}</span>
              </div>
              <div class="detailList">
                <label>最近一次充值金额：</label>
                <span>{{accountEditData.lastRecharge}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{accountEditData.owner}}</span>
              </div>
              <div class="detailList">
                <label>状态：</label>
                <span>{{accountEditData.status}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogAccountEdit = false">确 定</el-button>
            </div>
          </el-dialog>
          <!-- 账户分配 -->
          <el-dialog title="账户分配操作记录" width="40%" :visible.sync="dialogAccountDistribution">
            <div class="detailWrap">
              <div class="detailList">
                <label>辅优化师：</label>
                <span>{{accountDistributionData.assistUser}}</span>
              </div>
              <div class="detailList">
                <label>部门：</label>
                <span>{{accountDistributionData.dept}}</span>
              </div>
              <div class="detailList">
                <label>主优化师：</label>
                <span>{{accountDistributionData.mainUser}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{accountDistributionData.owner}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogAccountDistribution = false">确 定</el-button>
            </div>
          </el-dialog>

          <!-- 广告系列修改 -->
          <el-dialog title="广告系列修改操作记录" width="40%" :visible.sync="dialogAdPlanEdit">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{adPlanEditData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{adPlanEditData.owner}}</span>
              </div>
              <div class="detailList">
                <label>状态：</label>
                <span>{{adPlanEditData.status}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogAdPlanEdit = false">确 定</el-button>
            </div>
          </el-dialog>

          <!-- 广告组修改 -->
          <el-dialog title="广告组修改操作记录" width="40%" :visible.sync="dialogAdGroupEdit">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告组：</label>
                <span>{{adGroupEditData.adGroup}}</span>
              </div>
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{adGroupEditData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>cpa出价：</label>
                <span>{{adGroupEditData.cpaGoal}}</span>
              </div>
              <div class="detailList">
                <label>货币：</label>
                <span>{{adGroupEditData.currency}}</span>
              </div>
              <div class="detailList">
                <label>默认出价：</label>
                <span>{{adGroupEditData.defaultBidAmount}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{adGroupEditData.owner}}</span>
              </div>
              <div class="detailList">
                <label>状态：</label>
                <span>{{adGroupEditData.status}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogAdGroupEdit = false">确 定</el-button>
            </div>
          </el-dialog>

          <!-- 关键词修改 -->
          <el-dialog title="关键词修改操作记录" width="40%" :visible.sync="dialogKwEdit">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告组：</label>
                <span>{{kwEditData.adGroup}}</span>
              </div>
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{kwEditData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>出价：</label>
                <span>{{kwEditData.bidAmount}}</span>
              </div>
              <div class="detailList">
                <label>关键词：</label>
                <span>{{kwEditData.kw}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{kwEditData.owner}}</span>
              </div>
              <div class="detailList">
                <label>状态：</label>
                <span>{{kwEditData.status}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogKwEdit = false">确 定</el-button>
            </div>
          </el-dialog>

          <!-- 否定关键词修改 -->
          <el-dialog title="否定关键词修改操作记录" width="40%" :visible.sync="dialogNegKwEdit">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告组：</label>
                <span>{{negKwEditData.adGroup}}</span>
              </div>
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{negKwEditData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>出价：</label>
                <span>{{negKwEditData.bidAmount}}</span>
              </div>
              <div class="detailList">
                <label>关键词：</label>
                <span>{{negKwEditData.kw}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{negKwEditData.owner}}</span>
              </div>
              <div class="detailList">
                <label>状态：</label>
                <span>{{negKwEditData.status}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogNegKwEdit = false">确 定</el-button>
            </div>
          </el-dialog>

          <!-- 关键词删除 -->
          <el-dialog title="关键词删除操作记录" width="40%" :visible.sync="dialogKwDel">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告组：</label>
                <span>{{kwDelData.adGroup}}</span>
              </div>
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{kwDelData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>关键词：</label>
                <span>{{kwDelData.kw}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{kwDelData.owner}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogKwDel = false">确 定</el-button>
            </div>
          </el-dialog>

          <!-- 否定关键词删除 -->
          <el-dialog title="否定关键词删除操作记录" width="40%" :visible.sync="dialogNegKwDel">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告组：</label>
                <span>{{negKwDelData.adGroup}}</span>
              </div>
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{negKwDelData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>关键词：</label>
                <span>{{negKwDelData.kw}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{negKwDelData.owner}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogNegKwDel = false">确 定</el-button>
            </div>
          </el-dialog>

           <!-- 预警详情 -->
           <el-dialog title="预警操作记录" width="40%" :visible.sync="dialogWarnning">
            <div class="detailWrap">
              <div class="detailList">
                <label>广告组：</label>
                <span>{{warnningData.adGroup}}</span>
              </div>
              <div class="detailList">
                <label>广告系列：</label>
                <span>{{warnningData.adPlan}}</span>
              </div>
              <div class="detailList">
                <label>执行内容：</label>
                <span>{{warnningData.exeContent}}</span>
              </div>
              <div class="detailList">
                <label>上限阈值：</label>
                <span>{{warnningData.upperThreshold}}</span>
              </div>
              <div class="detailList">
                <label>下限阈值：</label>
                <span>{{warnningData.lowerThreshold}}</span>
              </div>
              <div class="detailList">
                <label>广告系列组：</label>
                <span>{{warnningData.owner}}</span>
              </div>
              <div class="detailList">
                <label>金额：</label>
                <span>{{warnningData.price}}</span>
              </div>
              <div class="detailList">
                <label>指标：</label>
                <span>{{warnningData.target}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogWarnning = false">确 定</el-button>
            </div>
          </el-dialog>




          <div class="footerPaging">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageCount"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.totalRow">
            </el-pagination>
          </div>
       </template>
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
export default {
  data() {
    return {
      recordDate:[],//日期
      recordAction:'',//动作
      recordActionOption:[{
        value:1,
        label:'全部'
      },{
        value:2,
        label:'新建'
      },{
        value:3,
        label:'修改'
      }],
      recordType:'',//类型
      recordTypeOption:[{
        value:'预警',
        label:'预警'
      },{
        value:'创建',
        label:'创建'
      },{
        value:'修改',
        label:'修改'
      },{
        value:'分配',
        label:'分配'
      },{
        value:'删除',
        label:'删除'
      }],
      recordPosition:'',//操作位置
      recordPositionOption:[{
        value:1,
        label:'全部'
      },{
        value:2,
        label:'AppSA'
      },{
        value:3,
        label:'苹果'
      }],
      adType:'',//placeholder内容
      adTypeContent:'',//搜索广告系列、广告组、操作人
      recordAdType:'',//搜索广告系列、广告组、操作人类型
      recordAdTypeOption:[],
      recordAdTypeOption1:[{
        value:'广告系列组',
        label:'广告系列组'
      },{
        value:'广告系列',
        label:'广告系列'
      },{
        value:'广告组',
        label:'广告组'
      }],
      recordAdTypeOption2:[{
        value:'广告系列组',
        label:'广告系列组'
      },{
        value:'广告系列',
        label:'广告系列'
      },{
        value:'广告组',
        label:'广告组'
      },{
        value:'关键词',
        label:'关键词'
      },{
        value:'否定关键词',
        label:'否定关键词'
      }],
      recordAdTypeOption3:[{
        value:'广告系列组',
        label:'广告系列组'
      }],
      recordAdTypeOption4:[{
        value:'关键词',
        label:'关键词'
      },{
        value:'否定关键词',
        label:'否定关键词'
      }],
      recordAdTypeOption5:[{
        value:'广告系列',
        label:'广告系列'
      },{
        value:'广告组',
        label:'广告组'
      },{
        value:'广告创意',
        label:'广告创意'
      },{
        value:'关键词excel',
        label:'关键词excel'
      },{
        value:'计划否定关键词excel',
        label:'计划否定关键词excel'
      },{
        value:'广告组否定关键词excel',
        label:'广告组否定关键词excel'
      },{
        value:'广告系列否定关键词',
        label:'广告系列否定关键词'
      },{
        value:'广告组否定关键词',
        label:'广告组否定关键词'
      }],
      tableData:[],//表格数据
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      userContent:{},
      userId:'',
      userName:'',
      deptId:'',
      adTypeContentPerson:'',//操作人
      opRecords:'',//搜索操作记录
      opRecordsPerson:'',//搜索操作人
      dialogAccountEdit:false,//账户修改-弹框
      accountEditData:{},//账户修改的data
      dialogAccountDistribution:false,//账户分配-弹框
      accountDistributionData:{},//账户分配的data
      dialogAdPlanEdit:false,//广告系列修改-弹框
      adPlanEditData:{},//广告系列修改的data
      dialogAdGroupEdit:false,//广告组修改-弹框
      adGroupEditData:{},//广告组修改的data
      dialogKwEdit:false,//关键词修改-弹框
      kwEditData:{},//关键词修改的data
      dialogNegKwEdit:false,//否定关键词修改-弹框
      negKwEditData:{},//否定关键词的data
      dialogKwDel:false,//关键词删除-弹框
      kwDelData:{},//关键词删除的data
      dialogNegKwDel:false,//否定关键词删除-弹框
      negKwDelData:{},//否定关键词删除的data
      dialogWarnning:false,//预警详情-弹框
      warnningData:{},//预警详情的data
      ownerId:'',
      ownerName:'',

    }
  },

  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    let nowTimes = Date.now();
    let oldTimes = nowTimes - (86400000 * 7);
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//今天的时间
    this.recordDate = [oldDate, nowDate];
    this.recordType = '预警'
    if(this.recordType == '预警'){
      this.recordAdTypeOption = this.recordAdTypeOption1
    }

    this.getOperateList()//列表
  },

  methods: {
    //tab切换
    clickChangeChannel(val){
      if(val == 1){
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName }})
      }
      // else if(val == 2){
      //   this.$router.push({path:'/plan-management/adTeamApple'})
      // }else if(val == 3){
      //   this.$router.push({path:'/plan-management/adKeywordApple'})
      // }
    },

    //操作日志的列表
    getOperateList(){
      let _this = this ,params = {}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'pageIndex':_this.page,
        'pageSize':_this.pageCount,
        'sDate':_this.recordDate[0],//开始时间
        'eDate':_this.recordDate[1],//结束时间
        'type':_this.recordType,//功能类型
        'adType':_this.recordAdType,//广告层级类型
        'query':_this.opRecords,//操作记录-模糊搜索
        'operater':_this.opRecordsPerson,//操作人-模糊搜索
      }
      $http.axios.post("/api/apple/logOperate/list",params).then(function(response) {
        if(response.data.code == 200){
          _this.tableData = response.data.data.list
          _this.totalRow = response.data.data.pageInfo.rowCount
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //重置
    reset(){
      this.$router.go(0)
    },

    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.getOperateList()
    },

    //格式化时间 返回年月日 
    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //change操作类型
    changeOptype(val){
      this.recordAdType = ''
      if(val == '预警'){
        this.recordAdTypeOption = this.recordAdTypeOption1
      }else if(val == '修改'){
        this.recordAdTypeOption = this.recordAdTypeOption2
      }else if(val == '分配'){
        this.recordAdTypeOption = this.recordAdTypeOption3
      }else if(val == '删除'){
        this.recordAdTypeOption = this.recordAdTypeOption4
      }else if(val == '创建'){
        this.recordAdTypeOption = this.recordAdTypeOption5
      }
    },

    //账户修改
    accountEdit(row){
      this.accountEditData = row.accountUpdateDetail
      this.dialogAccountEdit = true
    },

    //账户分配
    accountDistribution(row){
      this.accountDistributionData = row.accountDistributeDetail
      this.dialogAccountDistribution = true
    },

    //广告系列修改
    adPlanEdit(row){
      this.adPlanEditData = row.adPlanUpdateDetail
      this.dialogAdPlanEdit = true
    },

    //广告组修改
    adGroupEdit(row){
      this.adGroupEditData = row.adGroupUpdateDetail
      this.dialogAdGroupEdit = true
    },

    //关键词修改
    kwEdit(row){
      this.kwEditData = row.kwUpdateDetail
      this.dialogKwEdit = true
    },

    //否定关键词修改
    negKwEdit(row){
      this.negKwEditData = row.negativeKwUpdateDetail
      this.dialogNegKwEdit = true
    },

    //关键词删除
    kwDelete(row){
      this.kwDelData = row.kwDelDetail
      this.dialogKwDel = true
    },

    //否定关键词删除
    negKwDelete(row){
      this.negKwDelData = row.negativeKwDelDetail
      this.dialogNegKwDel = true
    },

    //预警详情
    warnningDetail(row){
      this.warnningData = row.warnDetail
      this.dialogWarnning = true
    }

  }, 
}
</script>
<style lang="scss" scoped>
  /* tab切换 */
  .chanelTab{
    margin:15px 0 20px;
    width:100%;
    padding-bottom:15px;
    border:none;
    border-bottom:1px solid #F1F7F5;
  }
  .operateTile{
    padding-left:13px;
    border-left:3px solid #48916F;
    font-size:14px;
    font-weight:700;
  }
  
  .detailOp{
    color:#1989fa;
  }
  .detailWrap{
    border-top:1px solid #ddd;
    border-bottom:1px solid #ddd;
  }
  .detailList{
    padding:8px 0;
    font-size:0;
  }
  .detailList label,.detailList span{
    display:inline-block;
    vertical-align:middle;
    font-size:14px;
    box-sizing: border-box;;
  }
  .detailList label{
    width:160px;
    text-align:right;
  }
  .detailList span{
    padding-left:20px;
  }
  .detailList:nth-child(2n){
    background:#eee;
  }
  /*新版*/
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .materialHead .el-range-editor--mini.el-input__inner{
    width:255px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .materialHead .el-range-editor--mini .el-range-input{
    background:#F5F7F6;
  }
  ::v-deep .materialHead .el-range-editor--mini .el-range-separator{
    height:32px;
    line-height:32px;
  }
  .fail i{
    color:#FF8080;
    font-weight:700;
    font-size:14px!important;
    padding-left:3px;
    display: inline-block;
    vertical-align: middle;
    margin-top:-2px;
  }
  
</style>
