<template>
<div>
  <!-- <div class="adGroup"></div>
  <strong class="partTitle fW">广告组</strong> -->
    <el-form :model="sizeForm" :rules="rules" ref="sizeForm" :label-position="labelPosition" label-width="110px">
      <div class="flowPart">
        <span class="title fW">流量</span>
        <el-form-item label="推广流量" prop="resourceFlow">
          <el-radio-group v-model="sizeForm.resourceFlow" size="small" @change="changePopu">
            <el-radio-button v-for="(item,index) in adTeamtabsListPart"
            :key="index"
            :label="item.value"
            :name="item.label">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="流量场景" prop="flowScene">
          <el-radio-group v-model="sizeForm.flowScene" size="small" @change="changePriceType">
            <el-radio-button v-for="(item,index) in flowYytgZyll"
            :key="index"
            :label="item.value"
            :name="item.name">
            {{item.name}}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <div class="one-one">
          <span class="title fW">目标</span>
          <div v-if="newApply">
            <el-form-item label="应用ID" prop="target">
              <el-select v-model="sizeForm.target" value-key="appId" ref="shuruValue" @change="changeTarget" @keyup.enter.native="targetIds" filterable style="width:350px;">
                <el-option
                  v-for="(item,i) in targetOption"
                  :key="i"
                  :label="item.appName"
                  :value="item">
                  <span style="float: left">{{ item.appId }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.appName }}</span>
                </el-option>
              </el-select>
              <div class="targetBox" v-show="targetShow">
                <div class="targetBox_img">
                  <img :src="targetData.iconUrl">
                </div>
                <div class="targetBox_name">{{targetData.appName}}</div>
              </div>
            </el-form-item>
            <el-form-item label="推广落地页" prop="landingPage" v-if="landingPages">
              <el-select v-model="sizeForm.landingPage" @change="changeLandingPage">
                <el-option
                  v-for="(item,i) in landingPageOption"
                  :key="i"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="landingSecond">
                <el-option
                  v-for="(item,i) in landingSecondOption"
                  :key="i"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推广落地页" prop="landingPage" v-if="landingPages2">
              <el-select v-model="sizeForm.landingPage" @change="changeLandingPage2">
                <el-option
                  v-for="(item,i) in landingPageOption2"
                  :key="i"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="landingSecond" value-key="pageId" v-show="optionOne" @change="changeNewPage11">
                <el-option
                  v-for="(item,i) in landingSecondOption2"
                  :key="i"
                  :label="item.pageName"
                  :value="item">
                  <span >{{ item.pageId }}</span> |
                  <span style="color: #8492a6; font-size: 13px">{{ item.pageName }}</span>
                </el-option>
              </el-select>
              <el-select v-model="landingSecond" value-key="h5Id" v-if="optionTwo" @change="changeNewPage">
                <el-option
                  v-for="(item,i) in landingSecondOption3"
                  :key="i"
                  :label="item.h5Name"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="下载并打开" v-if="openClose">
              <el-switch
                v-model="ocPrice"
                active-color="#13ce66"
                inactive-color="#9f9f9f"
                active-value="1"
                inactive-value="0"
                @change="changeopenOrclose">
              </el-switch>
            </el-form-item>
          </div>
          <div v-if="newApplyTwo">
            <el-form-item label="网页地址" prop="netPage" >
              <el-select v-model="sizeForm.netPage" @change="changeNet">
                <el-option
                  v-for="(item,i) in netPageOption"
                  :key="i"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-input v-model="netLink" v-if="netLinkShow" placeholder="请输入内容" style="width:300px;"></el-input>
              <el-select v-model="landingSecond" value-key="pageId" v-if="netLinkShowTwo" @change="changeNetChildren">
                <el-option
                  v-for="(item,i) in netPageSecondOption"
                  :key="i"
                  :label="item.pageName"
                  :value="item">
                  <span >{{ item.pageId }}</span> |
                  <span style="color: #8492a6; font-size: 13px">{{ item.pageName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Deeplink链接" prop="deepl" >
              <el-input v-model="deepLinkValue" placeholder="请输入内容" style="width:300px;"></el-input>
            </el-form-item>
          </div>
          <directional
            v-bind:sizeForm="sizeForm"
            ref="deriectRefs"
            v-if="showResource" 
            :pubVue = "pubVue"
            :extensionFlow = "extensionFlow" 
            :flowScene = "flowScene"
            :extensionType = "extensionType"
            :key = "flowScene"
            ></directional>
          <div v-if="zntlya">
            <span class="title fW">智能拓量</span>
            <el-form-item label="智能拓量" prop="brainPower">
              <el-switch
                v-model="sizeForm.brainPower"
                active-color="#13ce66"
                inactive-color="#9f9f9f"
                active-value="2"
                inactive-value="0"
                @change="changeZntl">
              </el-switch>
            </el-form-item>
          </div>
          <span class="title fW">排期</span>
          <el-form-item label="排期" prop="scheduling">
            <el-date-picker
              v-model="sizeForm.scheduling"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyyMMdd"
              :picker-options="pickerOptions"
              @change="changeDateSlot">
            </el-date-picker>
            <div class="setTime">
              <el-checkbox v-model="sizeForm.setTimeCheckbox" @change="setCarryTime">设置投放时间</el-checkbox>
            </div>
          </el-form-item>
          <el-form-item>
            <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
          </el-form-item>
          <span class="title fW">出价</span>
          <el-form-item label="投放模式" v-show="launchMode">
            <el-radio-group v-model="sizeForm.deliveryMode" size="small" @change="changeLaunchMode">
              <el-radio-button  v-for="(item,index) in deliveryModeList"
              :key="index"
              :label="item.value"
              :name="item.name">
              {{item.label}}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="出价方式" prop="priceType">
            <el-radio-group v-model="sizeForm.priceType" size="small" @change="priceTypeChange">
              <el-radio-button v-for="(item,index) in priceTypeOption"
              :key="index"
              :label="item.id">
              {{item.name}}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div v-if="typePriceShow">
            <el-form-item label="基础出价" prop="basePrice" >
              <el-input v-model="sizeForm.basePrice" :rules="numberlimitM" :placeholder="lowPrice" style="width:300px;" @blur="changeMoney"></el-input>
            </el-form-item>
            <el-form-item label="转化类型" prop="conversion" style="margin-top:20px;">
              <el-select v-model="sizeForm.conversion" @change="changeZHTypeNew">
                <el-option
                  v-for="(item,i) in conversionOption"
                  :key="i"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转化出价" prop="zhPrice" >
              <el-input v-model="sizeForm.zhPrice" :placeholder="zhcjPrice" style="width:300px;" @blur="changezhMoney"></el-input>
            </el-form-item>
          </div>
          <div v-if="deepZh">
            <el-form-item label="深度转化投放" >
              <el-switch
                v-model="deepZhValue"
                active-color="#13ce66"
                inactive-color="#9f9f9f"
                active-value="2"
                inactive-value="0"
                @change="changeDeepZhValue">
              </el-switch>
              <div v-if="deepShow" style="margin-left:-110px;margin-top:20px;">
                <el-form-item label="深度转化类型" prop="deepZhType" >
                  <el-select v-model="sizeForm.deepZhType" @change="changeDeeplx">
                    <el-option
                      v-for="(item,i) in deepsionOption"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="深度转化出价" prop="deepzhPrice" >
                  <el-input v-model="sizeForm.deepzhPrice" :placeholder="deepzhcjPrice" style="width:300px;" ></el-input>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
          <el-form-item label="跟随应用" prop="followApplication" v-show="launch2_one">
            <el-select v-model="targetNew" placeholder="请输入应用名称" @change="changeTargetNew" ref="gsshuruValue" @keyup.enter.native="gstargetIds" filterable :filter-method="dataFilter">
              <el-option
                v-for="(item,i) in targetNewOption"
                :key="i"
                :label="item.appName"
                :value="item.appId"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <ul class="launchBox"> 
              <li v-for="(item,index) in targetNewData" :key="index">
                <div class="targetBox_img">
                  <img :src="item.iconUrl">
                </div>
                <div class="targetBox_name">{{item.appName}}</div>   
                <i class="closeApp" @click="closeAppList(index)">X</i>
              </li>              
            </ul>
          </el-form-item>
          <el-form-item label="基础出价" prop="basePrice" v-if="launchPrice">
            <el-input v-model="sizeForm.basePrice" :rules="numberlimitM" :placeholder="lowPrice" style="width:300px;" @blur="changeMoney"></el-input>
          </el-form-item>
          <el-form-item label="动态出价" v-if="launch2_price">
            <el-switch
              v-model="dynamicPriceValue"
              active-color="#13ce66"
              inactive-color="#9f9f9f"
              active-value="1"
              inactive-value="0"
              @change="changeTrendsPrice">
            </el-switch>
            
          </el-form-item>
          <el-form-item label="转化类型" prop="conversion_cpd" v-show="conversionShow" style="margin-top:20px;">
            <el-select v-model="sizeForm.conversion_cpd" @change="changeZHType">
              <el-option
                v-for="(item,i) in conversionOption_cpd"
                :key="i"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跟随最高出价" prop="highestBid" v-if="launch2_one">
            <el-input v-model="sizeForm.highestBid" :placeholder="gensuilower" style="width:300px;" @blur="changegsMoney"></el-input>
          </el-form-item>
          <keyWord v-bind:sizeForm="sizeForm" ref="keyWordRef" v-if="keyWords" :pubVue = "pubVue"></keyWord>
          <!-- <directional v-bind:sizeForm="sizeForm"></directional> -->
          <span class="title fW">广告组名称</span>
          <el-form-item label="广告组名称" prop="adTeamName">
            <el-input v-model="sizeForm.adTeamName" style="width:300px;"></el-input>
          </el-form-item>
        </div>

      </div>
      <el-form-item style="margin-top:50px;">
        <!-- <el-button type="primary" size="small" @click="onSubmit">保存并新建广告创意</el-button>
        <el-button type="primary" size="small" @click="onSubmit">保存并关闭</el-button> -->
        <el-button type="primary" v-if="creatSucess" size="small" @click="submitFn">保存并新建广告创意</el-button>
        <el-button type="primary" v-if="creatSucess" size="small" @click="submitFn">保存并关闭</el-button>
        <el-button type="info" v-if="creatSucess" plain size="small" @click="goPlanPage">取消</el-button>
      </el-form-item>
    </el-form>
    <div class="adGroup"></div>
</div>


  
</template>
<script>
import keyWord from "./keyWord";//关键词
import directional from "./directional";//定向
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import TimeUnit from '../../../TimeUnit.vue';

export default {
  props:{
    newTeamVue:Object,
    // teamInfo:Object,////从计划模块获取的信息
  },
  components:{
    keyWord,
    directional,//关键词
    timeUnit:TimeUnit
    // allianceTraffic//联盟流量
  },
  data() {
      const generateData = _ => {
        const data = [];
        const cities = ['年龄', '性别', '地域', '用户活跃'];
        // const pinyin = ['shanghai', 'age', 'sex', 'shenzhen', 'nanjing', 'xian', 'chengdu'];
        cities.forEach((city, index) => {
          data.push({
            label: city,
            key: index,
            // pinyin: pinyin[index]
          });
        });
        return data;
      };
    return {
      numberlimitM: { validator: this.checkMoneyM, trigger: 'change' },
      pubVue:this,
      creatSucess:true,//新建广告组，保存，取消按钮是否显示
      data: generateData(),
      activeName:"ownFlowPart",
      conversionNewOption:[
        {
          value:1,
          label:'下载'
        },
        {
          value:2,
          label:'激活'
        }
      ],
      adTeamtabsListPart:[{label:'自有流量',value:'2'},
                          {label:'联盟流量',value:'3'}],//推广流量
      deliveryModeList:[{label:'普通投放',value:0},{label:'跟随投放',value:1}],
      priceTypeOption:[],//出价方式
      sizeForm: {
          netPage:'',
          resourceFlow: '',//流量类型
          target:'',//应用ID
          scheduling:'',//排期
          setTimeCheckbox:'',//设置投放时间
          downloadSwitch:'',//打开并下载开关
          landingPage:'',//推广落地页——流量场景->信息流
          flowScene:'',//流量场景
          deliveryMode:'',//投放模式
          basePrice:'',//基础出价
          followApplication:'',//跟随应用
          highestBid:'',//跟随最高出价
          adTeamName:'',//广告组名称
          searchKeyWord:'',//搜索关键字
          payPrice:'',//关键词里的出价
          regionKey:'',//匹配模式
          directe:'',//人群包定向
          feDirecte:'',//人群包排除
          local:'',//定向-地域
          choosedDirRegion:'',//选择定向包
          localTransfer:'',//定向设置-人口属性-按城市
          network:'',//网络
          modelPrice:'',//机型价格
          appInstall:'',//APP安装
          userActive:'',//用户活跃
          userActiveInterest:'',//用户行为兴趣
          shieldChange:'',//屏蔽已转化用户
          shieldChecked:'',//另存为定向包
          brainPower:'',//智能拓量
          brainPowerList:[],//智能拓量穿梭框
          deepLink:'',//Deeplink链接
          checkboxGroupPrice:[],//按机型价格-一定是数组
          checkboxGroupNetwork:[],//按网络-一定是数组
          checkboxGroupYear:[],//按年龄-一定是数组
          activeDay:'',//用户活跃
          unActiveDay:'',//非活跃
          conversion:'',//转化类型
          resourceFlows:'',//定向
          address:'',//地域
          sex:'',//性别
          ages:'',//年龄
          zhPrice:'',//转化出价
          deriectName:'',//定向包名称
          priceType:'',
          conversion_cpd:'',
          deepZhType:'',//深度转化类型
          deepzhPrice:'',//深度转化出价

        },
        netPageOption:[],
        netPageSecondOption:[],
        value1Date:'',//排期
        rules: {
          deepzhPrice:[
            { required: true, message: '请输入深度转化出价', trigger: 'blur' }
          ],
          deepZhType:[
            { required: true, message: '请选择深度转化类型', trigger: 'change' }
          ],
          deriectName:[
            { required: true, message: '请输入内容', trigger: 'blur' }
          ],
          netPage:[
            { required: true, message: '请选择网页地址', trigger: 'change' }
          ],
          resourceFlow: [
            { required: true, message: '请选择流量类型', trigger: 'blur' }
          ],
          target: [
            { required: true, message: '请输入应用ID', trigger: 'blur' }
          ],
          conversion:[
            { required: true, message: '请选择转化类型', trigger: 'change' }
          ],
          conversion_cpd:[
            { required: true, message: '请选择转化类型', trigger: 'change' }
          ],
          landingPage: [
            { required: true, message: '请输入模板名称', trigger: 'blur' }
          ],
          // flowScene:[
          //   { required: true, message: '请选择流量场景', trigger: 'blur' }
          // ],
          deliveryMode:[
            { required: true, message: '请选择投放模式', trigger: 'blur' }
          ],
          basePrice:[
            { required: true, message: '请输入最低价', trigger: 'blur' }
          ],
          // priceType:[
          //   { required: true, message: '请选择出价方式', trigger: 'change' }
          // ],
          highestBid:[
            { required: true, message: '请输入最低价', trigger: 'blur' }
          ],
          followApplication:[
            { required: true, message: '请输入最低价', trigger: 'blur' }
          ],
          zhPrice:[
            { required: true, message: '请输入最低价', trigger: 'blur' }
          ],
          adTeamName:[
            { required: true, message: '请输入广告组名称', trigger: 'blur' }
          ]},//校验规则提示语

          yytgZyll:true,//自有流量对应的流量场景
          yytgLmll:false,//联盟流量对应的流量场景
          yytgLmll2:false,
          yytgLmll3:false,
          radio:1,
          targetOption:[],//应用ID的下拉数据
          targetData:{},//change应用ID之后后台返回的数据
          labelPosition:'left',//表单中的label左对齐
          targetShow:false,//应用IDchange后的图片和name显示
          pickerOptions: {
            disabledDate(date) {
              //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
              return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
            },
            shortcuts: [{
              text: '最近一周',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
              }
            }, {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
              }
            }, {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
              }
            }]
        },//日期快捷键
        timeUnitShow:false,//设置执行时间
        getPriceInfo:{},//获取出价的金额上限下限
        launch2_one:false,//跟随投放才有的
        dynamicPriceValue:'',//动态出价
        conversionOption:[],//转化类型
        conversionOption_cpd:[],//只有cpd下边动态出价打开时，有这个转化出价
        conversionShow:false,//出价类型的显示
        launch2_price:true,//动态出价显示
        landingSecond:'',//推广落地页联动
        landingPageOption:[],
        landingSecondOption:[
          {
            value:0,
            label:'默认模版'
          }
        ],
        // landingPageOption2:[{
        //   value:4,
        //   label:'萤火虫建站'
        // },{
        //   value:11,
        //   label:'萤火虫模板'
        // },{
        //   value:12,
        //   label:'互动落地页'
        // }],
        landingPageOption2:[],
        landingSecondOption2:[],
        appid:'',
        landingPages:true,
        launchMode:true,
        keyWords:false,
        landingPages2:false,
        pageTypeParams:'',
        optionOne:true,
        optionTwo:false,
        landingSecondOption3:[],
        ownerId:'',//获取的ownerId
        showResource:false,//定向
        zntl:false,//智能拓量
        addParams:{
          adSearchPremiumKwSaveDTOList: [{
            // categoryId: '',
            // keyword: '',
            // matchType: '',
            // price: '',
            // source: ''
          }],
          appId: '',
          autoOpenFlag: '',
          beginTime: '',
          billingType: '',
          dayLimit: '',
          deepOcpcPrice: '',
          deepOcpcType: '',
          deepUrl: '',
          deleteFlag: '',
          deptId: '',
          dynamicPrice: '',
          endTime: '',
          extensionFlow: '',
          extensionType: '',
          flowScene: '',
          instantAppId: '',
          instantAppUrl: '',
          ocpcOptmType: '',
          ocpcPrice: '',
          ocpcType: '',
          ownerId: '',
          pageId: '',
          pageType: '',
          pageUrl: '',
          planId: '',
          price: '',
          smartExpandBreakthrough: '',
          smartExpandType: '',
          targetId: '',
          targetROI: '',
          targetSource: '',
          timeLimit: '',
          timeSet: '',
          userId: '',
          userName: '',
          adGroupName:'',
          adServingType:'',
          smartExpandType:'',//智能拓量
          ocpcOptmType:'',//深度转化开关，0是关，2是开
          deepOcpcType:'',
          deepOcpcPrice:''
        },
        userId:'',
        userName:'',
        deptId:'',
        planId:'',
        appId:'',
        targetNewData:[],
        targetNewOption:[],
        targetNew:'',
        applistshow:true,
        launchPrice:true,
        typePriceShow:false,
        ocPrice:'',
        openClose:false,
        flowYytgZyll:[],
        targetIdIs:false,//判断是否新建定向
        extensionFlow:null,//
        flowScene:null,//
        yingyongID:true,
        deeplinkShow:false,
        teamInfo:this.newTeamVue.newPlanVue.teamInfo,
        extensionType:null,//
        // lowPrice:'最低出价1.5元',
        lowPrice:'最低出价1.5元',
        zhcjPrice:'最低出价1.2元',
        deepzhcjPrice:'最低出价1.2元',
        gensuilower:'最低出价1.5元',
        ocpcTYPE:'',//获取不同价格
        newApply:true,//目标那一块的显示问题
        newApplyTwo:false,
        netLink:'',
        netLinkShow:false,
        netLinkShowTwo:true,
        deepLinkValue:'',
        kindOfPrices:{},
        zntlya:false,
        lowPriceShow:'', //基础出价
        gslowPriceShow:'', //跟随出价
        zhlowPriceShow:'', //转化出价
        deepZhValue:'',//深度转化
        deepsionOption:[],//深度转化类型集合
        deepZh:false,//深度转化投放的展示
        deepShow:false,//打开深度转化投放显示
        priceba:''

    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = Number(this.$route.query.ownerId)
    this.ownerName = this.$route.query.ownerName
    this.planId = this.teamInfo.planId
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.extensionType = this.teamInfo.extensionType
    this.getApplyId()//应用id
    this.getPriceList()//出价列表
    this.changeExtensionFlow = 2
    this.extensionFlow = 2
    this.getScreen()
    this.sizeForm.adTeamName = this.teamInfo.planName+"_"+this.adTeamtabsListPart[0].label+this.dateFormat()
    this.sizeForm.resourceFlow = 2 //推广流量默认
    this.sizeForm.deliveryMode = 0 //投放模式默认
    this.addParams.adServingType = 0
    if(this.sizeForm.deliveryMode == 0){
      this.launch2_one = false
    }   
    if(this.extensionType==1){
      this.keyWords = true
    }
    if(this.extensionType==1&&this.extensionFlow ==2&&this.changeFlowScene == 6){
      this.keyWords = false
    }
  },
  methods: {
    dateFormat(){
      const dt = new Date(new Date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },  
    goPlanPage(){
      this.$router.push({
        path: '/plan-management/advertisingPlan',
        query: {
          id: this.ownerId,
          name:this.ownerName,
        }
      })
    },

    //change推广流量
    changePopu(val){
      console.log(val)
      this.changeExtensionFlow = Number(val)
      this.extensionFlow = this.changeExtensionFlow
      this.getScreen()
      this.sizeForm.adTeamName = this.teamInfo.planName+"_"+this.adTeamtabsListPart[val-2].label+this.dateFormat()
      this.sizeForm.landingPage = ''
      this.landingSecond = ''
      if(this.extensionType ==2 && val == 3){
        this.launchMode = false
        this.showResource = true
      } else if(this.extensionType ==2 && val == 2 ){
        this.showResource = false
        this.launchMode = true
        this.openClose = false
      } 
      if(val == 3){
        this.openClose = false
      }
      if(this.extensionType == 1 && val == 3){
        this.zntlya = true
      }
      if(this.extensionType == 1&& val == 2){
        this.zntlya = false
      }
      if(this.extensionType ==1 && val == 2){
        this.keyWords = true
      }else{
        this.keyWords = false
      }
      this.typePriceShow = false
      this.launchPrice = true
      this.deepZh = false
      
    },
    //流量场景
    getScreen(){
      let that = this
      let ownerId = that.ownerId//oppo正式站的ownerId 
      $http.axios.get("/api/advertisement/preaddgroup/typeFLOW_SCENEList?ownerId="+ownerId+"&extensionFlow="+that.changeExtensionFlow+"&extensionType="+that.extensionType).then(res=> {
        if(res.data.code == 200){
          if(res.data.content.length != 0){
            that.flowYytgZyll = res.data.content
            that.sizeForm.flowScene = res.data.content[0].value //流量场景默认
            that.addParams.flowScene = res.data.content[0].value
            that.changeFlowScene = res.data.content[0].value
            that.flowScene = res.data.content[0].value
            if(that.extensionType ==1){
              that.newApply = false
              that.newApplyTwo = true
              that.addParams.dynamicPrice = 2
            }
            if(that.extensionType ==1 && that.changeFlowScene == 5){
              that.showResource = true
              that.launch2_price = false
              // that.addParams.dynamicPrice = 2
            }
            that.getPriceType()
            that.kindOfPage() 
            that.kindOfPrice()
            if(that.extensionType ==2 && that.extensionFlow == 2){
              that.landingPages2 = false
              that.landingPages = true
            } else if(that.extensionType ==2 && that.extensionFlow == 3){
              that.landingPages2 = true
              that.landingPages = false
            }
            if(that.extensionType == 1){
              that.launchMode = false
            }
            
            
            
            // that.changeLandingPage2()
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //应用id接口
    getApplyId(){
      let that = this
      let ownerId = that.ownerId//oppo正式站的ownerId 
      $http.axios.get("/api/advertisement/preaddgroup/listHistoryAppInfo?ownerId="+ownerId).then(res=> {
        if(res.data.code == 200){
          that.targetOption = res.data.content
          that.targetNewOption = res.data.content
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    

    //change应用ID
    changeTarget(val){
      this.addParams.appId = val.appId
      this.targetShow = true
      this.targetData = val
    },
    targetIds(){
      let that = this
      let ownerId = that.ownerId
      let appid = that.$refs.shuruValue.query
      $http.axios.get("/api/advertisement/preaddgroup/AppInfo?appid="+appid+'&ownerId='+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          that.targetOption.push(res.data.data)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    gstargetIds(){
      let that = this
      let ownerId = that.ownerId
      let appid = that.$refs.gsshuruValue.query
        $http.axios.get("/api/advertisement/preaddgroup/AppInfo?appid="+appid+'&ownerId='+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          that.targetNewOption.push(res.data.data)     
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }) 
    },

    changeTargetNew(val){
      this.targetModel()
      let addIds = []
      addIds.push(val)
      this.addParams.followappidlist = String(addIds) //跟随应用id
    },
    
    targetModel(){
      let that = this
      that.appid = that.targetNew
      let ownerId = that.ownerId
      let num = 0
      $http.axios.get("/api/advertisement/preaddgroup/AppInfo?appid="+that.appid+'&ownerId='+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          if(that.targetNewData.length==0){
            that.targetNewData.push(res.data.data)
          }else{
            that.targetNewData.forEach((e,i)=>{
              if(e.appId == res.data.data.appId){
                num = 1
              }
            })
            if(num == 0){
              that.targetNewData.push(res.data.data)
            } 
          }
        } else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //设置执行时间显示弹框
    setCarryTime(){
      let that = this
      if(that.sizeForm.setTimeCheckbox == true){
        that.timeUnitShow = true
        that.addParams.timeLimit = 1
      }else{
        that.timeUnitShow = false
        that.addParams.timeLimit = 0
      }
    },

    //change流量场景
    changePriceType(val){
      this.changeFlowScene = Number(val)
      this.flowScene = this.changeFlowScene
      this.addParams.flowScene = this.changeFlowScene
      this.typePriceShow = false
      this.getPriceType()
      this.kindOfPrice()
      this.kindOfPage() 
      console.log(881)
      // this.$refs.deriectRefs.kindofstyle2()

      this.sizeForm.landingPage = ''
      this.landingSecond = ''
      if(val == 2){
        this.launch2_price = true
        this.landingPages = true
        this.launchMode = true
        this.keyWords = false
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.zntlya = false
        this.deepZh = false
        this.openClose = false
      }else if(val == 3){
        this.launch2_price = false
        this.landingPages = true
        this.launchMode = true
        this.keyWords = false
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.addParams.dynamicPrice = 2
        this.zntlya = false
        this.deepZh = false
        this.openClose = false
      } else if(val == 4){
        this.launch2_price = true
        this.landingPages = false
        this.launchMode = true
        this.keyWords = false
        this.landingPages2 = false
        this.showResource = false
        this.launchPrice = true
        this.zntlya = false
        this.deepZh = false
        this.openClose = false
      } else if(val == 5){
        this.launch2_price = true
        this.landingPages = true
        this.launchMode = false
        this.keyWords = true
        this.landingPages2 = false
        // this.showResource = false
        this.showResource = true
        this.launchPrice = true
        this.zntlya = false
        this.launch2_one = false
        this.deepZh = false
        this.openClose = false
      } else if(val == 6){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.addParams.dynamicPrice = 2
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.launch2_one = false
        this.deepZh = false
      }else if(val == 7){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.addParams.dynamicPrice = 2
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.deepZh = false
        this.openClose = false
      }else if(val == 8){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
        this.openClose = false
      }else if(val == 9){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
        this.openClose = false
      }else if(val == 10){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
        this.openClose = false
      }else if(val == 11){
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.openClose = false
        this.deepZh = false
      }else if(val == 12){
        console.log(121221)
        this.launch2_price = false
        this.landingPages = false
        this.launchMode = false
        this.keyWords = false
        this.landingPages2 = true
        this.showResource = true
        this.launchPrice = true
        this.zntlya = true
        this.addParams.smartExpandType = 0
        this.addParams.dynamicPrice = 2
        this.deepZh = false
        this.openClose = false
      }
      // if(this.extensionType ==1 && val == 5){
      //   this.showResource = true
      //   this.launch2_price = false
      //   this.addParams.dynamicPrice = 2
      // }
      if(this.extensionType ==1 && this.extensionFlow == 2 && val == 6){
        this.zntlya = true
        this.addParams.smartExpandType = 0
      }
      if(this.extensionType ==1 && this.extensionFlow == 3){
        this.zntlya = true
        this.addParams.smartExpandType = 0
      } 
      if(this.extensionType ==1 && this.extensionFlow == 2 && val == 5){
        this.keyWords = true
      }else{
        this.keyWords = false
      }

    },

    //获取出价方式
    getPriceType(){
      let that = this
      let ownerId = that.ownerId
        // $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=BILLING_TYPE").then(res=> {
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=BILLING_TYPE").then(res=> {
        if(res.data.code == 200){
          if(res.data.content.length != 0){
            that.priceTypeOption = res.data.content
            that.sizeForm.priceType = res.data.content[0].id
            that.addParams.billingType = res.data.content[0].id
            if(res.data.content[0].id == 1){
              that.launch2_price = true
              that.addParams.dynamicPrice = 0
            }else{
                that.launch2_price = false
                that.addParams.dynamicPrice = 2
              }
            if(that.extensionType == 2 && that.extensionFlow == 2 && that.changeFlowScene == 3){
              that.launch2_price = false
              that.addParams.dynamicPrice = 2
            }
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })

    },

    //出价列表接口（控制输入最大最小值）
    getPriceList(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/groupPrice?ownerId="+ownerId).then(res=> {
        if(res.data.code == 0){//这个成功的时候code是0
          that.getPriceInfo = res.data.data
          // that.sizeForm.basePrice >= res.data.data.floorBid/80
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change投放模式
    changeLaunchMode(val){
      if(val == 1){
        this.launch2_one = true
        this.launch2_price = false
        this.addParams.adServingType = 1
      }else{
        this.launch2_one = false
        this.launch2_price = true
        this.addParams.adServingType = 0
      }
    },

    dataFilter(val) {
        this.targetNew = val;
        if (val) { //val存在
          this.targetNewOption = this.targetOption.filter((item) => {
            if (!!~item.appName.indexOf(val) || !!~item.appName.toUpperCase().indexOf(val.toUpperCase())) {
              return true
            }
            // if (!!~item.appId.indexOf(val) || !!~item.appId.toUpperCase().indexOf(val.toUpperCase())) {
            //   return true
            // }
          })
        } else { //val为空时，还原数组
          this.targetNewOption = this.targetOption;
        }
      
    },

    closeAppList(i){
      this.targetNewData.splice(i,1)
    },

    //change动态出价
    changeTrendsPrice(val){
      if(val == 1){
        this.conversionShow = true
        this.getChangeType()
        this.addParams.dynamicPrice = 1 //开启
        this.getChangeType_cpd()
      } else if(val == 0) {
        this.conversionShow = false
        this.addParams.dynamicPrice = 0 //关闭
      }
    },

    //转化类型
    getChangeType(){
      let that = this
      let ownerId = that.ownerId
        // $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=OCPC_TYPE").then(res=> {
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=OCPC_TYPE").then(res=> {
        if(res.data.code == 200){  
          that.conversionOption = []        
          res.data.content.forEach(function(e,i){
            if(e.id != 4){
              that.conversionOption.push(e)
            }
          })
          if(res.data.content.length != 0){
            that.sizeForm.conversion = res.data.content[0].name
            that.addParams.ocpcType = res.data.content[0].id
            that.ocpcTYPE = res.data.content[0].id
          }
          // this.kindOfPrice()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    getChangeType_cpd(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&extensionFlow="+that.extensionFlow+"&type=DYNAMIC_PRICE").then(res=> {
        if(res.data.code == 200){
          that.conversionOption_cpd = res.data.content
          if(res.data.content.length != 0){
            that.sizeForm.conversion_cpd = res.data.content[0].name
            that.addParams.ocpcType = res.data.content[0].id
          }
          // this.kindOfPrice()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //各个出价的价格
    kindOfPrice(){
      let that = this
      let ownerId = that.ownerId
      if(that.ocpcTYPE == ''){
        that.ocpcTYPE = 0
      }
      // $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=OCPC_TYPE").then(res=> {
      $http.axios.get("/api/advertisement/preaddgroup/groupPrice?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&ocpcType="+that.ocpcTYPE).then(res=> {
        if(res.data.code == 0){
          that.kindOfPrices = res.data.data
          that.lowPrice = "最低出价"+res.data.data.floorBid/100+"元"
          that.zhcjPrice = "最低出价"+res.data.data.oFloorBid/100+"元"
          that.gensuilower = "最低出价"+res.data.data.fFloorBid/100+"元"
          that.deepzhcjPrice = "最低出价"+res.data.data.dFloorBid/100+"元"
          that.lowPriceShow = res.data.data.floorBid/100 //基础出价最低
          that.gslowPriceShow = res.data.data.fFloorBid/100 //跟随出价最低
          that.zhlowPriceShow = res.data.data.oFloorBid/100 //转化出价最低
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     //各个落地页
     kindOfPage(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&type=PAGE_TYPE&extensionFlow="+that.extensionFlow).then(res=> {
        if(res.data.code == 200){
          that.landingPageOption = res.data.content
          that.landingPageOption2 = res.data.content
          that.netPageOption = res.data.content
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change落地页类型
    changeLandingPage(val){
      this.landingPageOption = []
      this.pageTypeParams = val
      this.addParams.pageType = val
      if(this.landingPages == false){
        this.addParams.pageType = 9 //落地页为应用落地页时为9
      }
      if(val == 9){
        this.landingSecond = '默认模版'
        this.addParams.pageId = 0
      }
      
      //this.getLandingPage()
    },

    changeNet(val){
      this.addParams.pageType = val
      if(val == 0){
        this.netLinkShow = true
        this.netLinkShowTwo = false
        this.addParams.pageUrl = this.netLink
      }else{
        this.netLinkShowTwo = true
        this.netLinkShow = false
      }
      this.pageTypeParams = val
      this.getLandingPage()
      
    },

    //推广落地页的后边联动下拉框
    getLandingPage(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/sparkPageNewlist?ownerId="+ownerId+"&pageType="+that.pageTypeParams).then(res=> {
        if(res.data.code == 0){
          that.landingSecondOption2 = res.data.data
          that.netPageSecondOption = res.data.data
          if(res.data.data == []){
            that.landingSecondOption = that.landingSecondOption
          }else{
            that.landingSecondOption = res.data.data
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //推广落地页的模板接口
    getLandingMode(){
      let that = this
      let ownerId = that.ownerId
      let extensionFlow = that.extensionFlow
      let flowScene = that.changeFlowScene
      $http.axios.get("/api/advertisement/preaddgroup/h5Templatelist?ownerId="+ownerId+"&extensionFlow="+extensionFlow+"&flowScene="+flowScene).then(res=> {
        if(res.data.code == 0){
          that.landingSecondOption3 = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //不同场景change
    changeLandingPage2(val){
      console.log(val)
      this.openClose = false
      this.addParams.pageType = val
      this.pageTypeParams = val
      if(val == 4){
        this.landingSecond = ''
        this.optionTwo = false
        this.optionOne = true
        // this.openClose = false
        this.getLandingPage()
      } else if(val == 11){
        this.landingSecond = ''
        this.optionTwo = true
        this.optionOne = false
        // this.openClose = true
        this.getLandingMode()
      }else if(val == 12){
        this.landingSecond = ''
        this.optionTwo = false
        this.optionOne = true
        // this.openClose = false
        this.getLandingPage()
      }
    },
    changeNewPage(val){
      console.log(val)
      if(this.extensionFlow == 2&&this.changeFlowScene == 6&&this.pageTypeParams ==11){
        this.openClose = true
      }
      
      this.addParams.pageId = val.h5Id
      this.addParams.pageUrl = val.h5Url
    },
    changeNewPage11(val){
      // this.addParams.pageId = Number(val.pageId)//之前是不传的，现在待确认
      this.addParams.pageUrl = val.pageUrl
    },
    changeNetChildren(val){
      this.addParams.pageUrl = val.pageUrl
    },
    changeopenOrclose(val){
      this.addParams.autoOpenFlag = val
    },

    //智能拓量开启关闭
    changeZntl(val){
      this.addParams.smartExpandType = Number(val)
    },

    //change排期日期
    changeDateSlot(val){
      this.addParams.dayLimit == 1
      this.addParams.beginTime = val[0] //开始日期
      this.addParams.endTime = val[1] //结束日期
    },

    //出价方式
    priceTypeChange(val){
      this.priceba = val
      // if(this.extensionFlow == 3&&this.changeFlowScene == 11&&this.priceba == 5){
      //   this.deepZh = true
      // }else{
      //   this.deepZh = false
      // }
      this.addParams.billingType = Number(val) //出价方式
      if(val == 1){
        this.launch2_price = true
      }else{
        this.launch2_price = false
        this.addParams.dynamicPrice = 2
      }
      if(val == 5){
        this.getChangeType()
        this.typePriceShow = true
        this.launchPrice = false
      }else{
        this.typePriceShow = false
        this.launchPrice = true
      }
    },

    //转化类型
    changeZHType(val){
      this.addParams.ocpcType = val
      this.ocpcTYPE = val
      // this.kindOfPrice()
    },

    changeZHTypeNew(val){
      this.addParams.ocpcType = val
      this.ocpcTYPE = val
      if(this.extensionType == 2&&this.extensionFlow == 3&&this.changeFlowScene == 11 && val == 2){
        this.deepZh = true
      }else{
        this.deepZh = false
      }
      // this.kindOfPrice()
    },
    submitFn(){
      let that = this     
      if(that.showResource == true){
        if(that.$refs.deriectRefs.xchange == 2){
          that.$refs.deriectRefs.submitDirection()
        }else if(that.$refs.deriectRefs.xchange == 1){
          that.addParams.targetId = that.$refs.deriectRefs.xtargetId
          that.onSubmit(that.$refs.deriectRefs.xtargetId)
        }else{
          that.onSubmit()
        }
      }else{
        that.onSubmit()
      }
      
    },
    //保存
    onSubmit(targetId) {
      //关键词
      let that = this
      if(that.keyWords == true){
        let addTableDatas = this.$refs.keyWordRef.addTableData
        let arr = []
        addTableDatas.forEach(function(e,i){
          if(e.type == 'ggc'){
            arr.push(
              {
                // categoryId: '',
                keyword: e.bidword,
                matchType: e.matchType,
                price: e.exprice*100,
                source: '1'
              }
            )
          }else if(e.type == 'lmcb'){
            arr.push(
              {
                categoryId: e.kwId,
                keyword: e.kwName,
                matchType: '3',
                price: e.exprice*100,
                source: '2'
              }
            )
          }else{
            arr.push(
              {
                // categoryId: e.kwId,
                keyword: e.bidword1,
                matchType: e.matchType,
                price: e.exprice*100,
                source: '5'
              }
            )
          }
        })
        that.addParams.adSearchPremiumKwSaveDTOList = arr
      }
      

      //每个表单必填
      that.addParams.planId = that.planId
      that.addParams.ownerId = that.ownerId
      that.addParams.userId = that.userId
      that.addParams.userName = that.userName
      that.addParams.deptId = that.deptId,
      that.addParams.adGroupName = that.sizeForm.adTeamName //广告组名称
      //本表单必填（应用推广-自有流量-精选推荐-普通投放）
      that.addParams.extensionFlow = that.changeExtensionFlow //推广流量
      // that.addParams.flowScene = that.changeFlowScene //流量场景
      // that.addParams.dayLimit = 0 //0-不限制推广日期1-限制推广日期，当为1时beginTime和endTime必传
      if(that.sizeForm.scheduling== ''){
        that.addParams.dayLimit = 0
      }else{
        that.addParams.dayLimit = 1
      }
      if(that.addParams.timeLimit == 1){
        let timeString = that.$refs.timeStrArray.timeContent
        let slot = {}
        timeString.forEach(function(e,i){
          let week=i+1
          slot[week]=String(e.arr)
        })
        that.addParams.timeSet = JSON.stringify(slot) //时间段设置
      }
      if(that.launchMode == false){
        that.addParams.price = ''
      }
      that.addParams.price = that.sizeForm.basePrice*100 //基础出价
      that.addParams.extensionType = that.extensionType, //推广目标
      that.addParams.followMaxPrice = that.sizeForm.highestBid*100 //跟随最高出价
      that.addParams.ocpcPrice = that.sizeForm.zhPrice*100
      that.addParams.targetId = targetId || ''
      that.addParams.deepUrl = that.deepLinkValue
      that.addParams.deepOcpcPrice = that.sizeForm.deepzhPrice*100//深度转化出价
      $http.axios.post("/api/advertisement/adGroup/v1/addGroup",that.addParams).then(res=> {
        if(res.data.code == 200){
          let creatInfo
          that.newTeamVue.newPlanVue.creatShowIs = true
          creatInfo={
            "planId":res.data.content.planid || that.planId,//计划ID
            "planName":res.data.content.planname,//计划名称
            "adGroupId":res.data.content.adgroupid,//广告组ID
            "adGroupName":res.data.content.adgroupname,//广告组名称
            "extensionType":res.data.content.extensiontype,//推广目的ID
            // "extensionTypeName":res.data.content.extensionTypeName,//推广名称
            "flowScene":res.data.content.flowscene,//
            // "flowSceneName":res.data.content.flowSceneName,//
            "extensionFlow":res.data.content.extensionflow,//
            // "extensionFlowName":res.data.content.extensionFlowName,//
            }
            that.creatSucess = false
            that.newTeamVue.newPlanVue.creatInfo = creatInfo
            that.$message({
              message: 'success',
              type: 'success'
            });

        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    checkMoneyM (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    },

    //基础出价判断
    changeMoney(){
      if(this.sizeForm.basePrice < this.lowPriceShow){
        this.$message({
          message: '不能低于'+this.lowPriceShow,
          type: 'warning'
        })
        this.sizeForm.basePrice = ''
      }
    },
    //跟随出价判断
    changegsMoney(){
      if(this.sizeForm.highestBid < this.gslowPriceShow){
        this.$message({
          message: '不能低于'+this.gslowPriceShow,
          type: 'warning'
        })
        this.sizeForm.highestBid = ''
      }
    },
    //转化出价判断
    changezhMoney(){
      if(this.sizeForm.zhPrice < this.zhlowPriceShow){
        this.$message({
          message: '不能低于'+this.zhlowPriceShow,
          type: 'warning'
        })
        this.sizeForm.zhPrice = ''
      }
    },

    //深度转化类型
    getDeepsionOption(){
      let that = this
      let ownerId = that.ownerId
      $http.axios.get("/api/advertisement/preaddgroup/getconfig?ownerId="+ownerId+"&flowScene="+that.changeFlowScene+"&extensionType="+that.extensionType+"&extensionFlow="+that.extensionFlow+"&type=DEEP_OCPC_TYPE").then(res=> {
        if(res.data.code == 200){
          that.deepsionOption = res.data.content
          // that.sizeForm.deepZhType = res.data.content[0].name
          // that.addParams.deepOcpcType = res.data.content[0].id
          if(res.data.content.length!=0){
            that.sizeForm.deepZhType = res.data.content[0].name
            that.addParams.deepOcpcType = res.data.content[0].id
          }
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //深度转化
    changeDeepZhValue(val){
      this.getDeepsionOption()
      if(val == 2){
        this.deepShow = true
      }else{
        this.deepShow = false
      }
      this.addParams.ocpcOptmType = val
    },

    //深度转化类型
    changeDeeplx(val){
      this.addParams.deepOcpcType = val
    }
  }
}
</script>
<style lang="scss">
  .adGroup{
    width:100%;
    height:2px;
    background:#F4F4F4;
    margin:30px 0 20px;
  }
  .el-radio-group .el-radio--medium.is-bordered{
    padding: 0 10px;
  }
 .el-radio-group .el-radio--mini.is-bordered{
    padding:0 8px;
    height: auto;
    line-height: 30px;
  }
  .el-form-item--mini .el-form-item__content{
    // 需要跟这个宽度一致label-width="90px"
    // float: left;//对dayLimitDialog页面有影响
    margin-left: 0;
  }
  .el-input{
    width: auto;
  }
  .partTitle{
    display:block;
    font-size: 18px;
    margin-bottom:20px;
  }
  .title{
    display: block;
    margin-bottom:15px;
    font-size: 16px;
  }
  .targetBox{
    margin-top:15px;
  }
  .targetBox_img,.targetBox_name{
    display:inline-block;
    vertical-align:middle;
    margin-right:15px;
  }
  .targetBox_img{
    width:50px;
    height:50px;
  }
  .targetBox_img img{
    width:100%;
  }
  /* .setTime{
    margin-left:100px;
  } */
  .launchBox{
    width:50%;
    padding:30px 20px;
    border:1px solid #ddd;
    border-radius:5px;
    /* margin-left:100px; */
    margin-top:20px;
  }
  .launchBox li {
    position: relative;
    display:inline-block;
    vertical-align:middle;
    padding:10px 20px;
    /* margin:10px 1%; */
  }
  .launchBox li .closeApp{
    position:absolute;
    top:-10px;
    right:0;
    font-style:normal;
    font-size:10px;
    width:14px;
    height:14px;
    border-radius:50%;
    border:1px solid #ddd;
    color:#ddd;
    text-align:center;
    line-height:14px;
    cursor: pointer;
  }
  .newtransfer{
    margin:30px 0 10px;
    /* margin-left:110px; */
  }
  /* .newtransfer .el-transfer-panel__item + .el-transfer-panel__item{
    margin-left:15px!important;
  } */
</style>