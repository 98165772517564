import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';

// ui组件库
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// one-ui组件
import Conponents from './components/index';

// 通用vue混入插件
import Emitter from './mixins/Emitter';
import TableMixin from './mixins/TableMixin';

// 工具
import moment from 'moment';
import Lodash from 'lodash';
import utils from './utils';

// 国际化资源，包含element-ui的资源
import i18n from './i18n';
// iconfont
import './assets/iconfont/iconfont.css';

const { Http, Storage, CommonRouter } = utils;

Vue.use(VueCookies);
Vue.use(VueRouter);
// 注册ElementUI
Vue.use(ElementUI, {
  size: 'mini',
  zIndex: 3000,
  i18n: (key, value) => i18n.t(key, value)
});

// 挂载工具到组件this上
Vue.prototype.$moment = moment;
Vue.prototype.$Lodash = Lodash;
Vue.prototype.$localStorage = Storage.local;
Vue.prototype.$sessionStorage = Storage.session;

export default {
  Vue,
  VueRouter,
  Vuex,
  VueI18n,
  i18n,
  moment,
  Lodash,
  $http: Http,
  Emitter,
  TableMixin,
  Message: ElementUI.Message,
  $localStorage: Storage.local,
  $sessionStorage: Storage.session,
  $cookies: VueCookies,
  CommonRouter,
  ...Conponents
};
