<template>
  <div>
    <div class="labelName">
      <i>*</i>
      <span>推广计划名称</span>
    </div>
    <!-- <el-select v-model="applyName" placeholder="请选择已有计划" class="planTname">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select> -->
    <el-select
    v-model="value"
    filterable
    remote
    placeholder="请输入关键词"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="choosePlanFn">
    <el-option
      v-for="(item,i) in options"
      :key="i"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
    <a class="dingX" href="https://e.oppomobile.com/creative/index.html#/store" target="_blank">商店落地页</a>
    <a class="dingX" href="https://e.oppomobile.com/station/index.html#/tempList" target="_blank">萤火虫建站</a>
    <a class="dingX" href="https://e.oppomobile.com/market/tool/index.html?appFrom=marketsV3#/tool/dmp/a" target="_blank">人群包</a>
    <a class="dingX" href="https://e.oppomobile.com/market/tool/index.html?appFrom=marketsV3#/tool/direction/d" target="_blank">定向包</a>
      <!-- <el-button type="primiry" size="small" @click="remoteMethod(value)">搜索</el-button> -->
  </div>
</template>
<script>
import config from '@/api/config';
import { $http } from '@/api/http';
import adTeamHeaderPart from "./adTeampart/adTeamHeaderPart";//应用推广组件
import adCreativity from "./adcreativity";//广告创意
export default {
  data() {
    return {
        options: [],
        value: [],
        list: [],
        loading: false,      
        applyName:'',
        states:[],
        tableData:[],
        type:null,
    }
  },
  props:{
    newPlanVue:Object
  },
  mounted(){
    this.ownerId = Number(this.$route.query.ownerId)
    this.ownerName = this.$route.query.ownerName
    this.type = this.$route.query.type
    console.log(666)
    // this.getPlanListFn({
    //   "ownerId": this.ownerId,
    //   "planNameLike":""
    // })
    this.getPlanListFn(this.ownerId)

  },
  methods: {
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },
    selectPlan(val){
      console.log(val)
      let text = '修改广告计划，已填写的内容将会被清空'
          this.$confirm(text,"修改广告计划",{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(()=>{
            this.choosePlanFn(val)
          }).catch(()=>{
            
          })
    },
    choosePlanFn(value){
      console.log(value)
      let _this = this,teamInfo = {}
      _this.newPlanVue.teamShowIs = false
      _this.tableData.forEach(obj=>{
        if(value == obj.planId){
          teamInfo={
            "planId":obj.planId,//计划ID
            "planName":obj.extensionTypeName,//推广目的名称
            "extensionType":obj.extensionType,//推广目的ID
            "extensionTypeName":obj.extensionTypeName}
        }
      })
      // // _this.newPlanVue.teamShowIs = true
      // _this.newPlanVue.teamInfo = teamInfo
      // // _this.newPlanVue.creatShowIs = false
      // setTimeout(function(){
        if(_this.type == '30'){
          _this.getTeamListFn(_this.ownerId,teamInfo)
          //  _this.newPlanVue.teamShowIs = true
      // _this.newPlanVue.creatShowIs = false
        }else{
          _this.newPlanVue.teamShowIs = true
          _this.newPlanVue.teamInfo = teamInfo
        }
      // },100)
      
        
      
    },
    //查询广告计划列表
    getTeamListFn(id,teamInfo,name){
      let _this = this,params = {},states = []
      params = {
        "adGroupNameLike": name,
        "ownerId": id,
        "planIds": teamInfo.planId
      }
      $http.axios.post("/api/advertisement/adGroup/v1/list",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.newPlanVue.teamShowIs = true
          _this.newPlanVue.teamInfo = teamInfo
          _this.newPlanVue.creatShowIs = false
          if(res.data.content != null && res.data.content.length != 0){
              _this.newPlanVue.tableData = res.data.content
              _this.newPlanVue.tableData.forEach((obj,i) => {
                states.push({value:obj.adGroupId,label:obj.adGroupName})
              });
              _this.newPlanVue.states =states
              _this.newPlanVue.options = _this.newPlanVue.states
              // _this.newPlanVue.$refs.teamPage.hadTamList = true
          }else{
            _this.newPlanVue.states =[]
              // _this.newPlanVue.$refs.teamPage.hadTamList = false
              // _this.newTeamVue.tabsListPart = [{label:"新建广告组",name:"nTeam",content:_this.newTeamVue.nTeam}]
          }
          console.log(_this.newPlanVue.states)
        }else{
          _this.newTeamVue.tabsListPart = [{label:"新建广告组",name:"nTeam",content:_this.newTeamVue.nTeam}]
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
    remoteMethod(query) {
      let _this = this
      if (query !== '') {
        _this.loading = true;
        // _this.getPlanListFn(this.ownerId,query)
        setTimeout(() => {
          _this.loading = false;
          console.log(222)
                  _this.getPlanListFn(_this.ownerId,query)
          _this.options = _this.states.filter(item => {
            return item.label.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.options = [];
        }
    },
    handleClick(tab, event) {
      // this.$store.commit('handleClick', tab.name)//
    },
    //查询广告计划列表
    getPlanListFn(id,name){
      let _this = this,params = {},states = []
      params = {
        'ownerId':id,//'1000092281',//id
        'planNameLike':name
      }
      $http.axios.post("/api/advertisement/adPlan/v1/getPlanList",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          if(res.data.content != null){
              _this.tableData = res.data.content
              _this.tableData.forEach((obj,i) => {
                states.push({value:obj.planId,label:obj.planName})
              });
              _this.options = states
              _this.states = states
          }
          
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
  }
  }
</script>
<style lang="scss" scoped>
  .labelName{
    i{
      color:red;
      vertical-align:middle;
      padding-right:5px;
    }
    span{
      font-size:14px;
    }
  }
  .planTname{
    margin-top:10px;
  }
  .dingX{
    font-size: 12px;
    background-color: #66856d;
    border-radius: 4px;
    padding: 6px 10px;
    color: #fff;
    margin: 0 10px;
    box-sizing: border-box;
  }
</style>