import { Message } from 'element-ui';

/**
 * 消息提示组件，页面上只会出现一次，上一次会被关闭
 */
let messageInstance = null;
const PocResetMessage = options => {
  if (messageInstance) {
    messageInstance.close(); //关闭
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
  PocResetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    return PocResetMessage(options);
  };
});

export default PocResetMessage;
