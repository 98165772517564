<template>
  <i class="iconfont" :class="'icon-oneui-' + name"></i>
</template>

<script>
export default {
  name: 'PocIcon',

  props: {
    name: String
  }
};
</script>
