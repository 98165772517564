 
 import { $http } from '@/api/http';
 import PublicisCommon from 'publicis-common';
 const { $sessionStorage,Vue } = PublicisCommon;

  var dataArry = []
 //获取用户列表
 async function getUserList(){
  let that = this
  // let ccPeople = []
  dataArry = await $http.axios.get("/api/system/user/optionSelectCopyUser")
  if(dataArry.data.code === 200) {
    $sessionStorage.setItem('optionSelectCopyUser',dataArry.data.data)
    dataArry = dataArry.data.data
  } else {
    Vue.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
  }
  return dataArry
  
  // .then(function(response) {
  //   if(response.data.code == 200){
  //     $sessionStorage.setItem('optionSelectCopyUser',response.data.data)
  //     cc_options = $sessionStorage.getItem('optionSelectCopyUser')
  //     // Vue.$set(cc_options ,'', $sessionStorage.getItem('optionSelectCopyUser'))

  //     console.log(cc_options)
  //   } else {
  //     that.$message({
  //       showClose: true,
  //       message: response.data.msg,
  //       type: 'error'
  //     })
    // }
  // })
}
export default getUserList