// 组件
import Container from './container';
import Table from './table';
import CardTable from './card-table';
import Icon from './icon';
import PocResetMessage from './reset-message';
import PocColor from './color';
import PageHeader from './page-header';

// 所有组件列表
const components = [Container, Table, CardTable, Icon, PocColor, PageHeader];

// 定义 install 方法，接收 Vue 作为参数
const install = function(Vue) {
  // 判断是否安装，安装过就不继续往下执行
  if (install.installed) return;
  install.installed = true;
  // 遍历注册所有组件
  components.forEach(component => Vue.component(component.name, component));
  // 挂载到组件的this上
  Vue.prototype.$PocResetMessage = PocResetMessage;
};

// 检测到 Vue 才执行
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  OneUI: {
    version: '0.1.0',
    install,
    // 所有组件，必须具有 install，才能使用 Vue.use()
    Container,
    Table,
    CardTable,
    Icon,
    PocColor,
    PageHeader
  },
  PocResetMessage
};
