<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
              <el-breadcrumb-item>关键词</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" @click="addNegKeywordsWrap">添加为否定关键词</el-button>
            <el-button type="primary" size="small" style="float:right;" @click="zdyFn">自定义列</el-button>
          </div>
        </div>
        <div class="chanelTab">
          <span class="channel_oppo" @click="clickChangeChannel(1)">广告系列</span>
          <span class="channel_vivo" @click="clickChangeChannel(2)">广告组</span>
          <span class="channel_vivo channelActive">关键词</span>
          <span class="channel_vivo" @click="clickChangeChannel(4)">广告</span>
        </div>
        <div class="chanelTabSecondary">
          <span @click="keyWords(1)">关键词</span>
          <span @click="keyWords(2)">否定关键词</span>
          <span class="channeSelActive">搜索词</span>
        </div>

        <div class="materialHead">
          <div class="materialHBtns clearfix queryLeft">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <el-date-picker
                    v-model="dateValue"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="dateStyle"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="kwNameShow" style="width:100px;" @change="changeKwName">
                    <el-option
                      v-for="(item,i) in kwNameShowOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input v-model = "grouplistName" clearable :placeholder="kwNameList" style="width:160px;"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" size="small" @click = "getSearchList(1)">查询</el-button>
                  <el-button type="info" size="small" plain @click = 'resetForm'>重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
            <div class="listDetail queryRight">
              <span>
                计划系列名称：
                <el-select 
                  :placeholder="campaignName"
                  @change="changeAdplanLabel"
                  value-key="id"
                  v-model="adplanLabel">
                  <el-option
                    v-for="(item,i) in adPlanxialaOption"
                    :key="i"
                    :label="item.name"
                    :value="item">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{ item.id }}</span>
                  </el-option>
                </el-select>
              </span>
              <span style="margin-left:10px;">
                <span v-if="changeSupplySources == ''">
                  <i v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else>投放位置：搜索标签</i>
                </span>
                <span v-else>
                  <i v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else>投放位置：搜索标签</i>
                </span>
              </span>
            </div>
        </div>
          <div class="clearfix">   
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @sort-change="handlesortChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="searchText"
                  label="搜索词"
                  width="220"
                  fixed>
                  <template slot-scope="scope">
                    <span v-if="scope.row.searchText == null" style="color:#ccc;">(低展示词)</span>
                    <span v-else>{{scope.row.searchText}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="kwName"
                  label="关键词"
                  width="220"
                  fixed>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="匹配类型"
                  fixed>
                  <template slot-scope="scope">
                    <span v-if="scope.row.matchType == 'EXACT'">完全匹配</span>
                    <span v-else>广泛匹配</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="bidAmount"
                  width="180"
                  sortable
                  label="默认最高出价">
                </el-table-column>
                <template v-for="(item, index) in titleList">
                  <el-table-column
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    width="170px"
                    sortable>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>

          <!-- 添加为否定关键词 -->
          <el-dialog 
            title="搜索词添加为否定关键词" 
            width="820px"
            class="kwWrap"
            center
            :visible.sync="teamKeywordsVisible">
            <div class="kwIcon"><img :src="kwIcon"></div>
            <div class="linkage">
              <div class='selectKw'>
                <div class='selectKwFrom'>添加自</div>
                <div class='selectKwName'>广告系列名称：{{campaignName}}</div>
              </div>
              <div class="linkageType linkageTypeNew selectKw">
                <label class='selectKwFrom'>添加至</label>
                <div class="kworNegRadio selectKwName">
                  <el-radio v-model="kwRadio" label="1" @change="changeAdPlan">广告系列</el-radio>
                  <el-radio v-model="kwRadio" label="2" @change="changeAdGroup">广告组</el-radio>
                </div>

                <div class="addKwWrap" v-if="adPlanShow">
                  <div class="addKwAdgroup ">
                    <el-select 
                      v-model="adPlanModel"
                      value-key="id"
                      multiple
                      @change="changeKwGroup"
                      placeholder="请选择广告系列">
                      <el-option
                        v-for="(item,i) in adPlanModelOption"
                        :key="i"
                        :label="item.name"
                        :value="item">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right;padding-right:20px; color: #8492a6; font-size: 13px">{{ item.id }}</span>
                      </el-option>
                    </el-select>
                  </div>
                  <div class="addKwAdgroupList">
                    <!-- <div class="addKwgroupList addKwAdList" v-for="(item,i) in changeTableData" :key="i"> -->
                    <div class="addKwgroupList addKwAdList" v-for="(item,i) in adPlanModel" :key="i">
                      <span>{{item.name}}</span>
                      <span style="text-align: right;color:#999;">{{item.id}}</span>
                      <span class="el-icon-error addKwDelete" @click="addGroupDelete(i)"></span>
                    </div>
                  </div>
                </div>

                <div class="addKwWrap" v-if="adGroupShow">
                  <div class="addKwWrap11">
                    <div class="addKwAdgroup11">
                      <el-select 
                        v-model="adPlanModelone"
                        @change="changeadPlanOne"
                        placeholder="请选择广告系列">
                        <el-option
                          v-for="(item,i) in adPlanModeloneOption"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="addKwAdgroup11 addKwAdgroupGroup">
                      <!-- <el-select 
                        v-model="adTeamModel"
                        value-key="id"
                        multiple
                        @change="changeKwGroupOne"
                        placeholder="请选择广告组">
                        <el-option
                          v-for="(item,i) in adTeamModelOption"
                          :key="i"
                          :label="item.name"
                          :value="item">
                          {{item.name}}
                          <span style="float: left">{{ item.name }}</span>
                          <span style="float: right;padding-right:20px; color: #8492a6; font-size: 13px">{{ item.id }}</span>
                        </el-option>
                      </el-select> -->
                      <el-select 
                        v-model="adTeamModelnew"
                        value-key="id"
                        @change="changeKwGroupOne"
                        placeholder="请选择广告组">
                        <el-option
                          v-for="(item,i) in adTeamModelOption"
                          :key="i"
                          :label="item.name"
                          :value="item">
                          {{item.name}}
                          <span style="float: left">{{ item.name }}</span>
                          <span style="float: right;padding-right:20px; color: #8492a6; font-size: 13px">{{ item.id }}</span>
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="addKwAdgroupList">
                    <div class="addKwgroupList addKwAdList" v-for="(item,i) in changeTableDataOne" :key="i">
                      <span>{{item.name}}</span>
                      <span style="text-align: right;color:#999;">{{item.id}}</span>
                      <span class="el-icon-error addKwDelete" @click="addKwGroupDelete(item,i)"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="linkageTypes">
                <label>匹配类型</label>
                <el-select 
                  v-model="groupMatchType">
                  <el-option
                    v-for="(item,i) in groupMatchTypeOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="teamKeyword">
              <div slot="footer" class="dialog-footer" style="margin-top:20px;padding-bottom:30px;text-align:center;">
                <el-button class="newDialogCancel" type="primary" size="middle" @click="teamKeywordsVisible = false" >取消保存</el-button>
                <el-button class="newDialog" type="primary" size="middle" @click="addNegKwSure">确定保存</el-button>
              </div>
            </div>
          </el-dialog>
          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
        </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <zdyApple :visible.sync="showzdyDialog" ref = 'zdyApple' :pubVue="pubVue"></zdyApple>
  </div>
</template>

<script>
import kwIcon from '@/assets/img/kw.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import zdyApple from './zdyApple'

export default {
  components: {
    zdyApple
  },
  data() {
    return {
      kwIcon,
      pubVue: this,//空vue，用于子组件之间传值
      showzdyDialog:false,
      showDialog: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showSingleDialog:false,
      nowIs : false,
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      nameId:'',//请输入计划ID查找
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      tableData:[],
      // tableData:[{
      //   'searchText':'cad看图王',
      //   'kwName':'cad看图王',
      //   'bidAmount':'3.00'
      // },{
      //   'searchText':'cad看图王1',
      //   'kwName':'cad看图王1',
      //   'bidAmount':'4.00'
      // },{
      //   'searchText':'cad看图王2',
      //   'kwName':'cad看图王2',
      //   'bidAmount':'1.00'
      // }],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:'',
      planType:30,
      dialogTitle:'批量调价',
      userContent:{},//登录用户信息&弹框默认
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      // closeGroupList:[],
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',

      //苹果渠道广告组
      formLabelWidth:'120px',
      adSeries:'',
      adSeriesOption:[{
        label:'广告系列1',
        value:1
      },{
        label:'广告系列2',
        value:2
      }],
      adGroupApple:'',
      adGroupAppleOption:[{
        label:'广告组1',
        value:1
      },{
        label:'广告组2',
        value:2
      }],
      pickerOptions: {
        shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue:[],//搜索时间
      adplanStatu:'',//搜索状态
      adplanStatuOption:[{//状态选项
        value:'BROAD',
        label:'广泛匹配'
      },{
        value:'EXACT',
        label:'完全匹配'
      }],
      adplanUseStatu:'',//搜索启用状态
      adplanUseStatuOption:[{//启用状态选项
        value:1,
        label:'所有'
      },{
        value:2,
        label:'已启用'
      },{
        value:3,
        label:'已移除'
      }],
      adplanLabelOption:[{//启用状态选项
        value:1,
        label:'全部'
      }],
      dialogFormVisible:false,//预警预算弹窗
      budgetRuleForm:{
        condition:{
          min:'',
          max:''
        }
      },
      budgetRules:{

      },
      radio: '1',
      priceRadio:'3',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      materialIdsPara:[],//选中的搜索词
      listParams:{},//关键词列表的入参
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      kwNameShow:'',
      kwNameShowOption:[{
        value:1,
        label:'搜索词'
      },{
        value:2,
        label:'关键词'
      }],
      grouplistName:'',
      kwNameList:'',
      ownerId:'',
      ownerName:'',
      campaignId:'',
      campaignName:'',
      adGroupId:'',
      adGroupName:'',
      teamKeywordsVisible:false,//添加为关键词弹框
      adTeamModel:[],//添加为关键词，广告组
      adTeamModelOption:[],//添加为关键词下拉，广告组
      listGroupParams:{},
      changeTableData:[],
      groupMatchType:'',
      groupMatchTypeOption:[{//匹配状态
        value:'BROAD',
        label:'广泛匹配'
      },{
        value:'EXACT',
        label:'完全匹配'
      }],
      groupChujia:'',
      groupChujiaOption:[{
        value:1,
        label:'使用当前关键词最高每次点击费用出价'
      },{
        value:2,
        label:'使用广告组默认最高每次点击费用出价'
      },{
        value:3,
        label:'选择自定义出价'
      }],
      cashNum:'',//输入金额
      cashShow:false,//自定的时候show
      maxKw:'',
      maxGroup:'',
      kwRadio:'1',//添加为否定关键词的选择广告组或者广告系列
      adPlanShow:true,//添加为否定关键词的选择广告系列显示
      adPlanModel:[],
      adPlanModelOption:[],
      adPlanModelone:'',
      adPlanModeloneOption:[],
      listPlanParams:[],
      adGroupShow:false,//添加为否定关键词的选择广告组显示
      changeTableDataOne:[],
      negkwTableData:[],
      supplySources:'',//广告系列投放位置
      appId:'',
      budget:'',//广告系列的每日预算
      adplanLabel:'',//搜索广告系列下的关键词
      loadingDialogVisible:true,//正在载入
      adPlanxialaOption:[],
      changeSupplySources:'',//change计划名称改变投放位置
      titleList:[],
      adTeamModelnew:''


    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)

    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    this.campaignId = this.$route.query.campaignId
    this.campaignName = this.$route.query.campaignName
    this.adGroupId = this.$route.query.adGroupId
    this.adGroupName = this.$route.query.adGroupName
    this.supplySources = this.$route.query.supplySources
    this.appId = this.$route.query.appId
    this.budget = this.$route.query.budget

    let nowTimes = Date.now();
    let oldTimes = Date.now();
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//今天的时间
    this.dateValue = [oldDate, nowDate];
    this.kwNameShow = 1
    this.kwNameList = '请输入搜索词'
    this.groupMatchType = 'BROAD'
    this.adplanLabel = this.campaignName
    this.getAdplanList()
    this.getSearchList()

  },
  methods: {
    //tab切换
    clickChangeChannel(val){
      if(val == 1){
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName }})
      }else if(val == 2){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
        
      }else if(val == 4){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
        
      }
    },

    //关键词、否定关键词、搜索词的切换
    keyWords(val){
      if(val == 1){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget
          }})
        }else{
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget
          }})
        }
        
      }else if(val == 2){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordNegApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget
          }})
        }else{
          this.$router.push({path:'/plan-management/adKeywordNegApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget
          }})
        }
        
      }
    },

     //格式化时间 返回年月日 
     getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //change关键词和搜索词进行搜索
    changeKwName(){
      if(this.kwNameShow == 1){
        this.kwNameList = '请输入搜索词'
        this.grouplistName = ''
      }else{
        this.kwNameList = '请输入关键词或ID'
        this.grouplistName = ''
      }
    },

    changeAdplanStatu(val){
      this.listParams.matchType = val
    },

    //change计划名称展示搜索词
    changeAdplanLabel(item){
      this.loadingDialogVisible = true
      let _this = this
      item.supplySources.forEach(function(val,i){
        _this.changeSupplySources = val
      })
      console.log(_this.changeSupplySources)
      _this.listParams.userId = _this.userId
      _this.listParams.userName = _this.userName
      _this.listParams.deptId = _this.deptId
      _this.listParams.ownerId = _this.ownerId
      _this.listParams.adPlanId = item.id
      _this.listParams.pageIndex = _this.page
      _this.listParams.pageSize = _this.pageCount
      _this.listParams.sDate = _this.dateValue[0]
      _this.listParams.eDate = _this.dateValue[1]
      if(_this.kwNameShow == 1){
        _this.listParams.searchQuery = _this.grouplistName
        _this.listParams.query = ''
      }else{
        _this.listParams.query = _this.grouplistName
        _this.listParams.searchQuery = ''
      }

      if(_this.adGroupId != undefined){
        _this.listParams.adGroupId = _this.adGroupId
      }
      $http.axios.post("/api/apple/kw/search/list",_this.listParams).then(res=> {
        if(res.data.code==200){
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
          _this.loadingDialogVisible = false
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })  
          _this.loadingDialogVisible = false  
        }
      })
    },

    //搜索词列表
    getSearchList(page){
      let _this = this
      _this.listParams.userId = _this.userId
      _this.listParams.userName = _this.userName
      _this.listParams.deptId = _this.deptId
      _this.listParams.ownerId = _this.ownerId
      _this.listParams.adPlanId = _this.campaignId
      if(page == 1){
        _this.listParams.pageIndex = 1
      }else{
        _this.listParams.pageIndex = _this.page
      }
      // _this.listParams.pageIndex = _this.page
      _this.listParams.pageSize = _this.pageCount
      _this.listParams.sDate = _this.dateValue[0]
      _this.listParams.eDate = _this.dateValue[1]
      if(_this.kwNameShow == 1){
        _this.listParams.searchQuery = _this.grouplistName
        _this.listParams.query = ''
      }else{
        _this.listParams.query = _this.grouplistName
        _this.listParams.searchQuery = ''
      }

      if(_this.adGroupId != undefined){
        _this.listParams.adGroupId = _this.adGroupId
      }
      $http.axios.post("/api/apple/kw/search/list",_this.listParams).then(res=> {
        if(res.data.code==200){
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
          _this.loadingDialogVisible = false
          _this.tableData.forEach((obj,i)=>{
            //支出
            if(obj.localSpend!=null){
              obj.localSpend = '¥'+(Number(obj.localSpend).toFixed(2))
            }
            //平均每次点击费用
            if(obj.avgCPT!=null){
              obj.avgCPT = '¥'+(Number(obj.avgCPT).toFixed(2))
            }
            //平均每次转化费用
            if(obj.avgCPA!=null){
              obj.avgCPA = '¥'+(Number(obj.avgCPA).toFixed(2))
            }
            //点击率
            if(obj.ttr!=null){
              obj.ttr = (Number(obj.ttr))+'%'
            }
            //转化率
            if(obj.conversionRate!=null){
              obj.conversionRate = (Number(obj.conversionRate))+'%'
            }
          })
          _this.userSetCustom()
        }else{
          _this.loadingDialogVisible = false  
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })  
          
        }
      })
    },

    //排序
    handlesortChange(column) {
      this.listParams.orderBy = column.prop
      if(column.order == 'descending'){
        this.listParams.asc = 'DESCENDING'
      }else if(column.order == 'ascending'){
        this.listParams.asc = 'ASCENDING'
      }
      this.getSearchList()
    },

    //用户已经设置的自定义列
    userSetCustom(){
      let _this = this,titleList = [],columnName = ''
      $http.axios.post("/api/apple/CustomColumn/getColumnsByOrgId?customType=6&orgId="+Number(_this.ownerId)).then(res=> {
        if(res.data.code == 200){
          titleList = res.data.data
          _this.orderByList = res.data.data
          _this.titleList = []
          if(res.data.data != null){
            titleList.forEach((val,i)=>{
              _this.titleList.push({'name':val.name,'prop':val.columnNameLink}) 
            })
          }
          
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     // 分页
     handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.getSearchList()
    },

    //重置
    resetForm(){
      this.$router.go(0)
    },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选搜索词',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },

    //获取广告组数据
    // getAdgroupList(){
    //   let _this = this,tableData = [],adGroupName1='',adGroupId1='',defaultBidAmount1=''
    //   _this.listGroupParams = {
    //     'startTime':_this.dateValue[0],//开始日期
    //     'endTime':_this.dateValue[1],//结束日期
    //     'returnGrandTotals':true,
    //     'returnRecordsWithNoMetrics':true,
    //     'returnRowTotals':true,
    //     'timeZone':'UTC',
    //     'selector':{
    //       'orderBy':[{
    //         'field':'localSpend',
    //         'sortOrder':'DESCENDING'
    //       }],
    //       'conditions':[],
    //       'pagination':{
    //         'limit':_this.pageCount,
    //         'offset':_this.page
    //       }
    //     }
    //   }
    //   $http.axios.post("/api/apple/adGroup/getAdgroups/"+_this.ownerId+"/"+_this.campaignId,_this.listGroupParams).then(res=> {
    //     if(res.data.code==200){
    //       tableData = res.data.data.data.reportingDataResponse.row
    //       tableData.forEach(function(val,index){
    //         adGroupName1 = val.metadata.adGroupName
    //         adGroupId1 = val.metadata.adGroupId
    //         defaultBidAmount1 = val.metadata.defaultBidAmount.amount
    //         _this.adTeamModelOption.push({
    //           'adGroupName':adGroupName1,
    //           'adGroupId':adGroupId1,
    //           'defaultBidAmount':defaultBidAmount1
    //         })
    //       })
    //     }else{
    //       _this.$message({
    //         showClose: true,
    //         message: res.data.msg,
    //         type: 'error'
    //       }) 
    //     }
    //   })
    // },

    //change广告系列下拉
    changeKwGroup(item){
      this.changeTableData = item
      this.groupChujia = ''
    },
    //删除
    addGroupDelete(i){
      let _this = this
      _this.adPlanModel.forEach((val,j)=>{
        console.log(val)
        if(i == j){
          _this.adPlanModel.splice(j, 1)
        }
      })
    },

    changeKwGroupOne(item){
      // this.adTeamModel = item
      // console.log(item)
      // this.changeTableDataOne = []
      this.changeTableDataOne.push(item)
      // console.log(this.changeTableDataOne)
    },

    //删除
    addKwGroupDelete(item,i){
      console.log(item)
      console.log(i)
      let _this = this
      _this.changeTableDataOne.forEach((val,j)=>{
        console.log(val)
        if(i == j){
          _this.changeTableDataOne.splice(j, 1)
        }
      })
    },

    //change最高每次点击费用
    changeChujia(item){
      let _this = this,maxKw = [],maxGroup = []
      if(item == 1){
        _this.materialIdsPara.forEach(function(val,i){
          maxKw.push(val.bidAmount)
        })
        _this.maxKw = Math.max.apply(null,maxKw)
      }else if(item == 2){
        if(_this.adTeamModel.length != 0){
          _this.changeTableData.forEach(function(val,i){
            maxGroup.push(val.defaultBidAmount)
          })
          _this.maxGroup = Math.max.apply(null,maxGroup)
        }else{
          _this.$message({
            showClose: true,
            message: '请选择广告组',
            type: 'error'
          }) 
        }
      }else if(item == 3){
        _this.cashShow = true
      }
    },

    //添加为关键词
    // addKeywordsWrap(){
    //   this.getAdgroupList()
    //   this.chooseTableFn('teamKeywordsVisible')
    //   let _this = this,params = {},groupkeywordsDtoList = []
    //   _this.kwRighttableData.forEach(function(val,index){
    //     groupkeywordsDtoList.push({
    //       'budgetAmount':{
    //         'amount':val.chujia,
    //         'currency':'RMB'
    //       },
    //       'matchType':val.matchType,
    //       'text':val.text
    //     })
    //   })
    //   params = {
    //     'deptId':_this.deptId,
    //     'userId':_this.userId,
    //     'userName':_this.userName,
    //     'campaignId':_this.campaignId,
    //     'groupId':_this.adTeamModel,
    //     'groupkeywordsDtoList':groupkeywordsDtoList,
    //     'orgId':_this.ownerId,
    //   }
    //   $http.axios.post("/api/apple/adGroup/saveTargetingkeywords",params).then(res=> {
    //     if(res.data.code==200){
    //       _this.getKwList()
    //       _this.teamKeywordsVisible = false
    //     }else{
    //       let messageContent =res.data.msg || '请查看网络，稍后再试'
    //       _this.$message({
    //         showClose: true,
    //         message: messageContent,
    //         type: 'error'
    //       })  
    //     }
    //   })
      
    // },

    //获取计划系列数据
    getAdplanList(){
      let _this = this
      $http.axios.get("/api/apple/adPlan/getCampaignList/"+_this.ownerId).then(res=> {
        if(res.data.code==200){
          _this.adPlanModelOption = res.data.data
          _this.adPlanModeloneOption = res.data.data
          _this.adPlanxialaOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //获取广告组数据
    getAdgroupsList(palnId){
      let _this = this
      $http.axios.get("/api/apple/adGroup/getAllGroup/"+_this.ownerId+"/"+palnId).then(res=> {
        if(res.data.code==200){
          _this.adTeamModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //change广告系列
    changeadPlanOne(val){
      // this.adTeamModel = []
      console.log(val)
      console.log(this.adTeamModel)
      this.getAdgroupsList(val)
      
    },

    //change广告系列和广告组
    changeAdPlan(val){
      this.changeTableData = []
      this.adPlanModel = []
      this.changeTableDataOne = []
      this.adTeamModel = []
      this.adTeamModelnew = ''
      this.adPlanModelone = ''
      if(val == '1'){
        this.adPlanShow = true
        this.adGroupShow = false
      }else{
        this.adPlanShow = false
        this.adGroupShow = true
      }
    },
    changeAdGroup(val){
      this.changeTableData = []
      this.adPlanModel = []
      this.adTeamModelnew = ''
      this.changeTableDataOne = []
      this.adTeamModel = []
      this.adPlanModelone = ''
      if(val == '1'){
        this.adPlanShow = true
        this.adGroupShow = false
      }else{
        this.adPlanShow = false
        this.adGroupShow = true
      }
    },

    //添加为否定关键词
    addNegKeywordsWrap(){
      let _this = this
      _this.chooseTableFn('teamKeywordsVisible')
      _this.getAdplanList()
      console.log(_this.materialIdsPara)
      _this.negkwTableData = _this.materialIdsPara
    },

    //确定添加为否定关键词
    addNegKwSure(){
      let _this = this,params={},negativekeywordsDtoList = [],planId=[],groupId=[]

      //val里面的value需要改，因为现在写的是自己造的数据，以后接口有数据之后需要根据接口改字段名
      _this.negkwTableData.forEach(function(val,index){
        negativekeywordsDtoList.push({
          'matchType':_this.groupMatchType,
          'text':val.searchText//这个的名字需要修改
        })
      })
      if(_this.kwRadio == '1'){
        planId.push(_this.adPlanModel)
      }else{
        planId.push(_this.adPlanModelone)
        groupId.push(_this.adTeamModel)
      }
      params = {
        'deptId':_this.deptId,
        'userId':_this.userId,
        'userName':_this.userName,
        'campaignIdList':planId,
        'negativekeywordsDtoList':negativekeywordsDtoList,
        'orgId':_this.ownerId,
        'type':Number(_this.kwRadio),//类型：1 添加到广告系列 2：添加到广告组
        'adgroupIdList':groupId
      }
      $http.axios.post("/api/apple/adGroup/saveNegativekeywords",params).then(res=> {
        if(res.data.code==200){
          _this.getSearchList()
          _this.teamKeywordsVisible = false
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //自定义
    zdyFn(){
      this.$refs.zdyApple.generateTimes(6,this.ownerId)
    },


  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .priceStyle{
    display: block;
    margin:10px 0;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:10px;
    width:100%;
    border-bottom:1px solid #ddd;
    font-size:14px;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#66856d;
    border-bottom:2px solid #66856d;
  }
  .linkage{
    margin-bottom:30px;
    /* padding-bottom:20px; */
    /* border-bottom:1px solid #ddd; */
  }
  .linkageType{
    display: inline-block;
    vertical-align:middle;
    /* margin-right:30px; */
  }
  .linkageType label{
    padding-right:15px;
  }
  ::v-deep .addKwAdgroup .el-input__inner,::v-deep .addKwAdgroup .el-input--mini{
    width:678px;
  }
  .addKwAdgroup11{
    display: inline-block;
    vertical-align:middle;
  }
  ::v-deep .addKwAdgroup11 .el-input__inner,::v-deep .addKwAdgroup11 .el-input--mini{
    width:329px;
  }
  .addKwAdgroupGroup{
    margin-left:20px;
  }
  .addKwWrap{
    width:100%;
    padding-top:10px;
    box-sizing: border-box;
    /* border:1px solid #ddd; */
  }
  .addKwAdgroup{
    padding:15px;
    box-sizing: border-box;
    background:#F5F7F6;
    /* margin-bottom:10px; */
    box-sizing: border-box;
  }
  .addKwWrap11{
    padding:15px;
    box-sizing: border-box;
    background:#F5F7F6;
    /* margin-bottom:10px; */
  }
  .addKwAdgroupList{
    /* padding:15px; */
    padding-top:0;
    margin-bottom:10px;
    box-sizing: border-box;
    /* border:1px solid #F5F7F6; */
  }
  .addKwgroupList{
    padding:5px 15px;
    box-sizing: border-box;
    margin:10px 0;
    background:#F5F7F6;
  }
  .addKwgroupList span{
    display: inline-block;
    vertical-align: middle;
    width:50%

  }
  .linkageTypes{
    padding:10px 15px;
    background:#F5F7F6;
    border-radius:8px;
  }
  .linkageTypes label{
    padding-right:15px;
    font-size:12px;
  } 
  ::v-deep .linkageTypes .el-input--mini .el-input__inner{
    /* background:#F5F7F6; */
    /* border:none; */
    display: inline-block;
    vertical-align: middle;
    width:632px;
  }
  .listDetail{
    display: inline-block;
    vertical-align:middle;
    /* margin-left:20px; */
  }
  .listDetail span{
    display: inline-block;
    vertical-align:middle;
    font-size:12px;
    color:#666;
    /* margin-right:20px; */
  }
  .listDetail span i{
    font-style: normal;
  }
  .loading{
    text-align:center;
  }
  .loading span,.loading i{
    display: block;
  }
  .loading span{
    font-size:20px;
    font-weight:700;
    color:#fff;
  }
  .loading i{
    margin-top:10px;
    font-size:60px;
    /* color: #409eff; */
    color:#fff;
  }
  /* .loadingBg{
    margin-top:200px;
  } */
  ::v-deep .loadingBg .el-dialog{
    margin-top:200px;
    background:rgba(255,255,255,0)!important;
    box-shadow: none;
  }
  /*新预警*/
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .queryLeft,.queryRight{
    display: inline-block;
    vertical-align: middle;
  }
  .queryLeft{
    width:65%;
  }
  .queryRight{
    width:35%;
    text-align:right;
  }
  ::v-deep .dateStyle.el-range-editor.el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .dateStyle.el-range-editor .el-range-input{
    background:#F5F7F6!important;
  }
  ::v-deep .queryLeft .el-input--mini .el-input__inner{
    /* width:160px; */
    border:none;
    background:#F5F7F6;
  }
  ::v-deep .materialHead .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .materialHead{
    padding-bottom:20px;
  }
  .queryRight span i{
    font-style:normal;
    color:#999;
  }
  ::v-deep .queryLeft .el-button + .el-button{
    margin-left:0!important;
  }
  .chanelTabSecondary{
    margin-bottom:20px;
  }
  .chanelTabSecondary span{
    display: inline-block;
    vertical-align: middle;
    font-size:12px;
    padding:0 20px;
    height:30px;
    line-height:30px;
    background:#ECF3F0;
    color:#82A69A;
    margin-right:20px;
    cursor: pointer;
    border-radius: 30px;
  }
  .chanelTabSecondary span:hover,.chanelTabSecondary span.channeSelActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .kwPriceInput .el-input--mini .el-input__inner{
    height:40px;
    line-height:40px;
    border:none;
    background:#F5F7F6;
  }
  .linkage-left,.linkage-right{
    display: inline-block;
    vertical-align:middle;
    width:49%;
  }
  .linkage-right{
    text-align:left;
  }
  .kwIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .kwWrap .el-dialog .el-dialog__header{
    padding-top:40px;
    font-size:18px;
    font-weight:700;
  }
  ::v-deep .kwWrap .el-dialog--center .el-dialog__body{
    padding-top:0;
  }
  .selectKwFrom,.selectKwName{
    display: inline-block;
    vertical-align: middle;
    margin-right:20px;
    font-size:12px;
  }
  .selectKw{
    margin-bottom:10px;
    background:#F5F7F6;
    border-radius:8px;
    padding:15px;
    box-sizing: border-box;
  }
  .linkageTypeNew{
    width:100%;
    border:1px solid #F5F7F6;
    background:none;
  }
  .addKwAdList{
    position: relative;
  }
  .addKwDelete{
    position: absolute;
    top:-7px;
    right:-344px;
    color:#f12323;
    font-size:16px;
    cursor: pointer;
  }
  
</style>
