<template>
  <el-dialog
    width="730px"
    @close="closeReset"
    :visible.sync="Visible"
    class="icon_custom_popup"
    :close-on-click-modal="false"
    :title="`${
      operationStatus == 'create' ? '新建' : '修改'
    }${reporFormsTitle}模板`"
  >
    <img class="icon" src="@/assets/img/suCaiIcon.png" />
    <el-form
      :rules="rules"
      ref="submitForm"
      :model="submitForm"
      :label-width="
        operationStatus == 'create' && reporFormsType == 2 ? '124px' : '84px'
      "
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="submitForm.name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="文件名" prop="title">
        <el-input
          clearable
          v-model="submitForm.title"
          placeholder="请输入文件名"
        ></el-input>
        <div class="button_label">
          <div
            v-for="(item, index) in fileNameList"
            :key="index"
            :class="{
              disabled: submitForm.title.indexOf('{' + item + '}') > -1,
            }"
            @click="addFileName(item)"
          >
            {{ item }}
          </div>
        </div>
      </el-form-item>
      <div v-if="DataSource && DataSource.length > 0">
        <div class="titles">数据源sheet</div>
        <div v-for="(item, index) in DataSource" :key="index">
          <el-collapse v-model="menuActiveName">
            <el-collapse-item :title="item.sheetName" :name="index">
              <div class="between">
                <el-form-item
                  label-width="70px"
                  :style="{ width: channelId == 8 ? '46%' : '83%' }"
                >
                  <div slot="label">
                    <span class="starStyle">*</span>报表维度
                  </div>
                  <el-select v-model="item.adType" placeholder="请选择报表维度">
                    <el-option
                      v-for="(item, index) in dimensionsList"
                      :key="index"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                  <div
                    class="el-form-item__error"
                    v-if="validateType && !item.adType"
                  >
                    请选择报表维度
                  </div>
                </el-form-item>
                <el-form-item
                  v-if="channelId == 8"
                  label="时区"
                  style="width: 36%"
                  label-width="63px"
                >
                  <el-select
                    v-model="item.timeZone"
                    placeholder="请选择时区"
                    :disabled="reporFormsType == 2 ? true : false"
                  >
                    <el-option label="Asia" value="Asia"></el-option>
                    <el-option label="UTC" value="UTC"></el-option>
                  </el-select>
                </el-form-item>
                <el-button
                  type="primary"
                  style="margin-top: 4px"
                  @click="batchGenerationFunction(item.sheetName, index)"
                  >批量生成</el-button
                >
              </div>
              <div
                class="el-form-item__label"
                style="width: 100%; text-align: left; margin-top: -10px"
              >
                广告主(必选)
              </div>
              <el-transfer
                filterable
                :data="menuList"
                v-model="item.value"
                :titles="['未选中', '已选中']"
                @mouseover.native="addTitle"
                @change="
                  ($event, direction, movedKeys) =>
                    changeMenu(movedKeys, item, direction)
                "
              >
                <div slot-scope="{ option }">
                  <div class="ellipsis-line1">
                    {{ option.label }}
                  </div>
                  <el-form-item
                    v-if="
                      appInfor[option.key] &&
                      appInfor[option.key].length > 0 &&
                      item.value &&
                      item.value.indexOf(option.key) > -1
                    "
                  >
                    <el-select
                      multiple
                      filterable
                      placeholder="请选择应用"
                      v-model="item.appInfor[option.key]"
                      @change="changeApplication(item, option.key)"
                      :class="{
                        error:
                          (item.appValidateList.indexOf(option.key) > -1 ||
                            validateType) &&
                          (!item.appInfor[option.key] ||
                            item.appInfor[option.key].length == 0),
                      }"
                    >
                      <el-option
                        v-for="(items, indexs) in appInfor[option.key]"
                        :key="indexs"
                        :label="items.name"
                        :value="items.allianceAppId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-transfer>
              <div
                class="el-form-item__error"
                style="position: static; height: 16px"
                v-if="
                  (item.flag || validateType) &&
                  (!item.value || item.value.length == 0)
                "
              >
                请选择广告主
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <el-dialog
          width="400px"
          append-to-body
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="batchGenerationVisible"
          :title="`批量生成${batchGenerationName}`"
        >
          <img class="icon" src="@/assets/img/ZDYLicon.png" />
          <el-form>
            <el-form-item>
              <el-input
                :rows="6"
                type="textarea"
                v-model="menuIds"
                placeholder="每个ID之间用英文逗号分割，不要有空格，填写在下方输入框中即可"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="batchGenerationVisible = false">取 消</el-button>
            <el-button type="primary" @click="batchGenerationSubmit"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
      <div v-if="KeyWord && KeyWord.length > 0">
        <div class="titles">关键词sheet</div>
        <div v-for="(item, index) in KeyWord" :key="index">
          <el-collapse v-model="keyWordActiveName">
            <el-collapse-item :title="item.sheetName + '(必选)'" :name="index">
              <el-transfer
                filterable
                :data="menuList"
                v-model="item.value"
                :titles="['未选中', '已选中']"
                @mouseover.native="addTitle"
                @change="
                  ($event, direction, movedKeys) =>
                    changeMenu(movedKeys, item, direction)
                "
              >
                <div slot-scope="{ option }">
                  <div class="ellipsis-line1">
                    {{ option.label }}
                  </div>
                  <el-form-item
                    v-if="
                      appInfor[option.key] &&
                      appInfor[option.key].length > 0 &&
                      item.value &&
                      item.value.indexOf(option.key) > -1
                    "
                  >
                    <el-select
                      multiple
                      filterable
                      placeholder="请选择应用"
                      v-model="item.appInfor[option.key]"
                      @change="changeApplication(item, option.key)"
                      :class="{
                        error:
                          (item.appValidateList.indexOf(option.key) > -1 ||
                            validateType) &&
                          (!item.appInfor[option.key] ||
                            item.appInfor[option.key].length == 0),
                      }"
                    >
                      <el-option
                        v-for="(items, indexs) in appInfor[option.key]"
                        :key="indexs"
                        :label="items.name"
                        :value="items.allianceAppId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-transfer>
              <div
                class="el-form-item__error"
                style="position: static; height: 16px"
                v-if="
                  (item.flag || validateType) &&
                  (!item.value || item.value.length == 0)
                "
              >
                请选择广告主
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="titles">其他</div>
      <el-form-item label="接收人" prop="assignPersonnel">
        <el-select
          multiple
          filterable
          value-key="userId"
          placeholder="请选择接收人"
          v-model="submitForm.assignPersonnel"
        >
          <el-option
            v-for="(item, index) in assignPersonnelList"
            :key="index"
            :value="item"
            :label="item.string"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抄送人" prop="CCRecipient">
        <div class="inline">
          <el-select
            multiple
            filterable
            value-key="name"
            placeholder="请选择抄送人"
            v-model="submitForm.CCRecipient"
          >
            <el-option
              v-for="(item, index) in CCRecipientList"
              :key="index"
              :value="item"
              :label="item.name"
            >
            </el-option>
          </el-select>
          <i
            class="el-icon-circle-plus-outline"
            @click="addCCRecipientVisible = true"
          ></i>
        </div>
        <el-dialog
          width="400px"
          append-to-body
          title="添加抄送人"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="addCCRecipientVisible"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_specify.png" />
          </div>
          <el-form :model="addCCRecipientForm" label-width="64px">
            <el-form-item label="人员名称">
              <el-input
                v-model="addCCRecipientForm.name"
                autocomplete="off"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱地址">
              <el-input
                v-model="addCCRecipientForm.email"
                autocomplete="off"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="addCCRecipientVisible = false">取 消</el-button>
            <el-button type="primary" @click="addCCRecipient">确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item
        v-if="operationStatus == 'create'"
        label="接收方式"
        prop="receivingMethod"
      >
        <el-checkbox-group class="middle" v-model="submitForm.receivingMethod">
          <el-checkbox label="excelFlag">Excel</el-checkbox>
          <el-checkbox label="imgFlag">图片</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="operationStatus == 'create' && reporFormsType == 2"
        label="是否拉取零点数据"
      >
        <el-switch
          class="middle"
          :active-value="1"
          :inactive-value="0"
          v-model="submitForm.zeroFlag"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        v-if="reporFormsType == 2"
        label="时报时间点"
        prop="timePoint"
      >
        <el-select
          multiple
          filterable
          placeholder="请选择时报时间点"
          v-model="submitForm.timePoint"
          v-if="channelId == 3 || channelId == 4"
        >
          <el-option
            v-for="(item, index) in timeLimitListOther"
            :key="index"
            :value="item.id"
            :label="item.time"
          >
          </el-option>
        </el-select>
        <el-select
          multiple
          filterable
          placeholder="请选择时报时间点"
          v-model="submitForm.timePoint"
          v-if="channelId == 5 || channelId == 6 || channelId == 7 || channelId == 8"
        >
          <el-option
            v-for="(item, index) in timeLimitList"
            :key="index"
            :value="item.id"
            :label="item.time"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="closeReset">取 消</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="submitLoading ? true : false"
        @click="submit('submitForm')"
        >保 存</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import config from "@/api/config";
import { $http } from "@/api/http";
import getUserList from "@/utils/getUserList";

let menuList = [];
let reportFormId = "";
let assignPersonnelList = [];
export default {
  props: {
    pubVue: Object,
    keyWord: Array,
    filePath: String,
    visible: Boolean,
    channelId: String,
    dataSource: Array,
    fileNameList: Array,
    dimensionsList: Array,
    reporFormsType: Number,
    interfaceParams: Object,
    reporFormsTitle: String,
    operationStatus: String,
    editReportFormsTime: Number,
  },

  data() {
    let timePointReg = (rule, value, callback) => {
      let _this = this;
      if (
        _this.propsData.reporFormsType == 2 &&
        (!value || value.length == 0)
      ) {
        callback(new Error("请选择时报时间点"));
      } else {
        callback();
      }
    };
    let receivingMethodReg = (rule, value, callback) => {
      let _this = this;
      if (
        _this.propsData.operationStatus == "create" &&
        (!value || value.length == 0)
      ) {
        callback(new Error("至少选择一种接收方式"));
      } else {
        callback();
      }
    };

    return {
      menuIds: "",
      appList: [],
      KeyWord: [],
      menuList: [],
      appInfor: {},
      DataSource: [],
      menuIdList: [],
      Visible: false,
      menuIndex: null,
      menuActiveName: [],
      CCRecipientList: [],
      validateType: false,
      submitLoading: false,
      keyWordActiveName: [],
      assignPersonnelList: [],
      batchGenerationName: "",
      addCCRecipientVisible: false,
      batchGenerationVisible: false,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        title: [{ required: true, message: "请输入文件名", trigger: "change" }],
        assignPersonnel: [
          {
            type: "array",
            required: true,
            message: "请选择接收人",
            trigger: "change",
          },
        ],
        timePoint: [
          {
            message: "",
            required: true,
            trigger: "change",
            validator: timePointReg,
          },
        ],
        receivingMethod: [
          {
            message: "",
            required: true,
            trigger: "change",
            validator: receivingMethodReg,
          },
        ],
      },
      submitForm: {
        name: "",
        title: "",
        zeroFlag: 0,
        keyWord: [],
        timePoint: [],
        CCRecipient: [],
        receivingMethod: [],
        assignPersonnel: [],
      },
      timeLimitList: [
        // { time: "8:00", id: "8" },
        // { time: "8:30", id: "8.5" },
        { time: "9:00", id: "9" },
        { time: "9:30", id: "9.5" },
        { time: "10:00", id: "10" },
        { time: "10:30", id: "10.5" },
        { time: "11:00", id: "11" },
        { time: "11:30", id: "11.5" },
        { time: "12:00", id: "12" },
        { time: "12:30", id: "12.5" },
        { time: "13:00", id: "13" },
        { time: "13:30", id: "13.5" },
        { time: "14:00", id: "14" },
        { time: "14:30", id: "14.5" },
        { time: "15:00", id: "15" },
        { time: "15:30", id: "15.5" },
        { time: "16:00", id: "16" },
        { time: "16:30", id: "16.5" },
        { time: "17:00", id: "17" },
        { time: "17:30", id: "17.5" },
        { time: "18:00", id: "18" },
        { time: "18:30", id: "18.5" },
        { time: "19:00", id: "19" },
        { time: "19:30", id: "19.5" },
        { time: "20:00", id: "20" },
        { time: "20:30", id: "20.5" },
        { time: "21:00", id: "21" },
        { time: "21:30", id: "21.5" },
        { time: "22:00", id: "22" },
        { time: "22:30", id: "22.5" },
        { time: "23:00", id: "23" },
        { time: "23:30", id: "23.5" },
      ],
      timeLimitListOther: [
        { time: "8:00", id: "8" },
        { time: "8:30", id: "8.5" },
        { time: "9:00", id: "9" },
        { time: "9:30", id: "9.5" },
        { time: "10:00", id: "10" },
        { time: "10:30", id: "10.5" },
        { time: "11:00", id: "11" },
        { time: "11:30", id: "11.5" },
        { time: "12:00", id: "12" },
        { time: "12:30", id: "12.5" },
        { time: "13:00", id: "13" },
        { time: "13:30", id: "13.5" },
        { time: "14:00", id: "14" },
        { time: "14:30", id: "14.5" },
        { time: "15:00", id: "15" },
        { time: "15:30", id: "15.5" },
        { time: "16:00", id: "16" },
        { time: "16:30", id: "16.5" },
        { time: "17:00", id: "17" },
        { time: "17:30", id: "17.5" },
        { time: "18:00", id: "18" },
        { time: "18:30", id: "18.5" },
        { time: "19:00", id: "19" },
        { time: "19:30", id: "19.5" },
        { time: "20:00", id: "20" },
        { time: "20:30", id: "20.5" },
        { time: "21:00", id: "21" },
        { time: "21:30", id: "21.5" },
        { time: "22:00", id: "22" },
        { time: "22:30", id: "22.5" },
        { time: "23:00", id: "23" },
        { time: "23:30", id: "23.5" },
      ],
      addCCRecipientForm: {
        name: "",
        email: "",
      },
    };
  },

  watch: {
    visible(item) {
      if (item) {
        Object.assign(this.$data, this.$options.data());
        this.Visible = item;
        if (this.operationStatus == "create") {
          this.KeyWord = this.keyWord;
          this.DataSource = this.dataSource;
        }
        this.menuActiveName = [0];
        this.keyWordActiveName = [0];
        this.initialization(true);
      }
    },
    Visible(item) {
      this.$emit("update:visible", item);
    },
    editReportFormsTime: {
      handler() {
        menuList = [];
        assignPersonnelList = [];
        this.initialization();
      },
      immediate: true,
    },
  },

  methods: {
    initialization(type) {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
      this.deptId = this.$sessionStorage.getItem(config.DEPTID);
      this.userName = this.$sessionStorage.getItem(config.USERNAME);
      let CCRecipientList = this.$sessionStorage.getItem(
        "optionSelectCopyUser"
      );
      if (CCRecipientList) {
        this.CCRecipientList = CCRecipientList;
      } else {
        this.getCCRecipient();
      }
      if (type) {
        this.$nextTick(() => {
          this.$refs.submitForm.resetFields();
        });
      }
      this.getMenu();
      this.getAssignPersonnel();
    },
    // 获取报表详情
    getReportFormsDetails(id) {
      let _this = this;
      reportFormId = id;
      $http.axios
        .get(
          `/api/${_this.interfaceParams.interfaceFields}/reportModel/queryById?id=${reportFormId}`
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.$nextTick(() => {
              let data = res.data.data;
              for (let item in _this.submitForm) {
                if (data[item]) {
                  _this.submitForm[item] = data[item];
                }
              }
              // 接收方式
              if (data.imgFlag == 1) {
                _this.submitForm.receivingMethod.push("imgFlag");
              }
              if (data.excelFlag == 1) {
                _this.submitForm.receivingMethod.push("excelFlag");
              }
              // 抄送人
              let CCRecipientId = data.copySender
                ? data.copySender.split(",")
                : [];
              let CCRecipientName = data.copySenderName
                ? data.copySenderName.split(",")
                : [];
              CCRecipientId.forEach((item, index) => {
                _this.submitForm.CCRecipient.push({
                  id: item,
                  name: CCRecipientName[index],
                });
              });
              // 接收人
              let assignPersonnelId = data.sender ? data.sender.split(",") : [];
              let assignPersonnelName = data.senderName
                ? data.senderName.split(",")
                : [];
              assignPersonnelId.forEach((item, index) => {
                _this.submitForm.assignPersonnel.push({
                  userId: item,
                  string: assignPersonnelName[index],
                });
              });
              // 数据源
              _this.DataSource = _this.reassemblingDetailsValue(
                data.excelConfig && data.excelConfig.dataSource
                  ? data.excelConfig.dataSource
                  : []
              );
              // 关键词
              _this.KeyWord = _this.reassemblingDetailsValue(
                data.excelConfig && data.excelConfig.kwDataSource
                  ? data.excelConfig.kwDataSource
                  : []
              );
              // 时报时间点
              _this.submitForm.timePoint = data.time
                ? data.time.split(",")
                : [];
            });
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: res.data.msg,
            });
          }
        });
    },
    // 重新组装详情值
    reassemblingDetailsValue(itemList) {
      let _this = this;
      itemList.forEach((item) => {
        if (item.owners) {
          item.value = [];
          if (_this.channelId == 6) {
            item.appInfor = {};
            item.appValidateList = [];
          }
          item.owners.forEach((items) => {
            if (items.appId && items.appId.length > 0) {
              item.appInfor = {
                ...item.appInfor,
                ...{
                  [items.ownerId]: items.appId,
                },
              };
              _this.getApplication(items.ownerId);
            }
            item.value.push(items.ownerId);
          });
        }
      });
      return itemList;
    },
    // 获取数据源
    getMenu() {
      let _this = this;
      if (menuList.length == 0) {
        let requestAxios = "";
        if (_this.channelId == 8) {
          let params = {
            pageSize: 1000,
            userId: _this.userId,
            deptId: _this.deptId,
            userName: _this.userName,
            pageIndex: _this.page || 1,
          };
          requestAxios = $http.axios.post(
            _this.interfaceParams.getMenuList,
            params
          );
        } else if (_this.channelId == 3) {
          requestAxios = $http.axios.get(
            `${_this.interfaceParams.getMenuList}?userId=${_this.userId}&type=1`
          );
        }else if (_this.channelId == 7) {
          let params = {
            pageSize: 1000,
            page: _this.page || 1,
          };
          requestAxios = $http.axios.post(
            _this.interfaceParams.getMenuList,
            params
          );
        } 
        else {
          requestAxios = $http.axios.get(
            `${_this.interfaceParams.getMenuList}?userId=${_this.userId}`
          );
        }
        requestAxios.then((res) => {
          let data = [];
          if (_this.channelId == 8) {
            if (res.data.code == 200) {
              data = res.data.data ? res.data.data.list : [];
            } else {
              _this.$message({
                type: "error",
                showClose: true,
                message: res.data.msg,
              });
            }
          }else if (_this.channelId == 7) {
            if (res.data.code == 200) {
              data = res.data.rows || [];
            } else {
              _this.$message({
                type: "error",
                showClose: true,
                message: res.data.msg,
              });
            }
          } 
          else {
            data = res.data || [];
          }
          data.forEach((item) => {
            // menuList.push({
            //   key: _this.channelId == 8 ? item.ownerId : item.id,
            //   label: _this.channelId == 8 ? item.ownerName : item.name,
            // });
            if(_this.channelId == 7){
              menuList.push({
                key: item.advertiserId,
                label: item.name,
              });
            }else{
              menuList.push({
                key: _this.channelId == 8 ? item.ownerId : item.id,
                label: _this.channelId == 8 ? item.ownerName : item.name,
              });
            }
            
          });
          _this.menuList = menuList;
        });
      } else {
        _this.menuList = menuList;
      }
    },
    // 获取接收人
    getAssignPersonnel() {
      let _this = this;
      if (assignPersonnelList.length == 0) {
        $http.axios
          .get(_this.interfaceParams.getAssignPersonnel)
          .then((res) => {
            if (res.data.code == 200) {
              assignPersonnelList = res.data.data || [];
              _this.assignPersonnelList = assignPersonnelList;
            } else {
              _this.$message({
                type: "error",
                showClose: true,
                message: res.data.msg,
              });
            }
          });
      } else {
        _this.assignPersonnelList = assignPersonnelList;
      }
    },
    // 获取抄送人
    getCCRecipient() {
      let _this = this;
      getUserList().then((res) => {
        _this.CCRecipientList = res;
        _this.$sessionStorage.setItem("optionSelectCopyUser", res);
      });
    },
    // 添加文件名
    addFileName(item) {
      let value = "{" + item + "}";
      if (this.submitForm.title.indexOf(value) == -1) {
        this.submitForm.title += value;
      } else {
        this.submitForm.title = this.submitForm.title.replace(value, "");
      }
    },
    // 更改数据源
    changeMenu(event, item, direction) {
      console.log(item)
      // console.log(this.appInfor)
     
      let _this = this;
      event.forEach((items,index) => {
        // console.log(items)
        console.log(item.owners,11)
        console.log(index)
        if (direction == "right") {
          item.flag = true;
          if (!_this.appInfor[items]) {
            _this.getApplication(items);
          }
        } else {
          // console.log(item.owners,22)
          // console.log(items,33)
          // delete item.appInfor[items];

          item.owners.forEach((val,j) => {
            console.log(items,88)
            console.log(val,77)
            if(items == val.ownerId){
              item.owners.splice(j,1)
            }
          })

          // item.owners.splice(index,1)
          console.log(item.owners)
        }
      });
    },
    // 更改应用
    changeApplication(item, key) {
      item.flag = true;
      if (item.appValidateList.indexOf(key) == -1) {
        item.appValidateList.push(key);
      }
    },
    // 获取应用
    getApplication(id) {
      let _this = this;
      $http.axios
        .get(
          `/api/${_this.interfaceParams.interfaceFields}/reportModel/listApp?ownerId=${id}`
        )
        .then((res) => {
          let data = res.data.data;
          _this.appInfor = Object.assign({}, _this.appInfor, {
            [id]: data,
          });
        });
    },
    // 批量生成
    batchGenerationFunction(name, index) {
      this.menuIndex = index;
      this.batchGenerationName = name;
      this.batchGenerationVisible = true;
      this.menuIds = this.DataSource[index].value
        ? this.DataSource[index].value.toString()
        : "";
    },
    // 批量生成提交
    batchGenerationSubmit() {
      let ownersList = [],
        addMenuIds = [];
      let menuIds = this.menuIds.split(",");
      this.menuList.filter((item) => {
        if (menuIds.indexOf(item.key) > -1) {
          menuIds.splice(menuIds.indexOf(item.key), 1);
          addMenuIds.push(item.key);
          ownersList.push({ ownerId: item.key, ownerName: item.label });
        }
      });
      this.batchGenerationVisible = false;
      this.DataSource[this.menuIndex].owners = ownersList;
      this.DataSource[this.menuIndex].value = addMenuIds;
      if (menuIds.length > 0) {
        this.$alert(
          `<div><img class="icon" src="${require("@/assets/img/warningIcon.png")}" /><div class="text_tip">查询到<span class="tips">${menuIds.toString()}</span>是错误的ID，请重新检查ID之后再次输入</div></div>`,
          "温馨提示",
          {
            center: true,
            showCancelButton: true,
            dangerouslyUseHTMLString: true,
            customClass: "icon_custom_popup",
            beforeClose: (action, instance, done) => {
              done();
            },
          }
        )
          .then(() => {})
          .catch(() => {});
      }
    },
    // 添加抄送人
    addCCRecipient() {
      let _this = this;
      _this.addCCRecipientVisible = false;
      $http.axios
        .get(
          `/api/system/user/bdAccountCopyUser?userName=${_this.addCCRecipientForm.name}&email=${_this.addCCRecipientForm.email}`
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.getCCRecipient();
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: res.data.msg,
            });
          }
        });
    },
    // 提交
    submit(formName) {
      let _this = this;
      let interfacePath = "";
      let CCRecipientParams = { name: [], id: [] },
        assignPersonnelParams = { name: [], id: [] };
      _this.validateType = true;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          // 数据源校验
          if (_this.DataSource && _this.DataSource.length > 0) {
            for (let i = 0; i < _this.DataSource.length; i++) {
              let dataSourceParams = _this.DataSource[i];
              if (!dataSourceParams.adType) {
                _this.verificationPrompt();
                return;
              }
              let dataSource = dataSourceParams.value;
              if (!dataSource || dataSource.length == 0) {
                _this.verificationPrompt();
                return;
              }
              for (let j in dataSourceParams.appInfor) {
                if (dataSourceParams.appInfor[j].length == 0) {
                  _this.verificationPrompt();
                  return;
                }
              }
            }
          }
          // 关键词校验
          if (_this.KeyWord && _this.KeyWord.length > 0) {
            for (let i = 0; i < _this.KeyWord.length; i++) {
              let keyWordParams = _this.KeyWord[i];
              let keyWord = keyWordParams.value;
              if (!keyWord || keyWord.length == 0) {
                _this.verificationPrompt();
                return;
              }
              for (let j in keyWordParams.appInfor) {
                if (keyWordParams.appInfor[j].length == 0) {
                  _this.verificationPrompt();
                  return;
                }
              }
            }
          }
          _this.submitLoading = true;
          // 抄送人
          _this.submitForm.CCRecipient.forEach((item) => {
            CCRecipientParams.id.push(item.id);
            CCRecipientParams.name.push(item.name);
          });
          // 接收人
          _this.submitForm.assignPersonnel.forEach((item) => {
            assignPersonnelParams.id.push(item.userId);
            assignPersonnelParams.name.push(item.string);
          });
          // 数据源
          _this.DataSource.forEach((item) => {
            if (item.flag) {
              item.owners = [];
              if (item.value.length > 0) {
                _this.menuList.forEach((items) => {
                  if (item.value.indexOf(items.key) > -1) {
                    let dataSourceParams = {
                      ownerId: items.key,
                      ownerName: items.label,
                    };
                    if (_this.channelId == 6) {
                      dataSourceParams.appId = item.appInfor[items.key] || null;
                    }
                    item.owners.push(dataSourceParams);
                  }
                });
              }
            }
          });
          // 关键词
          _this.KeyWord.forEach((item) => {
            if (item.flag) {
              item.adType = 6;
              item.owners = [];
              if (item.value.length > 0) {
                _this.menuList.forEach((items) => {
                  if (item.value.indexOf(items.key) > -1) {
                    let keyWordParams = {
                      ownerId: items.key,
                      ownerName: items.label,
                    };
                    if (_this.channelId == 6) {
                      keyWordParams.appId = item.appInfor[items.key] || null;
                    }
                    item.owners.push(keyWordParams);
                  }
                });
              }
            }
          });
          let params = {
            userId: _this.userId,
            deptId: _this.deptId,
            userName: _this.userName,
            channelId: _this.channelId,
            type: _this.reporFormsType,
            name: _this.submitForm.name,
            title: _this.submitForm.title,
            time: _this.submitForm.timePoint.toString(),
            copySender: CCRecipientParams.id.toString(),
            sender: assignPersonnelParams.id.toString(),
            copySenderName: CCRecipientParams.name.toString(),
            senderName: assignPersonnelParams.name.toString(),
            excelConfig: {
              kwDataSource: _this.KeyWord,
              dataSource: _this.DataSource,
            },
          };
          if (_this.operationStatus == "create") {
            params.path = _this.filePath;
            params.zeroFlag = _this.submitForm.zeroFlag;
            interfacePath = `/api/${_this.interfaceParams.interfaceFields}/reportModel/addModel`;
            for (let i = 0; i < _this.submitForm.receivingMethod.length; i++) {
              params[_this.submitForm.receivingMethod[i]] = 1;
            }
          } else {
            params.id = reportFormId;
            interfacePath = `/api/${_this.interfaceParams.interfaceFields}/reportModel/edit`;
          }
          $http.axios
            .post(interfacePath, params)
            .then((res) => {
              _this.submitLoading = false;
              if (res.data.code == 200) {
                _this.closeReset(true);
              } else {
                _this.$message({
                  type: "error",
                  showClose: true,
                  message: res.data.msg,
                });
              }
            })
            .catch(() => {
              _this.submitLoading = false;
            });
        } else {
          _this.verificationPrompt();
        }
      });
    },
    // 验证提示
    verificationPrompt() {
      this.$message({
        type: "error",
        showClose: true,
        message: "请先完善信息",
      });
    },
    // 关闭重置
    closeReset(type) {
      this.Visible = false;
      if (type) {
        this.pubVue.getTableDataList();
      }
    },
    addTitle(e) {
      const target = e.target;
      if (target.title) return;
      target.title = target.innerText;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-tree-node__label {
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

