import axios from 'axios';
import fileDownload from 'js-file-download';
import PocResetMessage from '../components/reset-message';
import i18n from '../i18n';

// axios.defaults.timeout = 20000;
axios.defaults.baseURL = '';
axios.defaults.withCredentials = true;

/**
 * request 拦截器
 */
axios.interceptors.request.use(
  config => {
    // 上传文件，采用对应的type
    if (config.upload) {
      config.headers.common['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 处理axios请求返回的文件流进行文件下载
 * 需要后端开放header中content-disposition字段访问，并给出文件名
 */
// const convertRes2Blob = response => {
//   // 提取文件名
//   const fileName = response.headers['content-disposition'].match(/filename=(.*)/)[1];
//   // 将二进制流转为blob
//   const blob = new Blob([response.data], { type: 'application/octet-stream' });
//   if (typeof window.navigator.msSaveBlob !== 'undefined') {
//     // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
//     window.navigator.msSaveBlob(blob, decodeURI(fileName));
//   } else {
//     // 创建新的URL并指向File对象或者Blob对象的地址
//     const blobURL = window.URL.createObjectURL(blob);
//     // 创建a标签，用于跳转至下载链接
//     const tempLink = document.createElement('a');
//     tempLink.style.display = 'none';
//     tempLink.href = blobURL;
//     tempLink.setAttribute('download', decodeURI(fileName));
//     // 兼容：某些浏览器不支持HTML5的download属性
//     if (typeof tempLink.download === 'undefined') {
//       tempLink.setAttribute('target', '_blank');
//     }
//     // 挂载a标签
//     document.body.appendChild(tempLink);
//     tempLink.click();
//     document.body.removeChild(tempLink);
//     // 释放blob URL地址
//     window.URL.revokeObjectURL(blobURL);
//   }
// };

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export default {
  axios,
  get(url, config) {
    return axios
      .get(url, config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return error;
      });
  },
  post(url, data, config) {
    return axios
      .post(url, data, config)
      .then(
        res => {
          return res.data;
        },
        err => {
          return err;
        }
      )
      .catch(error => {
        return error;
      });
  },
  /**
   * 用于文件流的请求，下载文件
   */
  downloadFile(url, config) {
    PocResetMessage.success(i18n.t('poc:请稍等，正在下载文件'));
    return axios
      .get(url, {
        responseType: 'blob',
        ...config
      })
      .then(res => {
        // convertRes2Blob(res, config.fileType);
        let fileName = res.headers['filename'] || res.headers['content-disposition'].match(/filename=(.*)/)[1];
        if (config && config.isEscape) {
          fileName = window.decodeURIComponent(fileName);
        }
        fileDownload(res.data, fileName);
        return res.data;
      })
      .catch(error => {
        return error;
      });
  }
};
