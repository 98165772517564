import AccountSelectionYyb from '@/views/auth/planManage/accountSelectionYyb';//账户选择
import AccountListYyb from '@/views/auth/homePage/AccountListYyb';//账户列表
// import UserDetailHw from '@/views/auth/homePage/UserDetailHw';//用户列表详情
// import TimesHw from '@/views/auth/operateManage/dailyOrtimes/TimesHw';//时报
// import DailyHw from '@/views/auth/operateManage/dailyOrtimes/DailyHw';//日报
// import PriceModifyHw from '@/views/auth/planManage/modifyPrice/PriceModifyHw';//调价-账户

// import PriceModifyPlanYyb from '@/views/auth/planManage/modifyPrice/PriceModifyPlan';//调价-计划
// import PriceModifyPlanBudgetHw from '@/views/auth/planManage/modifyPrice/PriceModifyPlanBudgetHw';//调价-计划
// import PriceModifyKeyAdvertHw from '@/views/auth/planManage/modifyPrice/PriceModifyKeyAdvertHw';//调价-创意
import PriceModifyAdvertYyb from '@/views/auth/planManage/modifyPrice/PriceModifyAdvertYyb';
import AdPlanYyb from '@/views/auth/planManage/adPlanYyb';//计划
// import AdTeamHw from '@/views/auth/planManage/adTeamHw';//子任务
import AdvertYyb from '@/views/auth/planManage/advertYyb';//广告
import StartStopYyb from '@/views/auth/planManage/modifyPrice/StartStopYyb';//启停-计划


export default {
  routes: [
    {
      path: 'accountSelectionYyb',
      name: 'YybAccountManagement.AccountSelectionYyb',
      component: AccountSelectionYyb
    },
    {
      path: 'accountListYyb',
      name: 'YybAccountManagement.AccountListYyb',
      component: AccountListYyb
    },
    // { 
    //   path: 'userDetailHw/:userId',
    //   name: 'HwAccountManagement.UserDetailHw',
    //   component: UserDetailHw
    // },
    // {
    //   path: 'timesHw',
    //   name: 'HwAccountManagement.TimesHw',
    //   component: TimesHw
    // },
    
    // {
    //   path: 'dailyHw',
    //   name: 'HwAccountManagement.DailyHw',
    //   component: DailyHw
    // },
    
    // {
    //   path: 'priceModifyHw',
    //   name: 'HwAccountManagement.PriceModifyHw',
    //   component: PriceModifyHw
    // },
    // {
    //   path: 'priceModifyPlanHw',
    //   name: 'HwAccountManagement.PriceModifyPlanHw',
    //   component: PriceModifyPlanHw
    // },
    
    // {
    //   path: 'priceModifyPlanBudgetHw',
    //   name: 'HwAccountManagement.PriceModifyPlanBudgetHw',
    //   component: PriceModifyPlanBudgetHw
    // },
    // {
    //   path: 'priceModifyKeyAdvertHw',
    //   name: 'HwAccountManagement.PriceModifyKeyAdvertHw',
    //   component: PriceModifyKeyAdvertHw
    // },
    {
      path: 'adPlanYyb',//计划
      name: 'YybAccountManagement.AdPlanYyb',
      component: AdPlanYyb
    },
    // {
    //   path: 'adTeamHw',//子任务
    //   name: 'HwAccountManagement.AdTeamHw',
    //   component: AdTeamHw
    // },
    {
      path: 'advertYyb',//广告
      name: 'YybAccountManagement.AdvertYyb',
      component: AdvertYyb
    },
    {
      path: 'startStopYyb',
      name: 'YybAccountManagement.StartStopYyb',
      component: StartStopYyb
    },
    {
      path: 'priceModifyAdvertYyb',
      name: 'YybAccountManagement.PriceModifyAdvertYyb',
      component: PriceModifyAdvertYyb
    },
    
    

  ]
  };