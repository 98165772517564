<template>
  <div class="hl_breadCrumb plan-tabs" style="margin-bottom:20px;margin-top:10px;">
    <breakTitle :titleData="breakTitle"></breakTitle>
    <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane 
            v-for="(item,index) in tabsList" 
            :key="index"
            :label="item.label" 
            :name="item.name"
            :ref="item.name">
            <!-- <component :is='item.content' :newVue = "newVue" :tableData = "tableData" :key = "total"></component> -->
            <component :is='item.content' :newVue = "newVue" :tableData = "tableData" :key = "total"></component>
          </el-tab-pane>
        </el-tabs>
    </template>
  </div>
</template>
<script>
import Api from "@/api";
import { $http } from '@/api/http';
import config from '@/api/config';
import breakTitle from "./breakHeader";//面包屑导航
import uploadPlan from "./uploadPlan";//广告计划
import uploadTeam from "./uploadTeam";//广告组
import uploadAdvert from "./uploadAdvert"//广告
export default {
  name: "planTabs",
  data() {
    return {
      newVue:this,
      activeName: 'uploadPlan',//记得修改成adPlan
      breakTitle:['计划管理','账户选择','广告计划'],
      tabsList:[
        {label:"广告计划",name:"uploadPlan",objIdType:10,content:uploadPlan},
        {label:"广告组",name:"uploadTeam",objIdType:20,content:uploadTeam},
        {label:"广告创意",name:"uploadAdvert",objIdType:30,content:uploadAdvert}],//tabs动态加载的组件内容

      tableData:[],
      total:0,
      ownerId:null,
      vivoOwnerId:''
    }
  },
  components:{
    breakTitle,//面包屑导航
    uploadPlan,//广告计划
    uploadTeam,//广告组
    uploadAdvert//广告
  },
  mounted(){

    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = Number(this.$route.query.id)
    this.vivoOwnerId = '04c0abc5a56a6371e6c0'

    this.getUploadListFn(10)
    console.log(this.$route.query.id)
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index)
      if(tab.index == '0'){
        this.getUploadListFn(10)
        console.log(this)
      }else if(tab.index == '1'){
        this.getUploadListFn(20)
      }else{
        this.getUploadListFn(30)
      }
    },
    getUploadListFn(opertaType,page,status,value){
      console.log(value)
      let _this = this,params = {}
      params = {
        "pageIndex":page||1,
        "opertaType":Number(opertaType),
        "userId":_this.userId,
        "userName":_this.userName,
        "deptId":_this.deptId,
        "status":status?Number(status):null,
        "ownerId":_this.$route.query.id
      }
      if(value != undefined&&value != ''){
          params.beginTime = value[0]
          params.endTime = value[1]
        }
      $http.axios.post("/api/vivo/adFile/selectAdFile",params).then(function(res) {
       if(res.data.code == 200){
          console.log(res)
          _this.tableData = res.data.data.list
          console.log(_this.tableData)
          if(_this.tableData!=null){
            _this.tableData.forEach(obj=>{
              if(obj.filePath != null){
                obj.filePathName = "源文件"
              }else{
                obj.filePathName = "--"
              }
              if(obj.errorFilePath != null){
                obj.errorFilePathName = "错误文件"
              }else{
                obj.errorFilePathName = "--"
              }
              if(obj.status == 0){
                obj.statusName = '未执行'
              }else if(obj.status == 1){
                obj.statusName = '执行中'
              }else{
                obj.statusName = '执行完成'
              }
            })
          }
          _this.total = res.data.data.pageInfo.rowCount
       }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    dowloadFileFn(fileType,opertaType,source){
      console.log(fileType)
      console.log(source)
      let _this = this
      // $http.axios.get("/api/vivo/adFile/downloadAdExcelFile/"+fileType+"/"+source,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
      $http.axios.get("/api/vivo/adFile/downloadAdExcelFile/"+fileType+"/"+opertaType+"/"+source,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
            _this.exprtVisible = false 
            //添加{responseType: 'blob'}  导出文件以下代码都是
            let blob = response.data
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = e => {
              let a = document.createElement('a')
              a.download = source
              a.href = e.target.result
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              _this.uploadingShow = false
            }
        
        }).catch(error=>{
      let messageContent =error.message || '请查看网络，稍后再试'
      _this.$message({
        showClose: true,
        message: messageContent,
        type: 'error'
      })        
    })

    }
  }
}
</script>
<style lang="scss" scoped>
    .el-tabs__item.is-active {color: #6e7c7c;
      font-size: 16px;
      font-weight: 700;}
      .el-tabs__header{
        margin:15px 0 0;
      }
</style>