<template>
  <!-- TODO(上传视频暂时搁置，逻辑有所变更) -->
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="hl_headers sticky">
          <div class="hl_breadCrumb hl_headerLeft">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="hl_headerLeftDrowp"
            >
              <el-breadcrumb-item :to="{ path: '/auth-management/demandOrder' }"
                >运营管理</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ path: '/auth-management/demandOrder' }"
                >需求工单</el-breadcrumb-item
              >
              <el-breadcrumb-item>上传素材</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button
              type="primary"
              size="small"
              class="el-button--primary"
              @click="addMaterialList(num)"
              >+ 新增素材</el-button
            >
          </div>
        </div>
        <div class="deputy_text_title">上传素材</div>
        <div
          class="submit_form_container"
          v-for="(item, index) in courseInfo.daysArray"
          :key="index"
        >
          <el-form
            ref="setupOrderform"
            :rules="rules"
            :model="setupOrderform"
            label-width="84px"
          >
            <div
              v-for="(item, i) in uploadParams"
              :key="i"
              ref="uploadRef"
            >
              <el-form-item>
                <div slot="label"><span class="starStyle">*</span>需求工单</div>
                <el-input
                  class="max_width"
                  :placeholder="workOrderName"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div slot="label"><span class="starStyle">*</span>素材标签</div>
                <el-select
                  class="max_width"
                  v-model="item.materialTagNames"
                  filterable
                  multiple
                  :placeholder="materialTab"
                  @change="selectContract($event, i)"
                >
                  <el-option
                    v-for="(item, i) in tfMaterialTabOptions"
                    :key="i"
                    :label="item.tagName"
                    :value="item.id"
                  >
                    {{ item.tagName }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="素材名称" v-if="mtnameShow">
                <el-input
                  class="max_width"
                  v-model="item.mtName"
                  placeholder='素材名称中不允许带有 \ / : * ? " < > | 中的字符'
                  @blur="checkSlash(item.mtName)"
                ></el-input>
                <!-- <div class="tips">
                  素材名称中不允许带有 \ / : * ? " < > | 中的字符
                </div> -->
              </el-form-item>
              <el-form-item>
                <div slot="label"><span class="starStyle">*</span>制作人员</div>
                <el-select
                  v-model="item.makerUserName"
                  filterable
                  :placeholder="uploadParams.makerUserName"
                  @change="changejj($event, i)"
                >
                  <el-option
                    v-for="(item, i) in demandNameOptionsjj"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="materialTypeshow">
                <el-select
                  v-model="item.photographyUserId"
                  filterable
                  placeholder="摄影人员"
                  @change="changesy($event, i)"
                >
                  <el-option
                    v-for="(item, i) in demandNameOptionssy"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="materialTypeshow">
                <el-select
                  v-model="item.directorUserId"
                  filterable
                  placeholder="编导人员"
                  @change="bdchange($event, i)"
                >
                  <el-option
                    v-for="(item, i) in demandNameOptionsbd"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主题文案">
                <el-input
                  class="max_width"
                  v-model="item.mainTitile"
                  placeholder="请输入文案"
                ></el-input>
              </el-form-item>
              <el-form-item label="素材内容" :prop="item.propFile">
                <div>
                  <!-- <div class="form_value_title">上传图片及LOGO</div> -->
                  <div
                    class="children_contant"
                    style="padding: 40px 30px;"
                    v-for="(item1, index) in picDetail"
                    :key="index"
                  >
                    <div class="title">
                      上传{{ btnInfo[item1.name] }}(
                      <span
                        class="error"
                        v-if="item1.height != 0 && item1.width != 0"
                        >规格：{{ item1.width }}*{{ item1.height }} <
                        {{ item1.size }}kb</span
                      >
                      <span
                        class="error"
                        v-else-if="item1.height == 0 && item1.width != 0"
                        >规格：{{ item1.width }}*不限 < {{ item1.size }}kb</span
                      >
                      <span
                        class="error"
                        v-else-if="item1.height != 0 && item1.width == 0"
                        >规格：不限*{{ item1.height }} <
                        {{ item1.size }}kb</span
                      >
                      <span class="error" v-else>规格：{{ item1.bli }}</span>
                      )
                    </div>
                    <!-- 循环展示批量上传的视频 -->
                    <div
                      class="video_contant video_contantStyle el-upload-list el-upload-list--picture-card"
                      v-if="
                        item1.name == 'video' &&
                        item.materialDtoUrlDtos.length!=0 &&
                        item.materialDtoUrlDtos[0].videoUrl
                      "
                    >
                      <div
                        v-for="(item2, s) in item.materialDtoUrlDtos"
                        :key="s"
                        style="overflow: visible;margin-bottom:100px;"
                        class="el-upload-list__item is-ready"
                      >
                        <video
                          ref="videosParams"
                          v-if="item1.name == 'video' && item2.videoUrl != ''"
                          :src="item2.videoUrl"
                          class="el-upload-list__item-thumbnail"
                        ></video>

                        
                        <p class="videoNameSize">{{ item2.videoUrl | fruitLoader }}</p>
                        <span
                          class="el-upload-list__item-actions"
                        >
                        
                          <span class="el-upload-list__item-preview"
                          @click="clickVideo(item2.videoUrl)">
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            class="el-upload-list__item-delete"
                            @click="clickVideoDel(item2.videoUrl, s, i, item2)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                        
                        <!-- <span
                        class="videoName"
                        v-if="item1.name == 'video' && item2.videoUrl != ''"
                      >
                        {{ item2.videoUrl | fruitLoader }}
                        <i
                          v-if="item1.name == 'video' && item2.videoUrl != ''"
                          class="el-icon-close videoDel"
                          @click.sync="
                            clickVideoDel(item2.videoUrl, s, i, item2)
                          "
                        ></i>
                      </span> -->
                        <!-- <i v-if = "item1.name=='video'&&item2.videoUrl!=''" class="el-icon-close videoDel" @click.sync="clickVideoDel(item2.videoUrl,s,i)"></i> -->
                      </div>
                    </div>
                    <!-- <video v-if = "videoForm!=''&&item1.name=='video'" :src="videoForm" class="videoPart" @click="clickVideo"></video> -->
                    <el-upload
                      ref="uploadNew"
                      drag
                      action
                      :http-request="
                        (file) => {
                          return upload(file, i, index, item1.name);
                        }
                      "
                      :on-preview="handlePreview"
                      :on-remove="
                        (file, fileList) => {
                          return handleRemove(file, fileList, i);
                        }
                      "
                      :show-file-list="item1.name == 'video' ? false : true"
                      :headers="myHeaders"
                      :on-success="onSuccess5"
                      :on-error="onError"
                      :file-list="fileList[item1.name]"
                      list-type="picture-card"
                      :before-upload="
                        (file, fileList) => {
                          return beforeAvatarUpload(file, item1);
                        }
                      "
                      multiple
                      :limit="
                        (item1.name == 'imgs' && Number(item1.value == 1)) ||
                        item1.name == 'video'||item1.name == 'videoPage'
                          ? 100
                          : Number(item1.value)
                      "
                      :on-exceed="handleExceed"
                      :class="[
                        item1.name == 'video' ? 'videoClass' : 'imgClass',
                        item1.name == 'video' &&
                        (matSpecId == 4051 || matSpecId == 4052)
                          ? 'showVideo'
                          : 'hideVideo',
                      ]"
                    >
                      <i class="el-icon-plus"></i>

                    </el-upload>
                    
                  </div>
                  <el-dialog
                    title=""
                    :visible.sync="ImageVisible"
                    class="icon_custom_popup"
                  >
                    <div class="icon">
                      <img src="@/assets/img/dialog_image.png" />
                    </div>
                    <div style="display: flex; justify-content: center">
                      <img
                        style="
                          width: auto;
                          max-width: 100%;
                          height: auto;
                          max-height: 60vh;
                          object-fit: contain;
                        "
                        :src="Image"
                        alt=""
                      />
                    </div>
                  </el-dialog>
                  <el-dialog
                    title=""
                    :visible.sync="VideoVisible"
                    class="icon_custom_popup"
                  >
                    <div class="icon">
                      <img src="@/assets/img/dialog_video.png" />
                    </div>
                    <div style="display: flex; justify-content: center">
                      <video
                        style="
                          width: auto;
                          max-width: 100%;
                          height: auto;
                          max-height: 60vh;
                          object-fit: contain;
                        "
                        ref="vueRef"
                        controls="controls"
                        :src="videoForm"
                      ></video>
                    </div>
                  </el-dialog>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  v-if="i > 0"
                  @click="removeRow(i)"
                  >删除素材</el-button
                >
              </el-form-item>
            </div>
            <el-form-item class="submit_bottom">
              <el-button size="small" @click="cancel" v-if="closeTip == ''"
                >取消</el-button
              >
              <el-button size="small" @click="cancelTip" v-else>取消</el-button>
              <!-- <el-button type="primary" size="small" @click="submitSuccess" v-show="uploaded">保存</el-button> -->
              <el-button
                type="primary"
                size="small"
                @click="submitSuccess"
                :disabled="flag"
                >保存</el-button
              >
              <span class="tips" style="margin-left: 20px" v-if="showSubmitTip"
                >5秒内请勿重复点击</span
              >
            </el-form-item>
          </el-form>
          <!-- 取消温馨提示弹框 -->
          <el-dialog
            class="icon_custom_popup"
            width="400px"
            title="温馨提示"
            :visible.sync="tipdialogVisible"
          :close-on-click-modal="false"
          >
            <div class="icon">
              <img src="@/assets/img/dialog_reason.png" />
            </div>
            <div class="text_tip">
              <span class="tips">注意：</span>
              如果已经上传视频，点击【取消】后再次上传同一视频会报错，请谨慎操作，如果误传，请联系管理员。
            </div>
            <div slot="footer">
              <el-button type="primary" @click="tipdialogVisible = false"
                >关 闭</el-button
              >
            </div>
          </el-dialog>
          <!-- 视频重名温馨提示弹框 -->
          <el-dialog
            class="icon_custom_popup"
            width="400px"
            title="温馨提示"
            :visible.sync="duplicateVisible"
          :close-on-click-modal="false"
          >
            <div class="icon">
              <img src="@/assets/img/dialog_reason.png" />
            </div>
            <div class="text_tip">
              <div>{{ duplicateDate }}</div>
              <div>请删除已存在的素材，请重新上传！</div>
            </div>
            <div slot="footer">
              <el-button type="primary" @click="duplicateVisible = false"
                >关闭</el-button
              >
            </div>
          </el-dialog>
        </div>
      </template>
      <template slot="headerGroup"> </template>
    </PocCardTable>
  </div>
</template>
 
<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";

export default {
  name: "uploadMaterial",
  data() {
    return {
      courseInfo: { daysArray: [{}] },
      tipdialogVisible: false,
      setupOrderform: {
        channelName: "",
        workOrderType: "",
        workOrderName: "",
        orderDate: "",
        demandDate: [],
        demandName: "",
        accountName: "",
        resourcesType: "",
        materialDesc: "",
        resourcesPosition: [],
        materialTab: "",
        materialLink: "",
        materialType: "",
        bdName: "",
        syName: "",
        jjName: "",
        workOrderName1: "",
        materialTab1: "",
        bdName1: "",
        file: "",
      },
      videoForm: "", //视频地址
      btnInfo: {
        imgs: "图片",
        video: "视频",
        videoBg: "视频封底",
        brandLogo: "logo",
        videoPage: "视频封面",
      },
      rules: {
        file0: [
          { required: true, message: "请重新上传文件", trigger: "change" },
        ],
      },
      uploaded: false, //是否显示保存按钮，默认不显示
      workOrderName: "",
      materialTab: "",
      channelOptions: [
        {
          //渠道名称
          value: "1",
          label: "OPPO",
        },
        {
          value: "2",
          label: "今日头条",
        },
        {
          value: "3",
          label: "快手",
        },
      ],
      workOrderTypeOptions: [
        {
          //工单类型
          value: "选项1",
          label: "OPPO",
        },
        {
          value: "选项2",
          label: "今日头条",
        },
        {
          value: "选项3",
          label: "快手",
        },
      ],
      demandOptions: [
        {
          //需求日期
          value: "选项1",
          label: "周一",
        },
        {
          value: "选项2",
          label: "周二",
        },
        {
          value: "选项3",
          label: "周三",
        },
        {
          value: "选项4",
          label: "周四",
        },
        {
          value: "选项5",
          label: "周五",
        },
        {
          value: "选项6",
          label: "周六",
        },
        {
          value: "选项7",
          label: "周日",
        },
      ],
      demandNameOptionsbd: [],
      demandNameOptionssy: [],
      demandNameOptionsjj: [],
      accountOptions: [
        {
          //账户名称
          value: "选项1",
          label: "OPPO",
        },
        {
          value: "选项2",
          label: "今日头条",
        },
        {
          value: "选项3",
          label: "快手",
        },
      ],
      resourcesOptions: [
        {
          //资源类型
          value: "选项1",
          label: "OPPO",
        },
        {
          value: "选项2",
          label: "今日头条",
        },
        {
          value: "选项3",
          label: "快手",
        },
      ],
      resourcesPoptions: [
        {
          //素材样式
          label: "banner0 1080*171 <30KB",
        },
        {
          label: "banner1 1080*171 <30KB",
        },
        {
          label: "banner2 1080*171 <30KB",
        },
        {
          label: "banner3 1080*171 <30KB",
        },
      ],
      resourcesNum: "", //素材样式-数量
      resourcesTab: "", //素材样式-标签
      imgList: {},
      uploadParams: [
        {
          accountId: "", //账号id
          // copywriting:'',//描述，可不传
          channelId: ",", //渠道id
          clipUserId: "", //剪辑人id//
          detailId: "", //子工单id
          directorUserId: "", //编导id//
          directorUserName: "", //编导name//
          mainTitile: "", //主标题//
          mtName: "", //素材名称
          makerUserId: "", //制作人id//
          makerUserName: "", //制作人name//
          materialTagIds: [], //素材标签id//
          materialTagNames: [], //素材标签name//
          photographyUserId: "", //
          photographyUserName: "", //
          specId: "",
          imgUrlList:[],
          imgBigUrlList:[],
          materialDtoUrlDtos: [
            {
              imgList: [],
              imgUrl: "",
              logoUrl: "",
              videoBgUrl: "",
              videoUrl: "",
            },
          ],
          userId: "", //登录者id
          username: "", //登录者用户名
          type: "", //回显接口中的，1图文2视频
          propFile: "file0", //区别文件
        },
      ],
      uploadParamsDto: {
        uploadDto: [
          {
            scbq: "",
            sclj: "",
            bdry: "",
            syry: "",
            jjry: "",
          },
        ],
      },
      tagListParams: {
        //标签列表参数
        tagType: "2",
        userId: "",
        channel: "",
      },
      tfMaterialTabOptions: [],
      hxParmas: {
        id: "",
      },
      hxData: {},
      fileList: [],
      myHeaders: { Authorization: "" },
      upParams: [],
      editerArr: [],
      value1: [],
      materialAccountId: "",
      materialChannel: "",
      uploadId: "",
      closeTip: "",
      mmId: [],
      mmName: [],
      num: 1,
      Image: "",
      videoList: [], //视频地址列表——>判断视频是否都已上传
      videoForm: "", //上传视频地址
      VideoVisible: false, //视频框是否显示
      ImageVisible: false,
      duplicateVisible: false, //有本次重名的上传视频弹框
      duplicateDate: "", //有本次重名的上传视频提示信息
      duplicateDate2: [], //服务器上传视频提示信息
      one: false,
      two: false,
      matSpecId: "",
      picDetail: [], //图片的几种情况
      successUrl1: "",
      successUrl2: "",
      successUrl3: "",
      successUrl4: "",
      mtnameShow: false, //素材名称的显示问题
      path: "", //素材请求地址
      picValue: "",
      numberValue: "", //限制的图片数量
      uploadTotalNum: 0,
      uploadPath: "",
      logoUrl: "",
      imgUrl: "",
      imgUrlList:{},
      accountChannel: "", //touploadview接口中获取到的渠道id
      materialTypeshow: false,
      firstWord: "?x-oss-process=video/snapshot,t_1,m_fast",
      videoUrlList: {}, //声明上传视频集合
      flag: false,
      showSubmitTip: false,
    };
  },
  computed: {},
  //过滤成需要的字段
  filters: {
    fruitLoader(v) {
      if (!v) {
        return "";
      }
      v = v.split(".com/")[1];
      return v;
    },
  },

  mounted() {
    this.uploadParams.makerUserName = this.$sessionStorage.getItem(
      config.NICKNAME
    );
    this.uploadParams.makerUserId =
      this.$sessionStorage.getItem(config.USERID) || 1;
    this.getId();
    this.gethx();
    this.getuserChannel();
    this.getStaffDetail();
  },
  methods: {
    //弱提示
    alertMsgFn(msg, type) {
      this.$message({
        showClose: true,
        message: msg,
        type: type,
      });
    },

    getuserChannel() {
      let that = this;
      let userChannel = String(
        that.$sessionStorage.getItem(config.USERID) || 1
      );
      $http.axios
        .get("/api/system/user/getChannelByUser?userId=" + userChannel)
        .then(function (response) {
          if (response.data.data.length != 0) {
            that.tagListParams.channel = response.data.data[0].id;
            that.getTagList();
          }
        });
    },

    //上传前的校验
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.code !== 200) {
        this.$message.error("上传失败！");
      } else {
        this.isShowRequest = false;
        this.isLoad = false;
        this.modalForm.logoId = res.body.fileId;
        this.modalForm.logoPath = res.body.filePath;
      }
    },

    beforeAvatarUpload(file, item) {
      // 校验图片格式(也可直接用 accept 属性限制如：accept="image/png,image/jpg,image/jpeg,image/gif")
      let format = item.format.split("/"),
        formatName = item.name,
        size = item.size,
        fileWidth = item.width,
        fileHeight = item.height,
        bli = item.bli
          ? (item.bli.split(":")[0] / item.bli.split(":")[1]).toFixed(2)
          : item.bli,
        isFormat,
        imgWidth,
        imgHeight;
      // format.forEach(obj=>{
      //   if(formatName=='video'){
      //     isFormat = file.type === 'video/'+obj
      //   }else{
      //     isFormat = file.type === 'image/'+obj|| file.type === 'image/jpeg'//有时上传的图片是jpg但是file.type是'image/jpeg'
      //   }
      // })
      isFormat = format.every((obj) => {
        if (formatName == "video") {
          if (file.type === "video/" + obj) {
            return false;
          } else {
            return true;
          }
        } else {
          if (file.type === "image/" + obj || file.type === "image/jpeg") {
            //需要加上jpeg格式
            return false;
          } else {
            return true;
          }
        }
      });
      // const isFormat = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif'
      // 校验图片大小
      let is200K;
      if (size != 0) {
        is200K = Math.ceil(file.size / 1024) < size;
      } else {
        is200K = true;
      }
      if (isFormat) {
        this.$message.error("上传文件只能为 " + format + " 格式!");
        return false;
        // } else if (!is200K&&!size) {
      } else if (!is200K && size) {
        this.$message.error("上传文件大小不能超过" + size + "KB!");
        return false;
      } else {
        let isSize;
        if (file.type == "video/" + item.format) {
          isSize = new Promise((resolve, reject) => {
            let videoElement = document.createElement("video");
            videoElement.addEventListener("loadedmetadata", function () {
              // resolve({
              //   duration: videoElement.duration,
              //   width: videoElement.videoWidth,
              //   height: videoElement.videoHeight
              // })
              const valid =
                (videoElement.videoWidth / videoElement.videoHeight).toFixed(
                  2
                ) == bli ||
                ((fileWidth != 0
                  ? videoElement.videoWidth == fileWidth
                  : false) &&
                  (fileHeight != 0
                    ? videoElement.videoHeight == fileHeight
                    : false));
              valid ? resolve() : reject();
            });
            // videoElement.src = URL.createObjectURL(file);
            videoElement.src = URL.createObjectURL(file);
          }).then(
            () => {
              return file;
            },
            () => {
              if (!bli) {
                this.$message.error(
                  "上传文件尺寸应为" + fileWidth + "*" + fileHeight
                );
              } else {
                this.$message.error("上传文件尺寸限制为" + item.bli + "比例");
              }
              return Promise.reject();
            }
          );
        } else {
          // 校验图片宽高大小
          isSize = new Promise((resolve, reject) => {
            const width = fileWidth;
            const height = fileHeight;
            const _URL = window.URL || window.webkitURL;
            const img = new Image();
            img.onload = () => {
              // 限制宽高必须为 18*18 像素
              // const valid = img.width == width?true:false && img.height == height?true:false
              const valid =
                (width ? img.width == width : true) &&
                (height ? img.height == height : true);
              // const valid = true && false
              imgWidth = img.width;
              imgHeight = img.height;
              // const valid = img.width == img.height;
              // // 限制必须为竖屏图片(宽必须小于高)
              // const valid = img.width < img.height;
              // // 限制必须为横屏图片(宽必须大于高)
              // const valid = img.width > img.height;
              valid ? resolve() : reject();
            };
            img.src = _URL.createObjectURL(file);
          }).then(
            () => {
              return file;
            },
            () => {
              if (!bli) {
                if (fileWidth != imgWidth || fileHeight != imgHeight) {
                  this.$message.error(
                    "上传文件尺寸应为" + fileWidth + "*" + fileHeight
                  );
                } else {
                  return true;
                }
              } else {
                this.$message.error("上传文件尺寸限制为" + bli + "比例");
              }
              return Promise.reject();
            }
          );
        }
        return !isFormat && is200K && isSize;
      }

      // return isFormat && is200K && isSize;
    },

    upload(file, i, index, name) {
      //回显或着上一页带过来的参数必填项
      this.uploadParams[i].accountId = this.materialAccountId;
      this.uploadParams[i].channelId = this.accountChannel;
      this.uploadParams[i].userId =
        this.$sessionStorage.getItem(config.USERID) || 1;
      this.uploadParams[i].username = this.$sessionStorage.getItem(
        config.USERNAME
      );
      this.uploadParams[i].specId = this.matSpecId;
      this.uploadParams[i].detailId = this.uploadId;
      this.uploadParams[i].type = this.materialType;
      this.uploadParams[i].makerUserName = this.$sessionStorage.getItem(
        config.NICKNAME
      );
      this.uploadParams[i].makerUserId =
        this.$sessionStorage.getItem(config.USERID) || 1;
      this.uploadParams[i].clipUserId =
        this.$sessionStorage.getItem(config.USERID) || 1;
      this.uploadParams[i].clipUserName = this.$sessionStorage.getItem(
        config.NICKNAME
      );
      //固定的上传路径
      // $http.axios.defaults.timeout = 500000
      let _this = this,
        formdata = new FormData(),
        fileListNew = [];
      fileListNew[name] = [];
      _this.uploadErrorNum++;
      formdata.append("file", file.file);
      formdata.append("config", JSON.stringify(_this.picDetail[index]));
      $http.axios
        .post("/api/operationManagement/material/uploadFileWithCheck", formdata)
        .then(function (response) {
          if (response.data.code == 200) {
            fileListNew[name].push({
              name: file.file.name,
              url: response.data.data,
            });
            _this.onSuccess5(response, file, fileListNew[name], i, name);
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //文件列表移除文件时的钩子
    handleRemove(file, fileList, i) {
      let _this = this,
      btnName = {
        imgs: "imgList",
        brandLogo: "logoUrl",
        videoPage: "imgUrl",
        videoBg: "videoBgUrl",
        video: "videoUrl",
      },
      name = btnName[file.raw.fileName];
      _this.$refs.setupOrderform[0].clearValidate("file" + i);
      _this.uploadParams[i].materialDtoUrlDtos.forEach((obj, index) => {
        if(!obj.uid && name == 'imgList'){
          obj.imgList.splice(index, 1)
        }
        if (file.uid == obj.uid) {
          _this.uploadParams[i].materialDtoUrlDtos.splice(index, 1);
          _this.imgList[i].splice(index, 1);
          // if(_this.matSpecId == 4051||_this.matSpecId == 4052){
          //   _this.videoUrlList[i].splice(index,1)
          // }else{
          //   _this.imgList[i].splice(index,1)
          // }
        }
      });
      console.log(_this.imgList[i])
      _this.uploadParams[i].imgBigUrlList.forEach((obj, index) => {
        if (file.uid == obj.uid) {
          _this.uploadParams[i].imgBigUrlList.splice(index, 1);
          _this.uploadParams[i].imgUrlList.splice(_this.uploadParams[i].imgUrlList.indexOf(obj.imgUrl),1);
          // arr.filter(item => item != value)
          // splice(arr.indexOf(value), 1)
        }
      });
      _this.imgUrlList[i] = _this.uploadParams[i].imgBigUrlList

      // _this.uploadParams[i].imgBigUrlList.forEach((item,j)=>{
      //     if(item.imgUrl == _this.uploadParams[i].imgUrlList)
      //   // _this.uploadParams[i].imgUrlList.forEach((val,k)=>{
      //   //   if(item.imgUrl !== val){
      //   //     _this.uploadParams[i].imgUrlList.splice(k, 1);
      //   //   }
      //   // })
      // })
      if (fileList.length == 0) {
        _this.videoList.splice(i, 1);
        if (name == "imgList") {
          if (_this.uploadParams[i].materialDtoUrlDtos[i] != undefined) {
            _this.uploadParams[i].materialDtoUrlDtos[i][name] = [];
          }
          // _this.uploadParams[i].materialDtoUrlDtos[i]['imgList'] = [];
        } else {
          if (_this.uploadParams[i].materialDtoUrlDtos[i] != undefined) {
            _this.uploadParams[i].materialDtoUrlDtos[i][name] = "";
          }
        }
      }
    },

    //点击已上传的文件链接时的钩子,获取服务器返回的数据
    handlePreview(file) {
      let that = this;
      if (file.url) {
        that.Image = file.url;
        setTimeout(function () {
          that.ImageVisible = true;
        }, 1000);
      }
    },

    getItemPath(item) {
      this.uploadPath = item.path;
    },

    //去重
    unique(arr) {
      // console.log(arr)
      let set = new Set(arr.map(item => item.videoUrl))
      let newArr = Array.from(set).map(videoUrl => {    
        return arr.find(item => item.videoUrl === videoUrl)
      })
        // console.log(newArr)



      // const res = new Map();
      // return arr.filter((arr) => !res.has(arr.videoUrl) && res.set(arr.videoUrl, 1));
      // console.log(arr)
    },

    //文件上传成功时的钩子
    onSuccess5(response, file, fileList, i, name) {
      console.log(this.matSpecId,name)
      let that = this
      if (response.data.code == 200) {
        file.file.fileName = name;
        if (that.uploadParams[i].materialDtoUrlDtos) {
          let successArr = that.uploadParams[i].materialDtoUrlDtos
          ,imgUrlList = that.uploadParams[i].imgUrlList, imgBigUrlList = that.uploadParams[i].imgBigUrlList;
          if (that.accountChannel == 3) {
            // if(that.matSpecId!=60 && that.matSpecId!=63&&that.matSpecId!=30&&that.matSpecId!=4049 && that.matSpecId!=4050){
            if (that.matSpecId != 30) {
              if (name == "brandLogo") {
                that.logoUrl = response.data.data;
                if (that.imgList[i] != undefined) {
                  that.imgList[i].forEach((obj, j) => {
                    successArr[j] = {
                      imgList: obj.imgList,
                      logoUrl: that.logoUrl,
                      uid: obj.uid,
                    };
                  });
                }
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }
              } else if (name == "videoPage") {
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    // that.imgUrl = response.data.data
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      // imgUrl: "",
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }

                //新增的批量上传封面
                if (that.imgUrlList[i] == undefined) {
                  that.imgUrlList[i] = [];
                }
                that.imgUrlList[i].push({
                  imgUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.imgUrlList[i] != undefined) {
                  that.imgUrlList[i].forEach(function (e, index) {
                    imgUrlList[index] = e.imgUrl;
                    imgBigUrlList[index] ={
                      imgUrl:e.imgUrl,
                      uid:e.uid,
                    } 
                  })
                }


              } else if (
                name == "video" &&
                (that.matSpecId == 4049 || that.matSpecId == 4050)
              ) {
                //判断是否是视频，然后进行循环push
                if (that.videoUrlList[i] == undefined) {
                  that.videoUrlList[i] = [];
                }
                that.videoUrlList[i].push({
                  videoUrl: response.data.data,
                  uid: file.file.uid,
                })
                //去重
                let set = new Set(that.videoUrlList[i].map(item => item.videoUrl))
                that.videoUrlList[i] = Array.from(set).map(videoUrl => {    
                  return that.videoUrlList[i].find(item => item.videoUrl === videoUrl)
                })

                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = { videoUrl: e.videoUrl, uid: e.uid };
                  })
                }

                console.log(successArr)
                
              } else if (that.matSpecId == 4051 || that.matSpecId == 4052 || that.matSpecId == 4055 || that.matSpecId == 4056 ) {
                if (that.videoUrlList[i] == undefined) {
                  that.videoUrlList[i] = [];
                }
                that.videoUrlList[i].push({
                  videoUrl: response.data.data,
                  uid: file.file.uid,
                });
                //去重
                let set = new Set(that.videoUrlList[i].map(item => item.videoUrl))
                that.videoUrlList[i] = Array.from(set).map(videoUrl => {    
                  return that.videoUrlList[i].find(item => item.videoUrl === videoUrl)
                })

                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }
                console.log(successArr)

              } else {
                console.log(that.imgList[i],111111)
                if (that.imgList[i] == undefined) {
                  that.imgList[i] = [];
                }
                that.imgList[i].push({
                  imgList: [response.data.data],
                  uid: file.file.uid,
                });
                if (that.imgList[i] != undefined) {
                  that.imgList[i].forEach((obj, j) => {
                    console.log(obj)
                    successArr[j] = {
                      imgList: obj.imgList,
                      logoUrl: that.logoUrl,
                      uid: obj.uid,
                    };
                  });
                }
                
              }
              // console.log(that.imgUrlList)
            } else {
              //新增的批量上传封面
              if(name == "videoPage"){
                if (that.imgUrlList[i] == undefined) {
                  that.imgUrlList[i] = [];
                }
                that.imgUrlList[i].push({
                  imgUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.imgUrlList[i] != undefined) {
                  that.imgUrlList[i].forEach(function (e, index) {
                    imgUrlList[index] = e.imgUrl;
                    imgBigUrlList[index] ={
                      imgUrl:e.imgUrl,
                      uid:e.uid,
                    } 
                  })
                }
              }
              console.log(successArr)
              successArr.forEach(function (e, i) {
                if (name == "imgs") {
                 console.log(121212)
                  e.imgList.push(response.data.data);
                  
                  // e.uid = '1111111111'
                } else if (name == "brandLogo") {
                  e.logoUrl = response.data.data;
                } else if (name == "videoPage") {
                  e.imgUrl = response.data.data;
                } else if (name == "videoBg") {
                  e.videoBgUrl = response.data.data;
                } else {
                  e.videoUrl = response.data.data;
                }
              });
            }
          } else if (that.accountChannel == 4) {
            if (
              that.matSpecId != 30 &&
              that.matSpecId != 4004 &&
              that.matSpecId != 4019 &&
              that.matSpecId != 4023 
              // &&
              // that.matSpecId != 4008 &&
              // that.matSpecId != 4009 &&
              // that.matSpecId != 4013 &&
              // that.matSpecId != 4025 &&
              // that.matSpecId != 4028 &&
              // that.matSpecId != 4032 &&
              // that.matSpecId != 4033
            ) {
              if (name == "brandLogo") {
                that.logoUrl = response.data.data;
                if (that.imgList[i] != undefined) {
                  that.imgList[i].forEach((obj, j) => {
                    successArr[j] = {
                      imgList: obj.imgList,
                      logoUrl: that.logoUrl,
                      uid: obj.uid,
                    };
                  });
                }
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }
              } else if (name == "videoPage") {
                // that.imgUrl = response.data.data;
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }

                //新增的批量上传封面
                if (that.imgUrlList[i] == undefined) {
                  that.imgUrlList[i] = [];
                }
                that.imgUrlList[i].push({
                  imgUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.imgUrlList[i] != undefined) {
                  that.imgUrlList[i].forEach(function (e, index) {
                    imgUrlList[index] = e.imgUrl;
                    imgBigUrlList[index] ={
                      imgUrl:e.imgUrl,
                      uid:e.uid,
                    } 
                  })
                }

              } else if (
                name == "video" &&
                (that.matSpecId == 4008 || that.matSpecId == 4009|| that.matSpecId == 4013)
              ) {
                //判断是否是视频，然后进行循环push
                if (that.videoUrlList[i] == undefined) {
                  that.videoUrlList[i] = [];
                }
                that.videoUrlList[i].push({
                  videoUrl: response.data.data,
                  uid: file.file.uid,
                });
                //去重
                let set = new Set(that.videoUrlList[i].map(item => item.videoUrl))
                that.videoUrlList[i] = Array.from(set).map(videoUrl => {    
                  return that.videoUrlList[i].find(item => item.videoUrl === videoUrl)
                })

                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = { videoUrl: e.videoUrl, imgUrl: that.imgUrl,uid: e.uid };
                  });
                }

              } else if (that.matSpecId == 4025 || that.matSpecId == 4028||that.matSpecId == 4032 || that.matSpecId == 4033) {
                if (that.videoUrlList[i] == undefined) {
                  that.videoUrlList[i] = [];
                }
                that.videoUrlList[i].push({
                  videoUrl: response.data.data,
                  uid: file.file.uid,
                });
                //去重
                let set = new Set(that.videoUrlList[i].map(item => item.videoUrl))
                that.videoUrlList[i] = Array.from(set).map(videoUrl => {    
                  return that.videoUrlList[i].find(item => item.videoUrl === videoUrl)
                })
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }
              } else {
                if (that.imgList[i] == undefined) {
                  that.imgList[i] = [];
                }
                that.imgList[i].push({
                  imgList: [response.data.data],
                  uid: file.file.uid,
                });
                if (that.imgList[i] != undefined) {
                  that.imgList[i].forEach((obj, j) => {
                    successArr[j] = {
                      imgList: obj.imgList,
                      logoUrl: that.logoUrl,
                      uid: obj.uid,
                    };
                  });
                }
              }
            } else {
              //新增的批量上传封面
              if(name == "videoPage"){
                if (that.imgUrlList[i] == undefined) {
                  that.imgUrlList[i] = [];
                }
                that.imgUrlList[i].push({
                  imgUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.imgUrlList[i] != undefined) {
                  that.imgUrlList[i].forEach(function (e, index) {
                    imgUrlList[index] = e.imgUrl;
                    imgBigUrlList[index] ={
                      imgUrl:e.imgUrl,
                      uid:e.uid,
                    } 
                  })
                }
              }
             
              successArr.forEach(function (e, i) {
                if (name == "imgs") {
                  e.imgList.push(response.data.data);
                } else if (name == "brandLogo") {
                  e.logoUrl = response.data.data;
                } else if (name == "videoPage") {
                  // e.imgUrl = response.data.data;
                  e.imgUrl = '';
                } else if (name == "videoBg") {
                  e.videoBgUrl = response.data.data;
                } else {
                  e.videoUrl = response.data.data;
                }
              });
            }
          } else if (that.accountChannel == 5) {
            if (
              that.matSpecId != 30 &&
              that.matSpecId != 4004 &&
              that.matSpecId != 4019 &&
              that.matSpecId != 4023 &&
              that.matSpecId != 4008 &&
              that.matSpecId != 4009 &&
              that.matSpecId != 4013 &&
              that.matSpecId != 4025 &&
              that.matSpecId != 4028 &&
              that.matSpecId != 4032 &&
              that.matSpecId != 4033 &&
              that.matSpecId != 4043 &&
              that.matSpecId != 4044 &&
              that.matSpecId != 4045 &&
              that.matSpecId != 4046 &&
              that.matSpecId != 4047 &&
              that.matSpecId != 4048 &&
              that.matSpecId != 4054
            ) {
              if (name == "brandLogo") {
                that.logoUrl = response.data.data;
                if (that.imgList[i] != undefined) {
                  that.imgList[i].forEach((obj, j) => {
                    successArr[j] = {
                      imgList: obj.imgList,
                      logoUrl: that.logoUrl,
                      uid: obj.uid,
                    };
                  });
                }
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }
              } else if (name == "videoPage") {
                // that.imgUrl = response.data.data;
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }

                //新增的批量上传封面
                if (that.imgUrlList[i] == undefined) {
                  that.imgUrlList[i] = [];
                }
                that.imgUrlList[i].push({
                  imgUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.imgUrlList[i] != undefined) {
                  that.imgUrlList[i].forEach(function (e, index) {
                    imgUrlList[index] = e.imgUrl;
                    imgBigUrlList[index] ={
                      imgUrl:e.imgUrl,
                      uid:e.uid,
                    } 
                  })
                }

              } else if (
                name == "video" &&
                (that.matSpecId == 4049 || that.matSpecId == 4050)
              ) {
                //判断是否是视频，然后进行循环push
                if (that.videoUrlList[i] == undefined) {
                  that.videoUrlList[i] = [];
                }
                that.videoUrlList[i].push({
                  videoUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = { videoUrl: e.videoUrl, uid: e.uid };
                  });
                }
              } else if (that.matSpecId == 4051 || that.matSpecId == 4052) {
                if (that.videoUrlList[i] == undefined) {
                  that.videoUrlList[i] = [];
                }
                that.videoUrlList[i].push({
                  videoUrl: response.data.data,
                  uid: file.file.uid,
                });
                if (that.videoUrlList[i] != undefined) {
                  that.videoUrlList[i].forEach(function (e, index) {
                    successArr[index] = {
                      videoUrl: e.videoUrl,
                      logoUrl: that.logoUrl,
                      imgUrl: that.imgUrl,
                      uid: e.uid,
                    };
                  });
                }
              } else {
                if (that.imgList[i] == undefined) {
                  that.imgList[i] = [];
                }
                that.imgList[i].push({
                  imgList: [response.data.data],
                  uid: file.file.uid,
                });
                if (that.imgList[i] != undefined) {
                  that.imgList[i].forEach((obj, j) => {
                    successArr[j] = {
                      imgList: obj.imgList,
                      logoUrl: that.logoUrl,
                      uid: obj.uid,
                    };
                  });
                }
              }
            } else {
              successArr.forEach(function (e, i) {
                if (name == "imgs") {
                  e.imgList.push(response.data.data);
                } else if (name == "brandLogo") {
                  e.logoUrl = response.data.data;
                } else if (name == "videoPage") {
                  e.imgUrl = response.data.data;
                } else if (name == "videoBg") {
                  e.videoBgUrl = response.data.data;
                } else {
                  e.videoUrl = response.data.data;
                }
              });
            }
          }
        }
        that.closeTip = response.data.data;
        that.$message({
          showClose: true,
          message: response.data.msg,
          type: "success",
        });
        let List = [];
        that.videoList = [];
        that.uploadParams.forEach((item, j) => {
          if (item.materialDtoUrlDtos && item.materialDtoUrlDtos.length) {
            List.push(item.materialDtoUrlDtos);
          }
        });
        that.videoList = List;
        // console.log(fileList)
      } else {
        that.$refs.setupOrderform[0].validateField(
          that.uploadParams[i].propFile
        );
        // console.log(that.$refs.setupOrderform[0].validateField())
        that.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
        that.handleRemove(file, [], i);
      }
    },

    onError(err, file, fileList) {
      let that = this;
      that.$message.error(err.msg);
    },

    handleExceed(files, fileList) {
      // console.log(files)
      this.$message.warning(
        `当前限制选择 ${this.numberValue}  个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //标签和工单回显
    gethx() {
      let that = this;
      $http.axios
        .get(
          "/api/operationManagement/material/touploadview?id=" + that.uploadId
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.matSpecId = response.data.data.specId;
            that.accountChannel = response.data.data.accountChannel;
            if (
              that.matSpecId == 63 ||
              that.matSpecId == 60 ||
              that.matSpecId == 4049 ||
              that.matSpecId == 4050 ||
              that.matSpecId == 4051 ||
              that.matSpecId == 4052
            ) {
              that.mtnameShow = false;
            } else {
              that.mtnameShow = true;
            }
            that.workOrderName = response.data.data.demandNum;
            that.materialTab = response.data.data.tagName;
            that.materialType = response.data.data.materialType;
            that.materialAccountId = response.data.data.accountId;
            that.materialChannel = response.data.data.accountChannel;
            that.mmId.push(response.data.data.tagId);
            that.mmName.push(response.data.data.tagName);
            that.uploadParams[0].materialTagIds.push(response.data.data.tagId);
            that.uploadParams[0].materialTagNames.push(
              response.data.data.tagName
            );
            that.pictureDetail();
            // console.log(that.materialType)
            if (that.materialType == 2) {
              that.materialTypeshow = true;
              that.mtnameShow = false;
            } else {
              that.materialTypeshow = false;
              that.mtnameShow = true;
            }
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg || "工单异常",
              type: "error",
            });
            // that.$router.push('/auth-management/DemandOrder')
          }
        });
    },

    //根据回显接口获取图片详情
    pictureDetail() {
      let that = this;
      // console.log(that.matSpecId)
      $http.axios
        .get(
          "/api/operationManagement/material/getChannelConfig?matSpecId=" +
            that.matSpecId +
            "&channelId=" +
            that.accountChannel
        )
        .then(function (response) {
          //修改之后的按钮
          if (response.data.code == 200) {
            // console.log(that.matSpecId)
            that.picDetail = response.data.data;
            that.path = response.data.data.path;
            response.data.data.forEach(function (e, i) {
              that.picValue = e.value;
              if (e.name == "imgs") {
                that.numberValue = e.value;
              }
            });
            // console.log(that.picDetail)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //查询的素材标签下拉框数据
    getTagList() {
      let that = this;
      that.tagListParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post("/api/operationManagement/material/taglist", that.tagListParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.tfMaterialTabOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //获取人员信息
    getStaffDetail() {
      let that = this;
      $http.axios
        .get("/api/system/user/optionselect")
        .then(function (response) {
          that.demandNameOptionsbd = response.data;
          that.demandNameOptionssy = response.data;
          that.demandNameOptionsjj = response.data;
        });
    },


    //没有重名后保存成功跳转页面
    submitSuccess() {
      let that = this;
      // that.flag = true
      // that.showSubmitTip = true

      
      $http.axios
        .post("/api/operationManagement/material/devupload", that.uploadParams)
        .then((res) => {
          that.flag = true;
          that.showSubmitTip = true;
          if (res.data.code == 200) {
            setTimeout(() => {
              that.flag = false;
              that.showSubmitTip = false;
              if (that.accountChannel == 5) {
                if (that.$route.query.status == 111) {
                  that.$router.back();
                } else {
                  if (that.$route.query.type == 2) {
                    that.$router.push({
                      name: "authManagement.MaterialListPic",
                      query: { mStatus: that.$route.query.mStatus },
                    });
                  } else {
                    that.$router.push({
                      name: "authManagement.MaterialList",
                      query: { mStatus: that.$route.query.mStatus },
                    });
                  }
                }
              } else {
                that.$router.back();
              }
            }, 5000);
            // if(that.accountChannel == 5){
            //   if(that.$route.query.status == 111){
            //     that.$router.back()
            //   }else{
            //     if(that.$route.query.type == 2){
            //       that.$router.push({name:'authManagement.MaterialListPic',query:{mStatus:that.$route.query.mStatus}})
            //     }else{
            //       that.$router.push({name:'authManagement.MaterialList',query:{mStatus:that.$route.query.mStatus}})
            //     }
            //   }
            // }else{
            //   that.$router.back()
            // }
          } else {
            // that.flag = true
            // that.showSubmitTip = true
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
          setTimeout(() => {
            that.flag = false;
            that.showSubmitTip = false;
          }, 5000);
        });
    },

    //保存的时候先判断有没有重复的视频：分为1和数据库重复，2本次上传有重复视频，3和数据库和本次上传都有重复
    submitUpload() {
      let that = this;
      $http.axios
        .post("/api/operationManagement/material/judgeName", that.uploadParams)
        .then((res) => {
          // $http.axios.post("/lcl/material/judgeName",that.uploadParams).then(res=> {
          let judgeData = res.data.data;
          if (res.data.code == 200) {
            that.submitSuccess();
          } else {
            that.duplicateVisible = true;
            that.duplicateDate = res.data.msg;
          }
        });
    },

    //新增素材
    addMaterialList() {
      let that = this;
      if (
        that.matSpecId == 63 ||
        that.matSpecId == 60 ||
        that.matSpecId == 4049 ||
        that.matSpecId == 4050 ||
        that.matSpecId == 4051 ||
        that.matSpecId == 4052
      ) {
        that.mtnameShow = false;
      } else {
        that.mtnameShow = true;
      }
      that.num++;
      // that.uploadParams.push({propFile:'file'+that.num,materialTagIds:that.mmId,materialTagNames:that.mmName})
      // that.uploadParams[that.num].materialDtoUrlDtos.push({imgUrl :''},{})
      that.uploadParams.push({
        propFile: "file" + that.num,
        materialTagIds: that.mmId,
        materialTagNames: that.mmName,
        imgUrlList:[],
        imgBigUrlList:[],
        materialDtoUrlDtos: [
          {
            imgList: [],
            imgUrl: "",
            logoUrl: "",
            videoBgUrl: "",
            videoUrl: "",
          },
        ],
      });
      that.rules["file" + that.num] = [
        { required: true, message: "请重新上传文件", trigger: "change" },
      ];
    },

    //删除新增素材
    removeRow(index) {
      this.num--;
      if (index >= 0) {
        this.uploadParams.splice(index, 1);
        this.videoList.splice(index, 1);
        delete this.rules["file" + this.num];
      }
    },

    getId() {
      this.myHeaders.Authorization =
        "Bearer " + this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
      this.uploadId = this.$route.params && this.$route.params.id;
    },

    cancelTip() {
      this.tipdialogVisible = true;
    },

    //取消
    cancel() {
      if (this.accountChannel == 5) {
        if (this.$route.query.status == 111) {
          this.$router.back();
        } else {
          if (this.$route.query.type == 2) {
            this.$router.push({
              name: "authManagement.MaterialListPic",
              query: { mStatus: this.$route.query.mStatus },
            });
          } else {
            this.$router.push({
              name: "authManagement.MaterialList",
              query: { mStatus: this.$route.query.mStatus },
            });
          }
        }
      } else {
        this.$router.back();
      }
    },

    //获取合同服务类型的name值
    selectContract(vId, i) {
      // vID 是一个数组，循环获取name,根据id
      if (!vId && vId.length === 0) {
        return;
      } //这里多选的时候获取name和单选不一样 单选是对象 多选是数组所以...
      let nameArr = [];
      let codeArr = [];
      let that = this;
      vId.forEach((element) => {
        let obj = that.tfMaterialTabOptions.find((item) => {
          return item.id === element;
        });
        if (obj) {
          nameArr.push(obj.tagName);
          codeArr.push(obj.id);
        }
      });
      this.uploadParams[i].materialTagNames = nameArr;
    },

    //获取编导详情
    bdchange(vId, i) {
      let obj = {};
      obj = this.demandNameOptionsbd.find((item) => {
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj)
      this.uploadParams[i].directorUserName = obj.name;
    },

    //获取摄影人员
    changesy(vId, i) {
      let obj = {};
      obj = this.demandNameOptionssy.find((item) => {
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj)
      this.uploadParams[i].photographyUserName = obj.name;
      // this.uploadParams[i].demandNameOptionsjj = obj.name
    },

    //剪辑
    changejj(vId, i) {
      let obj = {};
      obj = this.demandNameOptionsjj.find((item) => {
        return item.id === vId; //筛选出匹配数据
      });
      this.uploadParams[i].clipUserName = obj.name;
      this.uploadParams[i].makerUserName = obj.name;
      this.uploadParams[i].makerUserId = vId;
      this.uploadParams[i].clipUserId = vId;

      // //回显或着上一页带过来的参数必填项
      // this.uploadParams[i].accountId = this.materialAccountId
      // this.uploadParams[i].channelId = this.accountChannel
      // this.uploadParams[i].userId = this.$sessionStorage.getItem(config.USERID) || 1
      // this.uploadParams[i].username = this.$sessionStorage.getItem(config.USERNAME)
      // this.uploadParams[i].specId = this.matSpecId
      // this.uploadParams[i].detailId = this.uploadId
      // this.uploadParams[i].type = this.materialType
    },

    //素材名称失去焦点之后校验
    checkSlash(item) {
      let reg = new RegExp('[\\\\/:*?"<>|]');
      // console.log(reg.test(item))
      if (reg.test(item) == true) {
        this.$message({
          showClose: true,
          message: '请删除素材名称中的 / : * ? " < > | 字符',
          type: "error",
        });
      }
    },

    //点击视频预览
    clickVideo(src) {
      this.VideoVisible = true;
      this.videoForm = src;
    },
    //关闭视频
    handleClose(e) {
      this.$refs.vueRef[0].pause();
      this.VideoVisible = false;
    },

    //删除视频
    clickVideoDel(del, index, i, file) {
      // console.log(del)
      // console.log(index)
      // console.log(i)
      // console.log(file)
      let _this = this;
      _this.uploadParams[i].materialDtoUrlDtos.forEach((obj, item) => {
        console.log(obj)
        console.log(item)
        console.log(_this.$refs.uploadNew[i].uploadFiles);
        if (del == obj.videoUrl) {
          _this.uploadParams[i].materialDtoUrlDtos.splice(item, 1);
          if (
            _this.matSpecId == 4049 ||
            _this.matSpecId == 4050 ||
            _this.matSpecId == 4051 ||
            _this.matSpecId == 4052
          ) {
            // console.log('delete')
            _this.videoUrlList[i].splice(item, 1);
            console.log(_this.$refs.uploadNew[i].uploadFiles)
            _this.$refs.uploadNew[i].uploadFiles.splice(item, 1);
          }
        }
        
      });
      console.log(_this.uploadParams[i].materialDtoUrlDtos)
    },
  },
};
</script>
<style lang="scss" scoped>
/*批量上传视频展示样式*/
.videoPart {
  margin-bottom: 21px;
  width: 70px;
  height: 70px;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #c0ccda;
}
// .upload-demo .el-upload.el-upload--picture {
//   position: relative;
// }
// ::v-deep .videoClass .el-upload-list__item .el-icon-close {
//   color: #fff;
// }
// ::v-deep .imgClass .el-upload-list__item .el-icon-close {
//   color: #606266;
// }
// ::v-deep .videoClass .el-upload-list--picture .el-upload-list__item {
//   border: none;
// }
// ::v-deep .imgClass .el-upload-list--picture .el-upload-list__item {
//   border: 1px solid #c0ccda;
// }
// ::v-deep .videoClass .el-upload-list__item-name {
//   color: #fff;
// }
/*当matSpecId是4051和4052的时候出现showvideo的类名*/
// ::v-deep .showVideo .el-upload-list__item,
// ::v-deep .showVideo .el-upload-list--picture .el-upload-list__item-thumbnail {
//   display: none;
// }
// ::v-deep .showVideo .el-upload-list--picture .el-upload-list__item-name i {
//   font-size: 0;
// }
// ::v-deep .hideVideo .el-upload-list--picture .el-upload-list__item-thumbnail {
//   display: inline-block;
// }
// ::v-deep .hideVideo .el-upload-list--picture .el-upload-list__item-name i {
//   font-size: 70px;
// }
.submit_form_container {
  width: 100%;
  margin-top: 20px;

  .children_contant:nth-child(1) {
    margin-top: 0;
  }
  
  .el-form >div:not(:first-child) {
    padding-top: 20px;
    border-top: 1px solid $title-border-color;
  }
}
.el-card {
  overflow: visible;
}
.videoNameSize{
  width:150px;
  font-size:12px;
  display: inline-flex;
}
::v-deep .imgClass .el-upload-list--picture-card .el-upload-list__item{
  overflow: visible!important;
}
::v-deep .imgClass .el-upload-list--picture-card .el-upload-list__item-name{
  display: inline-flex;
  font-size: 12px;
  width: 150px;
  margin-right: 0;
  overflow:visible;
  white-space: normal;
  
}
</style>