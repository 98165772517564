<template>
  <div class="user-login login-scale login_bg">
    <!-- <video muted autoplay loop object-fit
      id="video-tvc" src="../assets/video/login.mp4" class="video-tvc">抱歉，您的浏览器不支持内嵌视频</video> -->

    <!-- <img class="logo" src="../../public/static/back.mp4" alt> -->
    <video muted autoplay loop object-fit controlslist="nodownload" 
      id="video-tvc" src="../../public/static/back.mp4" class="video-tvc"></video>
    <div class="login_wrap">
      <h3><img src="../assets/img/hl_logo.png"/></h3>
      <div class="login_form">
        <el-form :model="userInfo" status-icon ref="ruleForm" label-width="100px" class="loginInPage demo-ruleForm">
          <el-form-item label="" prop="username">
            <el-input v-model="userInfo.username" autocomplete="off" placeholder="请输入账号" class="inputUsername"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input 
            type="password" 
            class="inputPassword"
            v-model="userInfo.password" 
            autocomplete="off" 
            placeholder="请输入密码" 
            @keyup.enter.native="onLogin">
          </el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="background:#3b3d44;color:#fff;" @click="onLogin">登录</el-button>
            <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  
</template>

<script>
import Api from "@/api";
// import JSEncrypt from "jsencrypt";
import config from '@/api/config';
// import { mapActions } from "vuex";
const { VUE_APP_BASEURL } = process.env;

export default {
  name: "UserLogin",

  data() {
    //密码
    // var validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'));
    //   } else {
    //     if (this.ruleForm.checkPass !== '') {
    //       this.$refs.ruleForm.validateField('checkPass');
    //     }
    //     callback();
    //   }
    // };
    // var validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'));
    //   }
    // };
    //账号
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入账号'));
    //   } 
    // };
    return {
      userInfo: {
        username: '',
        password: ''
      },
      userId:''
      // rules: {
      //   pass: [
      //     // { validator: validatePass, trigger: 'blur' }
      //     {required: true, message: '请输入密码', trigger: 'change'}
      //   ],
      //   username: [
      //     { validator: validatePass2, trigger: 'blur' }
      //   ]
      // }
    };
  },

  mounted() {
  },

  methods: {
    async onLogin() {
      let _this = this,userId=''
      // let  loginWenan = this.$cookies.get(config.COOKIE_TIME);
      const {data,code,msg} = await Api.user.loginHl(_this.userInfo)
      if(code === 200) {
        this.$sessionStorage.setItem(config.USER_TOKEN_KEY, data.access_token);
        this.$cookies.set(config.COOKIE_TOKEN, data.access_token);
        this.$sessionStorage.setItem(config.USERID, data.userInfo.userid);
        this.$cookies.set(config.COOKIE_USERID, data.userInfo.userid);
        this.$sessionStorage.setItem(config.USERNAME, data.userInfo.username);
        this.$cookies.set(config.COOKIE_USERNAME, data.userInfo.username);
        this.$sessionStorage.setItem(config.DEPTID, data.userInfo.sysUser.dept.deptId);
        this.$cookies.set(config.COOKIE_DEPTID, data.userInfo.sysUser.dept.deptId);
        this.$sessionStorage.setItem(config.ROLES, data.userInfo.roles);
        this.$cookies.set(config.COOKIE_ROLES, data.userInfo.roles);
        this.$sessionStorage.setItem(config.NICKNAME, data.userInfo.sysUser.nickName);
        this.$sessionStorage.setItem(config.DEPTNAME, data.userInfo.sysUser.dept.deptName);
        // if(loginWenan==undefined){
        //   this.$cookies.set(config.COOKIE_TIME,'0');
        // }else{
        //   loginWenan++
        //   this.$cookies.set(config.COOKIE_TIME,loginWenan);
        // }
        console.log(this.$cookies.get(config.COOKIE_TOKEN))
        userId = data.userInfo.userid
        if(userId == 559){
          this.$router.push('/auth-management/DataSjkboppo2')
        }else{
          this.$router.push('/auth-management/DataSjkb')
        }
       

      } 
      else {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error',  
        })
      }
    }
  }
};
</script>

<style lang="scss">
/* @import "@/assets/css/var.scss"; */
.login_bg{
  width:100%;
  height:100%;
  /* background:url(../../public/static/back.mp4) no-repeat; */
  position: relative;
  background-size:100% 100%;
  /* background-size:contain; */
}
.login_wrap {
  position:absolute;
  top:0;
  right:0;
  width:400px;
  height:100%;
  min-height:754px;
  background:rgba(255,255,255,0.2);
  padding:30px 40px;
  box-sizing:border-box;
}
.login_wrap h3{
  width:260px;
  margin:0 auto;
}
.login_wrap h3 img{
  width:100%;
}
.login_form .el-form-item__content{
  margin-left:0!important;
  text-align:center;
  width:100%;
}
.login_form .el-form-item{
  width:100%;
}
.login_form .el-form-item input{
  height: 40px!important;
  line-height:40px!important;
}
.login_form .el-button{
  width:100%!important;
  height:40px!important;
  margin-top:30px;
}
/* .login_form .el-form-item--mini.el-form-item{
  margin-bottom:36px!important;
} */
.loginInPage .el-form-item--mini .el-form-item__content, .loginInPage .el-input.el-input--mini{
  width: 100%;
}
.login_form .el-popup-parent--hidden .el-form-item__error, #app .el-form-item__error{
  line-height:16px!important;
}

.login_form{
  position:absolute;
  bottom:120px;
  width:320px;
}
</style>
