<template>
<el-dialog
  title="自定义列"
  :visible.sync="columnVisible"
  center
  :show-close = "false"
  class="zdylMargin"
  width="820px">
  <div class="dialogIcon"><img :src='ZDYLicon1'></div>
  <div class="columnWrap">
    <div class="column_left">
      <template>
        <div>
          <!-- <span class="zdyboxTitle">广告组基础数据</span>
          <el-checkbox :indeterminate="isIndeterminateSystem" v-model="checkAllSystem" @change="(val) => handleCheckAllChange(val,'广告组基础数据')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedSystemId" @change = "checked => handleCheckedCitiesChange('广告组基础数据',checkedSystemId)">
            <el-checkbox v-for="(item,i) in systemList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group> -->
        </div>
      </template>
      <!-- 基础数据 -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">基础数据</span>
          <el-checkbox :indeterminate="isIndeterminateInfo" v-model="checkAllInfo" @change="(val) => handleCheckAllChange(val,'基础数据')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedInfoId" @change = "checked => handleCheckedCitiesChange('基础数据',checkedInfoId)">
            <el-checkbox v-for="(item,i) in infoList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- 效果数据 -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">效果数据</span>
          <el-checkbox :indeterminate="isIndeterminateData" v-model="checkAllData" @change="(val) => handleCheckAllChange(val,'效果数据')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedDataId" @change = "checked => handleCheckedCitiesChange('效果数据',checkedDataId)">
            <el-checkbox v-for="(item,i) in dataList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- APP转化数据(转化时间) -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">APP转化数据(转化时间)</span>
          <el-checkbox :indeterminate="isIndeterminateConversion" v-model="checkAllConversion" @change="(val) => handleCheckAllChange(val,'APP转化数据(转化时间)')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedConversionId" @change = "checked => handleCheckedCitiesChange('APP转化数据(转化时间)',checkedConversionId)">
            <el-checkbox v-for="(item,i) in conversionList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- APP转化数据(计费时间) -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">APP转化数据(计费时间)</span>
          <el-checkbox :indeterminate="isIndeterminateWeb" v-model="checkAllweb" @change="(val) => handleCheckAllChange(val,'APP转化数据(计费时间)')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedWebId"  @change = "checked => handleCheckedCitiesChange('APP转化数据(计费时间)',checkedWebId)">
            <el-checkbox v-for="(item,i) in webList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- 转化数据(转化时间) -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">转化数据(转化时间)</span>
          <el-checkbox :indeterminate="isTransformWebT" v-model="checkAllTransform" @change="(val) => handleCheckAllChange(val,'转化数据(转化时间)')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedAllTransformId"  @change = "checked => handleCheckedCitiesChange('转化数据(转化时间)',checkedAllTransformId)">
            <el-checkbox v-for="(item,i) in transformWebTList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- 转化数据(计费时间) -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">转化数据(计费时间)</span>
          <el-checkbox :indeterminate="isTransformWebJ" v-model="checkAllTransformJ" @change="(val) => handleCheckAllChange(val,'转化数据(计费时间)')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedAllTransformIdJ"  @change = "checked => handleCheckedCitiesChange('转化数据(计费时间)',checkedAllTransformIdJ)">
            <el-checkbox v-for="(item,i) in transformWebTListJ" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- 网页转化数据(转化时间) -->
      <template>
        <div class="zdybox">
          <span class="zdyboxTitle">网页转化数据(转化时间)</span>
          <el-checkbox :indeterminate="isTransformWebW" v-model="checkAllTransformW" @change="(val) => handleCheckAllChange(val,'网页转化数据(转化时间)')">全选</el-checkbox>
          <el-checkbox-group 
            v-model="checkedAllTransformIdW"  @change = "checked => handleCheckedCitiesChange('网页转化数据(转化时间)',checkedAllTransformIdW)">
            <el-checkbox v-for="(item,i) in transformWebTListW" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>




    </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" class="newDialogCancel" @click="columnVisibleFn">取消保存</el-button>
    <el-button type="primary" class="newDialog" @click="generateTemplate">确认保存</el-button>
  </span>
</el-dialog>  
</template>
<script>
  import ZDYLicon1 from '@/assets/img/ZDYLicon1.png';
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import alertMsgFn from '@/assets/js/alert';
  import Qs from 'qs'
  export default {
    name: 'Daily',
    props:{
       pubVue: {
      type: Object
    },
    typeName:Number
    },
    data() {
      return {
        ZDYLicon1,
        userId:'',
        userName:'',
        deptId:'',
        deptName:'',
        ruleForm:{//查询条件
          channelName:'',
          templateName:'',
          dateChoice:''
        },
        allList:[],//自定义返回的所有
        tableData: [],//时报列表数据
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        batchOperation:'',
        columnVisible:this.visible,//生成模版弹框隐藏显示
        checkedCities: [],//生成模版选择了多少选项
        checkedInfo: [],//
        checkedSystem: [],//
        checkedData: [],//
        checkedConversion: [],//
        checkedWeb:[],
        checkedInfoId: [],//
        checkedSystemId: [],//
        checkedDataId: [],//
        checkedConversionId: [],//
        checkedWebId:[],
        checkedAllTransform:[],//转化数据(转化时间)
        checkedAllTransformId:[],//转化数据(转化时间)
        checkedAllTransformJ:[],
        checkedAllTransformIdJ:[],
        checkedAllTransformW:[],
        checkedAllTransformIdW:[],
        newWords:'',//生成新的时报模板字段
        system:[],
        info:[],
        data:[],
        conversion:[],//点击生成按钮返回的关键字段
        web:[],
        webT:[],
        webJ:[],
        webW:[],
        systemList:[],
        infoList:[],//选中的基础信息
        dataList:[],//选中的基础数据
        conversionList:[],//选中的转化数据
        webList:[],
        transformWebTList:[],//转化数据(转化时间)
        transformWebIdTList:[],//转化数据(转化时间)
        transformWebTListJ:[],
        transformWebIdListJ:[],
        transformWebTListW:[],
        transformWebIdListW:[],
        systemIdList:[],
        infoIdList:[],
        dataIdList:[],
        conversionIdList:[],
        webIdList:[],
        deleteWords:false,//删除手动添加字段的按钮
        addWordsParams:{//添加自定义字段入参
          name:'',
          userId:'',
          userName:''
        },
        deleteParams:{//删除手动添加关键字的入参
          ids:[],
          userId:'',
          userName:''
        },
        newTempName:'',//模版名称
        dialogVisible:false,
        isIndeterminateInfo:false,
        checkAllInfo:false,
        isIndeterminateSystem:false,
        checkAllSystem:false,
        isIndeterminateData:false,
        checkAllData:false,
        isIndeterminateConversion:false,
        checkAllConversion:false,
        isIndeterminateWeb:false,
        isTransformWebT:false,//转化数据(转化时间)
        isTransformWebJ:false,//转化数据(计费时间)
        isTransformWebW:false,
        checkAllweb:false,
        checkAllTransform:false,//转化数据(转化时间)
        checkAllTransformJ:false,
        checkAllTransformW:false,
        titleListAll:{
          1:[
              {name:'广告计划ID',prop:'id'},
              {name:'计划类型',prop:'typeFormat'},//0-应用商店 1-非应用商店 2-广告联盟
              {name:'投放开关',prop:'statusTypeIs'},// 0-未暂停 1-已暂停
              // {name:'设置计划',prop:'openClosePlan'},//是否设置启停:1-是 0-否
              {name:'调价计划',prop:'modifyPricePlanIds'},//是否调价启停:1-是 0-否
              {name:'启停计划',prop:'openStopPlanIds'},//是否启停启停:1-是 0-否
              {name:'预警状态',prop:'warningSwitch'},//0-正常 1-禁用 2-删除
            ],
          2:[ {name:'投放开关',prop:'statusTypeIs'},// 
              {name:'组类型',prop:'displayTypeFormat'},// 
              {name:'投放状态',prop:'deliveryStatusDisplayName'},// 
              {name:'启停计划',prop:'openStopPlanIds'},// 

          ],
          3:[
              {name:'投放开关',prop:'statusTypeIs'},// 开->1(有效);关->2(暂停)
              {name:'创意类型',prop:'placementTypeFormat'},// 广告创意类型名称："精品列表广告"
              {name:'投放状态',prop:'deliveryStatusDisplayName'},// 投放状态名称："未投放"
              {name:'调价计划',prop:'modifyPricePlanIds'},//1->是；其他->否
              {name:'预警状态',prop:'warningSwitch'},//是否设置启停:1-是 0-否(还没有加上)
              {name:'审核状态',prop:'assetCheckStatusName'},// 创意审核结果：101(同搜索条件中的创意状态)
              {name:'广告出价',prop:'bidName'}
          ] 
        }

      };
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptName = this.$sessionStorage.getItem(config.DEPTNAME) 
      this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    },
    methods: {
      //弱提示
       alertMsgFn(msg,type){
        this.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
      columnVisibleFn(){
        let _this = this
        _this.columnVisible = false
      },
      handleCheckAllChange(val,type) {
        console.log(this.checkedCities,this.info,type,val)
        let _this = this
        if(type == '基础数据'){
          _this.checkedInfoId = val ? _this.infoIdList : [];
          _this.checkedInfo = val ? _this.infoList : [];
          _this.isIndeterminateInfo = false;
        }else if(type == '效果数据'){
          _this.checkedDataId = val ? _this.dataIdList : [];
          _this.checkedData = val ? _this.dataList : [];
          _this.isIndeterminateData = false;
        }else if(type == 'APP转化数据(转化时间)'){
          _this.checkedConversionId = val ? _this.conversionIdList : [];
          _this.checkedConversion = val ? _this.conversionList : [];
          _this.isIndeterminateConversion = false;
        }else if(type == '广告组基础数据'){
          _this.checkedSystemId = val ? _this.systemIdList : [];
          _this.checkedSystem = val ? _this.systemList : [];
          _this.isIndeterminateSystem = false;
        }else if(type == 'APP转化数据(计费时间)'){
          _this.checkedWebId = val ? _this.webIdList : [];
          _this.checkedWeb = val ? _this.webList : [];
          _this.isIndeterminateWeb = false;
        }else if(type == '转化数据(转化时间)'){
          _this.checkedAllTransformId = val ? _this.transformWebIdTList : [];
          _this.checkedAllTransform = val ? _this.transformWebTList : [];
          _this.isTransformWebT = false;
        }else if(type == '转化数据(计费时间)'){
          _this.checkedAllTransformIdJ = val ? _this.transformWebIdListJ : [];
          _this.checkedAllTransformJ = val ? _this.transformWebTListJ : [];
          _this.isTransformWebJ = false;
        }else if(type == '网页转化数据(转化时间)'){
          _this.checkedAllTransformIdW = val ? _this.transformWebIdListW : [];
          _this.checkedAllTransformW = val ? _this.transformWebTListW : [];
          _this.isTransformWebW = false;
        }

        

        
        

        this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkedSystem,_this.checkedWeb,_this.checkedAllTransform,_this.checkedAllTransformJ,_this.checkedAllTransformW)
      },
      handleCheckedCitiesChange(type,value) {
        let _this = this,val = [],
        checkedCountInfo = this.checkedInfoId,
        checkedCountData = this.checkedDataId,
        checkedCountConversion = this.checkedConversionId,
        checkedCountSystem = this.checkedSystemId,
        checkedCountWeb = this.checkedWebId,
        checkedTransformCountWeb = this.checkedAllTransformId,
        checkedTransformJCountWeb = this.checkedAllTransformIdJ,
        checkedTransformwCountWeb = this.checkedAllTransformIdW
        
        
        
        _this.allList.forEach((obj,i)=>{
          value.forEach((ele,j)=>{
            if(obj.id == ele){
              val.push(obj)
            }
          })
        })
        if(type == '基础数据'){
          _this.checkedInfoId = value 
          checkedCountInfo = value.length
          this.checkAllInfo = checkedCountInfo === this.infoIdList.length;
          this.isIndeterminateInfo = checkedCountInfo > 0 && checkedCountInfo < this.infoIdList.length;
          _this.checkedInfo = val
        }else{
          _this.checkedInfo = []
          _this.checkedInfoId.forEach((obj)=>{
            _this.infoList.forEach((ele)=>{
              if(ele.id == obj){
                _this.checkedInfo.push(ele)
              }
            })
          })
        }
        if(type == '效果数据'){
          _this.checkedDataId = value
          checkedCountData =value.length
          this.checkAllData = checkedCountData === this.dataIdList.length;
          this.isIndeterminateData = checkedCountData > 0 && checkedCountData < this.dataIdList.length;
          _this.checkedData = val
        }else{
          _this.checkedData = []
          _this.checkedDataId.forEach((obj,i)=>{
            _this.dataList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedData.push(ele)
              }
            })
          })
        }
        if(type == 'APP转化数据(转化时间)'){
          _this.checkedConversionId = value 
          checkedCountConversion = value.length;
          this.checkAllConversion = checkedCountConversion === this.conversionIdList.length;
          this.isIndeterminateConversion = checkedCountConversion > 0 && checkedCountConversion < this.conversionIdList.length;
          _this.checkedConversion = val
        }else{
          _this.checkedConversion = []
          _this.checkedConversionId.forEach((obj,i)=>{
            _this.conversionList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedConversion.push(ele)
              }
            })
          })
        }
        if(type == '广告组基础数据'){
          _this.checkedSystemId = value
          checkedCountSystem = value.length
          this.checkAllSystem = checkedCountSystem === this.systemIdList.length;
          this.isIndeterminateSystem = checkedCountSystem > 0 && checkedCountSystem < this.systemIdList.length;
          _this.checkedSystem = val
        }else{
           _this.checkedSystem = []
           _this.checkedSystemId.forEach((obj,i)=>{
            _this.systemList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedSystem.push(ele)
              }
            })
          })
        }
        if(type == 'APP转化数据(计费时间)'){
          _this.checkedWebId = value
          checkedCountWeb = value.length
          this.checkAllWeb = checkedCountWeb === this.webIdList.length;
          this.isIndeterminateWeb = checkedCountWeb > 0 && checkedCountWeb < this.webIdList.length;
          _this.checkedWeb = val
        }else{
          _this.checkedWeb = []
          _this.checkedWebId.forEach((obj,i)=>{
            _this.webList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedWeb.push(ele)
              }
            })
          })
        }
        if(type == '转化数据(转化时间)'){
          _this.checkedAllTransformId = value
          checkedTransformCountWeb = value.length
          this.checkAllTransform = checkedTransformCountWeb === this.transformWebIdTList.length;
          this.isTransformWebT = checkedTransformCountWeb > 0 && checkedTransformCountWeb < this.transformWebIdTList.length;
          _this.checkedAllTransform = val
        }else{
          _this.checkedAllTransform = []
          _this.checkedAllTransformId.forEach((obj,i)=>{
            _this.transformWebTList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedAllTransform.push(ele)
              }
            })
          })
        }
        if(type == '转化数据(计费时间)'){
          _this.checkedAllTransformIdJ = value
          checkedTransformJCountWeb = value.length
          this.checkAllTransformJ = checkedTransformJCountWeb === this.transformWebIdListJ.length;
          this.isTransformWebJ = checkedTransformJCountWeb > 0 && checkedTransformJCountWeb < this.transformWebIdListJ.length;
          _this.checkedAllTransformJ = val
        }else{
          _this.checkedAllTransformJ = []
          _this.checkedAllTransformIdJ.forEach((obj,i)=>{
            _this.transformWebTListJ.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedAllTransformJ.push(ele)
              }
            })
          })
        }
        if(type == '网页转化数据(转化时间)'){
          _this.checkedAllTransformIdW = value
          checkedTransformwCountWeb = value.length
          this.checkAllTransformW = checkedTransformwCountWeb === this.transformWebIdListW.length;
          this.isTransformWebW = checkedTransformwCountWeb > 0 && checkedTransformwCountWeb < this.transformWebIdListW.length;
          _this.checkedAllTransformW = val
        }else{
          _this.checkedAllTransformW = []
          _this.checkedAllTransformIdW.forEach((obj,i)=>{
            _this.transformWebTListW.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedAllTransformW.push(ele)
              }
            })
          })
        }
        
        this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkedSystem,_this.checkedWeb,_this.checkedAllTransform,_this.checkedAllTransformJ,_this.checkedAllTransformW)
      },
      //生成时报模板弹框显示
      generateTimes(num){
        this.columnVisible = true
        this.allWords(num)
      },
      //查询全部关键字接口
      allWords(type){
        let that = this,params = {},
        checkedList = [],
        systemList = [],
        infoList = [],
        dataList = [],
        conversionList = [],
        webList = [],
        transformWebTList = [],
        transformWebTListJ = [],
        transformWebTListW = [],
        systemIdList = [],
        infoIdList = [],
        dataIdList = [],
        conversionIdList = [],
        webIdList = [],
        transformWebIdTList = [],
        transformWebIdListJ = [],
        transformWebIdListW = []


        params = {'channelId': 5,'reportType':type}
        $http.axios.post("/api/mi/keyWord/list",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          console.log(res)
          if(res.data.code == 200){
            checkedList = res.data.data
            checkedList.forEach((e,i,arr)=>{
              if(e.type == "广告组基础数据"){
                systemList.push(e)
                systemIdList.push(e.id)
              }else if(e.type == '基础数据'){
                infoList.push(e)
                infoIdList.push(e.id)
              } else if (e.type == '效果数据'){
                dataList.push(e)
                dataIdList.push(e.id)
              }else if(e.type == 'APP转化数据(转化时间)'){
                conversionList.push(e)
                conversionIdList.push(e.id)
              }else if(e.type == 'APP转化数据(计费时间)'){
                webList.push(e)
                webIdList.push(e.id)
              }else if(e.type == '转化数据(转化时间)'){
                transformWebTList.push(e)
                transformWebIdTList.push(e.id)
              }else if(e.type == '转化数据(计费时间)'){
                transformWebTListJ.push(e)
                transformWebIdListJ.push(e.id)
              }else if(e.type == '网页转化数据(转化时间)'){
                transformWebTListW.push(e)
                transformWebIdListW.push(e.id)
              }
              
              
              
            })
            that.allList = checkedList
            that.checkedWordsFn(type)
            that.systemList = systemList
            that.infoList = infoList
            that.dataList = dataList
            that.conversionList = conversionList
            that.webList = webList
            that.systemIdList = systemIdList
            that.infoIdList = infoIdList
            that.dataIdList = dataIdList
            that.conversionIdList = conversionIdList
            that.webIdList = webIdList
            that.transformWebIdTList = transformWebIdTList
            that.transformWebIdListJ = transformWebIdListJ
            that.transformWebIdListW = transformWebIdListW
            that.transformWebTList = transformWebTList
            that.transformWebTListJ = transformWebTListJ
            that.transformWebTListW = transformWebTListW
          }  else {
            that.alertMsgFn(res.data.msg,'error')
          }
        })
      },
      //用户选中的
      checkedWordsFn(type){
        let that = this,params,
        checkedCities = [],
        titleList = [],
        checkedInfo = [],
        checkedData = [],
        checkedConversion = [],
        checkedSystem = [],
        checkedWeb = [],
        checkedAllTransform = [],
        checkedAllTransformJ = [],
        checkedAllTransformW = [],
        checkedInfoId = [],
        checkedDataId = [],
        checkedConversionId = [],
        checkedSystemId = [],
        checkedWebId = [],
        checkedTransformCountWeb = [],
        checkedAllTransformId = [],
        checkedAllTransformIdJ = [],
        checkedAllTransformIdW = []

        params = {'userId': that.userId,'reportType':type}
        $http.axios.post("/api/mi/keyWord/query",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code == 200){
            if(res.data.data.length!=0){
                res.data.data.forEach((e,i)=>{
                  titleList.push({'name':e.name,'prop':e.fieldName})
                  if(e.type == '广告组基础数据'){
                    checkedSystem.push(e)
                    checkedSystemId.push(e.id)
                  } else if(e.type == '基础数据'){
                    checkedInfo.push(e)
                    checkedInfoId.push(e.id)
                  } else if (e.type == '效果数据'){
                    checkedData.push(e)
                    checkedDataId.push(e.id)
                  }else if(e.type == 'APP转化数据(转化时间)'){
                    checkedConversion.push(e)
                    checkedConversionId.push(e.id)
                  }else if(e.type == 'APP转化数据(计费时间)'){
                    checkedWeb.push(e)
                    checkedWebId.push(e.id)
                  }else if(e.type == '转化数据(转化时间)'){
                    checkedAllTransform.push(e)
                    checkedAllTransformId.push(e.id)
                  }else if(e.type == '转化数据(计费时间)'){
                    checkedAllTransformJ.push(e)
                    checkedAllTransformIdJ.push(e.id)
                  }else if(e.type == '网页转化数据(转化时间)'){
                    checkedAllTransformW.push(e)
                    checkedAllTransformIdW.push(e.id)
                  }
                  
                  
                  
                  checkedCities.push(e)
                })
              that.checkedCities = checkedCities
              that.pubVue.titleList = that.titleListAll[that.typeName].concat(titleList)
              that.checkedInfo = checkedInfo
              that.checkedData = checkedData
              that.checkedConversion = checkedConversion
              that.checkedSystem = checkedSystem
              that.checkedWeb = checkedWeb
              that.checkedInfoId = checkedInfoId
              that.checkedDataId = checkedDataId
              that.checkedConversionId = checkedConversionId
              that.checkedSystemId = checkedSystemId
              that.checkedWebId = checkedWebId
              that.checkedAllTransformId = checkedAllTransformId
              that.checkedAllTransformIdJ = checkedAllTransformIdJ
              that.checkedAllTransformIdW = checkedAllTransformIdW
              that.checkedAllTransform = checkedAllTransform
              that.checkedAllTransformJ = checkedAllTransformJ
              that.checkedAllTransformW = checkedAllTransformW

              if(that.checkedInfo.length>0){
                that.isIndeterminateInfo = true
                if(that.checkedInfo.length == that.info.length){
                  that.checkAllInfo = true
                  that.isIndeterminateInfo = true
                }
              }else{
                that.isIndeterminateInfo = false
                that.checkAllInfo = false
              }
              if(that.checkedData.length>0){
                that.isIndeterminateData = true
                if(that.checkedData.length == that.data.length){
                  that.checkAllData = true
                  that.isIndeterminateData = true
                }
              }else{
                that.checkAllData = false
                that.isIndeterminateData = false
              }
              if(that.checkedConversion.length>0){
                that.isIndeterminateConversion = true
                if(that.checkedConversion.length == that.conversion.length){
                  that.checkAllConversion = true
                  that.isIndeterminateConversion = true
                }
              }else{
                that.checkAllConversion = false
                that.isIndeterminateConversion = false
              }
              if(that.checkedSystem.length>0){
                that.isIndeterminateSystem = true
                if(that.checkedSystem.length == that.system.length){
                  that.checkAllSystem = true
                  that.isIndeterminateSystem = true
                }
              }else{
                that.checkAllSystem = false
                that.isIndeterminateSystem = false
              }
              if(that.checkedWeb.length>0){
                that.isIndeterminateWeb = true
                if(that.checkedWeb.length == that.web.length){
                  that.checkAllWeb = true
                  that.isIndeterminateWeb = true
                }
              }else{
                that.checkAllWeb = false
                that.isIndeterminateWeb = false
              }
              if(that.checkedAllTransform.length>0){
                that.isTransformWebT = true
                if(that.checkedAllTransform.length == that.webT.length){
                  that.checkAllTransform = true
                  that.isTransformWebT = true
                }
              }else{
                that.checkAllTransform = false
                that.isTransformWebT = false
              }

              if(that.checkedAllTransformJ.length>0){
                that.isTransformWebJ = true
                if(that.checkedAllTransformJ.length == that.webJ.length){
                  that.checkAllTransformJ = true
                  that.isTransformWebJ = true
                }
              }else{
                that.checkAllTransformJ = false
                that.isTransformWebJ = false
              }

              if(that.checkedAllTransformW.length>0){
                that.isTransformWebW = true
                if(that.checkedAllTransformW.length == that.webW.length){
                  that.checkAllTransformW = true
                  that.isTransformWebW = true
                }
              }else{
                that.checkAllTransformW = false
                that.isTransformWebW = false
              }

              }

            
          }
        })
      },
      //清空自定义列弹框选中项
      clearWrap(){
        this.checkedCities = []
        this.checkedInfo = []
        this.checkedSystem = []
        this.checkedData = []
        this.checkedConversion = []
        this.checkedWeb = []
        this.checkedAllTransform = []
        this.checkedAllTransformJ = []
        this.checkedAllTransformW = []
        this.isIndeterminateInfo=false
        this.checkAllInfo=false
        this.isIndeterminateSystem = false
        this.checkAllSystem = false
        this.isIndeterminateData=false
        this.checkAllData=false
        this.isIndeterminateConversion=false
        this.checkAllConversion=false
        this.isIndeterminateWeb=false
        this.isTransformWebT = false
        this.isTransformWebJ = false
        this.isTransformWebW = false
        this.checkAllWeb=false
        this.checkAllTransform = false
        this.checkAllTransformJ = false
        this.checkAllTransformW = false
      },
  
      //自定义弹框右侧选中的项-----删除
      deletClumnChecked(i,val){
        console.log(i)
        console.log(val)
        let _this = this
        if(val.type=='基础数据'){
          _this.checkedInfo.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedInfo.splice(j,1)
              _this.checkedInfoId.splice(j,1)
              if(_this.checkedInfoId.length == 0){
                _this.isIndeterminateInfo=false
                _this.checkAllInfo=false                
              }
            }
          })
        }else if(val.type=='广告组基础数据'){
          _this.checkedSystem.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedSystem.splice(j,1)
              _this.checkedSystemId.splice(j,1)
              if(_this.checkedSystemId.length == 0){
                _this.isIndeterminateSystem=false
                _this.checkAllSystem=false                
              }
            }
          })
        }else if(val.type=='效果数据'){
          _this.checkedData.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedData.splice(j,1)
              _this.checkedDataId.splice(j,1)
              if(_this.checkedDataId.length == 0){
                _this.isIndeterminateData=false
                _this.checkAllData=false
              }
            }
          })        
          }else if(val.type=='APP转化数据(转化时间)'){
            _this.checkedConversion.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedConversion.splice(j,1)
              _this.checkedConversionId.splice(j,1)
              if(_this.checkedConversionId.length == 0){
                this.isIndeterminateConversion=false
                this.checkAllConversion=false
              }
            }
          })
        }else if(val.type=='APP转化数据(计费时间)'){
            _this.checkedWeb.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedWeb.splice(j,1)
              _this.checkedWebId.splice(j,1)
              if(_this.checkedWebId.length == 0){
                this.isIndeterminateWeb=false
                this.checkAllWeb=false
              }
            }
          })
        }else if(val.type=='转化数据(转化时间)'){
            _this.checkedAllTransform.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedAllTransform.splice(j,1)
              _this.checkedAllTransformId.splice(j,1)
              if(_this.checkedAllTransformId.length == 0){
                this.isTransformWebT=false
                this.checkAllTransform=false
              }
            }
          })
        }else if(val.type=='转化数据(计费时间)'){
            _this.checkedAllTransformJ.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedAllTransformJ.splice(j,1)
              _this.checkedAllTransformIdJ.splice(j,1)
              if(_this.checkedAllTransformIdJ.length == 0){
                this.isTransformWebJ=false
                this.checkAllTransformJ=false
              }
            }
          })
        }else if(val.type=='网页转化数据(转化时间)'){
            _this.checkedAllTransformW.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedAllTransformW.splice(j,1)
              _this.checkedAllTransformIdW.splice(j,1)
              if(_this.checkedAllTransformIdW.length == 0){
                this.isTransformWebW=false
                this.checkAllTransformW=false
              }
            }
          })
        }
        
        
        
        _this.checkedCities.splice(i,1);
      },
    
      //生成模版
      generateTemplate(){
        let that = this,params = {},titleList = []
        let allwordsArr = []
        if(that.checkedCities.length == 0){
          if(that.typeName == 1){
              that.pubVue.titleList=[
                {name:'广告计划ID',prop:'id'},
                {name:'计划类型',prop:'typeFormat'},//0-应用商店 1-非应用商店 2-广告联盟
                {name:'投放开关',prop:'statusTypeIs'},// 0-未暂停 1-已暂停
                // {name:'设置计划',prop:'openClosePlan'},//是否设置启停:1-是 0-否
                {name:'调价计划',prop:'modifyPricePlanIds'},//是否调价启停:1-是 0-否
                {name:'启停计划',prop:'openStopPlanIds'},//是否启停启停:1-是 0-否
                {name:'预警状态',prop:'warningSwitch'},//0-正常 1-禁用 2-删除
              ]
            }else if(that.typeName == 2){
              that.pubVue.titleList=[
                {name:'投放开关',prop:'statusTypeIs'},// 
                {name:'组类型',prop:'displayTypeFormat'},// 
                {name:'投放状态',prop:'deliveryStatusDisplayName'},// 
                {name:'启停计划',prop:'openStopPlanIds'},// 

              ]           
            }else if(that.typeName == 3){
              that.pubVue.titleList=[
                {name:'投放开关',prop:'statusTypeIs'},// 开->1(有效);关->2(暂停)
                {name:'创意类型',prop:'placementTypeFormat'},// 广告创意类型名称："精品列表广告"
                {name:'投放状态',prop:'deliveryStatusDisplayName'},// 投放状态名称："未投放"
                {name:'调价计划',prop:'modifyPricePlanIds'},//1->是；其他->否
                {name:'预警状态',prop:'warningSwitch'},//是否设置启停:1-是 0-否(还没有加上)
                {name:'审核状态',prop:'assetCheckStatusName'},// 创意审核结果：101(同搜索条件中的创意状态)
                {name:'广告出价',prop:'bidName'}
              ]          
            }
            }else{
              that.checkedCities.forEach(function(e,i,arr){
                console.log(e.code)
                //去重
                let bool = arr.indexOf(e,i+1)
                if(bool === -1){
                titleList.push({'name':e.name,'prop':e.fieldName})
                allwordsArr.push(e.code)
                }
              }) 
              that.pubVue.titleList = that.titleListAll[that.typeName].concat(titleList)
            }
          params.keyWordIds = allwordsArr.join(',')
          params.userId = that.userId
          params.reportType = that.typeName
          $http.axios.post("/api/mi/keyWord/updateCols",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {          
            if(res.data.code == 200){
            that.columnVisible = false
            if(that.typeName == 1){
              that.pubVue.getAccountPlanListFn()
            }else if(that.typeName == 2){
              that.pubVue.grouplistFn()
            }else if(that.typeName == 3){
              that.pubVue.advertListFn()
            }
          }  else {
            that.alertMsgFn(res.data.msg,'error')
          }
        })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .daily_library{
      margin-bottom:20px;
    }
    .daily_library span{
      padding-right:20px;
      font-size:14px;
      cursor: pointer;
    }
    .daily_library .timesTab{
      font-size:16px;
      font-weight:700;
      color:#6e7c7c;
    }
    .pageWrap{
      clear:both;
      margin-top:15px;
    }
    .pageBatch{
      float:left;
    }
    .paging{
      float:right;
    }
    .columnWrap{
      font-size:0;
    }
    .column_left,.column_right{
      display:inline-block;
      vertical-align:top;
      font-size:14px;
      box-sizing:border-box;
    }
    /* .column_left{
      width:100%;
      padding-right:20px;
    }
    .column_right{
      width:25%;
      padding-left:20px;
      border-left:1px solid #ddd;
    } */
    .column_right ul,.column_right li{
      margin:0;
      padding:0;
      list-style:none;
    }
    .column_right ul li i{
      font-weight:700;
      cursor:pointer;
    }
    .column_right .el-icon-download{
      transform: rotate(180deg);
      padding-right:10px;
    }
    .column_right .el-icon-remove-outline{
      color:brown;
      padding-left:5px;
    }
    .column_clear_showNum i{
      font-style:normal;
      color:#6e7c7c;
      padding:0 3px;
    }
    .column_right_clear{
      border:1px solid #ddd;
      padding:0 5px;
      border-radius:5px;
      margin-bottom:5px;
    }
    .column_clear{
      color:rgba(36, 132, 168, 1);
      float:right;
      cursor: pointer;
    }
    .newWordsBorn{
      margin-bottom:20px;
    }
    .newWordsBorn .el-input{
      width:300px;
      margin-left:10px;
    }
    .handAdd{
      border-top:1px solid #ddd;
      padding-bottom:20px;
      padding-top:20px;
      margin-top:20px;
    }
    .handAddwords{
      margin-top:20px;
      border-top:1px solid #ddd;
      /* padding:20px 0; */
    }
    .handAddwords h3{
      font-size:12px;
    }
    .timeCondition{
      display:inline-block;
      margin:0 3px;
    }
    .newWordsDel{
      float:right;
    }
    .tempName{
      margin-bottom:20px;
      padding-bottom:20px;
      border-bottom:1px solid #ddd;
    }
    .tempName label{
      display:inline-block;
      vertical-align:middle;
      margin-right:10px;
    }
    .tempnameWrite{
      width:200px;
    }
    .byted-weektime .calendar .calendar-table tr,.byted-weektime .calendar .calendar-table td,.byted-weektime .calendar .calendar-table th{
      min-width:3px;
    }
    .zdyboxTitle{
      font-weight:700;
      display:inline-block;
      margin:15px 0;
      margin-right:20px;
      color:#66856d;
    }
    .checkboxStyle{
      width:20%;
      box-sizing: border-box;
      margin-bottom:10px;
    }
    /*新版*/
    .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    .zdylStyle{
      margin-bottom:10px;
      position: relative;
    }
    .zdylAll{
      position: absolute;
      top:0;
      right:20px;
    }
    .zdylStyle span{
      display: block;
      font-weight: 500;
      padding:10px 20px;
      box-sizing: border-box;
      background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
      border-radius: 10px 0px 0px 0px;
    }
    .zdylChecked{
      margin-top:10px;
      box-sizing: border-box;
      padding-left:10px;
    }
    .zdylChecked .el-checkbox{
      width:25%;
      margin:3px 0;
    }
    .zdylMargin{
      padding-top:1500px;
      box-sizing: border-box;
    }
  </style>
  