<template>
  <div class="hl_breadCrumb plan-tabs" style="margin-bottom:20px;margin-top:10px;">
    <breakTitle :titleData="breakTitle"></breakTitle>
    <template>
        <el-tabs v-model="activeName"
       :tab-click= "(tab, event)=> handleClick(tab, event,666)" >
          <el-tab-pane 
            v-for="(item,index) in tabsList" 
            :key="index"
            :label="item.label" 
            :name="item.name"
            :ref="item.name">
            <component :is='item.content' :newVue = "newVue"></component>
          </el-tab-pane>
        </el-tabs>
    </template>
  </div>
</template>
<script>
import Api from "@/api";
import config from '@/api/config';
import breakTitle from "./breakHeader";//面包屑导航
import adPlanVivo from "./adPlanVivo";//广告计划
import adTeamVivo from "./adTeamVivo";//广告组
import advertVivo from "./advertVivo"//广告
export default {
  name: "planTabs",
  data() {
    return {
      newVue:this,
      activeName: 'adPlanVivo',//记得修改成adPlan
      breakTitle:['计划管理','账户选择','广告计划'],
      tabIndex:null,
      tabsList:[{label:"广告计划",name:"adPlanVivo",objIdType:10,content:adPlanVivo},
        {label:"广告组",name:"adTeamVivo",objIdType:20,content:adTeamVivo},
        {label:"广告",name:"advertVivo",objIdType:30,content:advertVivo}],//tabs动态加载的组件内容


    }
  },
  components:{
    breakTitle,//面包屑导航
    adPlanVivo,//广告计划
    adTeamVivo,//广告组
    advertVivo,//广告
  },
  mounted(){
    let _this = this
    this.tabIndex = Number(this.$route.query.tabIndex)
    console.log(this.tabIndex)
    if(this.tabIndex == 1){
      this.activeName = 'adTeamVivo'
      this.$refs.adTeamVivo[0].$children[0].grouplistFn()
      this.$refs.adTeamVivo[0].$children[0].$refs.zdyVivo.allWords(2)
    }else if(this.tabIndex == 2){
      this.activeName = 'advertVivo'
      this.$refs.advertVivo[0].$children[0].v3groupListFn()
      this.$refs.advertVivo[0].$children[0].$refs.zdyVivo.allWords(3)
    }else{
      this.$refs.adPlanVivo[0].$children[0].getAccountPlanListFn()
      this.$refs.adPlanVivo[0].$children[0].$refs.zdyVivo.allWords(1)
    }
    // let name = sessionStorage.getItem('currentTab') 
    //  // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
    //  if(name){
    //    this.activeName = name

    //    if(this.tabIndex == 1){
    //   this.activeName = 'adTeamVivo'
    //   this.$refs.adTeamVivo[0].$children[0].grouplistFn()
    //   this.$refs.adTeamVivo[0].$children[0].$refs.zdyVivo.allWords(2)
    // }else{
    //   this.$refs.adPlanVivo[0].$children[0].getAccountPlanListFn()
    //   this.$refs.adPlanVivo[0].$children[0].$refs.zdyVivo.allWords(1)
    // }
    //   }


    console.log(this)
  },
  methods: {
    handleClick(tab, event,num) {
      // sessionStorage.setItem('currentTab', tab.name)
      console.log(tab.index)
      console.log(num)
      console.log(event)
      if(tab.index == '0'){
        this.$refs.adPlanVivo[0].$children[0].getAccountPlanListFn()
        this.$refs.adPlanVivo[0].$children[0].$refs.zdyVivo.allWords(1)
        console.log(this)
      }else if(tab.index == '1'){
        if(typeof event == 'number'){
          this.$refs.adTeamVivo[0].$children[0].planIds = event
        }else{
          event = ''
        }
        this.activeName = 'adTeamVivo'
        this.$refs.adTeamVivo[0].$children[0].grouplistFn(1,event)
        this.$refs.adTeamVivo[0].$children[0].$refs.zdyVivo.allWords(2)
      }else{
        if(typeof event == 'number'){
          this.$refs.advertVivo[0].$children[0].adGroupIds = event
        }else{
          event = ''
        }
        this.activeName = 'advertVivo'
        this.$refs.advertVivo[0].$children[0].v3groupListFn(1,'',event)
        this.$refs.advertVivo[0].$children[0].$refs.zdyVivo.allWords(3)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
    .el-tabs__item.is-active {color: #6e7c7c;
      font-size: 16px;
      font-weight: 700;}
      .el-tabs__header{
        margin:15px 0 0;
      }
</style>