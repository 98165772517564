<template>
  <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">计划管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">账户选择</el-breadcrumb-item>
          <el-breadcrumb-item>广告计划</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
          <div class="chanelTab">
              <span class="channel_vivo" @click="clickChangeChannel('adPlanHw')">任务</span>
              <span class="channel_vivo" @click="clickChangeChannel('adTeamHw')">子任务</span>
              <span class="channel_vivo channelActive">创意</span>
          </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <!-- 创意名称/创意ID -->
                <el-form-item label="">
                  <el-input v-model = "advertId" clearable placeholder="请输入广告名称/ID查找"></el-input>
                </el-form-item>
               
                <!-- 所有出价类型 -->
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择出价类型" v-model="priceType">
                    <el-option
                      v-for="(item,i) in searchContentListArr.priceType"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- 创意类型 -->
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择创意类型" v-model="advertType">
                    <el-option
                      v-for="(item,i) in searchContentListArr.advertType"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                 <!-- 全部程序化状态 API没有-->
                <!-- <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择程序化状态"  v-model="programManagStatus">
                    <el-option
                      v-for="(item,i) in searchContentListArr.programManagStatus"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <!-- 创意状态 -->
                <!-- <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择创意状态" v-model="advertStatus">
                    <el-option
                      v-for="(item,i) in searchContentListArr.advertStatus"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="" prop="">
                  <el-cascader clearable placeholder="请选择创意状态" v-model="advertStatus"
                    :options="searchContentListArr.advertStatus"
                    collapse-tags></el-cascader>
                </el-form-item>
                <!-- 渠道包 -->
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择渠道包" v-model="channel">
                    <el-option
                      v-for="(item,i) in searchContentListArr.channel"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <div class="block">
                    <el-date-picker
                    v-model="value1"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  </div>
                </el-form-item>
              </el-form> 
            </div>
            <div class="MBOTTOM">
              <el-button type="primary" size="small" @click = "advertListFn(page)">查询</el-button>
              <el-button type="info" size="small" plain @click = 'resetForm()'>重置</el-button>
            </div>
          </div>
          <div class="clearfix">
            <div class="fL MBOTTOM">
              <el-dropdown trigger="click" @command = "changeStatusList">
                <el-button type="primary" size="small" @click="updateOppoSet('modifyState')">
                  修改状态
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='item.id'>{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              
              <el-dropdown trigger="click" @command = "upAdWarningSwitchFn">
                <el-button type="primary" size="small" @click="warmIs('warningOpen',url[0])">
                  预警开关
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> 
              <el-button type="primary" size="small" @click="warmIs('dayLimitPrice',url[1],3)">批量调价</el-button>
              <el-button type="primary" size="small" @click="goPlan('/miAccount-management/priceModifyAdvertMi')">调价计划列表页面</el-button>
            </div>
            <div class="fR MBOTTOM">
              <el-button type="success" size="small" @click = "zdyFn">自定义列</el-button>
              <el-button type="warning" size="small" @click = "exportTeam">下载报表</el-button>
            </div>
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @sort-change = "changeSort"
                @cell-click="clickcell"
                :cell-style="setRowStyle"
                show-summary
                :summary-method="getSummaries"
                @selection-change="handleSelectionChange">

                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="创意名称"
                  width="200">
                  <template slot-scope="scope">
                    <span class="adTeamName">{{scope.row.name}}</span>
                    <i 
                      class="el-icon-edit adTeamEditBtn"
                      @click="editAdTeamName(scope)">
                    </i>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="id"
                  label="广告ID"
                  width="120">
                </el-table-column> -->
                <el-table-column
                  prop="campaignName"
                  label="所属广告计划名称"
                  width="180">
                </el-table-column>
                  <el-table-column
                  prop="groupName"
                  label="所属广告组名称"
                  width="180">
                </el-table-column>
                <template>
                    <el-table-column
                    v-for="(item, index) in titleList"
                    :key='index'
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :show-overflow-tooltip="item.overHidden || true"
                    :sortable = "item.prop == 'viewSumFormat'||item.prop == 'clickSumFormat'||item.prop == 'downloadSumFormat'||item.prop == 'accurateCostFormat'||item.prop == 'ctrFormat'||item.prop == 'cpcFormat'||item.prop == 'downloadRatioFormat'||item.prop == 'costPerDownloadFormat'||item.prop == 'ecpmFormat'?true:false"
                    width='120'>
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else :disabled = "scope.row.disabledIs">
                          <!-- <span v-if='scope.column.property == "inDelivery"'>
                            {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                            <i v-if = 'scope.column.property =="inDelivery"'
                              :class = "{'isSwitch':scope.column.property =='inDelivery','active':scope.column.property =='inDelivery'&&scope.row[scope.column.property]==true}"></i>
                          </span> -->
                          <span v-if='scope.column.property == "modifyPricePlanIds"'>
                            {{ showStatusList[scope.column.property][Number(Boolean(scope.row[scope.column.property]))] }}
                            <i v-if = 'scope.column.property =="modifyPricePlanIds" '
                            :class = "{'isSwitch':scope.column.property =='modifyPricePlanIds','active':(scope.column.property =='modifyPricePlanIds')&&scope.row[scope.column.property]!=0}"></i>
                          </span>

                          
                          <span v-else-if='scope.column.property == "deliveryStatusDisplayName"'>
                           
                            <span class="statusOtherBtnwei" v-if="!scope.row.inDelivery">
                              {{scope.row.deliveryStatusDisplayName}}
                              <el-popover
                                placement="top-start"
                                title=""
                                width="200"
                                trigger="hover">
                                  {{scope.row.unDeliveryReasonList.toString()}}
                                  <i slot="reference" class="el-icon-warning warningIcon"></i>
                              </el-popover>
                            </span>
                            <span class="statusBtn" v-else>{{scope.row.deliveryStatusDisplayName}}</span>
                          </span>
                         
                          <span v-else-if='scope.column.property == "assetCheckStatusName"'>
                            <span class="statusOtherBtnwei" v-if="scope.row.assetCheckStatus==104">
                              {{scope.row.assetCheckStatusName}}
                              <el-popover
                                placement="top-start"
                                title=""
                                width="200"
                                trigger="hover">
                                  {{scope.row.assets[0].checkRemarkFormat}}
                                  <i slot="reference" class="el-icon-warning warningIcon"></i>
                              </el-popover>
                            </span>
                            <span class="statusBtn" v-else>{{scope.row.assetCheckStatusName}}</span>
                          </span>
                          <span v-else>
                             <template v-if='item.prop=="statusTypeIs"'>
                              <el-switch
                                v-model="scope.row.statusTypeIs"
                                class="switchStyle"
                                :active-value="1"  
                                :inactive-value="2"
                                active-color="#13ce66"
                                inactive-color="#ccc"
                                @change = "changeStatusFn(scope.row.statusTypeIs,scope)"
                                >
                              </el-switch>
                            </template>
                            <span v-if="item.prop=='placementTypeFormat'||item.prop=='warningSwitch'||item.prop=='bidName'||item.prop == 'deepConvertTarget'">
                              <span v-html = "scope.row[scope.column.property]"></span>
                            <!-- {{ scope.row[scope.column.property] }} -->
                            </span>
                          </span>
                        </div> 
                       
                      </template>
                    </el-table-column>
                  </template>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='320'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <!-- 编辑回显 -->

                    <el-button class="newBtn_Yellow" data-type = 'warningPriceSingle' plain size="mini">预警</el-button>
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deletePlanFn(scope.row.id)">
                      <template #reference>
                        <el-button class="newBtn_orang" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
            <!-- 修改广告名称 -->
            <el-dialog title="修改名称" :visible.sync="editNameVisible" width="40%">
              <el-form :model="editNameForm">
                <el-form-item label="创意名称" label-width="80px">
                  <el-input v-model="editName"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editNameVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditName">确 定</el-button>
              </div>
            </el-dialog>

            <!-- 下载报表 -->
            <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="280px">
              <span class="el-icon-loading uploading uploadIcon" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <earlyWarningSetMiDialog ref = 'earlyWarningDialog' :visible.sync="showEarlyWarningSetDialog" :userContent = 'userContent' :pubVue = 'pubVue'></earlyWarningSetMiDialog>
    <zdyMi :visible.sync="showzdyDialog" ref = 'zdyMi' :pubVue="pubVue" :typeName = "typeName"></zdyMi>
    <startLimitSingleMiDialog ref = 'dayLimitDom' :visible.sync="showSingleDialog" :nowIs = "nowIs" :yulanList = "yulanList" :limitIs = "limitIs" :page = 'page' :userContent = 'userContent' :dayType = "dayType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleMiDialog>
    <tiaojiaDialog ref = 'tiaojiaDialog' :visible.sync="tiaojiaDialogIs" :pubVue="pubVue"></tiaojiaDialog>
    <setAdertWarningMiDialog ref = 'setAdvert' :visible.sync="setAdertWarningDialogShow" :yulanList = "yulanList" :adIds = 'adIds' :userContent = 'userContent' :page = "page" :pubVue="pubVue"></setAdertWarningMiDialog>
    <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :groupName = "groupName" :pubVue="pubVue" :command = "command+''" :typeName = "'team'"></alertStartStopDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import earlyWarningSetMiDialog from '@/views/dialog/earlyWarningSetMiDialog';
import startLimitSingleMiDialog from '@/views/dialog/startLimitSingleMiDialog'
import setAdertWarningMiDialog from '@/views/dialog/setAdertWarningMiDialog'
import zdyMi from './zdyMi'
import tiaojiaDialog from '@/views/dialog/tiaojiaMiDialog'
import alertStartStopDialog from '@/views/dialog/alertStartStopDialog'
import TimeUnit from '../TimeUnit.vue';
import Qs from 'qs'
import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')
export default {
  components: {
    earlyWarningSetMiDialog,
    zdyMi,//自定义页面
    startLimitSingleMiDialog,//调价页面->批量调价&&批量日限额
    setAdertWarningMiDialog,
    tiaojiaDialog,
    alertStartStopDialog,
    timeUnit:TimeUnit
  },
  props:{
    newVue:Object,
  },
  data() {
    return {
      pubVue: this,//空vue，用于子组件之间传值
      // showDialogIs: false,
      tiaojiaDialogIs:false,
      showzdyDialog:false,//自定义页面是否展示
      showSingleDialog:false,//调价页面是否展示
      nowIs : false,
      alertStartStopDialogIs:false,
      showEarlyWarningSetDialog:false,
      modifyState:false,//修改状态下拉列表是否展示
      setAdertWarningDialogShow:false,//出价预警弹框
      // startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      searchContentListArr:{priceType:[{text:'所有出价类型',id:'0'},{text:'CPM',id:'1'},{text:'CPC',id:'2'},{text:'CPD',id:'3'},{text:'oCPC',id:'101'},{text:'oCPD',id:'102'}],//出价类型
                advertStatus:[{label:'全部创意状态',value:'010'},{label:'投放中',value:'201'},
                              {label:'未投放',value:'202',
                              children:[{label:'推广计划已暂停',value:'42'},{label:'广告组已暂停',value:'51'},{label:'广告创意已暂停',value:'61'},{label:'账户余额不足',value:'31'},
                                        {label:'账户达限',value:'32'},{label:'推广计划预算达限',value:'41'},{label:'不在推广周期内',value:'43'},{label:'不在推广时段',value:'55'},
                                        {label:'APP下线',value:'53'},{label:'APP被加入黑名单',value:'54'},{label:'人群包过期',value:'52'},{label:'人群包状态不合法',value:'56'},
                                        {label:'代理商账户被冻结',value:'11'},{label:'代理商账户被驳回',value:'12'},{label:'代理商账户审核中',value:'13'},{label:'账户被冻结',value:'21'},
                                        {label:'账户被驳回',value:'22'},{label:'账户审核中',value:'23'},{label:'审核未通过',value:'62'},{label:'审核中',value:'63'},
                                        {label:'程序化广告组托管中，程序化广告组内的广告创意需全部审核完毕后开始投放',value:'65'},
                                        {label:'程序化广告组托管中，程序化广告组内的广告创意需全部审核未通过',value:'66'},
                                        {label:'RTA异常',value:'67'},{label:'商品已下架',value:'57'},{label:'APP预约状态失效',value:'58'}]},
                              {label:'审核通过',value:'101'},{label:'审核未通过',value:'104'},{label:'部分审核通过',value:'200'},{label:'审核中',value:'102'},{label:'已删除',value:'0'}],//选择状态
                advertType:[],//创意类型
                programManagStatus:[{text:'托管中',id:'1'},{text:'托管完成(效果不好)',id:'104'},{text:'托管完成(效果最好)',id:'102'},{text:'托管完成(效果较好)',id:'103'},
                {text:'托管完成(无法探索)',id:'105'},{text:'托管终止(人为终止)',id:'100'},{text:'托管终止(审核未通过)',id:'101'},{text:'未启用托管',id:'0'}],//程序化状态
                channel:[],
                deepConvertTargetList:[{text:'自定义激活',id:'1'},{text:'自定义注册',id:'2'},{text:'自定义留存',id:'3'},{text:'自定义新增激活',id:'5'},
                {text:'付费',id:'6'},{text:'商详页浏览',id:'10000'},{text:'加购',id:'10001'},{text:'下单',id:'10002'},
                {text:'购买',id:'10003'},{text:'APP召回',id:'10009'},{text:'拉活"',id:'10013'},{text:'首单购买',id:'10016'},
                {text:'IPVUV',id:'10017'},{text:'关键行为',id:'10018'},{text:'完件',id:'10019'},{text:'授信',id:'10020'},
                {text:'用户访问深度',id:'10021'},{text:'加桌',id:'20002'},{text:'快应用打开"',id:'20003'},{text:'通信激活',id:'20004'},
                {text:'当月出账',id:'20005'},{text:'次月出账',id:'20006'},{text:'订单提交',id:'20009'},{text:'订单确认',id:'20010'},
                ]},//渠道包
      priceType:'',//出价类型
      advertStatus:[],//创意状态
      advertType:'',//创意类型
      channel:'',//渠道包
      titleList:[
        {name:'投放开关',prop:'statusTypeIs'},// 开->1(有效);关->2(暂停)
        {name:'创意类型',prop:'placementTypeFormat'},// 广告创意类型名称："精品列表广告"
        {name:'投放状态',prop:'deliveryStatusDisplayName'},// 投放状态名称："未投放"
        {name:'调价计划',prop:'modifyPricePlanIds'},//1->是；其他->否
        {name:'预警状态',prop:'warningSwitch'},//是否设置启停:1-是 0-否(还没有加上)
        {name:'审核状态',prop:'assetCheckStatusName'},// 创意审核结果：101(同搜索条件中的创意状态)
        // {name:'深度转化目标',prop:'deepConvertTarget'},// 三阶段转化目标：Number
        {name:'广告出价',prop:'bidName'}
      ],
      orderByList:{'viewSumFormat':'viewSum','clickSumFormat':'clickSum','downloadSumFormat':'downloadSum','accurateCostFormat':'cost','ctrFormat':'ctr','cpcFormat':'cpc','downloadRatioFormat':'downloadRatio','costPerDownloadFormat':'costPerDownload','ecpmFormat':'ecpm'},//排序字段
      showStatusList:{
                      statusTypeIs:[''],//0开启1关闭
                      modifyPricePlanIds:['否','是'],//调价计划               

                      deliveryStatusDisplayName:['']},               
      planName:'',//请输入计划名称查找
      searchPlanId:'',//请输入广告计划id
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      value1:[formatDate(new Date()),formatDate(new Date())],//时间
      tableData:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dataList:'',
      adIds:[],//创意ID集合
      yulanList:[],
      typeName:3,
      planType:30,
      dayType:null,
      dialogTitle:'批量调价',
      dayLimitIs:false,//校验日限额状态是否一致
      limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
      userContent:{},//登录用户信息&弹框默认
      url:['accountWarning/checkAccountWarning',//校验广告主是否设置过预警信息
          'modifyPrice/valid'],//预警是否开启、是否设置过定时调价计划
      myHeaders: {Authorization:''},
      command:'',//??
      groupName:'',
      advertId:'',//创意ID
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      btnShow:false,//鼠标划到表格上出现修改按钮
      editNameVisible:false,//修改广告名称
      editNameForm:{},
      editName:'',//广告名称修改字段
      editType:'',
      editId:'',
      editDateForm:{},
      activeName:'first',
      firstDate:'',//长期投放的日期
      secondDate:[],//修改开始和结束日期
      getGroupIds:'',//获取批量修改日期和时间段的ids集合
      activeTime:'first',//进弹框显示第一个
      timeUnitShow:true,//设置执行时间
      testAdvertType:{},//声明一个创意类型的对象后期有接口之后删除即可
      sortMode:'',//列表升序降序
      closeAdList:[],//校验是否有存在的启停计划
      sums:{},//合计

    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.userContent.ownerId = this.$route.query.id
    this.userContent.ownerName = this.$route.query.name
    this.value1 = [formatDate(new Date()),formatDate(new Date())]
    this.getId()
    this.queryPtListFn()//获取创意类型列表
    
    this.advertListFn(1)
    this.$refs.zdyMi.allWords(3)

    console.log('advert')
  },
  methods: {
    //列表排序
    changeSort(val){
      console.log(val)
      if(val.order == 'descending'){
        this.sortMode = 0
      }else{
        this.sortMode = 1
      }
      this.orderBy = this.orderByList[val.prop]//获取排序需要的字段
      this.advertListFn(this.page)
    },

    //获取创意类型列表
    queryPtListFn(){
      let _this = this
      $http.axios.get("/api/hw/adCreative/queryPtList?ownerId="+this.userContent.ownerId).then(function(res) {
        if(res.data.code==200){
          res.data.data.forEach(obj=>{
            _this.searchContentListArr.advertType.push({'text':obj.ptDesc,'id':obj.ptId})
          })
        }
      })
    },
    //tab切换
    clickChangeChannel(url){
      this.$router.push({
        path: '/HwAccount-management/'+url,
        query: {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName,
        }
      })
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
    zdyFn(){
      this.$refs.zdyMi.generateTimes(3)
    },
    exportTeam(){
      this.exprtVisible = true
    },
  
    //下载报表
    downLoadTable(){
      let _this = this,params ={},date = new Date().getTime(),myDate = formatDate(new Date()),pageTxt
      pageTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : _this.page,
          'kw':_this.advertId,
          'billingType':_this.priceType,
          'type':_this.advertType!=undefined?_this.advertType.toString(): '',
          'sortMode':_this.sortMode,//0-降序 1-升序 默认降序
          'orderBy':_this.orderBy,
          'unDeliveryType':_this.advertStatus&&_this.advertStatus.length>1?_this.advertStatus[1]:'',
          'status':_this.advertStatus?_this.advertStatus[0]:'',
          'channel':_this.channel,
          'startDate':  _this.value1[0],
          'endDate':  _this.value1[1],
          'adGroupId':_this.$route.query.type
        }
        
      params = Object.assign({},_this.clone(_this.userContent), pageTxt)    
      _this.uploadingShow = true
      $http.axios.post("/api/hw/adCreative/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        _this.exprtVisible = false 
        //添加{responseType: 'blob'}  导出文件以下代码都是
        let blob = response.data
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = e => {
          let a = document.createElement('a')
          a.download = _this.userContent.ownerName+"_广告创意_"+myDate+".xls"
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          _this.uploadingShow = false
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
     
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      row.ownerId
      let yjType = event.target.parentNode.dataset.type
      
      let className = event.target.className
      this.adIds = [row.id]//点击当前单元格添加创意ID
      if(yjType == 'warningPriceSingle'){
        // this.adIds = [row.id]
        this.yjCheck('warningPriceSingle',this.url[0],row)
      }else if(yjType == 'editBtn'){
        this.goPlan('/plan-management/AdgroupTeamEdit',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.id,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'qtBtn'){
        this.goPlan('/plan-management/SetAdgroupPrice',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.id,
          adGroupName:row.adGroupName
        })
      }
      if(column.property=="campaignName"){//点击所属计划名称跳转到相应的计划列表页
        this.goPlan('/miAccount-management/adPlanMi',{
          kw:row.campaignId,
        })
      }
      if(column.property=="groupName"){//点击所属广告组名称跳转到相应的广告组列表页
        this.goPlan('/miAccount-management/adTeamMi',{
          kw:row.groupId,
        })
      }
      if(column.property=="startSwitch"){
        let params = {"adGroupId": row.id}
        this.ssDetailParams = {}
        if(row.startSwitch == 0){
          this.ssDetailParams={currentPage:1,
                                    deptId:this.deptId,
                                    etime:"",
                                    pageSize:50,
                                    status:"",
                                    stime:"",
                                    openClosePlanId:"",
                                    userId:this.userId,
                                    userName:this.userName}
          this.getOpenCloseIdFn(params)
        }
      }
       if(column.property =="modifyPricePlanIds"){
          if(row.modifyPricePlanIds!=0){
            this.tiaojiaDialogIs = true
            this.$refs.tiaojiaDialog.getTimeSlotDetail(row.modifyPricePlanIds,this.userContent)
          }
      }
      
    },
    getOpenCloseIdFn(params){
      let _this = this, type = ''
      $http.axios.post("/api/advertisement/adPlan/v1/getOpenCloseId",params).then(res=> {
        if(res.data.code==200&res.data.content!=null){
          _this.startStopDialog = true
          _this.ssDetailParams.openClosePlanId=res.data.content
          _this.$refs.startStopDialogIs.timeSlotDetail(_this.ssDetailParams)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })      
    },
    // 启停页面跳转
    goPlan(url,query){//
      let _this = this,query1 =  {
      id: this.userContent.ownerId,
      name:this.userContent.ownerName}
      query = Object.assign({},_this.clone(query), query1)
      this.$router.push({
        path: url,
        query: query
      })
    }, 
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },

    // 分页
     handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
        _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(this.page)
     
    }, 
    //分页方法
    findAll (page) {
      //获取数据接口（调用封装的接口）
      this.page = page
      this.advertListFn(page,this.advertId,this.priceType,this.advertType,this.programManagStatus,this.advertStatus,this.channel,this.value1)
      //page,advertId,priceType,advertType,programManagStatus,advertStatus,channel,value1
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let adIds = [],yulanList = [],objList = [],startSwitch = [],numStr = 0,num = 0
      _this.limitIs = false
      val.forEach((obj,i,arr)=>{
        let cur = obj.pauseState
        adIds.push(obj.id)
        startSwitch.push(obj.startSwitch)
        yulanList.push({'ownerId':_this.userContent.ownerId,'planId':obj.campaignId,'planName':obj.campaignName,'groupId':obj.groupId,'groupName':obj.groupName,'adName':obj.name, 'bidType':obj.bidding.billingType,'id':obj.id,'price':obj.bidding.bid})
        if(obj.dailyBudget =='不限'){
            _this.limitIs = true
            numStr++
          }else{
            num++
          }
        objList[cur] = cur
      })
        _this.dayLimitIs = true
        if(val.length == num || val.length ==numStr){
            _this.dayLimitIs = true
          }else{
            _this.dayLimitIs = false
          }
      _this.adIds = adIds
      _this.startSwitch = startSwitch
      _this.yulanList = yulanList
      if(val.length == 0){
        _this.warningOpenList = []
        _this.modifyState = false

      }else{
        _this.modifyState = true
      }
    },
    //重置
    resetForm(){
      let _this = this
      _this.clearFn()
      _this.advertListFn()
      _this.value1 = [formatDate(new Date()),formatDate(new Date())]//时间
      
    },
    clearFn(){
      let _this = this
      _this.advertId = ''
      _this.priceType = ''//出价类型
      _this.advertType = ''//创意类型
      _this.advertStatus = ''//创意类型
      _this.channel = ''//创意类型

    },

    //创意列表
    advertListFn(page){
      let _this = this,params,pageTxt
      pageTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : page || 1,
          'kw':_this.advertId,
          'billingType':_this.priceType,
          'type':_this.advertType!=undefined?_this.advertType.toString(): '',
          'sortMode':_this.sortMode,//0-降序 1-升序 默认降序
          'orderBy':_this.orderBy,
          'unDeliveryType':_this.advertStatus&&_this.advertStatus.length>1?_this.advertStatus[1]:'',
          'status':_this.advertStatus?_this.advertStatus[0]:'',
          'channel':_this.channel,
          'startDate':  _this.value1[0],
          'endDate':  _this.value1[1],
          'adGroupId':_this.$route.query.type
        }
      params = Object.assign({},_this.clone(_this.userContent), pageTxt)      
      $http.axios.post("/api/hw/creative/list",params).then(res=> {
        if(res.data.code==200){
          if(res.data.data.list != null){
              _this.tableData = res.data.data.list
      
              _this.tableData.forEach((obj,i)=>{
                _this.searchContentListArr.advertStatus.forEach((ele,j)=>{
                  if(obj.assetCheckStatus==ele.value){
                    obj.assetCheckStatusName = ele.label
                  }
                })
                _this.searchContentListArr.deepConvertTargetList.forEach((ele,j)=>{
                  if(obj.bidding.deepConversionType==ele.id){
                    obj.deepConvertTarget = ele.text+'<br/>'+obj.bidding.deepConversionTarget
                    
                  }
                })
              obj.bidName = obj.bidding.bid+'<br/>('+obj.bidding.billingTypeName+')'
              if(obj.status!=2){
                  _this.$set(obj,'statusTypeIs',1)
                  // obj.statusTypeIs = '1'
                }else{
                  // obj.statusTypeIs = '2'
                  _this.$set(obj,'statusTypeIs',2)
                }
                if(obj.modifyPricePlanIds==''||obj.modifyPricePlanIds==null){
                  _this.$set(obj,'modifyPricePlanIds',0)
                }
                if(obj.warningSwitch == null){
                  obj.warningSwitch = '未设置'
                }else if(obj.warningSwitch == 0){
                  obj.warningSwitch = '开'
                }else if(obj.warningSwitch == 1){
                  obj.warningSwitch = '关'
                }
              })
            _this.totalRow = res.data.data.pageInfo.rowCount
            console.log(_this.tableData)
          }else{
            _this.tableData = []
            _this.totalRow = 0

          }
          _this.sums = res.data.data.total
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },

// "adCreativeIdList": [],
// 	"warningSwitch": 0,
// 	"userId": 0,
// 	"userName": "",
// 	"deptId": 0

    //预警开关方法
    upAdWarningSwitchFn(command){
      let _this = this,type,params

      params={'adCreativeIdList':_this.adIds,//修改成：adIds
        'warningSwitch' : command,//预警开关(0开 1关 )
        "deptId": _this.deptId,
        "userId": _this.userId,
        "userName": _this.userName}
      $http.axios.post("/api/hw/adCreativeWarning/batchUpdateAdCreativeWarningSwitch",params).then(res=> {
        _this.warningOpenList = []
        _this.modifyState = false
        _this.adIds = []
        _this.$refs.multipleTable.clearSelection();          
        if(res.data.code==200){
          _this.advertListFn(_this.page)
          type = "success"
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // "userId": 0,
    // "userName": "",
    // "deptId": 0,
    // "ownerId": 0,
    // "extendFlag": 0,
    // "validDate": "",
    // "adPlanIds": "",
    // "adGroupIds": "",
    // "adIds": "",
    // "status": 0//启停状态 1-开启 2-暂停
    //V3.0批量暂停/启动广告
    updateStatusFn(command,scope,status){//updateOppoAdGroupStatus
      console.log(command)
      let _this = this,type,params,adIdsString
      // adIds = adIds||_this.adIds
      adIdsString = _this.adIds.join(',')
      if(status == '继续'){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':this.$route.query.id,
          'adIds':adIdsString,
          'status':command,
          'validDate':formatDate(new Date(),'time')//当前时间
        }
      }else{
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':this.$route.query.id,
          'adIds':adIdsString,
          'status':command,
          'extendFlag':1,//不继续的时候传此参数
          'validDate':formatDate(new Date(),'time')//当前时间
        }      
      }

      $http.axios.post("/api/hw/adCreative/batch/openStop",params).then(res=> {
        _this.warningOpenList = []
        _this.modifyState = false
        _this.adIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
          type = "success"
        }else{
          type = "error"
        }
        _this.advertListFn(_this.page)
        let messageContent =res.data.msg || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: type
        })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // 批量启停设置
    updateOppoSet(type){
      console.log('startStop')
      let _this = this
      let adIds = []//声明一个空数组
        _this.adIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          adIds.push(v)
        }
      })
      
      if(adIds.length!=0){
        _this.userContent.adIds = _this.adIds
        _this.btnIsFn(_this.userContent,type)
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
    // 按钮判断
    btnIsFn(params,type){
      let _this = this
      _this.adIds = _this.userContent.adIds
      if(type == 'modifyState'&&_this.modifyState){//修改状态
        _this.checkOpenCloseFn(params.adIds)//需求更改暂时注释
        // _this.warningOpenList = ['开启','关闭']
      }

    },  
    //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs(type,url,dayType){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      let _this = this
      let isTrue = _this.updateOppoSet(type)
      _this.warningOpenList = []
      _this.modifyState = false
      _this.dayType = dayType
      console.log(this.yulanList)
      if(isTrue){
          if(type == 'dayLimit'|| type == "dayLimitPrice"){
            _this.yulanList.forEach(obj=>{
              if(type == 'dayLimit'){
                obj.price = obj.dayBudget
              }                
            })
            
            _this.dayLimitCheckFn(dayType)
          }else{
          _this.yjCheck(type,url)
          }
      }     
    },
    dayLimitCheckFn(dayType){
      let _this = this,idList = [],params = null
        _this.adIds.forEach(obj=>{
          idList.push(obj)
        }) 
        params = {}
        params = {"ids":idList.join(','),"type":dayType}   
        $http.axios.post("/api/hw/"+_this.url[1],Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.data
          if(content == null){
              this.showSingleDialog = true
              this.nowIs = false
            }else{
              let text = content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                this.showSingleDialog = true
                this.nowIs = true
              }).catch(()=>{
                
              })
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    yjCheck(type,url,row){
      let _this = this,messageContent,params = null,yulanList = []
      if(type == 'warningOpen'){
        params = []
        params = [_this.userContent.ownerId]
      }
      else if(type == 'warningPriceSingle'){//操作->预警（单个出价预警）
        yulanList.push({ 'adGroupName':row.name,'adGroupId':row.id,'planName':row.campaignName,'planId':row.campaignId,'price':row.price,'dayBudget':row.dailyBudget})
        params = []
        params.push(_this.userContent.ownerId)
        _this.yulanList = yulanList
      }else{
      _this.adIds.forEach(obj=>{
        params = []
        params.push(obj)
      })
    }
    $http.axios.post("/api/hw/"+url,params).then(res=> {
      let content = []
      if(res.data.code==200){
        content = res.data.data
        if(content == null){
          if(type != 'dayLimit'){
            messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
          
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })
          }
            
          }else{
            if(type == 'warningPriceSingle'){//操作->预警（单个出价预警）
              _this.warmTypeBtn = type
              _this.warmType = ''
              _this.setAdertWarningDialogShow = true
              _this.$refs.setAdvert.setDialogContentFn(row,4)//广告创意id&&预警字段->计划是4
            }else if(type == 'warningOpen'){
              _this.warningOpenList = [{name:'开启',id:1},{name:'关闭',id:2}]
            }
          }
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      }) 
    },
   
    //批量启停校验当日是否存在启停计划
    checkOpenCloseFn(Ids){
    let _this = this,params,adName
    Ids=Ids.join(',')
      params = {'ids': Ids,'type':3}//类型（1-广告计划 2-广告组 3-广告）
    $http.axios.post("/api/hw/openStop/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      if(res.data.code==200){
        if(res.data.data!= null){
          _this.validDate = formatDate(new Date())
          adName = res.data.data.split(',')
          adName.forEach(obj=>{
            _this.closeAdList.push(obj)
          })
        }else{
        _this.closeAdList = []
        }
        _this.warningOpenList = [{name:'开启',id:1},{name:'关闭',id:2}]
      }else{
        _this.warningOpenList = [{name:'开启',id:1},{name:'关闭',id:2}]
      }
    }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })      
    },
   
    //单个启停开关按钮启停计划校验
    changeStatusFn(value,scope) {
      console.log(value)
      console.log(this.tableData[scope.$index].statusTypeIs)
      //先把值赋为原来的值，保证swich状态不变
      let _this = this,adIdList,params,time
      adIdList=[scope.row.id].join(',')
      _this.adIdList = [scope.row.id]
      params = {'ids': adIdList,'type':3}

      // this.tableData[scope.$index].pauseState = value == 0?1:0//需求更改暂时注释
      // this.tableData[scope.$index].statusTypeIs = value == 1?'2':'1'//需求更改暂时注释
      console.log(this.tableData[scope.$index].statusTypeIs)
      console.log(value)
      $http.axios.post("/api/hw/openStop/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          if(res.data.data!= null){
            // _this.planName = res.data.data
            _this.planName = res.data.data
            _this.command = value
            _this.alertStartStopDialogIs = true
            _this.validDate = formatDate(new Date())
          }else{
          this.tableData[scope.$index].statusTypeIs = value
          this.updateStatusFn(scope.row.statusTypeIs,scope,'继续')//需求更改暂时打开
          }
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    changeStatusList(command){
      let _this = this,adName = '',text = ''
      adName = _this.closeAdList
      if(adName!=''){
        // _this.adName = adName
        _this.command = command+1
        _this.alertStartStopDialogIs = true     
      }else{
        this.updateStatusFn(command,'继续')
      }
    }, 
       
       
      //  deptId
      //  ids
      //  ownerId
      //  userId
      //  userName
    // 删除
    deletePlanFn(adId){
      let _this = this,params = {},type = ''
      params = {
        "deptId":_this.deptId,
        "ids": adId.toString(),
        "ownerId": Number(_this.userContent.ownerId),
        "userId":_this.userId,
        "userName":_this.userName
        }
      $http.axios.post("/api/hw/adCreative/batch/del",params).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.advertListFn(this.page)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 1){
        return 'color: #6495ed' 
      }else{
        return 'color: #606266' 
      }
    },

    //修改广告名称
    editAdTeamName(item){
      console.log(item)
      this.editId = String(item.row.id)
      if(item.column.label == '创意名称'){
        console.log(123456)
        this.editNameVisible = true
        this.editName = item.row.name
      }
    },

    //修改广告名称和时间段
    sureEditName(){
      let that = this, params = {}
      params = {
        info:{
          creativeId:that.editId,
          name:that.editName
        },
        ownerId:that.$route.query.id,
        userId:that.userId,
        userName:that.userName,
        deptId:that.deptId
      }

      // $http.axios.post("/api/hw/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      $http.axios.post("/api/hw/adCreative/edit",params).then(res=> {
        if(res.data.code==200){
          that.editNameVisible = false
          that.advertListFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //列表总计
    getSummaries(param) {
      let that = this
      const { columns, data } = param
      const sums =[]
      // console.log(column.property)
      columns.forEach((column, index) => {
        // console.log(column.property)
        if (index === 1) {
          sums[index] = '整体数据';
          return;
        }
        if(index>11){
          if(column.property == null ||column.property == ''){
            sums[index] = '-';
          }else{
            sums[index] = that.sums[column.property] 
          }
        }
      });
      return sums
    },
  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .adTeamEditBtn{
    padding-left:10px;
    color:#333;
    cursor: pointer;
    display:none;
  }
  .el-table .el-table__body tr:hover td .adTeamEditBtn{
    display:inline-block;
  }
  .timeTab .el-tabs__item{
    padding:0!important;
    padding:10px!important;
    border:1px solid #ccc!important;
  }
  .statusBtn{
    padding:3px 8px;
    background:rgba(103,194,58,.1);
    color:#67c23a;
  }
  .statusOtherBtn{
    padding:3px 8px;
    background:rgba(245,108,108,.1);
    color:#f56c6c;
  }
  .statusBtnFinish{
    padding:3px 8px;
    background:rgba(144,147,153,.1);
    color:#909399;
  }
  .uploadIcon{
    font-size:40px;
    display:block;
    text-align:center;
  }
  .isStyle i{
    font-style:normal;
    padding-right:10px;
  }
  /* tab切换当前样式 */
  .chanelTab {
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
    .channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }

// 投放状态按钮样式
  .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .statusOtherBtnwei{
      padding:3px 8px;
      background:rgba(230,162,60,.1);
      color:#E6A23C;
    }
</style>
