import Vue from 'vue';
import VueI18n from 'vue-i18n';

// element-ui 国际化资源
import en_el from 'element-ui/lib/locale/lang/en';
import zh_el from 'element-ui/lib/locale/lang/zh-CN';

// 本地国际化资源
import zh_locale from './zh-cn';
import en_locale from './en-us';

// 装载i18n组件
Vue.use(VueI18n);

const i18n = new VueI18n({
  // 默认采用本地缓存，没有采用英文
  locale: window.localStorage.getItem('lang') || 'zh-cn',
  messages: {
    'zh-cn': Object.assign({}, zh_el, zh_locale),
    'en-us': Object.assign({}, en_el, en_locale)
  }
});

export default i18n;
