<template>
    <div class="authority common-page">
      <!-- <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>运营管理</el-breadcrumb-item>
          <el-breadcrumb-item>消耗汇总</el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
       <PocCardTable>
         <template slot="headerTitle" v-if="accountSelectionListShow">
          <div class="chanelTab">
            <span class="chanelTabWord">消耗汇总 - </span>
          <span class="channel_vivo" :class="item.id==5?'channelActive':''" v-for="(item,i) in qdoptions" :key="i" @click="item.id!=5&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
          </div>
          <div>
            <iframe 
              id="iframe" 
              ref="iframeref" 
              name="mainFrame" 
              src="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=e327e013-e50d-4984-ac98-8ff0a722deea&accessToken=dcf64b8cec063ef2c7488984aae5ba27&dd_orientation=auto" 
              class="biConsumptionSum">
            </iframe>
          <!-- <el-tabs v-model="activeName" type="card">
              <el-tab-pane label="视频" name="first">
                <iframe 
                  id="iframe" 
                  ref="iframeref" 
                  name="mainFrame" 
                  src="" 
                  class="biConsumptionSum">
                </iframe>
              </el-tab-pane>
              <el-tab-pane label="图文" name="second">
                <iframe 
                  id="iframe" 
                  ref="iframeref" 
                  name="mainFrame" 
                  src="" 
                  class="biConsumptionSum">
                </iframe>
              </el-tab-pane>
            </el-tabs> -->
          </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import Qs from 'qs';

  export default {
    name: 'demandOrder',
    data() {
      var checkAge = (rule, value, callback) => {
        setTimeout(() => {
          if (!Number.isInteger(value)) {
            callback(new Error('请输入数字值'));
          } 
        }, 1000);
      };
      
      return {
        rules: {
          Numsr: [
            { validator: checkAge, trigger: 'blur' }
          ]},
        aboutUserIdNNN:[],//关联人员回显
        checkedCities: [],
        cities: [],
        city:[],
        showTheForm:true,//表头是否显示全部表单
        materiaChannelValue:'', //头部渠道搜索value
        mStatusValue:'', //选择素材状态下拉框的value
        qdoptions: [],//渠道列表
        materiaForm:{//头部查询表单的prop
          showQdList:[],//选择渠道
          showScstatus:'',//选择素材状态
          materialDate:[],//选择素材开始结束日期
          tfName:'',
          tfProject:'',
          tfMaterialType:'',
          tfMaterialTab:'',
          tfPerson:'',
          tfmaterialID:'',
          tfmaterialName:'',
          tfData:'',
          tfSymbol:'',
          postgl:'',
          glStaff:[],
          mPname:'',
          mXname:'',
          mPaccount:'',
          Numsr:'',
          userids:'',
          Numsr02:'',
          Numsr01:'',
          mTmakeusers:'',
          tagcheck:'',
          specifications:'',
          gongdan:''
        },
        mPnameOptions:[],
        mXnameOptions:[],
        mPaccountOptions:[],
        tfAccounOptions:[],
        tfAccounttOptions:[],
        tfNameOptions:[],
        tfProjectOptions:[],
        tfMaterialTabOptions:[],
        tfPersonOptions:[],
        tfDataOptions:[],
        tableData: [],
        multipleSelection: [],
        reportValue:'',//报表维度
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dialogFormVisible:false,
        formLabelWidth:'120px',
        customData:[],//自定义列data
        checkList:[],
        checkListTd:[],
        checkListExist:'',
        setParams:{
          customLineString:'',
          userId:''
        },
        checkListString:[],
        dialogTableVisible:false,
        gridData:[],//计划data
        planParams:{
          materialId:'',
          userId:'',
        },
        originParams:{
          materialId:'',
          userId:''
        },
        dialogTableVisible_origin:false,
        gridDataOrigin:[],//创意data
        consumeParams:{
        },
        consumeParamsOppo:{
          params:{}
        },
        getUserId:'',
        getzdyData:[],
        getMaterialListArray:[],
        checkdddd:'',
        checkListStringList:[],
        selectedPerson:[],
        gridCheckList:[],
        cyData:'',
        cyTag:[],
        cyValue:[],
        staffPageSize:'500',
        staffPageNum:'1',
        tagListParams:{//标签列表参数
          tagType:'2',
          userId:'',
          channel:''
        },
        bigImg:'',
        userIdParams:'',
        channelIdParams:'',
        typeParams:'',
        // 测试
        numm:[],
        userChannel:'',
        centerDialogVisible:false,
        videoUrlShow:'',
        firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',
        useridsOptions:[],
        yonghu:'',
        getParams:{
          productId:''
        },
        getProductId:'',
        exprtVisible:false,
        myHeaders: {Authorization:''},
        totalCost:'',
        //搜索框回显
        channelPH:'',//渠道
        holderAboutUser:'',//关联人员
        holderBegin:'',//消耗开始时间
        holderEnding:'',//消耗结束时间
        holderUser:'',//选择用户
        holderProduct:'',//客户名称
        holderProject:'',//项目名称
        holderTagType:'',//素材类型
        holderDirect:'',//编导
        holderClip:'',//剪辑
        holderPhoto:'',//摄影
        holderLanuch:'',//投放人员
        holderMaterName:'',//素材名称
        holderKey:'',//选择的数据形式
        holderKeyMark:'',//选择的符号
        holderKeyData:'',//数值
        uploadingShow:false,
        tagcheckOption:[],
        specificationsOption:[],
        gongdanOption:[{
          value:1,
          label:'图文'
        },{
          value:2,
          label:'视频'
        }],
        accountSelectionListShow:true,//用户是否有权限
        firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',
        screenHeight:window.innerHeight-300,
        // activeName: 'second',

      }
    },
    computed: {
       
    },
    mounted() {
      this.getPostDetail()
      this.getStaffDetail()
      this.getProject()
      this.getProduct()
      this.getuserChannel()
      this.getUserMaterialId()
    },
    methods: {
      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.mtName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      //根据id弹出视频
      showVideo(row){
        this.centerDialogVisible = true
        this.videoUrlShow = row.mtUrlJson.videoUrl
        this.$nextTick(() => {
          this.$refs.videoPouse.play()
        })
      },
      handleDialogClose(){
        this.centerDialogVisible = false
        this.$refs.videoPouse.pause()
      },

      //头部表单重置
      resetForm(materiaForm) {
        this.$refs[materiaForm].resetFields();
        console.log(this.materiaForm)
        this.aboutUserIdNNN = ''
        this.consumeParams.photographyId =  ''
        this.consumeParams.aboutUserId = ''
        this.consumeParams.beginTime = ''
        this.consumeParams.endTIme = ''
        this.consumeParams.productionName = ''
        this.consumeParams.projectName = ''
        this.consumeParams.materialType = ''
        this.consumeParams.materialTag = ''
        this.consumeParams.directId = ''
        this.consumeParams.clipId = ''
        this.consumeParams.launchId = ''
        this.consumeParams.materialName = ''
        this.consumeParams.key = ''
        this.consumeParams.keyMark = ''
        this.consumeParams.keyData = ''
        this.consumeParams.hlMaterial = ''
        this.materiaForm.showQdList = ''
        this.materiaForm.tfProject = '' //项目名称
        this.materiaForm.tfMaterialType = '' //素材类型
        this.materiaForm.tfMaterialTab = '' //素材标签
        this.materiaForm.mPname = ''
        this.materiaForm.mXname = ''
        this.materiaForm.mPaccount = ''
        this.materiaForm.tfPerson = ''
        this.materiaForm.tfData =''
        this.materiaForm.tfSymbol = ''
        this.materiaForm.Numsr = ''
        this.materiaForm.tfmaterialName = '' //素材名称
        this.$router.go(0)
      },

      changechannel(event){
        let channelIds = []
        channelIds.push(event)
        this.consumeParams.channelId = String(channelIds)       
        this.channelIdParams = String(channelIds)
        if(this.channelIdParams){
          this.getchannelUser()
        }
      },

      changePost(event){
        this.consumeParams.postId = String(event)
        this.userIdParams = String(event)
        this.materiaForm.glStaff =[]
        if(this.materiaForm.postgl){
          this.getPostUser()
        } 
      },

      //获取相关职位信息
      getPostDetail(){
        let that = this
        $http.axios.get("/api/system/post/optionselect").then(function(response) {
          if(response.data.code == 200){
            that.tfAccounOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取人员信息
      getStaffDetail(){
        let that = this
        $http.axios.get("/api/system/user/optionselect").then(function(response) {
          that.mPnameOptions = response.data
          that.mXnameOptions = response.data
          that.mPaccountOptions = response.data
          that.tfPersonOptions = response.data
        })
      },

      //项目名称下拉框
      getProject(){
        let that = this
        $http.axios.get("/api/operationManagement/materialoperation/getallProject?productId="+that.getProductId).then(function(response) {
          if(response.data.code == 200){
            that.tfProjectOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //客户名称下拉框
      getProduct(){
        let that = this
        $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
          if(response.data.code == 200){
            that.tfNameOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //查询的素材标签下拉框数据
      getTagList(){
        let that = this
        that.tagListParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/material/taglist",that.tagListParams).then(res=> {
          if(res.data.code == 200){
            that.tagcheckOption = res.data.data
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //查询用户
      getUserMaterialId(){
        let that = this
        $http.axios.get("/api/operationManagement/material/materialpull?name="+'').then(res=> {
          if(res.data.code == 200){
            that.useridsOptions = res.data.data
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      getDataByKeywords(){
        let that = this
        let userName = that.$refs.searchSelect.previousQuery
        $http.axios.get("/api/operationManagement/material/materialpull?name="+userName).then(res=> {
          if(res.data.code == 200){
            that.useridsOptions = res.data.data
            if(res.data.data == ''){
              that.$message('无匹配结果')
              that.getMaterialList(that.pageNum)
            }
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //导出
      exprot(){
        let that = this
        that.exprtVisible = true
      },

      //确认导出
      sureExport(){
        let that = this
        that.uploadingShow = true
        let timestamp = Date.parse(new Date())
        $http.axios.defaults.timeout = 500000
        that.consumeParamsOppo.mtName = that.materiaForm.mTitle //素材名称
        that.consumeParamsOppo.mtid = that.materiaForm.userids //素材id
        that.consumeParamsOppo.mkUser = that.materiaForm.mTmakeusers //制作人名称
        if(that.materiaForm.materialDate != null){
          that.consumeParamsOppo.params.beginCreateDate = that.materiaForm.materialDate[0]
          that.consumeParamsOppo.params.endCreateDate = that.materiaForm.materialDate[1]
        }else{
          that.consumeParamsOppo.params.beginCreateDate = ''
          that.consumeParamsOppo.params.endCreateDate = ''
        }
        $http.axios.post("/api/xmat/mtdata/export",that.consumeParamsOppo,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
          that.exprtVisible = false 
          let blob = response.data
          let reader = new FileReader()
          console.log(reader)
          reader.readAsDataURL(blob)
          reader.onload = e => {
            let a = document.createElement('a')
            a.download = "素材消耗数据"+timestamp+".xls"
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            that.uploadingShow = false
          }
        })
      },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
          if(response.data.data.length!=0){
            that.qdoptions = response.data.data    
            that.accountSelectionListShow=true//用户是否有权限
            that.tagListParams.channel = response.data.data[0].id
            let channelIdPa = []
            that.qdoptions.forEach(function(e,i){
              channelIdPa.push(e.id)
              that.consumeParams.channelId = String(channelIdPa)
              that.channelIdParams = String(channelIdPa)
            })
            that.getMorenList()
            that.getTagList()
            that.getchannelUser()
          }else{
            that.accountSelectionListShow = false
            that.$message({
              showClose: true,
              message: "您的操作权限不足",
              type: 'error'
            })
          }
        })
      },
      
      //channel的tab切换
      clickChangeChannel(channelId){
        let channelNameList = {3:'authManagement.ConsumptionSumOppo',4:'VivoAccountManagement.ConsumptionSumVivo',6:'authManagement.ConsumptionSumElse',7:'authManagement.ConsumptionSumElse'}
        this.$router.push({name:channelNameList[channelId]})
      },      
      
      getchannelUser(){
        let that = this
        $http.axios.get("/api/system/user/optionselectQuery?ids="+that.channelIdParams+"&type=1").then(function(response) {
          that.tfAccounttOptions = response.data
        })
      },

      getPostUser(){
        let that = this
        $http.axios.get("/api/system/user/optionselectQuery?id="+that.userIdParams+"&type=2").then(function(response) {
          that.tfAccounttOptions = response.data
        })
      },

      //获取用户自定义列
      getMorenList(){
        let that = this
        that.getMaterialListArray = []
        $http.axios.post("/api/xmat/mtdata/getCustomLineList").then(function(response) {
          if(response.data.code == 200){
            console.log(response)
            that.getzdyData = response.data.data
            if(that.getzdyData[0] != null){
              // that.getzdyData 去重
              that.getzdyData.forEach(function(v,i,arr){
              let bool = arr.indexOf(v,i+1)
              if(bool === -1){
                that.getMaterialListArray.push(v.string)
              }
            })
              that.consumeParams.customLineString = String(that.getMaterialListArray)
            }else{
              that.consumeParams.customLineString = ''
            }
            that.getMaterialList(that.pageNum,that.consumeParams.customLineString)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      sortChange(column) {
        if(column.order == 'ascending'){
          this.consumeParamsOppo.params.costOrder = "1";
          column.order = 'descending'
        }else{
          this.consumeParamsOppo.params.costOrder = "2";
          column.order = 'ascending'
        }
        let allRe=this.consumeParamsOppo.customLineString;
        this.getMaterialList(this.pageNum,column.prop,allRe,this.consumeParamsOppo.params.costOrder)
      },

      //消耗列表
      getMaterialList(page,customLineString,allRe,orderBy){
        let that = this
        that.consumeParamsOppo.mtName = that.materiaForm.mTitle //素材名称
        that.consumeParamsOppo.mtid = that.materiaForm.userids //素材id
        that.consumeParamsOppo.mkUser = that.materiaForm.mTmakeusers //制作人名称
        that.consumeParamsOppo.params.costOrder = orderBy||'2'
        if(that.materiaForm.materialDate != null){
          that.consumeParamsOppo.params.beginCreateDate = that.materiaForm.materialDate[0]
          that.consumeParamsOppo.params.endCreateDate = that.materiaForm.materialDate[1]
        }else{
          that.consumeParamsOppo.params.beginCreateDate = ''
          that.consumeParamsOppo.params.endCreateDate = ''
        }
        // that.consumeParamsOppo.params.beginCreateDate = that.materiaForm.materialDate[0]
        // that.consumeParamsOppo.params.endCreateDate = that.materiaForm.materialDate[1]
        $http.axios.post("/api/xmat/mtdata/list?pageNum="+page+"&pageSize="+that.pageSize,that.consumeParamsOppo).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
            if(that.getzdyData[0]==null){
              that.checkList=[]
            }else{
              that.checkList=that.getzdyData
            }
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取全部自定义列
      customColumns(){
        let that = this
        that.dialogFormVisible = true
        that.checkedCities = that.getMaterialListArray
        $http.axios.get("/api/xmat/mtdata/queryCustomLineList").then(function(response) {
          that.customData = response.data
        })
      },

      //设置用户自定义列
      setCustom(){
        let that = this
        that.checkListString=[]
        that.checkListStringList = []
        that.setParams.customLineString = String(that.checkedCities)
        $http.axios.post("/api/xmat/mtdata/bdCustomLineList",that.setParams).then(function(response) {
          if(response.data.code == 200){
            console.log(that.tableData.push(that.checkedCities))
            // that.tableData.push(that.checkedCities)
            that.dialogFormVisible = false
            that.getMorenList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      // 分页
      handleSizeChange(val) {
        console.log(val)
        this.pageSize = 10
        this.pageNum = val
        this.findAll(val)
      },
      handleCurrentChange(val) {
        console.log(val)
        this.pageNum = val
        this.findAll(val)
      },
      findAll (params) {
        this.getMaterialList(params)
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materialHead{
      font-size:0;
    }
    .materialHchannel,.materialHBtns{
      display:inline-block;
      vertical-align:middle;
      font-size:14px;
    }
    .materialHchannel{
      width:30%;
    }
    .materiaContent{
      margin-top:15px;
    }
    .showTheForm,.hideenTheForm{
      display:inline-block;
      vertical-align:top;
    }
    .showTheForm{
      position:relative;
    }
    .showTheForm i{
      position:absolute;
      bottom:-10px;
      left:50%;
      margin-left:-11px;
      font-size:22px;
      color:rgb(36, 132, 168);
      cursor:pointer;
    }
    .hideenTheForm i{
      font-size:22px;
      vertical-align:middle;
      color:rgb(36, 132, 168);
      cursor:pointer;
      margin:0 20px;
    }
    .tableTop{
      margin-bottom:20px;
    }
    .customColumns{
      float:right;
    }
    .customBox{
      width:100%;
      padding:0 20px;
      box-sizing: border-box;
      font-size:0;
    }
    .customContent,.customExist{
      font-size:14px;
      display:inline-block;
      vertical-align:top;
      box-sizing: border-box;
    }
    .customContent{
      width:60%;
      padding-right:15px;
    }
    .customExist{
      width:40%;
    }
    .customExist span{
      display:block;
    }
    .ss{
      cursor:pointer;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }
    .customColumnsTag{
      cursor:default;
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .customColumnsTag:hover{
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .uploading{
      display:block;
      text-align:center;
      font-size:50px;
    }
    /* oppo和vivo的tab切换 */
    .chanelTab{
      /* margin-bottom:30px; *//*暂时隐藏，等bi看板增加了视频和图文的tab切换之后放开*/
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:36px;
      line-height:36px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
    .biConsumptionSum{
      width:100%;
      border:none;
      height:1000px;
      /* height:100%; */
      /* position: absolute;
      top:132px;
      left:50%;
      transform: translateX(-50%);
      z-index:10;
      background: none; */
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      margin:0!important;
    }
    ::v-deep .el-tabs__item {
      line-height:24px;
      height:24px;
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      background: #66856d;
      color:#fff;
    }
    .chanelTabWord{
      font-size:15px;
      font-weight: 700;
    }

  </style>
