<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle" v-if="accountSelectionListShow">  
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>计划管理</el-breadcrumb-item>
              <el-breadcrumb-item>账户选择</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="success" class="setWarnning" size="small" @click="warmSet" >预警设置</el-button>
            <el-button type="primary" size="small" @click="warmIs1('dayLimit','/adGroup/v1/checkGroupModifyPriceSet')">批量日限额</el-button>
            <el-dropdown>
              <el-button type="primary" size="small">
                下载模板<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="cyOne">创意模板</el-dropdown-item>
                <el-dropdown-item @click.native="cyTwo">广告组+创意</el-dropdown-item>
                <el-dropdown-item @click.native="cyThree">广告计划+广告组+广告</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>  
        <div class="chanelTab">
          <span class="channel_oppo" :class="item.id==3?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=3&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="" prop="">
                  <el-input v-model="customerName" placeholder="请输入客户名称"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="账户状态" v-model="channeln">
                    <el-option
                      v-for="(item,i) in qdoptions.userStatuList"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form> 
              <!-- <el-button type="success" size="small" @click="userStatusFn" >搜索</el-button> -->
              <el-button type="primary" class="newQuery" size="small" @click="userStatusFn" >查询</el-button>
              <el-button type="info" size="small" plian @click="reset">重置</el-button>
            </div>
          </div>
          <div class="materiaContent">
             <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%;cursor: pointer;"
              fit
              :cell-class-name="tableCellClassName"
              :cell-style="setRowStyle"
              @cell-click="clickcell"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                align='center'
                style="width: 100%"
                v-for="(item,index) in titleList" :key="index"
                :prop="item.prop"
                :label="item.name"
                show-overflow-tooltip>
                
                <template v-if='item.prop=="type"' v-slot="scope">
                  <el-switch
                    v-model="scope.row.type"
                    class="switchStyle"
                    :active-value="3"  
                    :inactive-value="2"
                    active-text=""
                    inactive-text=""
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    :disabled = "scope.row.disabledIs"
                    @change="getBrandList(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column 
                fixed = 'right'
                align="center"
                width='340px'
                :show-overflow-tooltip="true"
                label="操作">
                <template>
                  <el-button class="solid-tag-success" plain size="mini" @click = "goNextPage(0)" >计划</el-button>
                  <el-button class="solid-tag-warning" plain size="mini" @click = "goNextPage(1)" >调价</el-button>
                  <el-button class="solid-tag-harmful" plain size="mini" @click = "goNextPage(2)" >启停</el-button>
                  <el-button class="solid-tag-instructions" size="mini" data-type = 'uploadBtn' @click="uploadDialogIs =true">上传</el-button>
                  <el-button class="solid-tag-instructions" plain size="mini" @click = "goNextPage(3)" >上传记录</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="clearfix">
            <div class="allOperation fL">
              <!-- <div class="totalNum fL">共<i>50</i>条</div> -->
              <!-- 批量操作功能暂时不用 -->
              <!-- <el-select v-model="value" placeholder="批量操作">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select> -->
            </div>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
          </div>
          <!-- 批量上传 -->
          <el-dialog 
            title="批量上传"
            width="400px"
            center
            :visible.sync="uploadDialogIs">
            <div class="dialogIcon"><img :src='biaoQianIcon'></div>
            <div class="batchUpload ">
              <el-select v-model="uploadValue" placeholder="请选择上传类型" @change = "uploadFn">
                <el-option
                  v-for="item in uploadOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <div id="uploadBtn" class="uploadBtnBottom">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="/api/advertisement/adFile/uploadAdFile"
                  accept=".xlsx"
                  :limit='1'
                  :data = fileList
                  :on-success= "onSuccess"
                  :show-file-list='false'>
                  <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                </el-upload>
              </div>
            </div>   
            <div class="uploadDialogtf">
              <el-button type="primary" class="newDialogCancel" @click="uploadDialogIs = false">取 消</el-button>
            </div>         
          </el-dialog>

        </div>
      </template>
    </PocCardTable>
    <startLimitSingleDialog :visible.sync="showSingleDialog" :yulanList = "yulanList" :userContent = 'userContent' :planType = "planType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleDialog>
    <earlyWarningDialog :visible.sync="showDialog" :warmSetType="warmSetType" :userContent = "userContent" :ownerIdList = 'ownerIdList' :pubVue = 'pubVue'></earlyWarningDialog>
    <earlyWarningDialogIs ref = 'earlyWarningDialogIs' :pubVue = "pubVue" :nowIs = "nowIs" :visible.sync="showDialogIs" :userContent = 'userContent' :ownerIdList = 'ownerIdList'></earlyWarningDialogIs>
    <!-- <uploadDialog :visible.sync="uploadDialogIs" :ownerId = 'ownerId'></uploadDialog> -->
  </div>
</template>

<script>
import biaoQianIcon from '@/assets/img/biaoQianIcon.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import earlyWarningDialog from '@/views/dialog/earlyWarningDialog'
import earlyWarningDialogIs from '@/views/dialog/earlyWarningDialogIs'
import startLimitSingleDialog from '@/views/dialog/startLimitSingleDialog'
import uploadDialog from '@/views/dialog/uploadDialog'

export default {
  components: {
    earlyWarningDialog,
    earlyWarningDialogIs,
    startLimitSingleDialog,
    // uploadDialog
  },
  data() {
    return {
      biaoQianIcon,
      pubVue:this,
      showSingleDialog:false,
      uploadDialogIs:false,//批量上传弹框
      showDialog: false,//预警设置弹框
      showDialogIs: false,//提示已经设置过的弹框
      nowIs:false,
      setData:{},//账户余额(cashBal)、今日消耗(cashCost)、计划日预算(cashDayBudget)设置回显
      userContent:{},//登录用户信息&弹框默认
      params:{},//入参
      // params:{
      //   "accid": "",
      //   "companyName": "",
      //   "endTime": "",
      //   "flag": "",
      //   "page": "",
      //   "pageCount": "",
      //   "startTime": "",
      //   "userId": ""
      // },//查询有权限的所有帐号接口入参
      qdoptions:{channelList:[{text:'oppo',id:'3'}],
                userStatuList:[{text:'全部状态',id:'0'},{text:'未设置',id:'1'},{text:'预警开启中',id:'3'},{text:'预警已关闭',id:'2'}]},//选择状态
      titleList:[{name:'渠道名称',prop:'channelId'},
      {name:'客户名称',prop:'accountName'},
      {name:'广告主ID',prop:'accountId'},
      {name:'账户余额',prop:'cashBal'},
      {name:'今日消耗',prop:'cashCost'},
      {name:'计划日预算',prop:'cashDayBudget'},
      {name:'账户预警',prop:'type'}],//title名称
      channel:'',
      channeln:'',
      customerName:'',//搜索框--客户名称
      // tableData:[],//查询有权限的所有帐号接口回显
      tableData:[],//查询有权限的所有帐号接口回显
      show:false,
      uploadOptions: [{
        value: '10',
        label: '上传计划+广告组+创意'
      }, {
        value: '20',
        label: '上传广告组+创意'
      },{
        value: '30',
        label: '上传创意'
      }, ], //批量上传
      uploadValue:'10',//批量上传绑定值
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      userId:'',//用户ID
      userName:'',
      deptId:'',
      ownerId:'',
      ownerIdList:[],//选中的用户
      disabledIs: false,//switch是否可操作
      operNum:0,
      yulanList:[],
      planType:10,
      dialogTitle:'批量日限额',
      myHeaders: {Authorization:''},
      fileList:{},
      operaType:10,
      warmSetType:'oppo',//如果是VIVO传1
      accountSelectionListShow:true,//用户是否有权限
      channelTitleList:[],//权限名称列表

    }
  },
  computed: {
     
  },
  mounted() {
    console.log(999)
    // this.myHeaders.Authorization = 'Bearer cf40c3ad-466e-45cd-b642-3a0992dbf357'
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.params.userId  = this.userId
    this.userContent.userId = this.userId
    this.params.userName  = this.userName
    this.userContent.userName = this.userName
    this.params.deptId = this.deptId
    this.userContent.deptId = this.deptId
    // this.params.pageCount = this.pageCount
    // this.params.page = this.page
    // this.oppoAccountList()
    console.log(this.nowIs)
     this.getId()
     this.getuserChannel()
  },
  methods: {
    //获取用户的权限
    getuserChannel(){
      let that = this
      $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userId).then(function(response) {
        if(response.data.data!=undefined&&response.data.data.length!=0){
           that.channelTitleList = response.data.data
          //  that.channelTitleList = [{'id':3,name:'OPPO'},
          //  {'id':4,name:'VIVO'},
          //  {'id':5,name:'Mi'}]
          // that.accountSelectionListShow = true
          // if(that.channelTitleList.id!=3){
          //   let channelNameList = {4:'VivoAccountManagement.AccountSelectionVivo',5:'MiAccountManagement.AccountSelectionMi'}
          //   that.$router.push({name:channelNameList[that.channelTitleList[0].id]})
          // }else{
          //   that.oppoAccountList()
          // }
          that.accountSelectionListShow = true
          let flag = that.channelTitleList.every((obj=>{
           if(obj.id == 3){
             return false
           }else{
             return true
           }
          }))
          if(flag){
            let channelNameList = {4:'VivoAccountManagement.AccountSelectionVivo',5:'MiAccountManagement.AccountSelectionMi',6:'HwAccountManagement.AccountSelectionHw',7:'YybAccountManagement.AccountSelectionYyb',8:'planManagement.AccountSelectionApple'}
            that.$router.push({name:channelNameList[that.channelTitleList[0].id]})
          }else{
            that.oppoAccountList()
          }

        }else{
          that.accountSelectionListShow = false
          that.$message({
          showClose: true,
          message: "您的操作权限不足",
          type: 'error'
        })
        }
      })
    },
    //channel的tab切换
    clickChangeChannel(channelId){
      // this.$router.push({name:'VivoAccountManagement.AccountSelectionVivo'})
      let channelNameList = {4:'VivoAccountManagement.AccountSelectionVivo',5:'MiAccountManagement.AccountSelectionMi',6:'HwAccountManagement.AccountSelectionHw',7:'YybAccountManagement.AccountSelectionYyb',8:'planManagement.AccountSelectionApple'}
      this.$router.push({name:channelNameList[channelId]})
      // this.$router.push({name:'VivoAccountManagement.AccountSelectionVivo'})
      // this.$router.push({name:'planManagement.AccountSelection'})
      console.log(channelId)
    },
     //重置
     reset(){
      this.$router.go(0)
    },
    //获取计划、启停、调价的索引
    goNextPage(num){
      console.log(num)
      this.operNum = num
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
      console.log(this.uploadId)
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,dataSource =[],keywords = []
      // let video = []
      console.log(response)
      if(response.code==200){
        that.oppoAccountList()
        that.uploadDialogIs = false
        
        that.$message({
          showClose: true,
          message: "提示上传成功，请稍后移步【上传记录】查看上传结果",
          type: 'success'
        })
      
      }else{
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'error'
        })
      }
      this.$refs.upload.clearFiles()
    },
    uploadFn(){
      this.fileList={
        "ownerId":this.ownerId,
        "operaType":this.uploadValue,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
    },
    //获取查询有权限的所有帐号接口
    oppoAccountList(pageNum,channelId,type,name){
      let _this = this,pageSize
      // $http.axios.post("/api/advertisement/account/selectAccount",params).then(res=> {
      //   if(res.data.code==200){
      channelId = channelId||3
      name = name || ''
      type = type|| 0
      pageNum = pageNum|| 1
      pageSize= _this.pageCount,
      // $http.axios.get("/ami/account/accountCostList?channelId="+channelId+'&type='+type+'&pageSize=10&pageNum='+pageNum+'&name='+name).then(res=> {
      $http.axios.get("/api/channel/account/accountCostList?channelId="+channelId+'&type='+type+'&pageSize='+pageSize+'&pageNum='+pageNum+'&name='+name).then(res=> {
        if(res.data.code==200){
        // if(res.code==200){
          // 数据修改，余额、消耗、日预算初始值如果为空的状态
          res.data.rows.forEach(element => {
          // res.rows.forEach(element => {
            element.channelId = 'OPPO'
            if(element.cashBal  == null){
              element.cashBal  = 0.00
            }else{
              element.cashBal = (element.cashBal/100).toFixed(2)
            }
            if(element.cashCost== null){
              element.cashCost = 0.00
            }else{
              element.cashCost = (element.cashCost/100).toFixed(2)
            }
            if(element.cashDayBudget== null){
              element.cashDayBudget = 0.00
            }else{
              element.cashDayBudget = (element.cashDayBudget/100).toFixed(2)
            }
            if(element.type == 1){
              element.disabledIs = true
            }else{
              element.disabledIs = false

            }
            // if(element.type != 3){
            //   element.type = 2
            // }
            if(element.channelId == null){
              _this.titleList.forEach(function(e,i){
                
            })
          }
        });
          _this.tableData = res.data.rows
          _this.totalRow = res.data.total
          // _this.tableData = res.rows
          // _this.totalRow = res.total
        // }
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
         
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },

    // 选择账户状态
    userStatusFn(){
      let _this = this
      _this.oppoAccountList(1,_this.channel,_this.channeln,_this.customerName,)

    },
    
    // 预警设置（判断是否有设置&无设置直接显示设置弹框，并加载部门名称列表/有设置罗列已设置的相，并提醒用户是否要设置=>取消关闭弹框||确认关闭确认弹框，开启预警设置弹框）
    warmSet(){
      let _this = this
      let ownerIdList = []//声明一个空数组
      //  _this.ownerIdList = [1100018542,1100018078]
       // that.getzdyData 去重
       _this.ownerIdList.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          ownerIdList.push(v)
        }
      })
      if(_this.ownerIdList.length!=0){
        _this.warmIs('warm',ownerIdList)
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
     // 批量启停设置
    updateOppoSet(type){
      let _this = this
      let ownerIdList = []//声明一个空数组
      //  _this.ownerIdList = [1100018542,1100018078]
        // that.getzdyData 去重
        _this.ownerIdList.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          ownerIdList.push(v)
        }
      })
      
      if(_this.ownerIdList.length!=0){
        _this.params.ownerIdList = _this.ownerIdList
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
   
    //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs1(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      // /adPlan/v1/checkPlanDailyMoneySet
      let _this = this
      let isTrue = _this.updateOppoSet(type)
      _this.planStatus = false
      if(isTrue){
          _this.yjCheck(type,url)     
      }
    },
     yjCheck(type,url,adPlanId){
      let _this = this,messageContent,idList = [],params = null
      if(type == 'dayLimit'){
        _this.ownerIdList.forEach(obj=>{
          idList.push(obj)
        }) 
        params = {"idList":idList,"planType":_this.planType}      
      }else{
      _this.ownerIdList.forEach(obj=>{
        params.push(obj)
      })
    }

      $http.axios.post("/api/advertisement"+url,params).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.content
          if(content == null){
            if(type == 'dayLimit'){
              this.showSingleDialog = true
            }
              
          }else{
            if(type == 'dayLimit'){
            //   let msgContent =  content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
            //   _this.$message({
            //   showClose: true,
            //   message: msgContent,
            //   type: 'error'
            // })

              let text = content.toString()+'账户已经设置过调价执行计划是否确认添加新计划'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                this.showSingleDialog = true
                // this.nowIs = true
              }).catch(()=>{
                
              })
              }
            }
          }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },

    // 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs(type,ownerIdList,setType){ //type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      let _this = this
      console.log(setType)
      $http.axios.post("/api/advertisement/account/v1/checkAccountWarning",ownerIdList).then(res=> {
        let content = []
        if(res.data.code==200){
          console.log(res.data.content)
          content = res.data.content
          console.log(type)
          if(type=='warm'){
            if(content == null){
            _this.showDialog = true
            }else{
              let messageContent = '已经设置过预警的用户无法多选账户设置预警；以下为已设置过的数据'+content.toString()
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }
          }else if(type=='set'){
            if(content == null){
              let messageContent = '您的账户未设置抄送人，请先设置抄送人'
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }else{
              if(setType == 3){
                let text = content.toString()+'账户已经设置过调价执行计划是否确认添加新计划'
                this.$confirm(text,"提示",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(()=>{
                  _this.showDialogIs = true
                  _this.userContent.switchValue = '1'
                  _this.userContent.maxNum = ''
                  _this.userContent.minNum = ''
                  _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.userContent.ownerId,setType)              
                  // _this.nowIs = true
                }).catch(()=>{
                  
                })
              }else{
                _this.showDialogIs = true
                _this.userContent.switchValue = '1'
                _this.userContent.maxNum = ''
                _this.userContent.minNum = ''
                _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.userContent.ownerId,setType)              
                // _this.nowIs = true
              }
              // let text = content.toString()+'账户已经设置过调价执行计划是否确认添加新计划'
              // this.$confirm(text,"提示",{
              //   confirmButtonText: '确定',
              //   cancelButtonText: '取消',
              // }).then(()=>{
              //   _this.showDialogIs = true
              //   _this.userContent.switchValue = '1'
              //   _this.userContent.maxNum = ''
              //   _this.userContent.minNum = ''
              //   _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.userContent.ownerId,setType)              
              //   // _this.nowIs = true
              // }).catch(()=>{
                
              // })



              // _this.showDialogIs = true
              // _this.userContent.switchValue = '1'
              // _this.userContent.maxNum = ''
              // _this.userContent.minNum = ''
              // _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.userContent.ownerId,setType)
            }
          }
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    
    tableCellClassName({row, column, rowIndex, columnIndex}){
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index=rowIndex;
      column.index=columnIndex;
    },
    
    // 点击一个单元格 账户余额(cashBal)、今日消耗(cashCost)、计划日预算(cashDayBudget)
    clickcell(row, column, cell, event){
      let _this = this,setType,num = event.target.parentNode.dataset.num//偶尔获取到undefined
      let urlList = ['advertisingPlan','PriceModify','StartStop','uploadPage']
      let type = event.target.parentNode.dataset.type

      // console.log(row);
      console.log(column.property)
      console.log(setType)
      console.log(type)
      // console.log(event);

      if(column.property=='cashBal'||column.property=='cashCost'||column.property=='cashDayBudget'){
        if(column.property=='cashBal'){
          setType = 1
        }else if(column.property=='cashCost'){
          setType = 2
        }else{
          setType = 3
        }
        _this.userContent.ownerId = row.accountId
        _this.userContent.ownerName = row.accountName
        _this.userContent.type = setType
        console.log(_this.userContent.type)
        console.log(setType)
        _this.ownerIdList = [] //金子加的，因为点击表格的时候总是把ownerId push进去
        _this.ownerIdList.push(row.accountId)
        // _this.warmSet()//金子隐藏的
        _this.warmIs('set',[row.accountId],setType)//金子放开的
      }else if(column.label == '操作'){
        if(type !=='uploadBtn'){
          _this.goPlan('/plan-management/'+urlList[_this.operNum],row.accountId,row.accountName)
        }else{
          _this.ownerId = row.accountId
          _this.uploadFn()
        }
      }
      console.log(column.property,urlList,num)
      if(event.target.parentNode.dataset.type=="selection"){//event.target.parentNode.dataset.type
          this.$refs.multipleTable.toggleRowSelection(row)
      }
      if(row.disabledIs && column.property == 'type'){
        let messageContent = '您的账户未设置抄送人，请先设置抄送人'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })
      }
      
    }, 
  // 分页
      handleSizeChange(val) {
        let _this = this
        console.log(666)
        _this.pageCount = val
        // _this.$set(_this.params,'page',val)
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        this.findAll(val)
      },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.oppoAccountList(val,3,0)
    },
    
    //选择行
    handleSelectionChange(val){
      let _this = this
      let ownerIdList = [], yulanList = []
      // console.log(val)
      // _this.userContent.ownerId = row.accountId
      // _this.userContent.ownerName = row.accountName
      val.forEach(obj=>{
        ownerIdList.push(obj.accountId)
        yulanList.push({ 'ownerId':obj.accountId,'ownerName':obj.accountName,'price':obj.cashDayBudget,'upPriceStatus':0})

      })
      _this.ownerIdList = ownerIdList
      _this.yulanList = yulanList
    },
    
    //按钮开关
    getBrandList(row){
      console.log(row)
      let params = {ownerId:row.accountId,totalSwitch:row.type} 
      this.updateAccountSwitchFn(params)       
    },
    
    // 账户列表页账户预警开关修改
    updateAccountSwitchFn(params){
      let _this = this
      $http.axios.post("/api/advertisement/account/v1/updateAccountSwitch",params).then(res=> {
        if(res.data.code == 200){

        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },  
    
    // 计划、调价、启停页面跳转
    goPlan(url,id,name){//
      console.log(url,id,name)
      this.$router.push({
          path: url,
          query: {
            id: id,
            name:name,
          }
        })
      // this.$router.push("/plan-management/"+urlList[num]+"/"+id+"/"+name)
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 4){
        return 'color: #6495ed' 
      }else if(row.columnIndex == 5){
        return 'color: #6495ed' 
      }else if(row.columnIndex == 6){
        return 'color: #6495ed' 
      }else{
        return 'color: #606266' 
      }
    },

    cyOne(){
      // window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model/ad_model.xlsx?OSSAccessKeyId=LTAI5tAUUykMtw127EvPAMaX&Expires=10000000001683622000&Signature=J08IOiw1HMhn%2Bq4RaVG%2F2%2FI3rsk%3D"
      window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model/ad_model.xlsx"
    },
    cyTwo(){
      // window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model/group_ad.xlsx?OSSAccessKeyId=LTAI5tAUUykMtw127EvPAMaX&Expires=100001683621292&Signature=8CeSUU%2BhXjdrTdj616EWN1s7r4o%3D"
      window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model2/group_ad.xlsx"
    },
    cyThree(){
      // window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model/plan_group_ad.xlsx?OSSAccessKeyId=LTAI5tAUUykMtw127EvPAMaX&Expires=100001683621334&Signature=3yvhS0nsNmf0Wdn3Qaof%2FHolmsw%3D"
      // window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model2/plan_group_ad.xlsx"
      window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/model2/plan_group_ad_1.xlsx"
      
    },

  }
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.changeBtn,.offBtn{
    display: inline-block;
    cursor: pointer;
  } */
  /* 操作按钮样式结束 */
   #uploadBtn{
    display: inline-block;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .el-button--small{
    margin-right: 10px;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }
  .setWarnning{
    background:#FFF5EC;
    color:#F3920B;
    border:1px solid #F3920B;
    border-radius:6px;
  }
  .setWarnning:hover{
    background:#F3920B;
    color:#FFF5EC;
    border:1px solid #F3920B;
  }
   /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
</style>