<template>
    <div class="authority common-page">
      <PocCardTable>
         <template slot="headerTitle" >
          <div>
            <!-- {{videoUrl}} -->
            <video 
              :src="videoUrl" 
              controls 
              autoplay 
              controlslist="nodownload" 
              ref="videoPouse" 
              style="width:100%;margin:0 auto;">
            </video>
          </div>

         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'demandOrder',
    data() {
      return {
        videoUrl:'',

      }
    },
    mounted() {
      this.videoUrl = this.$route.query.videoUrl
    },
    methods: {
      //根据id弹出视频
      showVideo(row){
        this.videoUrlShow = row.videoUrl
        this.$nextTick(() => {
          this.$refs.videoPouse.play()
        })
      },
      handleDialogClose(){
        this.centerDialogVisible = false
        this.$refs.videoPouse.pause()
      },

      

      



    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materialHead{
      font-size:0;
    }
    .materialHchannel,.materialHBtns{
      display:inline-block;
      vertical-align:middle;
      font-size:14px;
    }
    .materialHchannel{
      width:30%;
    }
    /* .materialHBtns{
      width:70%;
    } */
    .materiaContent{
      margin-top:15px;
    }
    .showTheForm,.hideenTheForm{
      display:inline-block;
      vertical-align:top;
    }
    .showTheForm{
      position:relative;
    }
    .showTheForm i{
      position:absolute;
      bottom:-10px;
      left:50%;
      margin-left:-11px;
      font-size:22px;
      color:rgb(36, 132, 168);
      cursor:pointer;
    }
    .hideenTheForm i{
      font-size:22px;
      vertical-align:middle;
      color:rgb(36, 132, 168);
      cursor:pointer;
      margin:0 20px;
    }
    .tableTop{
      margin-bottom:20px;
    }
    .customColumns{
      float:right;
    }
    .customBox{
      width:100%;
      padding:0 20px;
      box-sizing: border-box;
      font-size:0;
    }
    .customContent,.customExist{
      font-size:14px;
      display:inline-block;
      vertical-align:top;
      box-sizing: border-box;
    }
    .customContent{
      width:60%;
      padding-right:15px;
    }
    .customExist{
      width:40%;
    }
    .customExist span{
      display:block;
    }
    .ss{
      cursor:pointer;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }
    .customColumnsTag{
      cursor:default;
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .customColumnsTag:hover{
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .uploading{
      display:block;
      text-align:center;
      font-size:50px;
    }
    /* oppo和vivo的tab切换 */
    .chanelTab{
      margin-bottom:10px;
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:36px;
      line-height:36px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
    .biConsumptionSum{
      width:100%;
      border:none;
      height:1000px;
      /* height:100%; */
      /* position: absolute;
      top:132px;
      left:50%;
      transform: translateX(-50%);
      z-index:10;
      background: none; */
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      margin:0!important;
    }
    ::v-deep .el-tabs__item {
      line-height:24px;
      height:24px;
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      background: #66856d;
      color:#fff;
    }
    .chanelTabWord{
      font-size:15px;
      font-weight: 700;
    }
    /*xin*/
    .videoWrap{
      margin-top:20px;
    }
     /*新版*/
    ::v-deep .materialHead .el-input--mini .el-input__inner{
      /* width:160px; */
      height:32px;
      line-height:32px;
      background:#F5F7F6;
      border:none;
    }
    .el-form-item--mini.el-form-item{
      margin-bottom:10px;
    }
    .loading{
      text-align:center;
    }
    .loading span,.loading i{
      display: block;
    }
    .loading span{
      font-size:20px;
      font-weight:700;
      color:#fff;
    }
    .loading i{
      margin-top:10px;
      font-size:60px;
      /* color: #409eff; */
      color:#fff;
    }
    /* .loadingBg{
      margin-top:200px;
    } */
    ::v-deep .loadingBg .el-dialog{
      margin-top:200px;
      background:rgba(255,255,255,0)!important;
      box-shadow: none;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }
    ::v-deep .mineBg .el-dialog__headerbtn{
      right:420px;
      top:10px;
    }

    ::v-deep .el-table {
      display: flex;
      flex-direction: column;
    }
    ::v-deep .el-table__body-wrapper {
      order: 1;
    }
    ::v-deep .el-table__fixed-body-wrapper {
      top: 96px !important;
    }
    ::v-deep .el-table__fixed-footer-wrapper {
      z-index: 0;
    }

  </style>
