<template>
  <el-card class="poc-card-table">
    <div v-if="showHeader" slot="header">
      <div v-if="showTitleBox" class="pcth-title-box">
        <div class="pcth-title-left">
          <slot name="headerTitle"></slot>
        </div>
        <div v-if="$slots.hasOwnProperty('headerGroup')" class="pcth-title-right">
          <slot name="headerGroup"></slot>
        </div>
      </div>

      <div v-if="showQueryBox" :class="showTitleBox ? 'poc-mt16' : ''" class="pcth-query-box">
        <div class="pcth-queryList">
          <slot name="queryList"></slot>
        </div>
        <div v-if="$slots.hasOwnProperty('queryName')" class="pcth-queryName">
          <slot name="queryName"></slot>
        </div>
      </div>
    </div>

    <slot></slot>
  </el-card>
</template>

<script>
export default {
  name: 'PocCardTable',

  data() {
    return {
      ruleOut: ['headerTitle', 'headerGroup', 'queryList', 'queryName']
    };
  },

  computed: {
    showHeader() {
      return this.$slots.hasOwnProperty('headerTitle') || this.$slots.hasOwnProperty('headerGroup') || this.$slots.hasOwnProperty('queryList') || this.$slots.hasOwnProperty('queryName');
    },
    // 是否显示pcth-title-box
    showTitleBox() {
      return this.$slots.hasOwnProperty('headerTitle') || this.$slots.hasOwnProperty('headerGroup');
    },
    // 是否显示pcth-query-box
    showQueryBox() {
      return this.$slots.hasOwnProperty('queryList') || this.$slots.hasOwnProperty('queryName');
    }
  },

  methods: {}
};
</script>

<style lang="scss">
@import '../../var.scss';

.poc-card-table {
  width: 100%;

  .pcth-title-box {
    display: flex;
    justify-content: space-between;
    line-height: 28px;
  }

  .pcth-title-left: {
    display: flex;
    align-items: center;
  }

  .pcth-query-box {
    // margin-top: $MP16;
    display: flex;
    justify-content: space-between;

    .pcth-queryList {
      flex: 1;
    }

    .pcth-queryName {
      min-width: 200px;
    }
  }
}
</style>
