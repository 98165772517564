<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/auth-management/consumptionSum' }">运营管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/auth-management/ConsumptionSum' }">消耗汇总</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/auth-management/MaterialProduct' }">素材管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
          <div class="materialHead">
            <div class="materialHBtns">
              <el-form :inline="true" :model="materiaForm" ref="materiaForm" class="demo-form-inline">
                <el-form-item label="" prop="mType">
                  <el-select v-model="materiaForm.mType" filterable clearable placeholder="请选择账户名称">
                    <el-option
                      v-for="(item,i) in materiaTypeOptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="mPosition">
                  <el-select v-model="materiaForm.mPosition" filterable clearable placeholder="请选择运营人员">
                    <el-option
                      v-for="(item,i) in materiaPositionOptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
                  <el-button type="info" plain size="small" @click="resetForm('materiaForm')">重置</el-button>
                  <el-button type="primary" size="small" @click="goback">返回</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="materiaContent">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                prop="name"
                label="渠道名称"
                width="120">
                <template slot-scope="scope">{{ scope.row.channelName }}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="账户名称"
                width="200">
                <template slot-scope="scope">{{ scope.row.accountName }}</template>
              </el-table-column>
              <el-table-column 
                prop="name"
                label="账户ID"
                width="200">
                <template slot-scope="scope">{{ scope.row.accountId }}</template>
              </el-table-column>
              <el-table-column
                label="运营"
                width="200">
                <template slot-scope="scope">{{ scope.row.userName }}</template>
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button class="newBtn_Yellow" plain size="mini" @click="editProduct(scope.row)">修改</el-button>
                  <!-- <el-button type="text" @click="delete(scope.row.accountId)">删除</el-button> -->
                  <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deleteProduct(scope.row.accountId)">
                    <template #reference>
                      <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
            <el-dialog title="修改客户名称" :visible.sync="dialogFormVisible" width="40%">
              <el-form :model="form">
                <el-form-item label="" >
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="form.region" filterable placeholder="请选择账户名称" @change="changeProduct($event)">
                    <el-option
                      v-for="(item,i) in productOptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSure">确 定</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="footerPaging">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.totalRow">
          </el-pagination>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'materiaList',
  data() {
    return {
      materiaChannelOptions: [{//头部渠道搜索选项
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: 'VIVO'
      }, {
        value: '选项3',
        label: '快手'
      }, {
        value: '选项4',
        label: '今日头条'
      }],
      materiaForm:{//头部查询表单的prop
        mStatus:'',
        mType:'',
        mPosition:''
      },
      materiaTypeOptions: [],
      materiaPositionOptions:[],
      tableData: [],
      multipleSelection: [],
      detailId:'',
      aboutIdParams:'',
      channelIdParams:'',
      typeParams:'',
      pageSizeParams:'',
      pageNumParams:'',
      // 分页
      pageNum: 1,//当前页
      pageSize: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dialogFormVisible:false,
      form:{
        name:'',
        region:''
      },
      productOptions:[],
      productParams:{
        aboutId:'',
        aboutName:'',
        accountIdString:'',
        type:'1'
      }




    }
  },
  computed: {
     
  },
  mounted() {
    this.getId()
    this.getProductList()
    this.getStaffDetail()
    this.getAccountList()
  },
  methods: {
    //头部表单重置
    resetForm(materiaForm) {
      this.$refs[materiaForm].resetFields();
      
    },
    //点击查询按钮
    onSubmit() {
      console.log('submit!');
    },
    //打开新建标签弹框
    addNewTab() {
//       this.$refs.materiaDialog.openDialog(false)   
    },

    getId(){
      this.detailId = this.$route.params && this.$route.params.id
      console.log(this.detailId)
    },

     //获取账户名称下拉列表
     getAccountList(){
      let that = this
      // that.userIdAccount = that.$sessionStorage.getItem(config.USERID) || 1
      $http.axios.get("/api/channel/accountUser/selectList?userId="+that.detailId).then(function(response) {
        that.materiaTypeOptions = response.data
      })
    },

    getProductList(){
      let that = this
      that.aboutIdParams = String(that.detailId)
      that.channelIdParams = '2'
      that.typeParams = '1'
      console.log(typeof that.typeParams)
      $http.axios.get("/api/channel/account/mtAccountList?aboutId="+that.aboutIdParams+"&channelId="+that.channelIdParams+"&type="+that.typeParams+"&pageSize="+that.pageSize+"&pageNum="+that.pageNum).then(function(response) {
        if(response.data.code == 200){
          console.log(response)
          that.tableData = response.data.rows
          that.totalRow = response.data.total
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取人员信息
    getStaffDetail(){
      let that = this
      $http.axios.get("/api/system/user/optionselect").then(function(response) {
        that.materiaPositionOptions = response.data
      })
    },

    //客户名称下拉框
    getProduct(){
      // let that = this
      // $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
      //   if(response.data.code == 200){
      //     that.productOptions = response.data.data
      //   } else {
      //     that.$message({
      //       showClose: true,
      //       message: response.data.msg,
      //       type: 'error'
      //     })
      //   }
      // })
      let that = this
      // that.userIdAccount = that.$sessionStorage.getItem(config.USERID) || 1
      $http.axios.get("/api/channel/accountUser/selectList?userId="+that.detailId).then(function(response) {
        that.productOptions = response.data
      })
    },

    //获取选中客户名称id
    changeProduct(event){
      this.productParams.accountIdString = String(event)
    },

     //修改
     editProduct(row){
      let that = this
      that.dialogFormVisible = true
      that.getProduct()
      console.log(row)
      that.productParams.aboutId = String(row.accountId)
      that.productParams.aboutName = String(row.accountName)
      that.form.name = row.accountName
    },

    //确定修改
    editSure(){
      let that = this 
      $http.axios.get("/api/channel/account/updateMtAccount?aboutId="+that.productParams.aboutId+"&aboutName="+that.productParams.aboutName+"&accountIdString="+that.productParams.accountIdString+"&type="+that.productParams.type).then(function(response) {
        if(response.data.code == 200){
          console.log(response)
          that.getProductList()
          that.dialogFormVisible = false
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //删除
    deleteProduct(e){
      let that = this
      $http.axios.get("/api/channel/accountUser/delAccountUser?accountId="+e).then(function(response) {
        console.log(response)
        that.getProductList()
      })
    },

    goback(){
      this.$router.back()
    },

     // 分页
     handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getProductList()
    },



    //表格中选中和未选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
}
</script>

<style lang="scss" scoped>
  .materiaContent{
    margin-top:25px;
  }
  .materialTab{
    font-size:14px;
    color:#6e7c7c;
    margin-bottom:20px;
  }
  .materialTabCurrent{
    color:#333;
    font-size:16px;
  }
  .materialTab span{
    display:inline-block;
    vertical-align:middle;
    margin-right:10px;
  }

</style>
