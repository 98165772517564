<template>
  <el-dialog
    :visible.sync="show"
    width="600px"
    :close-on-click-modal="false"
    @close = "onCancel"
    center
    :show-close = "false"
    class="account-detail-dialog"
    :title="dialogTitle">
    <div class="dialogIcon"><img :src='accountCost'></div>
    <el-form
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData">

      <div v-if="checked == true&&dayType!=1">
        <el-form-item label="" prop="" >
          <el-select  clearable placeholder="选择调价类型" v-model="dayLimitV"  @change="changeDayLimit">
            <el-option
              v-for="(item,i) in dayLimitList"
              :key="i"
              :label="item.text"
              :value="item.id"
              :disabled = "limitIs&&item.id=='1'">
            </el-option>
          </el-select>
        </el-form-item>   
        <el-form-item label="" prop="" v-show="dayLimitV == '1'" >
          <el-select  clearable placeholder="选择调价方式" v-model="dayLimitF" @change = "numIn">
            <el-option
              v-for="(item,i) in dayLimitFnList"
              :key="i"
              :label="item.text"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>   
        <el-form-item :rules="dayLimitV == '1'?numberlimit:numberlimitJ" prop="scan_limit">
          <el-input clearable v-model="formData.scan_limit" placeholder="请输入数值" @blur="numIn"></el-input>
        </el-form-item>
        <el-button type="primary" size="small" @click="yulanFn" >预览</el-button>
        <div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-show="tableYL">
            <el-table-column
              align='center'
              v-for="(item,index) in dayLimitTitleList[dayType]" :key="index"
              :prop="item.prop"
              :label="item.name"
              show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="nowIs&&checked==true&&dayType!=1">
        <span>当日是否继续执行</span>
        <el-switch
          v-model="nowIsValue"
          active-value="0"  
          inactive-value="1"
          active-color="#48916F"
          inactive-color="#EAEFED">
        </el-switch>
      </div>

      <div class="dailyLimit">
        <!-- 批量日限额 -->
        <div v-if="checked==true&&dayType==1">
          <el-form-item :rules="!dayDisabled?numberlimitM:[{required:false}]" class="editInput" prop="accDayBudget">
            <el-input 
              v-model.trim = "formData.accDayBudget"
              placeholder="请输入日预算"   
              :disabled = dayDisabled
              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
              clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="dayDisabled" @change = "changeDay">不限</el-checkbox>
          </el-form-item> 
          <div v-if="nowIs&&checked==true">
            <span>当日是否继续执行</span>
            <el-switch
              v-model="accountNowIsValue"
              active-color="#48916F"
              inactive-color="#EAEFED"
              active-value="0"  
              inactive-value="1">
            </el-switch>
          </div> 
        </div>

        <el-checkbox v-model="checked" @change="nowLimitFn(checked)">立即执行</el-checkbox>
        <el-form-item label="开始（结束）时间" label-width="120px" v-if="checked == false" class="dateConent">
          <div>
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              style="width:384px;"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <div v-if="checked == false"  >
          <!-- <el-button type="primary"  @click="addTimeFn">新增</el-button> -->
          <div v-for="(item,i) in timeList" class="addList" :key="i">
            <el-form-item label="时间点">
              <el-select  clearable  placeholder="选择时间段" v-model="item.executeHourt"  @change="changeDayLimit($event,'addTime',i)">
                <el-option
                  v-for="(item1,j) in timeLimitList"
                  :key="j"
                  :label="item1.time"
                  :value="item1.time"
                  :disabled = item1.timeLimitIs>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  v-if="checked == false"  lable='目标价格'>
              <el-input clearable oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="item.newPrice" placeholder="请输入目标金额" ></el-input>
            </el-form-item>

            <!-- <el-button type="primary" v-if="i>0" @click="deleTimeFn(i,item.executeHourt)">删除</el-button> -->
            <div class="deleteBtn" v-if="i>0" @click="deleTimeFn(i,item.executeHourt)">
              <i class="el-icon-error"></i>
            </div>
          </div>
          <div class="costAdd"  @click="addTimeFn"><i class="el-icon-plus"></i>新增时间段</div>
          <!-- <el-button type="primary"  @click="addTimeFn"><i class="el-icon-plus"></i>新增时间段</el-button> -->
        </div>
      </div>

    </el-form>

    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button><!--{{$t('dc:取消')}}-->
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
    
  </el-dialog>
</template>

<script>
  import accountCost from '@/assets/img/accountCost.png';
  import { $http } from '@/api/http';
  import TimeUnit from '@/views/auth/TimeUnit'
  import Qs from 'qs';
export default {
components:{
    // TimeUnit
  },  
  name: "dayLimitDialog",

  props: {
    pubVue: {
      type: Object
    },
    page:Number,
    nowIs:Boolean,
    dialogTitle:String,
    dayType:Number,
    groupsIds:Array,
    visible: {
      type: Boolean,
      default: false
    },
    limitIs:Boolean,
    yulanList:Array,
    // type: Number,
    // ownerId:Number,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      accountCost,
      formData: {
        scan_limit:'',
        upDown:'',
        limitTop:'',
        limitD:'',
        time_limit:'',
        accDayBudget:'',//设置日预算
        },
      rules: {
      
      minNum: [
        { required: true, message: '请输入最低值', trigger: 'blur' }
      ]},
      dayLimitList:[{'text':'具体值','id':0},{'text':'百分比','id':1}],
      // dayLimitList:[{'text':'具体值','id':0}],
      dayLimitFnList:[{'text':'上调','id':0},{'text':'下调','id':1}],
      show: this.visible,
      timeLimitIs:false,//是否可选
      loading: false,
      dayLimitV:'',
      dayLimitF:'',
      timeLimitV:[],
      numberlimit: { validator: this.checkMoney, trigger: 'blur'},
      numberlimitJ: { validator: this.checkMoneyJ, trigger: 'blur'},

      numberlimitM: [{validator: this.checkMoneyM},
                    {required:true,  trigger: 'blur' }],
      // datelimitM: { validator: this.datelimitM, trigger: 'blur' },
      // limitTop:'',//上限值
      // limitD:'',//下限值
      checked:true,//立即执行
      dayDisabled:true,//日限额是否为不限
      value1:[],
      dayLimitTitleList:{1:[{name:'账户名称',prop:'ownerName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      2:[{name:'广告名称',prop:'planName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      3:[{name:'广告组名称',prop:'adGroupName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      4:[{name:'广告组名称',prop:'adGroupName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],},
      timeList:[
        {
          'executeHourt':'',
          'newPrice':''

        }
      ],
      timeLimitList:[{'time':'00:00','timeLimitIs':false,'id':'0'},{'time':'00:30','timeLimitIs':false,'id':'1'},{'time':'01:00','timeLimitIs':false,'id':'2'},{'time':'01:30','timeLimitIs':false,'id':'3'},{'time':'02:00','timeLimitIs':false,'id':'4'},{'time':'02:30','timeLimitIs':false,'id':'5'},{'time':'03:00','timeLimitIs':false,'id':'6'},{'time':'03:30','timeLimitIs':false,'id':'7'},
      {'time':'04:00','timeLimitIs':false,'id':'8'},{'time':'04:30','timeLimitIs':false,'id':'9'},{'time':'05:00','timeLimitIs':false,'id':'10'},{'time':'05:30','timeLimitIs':false,'id':'11'},{'time':'06:00','timeLimitIs':false,'id':'12'},{'time':'06:30','timeLimitIs':false,'id':'13'},{'time':'07:00','timeLimitIs':false,'id':'14'},{'time':'07:30','timeLimitIs':false,'id':'15'},
      {'time':'08:00','timeLimitIs':false,'id':'16'},{'time':'08:30','timeLimitIs':false,'id':'17'},{'time':'09:00','timeLimitIs':false,'id':'18'},{'time':'09:30','timeLimitIs':false,'id':'19'},{'time':'10:00','timeLimitIs':false,'id':'20'},{'time':'10:30','timeLimitIs':false,'id':'21'},{'time':'11:00','timeLimitIs':false,'id':'22'},{'time':'11:30','timeLimitIs':false,'id':'23'},
      {'time':'12:00','timeLimitIs':false,'id':'24'},{'time':'12:30','timeLimitIs':false,'id':'25'},{'time':'13:00','timeLimitIs':false,'id':'26'},{'time':'13:30','timeLimitIs':false,'id':'27'},{'time':'14:00','timeLimitIs':false,'id':'28'},{'time':'14:30','timeLimitIs':false,'id':'29'},{'time':'15:00','timeLimitIs':false,'id':'30'},{'time':'15:30','timeLimitIs':false,'id':'31'},
      {'time':'16:00','timeLimitIs':false,'id':'32'},{'time':'16:30','timeLimitIs':false,'id':'33'},{'time':'17:00','timeLimitIs':false,'id':'34'},{'time':'17:30','timeLimitIs':false,'id':'35'},{'time':'18:00','timeLimitIs':false,'id':'36'},{'time':'18:30','timeLimitIs':false,'id':'37'},{'time':'19:00','timeLimitIs':false,'id':'38'},{'time':'19:30','timeLimitIs':false,'id':'39'},
      {'time':'20:00','timeLimitIs':false,'id':'40'},{'time':'20:30','timeLimitIs':false,'id':'41'},{'time':'21:00','timeLimitIs':false,'id':'42'},{'time':'21:30','timeLimitIs':false,'id':'43'},{'time':'22:00','timeLimitIs':false,'id':'44'},{'time':'22:30','timeLimitIs':false,'id':'45'},{'time':'23:00','timeLimitIs':false,'id':'46'},{'time':'23:30','timeLimitIs':false,'id':'47'}],

      // timeLimitList:[{'time':'00:00','timeLimitIs':false,'id':'0'},{'time':'00:30','timeLimitIs':false,'id':'1'},{'time':'01:00','timeLimitIs':false,'id':'2'},{'time':'01:30','timeLimitIs':false,'id':'3'},{'time':'02:00','timeLimitIs':false,'id':'4'},{'time':'02:30','timeLimitIs':false,'id':'5'},{'time':'03:00','timeLimitIs':false,'id':'6'},{'time':'03:30','timeLimitIs':false,'id':'7'},
      // {'time':'04:00','timeLimitIs':false,'id':'8'}],


// tableData:[{"planName":null,
//         "lx":null,
//         "fs":null,
//         "num":"412.00",
//         "dayBudget":null,
//         "sendPrice":"412412"}],
      tableData:[],
      changeDayLimitArr:[],
      numInArr:[],
      tableYL:false,
      cjLimit:{1:'预算限制',2:'预算限制',3:'出价限制'},
      nowIsValue:'0',
      accountNowIsValue:'0',
      choosedTime:[],
      timeEvent:'',
      dayDisabled:true,//日预算是否可操作
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
     this.pubVue.$on('SHOW_DIALOG', data => {
      console.log('方法监听', data)
      this.show = data.showDialog
    })
    console.log(this.dayType)
    console.log(this.checked)
  },
  methods: {
    changeDay(event){
      console.log(event)
        this.dayDisabled = event
        

        if(event){
          this.formData.accDayBudget = ''
      }

    },
    yulanFn(){
      let _this = this

      if(_this.tableYL){
          _this.tableYL= false
      } else{
        _this.tableYL= true
        _this.showYu()
      }
    },
    showYu(){
      let _this = this
      console.log(this.dayType)
      _this.tableData = []
      _this.yulanList.forEach((obj,i)=>{
       _this.tableData.push(Object.assign({},obj,_this.changeDayLimitArr[i],_this.numInArr[i]))
      })
      console.log(_this.tableData)
    },
    nowLimitFn(checked){
        // this.nowIs = checked
        // this.pubVue.nowIs = checked
              console.log(checked)

    },
    deleTimeFn(i,time){
      if (i >= 0) {
          this.timeList.splice(i, 1);
          this.choosedTime.splice(i, 1);
          this.timeLimitList.forEach(obj=>{
            if(obj.time == time){
              obj.timeLimitIs = false
            }
          })
          // this.timeLimitList[]
          
          // this.formData[i] = []
        }
    },
    addTimeFn(){
      let _this = this
      _this.timeList.push({})
      console.log(this.timeLimitV)
      // _this.choosedTime.push(_this.timeEvent)
      console.log(_this.choosedTime)
      _this.choosedTime.forEach(obj=>{
      // _this.timeLimitList.forEach((ele,i,arr)=>{
        _this.timeLimitList[obj].timeLimitIs = true
      // })
      })      
      
    },
    checkMoney (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        // if (!/(^[1-9]\d*$)/.test(value)) {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (value > 100) {
            callback(new Error('不能超过100'))
          } else {
            callback()
          }
        }
      }, 1000)
    },
    checkMoneyJ (rule, value, callback)  {
      let _this = this

        if (!value) {
            return callback(new Error('不能为空'))
        }else if(_this.dayType == 2||_this.dayType == 3){
          if( value<200){
            return callback(new Error('不能小于200'))
          }
        }else if(_this.dayType == 1){
          if( value<1000){
            return callback(new Error('不能小于1000'))
          }
        }
        const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
        setTimeout(() => {
          if (!mailReg.test(value)) {
            callback(new Error('请输入数字值'))
          } else {
            callback()
          }
        }, 1000)
    },
    checkMoneyM (rule, value, callback)  {
      let _this = this
      if(!_this.dayDisabled){

        if (!value) {
            return callback(new Error('不能为空'))
        }else if(_this.dayType == 2||_this.dayType == 3){
          if( value<200){
            return callback(new Error('不能小于200'))
          }
        }else if(_this.dayType == 1){
          if( value<1000){
            return callback(new Error('不能小于1000'))
          }
        }
        const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
        setTimeout(() => {
          if (!mailReg.test(value)) {
            callback(new Error('请输入数字值'))
          } else {
            callback()
          }
        }, 1000)
      }else{
        callback()
      }

    },
    datelimitM(rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
    },
     // 设置账户日预算
    setAccDayBudgetFn(){
      let _this = this,accDayBudget = this.formData.accDayBudget==''?-1: parseInt(this.formData.accDayBudget),params,msg = '',advertiserIds = []
      _this.yulanList.forEach(obj=>{
        advertiserIds.push(obj.ownerId)
      })
      if(!Boolean(Number(_this.accountNowIsValue))){
        params ={'advertiserIds':advertiserIds.toString(),'dailyBudget':accDayBudget}
      }else{
        params={'advertiserIds':advertiserIds.toString(),'dailyBudget':accDayBudget,'extendFlag':1}
      }
      
        $http.axios.post("/api/vivo/advertiser/editDailyBudgetBatch",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code!=200){
            if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ownerId
                msg+=obj.msg
              })
               _this.$message({
                showClose: true,
                message:msg,
                type: 'error'
              })
            }else{
             _this.$message({
              showClose: true,
              message: res.data.msg,
              type:  'error'
            }) 
            }
          }else{
            _this.show = false
            _this.$message({
                showClose: true,
                message:res.data.msg,
                type: 'success'
              })
            _this.pubVue.oppoAccountList(_this.page)

          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
          
    },
    changeDayLimit(event,type,index){
      let _this = this,num
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
        //获取对象在数组的索引
          num = _this.timeLimitList.indexOf(_this.timeLimitList.filter(d => d.time ===event)[0])
          _this.choosedTime[index] = num
          _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
             
            }
             _this.choosedTime.forEach(obj=>{
              _this.timeLimitList[obj].timeLimitIs = true
            })
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
     changeDayLimitFn(event,type,index){
      let _this = this
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
           _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
              _this.choosedTime.forEach(obj=>{
                _this.timeLimitList[obj].timeLimitIs = true
              })
            }
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
    numIn(){
       let _this = this,val = _this.formData.scan_limit
        _this.numInArr = []
      console.log(666,_this.formData.scan_limit)
      _this.yulanList.forEach((obj,i)=>{
        if(_this.dayLimitV == '1'){
          if(_this.dayLimitF == '0'){
            val = (Number(obj.price)*(1+Number(_this.formData.scan_limit)/100)).toFixed(2)
              _this.numInArr.push(Object.assign({},{num:_this.formData.scan_limit,sendPrice:val,fs:'上调'}))

          }else if(_this.dayLimitF == '1'){
              val = (Number(obj.price)*(1-Number(_this.formData.scan_limit)/100)).toFixed(2)

              _this.numInArr.push(Object.assign({},{num:_this.formData.scan_limit,sendPrice:val,fs:'下调'}))

          }
        }else{
          _this.numInArr.push(Object.assign({},{num:val,sendPrice:val}))
        }
      })
      console.log(_this.tableData)
      _this.showYu()
    },
    onConfirm(formName) {
      let _this = this,type
       _this.$refs[formName].validate((valid) => {
          if (valid) {
            // _this.tableData.forEach(obj=>{
                // if(Number(obj.num)>=Number(_this.limitD)&&Number(_this.limitTop)>=Number(obj.num)){
                  console.log('成功2')
                  if(_this.checked){
                    console.log('选中')
                    if(_this.dayType ==1){
                      _this.setAccDayBudgetFn()
                    }else if(_this.dayType == 2){
                      _this.updateOppoDayBudgetThreeFn()
                    }else if(_this.dayType == 3){
                      _this.editDailyBudgetBatchFn()
                    }else if(_this.dayType == 4){
                      _this.editPriceBatchFn()
                    }
                  }else{
                    console.log('未选中')
                    if(_this.value1 != []){
                      
                     if(_this.dayType == 1){
                      type = 1
                     }else if(_this.dayType == 2){
                      type = 2
                     }else if(_this.dayType == 3){
                        if(_this.dialogTitle == '批量调价'){
                          type = 4
                        }else{
                          type = 3
                        }
                      }
                      if(_this.value1.length == 0){
                         _this.$message({
                            showClose: true,
                            message: '启动日期不能为空',
                            type: 'error'
                          })
                          return false
                      }





                      let flag = _this.timeList.length!=0
                      flag = _this.timeList.every(obj=>{
                        if(obj.executeHourt==''||obj.executeHourt==undefined){
                           _this.$message({
                            showClose: true,
                            message: '请选择时间段！',
                            type: 'error'
                          })
                          return false
                        }else if(obj.newPrice==''||obj.newPrice==undefined){
                          _this.$message({
                            showClose: true,
                            message: '请输入目标金额！',
                            type: 'error'
                          })
                          return false
                        }else if(type == 2&&obj.newPrice<200){
                          _this.$message({
                            showClose: true,
                            message: '输入目标金额不能小于200！',
                            type: 'error'
                          })
                          return false
                        }else if(type == 1&&obj.newPrice<1000){
                          _this.$message({
                            showClose: true,
                            message: '输入目标金额不能小于1000！',
                            type: 'error'
                          })
                          return false
                        }else{
                          return true
                        }
                      })
                      if(flag){
                          _this.addGroupModifyPriceSetFn(type)
                      }
                    }else{
                      _this.$message({
                        showClose: true,
                        message: "请选择日期",
                        type: 'error'
                      })
                    }
                  }
                // }else{
                //   _this.$message({
                //     showClose: true,
                //     message: "超出日限额限制",
                //     type: 'error'
                //   })
                // }
            // })
          } else {

            console.log('error submit!!');
            return false;
          }
        });
      
    },
    onCancel() {
      console.log(this.checked)
      this.show= false;
      this.tableYL= false;
      this.nowIsValue = '0'
      this.accountNowIsValue = '0'
      this.formData.scan_limit = ''
      this.dayLimitF = ''
      this.dayLimitV = ''
      this.tableData = []
      this.changeDayLimitArr =[]
      this.numInArr = []
      this.value1 = []
      this.formData.limitTop = ''
      this.formData.limitD = ''
      this.formData.accDayBudget = ''
      if(this.dayType == 1){
        this.checked = false
      }else{
        this.checked = true
      }
      // this.checked = true
      this.timeList=[
        {
          'executeHourt':'',
          'newPrice':''

        }
      ],
      this.pubVue.$refs.multipleTable.clearSelection();
      this.$refs.formData.clearValidate();
      // this.pubVue.grouplistFn()
      console.log('关闭')
      this.timeLimitList.forEach(obj=>{
        obj.timeLimitIs = false
      })
      console.log(this.checked)
    },
    addOrSetFn(type){
      
    },
    //保存-未立即执行接口	
      // {
      //   "userId": 0,
      //   "userName": "",
      //   "deptId": 0,
      //   "ownerIds": "",
      //   "ownerNames": "",
      //   "startDate": "",
      //   "endDate": "",
      //   "details": [
      //     {
      //       "executeHour": "",
      //       "price": 0
      //     }
      //   ],
      //   "type": 0,
      //   "infos": [
      //     {
      //       "adPlanId": 0,
      //       "adPlanName": "",
      //       "adGroupId": 0,
      //       "adGroupName": ""
      //     }
      //   ]
      // }
      //添加调价计划  1-账户日预算 2-计划日预算 3-广告组日预算 4-广告组基础出价
    addGroupModifyPriceSetFn(type){
    // /adPlan/v1/updateOppoDayBudgetThree
    let _this = this,params1 = {},params = [],infos = [],ownerIdsList =[],ownerNamesList =[],userContent={}
    console.log(_this.tableData)
    let timeString = _this.timeList
    let addOpenClosePlanDetailDtoList = []
      timeString.forEach(function(e,index){
        if(e!=''){
          addOpenClosePlanDetailDtoList.push(
            {executeHour:e.executeHourt,price:Number(e.newPrice)}
          )
        }
      })
      _this.tableData.forEach(obj=>{
          ownerIdsList.push(obj.ownerId)
          ownerNamesList.push(obj.ownerName)
        })

      params1 = {
        "ownerIds": ownerIdsList.join(','),
        "ownerNames": ownerNamesList.join(','),
        "startDate": _this.value1[0],
        "endDate": _this.value1[1]?_this.value1[1]:'2121-01-01',
        "details": addOpenClosePlanDetailDtoList,
        
      } 
      userContent = {'deptId':_this.userContent.deptId,
        'userId':_this.userContent.userId,
        'userName':_this.userContent.userName
      }     
      if(type == 1){
               
        params = Object.assign({},userContent,params1,{"type":1})
        
      }else if(type == 2){
        _this.yulanList.forEach((obj,i)=>{
          infos.push({'adPlanId':obj.planId,'adPlanName':obj.planName})
        })
        params1.ownerIds = _this.userContent.ownerId
        params1.ownerNames = _this.userContent.ownerName
          
        // params = Object.assign({},_this.clone(_this.params), params1)
        params = Object.assign({},userContent,params1,{"type":2,"infos":infos})

      }else if(type == 3){
        _this.tableData.forEach((obj,i)=>{
          infos.push({'adPlanId':obj.planId,'adPlanName':obj.planName,"adGroupId":obj.adGroupId,"adGroupName":obj.adGroupName})
        })
        params1.ownerIds = _this.userContent.ownerId
        params1.ownerNames = _this.userContent.ownerName

        // params = Object.assign({},_this.clone(_this.params), params1)
        params = Object.assign({},userContent,params1,{"type":3,"infos":infos})
      }else if(type == 4){
        _this.tableData.forEach((obj,i)=>{
          infos.push({'adPlanId':obj.planId,'adPlanName':obj.planName,"adGroupId":obj.adGroupId,"adGroupName":obj.adGroupName})
        })
        params1.ownerIds = _this.userContent.ownerId
        params1.ownerNames = _this.userContent.ownerName

        // params = Object.assign({},_this.clone(_this.params), params1)
        params = Object.assign({},userContent,params1,{"type":4,"infos":infos})
      }
      
    // addPlanDailyMoneySet
    $http.axios.post("/api/vivo/modifyPrice/add",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.pubVue.adPlanIdList = []
          if(_this.dayType == 1){
            setTimeout(function(){
            _this.pubVue.oppoAccountList(_this.page)
            },100)
          }else if(_this.dayType == 2){
            setTimeout(function(){
            _this.pubVue.getAccountPlanListFn(_this.page)
            },100)
          }else if(_this.dayType == 3){
            setTimeout(function(){
            _this.pubVue.grouplistFn(_this.page)
            },100)
          }
           _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
          _this.onCancel();
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    //批量修改广告组出价
    editPriceBatchFn(){
     let _this = this,params = {},ids = [],lists = [],msg = ''
      console.log(_this.tableData)
      _this.tableData.forEach((obj,i)=>{
        // ids.push(obj.adGroupId)
        if(Boolean(Number(_this.nowIsValue))&&_this.nowIs){
          lists.push({'price':Number(obj.sendPrice),
            'ids':obj.adGroupId,
            'extendFlag':1,})//设置排除日期 1-是
          }else{
              lists.push({'price':Number(obj.sendPrice),
            'ids':obj.adGroupId})
          }
          console.log(_this.numInArr)
        // if(limitIs){

        // }
      })
      params = {'advertiserId':_this.userContent.ownerId,
                'params':JSON.stringify(lists)}
      $http.axios.post("/api/vivo/advertisingGroup/editPriceBatch",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
            setTimeout(function(){
              _this.pubVue.grouplistFn(_this.page)
            },100)
            _this.$message({
                showClose: true,
                message:res.data.msg,
                type: 'success'
              })
          _this.pubVue.adPlanIdList = []
        }else{
          if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ids
                msg+=obj.msg
              })
               _this.$message({
                showClose: true,
                message:msg,
                type: 'error'
              })
            }
        }
        _this.onCancel();
      }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
     //保存-立即执行接口	
                  // "advertiserId": "",广告主ID
                  // "dailyBudget": 0,计划日限额:-1-不限额 单位元
                  // "ids": 0,计划编号Ids,逗号分割
                  // "existPlanFlag": [],设置排除日期
    //修改计划日限额
    updateOppoDayBudgetThreeFn(){
    // /adPlan/v1/updateOppoDayBudgetThree
    // let _this = this,params = {},ids = []
    // // console.log(_this.tableData)
    // // _this.tableData.forEach((obj,i)=>{
    // //   ids.push(obj.planId)
    // // })
    // // params = new URLSearchParams()
    // //   params.append(
    // //   'advertiserId',_this.userContent.ownerId,
    // //   )
    // // params.append(
    // //   'dailyBudget',Number(_this.formData.scan_limit))
    // //  params.append(
      
    // //   'ids',ids.join(",")) 
    // //   params.append(
      
    // //   'extendFlag','',//设置排除日期 1-是
    // // )

    let _this = this,params = {},ids = [],lists = [],msg = ''
     
    _this.tableData.forEach((obj,i)=>{
      // ids.push(obj.adGroupId)
      if(Boolean(Number(_this.nowIsValue))&&_this.nowIs){
        lists.push({'price':Number(obj.sendPrice),
          'id':obj.planId,
          'extendFlag':1,})//设置排除日期 1-是
        }else{
            lists.push({'price':Number(obj.sendPrice),
          'id':obj.planId})
        }
        console.log(_this.numInArr)
      // if(limitIs){

      // }
    })
    params = {'advertiserId':_this.userContent.ownerId,
                    'params':JSON.stringify(lists)}
    $http.axios.post("/api/vivo/advertisingPlan/editDailyBudgetBatch",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
           _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
            setTimeout(function(){
              _this.pubVue.getAccountPlanListFn(_this.page)
            },100)
          
          _this.pubVue.adPlanIdList = []
          
        }else{
          if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ids
                msg+=obj.msg
              })
               _this.$message({
                showClose: true,
                message:msg,
                type: 'error'
              })
            }
        }
        _this.onCancel();
      }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    //保存-立即执行接口"adGroupId": 0,
                    // "adGroupName": "",
                    // "ownerId": 0,
                    // "price": 0,
                    // "userId": 0,
                    // "userName": ""
    //批量-修改广告组日限额
   editDailyBudgetBatchFn(){
    //  /adPlan/v1/addPlanDailyMoneySet
    let _this = this,params = {},ids = [],lists = [],msg = ''
    console.log(_this.tableData)

    _this.tableData.forEach((obj,i)=>{
      // ids.push(obj.adGroupId)
      if(Boolean(Number(_this.nowIsValue))&&_this.nowIs){
        lists.push({'price':Number(obj.sendPrice),
          'ids':obj.planId,
          'extendFlag':1,})//设置排除日期 1-是
        }else{
            lists.push({'price':Number(obj.sendPrice),
          'ids':obj.planId})
        }
        console.log(_this.numInArr)
      // if(limitIs){

      // }
    })


    params = {'advertiserId':_this.userContent.ownerId,
                    'params':JSON.stringify(lists)}
    $http.axios.post("/api/vivo/advertisingGroup/editDailyBudgetBatch",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      if(res.data.code==200){
        _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
          setTimeout(function(){
            _this.pubVue.grouplistFn(_this.page)
          },100)
      }else{
        if(res.data.data!=null){
          res.data.data.forEach(obj=>{
            msg+=obj.ids
            msg+=obj.msg
          })
            
        }else{
          msg = res.data.msg
        }
        _this.$message({
            showClose: true,
            message:msg,
            type: 'error'
          })
      }
      _this.onCancel()
    }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
   },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}

.el-form--inline .el-form-item.priceLimt{
  display: block;
}
.priceLimt i{
  color:red;
}
.el-form--inline .el-form-item.dateConent{
  display: block;
  margin: 15px 0;
}
#app .el-dialog__body .el-form.el-form--inline{
  text-align: left!important;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
::v-deep .editInput .el-input{
  width:400px;
}
.costAdd{
  width:100%;
  height:26px;
  line-height:26px;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  color:#48916F;
  font-size:12px;
  text-align:center;
  cursor: pointer;
}
.addList{
  position: relative;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  margin:10px 0;
  padding:10px;
  box-sizing: border-box;
}
.deleteBtn{
  position: absolute;
  font-size:18px;
  top:-13px;
  right:-11px;
  color:rgb(212, 24, 24);
  cursor: pointer;
}
::v-deep .addList .el-form-item--mini.el-form-item{
  margin:0 5px;
}
::v-deep .addList .el-input--mini .el-input__inner{
  width:210px!important;
}

</style>

