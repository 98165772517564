<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>计划管理</el-breadcrumb-item>
          <el-breadcrumb-item>OPPO</el-breadcrumb-item>
          <el-breadcrumb-item>渠道</el-breadcrumb-item>
          <el-breadcrumb-item>启停展示</el-breadcrumb-item>
          <el-breadcrumb-item>创建启停</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="setPrice">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                    <span class="baseTitle">基础信息</span>
                    <el-form-item label="账户ID" prop="customerName">
                        <el-input v-model="ruleForm.customerName" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="计划名称" prop="planName">
                        <el-input v-model="ruleForm.planName" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="广告组名称" prop="adgroup">
                      <el-input v-model="ruleForm.adgroup" placeholder=""></el-input>
                  </el-form-item>
                    <span class="baseTitle">设置启停</span>
                    <el-form-item label="计划启停设置">
                        <el-form-item label="" prop="dateChecked">
                            <el-checkbox v-model="ruleForm.dateChecked" @change="dateSet">设置执行日期</el-checkbox>
                            <div class="setSureDate" v-show="setDatevalueShow">{{setDatevalueString}}</div>   
                        </el-form-item>
                        <el-form-item label="" prop="timeChecked">
                            <el-checkbox v-model="ruleForm.timeChecked">设置执行时间</el-checkbox>
                        </el-form-item>
                    </el-form-item>
                    <!-- <el-form-item label="" prop="timeChecked">
                        <div class="errorTip">
                            <el-checkbox v-model="ruleForm.timeChecked">设置执行时间</el-checkbox>
                        </div>   
                    </el-form-item> -->
                    <el-form-item label="计划执行设置" prop="radio">
                        <el-radio-group v-model="ruleForm.radio">
                            <el-radio
                              v-for="item in radioOptions"
                              :key="item.value"
                              :value="item.value"
                              :label="item.value"
                              @change="changeRadio"
                            >{{item.label}}</el-radio>
                        </el-radio-group>
                        <div class="setSureDate" v-show="setTimevalueShow">{{setTimevalueString}}</div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                        <el-button type="info" plain size="small">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 计划启停的设置执行日期 -->
            <el-dialog title="执行日期" :visible.sync="setDateVisible">
                <el-date-picker
                    v-model="setDatevalue"
                    type="daterange"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="setDateCommit">确 定</el-button>
                    <el-button @click="setDateCancel">取 消</el-button>
                </div>
            </el-dialog>
            <!-- 计划执行设置执行日期 -->
            <el-dialog title="执行日期" :visible.sync="setTimeVisible">
                <el-date-picker
                    v-model="setTimevalue"
                    type="daterange"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="setTimeCommit">确 定</el-button>
                    <el-button @click="setTimeCancel">取 消</el-button>
                </div>
            </el-dialog>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'SetPrice',
    data() {
      return {
        ruleForm: {
          customerName:'',
          planName:'',
          adgroup:'',
            dateChecked:'',//设置执行日期
            timeChecked:'',//设置执行时间
            radio:'',//计划执行设置，单选
        },
        rules: {
            dateChecked:[
                { required: true, message: '请设置执行日期', trigger: 'change' }
            ],
            timeChecked:[
                { required: true, message: '请设置执行时间', trigger: 'change' }
            ],
            radio:[
                { required: true, message: '请选择计划执行设置', trigger: 'change' }
            ],
        },
        radioOptions:[{//单选
            label:'设置执行日期',
            value:'1'
        },{
            label:'立即执行',
            value:'2'
        }],
        setDateVisible:false,//计划启停的设置执行日期
        setDatevalue:'',//计划启停的设置执行日期----日期选择
        setDatevalueString:'',
        setDatevalueShow:false,//计划启停的设置执行日期----日期选择展示
        setTimeVisible:false,//计划执行设置执行日期
        setTimevalue:'',//计划执行设置执行日期----日期选择
        setTimevalueShow:false,//计划执行设置执行日期----日期选择展示
        setTimevalueString:'',
        

        
      }
    },
    computed: {
       
    },
    mounted() {
    },
    methods: {
      submitForm(ruleForm) {
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      //计划启停设置---设置执行日期
      dateSet(val){
        if(val==true){
            this.setDateVisible = true
            this.setDatevalue = ''
        }else{
            this.setDateVisible = false
        }
      },

      //计划启停设置---设置执行日期--取消
      setDateCancel(){
        this.setDateVisible = false
        this.ruleForm.dateChecked = false
      },
      //计划启停设置---设置执行日期--确定
      setDateCommit(){
          if(this.setDatevalue == ''){
            this.$message('请选择日期')
          }else{
            this.setDateVisible = false
            this.setDatevalueShow = true
            this.setDatevalueString = String(this.setDatevalue[0]+'至'+this.setDatevalue[1])
          } 
      },

      //计划执行设置--选择
      changeRadio(val){
        console.log(val)
        if(val == 1){
            this.setTimeVisible = true
            this.setTimevalue = ''
        }else{
            this.setTimevalueShow = false
        }
      },
      //计划执行设置---设置执行日期--取消
      setTimeCancel(){
        this.setTimeVisible = false
        this.ruleForm.radio = ''
      },
      //计划执行设置---设置执行日期--确定
      setTimeCommit(){
        if(this.setTimevalue == ''){
            this.$message('请选择日期')
        }else{
            this.setTimeVisible = false
            this.setTimevalueShow = true
            this.setTimevalueString = String(this.setTimevalue[0]+'至'+this.setTimevalue[1])
        }
      },
      




    }
  }
  </script>
  
  <style lang="scss" scoped>
      .baseTitle{
          font-weight:700;
          display:block;
          font-size:14px;
          margin:10px 0 20px;
      }
      .setPrice .el-input{
          width:260px;
      }
      .errorTip{
          margin-left:150px;
      }
  </style>
