<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="faq">FAQ问题列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <PocCardTable>
        <template slot="headerTitle">
          <!-- 渠道tab -->
          <div class="chanelTab">
            <span class="channel_vivo" :class="item.id==channelId?'channelActive':''" v-for="(item,i) in editableTabs" :key="i" @click="item.id!=channelId&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
          </div>
          <div class="faqWrap">
            <el-container>
              <!-- 板块列表 -->
              <el-aside>
                <el-menu
                  :default-openeds="['1']"
                  :default-active="menuActive"
                  @close="handleClose"
                  ref="menus">
                  <el-submenu index="1">
                    <template slot="title" class="styleWrap">
                      <i class="el-icon-message"></i>
                      <span style="font-weight:700;">功能模块</span>
                      <el-button type="primary" size="small" @click="addType" class="createStyle">新建板块</el-button>
                    </template>
                    <el-menu-item-group v-for="(item,i) in plateTable" :key="i">
                      <el-menu-item class="faqbtnFather" @click="enterTypeList(item.id)" :index="item.id.toString()">
                        <div class="faqbtnleft">
                          <span class="plateName">{{item.name}}</span>
                          <el-tooltip :content="item.desc" placement="top-start" effect="dark" popper-class="atooltip">
                            <span class="plateDesc">{{item.desc}}</span>
                          </el-tooltip>
                        </div>
                        <div class="faqbtn">
                          <el-button type="success" class="el-icon-edit" size="mini" @click="updatesType(item)" circle style="margin-right:5px;"></el-button>
                          <el-popconfirm
                          title="您确定要删除吗？"
                          @confirm="deleteType(item)">
                            <template #reference>
                              <el-button type="danger" class="el-icon-delete" size="mini" circle></el-button>
                            </template>
                          </el-popconfirm>
                        </div>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                </el-menu>
              </el-aside>
              <!-- 列表 -->
              <el-container>             
                <el-main>
                  <div class="createQuestion" >
                    <el-form ref="form" :model="form" :inline="true" class="demo-form-inline" >
                      <el-form-item label="">
                        <el-input v-model="form.keyword" placeholder="请输入关键字" clearable style="width:200px;"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="small" @click="searchKeyword">查询</el-button>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="small" @click="addQuestion">新建问题</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-table
                    :data="tableData"
                    style="width: 100%"
                    :show-header="status"
                    >
                    <el-table-column type="expand">
                      <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                          <el-form-item label="">
                            <p v-html="props.row.answer" style="font-size:16px;"></p>
                          </el-form-item>
                          <el-form-item label="" style="text-align:right;">
                            <div class="personAndTime">
                              <span>创建人：{{props.row.creater}}</span>
                              <span>创建时间：{{props.row.createTime | dateFormat}}</span>
                              <span>更新人：{{props.row.updater}}</span>
                              <span v-if="props.row.updateTime!=null">更新时间：{{props.row.updateTime | dateFormat}}</span>
                              <span v-else>更新时间：{{props.row.updateTime}}</span>
                            </div>
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label=""
                      prop="name">
                      <template slot-scope="props">
                        <span style="font-size:16px;font-weight:700;">{{props.row.question}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label=""
                      prop="name"
                      width="120">
                      <template slot-scope="props">
                        <el-button type="success" size="mini" @click="editQuestion(props.row)">修改</el-button>
                        <el-popconfirm
                          title="您确定要删除吗？"
                          @confirm="deleteQuestion(props.row)">
                            <template #reference>
                              <el-button type="danger" size="mini">删除</el-button>
                            </template>
                        </el-popconfirm>
                      </template>
                    </el-table-column>             
                  </el-table>
                </el-main>
              </el-container>
            </el-container>
            <!-- 新建板块弹框 -->
            <el-dialog title="新建板块" :visible.sync="addTypeVisible" width="30%">
              <el-input v-model="plateName" placeholder="请输入新建板块的名称"></el-input>
              <div style="margin: 20px 0;"></div>
              <el-input type="textarea" v-model="plateDesc" placeholder="请输入新建板块的描述"></el-input>
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAdd">取 消</el-button>
                <el-button type="primary" @click="addPlateList">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 修改板块弹框 -->
            <el-dialog title="修改板块" :visible.sync="updateTypeVisible" width="40%">
              <el-input v-model="editPlateName" placeholder="请输入新建板块的名称"></el-input>
              <div style="margin: 20px 0;"></div>
              <el-input type="textarea" v-model="editPlateDesc" placeholder="请输入新建板块的描述"></el-input>
              <div slot="footer" class="dialog-footer">
                <el-button @click="updateTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateTypeSure">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 新建问题弹框 -->
            <el-dialog title="新建问题" :visible.sync="createQusVisible" width="90%">
              <div>
                <el-input v-model="faqTitle" placeholder="请输入问题" class="question"></el-input>
                <div style="margin: 20px 0;"></div>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="faqcontent"
                  :options="editorOption"/> 
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAddQues">取 消</el-button>
                <el-button type="primary" @click="addFAQ">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 修改问题弹框 -->
            <el-dialog title="修改问题" :visible.sync="editQusVisible" width="90%">
              <div>
                <el-input v-model="editfaqTitle" placeholder="请输入问题" class="question"></el-input>
                <div style="margin: 20px 0;"></div>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="editfaqcontent"
                  :options="editorOption"/> 
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editQusVisible = false">取 消</el-button>
                <el-button type="primary" @click="editFAQ">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </template>
      </PocCardTable>
    </div>
  </template>
  
 
<script>
import { quillEditor } from 'vue-quill-editor'
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'faq',
  components: {
    quillEditor
  },
  data() {
    return {
      editableTabs:[{
          id: '3',
          name: 'OPPO',
        }, {
          id: '4',
          name: 'VIVO',
        }
        // , {
        //   id: '5',
        //   name: 'MI',
        // }, {
        //   id: '6',
        //   name: '华为',
        // }, {
        //   id: '7',
        //   name: '应用宝',
        // }
      ],
      totalFaqList:[],//列表集合
      addTypeVisible:false,//新建分类的弹框
      addTypeName:'',//新建分类的value
      updateTypeVisible:false,//修改分类的弹框
      updateTypeName:'',//修改分类的value
      updateTypeId:'',//修改分类的id获取
      tableData: [],
      typeId:'',
      status:false,
      roleKeys:'',
      form:{
        keyword:''
      },
      idPart:'',
      menuActive: '1',
      channelId:'',
      urlList : {3:'system',4:'viat',5:'xmat',6:'hwat'},//OPPO->system;VIVO->viat;MI->xmat
      userId:'',
      userName:'',
      deptId:'',
      plateTable:[],// 板块列表
      addPlateQuery:{},//新增板块列表的入参集合
      plateName:'',
      plateDesc:'',
      editPlateName:'',
      editPlateDesc:'',
      editPlateList:{},//修改板块列表的入参集合
      createQusVisible:false,//新建问题的弹框
      faqTitle:'',//新建的问题
      faqcontent:'',//新建的答案
      editorOption:{},
      addfaqQuery:{},//新增问题的入参
      editfaqQuery:{},//修改问题的入参
      editQusVisible:false,//修改问题弹框
      editQuesId:'',//修改问题的id
      editfaqTitle:'',//修改的问题
      editfaqcontent:'',//修改的答案
      firstId:'',//第一个板块的id
      faqId:'',
      clickType:'',//点击的板块列表的id
      // screenHeight:window.innerHeight-336,

    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    // this.plateList()//板块列表
    // this.channelId = this.$route.query.channelId
    // if(this.channelId == undefined){
    //   this.channelId = 3
    //   this. plateList()
    // } 
    this.clickType = 1
    this.channelId = 3
    this. plateList()
     
  },
  methods: {
    //channel的tab切换
    clickChangeChannel(channelId){
      this.channelId = channelId
      this.$router.push({ path: '/auth-management/oPPOFaq', query: { channelId:channelId} }); 
      // console.log(this.menuActive)  
      // console.log(this.$refs.menus)
      // this.menuActive = this.$refs.menus.activeIndex
      this. plateList()
      this.form.keyword = ''
      
    },

    //板块列表
    plateList(){
      let that = this
      $http.axios.get("/api/vivo/apiFaq/listType?channelId="+that.channelId+"&status="+0).then(res=> {
        if(res.data.code == 200){
          that.plateTable = res.data.data
          that.clickType = res.data.data[0].id
          console.log(that.clickType)
          // if(res.data.data.length == 0){
          //   that.enterTypeList(1)
          // }else{
          //   that.enterTypeList(that.clickType)
          // }
          if(that.channelId == 3){
            that.enterTypeList(1)
            // that.menuActive = '1'
          }else if(that.channelId == 4){
            that.enterTypeList(that.clickType)
            // that.menuActive = String(that.clickType)
          }
          // console.log(that.menuActive)
          that.faqList() 
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //新建分类
    addType(){
      this.addTypeVisible = true
      this.plateName = ''
      this.plateDesc = ''
    },

    //取消新增板块
    cancelAdd(){
      this.addTypeVisible = false
      this.plateName = ''
      this.plateDesc = ''
    },

    //新增板块列表
    addPlateList(){
      let that = this
      that.addPlateQuery = {
        'userId' : that.userId,
        'userName' : that.userName,
        'deptId' : that.deptId,
        'channelId' : that.channelId,
        'name' : that.plateName,
        'desc' : that.plateDesc
      }
      $http.axios.post("/api/vivo/apiFaq/addType",that.addPlateQuery).then(res=> {
        if(res.data.code == 200){
          that.addTypeVisible = false
          that.plateList()
          that.plateName = ''
          that.plateDesc = ''
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改板块列表
    updatesType(val){
      this.updateTypeVisible = true
      this.editPlateName = val.name
      this.editPlateDesc = val.desc
      this.updateTypeId = val.id
    },

    //修改板块列表确定
    updateTypeSure(){
      let that = this
      that.editPlateList = {
        'userId' : that.userId,
        'userName' : that.userName,
        'deptId' : that.deptId,
        'id' : that.updateTypeId,
        'name' : that.editPlateName,
        'desc' : that.editPlateDesc,
        'status' : 0
      }
      $http.axios.post("/api/vivo/apiFaq/editType",that.editPlateList).then(res=> {
        if(res.data.code == 200){
          that.updateTypeVisible = false
          that.plateList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //删除板块列表//删除分类
    deleteType(val){
      let that = this
      that.editPlateList = {
        'userId' : that.userId,
        'userName' : that.userName,
        'deptId' : that.deptId,
        'id' : val.id,
        'name' : val.name,
        'desc' : val.desc,
        'status' : 2//删除
      }
      $http.axios.post("/api/vivo/apiFaq/editType",that.editPlateList).then(res=> {
        if(res.data.code == 200){
          that.plateList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //列表
    faqList(){
      let that = this
      $http.axios.get("/api/vivo/apiFaq/list?channelId="+that.channelId+"&status="+0+"&type="+that.clickType).then(res=> {
        if(res.data.code == 200){
          that.tableData = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     //新建问题
     addQuestion(){
       this.createQusVisible = true
       this.faqTitle = ''
       this.faqcontent = ''
    },

    //取消新建问题
    cancelAddQues(){
      this.createQusVisible = false
      this.faqTitle = ''
      this.faqcontent = ''
    },

    //新增问题
    addFAQ(){
      let that = this
      that.addfaqQuery = {
        'userId': that.userId,
        'userName':that.userName,
        'deptId':that.deptId,
        'channelId':that.channelId,
        'type':that.clickType,
        'question':that.faqTitle,
        'answer':that.faqcontent
      }
      $http.axios.post("/api/vivo/apiFaq/add",that.addfaqQuery).then(res=> {
        if(res.data.code == 200){
          that.createQusVisible = false
          that.faqList()
          that.faqTitle = ''
          that.faqcontent = ''
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改问题
    editQuestion(val){
      this.editQusVisible = true
      this.editfaqTitle = val.question
      this.editfaqcontent = val.answer
      this.editQuesId = val.id
      console.log(this.clickType)
    },

    //修改问题
    editFAQ(){
      let that = this
      that.editfaqQuery = {
        'userId': that.userId,
        'userName':that.userName,
        'deptId':that.deptId,
        'id':that.editQuesId,
        'type':that.clickType,
        'question':that.editfaqTitle,
        'answer':that.editfaqcontent,
        'status':0
      }
      $http.axios.post("/api/vivo/apiFaq/edit",that.editfaqQuery).then(res=> {
        if(res.data.code == 200){
          that.editQusVisible = false
          that.faqList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    enterTypeList(val){
      let that = this
      that.clickType = val
      $http.axios.get("/api/vivo/apiFaq/list?channelId="+that.channelId+"&status="+0+"&type="+val).then(res=> {
        if(res.data.code == 200){
          that.tableData = res.data.data
          console.log(res.data.data)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //删除问题
    deleteQuestion(val){
      console.log(val)
      let that = this
      that.editfaqQuery = {
        'userId': that.userId,
        'userName':that.userName,
        'deptId':that.deptId,
        'id':val.id,
        'type':that.clickType,
        'question':val.question,
        'answer':val.answer,
        'status':2//删除
      }
      $http.axios.post("/api/vivo/apiFaq/edit",that.editfaqQuery).then(res=> {
        if(res.data.code == 200){
          that.faqList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //查询
    searchKeyword(){
      let that = this
      $http.axios.get("/api/vivo/apiFaq/list?channelId="+that.channelId+"&status="+0+"&type="+that.clickType+"&desc="+that.form.keyword).then(res=> {
        if(res.data.code == 200){
          that.tableData = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
  }
}
</script>

<style lang="scss" scoped>
  .faqWrap{
    width:100%;
    height:100%;
    position: relative;
    overflow-y: scroll;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  .el-aside {
    color: #333;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .faq{
    font-size:18px;
    font-weight:700;
  }
  .faqbtnFather{
    width:300px;
    position:relative;
    padding-left:10px!important;
  }
  .faqbtnleft,.faqbtn{
    display:inline-block;
    vertical-align:middle;
  }
  .faqbtnleft{
    width:220px;
    padding-right:20px;
    box-sizing: border-box;
  }
  .plateName,.plateDesc{
    display:block;
    width:180px;
  }
  .plateName{
    font-weight: 700;
  }
  .plateDesc{
    margin-top:10px;
    color:#666;
    font-size:12px;
    width:200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .faqbtn{
    position:absolute;
    top:50%;
    right:10px;
    width:80px;
    margin-top:-10px;
    text-align:right;
    text-align:center;
  }
  /* .faqbtn span{
    margin:0;
    padding:0;
    display:inline-block;
    vertical-align:middle;
    font-size:12px;
    width:26px;
    height:26px;
    line-height:26px;
    border-radius: 26px;
    color:#fff;
    margin:0 4px;
    background-color: #66856d;
  } */
  .addfenlei{
    text-align:right;
    padding:0 10px;
  }
  .createQuestion{
    padding-top:15px;
    text-align:right;
  }
  .demo-table-expand .el-form-item{
    width:100%!important;
  }
  html,body,#app,.el-container{
    width:100%;
    /*设置内部填充为0，几个布局元素之间没有间距*/
    padding: 0px;
      /*外部间距也是如此设置*/
    margin: 0px;
    /*统一设置高度为100%*/
    height: 100%;
  }
  .el-submenu {
    text-align: left;
    width:300px;

    &.is-active .el-submenu__title {
      color: $--color-primary;
    }

    .el-menu-item.is-active {
      /* background: $poc-color-primary-deputy; */
      border-right: 4px solid $--color-primary;
      background:rgba(102,133,109,0.2);

      a {
        color: $--color-primary;
      }
    }
  }
  /* .el-menu { width: 100%; border-right: none !important; } .el-menu-item [class^="el-icon-"] { color: #333; } .el-menu .el-submenu__title i { color: #333; } .el-menu-item.is-active { background: #f8e8ea; border-right: 3px solid #bb162b; } .el-submenu.is-opened .el-menu { background: #fafafa; } */
  /* oppo和vivo的tab切换 */
  .chanelTab{
      margin-bottom:30px;
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:36px;
      line-height:36px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
    .el-submenu .el-menu-item{
      padding:8px 0;
      height:auto;
      line-height:normal;
    }
    ::v-deep .ql-editor{
      height:320px;
    }
    .styleWrap{
      position: relative;
    }
    .createStyle{
      position: absolute;
      top:50%;
      right:10px;
      margin-top:-16px;
    }
    .personAndTime{
      text-align:right;
    }
    .personAndTime span{
      font-size:12px;
      color:#999;
      padding-left:10px;
    }
</style>
