<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
              <el-breadcrumb-item>广告组</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" @click="zdyFn">自定义列</el-button>
            <el-upload
              class="upload-demo batchUpload"
              ref="upload"
              :headers="myHeaders"
              action = "#"
              :http-request="batchUploadNegKw" 
              accept=".xlsx"
              :limit='1'
              :show-file-list='false'>
              <el-button slot="trigger" size="small" type="primary">批量上传否定关键词</el-button>
            </el-upload>
            <el-button type="primary" size="small" @click="downLoadNegExcel">下载广告组否定关键词模版</el-button>
            <el-upload
              class="upload-demo batchUpload"
              ref="upload"
              :headers="myHeaders"
              action = "#"
              :http-request="batchUploadKw" 
              accept=".xlsx"
              :limit='1'
              :show-file-list='false'>
              <el-button slot="trigger" size="small" type="primary">批量上传关键词</el-button>
            </el-upload>
            <el-button type="primary" size="small" @click="downLoadExcel">下载关键词模板</el-button>
            <el-button type="primary" size="small" style="margin:0;" @click="createGroup"><i class="el-icon-plus"></i>创建广告组</el-button>
          </div>
        </div>
        <div class="chanelTab">
          <span class="channel_oppo" @click="clickChangeChannel(1)">广告系列</span>
          <span class="channel_vivo channelActive">广告组</span>
          <span v-if="changeSupplySources == ''">
            <span class="channel_vivo" @click="clickChangeChannel(3)" v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">关键词</span>
            <span class="channel_vivo" @click="clickChangeChannel(4)" v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">广告</span>
          </span>
          <span v-else>
            <span class="channel_vivo" @click="clickChangeChannel(3)" v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">关键词</span>
            <span class="channel_vivo" @click="clickChangeChannel(4)" v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">广告</span>
          </span>
        </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix queryLeft">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <el-date-picker
                    v-model="dateValue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="dateStyle"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "adGroupName" clearable placeholder="请输入广告组名称" @blur="blurGroupName"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "nameId" 
                  clearable           
                  @blur="blurGroupId"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="请输入广告组ID"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" class="newQuery" size="small" @click="grouplistFn">查询</el-button>
                  <el-button type="info" size="small" plian @click="resetForm">重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
            <div class="listDetail queryRight">
              <span>
                计划系列：
                <el-select 
                  :placeholder="campaignName"
                  @change="changeAdplanLabel"
                  value-key="id"
                  style="margin-right:10px;"
                  v-model="adplanLabel">
                  <el-option
                    v-for="(item,i) in adplanModelOption"
                    :key="i"
                    :label="item.name"
                    :value="item">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{ item.id }}</span>
                  </el-option>
                </el-select>
              </span>
              <span>
                <span v-if="changeSupplySources == ''">
                  <i v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else-if="supplySources == 'APPSTORE_SEARCH_TAB'">投放位置：搜索标签</i>
                </span>
                <span v-else>
                  <i v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else-if="changeSupplySources == 'APPSTORE_SEARCH_TAB'">投放位置：搜索标签</i>
                </span>
              </span>
            </div>
          </div>
          <div class="clearfix">
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @sort-change="handlesortChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="metadata.adGroupName"
                  label="广告组"
                  width="220"
                  fixed>
                  <template slot-scope="scope">
                    <span v-if="changeSupplySources == ''">
                      <span v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">
                        <span class="ownerNameColor" @click="enterKw(scope.row)">{{scope.row.metadata.adGroupName}}</span>
                      </span>
                      <span v-else-if="supplySources == 'APPSTORE_SEARCH_TAB'">
                        <span>{{scope.row.metadata.adGroupName}}</span>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">
                        <span class="ownerNameColor" @click="enterKw(scope.row)">{{scope.row.metadata.adGroupName}}</span>
                      </span>
                      <span v-else-if="changeSupplySources == 'APPSTORE_SEARCH_TAB'">
                        <span>{{scope.row.metadata.adGroupName}}</span>
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="预警详情"
                  fixed>
                  <template slot-scope="scope" >
                    <el-button plain size="mini" class="newBtn_orang" @click="warningDetail(scope.row)">详情</el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adGroupServingStatus"
                  label="状态"
                  fixed>
                  <template slot-scope="scope" >
                    <el-switch
                      v-model="scope.row.metadata.adGroupServingStatus"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      active-value="RUNNING"
                      inactive-value="NOT_RUNNING"
                      @change="changeListStatus(scope.row)">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="defaultBidAmount"
                  label="默认最高出价"
                  width="180px"
                  sortable>
                  <template slot-scope="scope" >
                   {{scope.row.metadata.defaultBidAmount.amount}}
                   <el-popover
                      placement="right"
                      width="70"
                      :ref="`popover-${scope.$index}`"
                      trigger="click">
                        <el-input v-model="defaultBidAmountGroup"></el-input>
                        <div class="inputBtn" style="text-align:right;">
                          <el-button class="el-icon-check" style="margin:0;" @click="handleConfirmSure(scope,1);handleConfirm(scope)"></el-button>
                          <el-button class="el-icon-close" @click="handleConfirm(scope)" style="margin:0;"></el-button>
                        </div>
                        <i class="el-icon-edit" slot="reference" @click="editrDefaultBid(scope)" style="padding-left:6px;color:#F3920B;font-weight:700;" ></i>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cpaGoal"
                  label="CPA出价"
                  width="150px"
                  sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.metadata.cpaGoal!=null">
                      {{scope.row.metadata.cpaGoal.amount}}
                      <el-popover
                         placement="right"
                         width="70"
                         :ref="`popover-${scope.$index}`"
                         trigger="click">
                           <el-input v-model="cpaGoalGroup"></el-input>
                           <div class="inputBtn" style="text-align:right;">
                             <el-button class="el-icon-check" style="margin:0;" @click="handleConfirmSure(scope,2);handleConfirm(scope)"></el-button>
                             <el-button class="el-icon-close" @click="handleConfirm(scope)" style="margin:0;"></el-button>
                           </div>
                           <i class="el-icon-edit" slot="reference" @click="editrCpaGoal(scope)" style="padding-left:6px;color:#F3920B;font-weight:700;"></i>
                       </el-popover>
                    </div>
                    <div v-else>-</div>
                  </template>
                </el-table-column>
                <template v-for="(item, index) in titleList">
                  <el-table-column
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    width="170px"
                    sortable>
                  </el-table-column>
                </template>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='100'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button class="newBtn_orang" plain  size="mini" @click="editGroup(scope.row)">修改</el-button>                   
                  </template>
                </el-table-column>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='80'
                  :show-overflow-tooltip="true"
                  label="删除">
                  <template slot-scope="scope">
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deletePlanFn(scope.row)">
                      <template #reference>
                        <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 更新 -->
              <el-dialog
                title="更新账户"
                :visible.sync="dialogAccountUpdate"
                :show-close = "false"
                width="36%">
                <el-form :model="accountUpdateForm" ref="accountUpdateForm" :rules="accountUpdaterules">
                  <el-form-item label="默认出价" prop="balance" :label-width="formLabelWidth1">
                    <el-input v-model="accountUpdateForm.balance" placeholder="请输入默认出价"></el-input>
                  </el-form-item>
                  <el-form-item label="cpa出价" prop="recharge" :label-width="formLabelWidth1">
                    <el-input v-model="accountUpdateForm.recharge" placeholder="请输入cpa出价"></el-input>
                  </el-form-item>
                  <el-form-item label="货币" prop="currency" :label-width="formLabelWidth1">
                    <el-select  clearable v-model="accountUpdateForm.currency" disabled>
                      <el-option
                        v-for="(item,i) in currencyOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="状态" prop="status" :label-width="formLabelWidth1">
                    <el-switch
                      v-model="accountUpdateForm.status"
                      @change="changeStatus">
                    </el-switch>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogAccountUpdate = false">取 消</el-button>
                  <el-button type="primary" @click="accountUpdateSure">确 定</el-button>
                </div>
              </el-dialog>

             <!-- 预警详情 -->
             <el-dialog
              title="预警详情"
              append-to-body
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :visible.sync="dialogWarningDetail"
              center
              class="warnningDialog"
              width="820px">
              <div class="warnTableData">
                <div class="dialogIcon"><img :src='warningIcon'></div>
                <div style="text-align:right;margin-bottom:15px;margin-top:-35px;">
                  <el-button type="primary" @click="accountWarnDialog">预警设置</el-button>
                </div>
                <el-table
                  ref="multipleWarnTable"
                  :data="warnInfosTableData"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  tooltip-effect="dark">
                  <el-table-column
                    prop=""
                    label="观察指标和执行条件"
                    width="200"
                    fixed>
                    <template slot-scope="scope">
                      <div class="warnInfosList" v-for="(item,i) in scope.row.infos">
                        <span>
                          <i v-if="item.target == 1">余额</i>
                          <i v-else-if="item.target == 2">消耗</i>
                          <i v-else-if="item.target == 3">平均CPT</i>
                          <i v-else-if="item.target == 4">平均CPA </i>
                          <i v-else-if="item.target == 5">曝光</i>
                          <i v-else-if="item.target == 6">点击</i>
                          <i v-else-if="item.target == 7">安装</i>
                          <i v-else-if="item.target == 8">点击率</i>
                          <i v-else-if="item.target == 9">转化率</i>
                        </span>
                        <span>
                          <i v-if="item.op == 1">>=</i>
                          <i v-else-if="item.op == 2"><=</i>
                        </span>
                        <span>{{item.val}}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="operate"
                    label="操作符">
                    <template slot-scope="scope">
                      <span v-if="scope.row.operate == 1">与</span>
                      <span v-else>或</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="startTime"
                    label="开始时间">
                  </el-table-column>
                  <el-table-column
                    prop="endTime"
                    label="结束时间">
                  </el-table-column>
                  <el-table-column
                    prop="executeStrategy"
                    width="130"
                    label="执行策略">
                    <template slot-scope="scope">
                      <span v-if="scope.row.executeStrategy == 1">无</span>
                      <span v-else-if="scope.row.executeStrategy == 2">停投</span>
                      <span v-else-if="scope.row.executeStrategy == 3">调日预算</span>
                      <span v-else-if="scope.row.executeStrategy == 4">调CPA出价</span>
                      <span v-else>调点击出价</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="状态"
                    width="120">
                    <template slot-scope="scope">
                      <el-switch
                        v-model="scope.row.status"
                        active-color="#48916F"
                        inactive-color="#EAEFED"
                        :active-value="0"
                        :inactive-value="1"
                        @change="changeWarnStatus(scope.row)">
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="warnCount"
                    label="预警次数">
                  </el-table-column>
                  <el-table-column
                    prop="priority"
                    label="优先级">
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="创建时间"
                    width="180">
                    <template slot-scope="scope">{{scope.row.createTime | dateTimeFormat}}</template>
                  </el-table-column>
                  <el-table-column
                    prop="updater"
                    label="更新人">
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="更新时间"
                    width="180">
                    <template slot-scope="scope">
                      <span v-if="scope.row.updateTime == null">暂无</span>
                      <span v-else>{{scope.row.updateTime | dateTimeFormat}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    width="100"
                    fixed="right">
                    <template slot-scope="scope">
                      <el-button class="newBtn_darkOrange" size="mini" @click="warnEdit(scope.row)"><i class="el-icon-edit" style="padding-right:3px;font-weight:700;"></i>修改</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button class="newDialogCancel" type="primary" @click="dialogWarningDetail = false">取消保存</el-button>
                <el-button class="newDialog" type="primary" @click="dialogWarningDetail = false">确定保存</el-button>
              </span>
            </el-dialog>
            <!-- 预警 -->
            <el-dialog 
              title="预警设置" 
              width="600px"
              center
              class="warnningForm"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :visible.sync="dialogFormVisible">
              <div class="dialogIcon"><img :src='warningIcon'></div>
              <el-form 
                :model="budgetRuleForm"
                :rules="budgetRules"
                ref="budgetRuleForm">
                <!-- 新版 -->
                <div>
                  <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
                    <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="formLabelWidth">
                      <el-select
                          v-model="obj.target">
                          <el-option
                            v-for="(item,i) in newObserveTargetOption"
                            :key="i"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select> 
                    </el-form-item>
                    <el-form-item label="执行条件" prop="" :label-width="formLabelWidth">
                      <el-select
                        v-model="obj.op"
                        style="width:120px;">
                        <el-option
                          v-for="(item,i) in newSymbolOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select> 
                      <el-input v-model.number="obj.val"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        style="width:280px;margin-left:20px;"
                        placeholder="请输入预警数值">
                      </el-input>
                    </el-form-item>
                    <div class="deleteBtn">
                      <div v-if="j>0" size="small" @click="removeRowpl(j)">
                        <i class="deleteBtnicon el-icon-error"></i>
                      </div>
                    </div>
                  </div>
                  <div @click="changeChecked" class="addConditions">
                    <i class="el-icon-plus"></i>新增执行条件
                  </div>
                </div>
                <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="formLabelWidth">
                  <el-select
                    v-model="budgetRuleForm.warningPerson"
                    multiple
                    value-key="userId"
                    @change="changeWarning"
                    style="width:300px;">
                    <el-option
                      v-for="(item,i) in warningPersonOption"
                      :key="i"
                      :label="item.string"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="formLabelWidth">
                  <el-select
                    v-model="budgetRuleForm.newOperator">
                    <el-option
                      v-for="(item,i) in newOperatorOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> 
                </el-form-item>
                
                <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="formLabelWidth">
                  <el-time-picker
                    is-range
                    v-model="budgetRuleForm.warningTime"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    format="HH:mm"
                    style="width:160px;"
                    @change="changeTimes">
                  </el-time-picker>
                </el-form-item>
                <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="formLabelWidth">
                  <el-input v-model="budgetRuleForm.warnNumTimes"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    style="width:180px;"
                    placeholder="请输入预警次数">
                  </el-input>
                </el-form-item>
                <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="formLabelWidth">
                  <el-input v-model="budgetRuleForm.priority"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    style="width:180px;"
                    placeholder="请输入优先级">
                  </el-input>
                </el-form-item>
                <el-form-item label="执行策略" :label-width="formLabelWidth">
                  <el-form-item prop="">
                    <div><el-radio v-model="radio" label="1" @change="changeExecute">无</el-radio></div>
                    <div><el-radio v-model="radio" label="2" @change="changeExecute">停止投放</el-radio></div>
                    <div style="margin-top:5px;">
                      <el-radio v-model="radio" label="4" @change="changeExecute">调CPA出价
                        <div style="padding-left:30px;">
                          <el-radio v-model="priceRadio" label="1" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                            <el-select v-model="topAndDown" :disabled = "pricetofNumber" style="width:100px;" @change="changeNumber">
                              <el-option
                                v-for="(item,i) in topAndDownOption"
                                :key="i"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                            <el-input v-model="topAndDownNum" :disabled = "pricetofNumber" style="width:110px;margin-left:15px;" placeholder="请输入数值"></el-input>
                          </el-radio>
                          <el-radio v-model="priceRadio" label="2" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                            <el-select v-model="topAndDown02" :disabled = "pricetofPercent" style="width:100px;" @change="changePercent">
                              <el-option
                                v-for="(item,i) in topAndDownOption02"
                                :key="i"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                            <el-input v-model="topAndDownNum02" :disabled = "pricetofPercent" style="width:110px;margin-left:15px;" placeholder="请输入百分比"></el-input>
                          </el-radio>
                        </div>
                      </el-radio>
                    </div>
                  </el-form-item>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button class="newDialogCancel" type="primary" @click="accountWarningCancel">取消保存</el-button>
                <el-button class="newDialog" type="primary" @click="accountWarning">确定保存</el-button>
              </div>
            </el-dialog>
            <!-- 修改预警 -->
            <el-dialog 
              title="预警修改" 
              width="600px"
              center
              class="warnningForm"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :visible.sync="dialogEditVisible">
              <el-form 
                :model="budgetRuleForm"
                :rules="budgetRules"
                ref="budgetRuleForm">
                <div class="dialogIcon"><img :src='warningIcon'></div>
                <!-- 新版 -->
                <div>
                  <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
                    <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="formLabelWidth">
                      <el-select
                          v-model="obj.target">
                          <el-option
                            v-for="(item,i) in newObserveTargetOption"
                            :key="i"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select> 
                    </el-form-item>
                    <el-form-item label="执行条件" prop="" :label-width="formLabelWidth">
                      <el-select
                        v-model="obj.op"
                        style="width:120px;">
                        <el-option
                          v-for="(item,i) in newSymbolOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select> 
                      <el-input v-model.number="obj.val"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        style="width:280px;margin-left:20px;"
                        placeholder="请输入预警数值">
                      </el-input>
                    </el-form-item>
                    <div class="deleteBtn">
                      <div v-if="j>0" size="small" @click="removeRowpl(j)">
                        <i class="deleteBtnicon el-icon-error"></i>
                      </div>
                    </div>
                  </div>
                  <div @click="changeChecked" class="addConditions">
                    <i class="el-icon-plus"></i>新增执行条件
                  </div>
                </div>
                <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="formLabelWidth">
                  <el-select
                    v-model="budgetRuleForm.warningPerson"
                    multiple
                    value-key="userId"
                    @change="changeWarning"
                    style="width:300px;">
                    <el-option
                      v-for="(item,i) in warningPersonOption"
                      :key="i"
                      :label="item.string"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="formLabelWidth">
                  <el-select
                    v-model="budgetRuleForm.newOperator">
                    <el-option
                      v-for="(item,i) in newOperatorOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> 
                </el-form-item>
                
                <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="formLabelWidth">
                  <el-time-picker
                    is-range
                    v-model="budgetRuleForm.warningTime"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    format="HH:mm"
                    style="width:160px;"
                    @change="changeEditTimes">
                  </el-time-picker>
                </el-form-item>
                <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="formLabelWidth">
                  <el-input v-model="budgetRuleForm.warnNumTimes"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    style="width:180px;"
                    placeholder="请输入预警次数">
                  </el-input>
                </el-form-item>
                <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="formLabelWidth">
                  <el-input v-model="budgetRuleForm.priority"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    style="width:180px;"
                    placeholder="请输入优先级">
                  </el-input>
                </el-form-item>
                <el-form-item label="是否清除当日预警次数" label-width="150px">
                  <el-switch
                    v-model="budgetRuleForm.clearHistory"
                    active-color="#13ce66"
                    inactive-color="#ccc"
                    :active-value="1"
                    :inactive-value="2">
                  </el-switch>
                </el-form-item>
                <el-form-item label="执行策略" :label-width="formLabelWidth">
                  <el-form-item prop="">
                    <div><el-radio v-model="radio" label="1" @change="changeExecute">无</el-radio></div>
                    <div><el-radio v-model="radio" label="2" @change="changeExecute">停止投放</el-radio></div>
                    <div style="margin-top:5px;">
                      <el-radio v-model="radio" label="4" @change="changeExecute">调CPA出价
                        <div style="padding-left:30px;">
                          <el-radio v-model="priceRadio" label="1" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                            <el-select v-model="topAndDown" :disabled = "pricetofNumber" style="width:100px;" @change="changeNumber">
                              <el-option
                                v-for="(item,i) in topAndDownOption"
                                :key="i"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                            <el-input v-model="topAndDownNum" :disabled = "pricetofNumber" style="width:110px;margin-left:15px;" placeholder="请输入数值"></el-input>
                          </el-radio>
                          <el-radio v-model="priceRadio" label="2" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                            <el-select v-model="topAndDown02" :disabled = "pricetofPercent" style="width:100px;" @change="changePercent">
                              <el-option
                                v-for="(item,i) in topAndDownOption02"
                                :key="i"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                            <el-input v-model="topAndDownNum02" :disabled = "pricetofPercent" style="width:110px;margin-left:15px;" placeholder="请输入百分比"></el-input>
                          </el-radio>
                        </div>
                      </el-radio>
                    </div>
                  </el-form-item>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="newDialogCancel" @click="dialogEditVisible = false">取消保存</el-button>
                <el-button type="primary" class="newDialog" @click="accountWarningEdit">确定保存</el-button>
              </div>
            </el-dialog>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>
          <!-- 正在载入 -->
          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <zdyApple :visible.sync="showzdyDialog" ref = 'zdyApple' :pubVue="pubVue"></zdyApple>
  </div>
</template>

<script>
import warningIcon from '@/assets/img/warningIcon.png';
import ZDYLicon from '@/assets/img/ZDYLicon.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import zdyApple from './zdyApple'

export default {
  components: {
    zdyApple
  },
  data() {
    return {
      warningIcon,
      ZDYLicon,
      pubVue: this,//空vue，用于子组件之间传值
      showzdyDialog:false,
      showDialog: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showSingleDialog:false,
      nowIs : false,
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      planName:'',//请输入计划名称查找
      nameId:'',//请输入计划ID查找
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      // 分页
      page: 0,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:'',
      planType:30,
      dialogTitle:'批量调价',
      userContent:{},//登录用户信息&弹框默认
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      // closeGroupList:[],
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      //苹果渠道广告组
      tableData:[],
      formLabelWidth:'80px',
      adSeries:'',
      adSeriesOption:[{
        label:'广告系列1',
        value:1
      },{
        label:'广告系列2',
        value:2
      }],
      adGroupApple:'',
      adGroupAppleOption:[{
        label:'广告组1',
        value:1
      },{
        label:'广告组2',
        value:2
      }],
      pickerOptions: {
        shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue:[],//搜索时间
      adplanStatu:'',//搜索状态
      adplanStatuOption:[{//状态选项
        value:1,
        label:'全部状态'
      },{
        value:2,
        label:'开启中'
      },{
        value:3,
        label:'暂停中'
      },{
        value:4,
        label:'挂起中'
      }],
      adplanUseStatu:'',//搜索启用状态
      adplanUseStatuOption:[{//启用状态选项
        value:1,
        label:'所有'
      },{
        value:2,
        label:'已启用'
      },{
        value:3,
        label:'已移除'
      }],
      adplanLabelOption:[{//启用状态选项
        value:1,
        label:'全部'
      }],
      dialogFormVisible:false,//预警预算弹窗
      radio: '1',
      priceRadio:'1',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      titleList:[],
      materialIdsPara:[],//选中的广告组
      ownerId:'',
      ownerName:'',
      //预警
      dialogFormVisible:false,//预警预算弹窗
      budgetRuleForm:{
        condition:{
          min:'',
          max:''
        },
        observeTarget:'1',//观察指标
        warningPerson:[],//预警人
        // 新
        clearHistory:'',
        newObserveTarget:[],//观察指标-新
        newSymbol:'',//执行条件中的符号，大于等于，小于等于
        newCondition:'',//执行条件中的num
        newOperator:'',//操作符
        warnNumTimes:'',//预警次数
        priority:'',//优先级
        warningTime:[new Date(0,0,0), new Date()],//操作时间

      },
      budgetRules:{

      },
      radio: '1',
      priceRadio:'1',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      warningPersonOption:[],//预警人的集合
      warnOwnerId:'',//预警的ownerId
      changeWarningPerson:'',//选中的预警人id
      changeWarningPersonName:'',//选中的预警人name
      dialogWarningDetail:false,//预警详情的弹框
      dialogEditVisible:false,//预警修改弹框
      warnInfosTableData:[],//预警详情表格
      warnDeptId:'',//修改预警时候的部门id
      warningId:'',//修改预警id
      row:[],
      dialogAccountUpdate:false,//账户更新弹框
      accountUpdateForm:{//账户更新弹框表单
        balance:'',//余额
        recharge:'',//最近充值
        status:'',//状态
        currency:'',//货币
      },
      accountUpdaterules:{//账户更新弹框表单-校验

      },
      currencyOption:[{//货币
        value:'RMB',
        label:'RMB'
      },{
        value:'AUD',
        label:'AUD'
      },{
        value:'CAD',
        label:'CAD'
      },{
        value:'EUR',
        label:'EUR'
      },{
        value:'GBP',
        label:'GBP'
      },{
        value:'JPY',
        label:'JPY'
      },{
        value:'MXN',
        label:'MXN'
      },{
        value:'NZD',
        label:'NZD'
      },{
        value:'RUB',
        label:'RUB'
      },{
        value:'USD',
        label:'USD'
      }],
      formLabelWidth1:'60px',
      appleOwnerIdUpdate:'',//更新的时候入参的ownerid
      appleStatus:'',//更新的时候入参的status
      // ownerId:'7774110',//广告系列组id
      // ownerName:'钢构宝',//广告系列组name
      // planId:'1430552493',//广告系列id
      // planName:'广告系列名称',//广告系列name
      groupId:'1430948813',//广告组id
      groupName:'广告组名称',//广告组name
      executeRate:'',//预警-执行比率
      executeDiff:'',//预警-执行增减差额
      exNum:'',
      exPercent:'',
      exRadio:'',
      exRadioto:'',
      ownerId:'',
      ownerName:'',
      campaignId:'',//广告系列id
      campaignName:'',//广告系列name
      localSpend:'',//默认
      dOrder:'',//默认排序
      listParams:{
        selector:{
          conditions:[],
          orderBy:[
          // {field:'localSpend',sortOrder:'ASCENDING'}
          ]
        }
      },//列表入参
      defaultBidAmountGroup:'',//默认最高出价
      cpaGoalGroup:'',//cpa出价
      adGroupName:'',//搜索广告组名称
      loadingDialogVisible:true,//正在载入的弹框
      supplySources:'',
      appId:'',
      budget:'',//广告系列的每日预算
      // totalBudget:'',//广告系列的总预算
      warnEigth:[],
      warnNine:[],
      adplanModelOption:[],//获取广告系列下拉列表
      adplanLabel:'',//搜索广告系列名称
      //预警
      newWarnStartTime:'',
      newWarnEndTime:'',
      warnParams:{//确定预警-入参
        infos:[{
          target:'',
          op:'',
          val:''
        }]
      },
      pricetofAll:true,//调日预算的all选框
      pricetofNumber:true,//调日预算--输入数值行
      pricetofPercent:true,//调日预算--输入百分比行
      executeValue:'',//change调日预算的值
      executeRate:'',//执行比率 下调10% => -0.1
      executeDiff:'',//执行增减差额 下调100 => -100
      percentDetail:'',
      numberDetail:'',
      newObserveTargetOption:[{//观察指标集合-新
        value:2,
        label:'消耗'
      },{
        value:3,
        label:'平均CPT'
      },{
        value:4,
        label:'平均CPA'
      },{
        value:5,
        label:'曝光'
      },{
        value:6,
        label:'点击'
      },{
        value:7,
        label:'安装'
      },{
        value:8,
        label:'点击率'
      },{
        value:9,
        label:'转化率'
      }],
      newSymbolOption:[{//执行条件中的符号
        value:1,
        label:'>='
      },{
        value:2,
        label:'<='
      }],
      newOperatorOption:[{//操作符
        value:1,
        label:'与'
      },{
        value:2,
        label:'或'
      }],
      changeSupplySources:'',//change计划系列名称的时候投放位置的变化
      myHeaders: {Authorization:''},

    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    this.campaignId = this.$route.query.campaignId
    this.campaignName = this.$route.query.campaignName
    this.supplySources = this.$route.query.position
    this.appId = this.$route.query.appId
    this.budget = this.$route.query.budget
    // this.totalBudget = this.$route.query.totalBudget
    this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)

    this.warnDeptId = 328//开发组的，苹果的是328
    this.accountUpdateForm.currency = 'RMB'
    this.adplanLabel = this.campaignName

    let nowTimes = Date.now();
    let oldTimes = Date.now();
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//今天的时间
    this.dateValue = [oldDate, nowDate];
    this.localSpend = 'localSpend'
    this.dOrder = 'DESCENDING'
    this.budgetRuleForm.newOperator = 1,//预警的操作符默认1-与
    this.budgetRuleForm.clearHistory = 1
    this.budgetRuleForm.warnNumTimes = 3
    this.budgetRuleForm.priority = 1
    this.newWarnStartTime = this.getDateTime(this.budgetRuleForm.warningTime[0])
    this.newWarnEndTime = this.getDateTime(this.budgetRuleForm.warningTime[1])
    this.grouplistFn()
    this.getAdplanList()
    this.getFpyjList328()
    this.getFpyjList329()

  },
  methods: {
    //tab切换
    clickChangeChannel(val){
      if(val == 1){//广告计划
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName }})
      }else if(val == 3){//关键词
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
          supplySources:this.supplySources,appId:this.appId,budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
          supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget}})
        }
      }else if(val == 4){//广告
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
          supplySources:this.supplySources,appId:this.appId,budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,campaignName:this.campaignName,
          supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget}})
        }
      }
    },

     //格式化时间 返回年月日 
     getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //保留两位小数
    fun(val){
　　  return Number(val).toFixed(2);
　　 },

    //率
    funny(val){
      let num = ''
      num = val*100
      return num.toFixed(2);   

      // return Number(val)*100;
　　 },

    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.grouplistFn()
    },

    //重置
    resetForm(){
      this.$router.go(0)
    },

    //change搜索条件
    blurGroupName(){
      this.field = 'adGroupName'
    },
    blurGroupId(){
      this.field = 'adGroupId'
    },

    //change广告系列展示广告组列表
    changeAdplanLabel(item){
      this.loadingDialogVisible = true
      let _this = this
      console.log(item)
      item.supplySources.forEach(function(val,i){
        _this.changeSupplySources = val
      })

      _this.listParams = {
        'startTime':_this.dateValue[0],//开始日期
        'endTime':_this.dateValue[1],//结束日期
        // 'groupBy':[],
        'returnGrandTotals':true,
        'returnRecordsWithNoMetrics':true,
        'returnRowTotals':true,
        'timeZone':'ORTZ',
        'selector':{
          'conditions':[],
          'orderBy':[],
          'pagination':{
            'limit':_this.pageCount,
            'offset':_this.page
          }
        }
      }
      _this.listParams.selector.orderBy.push({
        'field':_this.localSpend,
        'sortOrder':_this.dOrder
      })
      $http.axios.post("/api/apple/adGroup/getAdgroups/"+_this.ownerId+"/"+item.id,_this.listParams).then(res=> {
        if(res.data.code==200){
          console.log(res.data.data)
          _this.tableData = res.data.data.data.reportingDataResponse.row
          _this.totalRow = res.data.data.pagination.totalResults
          _this.loadingDialogVisible = false

          _this.campaignId = item.id
          _this.campaignName = item.name
          _this.tableData.forEach((obj,i)=>{
            //支出
            // if(obj.total.localSpend==null){
            //   obj.total.localSpend = '-'
            // }else{
            //   obj.total.localSpend.amount  = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
            // }
            if(obj.total.localSpend!=null){
              obj.total.localSpend.amount = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
            }
            //平均每次点击费用
            if(obj.total.avgCPT!=null){
              obj.total.avgCPT.amount = '¥'+(Number(obj.total.avgCPT.amount).toFixed(2))
            }
            //平均每次转化费用
            if(obj.total.avgCPA!=null){
              obj.total.avgCPA.amount = '¥'+(Number(obj.total.avgCPA.amount).toFixed(2))
            }
            //平均每千次展示费用
            // if(obj.total.avgCPM!=null){
            //   obj.total.avgCPM.amount = '¥'+(Number(obj.total.avgCPM.amount).toFixed(2))
            // }
            //点击率
            if(obj.total.ttr!=null){
              obj.total.ttr = ((Number(obj.total.ttr)*100).toFixed(2))+'%'
            }
            //转化率
            if(obj.total.conversionRate!=null){
              obj.total.conversionRate = ((Number(obj.total.conversionRate)*100).toFixed(2))+'%'
            }

          })
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          }) 
        }
      })
    },
   
    //广告组列表
    grouplistFn(){
      let _this = this,conditions = [],orderBy = []
      _this.listParams.startTime = _this.dateValue[0]//开始日期
      _this.listParams.endTime = _this.dateValue[1]//结束日期
      _this.listParams.returnGrandTotals = true
      _this.listParams.returnRecordsWithNoMetrics = true
      _this.listParams.returnRowTotals = true
      _this.listParams.timeZone = 'ORTZ'
      _this.listParams.selector = {
        'pagination':{
          'limit':_this.pageCount,
          'offset':_this.page
        }
      }
      orderBy.push({
        'field':_this.localSpend,
        'sortOrder':_this.dOrder
      })
      if(_this.adGroupName != ''){
        if(_this.planName.length<=2){
          conditions.push({'field':'adGroupName','operator':'IN','values':[_this.adGroupName]})//判断一下字符大小--小于等于两个字符
        }else{
          conditions.push({'field':'adGroupName','operator':'STARTSWITH','values':[_this.adGroupName]})//判断一下字符大小--大于两个字符
        }
      }
      if(_this.nameId != ''){
        if(_this.planName.length<=2){
          conditions.push({'field':'adGroupId','operator':'IN','values':[_this.nameId]})//判断一下字符大小--小于等于两个字符
        }else{
          conditions.push({'field':'adGroupId','operator':'STARTSWITH','values':[_this.nameId]})//判断一下字符大小--大于两个字符
        }
      }
      _this.listParams.selector.conditions = conditions
      _this.listParams.selector.orderBy = orderBy
      $http.axios.post("/api/apple/adGroup/getAdgroups/"+_this.ownerId+"/"+_this.campaignId,_this.listParams).then(res=> {
        if(res.data.code==200){
          console.log(res.data.data)
          _this.tableData = res.data.data.data.reportingDataResponse.row
          _this.totalRow = res.data.data.pagination.totalResults
          _this.loadingDialogVisible = false
          _this.tableData.forEach((obj,i)=>{
            //支出
            // if(obj.total.localSpend==null){
            //   obj.total.localSpend = '-'
            // }else{
            //   obj.total.localSpend.amount  = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
            // }
            if(obj.total.localSpend!=null){
              obj.total.localSpend.amount = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
            }
            //平均每次点击费用
            if(obj.total.avgCPT!=null){
              obj.total.avgCPT.amount = '¥'+(Number(obj.total.avgCPT.amount).toFixed(2))
            }
            //平均每次转化费用
            if(obj.total.avgCPA!=null){
              obj.total.avgCPA.amount = '¥'+(Number(obj.total.avgCPA.amount).toFixed(2))
            }
            //平均每千次展示费用
            // if(obj.total.avgCPM!=null){
            //   obj.total.avgCPM.amount = '¥'+(Number(obj.total.avgCPM.amount).toFixed(2))
            // }
            //点击率
            if(obj.total.ttr!=null){
              obj.total.ttr = ((Number(obj.total.ttr)*100).toFixed(2))+'%'
            }
            //转化率
            if(obj.total.conversionRate!=null){
              obj.total.conversionRate = ((Number(obj.total.conversionRate)*100).toFixed(2))+'%'
            }

          })
          _this.userSetCustom()
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          }) 
        }
      })
    },

    //排序
    handlesortChange(column) {
      console.log(column)
      let _this = this,orderBy = [],localSpend = '',columnOrder = ''
      columnOrder = column.prop
      // metadata.defaultBidAmount.amount
      // metadata.cpaGoal.amount
      if(column.prop == 'defaultBidAmount'||column.prop == 'cpaGoal'){
        this.localSpend = column.prop
      }else{
        _this.orderByList.forEach((val,i)=>{
          if(columnOrder.indexOf(val.orderBy)!=-1){
            _this.localSpend = val.orderBy
          }
        })
      }
      
      // this.localSpend = column.prop
      if(column.order == 'descending'){
        this.dOrder = 'DESCENDING'
        orderBy.push({'field':this.localSpend,'sortOrder':this.dOrder})
      }else if(column.order == 'ascending'){
        this.dOrder = 'ASCENDING'
        orderBy.push({'field':this.localSpend,'sortOrder':this.dOrder})
      }
      this.listParams.selector.orderBy = orderBy
      this.grouplistFn()     
    },
         
    // 删除
    deletePlanFn(row){
      let _this = this,params={}
      params = {
        'deptId':_this.deptId,
        'userId':_this.userId,
        'userName':_this.userName,
        'adgroupId':row.metadata.adGroupId,
        'campaignId':row.metadata.campaignId.toString(),
        'orgId':row.metadata.orgId.toString()
      }
      $http.axios.post("/api/apple/adGroup/deleteGroups",params).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          _this.grouplistFn()
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msgntent,
            type: 'error'
          })  
        }   
      })
    },

    //进入关键词列表
    enterKw(row){
      console.log(row)
      if(this.changeSupplySources == ''){
        this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:this.campaignId,campaignName:this.campaignName,
          adGroupId:row.metadata.adGroupId,adGroupName:row.metadata.adGroupName,
          supplySources:this.supplySources,appId:this.appId,
          budget:this.budget
        }})
      }else{
        this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:this.campaignId,campaignName:this.campaignName,
          adGroupId:row.metadata.adGroupId,adGroupName:row.metadata.adGroupName,
          supplySources:this.changeSupplySources,appId:this.appId,
          budget:this.budget
        }})
      }
    }, 

    //预警预算
    changePriceStatus(value){
      console.log(value)
      this.exRadio = value
      if(value== 2){
        this.pricetof = false
        this.pricetof01 = false
        this.pricetof02 = true
      }else{
        this.pricetof = true
        this.pricetof01 = true
        this.pricetof02 = true
      }
    },

    changetod(val){
      console.log(val)
      this.exRadioto = val
      if(val == 3){
        this.topAndDown02 = ''
        this.topAndDownNum02 = ''
        this.pricetof01 = false
        this.pricetof02 = true

      }else{
        this.topAndDown = ''
        this.topAndDownNum = ''
        this.pricetof01 = true
        this.pricetof02 = false
      }
    },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e.id)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选广告组',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },

    //获取分配人员列表
    getFpyjList328(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
        if(response.data.code == 200){
          that.warnEigth = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    getFpyjList329(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
        if(response.data.code == 200){
          that.warnNine = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change预警人
    changeWarning(event){
      let changeWarningPerson = []
      event.forEach(function(val,i){
        changeWarningPerson.push(val.userId)
      })
      this.changeWarningPerson = changeWarningPerson.toString()
    },

    //取消预警弹框
    accountWarningCancel(){
      this.budgetRuleForm.warningPerson = []
      this.radio = '1'
      this.budgetRuleForm.newOperator = 1//操作符
      this.budgetRuleForm.warnNumTimes = 3//预警次数
      this.budgetRuleForm.priority = 1//优先级
      this.topAndDown = ''//数值的上调下调
      this.topAndDownNum = ''//数值
      this.topAndDown02 = ''//百分比的上调下调
      this.topAndDownNum02 = ''//百分比
      this.pricetofAll = true//调日预算的整个选框
      this.pricetofNumber = true//数值
      this.pricetofPercent = true//百分比
      this.dialogFormVisible = false
    },

    //change预警中的调整默认最高出价
    changePercent(val){
      this.executeRate = ''
      this.exNum = val
      if(this.exNum == 1){
        this.executeDiff = this.topAndDownNum
      }else{
        this.executeDiff = -(this.topAndDownNum)
      }
      console.log(this.executeDiff)
    },
    blurTopAndDownNum(){
      if(this.exNum == 1){
        this.executeDiff = this.topAndDownNum
      }else{
        this.executeDiff = -(this.topAndDownNum)
      }
      console.log(this.executeDiff)
    },

    changeTopAndDown02(val){
      this.executeDiff = ''
      this.exPercent = val
      if(this.exPercent == 1){
        this.executeRate = this.topAndDownNum02/100
      }else{
        this.executeRate = -(this.topAndDownNum02/100)
      }
      console.log(this.executeRate)
    },
    blurTopAndDownNum02(){
      if(this.exPercent == 1){
        this.executeRate = this.topAndDownNum02/100
      }else{
        this.executeRate = -(this.topAndDownNum02/100)
      }
      console.log(this.executeRate)
    },

    //预警确定
    accountWarning(){
      let _this = this
      _this.warnParams.userId = _this.userId
      _this.warnParams.userName = _this.userName
      _this.warnParams.deptId = _this.deptId
      _this.warnParams.ownerId = _this.warnOwnerId
      _this.warnParams.dataId = Number(_this.groupId) //数据id
      _this.warnParams.adPlanId = Number(_this.campaignId)//计划系列id
      _this.warnParams.adGroupId = Number(_this.groupId)//广告组id
      _this.warnParams.type = 3 //1-系列组 2-系列 3-广告组
      _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
      _this.warnParams.warnId = _this.changeWarningPerson//预警人id
      // _this.newWarnStartTime = _this.getDateTime(_this.budgetRuleForm.warningTime[0])
      // _this.newWarnEndTime = _this.getDateTime(_this.budgetRuleForm.warningTime[1])
      _this.warnParams.startTime = _this.newWarnStartTime//开始时间
      _this.warnParams.endTime = _this.newWarnEndTime//结束时间
      _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
      _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
      _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
      if(_this.numberDetail == 1){
        _this.executeDiff = _this.topAndDownNum
      }else{
        _this.executeDiff = -(_this.topAndDownNum)
      }
      if(_this.percentDetail == 1){
        _this.executeRate = _this.topAndDownNum02/100
      }else{
        _this.executeRate = -(_this.topAndDownNum02/100)
      }
      _this.warnParams.executeRate = _this.executeRate//执行比率 下调10% => -0.1
      _this.warnParams.executeDiff = _this.executeDiff//执行增减差额 下调100 => -100

      $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
        if(response.data.code == 200){
          _this.dialogFormVisible = false
          _this.dialogWarningDetail = false
          _this.accountWarningCancel()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警详情
    warningDetail(row){
      console.log(row)
      this.row = row
      this.dialogWarningDetail = true
      this.warnOwnerId = this.ownerId
      // this.warnPlanId = this.planId
      let _this = this
      $http.axios.get("/api/apple/warn/query?adType=3&dataId="+_this.groupId).then(function(response) {
        if(response.data.code == 200){
         console.log(response)
         _this.warnInfosTableData = response.data.data
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警修改
    warnEdit(row){
      console.log(row)
      //拼接预警人id
      this.getFpyjList328()
      this.getFpyjList329()
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      this.budgetRuleForm.clearHistory = 1//默认清除当初预警次数
      this.dialogEditVisible = true
      let warnId = [],warnName = [],_this = this,infos = []
      infos = row.infos//获取观察指标和执行条件
      // infos = _this.warnInfosTableData.infos//获取观察指标和执行条件
      console.log(infos)
      warnId = row.warnId.split(",")
      _this.budgetRuleForm.warningPerson = []
      //回显数据
      _this.warningPersonOption.forEach(function(val,i){
        warnId.forEach(function(item,index){
          if(val.userId == item){
            _this.budgetRuleForm.warningPerson.push({'userId':item})
          }
        })
      })//预警人
      _this.warnParams.infos = []
      infos.forEach(function(obj,index){
        console.log(obj)
        _this.warnParams.infos.push({
          target:obj.target,
          op:obj.op,
          val:obj.val
        })
      })//观察指标和执行条件
      _this.budgetRuleForm.newOperator = row.operate //操作符
      //开始结束时间回显
      const date = new Date();
      let nowDate = ''
      nowDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;
      _this.budgetRuleForm.warningTime[0] = new Date(nowDate+" "+row.startTime+":00")///开始结束时间
      _this.budgetRuleForm.warningTime[1] = new Date(nowDate+" "+row.endTime+":00")///开始结束时间
      //开始结束时间传参
      _this.newWarnStartTime = row.startTime///开始结束时间
      _this.newWarnEndTime = row.endTime///开始结束时间
      _this.radio = String(row.executeStrategy) //执行策略
      _this.changeWarningPerson = row.warnId
      _this.warningId = row.id
      _this.budgetRuleForm.warnNumTimes = row.warnCount//预警次数
      _this.budgetRuleForm.priority = row.priority//优先级
      if(String(row.executeStrategy) == '4'){
        _this.executeValue = '4'
        _this.pricetofAll = false//调日预算的整个选框
        _this.pricetofNumber = false//数值
        _this.pricetofPercent = true//百分比
        //数值
        if(row.executeDiff != 0){
          _this.topAndDown02 = ''
          _this.topAndDownNum02 = ''
          _this.pricetofNumber = false//数值
          _this.pricetofPercent = true//百分比
          _this.priceRadio = '1'
          if(row.executeDiff>0){
            _this.topAndDown = 1
            _this.numberDetail = _this.topAndDown
            _this.topAndDownNum = row.executeDiff
            _this.executeDiff = _this.topAndDownNum
          }else{
            _this.topAndDown = 2
            _this.numberDetail = _this.topAndDown
            _this.topAndDownNum = -(row.executeDiff)
            _this.executeDiff = -(_this.topAndDownNum)
          }
        }else{
          _this.priceRadio = '2'
          _this.pricetofNumber = true//数值
          _this.pricetofPercent = false//百分比
          _this.executeDiff = 0
        }
        //百分比
        if(row.executeRate != 0){
          _this.topAndDown = ''
          _this.topAndDownNum = ''
          _this.pricetofNumber = true//数值
          _this.pricetofPercent = false//百分比
          _this.priceRadio = '2'
          if(row.executeRate>0){
            _this.topAndDown02 = 1
            _this.percentDetail = _this.topAndDown02
            _this.topAndDownNum02 = row.executeRate*100
            _this.executeRate = row.executeRate
          }else{
            _this.topAndDown02 = 2
            _this.percentDetail = _this.topAndDown02
            _this.topAndDownNum02 = -(row.executeRate*100)
            _this.executeRate = -(row.executeRate)
          }
        }else{
          _this.priceRadio = '1'
          _this.pricetofNumber = false//数值
          _this.pricetofPercent = true//百分比
          _this.executeRate = 0
        }

      }else{
        _this.pricetofAll = true//调日预算的整个选框
        _this.pricetofNumber = true//数值
        _this.pricetofPercent = true//百分比
        _this.topAndDown = ''//数值的上调下调
        _this.topAndDownNum = ''//数值
        _this.topAndDown02 = ''//百分比的上调下调
        _this.topAndDownNum02 = ''//百分比
      }
    },

    //预警修改
    accountWarningEdit(){
      let _this = this
      _this.warnParams.userId = _this.userId
      _this.warnParams.userName = _this.userName
      _this.warnParams.deptId = _this.deptId
      _this.warnParams.ownerId = _this.warnOwnerId
      _this.warnParams.dataId = Number(_this.groupId) //数据id
      _this.warnParams.adPlanId = _this.groupId//广告组id
      _this.warnParams.adPlanId = _this.campaignId//计划系列id
      _this.warnParams.type = 3 //1-系列组 2-系列 3-广告组
      _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
      _this.warnParams.warnId = _this.changeWarningPerson//预警人id
      _this.warnParams.startTime = _this.newWarnStartTime//开始时间
      _this.warnParams.endTime = _this.newWarnEndTime//结束时间
      _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
      _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
      _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
      if(_this.numberDetail == 1){
        _this.executeDiff = _this.topAndDownNum
      }else{
        _this.executeDiff = -(_this.topAndDownNum)
      }
      if(_this.percentDetail == 1){
        _this.executeRate = _this.topAndDownNum02/100
      }else{
        _this.executeRate = -(_this.topAndDownNum02/100)
      }
      _this.warnParams.executeRate = _this.executeRate//执行比率 下调10% => -0.1
      _this.warnParams.executeDiff = _this.executeDiff//执行增减差额 下调100 => -100
      _this.warnParams.id = Number(_this.warningId)//修改时候的传id
      _this.warnParams.clearHistoryPermitsFlag = Number(_this.budgetRuleForm.clearHistory)//是否清空预警次数
      $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
        if(response.data.code == 200){
          _this.dialogEditVisible = false
          _this.dialogWarningDetail = false
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改预警状态
    changeWarnStatus(row){
      console.log(row)
      let _this = this,params={}
      params={
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        'dataId':Number(_this.groupId),//账户id
        'type':3,//1-系列组 2-系列 3-广告组
        'infos':row.infos,//监控条件明细
        'executeStrategy':row.executeStrategy,//执行策略
        'warnId':row.warnId,//预警人
        'startTime':row.startTime,//开始时间
        'endTime':row.endTime,//结束时间
        'adGroupId':Number(_this.groupId),
        'adPlanId':Number(_this.campaignId),
        'operate':row.operate,
        'executeRate':row.executeRate,
        'executeDiff':row.executeDiff,
        'clearHistoryPermitsFlag':1,//修改时候传1-暂时
        'warnCount':row.warnCount,//预警次数 默认3
        'priority':row.priority,//优先级
        'id':Number(row.id),//修改时候的id
        'status':Number(row.status),
      }
      $http.axios.post("/api/apple/warn/insertOrUpdate",params).then(function(response) {
        if(response.data.code == 200){
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //账户更新
    accountUpdate(row){
      this.dialogAccountUpdate = true
      this.accountUpdateForm.recharge = row.lastRecharge
      if(row.status == 0){
        this.accountUpdateForm.status = true
      }else{
        this.accountUpdateForm.status = false
      }
      this.appleOwnerIdUpdate = row.ownerId
      this.appleStatus = row.status
    },

    //change状态
    changeStatus(event){
      if(event == true){
        this.appleStatus = 0
      }else{
        this.appleStatus = 1
      }
    },

    //账户更新-确定
    accountUpdateSure(){
      let _this = this,params={}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        // 'adPlanId':,
        // 'adGroupId':,
        'status':_this.appleStatus,
        'defaultBidAmount':_this.accountUpdateForm.balance,//默认出价
        'cpaGoal':_this.accountUpdateForm.recharge,//cpa出价
        'currency':_this.accountUpdateForm.currency //货币，目前固定位RMB   
      }
      $http.axios.post("/api/apple/adGroup/update",params).then(function(response) {
        if(response.data.code == 200){
          _this.dialogAccountUpdate = false
          // _this.appleAccountList()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警
    accountWarnDialog(){
      this.dialogFormVisible = true
      this.getFpyjList328()
      this.getFpyjList329()
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      // this.accountWarningCancel()
      this.exRadio = 1
      if(this.exRadio == 1){
        this.pricetof = true
        this.pricetof01 = true
        this.pricetof02 = true
      }
    },

    editrDefaultBid(scope){
      this.defaultBidAmountGroup = scope.row.metadata.defaultBidAmount.amount
    },

    editrCpaGoal(scope){
      this.cpaGoalGroup = scope.row.metadata.cpaGoal.amount
    },

    handleConfirm(scope) {
      console.log(scope)
      scope._self.$refs[`popover-${scope.$index}`].doClose()
      scope._self.$refs[`popover-${scope.$index}`].showPopper = false
    },

    handleConfirm() {
      document.body.click() // 模拟点击页面其它部分关掉弹层，因为该页面列表使用懒加载导致doClose无效
    },

    handleConfirmSure(scope,index) {
      console.log(index)
      console.log(scope)
      let _this = this,params={}
      if(index == 1){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':scope.row.metadata.orgId,
          'adPlanId':scope.row.metadata.campaignId,
          'adGroupId':scope.row.metadata.adGroupId,
          'defaultBidAmount':Number(_this.defaultBidAmountGroup),//默认最高出价
          'currency':'RMB'
        }
      }else if(index == 2){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':scope.row.metadata.orgId,
          'adPlanId':scope.row.metadata.campaignId,
          'adGroupId':scope.row.metadata.adGroupId,
          'cpaGoal':Number(_this.cpaGoalGroup),//默认最高出价
          'currency':'RMB'
        }
      }
      $http.axios.post("/api/apple/adGroup/update",params).then(function(response) {
        if(response.data.code == 200){
          scope._self.$refs[`popover-${scope.$index}`].doClose()
          scope._self.$refs[`popover-${scope.$index}`].showPopper = false
          _this.grouplistFn()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //创建广告组跳转
    createGroup(){
      if(this.changeSupplySources == ''){
        this.$router.push({path:'/plan-management/createAdvertPlan',
        query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,type:1,
          supplySources:this.supplySources,appId:this.appId,budget:this.budget,tab:1}
        })
      }else{
        this.$router.push({path:'/plan-management/createAdvertPlan',
        query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,type:1,
          supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget,tab:1}
        })
      }
      
    },

    //change列表状态
    changeListStatus(row){
      console.log(row)
      // NOT_RUNNING
      // RUNNING
      let _this = this,params={},status = ''
      if(row.metadata.adGroupServingStatus == 'NOT_RUNNING'){
        status = 2
      }else{
        status = 1
      }
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        'adPlanId':row.metadata.campaignId,
        'adGroupId':row.metadata.adGroupId,
        'status':status
      }
      $http.axios.post("/api/apple/adGroup/update",params).then(function(response) {
        if(response.data.code == 200){
          _this.grouplistFn()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改广告组
    editGroup(row){
      // console.log(row)
      if(this.changeSupplySources == ''){
        this.$router.push({path:'/plan-management/editAdvertGroup', 
        query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adgroupId:row.metadata.adGroupId,adgroupName:row.metadata.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget,tab:1
          }
        })
      }else{
        this.$router.push({path:'/plan-management/editAdvertGroup', 
        query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adgroupId:row.metadata.adGroupId,adgroupName:row.metadata.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget,tab:1
          }
        })
      }
      
    },

    //获取计划系列数据
    getAdplanList(){
      let _this = this
      $http.axios.get("/api/apple/adPlan/getCampaignList/"+_this.ownerId).then(res=> {
        if(res.data.code==200){
          _this.adplanModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

     //预警-选择观察指标-新增
     changeChecked(){
      // this.warnParams.infos.push({})
      this.warnParams.infos.push({
        target:'',
        op:'',
        val:''
      })
    },

    //预警-选择观察指标-删除
    removeRowpl(index) {
      if (index >= 0) {
        this.warnParams.infos.splice(index, 1);
      }
    },

    getDateTime(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return h + ':' + m;
      }
      return ''
    },

    //change预警-开始结束时间
    changeTimes(val){
      this.budgetRuleForm.warningTime = val
      this.newWarnStartTime = this.getDateTime(val[0])
      this.newWarnEndTime = this.getDateTime(val[1])
    },
    changeEditTimes(val){
      this.budgetRuleForm.warningTime = val
      this.newWarnStartTime = this.getDateTime(this.budgetRuleForm.warningTime[0])
      this.newWarnEndTime = this.getDateTime(this.budgetRuleForm.warningTime[1])
    },

    //change执行策略-显示隐藏问题
    changeExecute(val){
      this.executeValue = val
      if(val == '4'){
        this.priceRadio = '1'
        this.pricetofAll = false//调日预算的整个选框
        this.pricetofNumber = false//数值
        this.pricetofPercent = true//百分比
      }else{
        this.pricetofAll = true//调日预算的整个选框
        this.pricetofNumber = true//数值
        this.pricetofPercent = true//百分比
        this.topAndDown = ''//数值的上调下调
        this.topAndDownNum = ''//数值
        this.topAndDown02 = ''//百分比的上调下调
        this.topAndDownNum02 = ''//百分比
      }
    },

    //change调日预算中的数值和百分比
    changeNumOrPercent(val){
      if(this.executeValue == '4'){
        if(val == '1'){
          this.pricetofNumber = false//数值
          this.pricetofPercent = true//百分比
          this.topAndDown02 = ''//百分比的上调下调
          this.topAndDownNum02 = ''//百分比
        }else{
          this.pricetofNumber = true//数值
          this.pricetofPercent = false//百分比
          this.topAndDown = ''//数值的上调下调
          this.topAndDownNum = ''//数值
        }
      }
    },

    //change调日预算--上调下调数值
    changeNumber(val){
      // topAndDownNum
      this.numberDetail = val
      if(val == 1){//上调
        this.executeDiff = this.topAndDownNum
      }else{
        this.executeDiff = -(this.topAndDownNum)
      }
      console.log(this.numberDetail)
    },

    //change调日预算--上调下调百分比
    changePercent(val){
      this.percentDetail = val
      if(val == 1){
        this.executeRate = this.topAndDownNum02/100
      }else{
        this.executeRate = -(this.topAndDownNum02/100)
      }
      console.log(this.percentDetail)
    },

    //广告组名称进入广告列表
    enterAdvert(row){
      console.log(row)
      if(this.changeSupplySources == ''){
        this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:this.campaignId,campaignName:this.campaignName,
          adGroupId:row.metadata.adGroupId,adGroupName:row.metadata.adGroupName,
          supplySources:this.supplySources,appId:this.appId,
          budget:this.budget
        }})
      }else{
        this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:this.campaignId,campaignName:this.campaignName,
          adGroupId:row.metadata.adGroupId,adGroupName:row.metadata.adGroupName,
          supplySources:this.changeSupplySources,appId:this.appId,
          budget:this.budget
        }})
      }
    },

    //批量上传关键词
    batchUploadKw(params){
      // this.exprtVisible = true
      let _this = this,data = new FormData();
      $http.axios.defaults.timeout = 500000
      data.append('file',params.file);
      data.append('deptId',_this.deptId);
      data.append('orgId',_this.ownerId);
      data.append('userId',_this.userId);
      data.append('userName',_this.userName);
      $http.axios.post("/api/apple/keyWordFile/uploadKeyWordFile",data).then(function(response) {
        _this.$message({
          showClose: true,
          message: response.data.msg,
          type: 'success'
        })
      })
    },

    //批量上传否定关键词
    batchUploadNegKw(params){
      // this.exprtVisible = true
      let _this = this,data = new FormData();
      $http.axios.defaults.timeout = 500000
      data.append('file',params.file);
      data.append('deptId',_this.deptId);
      data.append('orgId',_this.ownerId);
      data.append('userId',_this.userId);
      data.append('userName',_this.userName);
      $http.axios.post("/api/apple/keyWordFile/uploadGroupNegativekeywordsFile",data).then(function(response) {
        _this.$message({
          showClose: true,
          message: response.data.msg,
          type: 'success'
        })
      })
    },

    //自定义
    zdyFn(){
      this.$refs.zdyApple.generateTimes(2,this.ownerId)
    },

    //下载关键词模板
    downLoadExcel(){
      window.location.href = "https://oppo-ad.oss-cn-beijing.aliyuncs.com/asakeyword/%E5%85%B3%E9%94%AE%E8%AF%8D%E6%A8%A1%E6%9D%BF.xlsx"
    },

    //下载否定关键词模板
    downLoadNegExcel(){
      window.location.href = "https://oppo-ad.oss-cn-beijing.aliyuncs.com/asakeyword/%E5%B9%BF%E5%91%8A%E7%BB%84%E5%90%A6%E5%AE%9A%E5%85%B3%E9%94%AE%E8%AF%8D.xlsx"
    },

    //用户已经设置的自定义列
    userSetCustom(){
      let _this = this,titleList = [],columnName = ''
      $http.axios.post("/api/apple/CustomColumn/getColumnsByOrgId?customType=2&orgId="+Number(_this.ownerId)).then(res=> {
        if(res.data.code == 200){
          titleList = res.data.data
          _this.orderByList = res.data.data
          _this.titleList = []
          if(res.data.data != null){
            titleList.forEach((val,i)=>{
              _this.titleList.push({'name':val.name,'prop':val.columnNameLink}) 
            })
          }
          
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },



  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  /* .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  } */
  .priceStyle{
    display: block;
    margin:10px 0;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
    font-size:14px;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }
  .detail{
    color:#409eff;
  }
  .loading{
    text-align:center;
  }
  .loading span,.loading i{
    display: block;
  }
  .loading span{
    font-size:20px;
    font-weight:700;
    color:#fff;
  }
  .loading i{
    margin-top:10px;
    font-size:60px;
    /* color: #409eff; */
    color:#fff;
  }
  /* .loadingBg{
    margin-top:200px;
  } */
  ::v-deep .loadingBg .el-dialog{
    margin-top:200px;
    background:rgba(255,255,255,0)!important;
    box-shadow: none;
  }
  .listDetail span{
    display: inline-block;
    vertical-align:middle;
    font-size:12px;
    color:#666;
    /* margin-right:20px; */
  }
  .listDetail span i{
    font-style: normal;
  }
  /*点击名称进入下一个列表*/
  .ownerNameColor{
    color:#2F674D;
    font-weight:500;
  }
  /*预警-新*/
  .newAddfpStyle{
    position: relative;
  }
  .newReduceBtnStyle{
    position:absolute;
    top:50%;
    right:0;
    transform: translate(0,-50%);
  }
  .warnInfosList span i{
    font-style: normal;
    font-weight:500;
  }
  .batchUpload{
    display:inline-block;
    vertical-align:middle;
  }
  /*新版*/
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  /*新预警*/
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .queryLeft,.queryRight{
    display: inline-block;
    vertical-align: middle;
  }
  .queryLeft{
    width:65%;
  }
  .queryRight{
    width:35%;
    text-align:right;
  }
  ::v-deep .dateStyle.el-range-editor.el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .dateStyle.el-range-editor .el-range-input{
    background:#F5F7F6!important;
  }
  ::v-deep .queryLeft .el-input--mini .el-input__inner{
    width:160px;
    border:none;
    background:#F5F7F6;
  }
  ::v-deep .materialHead .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .materialHead{
    padding-bottom:20px;
  }
  .queryRight span i{
    font-style:normal;
    color:#999;
  }
  ::v-deep .queryLeft .el-button + .el-button{
    margin-left:0!important;
  }
</style>
