<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item  :to="{ path: '/auth-management/homePage' }">首页管理</el-breadcrumb-item>
          <el-breadcrumb-item>登录日志</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
          <div class="userList">
            <span class="role_headerChecked role_headerChecked_user">登录日志</span>
            <div class="userform">
              <el-form :model="ruleForm" :inline="true" ref="ruleForm" class="demo-form-inline">
                <el-form-item label="" prop="qdvalue">
                  <el-select v-model="ruleForm.qdvalue" placeholder="请选择渠道名称" @change="changeChannelName">
                    <el-option
                      v-for="item in qdoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="bmsshvalue">
                  <el-select v-model="ruleForm.bmsshvalue" filterable clearable placeholder="请选择所属部门" @change="changeDeptName">
                    <el-option
                      v-for="(item,i) in bmsshoptions"
                      :key="i"
                      :label="item.depyName"
                      :value="item.depyName">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="comName" style="margin-right:10px;">
                  <el-autocomplete
                    class="inline-input"
                    v-model="ruleForm.comName"
                    value-key="userName"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入人员姓名"
                    @select="handleSelect"
                  ></el-autocomplete>
                </el-form-item>
                <el-form-item label="" prop="dateChoice" class="positionBox">
                  <el-date-picker
                    v-model="ruleForm.dateChoice"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>    
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" style="margin-left:20px;" @click="getloginJournal(1)">查询</el-button>
                  <el-button type="info" plain size="small" @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="userTable">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <!-- <el-table-column
                  prop="channelName"
                  label="渠道名称"
                  width="180">
                  <template slot-scope="scope">{{scope.row.channelName}}</template>
                </el-table-column>
                <el-table-column
                  prop="username"
                  label="所属部门"
                  width="180">
                  <template slot-scope="scope">{{scope.row.deptName}}</template>
                </el-table-column>
                <el-table-column
                  prop="userPosition"
                  label="人员职位"
                  width="180">
                  <template slot-scope="scope">{{scope.row.postName}}</template>
                </el-table-column> -->
                <el-table-column
                  prop="userManage"
                  label="人员姓名"
                  width="180">
                  <template slot-scope="scope">{{scope.row.userName}}</template>
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="登录时间"
                  width="200">
                  <template slot-scope="scope">{{scope.row.accessTime}}</template>
                </el-table-column>
                <el-table-column
                  prop="IPaddress"
                  label="IP地址"
                  show-overflow-tooltip>
                  <template slot-scope="scope">{{scope.row.ipaddr}}</template>
                </el-table-column>
              </el-table>
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
                </el-pagination>
              </div>
            </div>
          </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
      <accessDialog :visible.sync="showDialog" ></accessDialog>
    </div>
  </template>
  
  <script>
  import accessDialog from '@/views/dialog/accountDialog';
  import echarts from  "echarts";
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'loginJournal',
    components: {
      accessDialog
    },
    data() {
      return {
        showDialog: false,
        qdoptions:[],
        bmsshoptions:[],
        comAccountID:'',
        ruleForm:{
          qdvalue:'',
          bmsshvalue:'',
          comName:'',
          dateChoice:'',
        },
        
        tableData: [],
        multipleSelection:[],
        
        dialogFormVisible: false,
        dialogFormVisible_account:false,
        distribution_form: {
          name: '',
          regionzu: '',
          regionshi: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        account__form: {
          name: '',
          regionID: '',
          regionyhs:'',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        loginJournalParams:{
          pageSize:'',
          pageNum:''
        },
        queryLogParams:{
          channelName:'',
          deptName:'',
          userName:'',
          params:{
            beginTime:'',
            endTime:''
          }
        },




      }
    },
    computed: {
       
    },
    mounted() {
      this.getloginJournal()
      this.getChannelList()
      this.getDeptList()
    },
    // created() {
    //   var params = {
    //     pageNum: this.pageNum,
    //     pageSize: this.pageSize
    //   }
    //   this.findAll(params)
    // },
    methods: {
      //头部表单重置
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
        // this.getloginJournal()
        this.$router.go(0)
      },

      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.userName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      handleSelect(item) {
        console.log(item);
      },

      //登陆日志列表
      getloginJournal(page){
        let that = this
        if(page == 1){
          that.pageNum = 1
        }
        that.queryLogParams.userName = that.ruleForm.comName
        that.queryLogParams.params.beginTime = that.ruleForm.dateChoice[0]
        that.queryLogParams.params.endTime = that.ruleForm.dateChoice[1]
        $http.axios.post("/api/channel/logininfor/list?pageNum="+that.pageNum+"&pageSize="+that.pageSize,that.queryLogParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取渠道列表
      getChannelList(){
        let that = this
        $http.axios.get("/api/system/user/getChannelList").then(function(response) {
          if(response.data.code == 200){
            that.qdoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取部门列表
      getDeptList(){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
          if(response.data.code == 200){
            console.log(response)
            that.bmsshoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      changeChannelName(event){
        this.queryLogParams.channelName = String(event)
      },
      changeDeptName(event){
        this.queryLogParams.deptName = String(event)
      },

      //查询日志列表
      queryLogList(){
        let that = this
        that.queryLogParams.userName = that.ruleForm.comName
        that.queryLogParams.params.beginTime = that.ruleForm.dateChoice[0]
        that.queryLogParams.params.endTime = that.ruleForm.dateChoice[1]
        $http.axios.post("/api/channel/logininfor/list?pageNum="+that.pageNum+"&pageSize="+that.pageSize,that.queryLogParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = []
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },




    
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      goToDetail(){
        this.$router.replace('/auth-management/userDetail')
      },


    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      this.getloginJournal()
    },
    }
  }
  </script>
  
  <style lang="scss">
    /* .userform .el-input{
      display:inline-block;
      margin:0!important;
      margin-right:1%;
    } */
    .userform .el-select{
      margin-right:10px;
    }
    .userTable{
      margin-top:25px;
    }
    .userTable .el-table .cell{
      text-align:center;
    }
    .el-table th{
      /* background:#f1f8ff; */
      color:#1b1f23;
      background:$poc-color-primary-deputy;

    }
    .footer_paging{
      margin:20px 0 4px;
    }
    .footer_totalNums{
      font-size:12px;
      color:#666;
      padding-left:5px;
      padding-right:20px;
    }
    .footerPaging {
      float:right;
    }
    .userBtn{
      padding:0 5px;
      cursor:pointer;
    }
    .userBtn_delete{
      color:red;
    }
  </style>
