<template>
  <el-dialog
    :visible.sync="show"
    width="400px"
    :close-on-click-modal="false"
    @close="onCancel"
    class="icon_custom_popup"
    title="拒绝原因"
  >
    <div class="icon">
      <img src="@/assets/img/juJueIcon.png" />
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="reason">
        <el-input
          type="textarea"
          :rows="6"
          v-model="ruleForm.reason"
          placeholder="请填写拒绝原因（必填）"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button :loading="loading" @click="onCancel">{{
        $t("dc:取消")
      }}</el-button>
      <el-button type="primary" :loading="loading" @click="onConfirm('ruleForm')">{{
        $t("dc:保存")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";

export default {
  props: {
    pubVue: Object,
    title: String,
    visible: {
      type: Boolean,
      default: false,
    },
    id: String,
    type: Number,
    typeName: String,
    dataSourceIs: Boolean,
    keywordsIs: Boolean,
    dataSource: Array,
    keywords: Array,
    path: String,
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      ruleForm: {
        reason: "",
      },

      rules: {
        reason: [
          { required: true, message: "请输入拒绝原因", trigger: "blur" },
        ],
      },
      wordsA: [],
    };
  },

  watch: {
    show(v) {
      this.$emit("update:visible", v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
  },
  methods: {
    onConfirm(formName) {
      let _this = this,
        sourceIs = false,
        keyIs = false,
        sourceNum = 0,
        keyNum = 0;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("成功2");
          console.log("选中");
          console.log(_this.ruleForm);
          if (_this.typeName == "工单") {
            _this.refuseDemandFn();
          } else if (_this.typeName == "素材") {
            _this.mediaRefuseFn();
          }
        }
      });
      console.log(_this.ruleForm);
    },
    onCancel() {
      this.show = false;
      this.$refs.ruleForm.resetFields();
      console.log(this.type + "关闭");
    },

    // {
    //   "materialId": 0,
    //   "tips": ""
    // }
    // /material/mediaRefuse 媒体拒绝
    mediaRefuseFn() {
      let _this = this,
        params = {};
      params = {
        materialId: _this.id,
        tips: _this.ruleForm.reason,
        userName: _this.$sessionStorage.getItem(config.USERNAME),
      };
      $http.axios
        .post("/api/operationManagement/material/mediaRefuse", params)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.onCancel();
            _this.pubVue.getMaterialList();
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
    // {
    //   "id": 0,
    //   "msg": "",
    //   "type": 0
    // }
    //需求工单拒绝
    refuseDemandFn() {
      let _this = this,
        params = {};
      params = { id: _this.id, msg: _this.ruleForm.reason, type: _this.type };
      $http.axios
        .post("/api/operationManagement/materialDemand/refuseDemand", params)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.onCancel();
            _this.pubVue.getDemandOrder();
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

