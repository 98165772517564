// 时间戳转换器
function formatDate(now,time) { 
  var year=now.getFullYear(); 
  var month=now.getMonth()+1>9?now.getMonth()+1:'0'+(now.getMonth()+1); 
  var date=now.getDate()>9?now.getDate():'0'+now.getDate(); 
  var hour=now.getHours()>9?now.getHours():'0'+now.getHours(); 
  var minute=now.getMinutes()>9?now.getMinutes():'0'+now.getMinutes(); 
  var second=now.getSeconds()>9?now.getSeconds():'0'+now.getSeconds(); 
  if(time == 'time'){
    return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second; 
  }else{
    return year+"-"+month+"-"+date; 
  }
} 
export default formatDate