<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="materialHead">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionGaode' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionGaode' }">账户选择</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div> 
          <div class="copySelect">
            <el-form :model="ruleForm" :inline="true" ref="ruleForm" class="demo-form-inline">
              <el-form-item label="">
                <el-input v-model = "ruleForm.copyPlanID" clearable placeholder="请输入计划ID"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-autocomplete
                    class="inline-input"
                    v-model="ruleForm.copyPlanName"
                    value-key="planName"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入计划名称">
                  </el-autocomplete>
                <!-- <el-input v-model = "ruleForm.copyPlanName" clearable placeholder="请输入计划名称"></el-input> -->
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" class="newQuery" size="small" @click="getCopyList(1)">查询</el-button>
                <el-button type="info" size="small" @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              prop="planId"
              label="计划ID">
            </el-table-column>
            <el-table-column
              prop="planName"
              label="计划名称"
              width="200">
            </el-table-column>
            <el-table-column
              prop="copyNumber"
              label="复制份数">
            </el-table-column>
            <el-table-column
              prop="createrName"
              label="创建者">
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              width="200">
              <template slot-scope="scope">
                <span>{{scope.row.createTime | dateTimeFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待执行</span>                  
                <span v-else-if="scope.row.status == 1">执行中</span>                  
                <span v-else-if="scope.row.status == 2">执行完成</span>                  
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="执行结果">
              <template slot-scope="scope">
                <span v-if="scope.row.status ==1">
                  <!-- <el-tooltip class="item" effect="dark"  placement="right-end">
                    <div slot="content" v-html="scope.row.errorMessage"></div>    -->
                    <!-- <i class="el-icon-warning-outline"></i> -->
                  <!-- </el-tooltip> -->
                </span>
                <span v-else-if="scope.row.errorMessage==''&&scope.row.status ==2">复制成功</span>
                <span v-else-if="scope.row.errorMessage!=null&&scope.row.status ==2">
                  <el-tooltip class="item" effect="dark"  placement="right-end">
                    <div slot="content" v-html="scope.row.errorMessage"></div>   
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="footerPaging">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.totalRow">
          </el-pagination>
          </div>
        </div>
      </template>
    </PocCardTable>
  </div>
</template>
<script>
import Api from "@/api";
import { $http } from '@/api/http';
import config from '@/api/config';
// import breakTitle from "./breakHeader";//面包屑导航
export default {
  name: "copyPage",
  data() {
    return {
      // breakTitle:['计划管理','账户选择','广告计划'],
      tableData:[],
      total:0,
      ownerId:null,
      // 分页
      pageNum: 1,//当前页
      pageSize: 20,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      ruleForm:{
        copyPlanID:'',
        copyPlanName:''
      }
    }
  },
  components:{
    // breakTitle,//面包屑导航
  },
  mounted(){
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = Number(this.$route.query.ownerId)
    this.getCopyList()
  },
  methods: {
    //复制计划记录列表
    getCopyList(){
      let _this = this,params = {}
      params = {
        'deptId': _this.deptId,
        'userId':_this.userId,
        'userName':_this.userName,
        'ownerId':_this.ownerId,
        'page':_this.pageNum,
        'pageCount':_this.pageSize,
        'planId':_this.ruleForm.copyPlanID,
        'planName':_this.ruleForm.copyPlanName
      }
      $http.axios.post("/api/advertisement/copy/copyPlanLog",params).then(function(response) {   
        if(response.data.code == 200){
          _this.tableData = response.data.content.items
          _this.totalRow = response.data.content.totalCount
        }else{
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      }) 
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.planName.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
      };
    },

    //重置
    resetForm(ruleForm){
      this.$refs[ruleForm].resetFields();
      this.ruleForm.copyPlanID = ''
      this.ruleForm.copyPlanName = ''
      this.getCopyList(1)
    },



    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getCopyList()
    },

  }
}
</script>
<style lang="scss" scoped>
  .el-icon-warning-outline{
    color:red
  }
  /* 新版 */
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  .copySelect{
    margin-top:20px;
  }
</style>