<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
              <el-breadcrumb-item>关键词</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" @click="bacthEditPrice">批量修改出价</el-button>
            <el-button type="primary" size="small" @click="zdyFn">自定义列</el-button>
            <el-button type="primary" size="small" @click="addClickKeywords">添加关键词</el-button>
          </div>
        </div>
        <div class="chanelTab">
          <span class="channel_oppo" @click="clickChangeChannel(1)">广告系列</span>
          <span class="channel_vivo" @click="clickChangeChannel(2)">广告组</span>
          <span class="channel_vivo channelActive">关键词</span>
           <span class="channel_vivo" @click="clickChangeChannel(4)">广告</span>
        </div>

        <div class="chanelTabSecondary">
          <span class="channeSelActive">关键词</span>
          <span @click="keyWords(2)">否定关键词</span>
          <span @click="keyWords(3)">搜索词</span>
        </div>

        <div class="materialHead">
          <div class="materialHBtns clearfix queryLeft">
            <el-form :inline="true" ref="" class="demo-form-inline fL">
              <el-form-item label="">
                <el-date-picker
                  v-model="dateValue"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  class="dateStyle"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model = "kwNameSearch" clearable placeholder="关键词(模糊搜索)"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input 
                  v-model = "kwIdSearch" 
                  clearable           
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="关键词ID(精准搜索)">
                </el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" class="newQuery" size="small" @click = "getKwList(1)">查询</el-button>
                <el-button type="info" size="small" plian @click="resetForm">重置</el-button>
              </el-form-item>
            </el-form> 
          </div>
          <div class="listDetail queryRight">
            <span>
              计划系列：
              <el-select 
                :placeholder="campaignName"
                @change="changeAdplanLabel"
                value-key="id"
                style="margin-right:10px;"
                v-model="adplanLabel">
                <el-option
                  v-for="(item,i) in adplanModelOption"
                  :key="i"
                  :label="item.name"
                  :value="item">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 12px">{{ item.id }}</span>
                </el-option>
              </el-select>
            </span>
            <span>
              <span v-if="changeSupplySources == ''">
                <i v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                <i v-else>投放位置：搜索标签</i>
              </span>
              <span v-else>
                <i v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                <i v-else>投放位置：搜索标签</i>
              </span>
            </span>
          </div>   
        </div>
        <div class="clearfix ">
          <!-- //修改默认最高出价 -->
          <el-dialog
            title="修改默认最高出价"
            width="400px"
            center
            :visible.sync="dialogPrice">
            <div class="dialogIcon"><img :src='warningIcon'></div>
            <el-input v-model="priceValue" class="kwPriceInput" placeholder="请输入默认最高出价"></el-input>
            <span style="display:block;text-align:center;font-size:12px;color:#666;margin-top:12px;">填写的数值不可超过，所选广告组的默认最高出价</span>
            <div slot="footer" class="dialog-footer">
              <el-button class="newDialogCancel" type="primary" @click="dialogPrice = false">取消保存</el-button>
              <el-button class="newDialog" type="primary" @click="bacthEditPriceSure">确定保存</el-button>
            </div>
          </el-dialog>
          <!-- 添加关键词 -->
          <el-dialog 
            title="添加投放关键词" 
            width="820px"
            class="kwWrap"
            center
            :visible.sync="teamKeywordsVisible">
            <div class="kwIcon"><img :src="kwIcon"></div>
            <!-- 关键词右侧 -->
            <div class="teamKeyword ">
              <div class="addKwTeam">
                <span class="addNegKeywords">已添加（<i>{{teamKeywordNum}}/5000</i>）</span>
                <span class="addNum">还可添加{{residueKeywordNum}}个关键词</span>
              </div>
              <div class="batchBtns addKwTeamBtn">
                <el-button type="primary" size="mini" @click="batchKwMatchType">批量修改匹配类型</el-button>
                <el-button type="primary" size="mini" @click="batchKwPrice">批量修改默认最高出价</el-button>
                <el-button type="primary" size="mini" @click="batchKwDel">批量删除</el-button>
              </div>
              <div class="enterKeywords addKwInput">
                <el-input v-model="addKeywords" class="enterKeywordsInput" placeholder="添加关键词，多个词用逗号分隔"></el-input>
                <el-button type="primary" class="enterKeywordsBtn newDialog" @click="addKw">添加</el-button>
              </div>
              <div class="linkage">
                <div class="linkKw linkKwLeft">广告系列名称：<i>{{adplanLabelName}}</i></div>
                <div class="linkKw linkKwRight linkageType">
                  <label>广告组</label>
                  <el-select v-model="adTeamModel" placeholder="请选择广告组">
                    <el-option
                      v-for="(item,i) in adTeamModelOption"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- 批量修改匹配类型 -->
              <el-dialog 
                title="批量修改匹配类型" 
                width="330px"
                center
                class="smallDialog"
                append-to-body
                :visible.sync="dialogEditkwMatchType">
                <div class="editTypeChoose">
                  <el-select v-model="batchkwMateType" placeholder="请选择匹配类型">
                    <el-option
                      v-for="(item,i) in mateTypeOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="editTypeBtns">
                  <!-- <el-button size="mini" @click="editkwMatchTypeFalse">取 消</el-button>
                  <el-button size="mini" type="primary" @click="editkwMatchType">确 定</el-button> -->
                  <el-button class="newDialogCancel" type="primary" @click="editkwMatchTypeFalse">取消保存</el-button>
                  <el-button class="newDialog" type="primary" @click="editkwMatchType">确定保存</el-button>
                </div>
              </el-dialog>
              <!-- 批量修改默认最高出价 -->
              <el-dialog 
                title="批量修改默认最高出价" 
                width="330px"
                center
                class="smallDialog"
                append-to-body
                :visible.sync="dialogEditPrice">
                <div class="editTypeChoose">
                  <el-input v-model="editPrice" placeholder="请输入修改后的出价金额"></el-input>
                </div>
                <div class="editTypeBtns">
                  <!-- <el-button size="mini" @click="editPriceFalse">取 消</el-button>
                  <el-button size="mini" type="primary" @click="editPriceSure">确 定</el-button> -->
                  <el-button class="newDialogCancel" type="primary" @click="editPriceFalse">取消保存</el-button>
                    <el-button class="newDialog" type="primary" @click="editPriceSure">确定保存</el-button>
                </div>
              </el-dialog>

              <div class="teamKwRight">
                <el-table
                  ref="multipleTable"
                  :data="kwRighttableData"
                  class="inner innerKw"
                  @selection-change="handleSelectionChangekw"
                  tooltip-effect="dark">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="text"
                    label="关键词"
                    fixed>
                  </el-table-column>
                  <el-table-column
                    prop="chujia"
                    label="默认最高出价"
                    width="150">
                    <template slot-scope="scope" >
                      <span style="padding-right:5px;">¥</span>
                      <el-input class="priceMax" v-model="scope.row.chujia"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="匹配类型"
                    width="150">
                    <template slot-scope="scope" >
                      <el-select v-model="scope.row.matchType">
                        <el-option
                          v-for="(item,i) in mateTypeOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    center
                    width="100">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deleteTeamFn(scope.row)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini"><i class="el-icon-delete"></i>删除</el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div slot="footer" class="dialog-footer" style="margin-top:30px;padding-bottom: 30px;text-align:center;">
                <el-button class="newDialogCancel" type="primary" size="middle" @click="teamKeywordsVisible = false">取消保存</el-button>
                <el-button class="newDialog" type="primary" size="middle" @click="keyWordSure">确定保存</el-button>
              </div>
            </div>
          </el-dialog>

          <div class="materiaContent">
            <el-table
              ref="multipleAllTable"
              :data="tableData"
              style=" cursor:pointer;"
              tooltip-effect="dark"
              @sort-change="handlesortChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                prop="kwName"
                label="关键词"
                width="220px"
                fixed>
                <template slot-scope="scope">
                  <span v-if="scope.row.matchType == 'EXACT'">[{{scope.row.kwName}}]</span>
                  <span v-else>{{scope.row.kwName}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="状态"
                fixed>
                <template slot-scope="scope" >
                  <el-switch
                    v-model="scope.row.status"
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    active-value="ACTIVE"
                    inactive-value="PAUSED"
                    @change="changeStatus(scope.row.status,scope)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                prop="bidAmount"
                width="150px"
                label="默认最高出价"
                sortable
                fixed>
                <template slot-scope="scope" >
                  <span>{{scope.row.bidAmount}}</span>
                  <el-popover
                    placement="right"
                    width="70"
                    :ref="`popover-${scope.$index}`"
                    trigger="click">
                      <el-input v-model="kwAmount" ></el-input>
                      <div class="inputBtn" style="text-align:right;">
                        <el-button class="el-icon-check" style="margin:0;" @click="handleConfirmSure(scope,1);handleConfirm(scope)"></el-button>
                        <el-button class="el-icon-close" @click="handleConfirm(scope)" style="margin:0;"></el-button>
                      </div>
                      <i class="el-icon-edit" slot="reference" @click="editBidAmount(scope)" style="padding-left:6px;color:#F3920B;font-weight:700;" ></i>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="预警详情"
                fixed>
                <template slot-scope="scope" >
                  <el-button plain size="mini" class="newBtn_orang" @click="warningDetail(scope.row)">详情</el-button>
                </template>
              </el-table-column>
              <el-table-column
                prop="kwId"
                width="120px"
                label="关键词ID"
                fixed>
              </el-table-column>
              <el-table-column
                prop=""
                label="匹配类型"
                fixed>
                <template slot-scope="scope">
                  <span v-if="scope.row.matchType == 'EXACT'">完全匹配</span>
                  <span v-else>广泛匹配</span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                min-width="150px"
                label="建议出价范围">
                <template slot-scope="scope">
                  <span v-if="scope.row.minBidAmount!=null||scope.row.maxBidAmount!=null">{{scope.row.minBidAmount}}~{{scope.row.maxBidAmount}}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <template v-for="(item, index) in titleList">
                <el-table-column
                  :key="index"
                  :prop="item.prop"
                  :label="item.name"
                  width="170px"
                  sortable>
                </el-table-column>
              </template>
              <el-table-column 
                fixed = 'right'
                align="center"
                width='80'
                :show-overflow-tooltip="true"
                label="操作">
                <template slot-scope="scope">
                  <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deletePlanFn(scope.row)">
                    <template #reference>
                      <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 预警详情 -->
        <el-dialog
        title="预警详情"
        append-to-body
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogWarningDetail"
        center
        class="warnningDialog"
        width="820px">
        <div class="warnTableData">
          <div class="dialogIcon"><img :src='warningIcon'></div>
          <div style="text-align:right;margin-bottom:15px;margin-top:-35px;">
            <el-button type="primary" @click="accountWarnDialog">预警设置</el-button>
          </div>
          <el-table
            ref="multipleWarnTable"
            :data="warnInfosTableData"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            tooltip-effect="dark">
            <el-table-column
              prop=""
              label="观察指标和执行条件"
              width="200"
              fixed>
              <template slot-scope="scope">
                <div class="warnInfosList" v-for="(item,i) in scope.row.infos">
                  <span>
                    <i v-if="item.target == 1">余额</i>
                    <i v-else-if="item.target == 2">消耗</i>
                    <i v-else-if="item.target == 3">平均CPT</i>
                    <i v-else-if="item.target == 4">平均CPA </i>
                    <i v-else-if="item.target == 5">曝光</i>
                    <i v-else-if="item.target == 6">点击</i>
                    <i v-else-if="item.target == 7">安装</i>
                    <i v-else-if="item.target == 8">点击率</i>
                    <i v-else-if="item.target == 9">转化率</i>
                  </span>
                  <span>
                    <i v-if="item.op == 1">>=</i>
                    <i v-else-if="item.op == 2"><=</i>
                  </span>
                  <span>{{item.val}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="operate"
              label="操作符">
              <template slot-scope="scope">
                <span v-if="scope.row.operate == 1">与</span>
                <span v-else>或</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="startTime"
              label="开始时间">
            </el-table-column>
            <el-table-column
              prop="endTime"
              label="结束时间">
            </el-table-column>
            <el-table-column
              prop="executeStrategy"
              width="130"
              label="执行策略">
              <template slot-scope="scope">
                <span v-if="scope.row.executeStrategy == 1">无</span>
                <span v-else-if="scope.row.executeStrategy == 2">停投</span>
                <span v-else-if="scope.row.executeStrategy == 3">调日预算</span>
                <span v-else-if="scope.row.executeStrategy == 4">调CPA出价</span>
                <span v-else>调点击出价</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="状态"
              width="120">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  active-color="#48916F"
                  inactive-color="#EAEFED"
                  :active-value="0"
                  :inactive-value="1"
                  @change="changeWarnStatus(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="warnCount"
              label="预警次数">
            </el-table-column>
            <el-table-column
              prop="priority"
              label="优先级">
            </el-table-column>
            <el-table-column
              prop=""
              label="创建时间"
              width="180">
              <template slot-scope="scope">{{scope.row.createTime | dateTimeFormat}}</template>
            </el-table-column>
            <el-table-column
              prop="updater"
              label="更新人">
            </el-table-column>
            <el-table-column
              prop=""
              label="更新时间"
              width="180">
              <template slot-scope="scope">
                <span v-if="scope.row.updateTime == null">暂无</span>
                <span v-else>{{scope.row.updateTime | dateTimeFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="操作"
              width="100"
              fixed="right">
              <template slot-scope="scope">
                <el-button class="newBtn_darkOrange" size="mini" @click="warnEdit(scope.row)"><i class="el-icon-edit" style="padding-right:3px;font-weight:700;"></i>修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="newDialogCancel" type="primary" @click="dialogWarningDetail = false">取消保存</el-button>
          <el-button class="newDialog" type="primary" @click="dialogWarningDetail = false">确定保存</el-button>
        </span>
      </el-dialog>

      <!-- 预警 -->
      <el-dialog 
      title="预警设置" 
      width="600px"
      center
      class="warnningForm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogFormVisible">
      <div class="dialogIcon"><img :src='warningIcon'></div>
      <el-form 
        :model="budgetRuleForm"
        :rules="budgetRules"
        ref="budgetRuleForm">
        <!-- 新版 -->
        <div>
          <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
            <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="formLabelWidth">
              <el-select
                  v-model="obj.target">
                  <el-option
                    v-for="(item,i) in newObserveTargetOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> 
            </el-form-item>
            <el-form-item label="执行条件" prop="" :label-width="formLabelWidth">
              <el-select
                v-model="obj.op"
                style="width:120px;">
                <el-option
                  v-for="(item,i) in newSymbolOption"
                  :key="i"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> 
              <el-input v-model.number="obj.val"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width:280px;margin-left:20px;"
                placeholder="请输入预警数值">
              </el-input>
            </el-form-item>
            <div class="deleteBtn">
              <div v-if="j>0" size="small" @click="removeRowpl(j)">
                <i class="deleteBtnicon el-icon-error"></i>
              </div>
            </div>
          </div>
          <div @click="changeChecked" class="addConditions">
            <i class="el-icon-plus"></i>新增执行条件
          </div>
        </div>
        <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="formLabelWidth">
          <el-select
            v-model="budgetRuleForm.warningPerson"
            multiple
            value-key="userId"
            @change="changeWarning"
            style="width:300px;">
            <el-option
              v-for="(item,i) in warningPersonOption"
              :key="i"
              :label="item.string"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="formLabelWidth">
          <el-select
            v-model="budgetRuleForm.newOperator">
            <el-option
              v-for="(item,i) in newOperatorOption"
              :key="i"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> 
        </el-form-item>
        <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="formLabelWidth">
          <el-time-picker
            is-range
            v-model="budgetRuleForm.warningTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm"
            style="width:160px;"
            @change="changeTimes">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="formLabelWidth">
          <el-input v-model="budgetRuleForm.warnNumTimes"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width:180px;"
            placeholder="请输入预警次数">
          </el-input>
        </el-form-item>
        <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="formLabelWidth">
          <el-input v-model="budgetRuleForm.priority"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width:180px;"
            placeholder="请输入优先级">
          </el-input>
        </el-form-item>
        <el-form-item label="执行策略" :label-width="formLabelWidth">
          <el-form-item prop="">
            <div><el-radio v-model="radio" label="1" @change="changeExecute">无</el-radio></div>
            <div><el-radio v-model="radio" label="2" @change="changeExecute">停止投放</el-radio></div>
            <div style="margin-top:5px;">
              <el-radio v-model="radio" label="5" @change="changeExecute">调点击出价
                <div style="padding-left:30px;">
                  <el-radio v-model="priceRadio" label="1" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                    <el-select v-model="topAndDown" :disabled = "pricetofNumber" style="width:100px;" @change="changeNumber">
                      <el-option
                        v-for="(item,i) in topAndDownOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-model="topAndDownNum" :disabled = "pricetofNumber" style="width:110px;margin-left:15px;" placeholder="请输入数值"></el-input>
                  </el-radio>
                  <el-radio v-model="priceRadio" label="2" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                    <el-select v-model="topAndDown02" :disabled = "pricetofPercent" style="width:100px;" @change="changePercent">
                      <el-option
                        v-for="(item,i) in topAndDownOption02"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-model="topAndDownNum02" :disabled = "pricetofPercent" style="width:110px;margin-left:15px;" placeholder="请输入百分比"></el-input>
                  </el-radio>
                </div>
              </el-radio>
            </div>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="newDialogCancel" type="primary" @click="accountWarningCancel">取消保存</el-button>
        <el-button class="newDialog" type="primary" @click="accountWarning">确定保存</el-button>
      </div>
    </el-dialog>

    <!-- 修改预警 -->
    <el-dialog 
      title="预警修改" 
      width="600px"
      center
      class="warnningForm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogEditVisible">
      <el-form 
        :model="budgetRuleForm"
        :rules="budgetRules"
        ref="budgetRuleForm">
        <div class="dialogIcon"><img :src='warningIcon'></div>
        <!-- 新版 -->
        <div>
          <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
            <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="formLabelWidth">
              <el-select
                  v-model="obj.target">
                  <el-option
                    v-for="(item,i) in newObserveTargetOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> 
            </el-form-item>
            <el-form-item label="执行条件" prop="" :label-width="formLabelWidth">
              <el-select
                v-model="obj.op"
                style="width:120px;">
                <el-option
                  v-for="(item,i) in newSymbolOption"
                  :key="i"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> 
              <el-input v-model.number="obj.val"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width:280px;margin-left:20px;"
                placeholder="请输入预警数值">
              </el-input>
            </el-form-item>
            <div class="deleteBtn">
              <div v-if="j>0" size="small" @click="removeRowpl(j)">
                <i class="deleteBtnicon el-icon-error"></i>
              </div>
            </div>
          </div>
          <div @click="changeChecked" class="addConditions">
            <i class="el-icon-plus"></i>新增执行条件
          </div>
        </div>
        <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="formLabelWidth">
          <el-select
            v-model="budgetRuleForm.warningPerson"
            multiple
            value-key="userId"
            @change="changeWarning"
            style="width:300px;">
            <el-option
              v-for="(item,i) in warningPersonOption"
              :key="i"
              :label="item.string"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="formLabelWidth">
          <el-select
            v-model="budgetRuleForm.newOperator">
            <el-option
              v-for="(item,i) in newOperatorOption"
              :key="i"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> 
        </el-form-item>
        <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="formLabelWidth">
          <el-time-picker
            is-range
            v-model="budgetRuleForm.warningTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm"
            style="width:160px;"
            @change="changeTimes">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="formLabelWidth">
          <el-input v-model="budgetRuleForm.warnNumTimes"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width:180px;"
            placeholder="请输入预警次数">
          </el-input>
        </el-form-item>
        <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="formLabelWidth">
          <el-input v-model="budgetRuleForm.priority"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width:180px;"
            placeholder="请输入优先级">
          </el-input>
        </el-form-item>
        <el-form-item label="是否清除当日预警次数" label-width="150px">
          <el-switch
            v-model="budgetRuleForm.clearHistory"
            active-color="#13ce66"
            inactive-color="#ccc"
            :active-value="1"
            :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="执行策略" :label-width="formLabelWidth">
          <el-form-item prop="">
            <div><el-radio v-model="radio" label="1" @change="changeExecute">无</el-radio></div>
            <div><el-radio v-model="radio" label="2" @change="changeExecute">停止投放</el-radio></div>
            <div style="margin-top:5px;">
              <el-radio v-model="radio" label="5" @change="changeExecute">调点击出价
                <div style="padding-left:30px;">
                  <el-radio v-model="priceRadio" label="1" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                    <el-select v-model="topAndDown" :disabled = "pricetofNumber" style="width:100px;" @change="changeNumber">
                      <el-option
                        v-for="(item,i) in topAndDownOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-model="topAndDownNum" :disabled = "pricetofNumber" style="width:110px;margin-left:15px;" placeholder="请输入数值"></el-input>
                  </el-radio>
                  <el-radio v-model="priceRadio" label="2" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                    <el-select v-model="topAndDown02" :disabled = "pricetofPercent" style="width:100px;" @change="changePercent">
                      <el-option
                        v-for="(item,i) in topAndDownOption02"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-model="topAndDownNum02" :disabled = "pricetofPercent" style="width:110px;margin-left:15px;" placeholder="请输入百分比"></el-input>
                  </el-radio>
                </div>
              </el-radio>
            </div>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="newDialogCancel" type="primary" @click="dialogEditVisible = false">取消保存</el-button>
        <el-button class="newDialog" type="primary" @click="accountWarningEdit">确定保存</el-button>
      </div>
    </el-dialog>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>
          <!-- 正在载入 -->
          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <zdyApple :visible.sync="showzdyDialog" ref = 'zdyApple' :pubVue="pubVue"></zdyApple>
  </div>
</template>

<script>
import warningIcon from '@/assets/img/warningIcon.png';
import editPrice from '@/assets/img/editPrice.png';
import kwIcon from '@/assets/img/kw.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import zdyApple from './zdyApple'

export default {
  components: {
    zdyApple
  },
  data() {
    return {
      warningIcon,
      editPrice,
      kwIcon,
      pubVue: this,//空vue，用于子组件之间传值
      showzdyDialog:false,
      showDialog: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showSingleDialog:false,
      nowIs : false,
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      nameId:'',//请输入计划ID查找
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      tableData:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:'',
      planType:30,
      dialogTitle:'批量调价',
      userContent:{},//登录用户信息&弹框默认
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      //苹果渠道广告组
      formLabelWidth:'80px',
      adSeries:'',
      adSeriesOption:[{
        label:'广告系列1',
        value:1
      },{
        label:'广告系列2',
        value:2
      }],
      adGroupApple:'',
      adGroupAppleOption:[{
        label:'广告组1',
        value:1
      },{
        label:'广告组2',
        value:2
      }],
      pickerOptions: {
        shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue:[],//搜索时间
      adplanStatu:'',//搜索状态
      adplanStatuOption:[{//状态选项
        value:'ACTIVE',
        label:'开启'
      },{
        value:'PAUSED',
        label:'暂停'
      }],
      adplanLabelOption:[{//启用状态选项
        value:1,
        label:'全部'
      }],
      dialogFormVisible:false,//预警预算弹窗
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      adplanFilter:'',//筛选类型
      adplanFilterOption:[
      {
        value:'BROAD',
        label:'广泛匹配'
      },{
        value:'EXACT',
        label:'完全匹配'
      }],
      checkList:[],
      titleList:[],
      materialIdsPara:[],//选中的关键词
      //添加关键词
      teamKeywordsVisible:false,//添加关键词的弹框
      keywordNum:0,//添加关键词中的推荐关键词数量
      recommendKeywords:'',//推荐关键词的搜索
      kwLefttableData:[],//推荐关键词的表格
      teamKeywordNum:0,//添加关键词的数量
      residueKeywordNum:5000,//还可添加关键词的数量
      addKeywords:'',//添加关键词的搜索
      dialogEditkwMatchType:false,//添加关键词中的批量匹配类型修改弹框
      batchkwMateType:'',//添加关键词中的批量匹配类型
      mateTypeOption:[{//添加关键词中的批量匹配类型集合
        value:'BROAD',
        label:'广泛匹配'
      },{
        value:'EXACT',
        label:'完全匹配'
      }],
      dialogEditPrice:false,//添加关键词的批量修改最高出价弹框
      editPrice:'',//添加关键词的批量修改最高出价
      kwRighttableData:[],//添加关键词的表格
      batchkwInfo:[],//关键词批量修改的时候选择行的集合
      sureKw:[],//广告组选中的关键词
      adplanModel:'',//广告系列的联动
      adplanModelOption:[],
      adTeamModel:'',//广告组的联动
      adTeamModelOption:[],
      listParams:{},//关键词列表的入参
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      kwNameSearch:'',//搜索关键词name
      kwIdSearch:'',//搜索关键词id
      kwAmount:'',//出价
      ownerId:'',
      ownerName:'',
      campaignId:'',//广告系列id
      campaignName:'',//广告系列name
      adGroupId:'',//广告组id
      adGroupName:'',//广告组name
      adplanList:[],//计划系列列表数据
      loadingDialogVisible:true,//正在载入的弹框
      supplySources:'',//广告系列投放位置
      appId:'',
      budget:'',//广告系列的每日预算
      adplanLabel:'',//搜索广告系列下的关键词
      priceValue:'',//批量修改默认最高出价
      dialogPrice:false,//批量修改默认出价的弹框
      kwIdList:[],
      dataList:[],
      adGroupIdList:[],
      editPriceParams:{
        list:[]
      },//修改默认最高出价的参数
      newCampaignId:'',
      warnEigth:[],
      warnNine:[],
      dialogWarningDetail:false,//预警详情弹框
      //预警
      newWarnStartTime:'',
      newWarnEndTime:'',
      warnParams:{//确定预警-入参
        infos:[{
          target:'',
          op:'',
          val:''
        }]
      },
      pricetofAll:true,//调日预算的all选框
      pricetofNumber:true,//调日预算--输入数值行
      pricetofPercent:true,//调日预算--输入百分比行
      executeValue:'',//change调日预算的值
      executeRate:'',//执行比率 下调10% => -0.1
      executeDiff:'',//执行增减差额 下调100 => -100
      percentDetail:'',
      numberDetail:'',
      newObserveTargetOption:[{//观察指标集合-新
        value:2,
        label:'消耗'
      },{
        value:3,
        label:'平均CPT'
      },{
        value:4,
        label:'平均CPA'
      },{
        value:5,
        label:'曝光'
      },{
        value:6,
        label:'点击'
      },{
        value:7,
        label:'安装'
      },{
        value:8,
        label:'点击率'
      },{
        value:9,
        label:'转化率'
      }],
      newSymbolOption:[{//执行条件中的符号
        value:1,
        label:'>='
      },{
        value:2,
        label:'<='
      }],
      newOperatorOption:[{//操作符
        value:1,
        label:'与'
      },{
        value:2,
        label:'或'
      }],
      //预警
      dialogFormVisible:false,//预警预算弹窗
      budgetRuleForm:{
        condition:{
          min:'',
          max:''
        },
        observeTarget:'1',//观察指标
        warningPerson:[],//预警人
        // 新
        clearHistory:'',
        newObserveTarget:[],//观察指标-新
        newSymbol:'',//执行条件中的符号，大于等于，小于等于
        newCondition:'',//执行条件中的num
        newOperator:'',//操作符
        warnNumTimes:'',//预警次数
        priority:'',//优先级
        warningTime:[new Date(0,0,0), new Date()],//操作时间

      },
      budgetRules:{

      },
      radio: '1',
      priceRadio:'1',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      warningPersonOption:[],//预警人的集合
      warnOwnerId:'',//预警的ownerId
      changeWarningPerson:'',//选中的预警人id
      changeWarningPersonName:'',//选中的预警人name
      dialogWarningDetail:false,//预警详情的弹框
      dialogEditVisible:false,//预警修改弹框
      warnInfosTableData:[],//预警详情表格
      warnDeptId:'',//修改预警时候的部门id
      warningId:'',//修改预警id
      row:[],
      kwIdNumber:'',
      adplanLabelName:'',
      adplanLabelId:'',
      changeSupplySources:'',//change计划系列名称改变投放位置




    }
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    this.campaignId = this.$route.query.campaignId
    this.campaignName = this.$route.query.campaignName
    this.adGroupId = this.$route.query.adGroupId
    this.adGroupName = this.$route.query.adGroupName
    this.supplySources = this.$route.query.supplySources
    this.appId = this.$route.query.appId
    this.budget = this.$route.query.budget
    // this.totalBudget = this.$route.query.totalBudget
    this.editPriceParams.adPlanId = this.campaignId

    let nowTimes = Date.now();
    let oldTimes = Date.now();
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//今天的时间
    this.dateValue = [oldDate, nowDate];
    this.budgetRuleForm.newOperator = 1,//预警的操作符默认1-与
    this.budgetRuleForm.clearHistory = 1
    this.adplanLabel = this.campaignName
    this.budgetRuleForm.warnNumTimes = 3
    this.budgetRuleForm.priority = 1
    this.getAdplanList()
    this.getKwList()
    this.getFpyjList328()
    this.getFpyjList329()
    

  },
  methods: {
    //tab切换
    clickChangeChannel(val){
      if(val == 1){
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName }})
      }else if(val == 2){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
        
      }else if(val == 4){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
        
      }
    },

    //关键词、否定关键词、搜索词的切换
    keyWords(val){
      if(val == 2){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordNegApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget
          }})
        }else{
          this.$router.push({path:'/plan-management/adKeywordNegApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget
          }})
        }
        
      }else if(val == 3){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordSelectApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget
          }})
        }else{
          this.$router.push({path:'/plan-management/adKeywordSelectApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget
          }})
        }
        
      }
    },

    //格式化时间 返回年月日 
    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //change搜索条件
    changeAdplanStatu(val){
      this.listParams.status = val
    },
    changeAdplanFilter(val){
      this.listParams.matchType = val
    },

    //clear
    clearAdplanStatu(val){
      this.adplanStatu = null
      this.getKwList()
    },

    //change广告系列查询关键词列表
    changeAdplanLabel(item){
      this.newCampaignId = item.id
      console.log(item)
      this.loadingDialogVisible = true
      let _this = this
      item.supplySources.forEach(function(val,i){
        _this.changeSupplySources = val
      })
      console.log(_this.changeSupplySources)

      _this.listParams.userId = _this.userId
      _this.listParams.userName = _this.userName
      _this.listParams.deptId = _this.deptId
      _this.listParams.ownerId = _this.ownerId
      _this.listParams.adPlanId = item.id
      _this.listParams.pageIndex = _this.page|| 1
      _this.listParams.pageSize = _this.pageCount
      _this.listParams.sDate = _this.dateValue[0]
      _this.listParams.eDate = _this.dateValue[1]
      _this.listParams.query = _this.kwNameSearch
      _this.listParams.queryId = _this.kwIdSearch
      if(_this.adGroupId != undefined){
        _this.listParams.adGroupId = _this.adGroupId
      }
      $http.axios.post("/api/apple/kw/list",_this.listParams).then(res=> {
        if(res.data.code==200){
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
          _this.loadingDialogVisible = false
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })    
        }
      })
    },

    //关键词列表
    getKwList(page){
      let _this = this
      _this.listParams.userId = _this.userId
      _this.listParams.userName = _this.userName
      _this.listParams.deptId = _this.deptId
      _this.listParams.ownerId = _this.ownerId
      if(page == 1){
        _this.listParams.pageIndex = 1
      }else{
        _this.listParams.pageIndex = _this.page
      }
      // _this.listParams.pageIndex = _this.page|| 1
      _this.listParams.pageSize = _this.pageCount
      _this.listParams.sDate = _this.dateValue[0]
      _this.listParams.eDate = _this.dateValue[1]
      _this.listParams.query = _this.kwNameSearch
      _this.listParams.queryId = _this.kwIdSearch
      if(_this.adGroupId != undefined){
        _this.listParams.adGroupId = _this.adGroupId
      }
      if(_this.newCampaignId == ''){
        _this.listParams.adPlanId = _this.campaignId
      }else{
        _this.listParams.adPlanId = _this.newCampaignId
      }
      $http.axios.post("/api/apple/kw/list",_this.listParams).then(res=> {
        if(res.data.code==200){
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
          _this.loadingDialogVisible = false
          _this.tableData.forEach((obj,i)=>{
            //支出
            if(obj.localSpend!=null){
              obj.localSpend = '¥'+(Number(obj.localSpend).toFixed(2))
            }
            //平均每次点击费用
            if(obj.avgCPT!=null){
              obj.avgCPT = '¥'+(Number(obj.avgCPT).toFixed(2))
            }
            //平均每次转化费用
            if(obj.avgCPA!=null){
              obj.avgCPA = '¥'+(Number(obj.avgCPA).toFixed(2))
            }
            //平均每千次展示成本
            if(obj.avgCPM!=null){
              obj.avgCPM = '¥'+(Number(obj.avgCPM).toFixed(2))
            }
            //点击率
            if(obj.ttr!=null){
              obj.ttr = (Number(obj.ttr))+'%'
            }
            //转化率
            if(obj.conversionRate!=null){
              obj.conversionRate = (Number(obj.conversionRate))+'%'
            }



          })

          _this.userSetCustom()
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })    
        }
      })
    },

    //排序
    handlesortChange(column) {
      this.listParams.orderBy = column.prop
      if(column.order == 'descending'){
        this.listParams.asc = 'DESCENDING'
      }else if(column.order == 'ascending'){
        this.listParams.asc = 'ASCENDING'
      }
      this.getKwList()
    },

    //用户已经设置的自定义列
    userSetCustom(){
      let _this = this,titleList = [],columnName = ''
      $http.axios.post("/api/apple/CustomColumn/getColumnsByOrgId?customType=4&orgId="+Number(_this.ownerId)).then(res=> {
        if(res.data.code == 200){
          titleList = res.data.data
          _this.orderByList = res.data.data
          _this.titleList = []
          if(res.data.data != null){
            titleList.forEach((val,i)=>{
              _this.titleList.push({'name':val.name,'prop':val.columnNameLink}) 
            })
          }
          
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.getKwList()
    },

    //重置
    resetForm(){
      this.$router.go(0)
    },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e.id)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选关键词',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },

    //添加关键词弹框show
    addClickKeywords(){
      this.teamKeywordsVisible = true
      let _this = this
      if(_this.adplanLabelName == ''){
        _this.adplanLabelName = _this.campaignName
      }else{
        _this.adplanModelOption.forEach(function(val,i){
          if(val.id == _this.adplanLabel){
            _this.adplanLabelName = val.name
          }
        })
      }
      if(_this.adplanLabel == _this.campaignName){
        _this.adplanLabelId = _this.campaignId
      }else{
        _this.adplanModelOption.forEach(function(val,i){
          if(val.id == _this.adplanLabel){
            _this.adplanLabelId = val.id
          }
        })
      }

      _this.getAdgroupList()//获取广告组列表
    },

    //广告组中添加关键词
    addKeywordShow(){
      this.teamKeywordsVisible = true
    },

    //添加否定关键词弹框中的添加btn
    addKw(){
      let addKeywords = [],_this = this
      addKeywords = _this.addKeywords.split(',')
      addKeywords.forEach(function(obj,index){
        _this.kwRighttableData.push({'text':obj,'matchType':'BROAD','chujia':1})
      })
      //去重
      let obj={}
      this.kwRighttableData=this.kwRighttableData.reduce((item,next)=>{
        obj[next.text]?'':obj[next.text]=true&&item.push(next)
        return item
      },[])
      _this.addKeywords = ''
      _this.teamKeywordNum =  _this.kwRighttableData.length
      _this.residueKeywordNum = 5000-(_this.kwRighttableData.length)
      console.log(_this.kwRighttableData)
    },

    //change批量修改关键词的匹配类型
    batchKwMatchType(){
      this.chooseTableFn('dialogEditkwMatchType')
    },

    //关键词的批量删除
    batchKwDel(){
      if(this.$refs.multipleTable.selection.length == 0){
        this.$message({
          message: '请进行勾选',
          type: 'warning'
        });
      }else{
        let _this = this,
        selection = this.$refs.multipleTable.selection,
        data = []
        _this.kwRighttableData.map((el, index) => {
          if (!selection.find(item => item.text == el.text)) {
              data.push(_this.kwRighttableData[index]);
          }
        })
        _this.kwRighttableData = data
      }
    },

    //批量修改默认最高出价
    batchKwPrice(){
      this.chooseTableFn('dialogEditPrice')
    },

    //change关键词的表格
    handleSelectionChangekw(val){
      this.batchkwInfo = val
    },

    //批量修改默认出价
    editPriceSure(){
      let _this = this
      _this.batchkwInfo.forEach(function(obj,index){
        obj.chujia = _this.editPrice
      })
      _this.dialogEditPrice = false
      _this.editPrice = ''
      _this.$refs.multipleTable.clearSelection()
    },

    //批量修改默认最高值取消
    editPriceFalse(){
      this.dialogEditPrice = false
      this.editPrice = ''
      this.$refs.multipleTable.clearSelection()
    },

    //批量修改关键词的匹配类型
    editkwMatchType(){
      let _this = this
      _this.batchkwInfo.forEach(function(val,i){
        val.matchType = _this.batchkwMateType
      })
      _this.dialogEditkwMatchType = false
      _this.batchkwMateType = ''
      _this.$refs.multipleTable.clearSelection()
    },

    //批量修改关键词的匹配类型
    editkwMatchTypeFalse(){
      this.batchkwMateType = ''
      this.dialogEditkwMatchType = false
      this.$refs.multipleTable.clearSelection()
    },

    //添加关键词弹框中的单个删除
    deleteTeamFn(row){
      let _this = this
      _this.kwRighttableData.forEach(function(obj,i){
        if(obj.text == row.text){
          console.log(row.text)
          _this.kwRighttableData.splice(i,1)
        }
      })
      _this.teamKeywordNum =  _this.kwRighttableData.length
    },

    //change投放位置的显示隐藏
    changePosition(val){
      if(val == 1){
        this.changePosition2 = true
      }else if(val == 2){
        this.changePosition2 = false
      }
    },

    editBidAmount(scope){
      this.kwAmount = scope.row.bidAmount
    },

    //修改默认最高出价
    handleConfirm(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose()
      scope._self.$refs[`popover-${scope.$index}`].showPopper = false
    },

    handleConfirm() {
      document.body.click() // 模拟点击页面其它部分关掉弹层，因为该页面列表使用懒加载导致doClose无效
    },

    handleConfirmSure(scope,index) {
      console.log(scope)
      let _this = this,params={}
      if(index == 1){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.ownerId,
          'list':[{
            'adGroupId':scope.row.adGroupId,
            'id':scope.row.kwId,
            'bidAmount':_this.kwAmount,
            'currency':'RMB',
          }]
        }
        if(_this.newCampaignId == ''){
          params.adPlanId = _this.campaignId
        }else{
          params.adPlanId = _this.newCampaignId
        }
      }
      $http.axios.post("/api/apple/kw/update/batch",params).then(function(response) {
        if(response.data.code == 200){
          scope._self.$refs[`popover-${scope.$index}`].doClose()
          scope._self.$refs[`popover-${scope.$index}`].showPopper = false
          _this.getKwList()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change状态
    changeStatus(event,scope){
      let _this = this,params = {},status = ''
      if(event == 'PAUSED'){
        status = 2
      }else{
        status = 1
      }
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        'list':[{
          'adGroupId':scope.row.adGroupId,
          'id':scope.row.kwId,
          'status':Number(status),
        }]
      }
      if(_this.newCampaignId == ''){
        params.adPlanId = _this.campaignId
      }else{
        params.adPlanId = _this.newCampaignId
      }
      $http.axios.post("/api/apple/kw/update/batch",params).then(function(response) {
        if(response.data.code == 200){
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 删除
    deletePlanFn(row){
      let _this = this
      $http.axios.get("/api/apple/kw/del/batch?adGroupId="+row.adGroupId+"&adPlanId="+_this.campaignId+"&kwIds="+row.kwId+"&ownerId="+_this.ownerId+"&userName="+_this.userName).then(res=> {
        if(res.data.code==200){
          _this.getKwList()
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //获取计划系列数据
    getAdplanList(){
      let _this = this
      $http.axios.get("/api/apple/adPlan/getCampaignList/"+_this.ownerId).then(res=> {
        if(res.data.code==200){
          _this.adplanModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //获取广告组数据
    getAdgroupList(){
      let _this = this
      $http.axios.get("/api/apple/adGroup/getAllGroup/"+_this.ownerId+"/"+_this.adplanLabelId).then(res=> {
        if(res.data.code==200){
          _this.adTeamModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //广告组中的关键词确定
    keyWordSure(){
      let _this = this,params = {},groupkeywordsDtoList = []
      _this.kwRighttableData.forEach(function(val,index){
        groupkeywordsDtoList.push({
          'budgetAmount':{
            'amount':val.chujia,
            'currency':'RMB'
          },
          'matchType':val.matchType,
          'text':val.text
        })
      })
      params = {
        'deptId':_this.deptId,
        'userId':_this.userId,
        'userName':_this.userName,
        'campaignId':_this.adplanLabelId,
        'groupId':_this.adTeamModel,
        'groupkeywordsDtoList':groupkeywordsDtoList,
        'orgId':_this.ownerId,
      }
     
      $http.axios.post("/api/apple/adGroup/saveTargetingkeywords",params).then(res=> {
        if(res.data.code==200){
          _this.getKwList()
          _this.teamKeywordsVisible = false
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //批量修改默认最高出价-关键词列表的
    bacthEditPrice(){
      let _this = this,sendttSelection = []
      sendttSelection = _this.$refs.multipleAllTable.selection
      _this.kwIdList = []
      _this.adGroupIdList = []
      _this.dataList = []
      sendttSelection.forEach(function(e,i){
        _this.dataList.push(e)
      })
      if(sendttSelection.length == 0){
        _this.$message({
          message: '请勾选关键词',
          type: 'warning'
        });
      }else{
        _this.priceValue = ''
        _this.dialogPrice = true
      }
    },

    //确认修改默认最高出价
    bacthEditPriceSure(){
      this.loadingDialogVisible = true
      console.log(this.newCampaignId)
      let _this = this,kwId = ''
      console.log(_this.dataList)
      _this.editPriceParams.userId = _this.userId
      _this.editPriceParams.userName = _this.userName
      _this.editPriceParams.deptId = _this.deptId
      _this.editPriceParams.ownerId = _this.ownerId
      if(_this.newCampaignId == ''){
        _this.editPriceParams.adPlanId = _this.campaignId
      }else{
        _this.editPriceParams.adPlanId = _this.newCampaignId
      }
      _this.dataList.forEach((val,i) => {
        _this.editPriceParams.list.push({'adGroupId':String(val.adGroupId),'id':val.kwId,'bidAmount':Number(_this.priceValue),'currency':'RMB'})
      })
      $http.axios.post("/api/apple/kw/update/batch",_this.editPriceParams).then(function(response) {
        if(response.data.code == 200){
          _this.loadingDialogVisible = false
          _this.dialogPrice = false
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
          _this.getKwList()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警详情
    warningDetail(row){
      console.log(row)
      this.row = row
      this.kwIdNumber = row.kwId
      this.dialogWarningDetail = true
      let _this = this
      $http.axios.get("/api/apple/warn/query?adType=6&dataId="+row.kwId).then(function(response) {
        if(response.data.code == 200){
         console.log(response)
         _this.warnInfosTableData = response.data.data
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取分配人员列表
    getFpyjList328(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
        if(response.data.code == 200){
          that.warnEigth = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    getFpyjList329(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
        if(response.data.code == 200){
          that.warnNine = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警
    accountWarnDialog(){
      this.dialogFormVisible = true
      this.getFpyjList328()
      this.getFpyjList329()
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      // this.accountWarningCancel()
    },

    //修改预警状态
    changeWarnStatus(row){
      console.log(row)
      let _this = this,params={},groupId = ''
      if(_this.adGroupId != undefined){
        groupId = _this.adGroupId
      }
      params={
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        'dataId':Number(_this.kwIdNumber),//账户id
        'type':6,//1-系列组 2-系列 3-广告组
        'infos':row.infos,//监控条件明细
        'executeStrategy':row.executeStrategy,//执行策略
        'warnId':row.warnId,//预警人
        'startTime':row.startTime,//开始时间
        'endTime':row.endTime,//结束时间
        'adGroupId':Number(groupId),
        'adPlanId':Number(_this.campaignId),
        'operate':row.operate,
        'executeRate':row.executeRate,
        'executeDiff':row.executeDiff,
        'clearHistoryPermitsFlag':1,//修改时候传1-暂时
        'warnCount':row.warnCount,//预警次数 默认3
        'priority':row.priority,//优先级
        'id':Number(row.id),//修改时候的id
        'status':Number(row.status)
      }
      $http.axios.post("/api/apple/warn/insertOrUpdate",params).then(function(response) {
        if(response.data.code == 200){
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警修改
    warnEdit(row){
      console.log(row)
      //拼接预警人id
      this.getFpyjList328()
      this.getFpyjList329()
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      this.budgetRuleForm.clearHistory = 1//默认清除当初预警次数
      this.dialogEditVisible = true
      let warnId = [],warnName = [],_this = this,infos = []
      infos = row.infos//获取观察指标和执行条件
      // infos = _this.warnInfosTableData.infos//获取观察指标和执行条件
      console.log(infos)
      warnId = row.warnId.split(",")
      _this.budgetRuleForm.warningPerson = []
      //回显数据
      _this.warningPersonOption.forEach(function(val,i){
        warnId.forEach(function(item,index){
          if(val.userId == item){
            _this.budgetRuleForm.warningPerson.push({'userId':item})
          }
        })
      })//预警人
      _this.warnParams.infos = []
      infos.forEach(function(obj,index){
        console.log(obj)
        _this.warnParams.infos.push({
          target:obj.target,
          op:obj.op,
          val:obj.val
        })
      })//观察指标和执行条件
      _this.budgetRuleForm.newOperator = row.operate //操作符
      //开始结束时间回显
      const date = new Date();
      let nowDate = ''
      nowDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;
      _this.budgetRuleForm.warningTime[0] = new Date(nowDate+" "+row.startTime+":00")///开始结束时间
      _this.budgetRuleForm.warningTime[1] = new Date(nowDate+" "+row.endTime+":00")///开始结束时间
      //开始结束时间传参
      _this.newWarnStartTime = row.startTime///开始结束时间
      _this.newWarnEndTime = row.endTime///开始结束时间
      _this.radio = String(row.executeStrategy) //执行策略
      _this.changeWarningPerson = row.warnId
      _this.warningId = row.id
      _this.budgetRuleForm.warnNumTimes = row.warnCount//预警次数
      _this.budgetRuleForm.priority = row.priority//优先级
      if(String(row.executeStrategy) == '5'){
        _this.executeValue = '5'
        _this.pricetofAll = false//调日预算的整个选框
        _this.pricetofNumber = false//数值
        _this.pricetofPercent = true//百分比
        //数值
        if(row.executeDiff != 0){
          _this.topAndDown02 = ''
          _this.topAndDownNum02 = ''
          _this.pricetofNumber = false//数值
          _this.pricetofPercent = true//百分比
          _this.priceRadio = '1'
          if(row.executeDiff>0){
            _this.topAndDown = 1
            _this.numberDetail = _this.topAndDown
            _this.topAndDownNum = row.executeDiff
            _this.executeDiff = _this.topAndDownNum
          }else{
            _this.topAndDown = 2
            _this.numberDetail = _this.topAndDown
            _this.topAndDownNum = -(row.executeDiff)
            _this.executeDiff = -(_this.topAndDownNum)
          }
        }else{
          _this.priceRadio = '2'
          _this.pricetofNumber = true//数值
          _this.pricetofPercent = false//百分比
          _this.executeDiff = 0
        }
        //百分比
        if(row.executeRate != 0){
          _this.topAndDown = ''
          _this.topAndDownNum = ''
          _this.pricetofNumber = true//数值
          _this.pricetofPercent = false//百分比
          _this.priceRadio = '2'
          if(row.executeRate>0){
            _this.topAndDown02 = 1
            _this.percentDetail = _this.topAndDown02
            _this.topAndDownNum02 = row.executeRate*100
            _this.executeRate = row.executeRate
          }else{
            _this.topAndDown02 = 2
            _this.percentDetail = _this.topAndDown02
            _this.topAndDownNum02 = -(row.executeRate*100)
            _this.executeRate = -(row.executeRate)
          }
        }else{
          _this.priceRadio = '1'
          _this.pricetofNumber = false//数值
          _this.pricetofPercent = true//百分比
          _this.executeRate = 0
        }
      }else{
        _this.pricetofAll = true//调日预算的整个选框
        _this.pricetofNumber = true//数值
        _this.pricetofPercent = true//百分比
        _this.topAndDown = ''//数值的上调下调
        _this.topAndDownNum = ''//数值
        _this.topAndDown02 = ''//百分比的上调下调
        _this.topAndDownNum02 = ''//百分比
      }
    },

    //预警修改
    accountWarningEdit(){
      let _this = this
      _this.warnParams.userId = _this.userId
      _this.warnParams.userName = _this.userName
      _this.warnParams.deptId = _this.deptId
      _this.warnParams.ownerId = _this.ownerId
      _this.warnParams.dataId = Number(_this.kwIdNumber) //数据id
      _this.warnParams.adPlanId = Number(_this.campaignId)//计划系列id
      if(_this.adGroupId != undefined){
        _this.warnParams.adGroupId = _this.adGroupId
      }
      _this.warnParams.type = 6 //1-系列组 2-系列 3-广告组 6-关键词
      _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
      _this.warnParams.warnId = _this.changeWarningPerson//预警人id
      _this.warnParams.startTime = _this.newWarnStartTime//开始时间
      _this.warnParams.endTime = _this.newWarnEndTime//结束时间
      _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
      _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
      _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
      if(_this.numberDetail == 1){
        _this.executeDiff = _this.topAndDownNum
      }else{
        _this.executeDiff = -(_this.topAndDownNum)
      }
      if(_this.percentDetail == 1){
        _this.executeRate = _this.topAndDownNum02/100
      }else{
        _this.executeRate = -(_this.topAndDownNum02/100)
      }
      _this.warnParams.executeRate = _this.executeRate//执行比率 下调10% => -0.1
      _this.warnParams.executeDiff = _this.executeDiff//执行增减差额 下调100 => -100
      _this.warnParams.id = Number(_this.warningId)//修改时候的传id
      _this.warnParams.clearHistoryPermitsFlag = Number(_this.budgetRuleForm.clearHistory)//是否清空预警次数
      $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
        if(response.data.code == 200){
          _this.dialogEditVisible = false
          _this.dialogWarningDetail = false
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //预警确定
    accountWarning(){
      let _this = this
      _this.warnParams.userId = _this.userId
      _this.warnParams.userName = _this.userName
      _this.warnParams.deptId = _this.deptId
      _this.warnParams.ownerId = _this.ownerId
      _this.warnParams.dataId = Number(_this.kwIdNumber) //数据id
      _this.warnParams.adPlanId = Number(_this.campaignId)//计划系列id
      if(_this.adGroupId != undefined){
        _this.warnParams.adGroupId = _this.adGroupId
      }
      _this.warnParams.type = 6 //1-系列组 2-系列 3-广告组 6-关键词
      _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
      _this.warnParams.warnId = _this.changeWarningPerson//预警人id
      _this.newWarnStartTime = _this.getDateTime(_this.budgetRuleForm.warningTime[0])
      _this.newWarnEndTime = _this.getDateTime(_this.budgetRuleForm.warningTime[1])
      _this.warnParams.startTime = _this.newWarnStartTime//开始时间
      _this.warnParams.endTime = _this.newWarnEndTime//结束时间
      _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
      _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
      _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
      if(_this.numberDetail == 1){
        _this.executeDiff = _this.topAndDownNum
      }else{
        _this.executeDiff = -(_this.topAndDownNum)
      }
      if(_this.percentDetail == 1){
        _this.executeRate = _this.topAndDownNum02/100
      }else{
        _this.executeRate = -(_this.topAndDownNum02/100)
      }
      _this.warnParams.executeRate = _this.executeRate//执行比率 下调10% => -0.1
      _this.warnParams.executeDiff = _this.executeDiff//执行增减差额 下调100 => -100

      $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
        if(response.data.code == 200){
          _this.dialogFormVisible = false
          _this.dialogWarningDetail = false
          _this.accountWarningCancel()
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change预警人
    changeWarning(event){
      let changeWarningPerson = []
      event.forEach(function(val,i){
        changeWarningPerson.push(val.userId)
      })
      this.changeWarningPerson = changeWarningPerson.toString()
    },

    //取消预警弹框
    accountWarningCancel(){
      this.budgetRuleForm.warningPerson = []
      this.radio = '1'
      this.budgetRuleForm.newOperator = 1//操作符
      this.budgetRuleForm.warnNumTimes = 3//预警次数
      this.budgetRuleForm.priority = 1//优先级
      this.topAndDown = ''//数值的上调下调
      this.topAndDownNum = ''//数值
      this.topAndDown02 = ''//百分比的上调下调
      this.topAndDownNum02 = ''//百分比
      this.pricetofAll = true//调日预算的整个选框
      this.pricetofNumber = true//数值
      this.pricetofPercent = true//百分比
      this.dialogFormVisible = false
    },

    //预警-选择观察指标-新增
    changeChecked(){
      // this.warnParams.infos.push({})
      this.warnParams.infos.push({
        target:'',
        op:'',
        val:''
      })
    },

    //预警-选择观察指标-删除
    removeRowpl(index) {
      if (index >= 0) {
        this.warnParams.infos.splice(index, 1);
      }
    },

    getDateTime(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return h + ':' + m;
      }
      return ''
    },

    //change预警-开始结束时间
    changeTimes(val){
      this.budgetRuleForm.warningTime = val
      this.newWarnStartTime = this.getDateTime(this.budgetRuleForm.warningTime[0])
      this.newWarnEndTime = this.getDateTime(this.budgetRuleForm.warningTime[1])
    },

    //change执行策略-显示隐藏问题
    changeExecute(val){
      this.executeValue = val
      if(val == '5'){
        this.priceRadio = '1'
        this.pricetofAll = false//调日预算的整个选框
        this.pricetofNumber = false//数值
        this.pricetofPercent = true//百分比
      }else{
        this.pricetofAll = true//调日预算的整个选框
        this.pricetofNumber = true//数值
        this.pricetofPercent = true//百分比
        this.topAndDown = ''//数值的上调下调
        this.topAndDownNum = ''//数值
        this.topAndDown02 = ''//百分比的上调下调
        this.topAndDownNum02 = ''//百分比
      }
    },

    //change调日预算中的数值和百分比
    changeNumOrPercent(val){
      if(this.executeValue == '5'){
        if(val == '1'){
          this.pricetofNumber = false//数值
          this.pricetofPercent = true//百分比
          this.topAndDown02 = ''//百分比的上调下调
          this.topAndDownNum02 = ''//百分比
        }else{
          this.pricetofNumber = true//数值
          this.pricetofPercent = false//百分比
          this.topAndDown = ''//数值的上调下调
          this.topAndDownNum = ''//数值
        }
      }
    },

    //change调日预算--上调下调数值
    changeNumber(val){
      // topAndDownNum
      this.numberDetail = val
      if(val == 1){//上调
        this.executeDiff = this.topAndDownNum
      }else{
        this.executeDiff = -(this.topAndDownNum)
      }
      console.log(this.numberDetail)
    },

    //change调日预算--上调下调百分比
    changePercent(val){
      this.percentDetail = val
      if(val == 1){
        this.executeRate = this.topAndDownNum02/100
      }else{
        this.executeRate = -(this.topAndDownNum02/100)
      }
      console.log(this.percentDetail)
    },

    //自定义
    zdyFn(){
      this.$refs.zdyApple.generateTimes(4,this.ownerId)
    },


  },

}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
  .allOperation .totalNum i{
    font-style: initial;
  }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .priceStyle{
    display: block;
    margin:10px 0;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:10px;
    width:100%;
    border-bottom:1px solid #ddd;
    font-size:14px;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }

  .teamKeyword .el-icon-search{
    font-size:16px;
  }
  .teamKeywordLeft,.teamKeywordRight{
    display:inline-block;
    vertical-align:top
  }
  .teamKeywordLeft{
    width:40%;
    box-sizing: border-box;
    border-right:1px solid #ddd;
    padding-right:20px;
  }
  .teamKeywordRight{
    width:59%;
    padding-left:20px;
    box-sizing: border-box;
  }
  ::v-deep .teamKeywordLeft .el-input__inner,::v-deep .teamKeywordLeft .el-input--mini{
    width:300px!important;
  }
  ::v-deep .innerKw .el-input__inner,::v-deep .innerKw .el-input--mini{
    width:110px;
  }
  .teamKeyword i {
    font-style: normal;
  }
  .teamKwLeft,.teamKwRight{
    margin-top:20px;
  }
  .enterKeywords{
    width:100%;
    margin:10px 0 20px;
  }
  .enterKeywords .enterKeywordsInput,.enterKeywords .enterKeywordsBtn{
    display: inline-block;
  }
  .enterKeywords .enterKeywordsInput{
    width:58%;
  }
  .enterKeywords .enterKeywordsBtn{
    width:10%;
    height:30px;
    border-radius: 30px;
    margin-left:10px!important;
  }
  .editTypeChoose{
    text-align:center;
  }
  .editTypeBtns{
    margin-top:20px;
    padding-bottom:20px;
    text-align:center;
  }
  .linkage{
    margin-bottom:20px;
    /* padding-bottom:20px; */
    /* border-bottom:1px solid #ddd; */
  }
  .linkageType{
    display: inline-block;
    vertical-align:middle;
    margin-right:30px;
  }
  .linkageType label{
    padding-right:15px;
  }
  .loading{
    text-align:center;
  }
  .loading span,.loading i{
    display: block;
  }
  .loading span{
    font-size:20px;
    font-weight:700;
    color:#fff;
  }
  .loading i{
    margin-top:10px;
    font-size:60px;
    /* color: #409eff; */
    color:#fff;
  }
  /* .loadingBg{
    margin-top:200px;
  } */
  ::v-deep .loadingBg .el-dialog{
    margin-top:200px;
    background:rgba(255,255,255,0)!important;
    box-shadow: none;
  }
  .listDetail{
    display: inline-block;
    vertical-align:middle;
    /* margin-left:20px; */
  }
  .listDetail span{
    display: inline-block;
    vertical-align:middle;
    font-size:12px;
    color:#666;
    /* margin-right:20px; */
  }
  .listDetail span i{
    font-style: normal;
  }
  .detail{
    color:#409eff;
  }
   /*预警-新*/
   .newAddfpStyle{
    position: relative;
  }
  .newReduceBtnStyle{
    position:absolute;
    top:50%;
    right:0;
    transform: translate(0,-50%);
  }
  .warnInfosList span i{
    font-style: normal;
  }
   /*新版*/
   ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  /* ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  } */
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  /*新预警*/
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .queryLeft,.queryRight{
    display: inline-block;
    vertical-align: middle;
  }
  .queryLeft{
    width:65%;
  }
  .queryRight{
    width:35%;
    text-align:right;
  }
  ::v-deep .dateStyle.el-range-editor.el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .dateStyle.el-range-editor .el-range-input{
    background:#F5F7F6!important;
  }
  ::v-deep .queryLeft .el-input--mini .el-input__inner{
    width:160px;
    border:none;
    background:#F5F7F6;
  }
  ::v-deep .materialHead .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .materialHead{
    padding-bottom:20px;
  }
  .queryRight span i{
    font-style:normal;
    color:#999;
  }
  ::v-deep .queryLeft .el-button + .el-button{
    margin-left:0!important;
  }
  .chanelTabSecondary{
    margin-bottom:20px;
  }
  .chanelTabSecondary span{
    display: inline-block;
    vertical-align: middle;
    font-size:12px;
    padding:0 20px;
    height:30px;
    line-height:30px;
    background:#ECF3F0;
    color:#82A69A;
    margin-right:20px;
    cursor: pointer;
    border-radius: 30px;
  }
  .chanelTabSecondary span:hover,.chanelTabSecondary span.channeSelActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .kwPriceInput .el-input--mini .el-input__inner{
    height:40px;
    line-height:40px;
    border:none;
    background:#F5F7F6;
  }
  /*客户端-新版*/
  .createAdverts{
    padding-bottom:10px;
    border-bottom:1px solid #F1F7F5;
    margin-bottom:20px;
  }
  .createAdverts span{
    font-size:14px;
    font-weight:700;
    color:#333;
    padding-left:13px;
    border-left:3px solid #48916F;
  }
  ::v-deep .createAll .el-input--mini .el-input__inner{
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .createAdTeamKeyword .el-input--mini .el-input__inner{
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .createAdTeamKeyword .el-switch.is-checked .el-switch__core{
    background:#48916F!important;
    border:none!important;
  }
  .budgetOrder{
    width:40%;
    height: 40px;
    line-height:40px;
    background: linear-gradient(270deg, #FFFFFF 0%, #F2F8F6 100%);
    padding-left:20px;
    margin-bottom:15px;
  }
  .budgetOrder span{
    font-size:14px;
    color:#65A487;
    font-weight:400;
  }
  ::v-deep .createAll .el-form-item--mini .el-form-item__label{
    color:#333!important;
  }
  .kwIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .kwWrap .el-dialog .el-dialog__header{
    padding-top:40px;
    font-size:18px;
    font-weight:700;
  }
  ::v-deep .kwWrap .el-dialog--center .el-dialog__body{
    padding-top:0;
  }
  .addKwTeam,.addKwTeamBtn{
    display: inline-block;
    vertical-align: middle;
  }
  .addKwTeam{
    width:40%;
    height: 36px;
    line-height:36px;
    background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
    border-radius: 10px 0px 0px 0px;
    padding-left:18px;
    box-sizing: border-box;
  }
  .addKwTeamBtn{
    text-align:right;
    width:60%;
  }
  .addKwInput{
    width:100%;
    padding:15px 0;
    background: #005E3F;
    border-radius: 7px;
    text-align:center;
  }
  ::v-deep .addKwInput .el-input--mini .el-input__inner{
    border-radius:30px;
  }
  ::v-deep .priceMax .el-input__inner{
    width:50px;
  }
  .kwAddNumBtn{
    display: inline-block;
    vertical-align: middle;
  }
  .kwAddNumBtn i{
    font-size:18px;
    font-weight:700;
    color:#48916F;
    cursor: pointer;
    margin-left:10px;
  }
  .kwdelete{
    font-size:16px;
    font-weight:700;
    color:#FF8080;
    cursor: pointer;
  }
  ::v-deep .smallDialog .el-popup-parent--hidden .el-dialog__body, #app .el-dialog__body{
    margin:0!important;
    padding:0!important;
  }
  .addNegKeywords,.addNegKeywords i {
    font-style: normal;
    font-size:14px;
    font-weight:700;
  }
  .addNum{
    font-size:12px!important;
    color:#666;
    float:right;
  }
  .linkage .linkKw{
    display: inline-block;
    vertical-align: middle;
    margin-right:20px;
    font-size:12px;
  }
  .linkKwLeft{
    padding:4px 12px;
    border:1px solid #EBEBEB;
    border-radius:4px;
    color:#999;
  }
  .linkKwLeft i{
    font-style: normal;
    color:#333;
  }
  .linkKwRight{
    padding:4px 12px;
    background:#F5F7F6;
  }
  ::v-deep .linkKwRight .el-input--mini .el-input__inner{
    border:none;
    background:#F5F7F6;
  }
  
</style>
