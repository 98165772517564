<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="hl_headers sticky">
          <div class="hl_headerLeft">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="hl_headerLeftDrowp"
            >
              <el-breadcrumb-item :to="{ path: '/auth-management/demandOrder' }"
                >运营管理</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ path: '/auth-management/demandOrder' }"
                >需求工单</el-breadcrumb-item
              >
              <el-breadcrumb-item>工单详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button
              class="el-button--primary"
              type="primary"
              size="small"
              v-if="typeFlag == 1"
              @click="pljs"
              >接收</el-button
            >
            <el-button
              class="el-button--primary"
              type="primary"
              size="small"
              v-else
              @click="remind"
              >提醒</el-button
            >
          </div>
        </div>
        <div class="search_container">
          <el-form :inline="true" :model="ruleForm" ref="ruleForm">
            <el-form-item label="" prop="mStatus">
              <el-input
                v-model="ruleForm.mStatus"
                clearable
                placeholder="请输入工单编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="mType">
              <el-select
                v-model="ruleForm.mType"
                filterable
                clearable
                placeholder="请选择工单状态"
                @change="changeOpstatus($event)"
              >
                <el-option
                  v-for="(item, i) in materiaTypeOptions"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="mPosition">
              <el-date-picker
                v-model="ruleForm.mPosition"
                type="daterange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="" prop="accName">
                    <el-autocomplete
                      class="inline-input"
                      v-model="ruleForm.accName"
                      value-key="acountName"
                      :fetch-suggestions="querySearch"
                      placeholder="请输入账户名称"
                      @select="handleSelect"
                      clearable
                    ></el-autocomplete>
                  </el-form-item> -->
            <el-form-item>
              <el-button type="primary" size="small" @click="getDemandList(1)"
                >查询</el-button
              >
              <el-button type="info" size="small" @click="resetForm('ruleForm')"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <el-dialog
            title="提示"
            class="icon_custom_popup"
            :visible.sync="tipdialogVisible"
            width="300px"
          :close-on-click-modal="false"
          >
            <div class="icon">
              <i class="iconfont font_family icon-chakanyuanyinxuanting"></i>
            </div>
            <div class="text_tip">已提醒该指定人员</div>
            <span slot="footer">
              <!-- <el-button @click="tipdialogVisible = false">取 消</el-button> -->
              <el-button type="primary" @click="remindSure">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <div class="materiaContent">
          <el-table
            ref="multipleTable"
            :data="demandListData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" fixed="left">
            </el-table-column>
            <el-table-column
              prop="name"
              label="工单编号"
              width="200"
              fixed="left"
            >
              <template slot-scope="scope">{{
                scope.row.demandDetailNum
              }}</template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="工单名称"
              width="200"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.row.demandName }}</template>
            </el-table-column>
            <el-table-column prop="name" label="工单状态" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.opStatus == -1">待分配</span>
                <span v-if="scope.row.opStatus == 1">待完成</span>
                <span v-if="scope.row.opStatus == 2">等待审核</span>
                <span v-if="scope.row.opStatus == 3">运营审核通过</span>
                <span v-if="scope.row.opStatus == 4">运营审核拒绝</span>
                <span v-if="scope.row.opStatus == 5">已完成</span>
                <span v-if="scope.row.opStatus == 6">客户审核拒绝</span>
                <span v-if="scope.row.opStatus == 7">待接收</span>
                <span v-if="scope.row.opStatus == 8">已关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="提交时间" width="200">
              <template slot-scope="scope">{{
                scope.row.createTime | dateFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="需求日期" width="180">
              <template slot-scope="scope">
                <div
                  v-for="item in scope.row.demandCycle"
                  style="display: inline-block; margin: 0 2px"
                >
                  <span v-if="item == 1">周一</span>
                  <span v-if="item == 2">周二</span>
                  <span v-if="item == 3">周三</span>
                  <span v-if="item == 4">周四</span>
                  <span v-if="item == 5">周五</span>
                  <span v-if="item == 6">周六</span>
                  <span v-if="item == 7">周日</span>
                  <!-- <span v-if="scope.row.demandCycle == 8">每天</span> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column label="截止时间" width="120">
              <template slot-scope="scope">{{
                scope.row.deadline | dateFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="需求人员" width="120">
              <template slot-scope="scope">{{
                scope.row.submitUserName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="账户名称" width="120">
              <template slot-scope="scope">{{ scope.row.acountName }}</template>
            </el-table-column>
            <!-- <el-table-column
                  prop="name"
                  label="资源类型"
                  width="120">
                  <template slot-scope="scope">{{scope.row.demandName}}</template>
                </el-table-column> -->
            <el-table-column prop="name" label="资源位置" width="200">
              <template slot-scope="scope">{{
                scope.row.resourceBits
              }}</template>
            </el-table-column>
            <el-table-column label="需求数量" width="200">
              <template slot-scope="scope">{{ scope.row.needNum }}</template>
            </el-table-column>
            <el-table-column prop="name" label="素材限制" width="120">
              <template slot-scope="scope">{{ scope.row.size }}</template>
            </el-table-column>
            <el-table-column prop="name" label="素材备注" width="200">
              <template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  v-if="scope.row.materialDesc"
                >
                  <div v-html="scope.row.materialDesc">
                    {{ scope.row.materialDesc }}
                  </div>
                  <span
                    slot="reference"
                    class="zhugongdan"
                    v-html="scope.row.materialDesc"
                    >{{ scope.row.materialDesc }}</span
                  >
                </el-popover>
              </template>
              <!-- <template slot-scope="scope">{{scope.row.materialDesc}}</template> -->
            </el-table-column>
            <el-table-column prop="name" label="指定人员" width="200">
              <template slot-scope="scope"
                ><div v-html="scope.row.makerUserName"></div
              ></template>
            </el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <span style="padding-right: 10px">
                  <el-button
                    class="newBtn_garyGreen"
                    size="mini"
                    plain
                    v-if="scope.row.opStatus == 7"
                    @click="uploadForm(scope.row.id)"
                    >接收</el-button
                  >
                  <span v-if="typeFlag == 1">
                    <!-- <el-button class="newBtn_red" size="mini" plain v-if="scope.row.opStatus == 1||scope.row.opStatus == 2||scope.row.opStatus == 3||scope.row.opStatus == 4||scope.row.opStatus == 6" @click="upload(scope.row.id)">上传</el-button> -->
                    <el-button
                      class="solid-tag-instructions"
                      v-if="
                        scope.row.opStatus == 1 ||
                        scope.row.opStatus == 2 ||
                        scope.row.opStatus == 3 ||
                        scope.row.opStatus == 4 ||
                        scope.row.opStatus == 6
                      "
                      @click="upload(scope.row)"
                      >上传</el-button
                    >
                  </span>
                  <span v-else></span>
                  <el-button type="text" v-if="scope.row.opStatus == 5"
                    >已完成</el-button
                  >
                  <el-button
                    class="newBtn_darkBlue"
                    size="mini"
                    plain
                    v-if="scope.row.opStatus == -1"
                    >待分配（视频部门有权分配）</el-button
                  >
                </span>
                <span>
                  <!-- <span v-if="scope.row.opStatus == 5"></span>
                      <span v-else> -->
                  <el-button
                    class="newBtn_garyWhite"
                    size="mini"
                    plain
                    v-if="
                      scope.row.stopFlag == 1 &&
                      scope.row.opStatus != 5 &&
                      scope.row.opStatus != 8
                    "
                    @click="demandStstu(scope.row.id)"
                    >结束工单</el-button
                  >
                  <el-button
                    type="text"
                    v-if="scope.row.stopFlag == 2"
                  ></el-button>
                  <!-- </span> -->
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footerPaging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageCount"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.totalRow"
          >
          </el-pagination>
        </div>
      </template>
      <template slot="headerGroup"> </template>
    </PocCardTable>
  </div>
</template>
  
  <script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";

export default {
  name: "videoMaterialList",
  data() {
    return {
      ruleForm: {
        //头部查询表单的prop
        mStatus: "",
        mType: "",
        mPosition: "",
        accName: "",
      },
      // materiaTypeOptions: [{//查询表单的选择账户名称
      //   value: '1',
      //   label: '待完成'
      // }, {
      //   value: '2',
      //   label: '待审核'
      // }, {
      //   value: '3',
      //   label: '运营审核通过'
      // }, {
      //   value: '4',
      //   label: '运营审核拒绝'
      // }, {
      //   value: '5',
      //   label: '客户审核通过'
      // }, {
      //   value: '6',
      //   label: '客户审核未通过'
      // }, {
      //   value: '7',
      //   label: '已分配未接收'
      // }],
      materiaTypeOptions: [
        {
          //查询表单的选择账户名称
          value: "-1",
          label: "待分配",
        },
        {
          value: "1",
          label: "待完成",
        },
        {
          value: "7",
          label: "未接收",
        },
        {
          value: "5",
          label: "已完成",
        },
        {
          value: "8",
          label: "拒绝并关闭 ",
        },
      ],
      materiaPositionOptions: [
        {
          //查询表单的选择运营人员
          value: "选项1",
          label: "联盟",
        },
        {
          value: "选项2",
          label: "富媒体",
        },
        {
          value: "选项3",
          label: "信息流",
        },
      ],
      multipleSelection: [],
      videoDetailId: "",
      demandDetailParams: {
        channel: "",
        deptId: "",
        userId: "",
        demandId: "",
        pages: "",
        pageCount: "",
        demandDetailNum: "",
        opStatus: "",
        startime: "",
        deadline: "",
        acountName: "",
      },
      // 分页
      page: 1, //当前页
      pageCount: 10, //每页大小
      totalRow: 0, //总条数
      totalPage: 0, //总页数
      demandListData: [],
      typeFlag: "",
      queryParams: {
        demandNum: "",
        demandName: "",
        opStatus: "",
        startime: "",
        deadline: "",
      },
      uploadParams: {
        demandDetailIds: [],
        userId: "",
      },
      multipleSelection: [],
      checkedId: [],
      remindParams: {
        demandDetailIds: [],
      },
      tipdialogVisible: false,
      stopParams: {
        demandDetailId: "",
        userId: "",
      },
    };
  },
  computed: {},
  mounted() {
    this.getId();
    this.getuserChannel();
    this.queryVideoList();
  },

  methods: {
    //头部表单重置
    resetForm(ruleForm) {
      let that = this;
      that.$refs[ruleForm].resetFields();
      that.$router.go(0);
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.demandListData;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.acountName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelect(item) {
      console.log(item);
    },

    //修改后的关联到user的channel
    getuserChannel() {
      let that = this;
      let userChannel = String(
        that.$sessionStorage.getItem(config.USERID) || 1
      );
      $http.axios
        .get("/api/system/user/getChannelByUser?userId=" + userChannel)
        .then(function (response) {
          if (response.data.data.length != 0) {
            // that.materiaChannelOptions = response.data.data
            // that.materiaForm.showQdList = response.data.data[0].name
            that.demandDetailParams.channel = response.data.data[0].id;
            that.getDemandList();
          }
        });
    },

    //点击上传跳转页面
    upload(row) {
      // this.$router.push({path:'/auth-management/UploadMaterial/'+e})
      // this.$router.push({path:'/auth-management/UploadMaterial/'+e})
      // if(row.acountChannel == 1 || row.acountChannel == 2){
      //   this.$router.push({path:'/auth-management/UploadMaterial/'+row.id})
      //   // this.$router.push({path:'/auth-management/UploadMaterialPic/'+row.id})
      // }else{
      //   this.$router.push({path:'/auth-management/UploadMaterialPic/'+row.id})
      // }

      if (row.acountChannel == 1 || row.acountChannel == 2) {
        this.$router.push({
          path: "/auth-management/UploadMaterial/" + row.id,
        });
      }
      // else if(row.acountChannel == 3){
      //   this.$router.push({path:'/auth-management/UploadMaterialPic/'+row.id})//记得放开，这个是oppo渠道的
      // }
      else {
        this.$router.push({
          path: "/auth-management/UploadMaterialPicWait/" + row.id,
        }); //vivo渠道的，用oppo的渠道id进行的测试，因为vivo还没有工单
      }
    },

    //获取列表id
    getId() {
      this.videoDetailId = this.$route.params && this.$route.params.id;
      console.log(this.videoDetailId);
      this.demandDetailParams.demandId = this.videoDetailId;
      console.log(this.demandDetailParams.demandId);
    },

    //获取主工单详情列表
    getDemandList(page) {
      let that = this;
      if (page == 1) {
        that.page = 1;
      }
      that.demandDetailParams.demandDetailNum = that.ruleForm.mStatus;
      that.demandDetailParams.opStatus = that.ruleForm.mType;
      if (that.ruleForm.mPosition != null) {
        that.demandDetailParams.deadline = that.ruleForm.mPosition[1];
        that.demandDetailParams.startime = that.ruleForm.mPosition[0];
      } else {
        that.demandDetailParams.startime = "";
        that.demandDetailParams.deadline = "";
      }
      // that.demandDetailParams.startime = that.ruleForm.mPosition[0]
      // that.demandDetailParams.deadline = that.ruleForm.mPosition[1]
      that.demandDetailParams.deptId = that.$sessionStorage.getItem(
        config.DEPTID
      );
      that.demandDetailParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      that.demandDetailParams.pages = that.page;
      that.demandDetailParams.pageCount = that.pageCount;
      that.demandDetailParams.acountName = that.ruleForm.accName;

      $http.axios
        .post(
          "/api/operationManagement/materialDemand/demandview",
          that.demandDetailParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData = res.data.data.items;
            that.totalRow = res.data.data.totalCount;
            that.typeFlag = res.data.data.typeFlag;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //查询
    queryVideoList() {
      let that = this;
      that.demandDetailParams.demandDetailNum = that.ruleForm.mStatus;
      that.demandDetailParams.opStatus = that.ruleForm.mType;
      that.demandDetailParams.startime = that.ruleForm.mPosition[0];
      that.demandDetailParams.deadline = that.ruleForm.mPosition[1];
      that.demandDetailParams.deptId = that.$sessionStorage.getItem(
        config.DEPTID
      );
      that.demandDetailParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      that.demandDetailParams.pages = that.page;
      that.demandDetailParams.pageCount = that.pageCount;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/demandview",
          that.demandDetailParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData = res.data.data.items;
            that.totalRow = res.data.data.totalCount;
            that.typeFlag = res.data.data.typeFlag;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //change状态
    changeOpstatus(event) {
      this.queryParams.opStatus = event;
    },

    queryDemandList() {
      let that = this;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/demandview",
          that.queryParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData = res.data.data.items;
            that.totalRow = res.data.data.totalCount;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //点击接收状态变化
    uploadForm(e) {
      let that = this;
      that.uploadParams.demandDetailIds = [];
      that.uploadParams.demandDetailIds.push(e);
      that.uploadParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/acpectdemand",
          that.uploadParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData.opStatus == 1;
            that.getDemandList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //多选表格进行提醒
    remind() {
      let that = this;
      // that.tipdialogVisible = true
      let checkedSelection = that.$refs.multipleTable.selection;
      console.log(checkedSelection);
      that.checkedId = [];
      checkedSelection.forEach(function (e, i) {
        that.checkedId.push(e.id);
      });
      that.remindParams.demandDetailIds = that.checkedId;
      if (checkedSelection.length == 0) {
        that.$message("请勾选相应工单");
      } else {
        $http.axios
          .post(
            "/api/operationManagement/materialDemand/warmdemand",
            that.remindParams
          )
          .then((res) => {
            if (res.data.code == 200) {
              that.tipdialogVisible = true;
            } else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      }
    },
    remindSure() {
      this.tipdialogVisible = false;
      this.getDemandList();
    },

    //批量接收
    pljs() {
      let that = this;
      // that.tipdialogVisible = true
      that.uploadParams.demandDetailIds = [];
      let checkedSelection = that.$refs.multipleTable.selection;
      // console.log(checkedSelection)
      // that.checkedId = []
      checkedSelection.forEach(function (e, i) {
        // that.checkedId.push(e.id)
        that.uploadParams.demandDetailIds.push(e.id);
      });

      // that.uploadParams.demandDetailIds=[]
      // that.uploadParams.demandDetailIds.push(e)
      that.uploadParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/acpectdemand",
          that.uploadParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.demandListData.opStatus == 1;
            that.getDemandList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //结束工单
    demandStstu(i) {
      let that = this;
      that.stopParams.demandDetailId = i;
      that.stopParams.userId = that.$sessionStorage.getItem(config.USERID) || 1;
      $http.axios
        .post(
          "/api/operationManagement/materialDemand/stopdemand",
          that.stopParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.getDemandList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    // 分页
    handleSizeChange(val) {
      this.pageCount = val;
      var params = {
        page: this.page,
        pageCount: this.pageCount,
      };
      this.findAll(params);
    },
    handleCurrentChange(val) {
      this.page = val;
      var params = {
        page: this.page,
        pageCount: this.pageCount,
      };
      this.findAll(params);
    },
    //分页方法
    findAll(params) {
      //获取数据接口（调用封装的接口）
      this.getDemandList();
    },
    //表格中选中和未选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.materiaContent {
  margin-top: 25px;
}
.zhugongdan {
  height: 67px;
  /* overflow:hidden!important;
    text-overflow:ellipsis!important ;
    white-space: nowrap!important; */

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.el-card {
  overflow: visible;
}
</style>
