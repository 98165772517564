<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle" v-if="accountSelectionListShow">
          <div class="chanelTab">
            <span class="chanelTabWord">消耗汇总 - </span>
            <span class="channel_oppo" 
              v-for="(item,i) in qdoptions" 
              :class="item.id==3?'channelActive':''" 
              :key="i" 
              @click="item.id!=3&& clickChangeChannel(item.id)"
            >
            {{item.name.toUpperCase()}}
          </span>
          </div>
          <div>
            <el-tabs v-model="activeName" type="card" class="stTab">
              <el-tab-pane label="视频" name="first">
                <div class="videoWrap">
                  <div class="materialHead">
                    <div class="materialHBtns">
                      <el-form :inline="true" :model="materiaForm" :rules="rules"  ref="materiaForm" class="demo-form-inline">
                        <el-form-item label="" prop="startAndEnd">
                          <el-date-picker
                            v-model="materiaForm.startAndEnd"
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:300px;"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            align="right">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="" prop="materialFormId">
                          <el-input v-model="materiaForm.materialFormId" clearable placeholder="请输入素材ID"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="materialFormName">
                          <el-input v-model="materiaForm.materialFormName" clearable placeholder="请输入素材名称"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="zzrFormName">
                          <!-- <el-select v-model="materiaForm.zzrFormName" filterable clearable placeholder="请选择制作人">
                            <el-option
                              v-for="(item,i) in mPnameOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select> -->
                          <el-input v-model="materiaForm.zzrFormName" clearable placeholder="请输入制作人"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="">
                          <el-form-item label="" prop="" style="display: inline-block;width:80px;">
                            <el-select 
                              v-model="materiaForm.tfSymbol" 
                              filterable 
                              clearable 
                              @change="changeOperator"
                              placeholder="符号">
                              <el-option
                                v-for="(item,i) in tfSymbolOptions"
                                :key="i"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="" prop="" style="display: inline-block;">
                            <el-input v-model="materiaForm.costPrice" clearable placeholder="请输入消耗金额"></el-input>
                          </el-form-item>                         
                        </el-form-item>
                        <el-form-item label="" prop="tfMaterialTab">
                          <!-- <el-select v-model="materiaForm.tfMaterialTab" filterable clearable placeholder="请选择素材标签">
                            <el-option
                              v-for="(item,i) in tfMaterialTabOptions"
                              :key="i"
                              :label="item.tagName"
                              :value="item.id">
                            </el-option>
                          </el-select> -->
                          <el-input v-model="materiaForm.tfMaterialTab" clearable placeholder="请输入素材标签"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="materialSpecify">
                          <el-select v-model="materiaForm.materialSpecify" filterable clearable placeholder="请选择素材规格">
                            <el-option
                              v-for="(item,i) in materialSpecifyOptions"
                              :key="i"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" size="small" @click="getMaterialList(1)">查询</el-button>
                          <el-button type="info" plain size="small" @click="resetForm('materiaForm')">重置</el-button>
                          <el-button type="warning" size="small" style="margin-bottom:10px;" @click="exprot">导出</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                  <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    show-summary
                    :summary-method="spanMethod"
                    @sort-change="sortChange">
                    <el-table-column
                      type="selection"
                      width="55">
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="素材名称"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="mid"
                      label="素材ID"
                      width="160">
                    </el-table-column>
                    <el-table-column
                      prop="ownerId"
                      label="广告主ID"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      prop="mkUser"
                      label="制作人"
                      width="80">
                    </el-table-column>
                    <el-table-column
                      prop="videoUrl"
                      label="视频素材地址"
                      width="150">
                      <template slot-scope="scope">
                        <img 
                          src="@/assets/img/videoImg.jpeg" 
                          style="width:30%;cursor: pointer;" 
                          @click="showVideo(scope.row)"/> 
                      </template>
                    </el-table-column>
                    <el-table-column 
                      prop=""
                      label="视频地址"
                      width="100">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="copyOssUrl(scope.row)" class="solid-tag-instructions">复制链接</el-button>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="mkUserTag"
                      label="制作人标签"
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="spec"
                      label="规格"
                      width="180">
                      <template slot-scope="scope">
                        <span v-if="scope.row.spec == 4049">联盟-竖版视频(9:16)</span>
                        <span v-else-if="scope.row.spec == 4050">联盟-横板视频(16:9)</span>
                        <span v-else-if="scope.row.spec == 4051">信息流-竖版视频(9:16)</span>
                        <span v-else-if="scope.row.spec == 4052">信息流-横板视频(16:9)</span>
                      </template>
                    </el-table-column>
                    <el-table-column 
                      prop="tagName"
                      label="素材标签"
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="cost"
                      label="消耗"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="expose"
                      label="曝光量"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="click"
                      label="点击量"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="clickPrice"
                      label="点击均价"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="clickRate"
                      label="点击率"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="ecpm"
                      label="ecpm"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="active"
                      label="激活量"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="register"
                      label="注册量"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="registerPrice"
                      label="注册成本"
                      sortable
                      width="100">
                    </el-table-column>
                    <el-table-column 
                      prop="appPay"
                      label="应用内付费量"
                      sortable
                      width="150">
                    </el-table-column>
                    <el-table-column 
                      prop="appPayPrice"
                      label="应用内付费成本"
                      sortable
                      width="150">
                    </el-table-column>
                    <el-table-column 
                      prop="appCustom"
                      label="应用内自定义转化"
                      sortable
                      width="160">
                    </el-table-column>
                    <el-table-column 
                      prop="dp"
                      label="回传拉活量"
                      sortable
                      width="120">
                    </el-table-column>
                    <el-table-column 
                      prop="dpConvertActivationNums"
                      label="deeplink回传激活量"
                      sortable
                      width="180">
                    </el-table-column>
                    <el-table-column 
                      prop="appCustomPrice"
                      label="应用内自定义转化成本"
                      sortable
                      width="190">
                    </el-table-column>
                    <el-table-column 
                      prop="dpConvertActivationPrice"
                      label="deeplink回传激活成本"
                      sortable
                      width="190">
                    </el-table-column>
                    <el-table-column 
                      prop="dpPrice"
                      label="回传拉活成本"
                      sortable
                      width="160">
                    </el-table-column>
                    <!-- <el-table-column 
                      prop=""
                      label="操作"
                      fixed="right"
                      width="100">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="copyOssUrl(scope.row)" class="solid-tag-instructions">复制链接</el-button>
                      </template>
                    </el-table-column> -->
                  </el-table>
                  <div class="footerPaging">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="page"
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="pageCount"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.totalRow">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="图文" name="second">
                <iframe 
                  id="iframe" 
                  ref="iframeref" 
                  name="mainFrame" 
                  src="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=19059204-59d1-4d71-afa5-71886af5bdb1&accessToken=19823222768e9cdf546e9f52efd31a62&dd_orientation=auto" 
                  class="biConsumptionSum">
                </iframe>
              </el-tab-pane>
            </el-tabs>
          </div>
          
          <!-- 播放视频 -->
          <el-dialog
            title=""
            :visible.sync="centerDialogVisible"
            width="100%"
            background="rgba(0,0,0,0)"
            center
            :before-close="handleDialogClose"
            class="mineBg">
            <span style="text-align:center;display:block;">
              <video 
                :src="videoUrlShow" 
                controls 
                autoplay 
                controlslist="nodownload" 
                ref="videoPouse" 
                style="width:400px;margin:0 auto;">
              </video>
            </span>
          </el-dialog>
          <!-- 正在载入 -->
          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入...</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
          <!-- 导出 -->
          <el-dialog
            title="确认导出吗"
            :visible.sync="exprtVisible"
            width="30%">
            <span class="el-icon-loading uploading" v-show="uploadingShow"></span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="exprtVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureExport">确 定</el-button>
            </span>
          </el-dialog>
          <iframe :src="html" style="display: none;"></iframe>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'demandOrder',
    data() {
      return {
        rules: {},
        qdoptions: [],//渠道列表
        materiaForm:{//头部查询表单的prop
          startAndEnd:[],
          materialFormId:'',
          materialFormName:'',
          zzrFormName:'',
          costPrice:'',
          tfSymbol:'',
          tfMaterialTab:'',
          materialSpecify:'',
        },
        mPnameOptions:[],//制作人
        tfMaterialTabOptions:[],//素材标签
        materialSpecifyOptions:[{
          value:4049,
          label:'联盟-竖版视频(9:16)'
        },{
          value:4050,
          label:'联盟-横板视频(16:9)'
        },{
          value:4051,
          label:'信息流-竖版视频(9:16)'
        },{
          value:4052,
          label:'信息流-横板视频(16:9)'
        }],//素材规格
        exprtVisible:false,
        tableData: [],
        tfSymbolOptions:[{
          value: '=',
          label: '='
        },{
          value: '>=',
          label: '>='
        }, {
          value: '<=',
          label: '<='
        }],
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        consumeParamsOppo:{},
        tagListParams:{//标签列表参数
          tagType:'2',
          userId:'',
          channel:''
        },
        userChannel:'',
        uploadingShow:false,//导出
        accountSelectionListShow:true,//用户是否有权限
        activeName: 'first',//默认second
        screenHeight:window.innerHeight-300,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        centerDialogVisible:false,
        videoUrlShow:'',
        userId:'',
        userName:'',
        deptId:'',
        loadingDialogVisible:true,
        asc:'',
        prop:'',
        operator:'',
        customNum:{},
        videoPath:'',
        html:''


      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      let nowTimes = Date.now();
      // let oldTimes = Date.now();
      let oldTimes = nowTimes - (86400000 * 6)
      let oldDate = this.getDate(oldTimes); //一个月前的时间
      let nowDate = this.getDate(nowTimes);//今天的时间
      this.materiaForm.startAndEnd = [oldDate, nowDate];
      console.log(this.materiaForm.startAndEnd)
      // this.getDefaultWeekRange()
      this.getuserChannel()
    },
    methods: {
      //格式化时间 返回年月日 
      getDate(time) {
        if (time) {
          let val = new Date(time);
          let Y = val.getFullYear();
          let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
          let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
          let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
          let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
          return Y + '-' + M + '-' + D;
        }
        return ''
      },

      //头部表单重置
      resetForm(materiaForm) {
        this.$refs[materiaForm].resetFields();
        this.$router.go(0)
      },

      //查询的素材标签下拉框数据
      getTagList(){
        let that = this
        that.tagListParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/material/taglist",that.tagListParams).then(res=> {
          if(res.data.code == 200){
            that.tfMaterialTabOptions = res.data.data
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取人员信息
      getStaffDetail(){
        let that = this
        $http.axios.get("/api/system/user/optionselect").then(function(response) {
          that.mPnameOptions = response.data//制作人
        })
      },

      //导出
      exprot(){
        this.exprtVisible = true
      },

      //确认导出
      sureExport(){
        let that = this,params = {}
        that.uploadingShow = true
        let timestamp = Date.parse(new Date())
        $http.axios.defaults.timeout = 500000
        params = {
          'userId':that.userId,
          'userName':that.userName,
          'deptId':that.deptId,
          'channelId':3,
          'materialId':that.materiaForm.materialFormId,//素材ID
          'materialName':that.materiaForm.materialFormName,//素材名称
          'mkUserName':that.materiaForm.zzrFormName,//制作人
          // 'operator':that.materiaForm.tfSymbol,//运算符
          'val':that.materiaForm.costPrice,//运算值
          'specId':that.materiaForm.materialSpecify,//规格id
          'tag':that.materiaForm.tfMaterialTab,//素材标签
          'sDate':that.materiaForm.startAndEnd[0],//开始时间
          'eDate':that.materiaForm.startAndEnd[1],//结束时间
          // 'oppoOrderBy':that.prop,//排序字段
          // 'asc':that.asc,//排序顺序,排序顺序 ASC DESC 默认DESC

        }
        if(that.prop == ''){
          params.oppoOrderBy = 'cost'
        }else{
          params.oppoOrderBy = that.prop
        }
        if(that.asc == ''){
          params.asc = 'DESC'
        }else{
          params.asc = that.asc
        }
        if(that.materiaForm.tfSymbol != ''){
          params.operator = that.operator
        }

        $http.axios.post("/api/dataSync/material/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
          that.exprtVisible = false 
          let blob = response.data
          let reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = e => {
            let a = document.createElement('a')
            a.download = "素材消耗数据"+timestamp+".xls"
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            that.uploadingShow = false
          }
        })
      },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
          if(response.data.code == 200){
            if(response.data.data.length!=0){
              that.qdoptions = response.data.data
              that.accountSelectionListShow=true//用户是否有权限
              console.log(that.qdoptions.id)
              if(that.qdoptions.id!=3){
                let channelNameList = {4:'VivoAccountManagement.ConsumptionSumVivo',5:'MiAccountManagement.ConsumptionSumMi',6:'authManagement.ConsumptionSumElse',7:'authManagement.ConsumptionSumElse'}
                that.$router.push({name:channelNameList[that.qdoptions[0].id]})
              }else{
                that.getTagList()//标签
                that.getStaffDetail()//制作人              
              }
              that.getMaterialList()
            }else{
              that.accountSelectionListShow = false
              that.$message({
                showClose: true,
                message: "您的操作权限不足",
                type: 'error'
              })
            }
          }
        })
      },
      //channel的tab切换
      clickChangeChannel(channelId){
        let channelNameList = {4:'VivoAccountManagement.ConsumptionSumVivo',5:'MiAccountManagement.ConsumptionSumMi',6:'authManagement.ConsumptionSumElse',7:'authManagement.ConsumptionSumElse'}
        this.$router.push({name:channelNameList[channelId]})
      }, 

      //排序
      sortChange(column) {
        console.log(column)
        this.prop = column.prop
        if(column.order == 'ascending'){
          this.asc = 'ASC'
        }else{
          this.asc = 'DESC'
        }
        this.getMaterialList()
      },

      //change运算符
      changeOperator(val){
        this.operator = this.materiaForm.tfSymbol
        // this.getMaterialList()
      },

      //消耗列表
      getMaterialList(page){
        this.loadingDialogVisible = true
        let that = this,params = {}
        params = {
          'userId':that.userId,
          'userName':that.userName,
          'deptId':that.deptId,
          'pageIndex':page,
          'pageSize':that.pageCount,
          'channelId':3,
          'materialId':that.materiaForm.materialFormId,//素材ID
          'materialName':that.materiaForm.materialFormName,//素材名称
          'mkUserName':that.materiaForm.zzrFormName,//制作人
          // 'operator':that.materiaForm.tfSymbol,//运算符
          'val':that.materiaForm.costPrice,//运算值
          'specId':that.materiaForm.materialSpecify,//规格id
          'tag':that.materiaForm.tfMaterialTab,//素材标签
          'sDate':that.materiaForm.startAndEnd[0],//开始时间
          'eDate':that.materiaForm.startAndEnd[1],//结束时间
          // 'oppoOrderBy':that.prop,//排序字段
          // 'asc':that.asc,//排序顺序,排序顺序 ASC DESC 默认DESC
        }
        if(that.prop == ''){
          params.oppoOrderBy = 'cost'
        }else{
          params.oppoOrderBy = that.prop
        }
        if(that.asc == ''){
          params.asc = 'DESC'
        }else{
          params.asc = that.asc
        }
        if(that.materiaForm.tfSymbol != ''){
          params.operator = that.operator
        }

        $http.axios.post("/api/dataSync/material/list",params).then(function(response) {
          if(response.data.code == 200){
            that.loadingDialogVisible = false
            that.tableData = response.data.data.oppoInfos
            that.totalRow = response.data.data.pageInfo.rowCount
            that.customNum = response.data.data.oppoSum
 
          } else {
            that.loadingDialogVisible = false
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      // 分页
      handleSizeChange(val) {
        let _this = this
        _this.pageCount = val
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        this.findAll(val)
      },
      //分页方法
      findAll (val) {
        this.page = val
        this.getMaterialList(val)
      },

      //根据id弹出视频
      showVideo(row){
        this.centerDialogVisible = true
        this.videoUrlShow = row.videoUrl
        this.$nextTick(() => {
          this.$refs.videoPouse.play()
        })
      },
      handleDialogClose(){
        this.centerDialogVisible = false
        this.$refs.videoPouse.pause()
      },

      spanMethod(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[0] = '合计';
            return;
          }
          if (index >= 9) {
            sums[index] = this.customNum[column.property];
            return;
          }
          sums[index] = '';
        });
        return sums;
      },

      copyOssUrl(row) {
        const input = document.createElement('input');
        this.html = 'https://iad.hljyer.com/videoCopy.html?'+encodeURIComponent(row.videoUrl)
        input.value = this.html // 设置要复制的内容
        document.body.appendChild(input);
        input.select(); // 选中内容
        document.execCommand('copy'); // 执行复制
        document.body.removeChild(input); // 移除临时的输入框
        this.$message({
          showClose: true,
          message: '链接已复制'+this.html,
          type: 'success'
        })

      },

    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materialHead{
      font-size:0;
    }
    .materialHchannel,.materialHBtns{
      display:inline-block;
      vertical-align:middle;
      font-size:14px;
    }
    .materialHchannel{
      width:30%;
    }
    /* .materialHBtns{
      width:70%;
    } */
    .materiaContent{
      margin-top:15px;
    }
    .showTheForm,.hideenTheForm{
      display:inline-block;
      vertical-align:top;
    }
    .showTheForm{
      position:relative;
    }
    .showTheForm i{
      position:absolute;
      bottom:-10px;
      left:50%;
      margin-left:-11px;
      font-size:22px;
      color:rgb(36, 132, 168);
      cursor:pointer;
    }
    .hideenTheForm i{
      font-size:22px;
      vertical-align:middle;
      color:rgb(36, 132, 168);
      cursor:pointer;
      margin:0 20px;
    }
    .tableTop{
      margin-bottom:20px;
    }
    .customColumns{
      float:right;
    }
    .customBox{
      width:100%;
      padding:0 20px;
      box-sizing: border-box;
      font-size:0;
    }
    .customContent,.customExist{
      font-size:14px;
      display:inline-block;
      vertical-align:top;
      box-sizing: border-box;
    }
    .customContent{
      width:60%;
      padding-right:15px;
    }
    .customExist{
      width:40%;
    }
    .customExist span{
      display:block;
    }
    .ss{
      cursor:pointer;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }
    .customColumnsTag{
      cursor:default;
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .customColumnsTag:hover{
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .uploading{
      display:block;
      text-align:center;
      font-size:50px;
    }
    /* oppo和vivo的tab切换 */
    .chanelTab{
      margin-bottom:10px;
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:36px;
      line-height:36px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
    .biConsumptionSum{
      width:100%;
      border:none;
      height:1000px;
      /* height:100%; */
      /* position: absolute;
      top:132px;
      left:50%;
      transform: translateX(-50%);
      z-index:10;
      background: none; */
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      margin:0!important;
    }
    ::v-deep .el-tabs__item {
      line-height:24px;
      height:24px;
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      background: #66856d;
      color:#fff;
    }
    .chanelTabWord{
      font-size:15px;
      font-weight: 700;
    }
    /*xin*/
    .videoWrap{
      margin-top:20px;
    }
     /*新版*/
    ::v-deep .materialHead .el-input--mini .el-input__inner{
      /* width:160px; */
      height:32px;
      line-height:32px;
      background:#F5F7F6;
      border:none;
    }
    .el-form-item--mini.el-form-item{
      margin-bottom:10px;
    }
    .loading{
      text-align:center;
    }
    .loading span,.loading i{
      display: block;
    }
    .loading span{
      font-size:20px;
      font-weight:700;
      color:#fff;
    }
    .loading i{
      margin-top:10px;
      font-size:60px;
      /* color: #409eff; */
      color:#fff;
    }
    /* .loadingBg{
      margin-top:200px;
    } */
    ::v-deep .loadingBg .el-dialog{
      margin-top:200px;
      background:rgba(255,255,255,0)!important;
      box-shadow: none;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }
    ::v-deep .mineBg .el-dialog__headerbtn{
      right:420px;
      top:10px;
    }

    ::v-deep .el-table {
      display: flex;
      flex-direction: column;
    }
    ::v-deep .el-table__body-wrapper {
      order: 1;
    }
    ::v-deep .el-table__fixed-body-wrapper {
      top: 96px !important;
    }
    ::v-deep .el-table__fixed-footer-wrapper {
      z-index: 0;
    }

  </style>
