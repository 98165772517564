<template>
  <div class="hbImg">
    <ul class="sucai clearfix">
      <li>
        <div><i>*</i><span>图片</span></div>
        <div class="imgBox">
          <img class = "Img" :src="childrenVue.sc.imgUrl" alt="">
          <div class="addIcon">+</div>
          <div>点击或拖拽素材</div>
          <!-- <div class="sck">素材库选择</div> -->
          
        </div>
        <div>
          <div>图片尺寸 1080 x 1920</div>
          <div>图片格式 jpg/png/jpeg</div>
          <div>图片大小 &lt; 300 KB</div>
        </div>
      </li>
      <li>
        <div><i>*</i><span>品牌 Logo</span></div>
        <div class="imgBox">
          <img class = "Img" :src="childrenVue.sc.logoUrl" alt="">
          <div class="addIcon">+</div>
          <div>点击或拖拽素材</div>
          <!-- <div class="sck">素材库选择</div> -->
          
        </div>
        <div>
          <div>图片尺寸 200 x 200</div>
          <div>图片格式 jpg/png/jpeg</div>
          <div>图片大小 &lt;100 KB</div>
      </div>
      </li>
    </ul>
    <div>
      <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-switch
          style="display: block"
          v-model="value2"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="不开启">
        </el-switch>
        <el-form-item label="文案" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请输入 1 - 23 字的文案">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入 1 - 8 字的品牌名称"></el-input>
        </el-form-item>
      </el-form> -->
    </div>
  </div>
</template>
<script>
import config from '@/api/config';
import { $http } from '@/api/http';
import imgDialog from "@/views/dialog/imgDialog";//广告创意
export default {
   
  props: {
    specId:Number,
    childrenVue:Object,
  },
  data() {
      return {
        value2:true,
        imgDialogShow:false,
        ruleForm: {
          name: '',
          region: '',
        },
        rules: {
          name: [
            { required: true, message: '品牌名称不能为空', trigger: 'blur' },
            { min: 1, max: 18, message: '长度在 1 到 8 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '文案不能为空', trigger: 'change' }
          ],
          sucai:{}
        },
      };
    },
    mounted(){
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.ownerId = Number(this.$route.query.ownerId)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      console.log('H')

    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // listFn(){
      //   this.imgDialogShow = true
      //   this.$refs.imgDialog.listFn(60,this.userId,this.deptId)
      // }
     
    }
}
</script>
<style lang="scss" scoped>
.hbImg{
    width: 950px;
    background-color: #f7f8fb;
    padding: 15px 0;
    border-radius: 12px;}
.hbImg li{
  float: left;
}
.imgBox{
  width: 198px;
  height: 120px;
  font-size: 14px;
  padding: 5px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #999;
  border: 1px dashed #E5E5E5;
  background-color: #fff;
  margin: 8px 8px 8px 0;  position: relative;
}
.addIcon{
  font-size: 30px;
}
.sck{
    position: absolute;
    border-radius: 8px;
    cursor: pointer;
    width: 180px;
    height: 28px;
    bottom: 20px;
    left: 9px;
    line-height: 28px;
    background-color: #EEF0F7;
    text-align: center;
}
.sck:hover{
  color:#2fc29b;
}
.sck.twoBtn{
  width: 85px;
}
.sck.twoBtn.rightPart{left: auto;
  right: 9px;
  cursor: default;
  color: #bbb;}
.Img{
    max-width: 200px;
    max-height: 110px;
    position: absolute;
}
</style>