<template>
  <div class="poc-container">
    <el-container style="height: 100%;">
      <el-header class="poc-header">
        <div class="poc-header-logo">
          <slot name="logo"></slot>
        </div>
        <div class="poc-header-right">
          <slot name="rightGroup"></slot>
        </div>
      </el-header>

      <el-container class="poc-body" :class="$slots.hasOwnProperty('footer') ? 'have-footer' : 'no-footer'">
        <el-aside>
          <slot name="menu"></slot>
        </el-aside>

        <el-main>
          <el-scrollbar class="poc-scrollbar-default">
            <slot></slot>
          </el-scrollbar>
        </el-main>
      </el-container>

      <el-footer v-if="$slots.hasOwnProperty('footer')" class="poc-footer">
        <slot name="footer"></slot>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  // 这个名字很重要，它就是未来的标签名<poc-container></poc-container>
  name: 'PocContainer',

  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import '../../var.scss';

.poc-container {
  height: 100%;
  // background: $poc-theme-bg-color;

  .poc-header {
    height: $poc-header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $poc-theme-bg-color;
    color: $poc-text-color;

    .poc-header-logo {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        height: 100%;
        margin-right: 16px;
      }
    }
  }

  .poc-body {
    .el-aside {
      background: $white-bg;
      width: auto !important;
      // border-right: 1px solid $poc-border-color;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      z-index: 1;
    }

    .el-main {
      background: $base-bg-color;
      padding: 0;
    }
  }

  .have-footer {
    height: calc(100% - #{$poc-header-height} - #{$poc-footer-height});
  }

  .no-footer {
    height: calc(100% - #{$poc-header-height});
  }

  .poc-footer {
    height: $poc-footer-height;
    display: flex;
    align-items: center;
    background: $poc-theme-bg-color;
    color: $poc-text-color;
  }

  .poc-scrollbar-default {
    height: 100%;
    width: 100%;

    .el-scrollbar__view {
      height: 100%;
    }
  }
}
</style>
