<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item>计划管理</el-breadcrumb-item>
                <el-breadcrumb-item  :to="{ path: '/plan-management/AccountSelection' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>调价展示</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>  
          <!-- tab切换 -->
          <ul class="modifyTab">
            <li @click="priceModifyPlan">账户</li>
            <li class="modifyActive">计划</li>
            <li @click="priceModifyGroup">广告组</li>
            <li @click="priceModifyGroupPrice">广告组转化出价</li>
          </ul>
            <div class="channelFlowHead">
                <el-form :inline="true" :model="priceForm" ref="priceForm" class="demo-form-inline">
                    <el-form-item label="" prop="planName">
                        <el-autocomplete
                            class="inline-input"
                            v-model="priceForm.planName"
                            value-key="planName"
                            :fetch-suggestions="querySearch"
                            placeholder="搜索计划名称"
                            @select="handleSelect"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" class="newQuery" size="small" @click="priceModifyList">查询</el-button>
                      <el-button type="info" size="small" @click="resetForm('priceForm')">重置</el-button>
                    </el-form-item>
                </el-form> 
            </div>
            <div class="channelFlowBody">
                <div class="channelTable">
                    <el-table
                      :data="tableData"
                      border
                      style="width: 100%">
                      <el-table-column
                      type="selection"
                      width="50">
                      </el-table-column>
                      <el-table-column
                        prop="planName"
                        label="计划名称">
                      </el-table-column>
                      <el-table-column
                        prop="planId"
                        label="计划ID"
                        width="200">
                      </el-table-column>
                      <el-table-column
                        prop="count"
                        label="调价时间段">
                        <template slot-scope="scope">
                          <span class="priceTimes" @click="timeSlot(scope.row)">{{scope.row.count}}个时间段</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="operation"
                        label="操作">
                        <template slot-scope="scope">
                          <el-button class="solid-tag-success" plain size="mini" @click="jumpAdvertisingGroup">计划</el-button>
                          <el-button class="solid-tag-harmful" plain size="mini" type="text" @click="ssList">启停</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- 时间段弹框 -->
                    <el-dialog title="" :visible.sync="dialogTableVisible" width="100%">
                        <el-form :inline="true" :model="timeForm" ref="timeForm" class="demo-form-inline">
                            <el-form-item label="" prop="tan1">
                                <el-select v-model="timeForm.tan1" placeholder="请选择执行状态" filterable clearable>
                                    <el-option
                                      v-for="(item,i) in implementOptions"
                                      :key="i"
                                      :label="item.name"
                                      :value="item.id">
                                    </el-option>
                                  </el-select>
                            </el-form-item>
                            <el-form-item label="" prop="tan2">
                                <el-date-picker
                                    v-model="timeForm.tan2"
                                    type="daterange"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="" prop="tan5">
                              <el-select v-model="timeForm.tan5" placeholder="请选择调价状态" filterable clearable>
                                <el-option
                                  v-for="(item,i) in tan5Options"
                                  :key="i"
                                  :label="item.name"
                                  :value="item.id">
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item>
                              <el-button type="primary" class="newQuery" size="small" @click="getTimeSlotDetail">查询</el-button>
                              <el-button type="info" size="small" @click="resetForm('timeForm')">重置</el-button>
                            </el-form-item>
                        </el-form> 
                        <el-table :data="gridData" ref="multipleTable">
                          <el-table-column
                            type="selection"
                            width="50">
                          </el-table-column>
                          <el-table-column
                            property="priceDate"
                            label="调价日期"
                            width="200">
                            <template slot-scope="scope">
                              {{ scope.row.startTime | dateFormat }}至{{ scope.row.endTime | dateFormat }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="priceDateSlot"
                            label="调价时间段"
                            width="150">
                            <template slot-scope="scope">
                              <div v-for="(time,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
                                <span>{{time.executeHourt}}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="priceStatus"
                            label="当天是否继续执行"
                            width="150">
                            <template slot-scope="scope">
                              <div v-for="(item,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
                                <span v-if="item.status == 0" style="display: block;">执行</span>
                                <span v-if="item.status == 1" style="display: block;">不执行</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="priceStatus"
                            label="目标金额"
                            width="100">
                            <template slot-scope="scope">
                              <div v-for="(item,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
                                <span style="display: block;">{{item.newPrice/100}}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="priceStatus"
                            label="调价状态"
                            width="150">
                            <template slot-scope="scope">
                              <span v-if="scope.row.upPriceStatus == 0">开启</span>
                              <span v-if="scope.row.upPriceStatus == 1">关闭</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="implementStatus"
                            label="执行状态"
                            width="150">
                            <template slot-scope="scope">
                              <span v-if="scope.row.executionStatus == 10">待执行</span>
                              <span v-if="scope.row.executionStatus == 20">执行中</span>
                              <span v-if="scope.row.executionStatus == 30">已执行</span>
                              <span v-if="scope.row.executionStatus == 40">异常</span>
                              <span v-if="scope.row.executionStatus == 50">弃用</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="operation"
                            label="操作">
                            <template slot-scope="scope">
                              <div class="opBtn">
                                <span v-if="scope.row.executionStatus == 10">
                                  <!-- <el-button class="newBtn_Yellow" size="mini" plain @click="editPrice(scope.row)">修改</el-button> -->
                                </span>
                                <span v-else></span>
                              </div>
                              <div class="opBtn">
                                <span v-if="scope.row.executionStatus == 10||scope.row.executionStatus == 20 ">
                                  <el-popconfirm
                                  title="确定要弃用吗？"
                                  @confirm="stopUse(scope.row.id)">
                                  <template #reference>
                                    <el-button class="newBtn_garyWhite" plain size="mini" style="margin-left:10px;">弃用</el-button>
                                  </template>
                                </el-popconfirm>
                                </span>
                                <span v-else></span>
                              </div>
                              <div class="opBtn">
                                <span  v-if="scope.row.executionStatus == 50">
                                  <el-popconfirm
                                    title="您是否确认删除，删除后不可恢复？"
                                    @confirm="delUse(scope.row.id)">
                                    <template #reference>
                                      <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                                    </template>
                                  </el-popconfirm>
                                </span>
                              </div>
                            </template>
                          </el-table-column>
                      </el-table>
                      <div class="pageWrap">
                        <div class="pageBatch">
                          <el-button @click="allDelete" size="small">批量删除</el-button>
                        </div>
                        <el-dialog
                          title=""
                          :visible.sync="dialogVisible"
                          append-to-body
                          width="30%"
                          :modal-append-to-body='false'>
                          <span>确定要删除吗</span>
                          <span slot="footer" class="dialog-footer">
                            <el-button @click="delCancel">取 消</el-button>
                            <el-button type="primary" @click="delSure">确 定</el-button>
                          </span>
                        </el-dialog>
                        <div class="paging">
                          <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNumdetail"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSizedetail"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="this.totalRowdetail">
                          </el-pagination>
                        </div>
                      </div>
                    </el-dialog>
                    <!-- 时间段弹框结束 -->
                </div>
                <div class="pageWrap">
                    <div class="pageBatch"></div>
                    <div class="paging">
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.totalRow">
                      </el-pagination>
                    </div>
                </div>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'priceModify',
    data() {
      return {
        userId:'',
        userName:'',
        deptId:'',
        showTheForm:true,//表头是否显示全部表单
        priceForm:{
            planName:'',//搜索计划名称
            adGroup:'',//搜索广告组名称
            adGroupStatus:''//广告组状态    
        },
        adStatusOptions:[{
            name:'全部',
            id:1
        },{
            name:'启动中',
            id:2
        },{
            name:'暂停中',
            id:3
        },{
            name:'不在推广时段',
            id:4
        },{
            name:'计划暂停',
            id:5
        },{
            name:'广告组暂停',
            id:6
        }],
        tableData: [],
        pmParams:{
          currentPage:'',
          pageSize:'',
          ownerId:'',
          adGroupName:'',
          planName:'',
          planType:''
        },
        // 时间段分页
        pageNumdetail: 1,//当前页
        pageSizedetail: 10,//每页大小
        totalRowdetail: 0,//总条数
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dialogTableVisible:false,//时间段弹框
        gridData: [],//时间段表格数据
        timeForm:{
          tan1:'',
          tan2:'',
          tan3:'',
          tan4:'',
          tan5:'',
          tan6:'',
          tan7:'',
          tan8:'',
          tan9:''
        },//时间段弹框查询form
        tan9:false,
        implementStatus:'',//时间段弹框执行状态
        implementOptions:[{//时间段弹框执行状态选项
            name:'全部',
            id:''
        },{
            name:'待执行',
            id:10
        },{
            name:'执行中',
            id:20
        },{
            name:'已执行',
            id:30
        },{
            name:'异常',
            id:40
        },{
            name:'弃用',
            id:50
        }],
        tan3Options:[//子列表弹框中的选择数值
          {
            name:'>=',
            id:'1'
          },{
            name:'<=',
            id:'2'
          },{
            name:'><',
            id:'3'
          },
        ],
        tan5Options:[//子列表弹框中的调价状态
          {
            name:'开启中',
            id:'0'
          },{
            name:'关闭中',
            id:'1'
          }
        ],
        tan6Options:[//子列表弹框中的出价类型
          {
            name:'CPC',
            id:'1'
          }
          // },{
          //   name:'CPD',
          //   id:'2'
          // },{
          //   name:'OCPC',
          //   id:'5'
          // }
        ],
        tan7Options:[//子列表弹框中的调价类型
          {
            name:'具体值',
            id:'1'
          },{
            name:'百分比',
            id:'2'
          }
        ],
        tan8Options:[//子列表弹框中的调价方式
          {
            name:'上调',
            id:'1'
          },{
            name:'下调',
            id:'2'
          },{
            name:'自定义',
            id:'3'
          }
        ],
        dateChoice:'',
        batchOperation:'',
        timeSlotParams:{//时间段详情列表入参
          // adGroupId:'',//列表中的广告组id
          planId:'',//计划列表传planId
          id:'',//每一条的id
          currentPage:'',
          pageSize:'',
          userId:'',
          userName:'',
          executionStatus:'',
          stime:'',
          etime:'',
          mathType:'',
          mathStart:'',
          mathEnd:'',
          upPriceStatus:'',
          priceType:'',
          upPriceType:'',
          upPriceMode:'',
          planType:''
        },
        delSlotParams:[],//删除子列表--单个   
        giveUpParams:{//弃用子列表
          deptId:'',
          userId:'',
          userName:'',
          id:''
        },
        dialogVisible:false,
        changeNumber:'',//子列表中的切换数值类型
        ownerId:'',//上一级获取的ownerId
        ownerName:'',//上一级获取的ownerName


      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.ownerName = this.$route.query.name
      this.priceModifyList()
    },
    methods: {
      //表头点击向右三角显示
      showList(){
        this.showTheForm = false;
      },
      //表头点击向上三角隐藏
      hiddenList(){
        this.showTheForm = true;
      },

      //跳转到账户调价列表
      priceModifyPlan(){
        this.$router.push({name:'planManagement.PriceModify',query:{id:this.ownerId,name:this.ownerName}})
      },
      //跳转到广告组调价列表
      priceModifyGroup(){
        this.$router.push({name:'planManagement.PriceModifyGroup',query:{id:this.ownerId,name:this.ownerName}})
      },
      //跳转到出价调价列表
      priceModifyGroupPrice(){
        this.$router.push({name:'planManagement.PriceModifyGroupPrice',query:{id:this.ownerId,name:this.ownerName}})
      },

      //头部表单重置
      // resetForm(priceForm) {
      //   this.$refs[priceForm].resetFields();
      //   this.$router.go(0)
      // },
      //头部表单重置
      resetForm(timeForm) {
        this.$refs[timeForm].resetFields();
        // this.getTimeSlotDetail()
        this.$router.go(0)
      },

      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
            return (restaurant.planName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            return (restaurant.adGroupName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      handleSelect(item) {
        console.log(item);
      },

      //点击广告组跳转页面
      jumpAdvertisingGroup(){
        this.$router.push({name:'planManagement.AdvertisingPlan',query:{id:this.ownerId,name:this.ownerName}})
      },

      //点击启停跳转页面
      ssList(){
        this.$router.push({name:'planManagement.StartStop',query:{id:this.ownerId,name:this.ownerName}})
      },

      //点击添加按钮跳转到调价设置页面
      addPriceList(row){
        this.$router.push({name:'planManagement.SetPrice',query:{id:this.ownerId,name:this.ownerName,planId:row.planId,planName:row.planName,planType:row.planType,oldPrice:row.oldPrice}})
      },

      //点击修改按钮跳转到调价修改页面
      editPrice(row){
        console.log(row)
        // this.$router.push('/plan-management/EditPrice')
        // this.$router.push({name:'planManagement.EditPrice',query:{planId:row.planId,planName:row.planName,adGroupId:row.adGroupId,adGroupName:row.adGroupName}})

      },

      //广告组调价展示列表
      priceModifyList(){
        let that = this
        that.pmParams.currentPage = that.pageNum
        that.pmParams.pageSize = that.pageSize
        that.pmParams.ownerId = that.ownerId
        that.pmParams.adGroupName = that.priceForm.adGroup
        that.pmParams.planName = that.priceForm.planName
        that.pmParams.planType = 20

        $http.axios.post("/api/advertisement/adGroup/v1/getGroupModifyPriceList",that.pmParams).then(res=> {
        // $http.axios.post("/aji/adGroup/v1/getGroupModifyPriceList",that.pmParams).then(res=> {
          if(res.data.code == 200){
            that.tableData = res.data.content.data
            that.totalRow = res.data.content.total
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //点击时间段
      timeSlot(row){
        console.log(row)
        this.timeSlotParams.planId = row.planId
        // this.timeSlotParams.id = row.id
        this.dialogTableVisible = true
        this.getTimeSlotDetail()
      },

      //子列表中选择数值
      changeNum(event){
        let that = this
        that.timeSlotParams.mathType = Number(event)
        that.changeNumber = event
        if(event == 3){
          that.tan9 = true
          // that.timeSlotParams.mathStart = that.timeForm.tan4
          // that.timeSlotParams.mathEnd = that.timeForm.tan9
        }else{
          that.tan9 = false
          that.timeSlotParams.mathEnd = ''
          // that.timeSlotParams.mathStart = that.timeForm.tan4
        }
      },

      //子列表中的输入数值
      changeStart(){
        let that = this
        if(that.changeNumber == 3){
          that.timeSlotParams.mathStart = Number(that.timeForm.tan4)
          that.timeSlotParams.mathEnd = Number(that.timeForm.tan9)
        }else{
          that.timeSlotParams.mathStart = Number(that.timeForm.tan4)
          
        }
      },
      // changeEnd(){
      //   if(that.changeNumber == 3){
      //     that.timeSlotParams.mathStart = that.timeForm.tan4
      //   }
      // },

      //获取时间段的详情列表
      getTimeSlotDetail(){
        let that = this
        that.timeSlotParams.currentPage = that.pageNumdetail
        that.timeSlotParams.pageSize = that.pageSizedetail
        that.timeSlotParams.userId = that.userId
        that.timeSlotParams.userName = that.userName
        that.timeSlotParams.planType = 20

        that.timeSlotParams.executionStatus = that.timeForm.tan1
        that.timeSlotParams.stime = that.timeForm.tan2[0]
        that.timeSlotParams.etime = that.timeForm.tan2[1]
        that.timeSlotParams.upPriceStatus = that.timeForm.tan5
        that.timeSlotParams.priceType = that.timeForm.tan6
        that.timeSlotParams.upPriceType = that.timeForm.tan7
        that.timeSlotParams.upPriceMode = that.timeForm.tan8


        $http.axios.post("/api/advertisement/adGroup/v1/getGroupModifyPriceSetDetailList",that.timeSlotParams).then(res=> {
        // $http.axios.post("/aji/adGroup/v1/getGroupModifyPriceSetDetailList",that.timeSlotParams).then(res=> {
          if(res.data.code == 200){
            that.gridData = res.data.content.data
            that.totalRowdetail = res.data.content.total
            that.priceModifyList()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //弃用子列表
      stopUse(id){
        let that = this
        that.giveUpParams.deptId = that.deptId
        that.giveUpParams.userId = that.userId
        that.giveUpParams.userName = that.userName
        that.giveUpParams.id = id
        $http.axios.post("/api/advertisement/adGroup/v1/discardGroupModifyPriceSet",that.giveUpParams).then(res=> {
          if(res.data.code == 200){
            that.getTimeSlotDetail()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //删除子列表
      delUse(id){
        let that = this
        that.delSlotParams = []
        that.delSlotParams.push(id)
        $http.axios.post("/api/advertisement/adGroup/v1/deleteGroupModifyPriceSet",that.delSlotParams).then(res=> {
          if(res.data.code == 200){
            that.getTimeSlotDetail()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //选中的选项
      chooseTableFn(visible){
        let that = this
        let sendttSelection = that.$refs.multipleTable.selection
        that.delSlotParams = []
        sendttSelection.forEach(function(e,i){
          that.delSlotParams.push(e.id)
        })
        if(sendttSelection.length == 0){
          that.$message({
            message: '请勾选要删除的模板',
            type: 'warning'
          });
          that[visible]=false
        }else{
          that[visible]=true
        }
      },

      //批量删除
      allDelete(){
        this.chooseTableFn('dialogVisible')
      },

      //取消删除
      delCancel(){
        this.dialogVisible = false
        // this.$router.go(0)
      },

      //确定全部删除
      delSure(){
        let that = this 
        $http.axios.post("/api/advertisement/adGroup/v1/deleteGroupModifyPriceSet",that.delSlotParams).then(res=> {
          if(res.data.code == 200){
            that.getTimeSlotDetail()
            that.dialogVisible = false
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },




      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        //获取数据接口（调用封装的接口）
        // this.getMaterialList()
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .priceTimes{
      color:cornflowerblue;
      cursor: pointer;
    }
    .pageWrap{
      clear:both;
      margin-top:15px;
      font-size:0;
    }
    .pageBatch,.paging{
        display:inline-block;
        vertical-align:middle;
        width:50%;
        font-size:12px;
    }
    .pageBatch{
      /* float:left; */
    }
    .paging{
      text-align:right;
    }
    .showTheForm,.hideenTheForm{
    display:inline-block;
    vertical-align:top;
  }
  .showTheForm{
    position:relative;
  }
  .showTheForm i{
    position:absolute;
    bottom:-4px;
    left:50%;
    margin-left:-11px;
    font-size:22px;
    color:rgb(36, 132, 168);
    cursor:pointer;
  }
  .hideenTheForm i{
    font-size:22px;
    vertical-align:middle;
    color:rgb(36, 132, 168);
    cursor:pointer;
    margin:0 20px;
  }
  .sonList{
    display:inline-block;
    vertical-align:middle;
    margin-right:10px;
  }
  /* 新版 */
  .modifyTab{
    padding:0;
    margin:0;
    font-size:0;
    margin-bottom:20px;
    margin-top:20px;
    border-bottom:1px solid #F5F7F6;
  }
  .modifyTab li {
    display:inline-block;
    vertical-align:middle;
    /* width:80px; */
    font-size:14px;
    text-align:center;
    cursor: pointer;
    background:#F5F7F6;
    color:#2F674D;
    padding:3px 30px;
    border-radius: 8px 8px 0px 0px;
    margin-right:10px;
    font-weight:500;
  }
  .modifyTab .modifyActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .channelFlowHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  </style>
