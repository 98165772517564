/**
 * 广播
 * 向下查找子集
 * @param {*} componentName 组件名
 * @param {*} eventName 事件名
 * @param {*} params 参数
 */
function broadcast(componentName, eventName, params) {
  // 开始遍历子组件
  this.$children.forEach(child => {
    const name = child.$options.componentName || child.$options.name;

    // 匹配子组件的name
    if (name === componentName) {
      // 匹配到时，子组件触发对应的事件
      // 匹配到一个就over了，该子元素的子元素就忽略了
      /* eslint-disable prefer-spread */
      child.$emit.apply(child, [eventName].concat(params));
    } else {
      // 继续查找该子组件的子元素
      /* eslint-disable prefer-spread */
      broadcast.apply(child, [componentName, eventName].concat([params]));
    }
  });
}

/**
 * element ui事件调度组件
 * 用于触发父级/子集节点的事件函数（vue method）
 */
/* eslint-disable import/no-default-export */
export default {
  methods: {
    /**
     * 调度
     * 向上查找父级
     * @param {*} componentName 组件名
     * @param {*} eventName 事件名
     * @param {*} params 参数
     */
    dispatch(componentName, eventName, params) {
      let parent = this.$parent || this.$root;
      let name = parent.$options.componentName || parent.$options.name;

      // 遍历父组件，查找并匹配name，直到找到对应的父组件或无
      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;

        if (parent) {
          // 匹配到，则修改name，用于判断并推出循环
          name = parent.$options.componentName || parent.$options.name;
        }
      }
      if (parent) {
        // 有对应的父组件，则去触发对应的事件
        parent.$emit.apply(parent, [eventName].concat(params));
      }
    },
    /**
     * 广播
     * 向下查找子集
     * @param {*} componentName 组件名
     * @param {*} eventName 事件名
     * @param {*} params 参数
     */
    broadcast(componentName, eventName, params) {
      // 调用broadcast函数，需要纠正this，因为broadcast函数的this并不指向调用者
      broadcast.call(this, componentName, eventName, params);
    },
    /**
     * 获取父节点
     * @param {*} name 名称
     */
    getParent(name) {
      let parent = this.$parent;

      while (parent) {
        if (parent.$options.name !== name) {
          parent = parent.$parent;
        } else {
          return parent;
        }
      }
      return parent;
    }
  }
};
