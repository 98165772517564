<template>
    <el-dialog
      :visible.sync="show"
      width="32.5%"
      top="15%"
      :title="titleMsg"
      :close-on-click-modal="false"
      class="acctount-detail-dialog"
    >
      <!-- <div slot="title" class="header-box">
        <span>Reminder</span>
      </div>-->
      <div>{{contentMsg}}</div>
      <div id="uploadBtn">
        <el-upload
          class="upload-demo"
          ref="upload"
          :headers="myHeaders"
          action="/api/advertisement/adFile/uploadAdFile"
          accept=".xlsx"
          :limit='1'
          :data = fileList
          :on-success= "onSuccess"
          :show-file-list='false'>
          <el-button slot="trigger" size="small" type="primary">批量上传</el-button>
        </el-upload>
      </div>
      <div slot="footer">
        <el-button type @click="show=false" class="marginR20">No</el-button>
        <el-button type="primary" @click="submit">YES</el-button>
      </div>
    </el-dialog>
</template>
<script>
import config from '@/api/config';
import { $http } from '@/api/http';
export default {
  name: 'applyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    content: String,
    ownerId:String
  },
  data() {
    return {
      show: this.visible,
      titleMsg: this.title,
      contentMsg: this.content,
      myHeaders: {Authorization:''},
      fileList:{},
      operaType:10,
    }
  },
  mounted(){
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
  },
  methods: {
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
      console.log(this.uploadId)
    },
//文件上传成功时的钩子
      onSuccess(response, file, fileList, i) {
        let that = this,dataSource =[],keywords = []
        // let video = []
        console.log(response)
        if(response.code==200){
          that.v3groupListFn()
        
        }else{
          that.$message({
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }
        this.$refs.upload.clearFiles()
      },
      submit() {
      this.$emit('confrom');
    }
  }
}
</script>

