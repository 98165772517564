<template>
  <div class="hbImg">
    <ul class="sucai clearfix">
      <li>
        <div><i>*</i><span>图片</span></div>
        <div>
          <div class="imgBox" v-for="(item,i) in childrenVue.sc.imgList" :key="i">
            <img class = "Img" :src="item" alt="">
            <div class="addIcon">+</div>
            <div>点击或拖拽素材</div>
            <!-- <div class="sck">素材库选择</div> -->
            <div>
              <div>图片尺寸 320 x 210</div>
              <div>图片格式 jpg/png/jpeg</div>
              <div>图片大小 &lt; 100 KB</div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div><i>*</i><span>品牌 Logo</span></div>
        <div class="imgBox">
          <img class = "Img" :src="childrenVue.sc.logoUrl" alt="">
          <div class="addIcon">+</div>
          <div>点击或拖拽素材</div>
        </div>
        <div>
          <div>图片尺寸 200 x 200</div>
          <div>图片格式 jpg/png/jpeg</div>
          <div>图片大小 &lt;100 KB</div>
      </div>
      </li>
    </ul>
    <div>
      <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-switch
          style="display: block"
          v-model="value2"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="不开启">
        </el-switch>
         <el-form-item label="文案" prop="region">
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="ruleForm.region"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect">
            <i
              class="el-icon-edit el-input__icon"
              slot="suffix"
              @change="handleIconClick">
            </i>
            <template slot-scope="{ item }">
              <div class="name">{{ item.value }}</div>
              <span class="addr">{{ item.address }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="品牌名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入 1 - 8 字的品牌名称"></el-input>
        </el-form-item>
      </el-form> -->
    </div>
  </div>
</template>
<script>
import config from '@/api/config';
import { $http } from '@/api/http';
import imgDialog from "@/views/dialog/imgDialog";//广告创意
export default {
   
  props: {
    specId:Number,
    childrenVue:Object,
  },
  data() {
      return {
        value2:true,
        imgDialogShow:false,
        ruleForm: {
          name: '',
          region: '',
        },
        rules: {
          name: [
            { required: true, message: '品牌名称不能为空', trigger: 'blur' },
            { min: 1, max: 18, message: '长度在 1 到 8 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '文案不能为空', trigger: 'change' }
          ],
          sucai:{},
          restaurants: [],
          num:this.childrenVue.num,//第几个创意
        },
      };
    },
    mounted(){
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.ownerId = Number(this.$route.query.ownerId)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      console.log('H')
      this.restaurants = this.loadAll();

    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      loadAll() {
        return [
          { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
          { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
          { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
        ];
      },
      handleSelect(item) {
        console.log(item);
      },
      handleIconClick(ev) {
        console.log(ev);
      }    
    }
}
</script>
<style lang="scss" scoped>
.hbImg{
    width: 950px;
    background-color: #f7f8fb;
    padding: 15px 0;
    border-radius: 12px;}
.hbImg li{
  float: left;
}
.imgBox{
  width: 198px;
  height: 120px;
  font-size: 14px;
  float:left;
  padding: 5px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #999;
  border: 1px dashed #E5E5E5;
  background-color: #fff;
  margin: 8px 8px 8px 0;  position: relative;
}
.addIcon{
  font-size: 30px;
}
.sck{
    position: absolute;
    border-radius: 8px;
    cursor: pointer;
    width: 180px;
    height: 28px;
    bottom: 20px;
    left: 9px;
    line-height: 28px;
    background-color: #EEF0F7;
    text-align: center;
}
.sck:hover{
  color:#2fc29b;
}
.sck.twoBtn{
  width: 85px;
}
.sck.twoBtn.rightPart{left: auto;
  right: 9px;
  cursor: default;
  color: #bbb;}
.Img{
    max-width: 200px;
    max-height: 110px;
    position: absolute;
}
</style>