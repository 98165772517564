<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item  :to="{ path: '/auth-management/demandOrder' }">运营管理</el-breadcrumb-item>
        <el-breadcrumb-item  :to="{ path: '/auth-management/demandOrder' }">需求工单</el-breadcrumb-item>
        <el-breadcrumb-item>上传素材</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
          <div class="setupOrderForm" v-for="(item,index) in courseInfo.daysArray" :key=index>
            <el-form ref="setupOrderform" :rules="rules" :model="setupOrderform" label-width="80px">
              <div v-for="(item,i) in uploadParams" :key="i" style="margin-bottom:30px;" ref="uploadRef">
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="需求工单">
                    <el-input  :placeholder="workOrderName" :disabled="true"></el-input>
                  </el-form-item>
                </div>
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="素材标签">
                    <el-select v-model="item.materialTagIds" filterable multiple :placeholder="materialTab" @change="selectContract($event,i)">
                      <el-option
                        v-for="(item,i) in tfMaterialTabOptions"
                        :key="i"
                        :label="item.tagName"
                        :value="item.id">
                        {{item.tagName}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="制作人员">
                    <el-select v-model="item.directorUserId" filterable placeholder="编导人员" @change="bdchange($event,i)">
                      <el-option
                        v-for="(item,i) in demandNameOptionsbd"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                        {{item.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="item.photographyUserId" filterable placeholder="摄影人员" @change="changesy($event,i)">
                      <el-option
                        v-for="(item,i) in demandNameOptionssy"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                        {{item.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="item.clipUserId" filterable placeholder="剪辑人员" @change="changejj($event,i)">
                      <el-option
                        v-for="(item,i) in demandNameOptionsjj"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                        {{item.name}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="主题文案">
                  <el-input v-model="item.mainTitile" placeholder="请输入文案" style="width:300px;"></el-input>
                </el-form-item>              
                <el-form-item label="素材内容" :prop="item.propFile">
                  <el-upload 
                    class="upload-demo" 
                    ref="upload" 
                    action="/api/operationManagement/material/uploadfile" 
                    :on-preview="handlePreview" 
                    :on-remove="(file, fileList)=>{return handleRemove(file, fileList, i)}" 
                    :file-list="fileList" 
                    list-type="text" 
                    :headers="myHeaders"
                    :limit="10" 
                    :on-success="(response, file, fileList)=>{return onSuccess1(response, file, fileList, i)}"
                    :on-error="onError"
                    style="margin-left:120px;">
                    <el-button size="small" type="primary">点击上传视频</el-button>
                  </el-upload>
                  <el-dialog
                      title=""
                      :visible.sync="VideoVisible"
                      width="20%">
                        <video v-if="Video !='' " :src="Video" controlsList="nodownload" class="avatar" autoplay="autoplay" controls="controls">您的浏览器不支持视频播放</video>
                    </el-dialog>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" v-if="i>0" @click="removeRow(i)">删除素材</el-button>
                </el-form-item>
              </div>
              <el-form-item>
                <el-button type="primary" size="small" @click="submitUpload" v-show="uploaded">保存</el-button>
                <el-button type="info" plain size="small" @click="cancel" v-if="closeTip==''">取消</el-button>
                <el-button type="info" plain size="small" @click="cancelTip" v-else>取消</el-button>
              </el-form-item>
            </el-form>
            <el-dialog
              title=""
              :visible.sync="tipdialogVisible"
              width="30%">
              <span><i style="font-style:normal;color:red;font-weight:700;font-size:16px;">注意：</i>如果已经上传视频，点击【取消】后再次上传同一视频会报错，请谨慎操作，如果误传，请联系管理员。</span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tipdialogVisible = false">关闭弹框</el-button>
              </span>
            </el-dialog>
            <!-- 上传视频有重名的时候弹出的弹框 -->
            <el-dialog
              title=""
              :visible.sync="duplicateVisible"
              width="30%">
              <div class="duplicateName">
                <p>{{duplicateDate}}</p>
                <p>请删除已存在的素材，请重新上传！</p>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="duplicateVisible = false">关闭</el-button>
              </span>
            </el-dialog>
            <el-button type="primary" size="small" class="changeMaterial addMaterial" @click="addMaterialList(num)">新增素材</el-button>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
  </div>
</template>
 
<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
 
export default {
  name: 'uploadMaterial',
  data() {
    return {
      courseInfo:{daysArray:[{}]},
      tipdialogVisible:false,
      setupOrderform: {
        channelName:'',
        workOrderType:'',
        workOrderName:'',
        orderDate:'',
        demandDate:[],
        demandName:'',
        accountName:'',
        resourcesType:'',
        materialDesc:'',
        resourcesPosition:[],
        materialTab:'',
        materialLink:'',
        materialType:'',
        bdName:'',
        syName:'',
        jjName:'',
        workOrderName1:'',
        materialTab1:'',
        bdName1:'',
        file:'',
      },
      rules: {
        file0: [{required: true, message:'请重新上传视频', trigger: 'change'}]
      },
      uploaded:false,//是否显示保存按钮，默认不显示
      workOrderName:'',
      materialTab:'',
      channelOptions:[{//渠道名称
        value: '1',
        label: 'OPPO'
      }, {
        value: '2',
        label: '今日头条'
      }, {
        value: '3',
        label: '快手'
      }],
      workOrderTypeOptions:[{//工单类型
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: '今日头条'
      }, {
        value: '选项3',
        label: '快手'
      }],
      demandOptions:[{//需求日期
        value: '选项1',
        label: '周一'
      }, {
        value: '选项2',
        label: '周二'
      }, {
        value: '选项3',
        label: '周三'
      }, {
        value: '选项4',
        label: '周四'
      }, {
        value: '选项5',
        label: '周五'
      }, {
        value: '选项6',
        label: '周六'
      }, {
        value: '选项7',
        label: '周日'
      }],
      demandNameOptionsbd:[],
      demandNameOptionssy:[],
      demandNameOptionsjj:[],
      accountOptions:[{//账户名称
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: '今日头条'
      }, {
        value: '选项3',
        label: '快手'
      }],
      resourcesOptions:[{//资源类型
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: '今日头条'
      }, {
        value: '选项3',
        label: '快手'
      }],
      resourcesPoptions:[{//素材样式
        label:'banner0 1080*171 <30KB'
      },{
        label:'banner1 1080*171 <30KB'
      },{
        label:'banner2 1080*171 <30KB'
      },{
        label:'banner3 1080*171 <30KB'
      }],
      resourcesNum:'',//素材样式-数量
      resourcesTab:'',//素材样式-标签
      uploadParams:[
        {
          accountId:'',//账号id
          // copywriting:'',//描述，可不传
          channelId:',',//渠道id
          clipUserId:'',//剪辑人id//
          clipUserName:'',//剪辑人name//
          detailId:'',//子工单id
          directorUserId:'',//编导id//
          directorUserName:'',//编导name//
          mainTitile:'',//主标题//
          makerUserId:'',//制作人id//
          makerUserName:'',//制作人name//
          materialTagIds:[],//素材标签id//
          materialTagNames:[],//素材标签name//
          photographyUserId:'',//
          photographyUserName:'',//
          materialDtoUrlDtos:[
            // {
            //   videoUrl:''//2视频url//
            // }
          ],
          userId:'',//登录者id
          username:'',//登录者用户名
          type:'',//回显接口中的，1图文2视频
          propFile:'file0'//区别文件
        }
      ],
      uploadParamsDto:{
        uploadDto:[
          {
            scbq:'',
            sclj:'',
            bdry:'',
            syry:'',
            jjry:'',
          }
        ]
      },
      tagListParams:{//标签列表参数
        tagType:'2',
        userId:'',
        channel:''
      },
      tfMaterialTabOptions:[],
      hxParmas:{
        id:''
      },
      hxData:{},
      fileList:[],
      myHeaders: {Authorization:''},
      upParams:[],
      editerArr:[],
      value1:[],
      materialAccountId:'',
      materialChannel:'',
      uploadId:'',
      closeTip:'',
      mmId:[],
      mmName:[],
      num:1,
      videoList:[],//视频地址列表——>判断视频是否都已上传
      Video:'',//上传视频地址
      VideoVisible:false,//视频框是否显示
      duplicateVisible:false,//有本次重名的上传视频弹框
      duplicateDate:'',//有本次重名的上传视频提示信息
      duplicateDate2:[],//服务器上传视频提示信息
      one:false,
      two:false,
 
 
      
 
    }
  },
  computed: {
     
  },
  mounted() {
    this.getId()
    this.getuserChannel()
    // this.getChannelList()
    // this.getTagList()
    this.getStaffDetail()
    this.gethx()
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
 
    getuserChannel(){
      let that = this
      let userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
      $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
        if(response.data.data.length != 0){
          that.tagListParams.channel = response.data.data[0].id
          that.getTagList()
        }
      })
    },
 
    //上传水印
    //文件列表移除文件时的钩子
    handleRemove(file, fileList,i) {
      console.log(file)
      console.log(fileList)
      // this.$refs.setupOrderform[0].resetFields()
      this.$refs.setupOrderform[0].clearValidate('file'+i);
      console.log(this.$refs.setupOrderform[0])
      if(fileList.length==0){
        this.videoList.splice(i, 1);
        this.uploadParams[i].materialDtoUrlDtos = [];
      }
      this.saveIsShow()
    },
    //点击已上传的文件链接时的钩子,获取服务器返回的数据
    handlePreview(file) {
      let that = this
      if(file.response.data){
        that.Video = file.response.data
        setTimeout(function(){
          that.VideoVisible = true
        },1000)
      }
    },
 
    //文件上传成功时的钩子
    onSuccess1(response, file, fileList, i) {
      let that = this
      if(response.code==200){
        // that.Video = response.data
        if(that.uploadParams[i].materialDtoUrlDtos){
          that.uploadParams[i].materialDtoUrlDtos.push({videoUrl:response.data})
        }else{
          that.uploadParams[i]['materialDtoUrlDtos']=[{videoUrl:response.data}]
        }
        
        that.closeTip = response.data
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'success'
        })
        let List=[]
        that.videoList = []
        that.uploadParams.forEach((item,j)=>{
          if(item.materialDtoUrlDtos&&item.materialDtoUrlDtos.length){
            List.push(item.materialDtoUrlDtos)
          }
        })
        that.videoList=List
        that.saveIsShow()
      }else{
        that.$refs.setupOrderform[0].validateField(that.uploadParams[i].propFile)
        console.log(that.$refs.setupOrderform[0].validateField())
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'error'
        })
      }

      //回显或着上一页带过来的参数必填项
      this.uploadParams[i].accountId = this.materialAccountId
      this.uploadParams[i].channelId = this.materialChannel
      this.uploadParams[i].userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.uploadParams[i].username = this.$sessionStorage.getItem(config.USERNAME)
      this.uploadParams[i].type = 2
      this.uploadParams[i].detailId = this.uploadId
    },
    onError(err, file, fileList) {
      let that = this
      that.$message.error(err.msg)
    },
 
    //标签和工单回显
    gethx(){
      let that = this
      $http.axios.get("/api/operationManagement/material/touploadview?id="+that.uploadId).then(function(response) {
        if(response.data.code == 200){
          that.workOrderName = response.data.data.demandNum
          that.materialTab = response.data.data.tagName
          that.materialType = response.data.data.materialType
          that.materialAccountId = response.data.data.accountId
          that.materialChannel = response.data.data.accountChannel
          that.mmId.push(response.data.data.tagId)
          that.mmName.push(response.data.data.tagName)
          that.uploadParams[0].materialTagIds.push(response.data.data.tagId)
          that.uploadParams[0].materialTagNames.push(response.data.data.tagName)
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg || "工单异常",
            type: 'error'
          })
          that.$router.push('/auth-management/DemandOrder')
        }
      })
    },
 
    //查询的素材标签下拉框数据
    getTagList(){
      let that = this
      that.tagListParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
      $http.axios.post("/api/operationManagement/material/taglist",that.tagListParams).then(res=> {
        if(res.data.code == 200){
          that.tfMaterialTabOptions = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
 
    //获取人员信息
    getStaffDetail(){
      let that = this
      $http.axios.get("/api/system/user/optionselect").then(function(response) {
        that.demandNameOptionsbd = response.data
        that.demandNameOptionssy = response.data
        that.demandNameOptionsjj = response.data
      })
    },
 
    //没有重名后保存成功跳转页面
    submitSuccess(){
      let that = this
      $http.axios.post("/api/operationManagement/material/devupload",that.uploadParams).then(res=> {
        if(res.data.code == 200){
          console.log(res)
          that.$router.back()          
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //保存的时候先判断有没有重复的视频：分为1和数据库重复，2本次上传有重复视频，3和数据库和本次上传都有重复
    submitUpload(){
      let that = this
      $http.axios.post("/api/operationManagement/material/judgeName",that.uploadParams).then(res=> {
      // $http.axios.post("/lcl/material/judgeName",that.uploadParams).then(res=> {
        let judgeData = res.data.data
        if(res.data.code == 200){
          console.log(res)
          that.submitSuccess()
        } else {
          that.duplicateVisible = true
          that.duplicateDate = res.data.msg
        }
      })
    },
 
    //新增素材
    addMaterialList(){
      let that = this
      that.num++
      that.uploadParams.push({propFile:'file'+that.num,materialTagIds:that.mmId,materialTagNames:that.mmName})
 
      that.rules['file'+that.num]=[{required: true, message:'请重新上传视频', trigger: 'change'}]
      // that.gethx()
      that.saveIsShow()
    },
    //判断保存按钮是否显示——>根据视频是否已全部上传
    saveIsShow(){
      if(this.videoList.length==this.num){
          this.uploaded=true
        }else{
          this.uploaded=false
        }
    },
    //删除新增素材
      removeRow(index) {
        this.num--
        if (index >= 0) {
          this.uploadParams.splice(index, 1);
          this.videoList.splice(index, 1);
          delete this.rules['file'+this.num]
        }
        this.saveIsShow()
      },
 
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
      console.log(this.uploadId)
    },
 
    cancelTip(){
      this.tipdialogVisible = true
    },
 
 
 
    //取消
    cancel(){
      // this.$router.push('/auth-management/VideoMaterialList')
      this.$router.back()
    },
 
    //获取合同服务类型的name值
    selectContract(vId,i){
      // vID 是一个数组，循环获取name,根据id
      if (!vId && vId.length === 0) {
        return ;
      }//这里多选的时候获取name和单选不一样 单选是对象 多选是数组所以...
      let nameArr = [];
      let codeArr = [];
      let that = this;
      vId.forEach(element => {
        let obj = that.tfMaterialTabOptions.find((item)=>{
          return item.id === element;
        });
        if (obj) {
          nameArr.push(obj.tagName);
          codeArr.push(obj.id);   
        }
      });
      this.uploadParams[i].materialTagNames=nameArr;
    },
 
    //获取编导详情
    bdchange(vId,i){
      let obj = {};
      obj = this.demandNameOptionsbd.find((item)=>{
          return item.id === vId;//筛选出匹配数据
      });
      console.log(obj)
      this.uploadParams[i].directorUserName = obj.name
    },
 
    //获取摄影人员
    changesy(vId,i){
      let obj = {};
      obj = this.demandNameOptionssy.find((item)=>{
          return item.id === vId;//筛选出匹配数据
      });
      console.log(obj)
      this.uploadParams[i].photographyUserName = obj.name
      // this.uploadParams[i].demandNameOptionsjj = obj.name
    },
 
    //剪辑
    changejj(vId,i){
      let obj = {};
      obj = this.demandNameOptionsjj.find((item)=>{
          return item.id === vId;//筛选出匹配数据
      });
      this.uploadParams[i].clipUserName = obj.name
      this.uploadParams[i].makerUserName = obj.name
      this.uploadParams[i].makerUserId = vId
 
      // this.uploadParams[i].accountId = this.materialAccountId
      // this.uploadParams[i].channelId = this.materialChannel
      // this.uploadParams[i].userId = this.$sessionStorage.getItem(config.USERID) || 1
      // this.uploadParams[i].username = this.$sessionStorage.getItem(config.USERNAME)
      // this.uploadParams[i].type = 2
      // this.uploadParams[i].detailId = this.uploadId
    },
 
 
 
 
 
  }
}
</script>
 
<style lang="scss" scoped>
  .el-form-item{
    display:block!important;
  }
  .setupOrderForm{
    width:50%;
  }
  .resourcesResult,.resourceForm,.resourcesDelete{
    display:inline-block;
    vertical-align:middle;
    margin-right:1%;
  }
  .resourcesResult{
    width:50%;
    padding-right:5px;
    box-sizing:border-box
  }
  .resourceForm{
    width:45%;
  }
  /* .resourcesDelete{
    width:5%;
  } */
  .resourcesNum{
    width:60px;
    margin-right:5px;
  }
  .resourcesTab{
    width:100px;
  }
  .changeMaterial{
    font-size:14px;
    position:absolute;
    top:150px;
    right:50px;
    cursor:pointer;
  }
  .el-input{
    width: auto;
  }
  .el-select{
    margin: 0 5px;
  }
  .avatar{
    width: 100%;
    height:90%;
  }
  .el-dialog__wrapper .el-dialog{
    background-color: transparent;
  }
  .duplicateName p {
    margin:8px 0;
  }
  .duplicateName p span{
    margin:5px 0;
  }
  .duplicateName p span i{
    font-style:normal;
    color:red;
  }
  .redstar{
    position: relative;
  }
  .starStyle{
    position:absolute;
    color:red;
    font-size:10px;
  }
</style>