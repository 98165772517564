<template>
  <div class="page-header">
    <el-page-header @back="goBack" class="header-title" :class="{ borderN: borderN }">
      <template slot="title">
        <slot name="title"></slot>
      </template>
      <template slot="content" v-if="$slots.hasOwnProperty('content')">
        <slot name="content"></slot>
      </template>
    </el-page-header>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    // 隐藏分页,默认不隐藏
    backUrl: {
      type: String
    },
    borderN: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.backUrl ? this.$router.push(this.backUrl) : this.$router.back(-1);
    }
  }
};
</script>
<style lang="scss">
.page-header {
  .header-title {
    line-height: 30px;
    height: 30px;
  }
  .header-title.borderN .el-page-header__left::after {
    display: none;
  }
  .el-page-header__content {
    font-size: 14px;
  }
}
</style>
