<template>
  <div class="poc-table">
    <div v-if="$slots.hasOwnProperty('tip')" class="poc-table-tip">
      <slot name="tip"></slot>
    </div>
    <el-table
      ref="elTable"
      v-bind="$attrs"
      v-on="$listeners"
      :header-row-class-name="$attrs['header-row-class-name'] || 'poc-table-header-row'"
      :header-cell-style="$attrs['header-cell-style'] || cellStyle"
      :cell-style="$attrs['cell-style'] || cellStyle"
      :max-height="$attrs['max-height'] || 500"
      :highlight-current-row="$attrs['highlight-current-row'] || true"
      class="poct-table"
    >
      <template v-for="(_, name) in $slots" :slot="name">
        <slot :name="name" />
      </template>
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="props">
        <slot :name="name" v-bind="props" />
      </template>
    </el-table>

    <div v-if="!hidepage" class="poct-pagination">
      <el-pagination
        v-bind="$attrs"
        :current-page.sync="$attrs['current-page']"
        :page-sizes="$attrs['page-sizes'] || pageSizes"
        :page-size="$attrs['page-sizes'] || pageSize"
        :layout="$attrs.layout || layout"
        :total="$attrs.total"
        v-on="$listeners"
        @current-change="$listeners['page-current-change']"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PocTable',

  props: {
    // 隐藏分页,默认不隐藏
    hidepage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cellStyle: { padding: '8px 0' },
      pageSizes: [10, 20, 50, 100],
      pageSize: 10,
      layout: 'total, sizes, prev, pager, next, jumper'
    };
  },

  methods: {
    clearSelection() {
      return this.$refs.elTable.clearSelection();
    }
  }
};
</script>

<style lang="scss">
@import '../../var.scss';

.poc-table {
  width: 100%;

  .poc-table-tip {
    font-size: 12px;
    // color: #DDD;
    margin-bottom: $MP8;
  }

  .poct-table {
    width: 100%;
    // border: 1px solid $poc-border-color;
    // border-radius: $poc-border-radius;
  }

  .poct-pagination {
    width: 100%;
    // padding: $MP16 0;
    padding-top: $MP16;

    .el-pagination {
      text-align: right;

      .el-pagination__total,
      .el-pagination__sizes {
        float: left;
      }
    }
  }
}
</style>
