
<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>内容运营</el-breadcrumb-item>
        <el-breadcrumb-item>日报模板库</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <PocCardTable>
      <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class="chanelTab">
          <!-- <span class="channel_oppo channelActive">OPPO</span>
          <span class="channel_vivo" @click="clickChangeChannel">VIVO</span> -->
          <span class="channel_oppo" :class="item.id==6?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=6&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>
        <div class="daily_library">
            <span class="timesTab">时报</span>
            <span @click="goToDaily">日报</span>
        </div>
        <div class="content">
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref=""  class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-input v-model = "dayName" clearable placeholder="请输入模板名称"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "adId" 
                    clearable           
                    placeholder="请输入广告主名称查找"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "sendName" clearable placeholder="请输入接收人"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "copyName" clearable placeholder="请输入抄送人"></el-input>
                  </el-form-item>
                  <el-form-item label="报表开关" prop="">
                    <el-select clearable placeholder="请选择报表开关状态" v-model="channelm">
                      <el-option label="开" value = "1">开</el-option>
                      <el-option label="关" value = "0">关</el-option>
                    </el-select>
                  </el-form-item>
                </el-form> 
              </div>
              <div class="MBOTTOM">
                <el-button type="primary" size="small" @click = "listModelFn(page,dayName,adId,sendName,copyName,channelm)" >查询</el-button>
                <el-button type="info" size="small" plain @click = 'resetForm'>重置</el-button>
                <div class="content_head fR">
                  <div id="app">
                    <el-button type="primary" size="small" @click="updateVisible = true" v-if="userId == 427" style="float: left; margin-right:10px;">更新爱奇艺任务关系</el-button>
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :headers="myHeaders"
                      action="/api/hwReport/reportModel/uploadExcel"
                      accept=".xlsx"
                      :limit='1'
                      :on-success= "onSuccess"
                      :show-file-list='false'>
                      <el-button slot="trigger" size="small" type="primary">新建模板</el-button>
                    </el-upload>
                    <!-- <el-link :href="`${publicPath}/dayRule.html`" target='_blank' type='primary'>日报时报 系统使用说明</el-link> -->
                    <!-- <router-link tag="a" target="_blank" to="/auth-management/Faq?id=28">日报时报 系统使用说明</router-link> -->
                    <el-button type="primary" size="small" @click="goFaq" style="margin-left:10px;">日报时报 系统使用说明</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix">
              <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :cell-class-name="tableCellClassName"
                @cell-click="clickcell">
                <el-table-column
                  align='center'
                  v-for="(item,index) in titleList" :key="index"
                  :prop="item.prop"
                  :label="item.name"
                  show-overflow-tooltip>
                  <template v-if='item.prop=="sendFlag"' v-slot="scope">
                    <el-switch
                      v-model="scope.row.sendFlag"
                      class="switchStyle"
                      :active-value="1"  
                      :inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ccc"
                      @change="getBrandList(scope.row)">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='260'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button class="newBtn_Yellow" data-type = 'sendBtn' plain size="mini">重发抄送人</el-button>
                    <el-button class="newBtn_garyLightBlue" data-type = 'infoBtn' plain size="mini">详情</el-button>
                    <el-button class="newBtn_darkRed" data-type = 'xiuGai' plain size="mini">修改</el-button>
                    <el-popconfirm
                      title="您是否确认删除报表模板，删除后数据源中数据将不再累加，且无法恢复？"
                      @confirm="deletePlanFn(scope.row.id)">
                      <template #reference>
                        <el-button class="newBtn_orang" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              </div>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleSizeChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
        </div>
      </template>
    </PocCardTable>
    <aiQiYiUpdateHwDialog :visible.sync="updateVisible"></aiQiYiUpdateHwDialog>
    <dayTimeDialog :pubVue = "pubVue" :visible.sync="dayTimeDialogIs" :path="path" :type = "type" :keywords="keywords" :keywordsIs="keywordsIs" :dataSource="dataSource" :dataSourceIs="dataSourceIs" :title="title" ref="dayTime"></dayTimeDialog>
    <dayInfoDialog :visible.sync="dayInfoDialogIs"  :type = "type" :title="title" ref="dayInfo"></dayInfoDialog>
    <dayTimeXDialog :pubVue = "pubVue" :visible.sync="dayTimeXDialogIs"  :type = "type" :title="title" ref="dayTimeXDialog" :keywordsIs="keywordsIs" :dataSourceIs="dataSourceIs"></dayTimeXDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import dayTimeDialog from '@/views/dialog/dayTimeHw'
import dayTimeXDialog from '@/views/dialog/dayTimeXHwDialog'
import dayInfoDialog from '@/views/dialog/dayInfoHwDialog'
import aiQiYiUpdateHwDialog from '@/views/dialog/aiQiYiUpdateHwDialog'

export default {
  name: 'Daily',
  components:{
    dayTimeDialog,
    dayInfoDialog,
    dayTimeXDialog,
    aiQiYiUpdateHwDialog

  },
  data() {
    return {
      pubVue:this,
      fileList:[],
      dayTimeDialogIs:false,
      dayInfoDialogIs:false,
      dayTimeXDialogIs:false,
      tableData:[],
      publicPath: process.env.VUE_APP_BASENAME,
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数


      title:'时报',
      dataSource:[],
      keywords:null,
      dataSourceIs:false,
      keywordsIs:false,
      path:'',
      sizeType:null,
      type:2,
      // sendFlag:1,//发送开关 0-关 1-开
      dayName:'',
      sendName:'',
      copyName:'',
      adId:'',
      channelm:'1',//发送开关 0-关 1-开
      titleList:[
        {name:'编号',prop:'num'},
        {name:'报表开关',prop:'sendFlag'},
        {name:'模板名称',prop:'name'},
        {name:'广告主名称',prop:'accountName'},
        {name:'接收人',prop:'senderName'},
        {name:'抄送人',prop:'copySenderName'}],
      myHeaders: {Authorization:''},
      accountSelectionListShow:true,//用户是否有权限
      channelTitleList:[],//权限名称列表
      updateVisible: false,
    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptName = this.$sessionStorage.getItem(config.DEPTNAME) 
    this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    this.channelTitleList = this.$sessionStorage.getItem('channelTitleList') 
    this.getId()
    this.getuserChannel()

  },
  methods: {
    //获取用户的权限
    getuserChannel(){
      let that = this
      if(that.channelTitleList!=undefined&&that.channelTitleList!=0){
          //  that.channelTitleList = response.data.data
          //  that.channelTitleList = [
          //  {'id':3,name:'OPPO'},
          //  {'id':4,name:'VIVO'},
          //  {'id':5,name:'Mi'},
          //  {'id':6,name:'华为'}]
          that.accountSelectionListShow = true
          let flag = that.channelTitleList.every((obj=>{
           if(obj.id == 6){
             return false
           }else{
             return true
           }
          }))
           if(flag){
              let channelNameList = {3:'authManagement.Times',4:'VivoAccountManagement.TimesVivo',5:'MiAccountManagement.TimesMi'}
              that.$router.push({name:channelNameList[that.channelTitleList[0].id]})
            }else{
              that.listModelFn()
            }
          
        }else{
          that.accountSelectionListShow = false
          that.$message({
          showClose: true,
          message: "您的操作权限不足",
          type: 'error'
        })
        }

      // $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userId).then(function(response) {
        
      // })
      
    },
     //获取用户列表
    getUserList(){
      let that = this
      // let ccPeople = []
      $http.axios.get("/api/system/user/optionSelectCopyUser").then(function(response) {
        if(response.data.code == 200){
          // that.cc_options = response.data.data
          that.$sessionStorage.setItem('optionSelectCopyUser',response.data.data)
          that.$refs.dayTimeXDialog.cc_options = that.$sessionStorage.getItem('optionSelectCopyUser')
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    }, 
    //channel的tab切换
    clickChangeChannel(channelId){
      let channelNameList = {3:'authManagement.Times',4:'VivoAccountManagement.TimesVivo',5:'MiAccountManagement.TimesMi',8:'authManagement.TimesAsa'}
      this.$router.push({name:channelNameList[channelId]})
    },
    //channel的tab切换
    // clickChangeChannel(){
    //   this.$router.push({name:'VivoAccountManagement.TimesVivo'})
    // },

    goFaq(){
      let route = this.$router.resolve({ path: '/auth-management/FaqVivo', query: {  id: 28 ,channelId:6} });
      window.open(route.href, '_blank');  
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
      console.log(this.uploadId)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    // "reportId": [],
	  // "sendFlag": 0
    //批量开关
    getBrandList(row){
      let _this = this
      console.log(row)//api/hwReport
      $http.axios.get("/api/hwReport/reportModel/switchReport?reportIds="+[row.id]+"&sendFlag="+row.sendFlag).then(res=> {
        if(res.data.code == 200){
          _this.listModelFn()

        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })

    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,dataSource =[],keywords = []
      // let video = []
      console.log(response)
      if(response.code==200){
        that.dayTimeDialogIs = true
        if(response.data.dataSource.length !=0){
          that.dataSourceIs = true
        }
        if(response.data.keywords.length !=0){
          that.keywordsIs = true
        }
        response.data.dataSource.forEach((element,i) => {
          dataSource.push({'sheetName':element})
        });
        response.data.keywords.forEach((element,i) => {
          keywords.push({'sheetName':element})
        });
        that.dataSource = dataSource
        that.keywords =keywords
        that.path = response.data.path
        that.sizeType = response.data.sizeType
       
      }else{
        that.$message({
          duration:0,
          showClose: true,
          message: response.msg,
          type: 'error'
        })
      }
       this.$refs.upload.clearFiles()
    },
    tableCellClassName({row, column, rowIndex, columnIndex}){
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index=rowIndex;
      column.index=columnIndex;
    },
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      console.log(row);
      console.log(column);
      console.log(cell);
      console.log(event);
      let yjType = event.target.parentNode.dataset.type
      if(yjType == "infoBtn"){
        this.dayInfoDialogIs = true
        this.$refs.dayInfo.getInfoFn(row.id)


      }else if(yjType == "xiuGai"){
        this.dayTimeXDialogIs = true
        this.$refs.dayTimeXDialog.editFn(row.id)
        // this.getUserList()
      }else if(yjType == 'sendBtn'){
          this.pushFn(row.id,row.num)
      }
      
    }, 
   
    // tab跳转
    goToDaily(){
      this.$router.replace('/hwAccount-management/DailyHw')
    },
    
   
    // 重新发送抄送人
    pushFn(id,reportNum){
      let _this = this
      $http.axios.get("/api/hwReport/reportModel/reSendCopy?reportIds="+id).then(res=> {
      // $http.axios.get("/api/report/model/push?reportIds="+id+'&type=2').then(res=> {
        if(res.data.code == 200){
          _this.$message({
            showClose: true,
            message: reportNum+'已重新发送给抄送人，请注意查收',
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //列表 /reportModel/listModel
    listModelFn(page,dayName,adId,sendName,copyName,channelm){
      let _this = this,params = {}
      params = {'pageIndex':page||1,
      'userId':_this.userId,
      'userName':_this.userName,
      'deptId':_this.deptId,
      'pageSize':10,
      'reportName':dayName,
      'ownerName':adId,
      'status':0,
      'senderName':sendName,
      'copySenderName':copyName,
      'sendFlag':channelm||_this.channelm,
      'type':_this.type}
      $http.axios.post("/api/hwReport/reportModel/listModel",params).then(res=> {
        if(res.data.code == 200){
          _this.tableData = res.data.data.infos
          _this.totalRow = res.data.data.pageInfo.rowCount//分页总条数
          console.log(666)

        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //删除按钮
    deletePlanFn(e){
      let that = this,ids = []
      ids.push(e)
      $http.axios.get("/api/hwReport/reportModel/del?reportIds="+ids).then(res=> {
        if(res.data.code == 200){
          that.listModelFn()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //头部表单重置
    resetForm(ruleForm) {
      // this.$refs[ruleForm].resetFields();
      // this.$router.go(0)
      this.dayName=''
      this.sendName=''
      this.copyName=''
      this.adId=''
      this.channelm='1'
      this.listModelFn()
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    
    //清空自定义列弹框选中项
    clearWrap(){
      this.checkedCities = []
      this.handCheckList = []
    },
    // 分页
    handleSizeChange(val) {
      
      this.findAll(val)
    },
    
    //分页方法
    findAll (val) {
      //获取数据接口（调用封装的接口）
      this.listModelFn(val)
    },

  }
}
</script>

<style lang="scss" scoped>
  .daily_library{
    margin-bottom:20px;
  }
  .daily_library span{
    padding-right:20px;
    font-size:14px;
    cursor: pointer;
  }
  .daily_library .timesTab{
    font-size:16px;
    font-weight:700;
    color:#6e7c7c;
  }
  .MBOTTOM{
    margin: 10px 0;
  }
  .upload-demo{
    float: left;
  }
  #app>a{
    line-height: 30px;
  } 
  /* oppo和vivo的tab切换 */
  .chanelTab{
      margin-bottom:30px;
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:36px;
      line-height:36px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
</style>
