<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
                <el-breadcrumb-item >广告系列</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-dropdown @command="batchEditStatus">
                <el-button type="primary" size="small" class="newQuery">
                  修改状态<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-plus" command="1">开启</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-circle-plus" command="2">暂停</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" size="small" @click="zdyFn">自定义列</el-button>
              <el-button type="primary" size="small" @click="downLoadExcel">下载计划否定关键词模版</el-button>
              <div style="display: inline-block;vertical-align:middle;">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :http-request="batchUploadNegKw" 
                  accept=".xlsx"
                  :limit='1'
                  :show-file-list='false'>
                  <el-button type="primary" slot="trigger" size="small" >批量上传否定关键词</el-button>
                </el-upload>
              </div>
              <el-button type="primary" size="small" @click="createAdvertPlan"><i class="el-icon-plus"></i>创建广告系列</el-button>
            </div>
          </div>
          <div class="chanelTab">
            <span class="channel_oppo channelActive">广告系列</span>
            <span class="channel_vivo" @click="clickChangeChannel(2)">广告组</span>
            <span class="channel_vivo" @click="clickChangeChannel(3)">关键词</span>
            <span class="channel_vivo" @click="clickChangeChannel(4)">广告</span>
          </div>
            <div class="materialHead">

              <div class="materialHBtns clearfix queryLeft">
                <el-form :inline="true" ref="ruleForm" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-date-picker
                      v-model="dateValue"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
                      class="dateStyle">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "planName" clearable placeholder="请输入计划系列名称" @blur="blurPlanName"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "planId" 
                    clearable           
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入计划系列ID"
                    @blur="blurPlanId"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-button type="primary" class="newQuery" size="small" @click="getAccountPlanListFn">查询</el-button>
                    <el-button type="info" size="small" plian @click="resetForm">重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
              <div class="listDetail queryRight">
                <span>账户名称：<i>{{ownerName}}</i></span>
                <span>账户ID：<i>{{ownerId}}</i></span>
              </div>
            </div>
            <div class="clearfix">
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  style=" cursor:pointer;"
                  :data="tableData"
                  tooltip-effect="dark"
                  @sort-change="handlesortChange">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="metadata.campaignName"
                    label="广告系列"
                    width="220"
                    fixed>
                    <template slot-scope="scope">
                      <span class="ownerNameColor" @click="enterAdvertGroup(scope.row)">{{scope.row.metadata.campaignName}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="状态"
                    width="90"
                    fixed>
                    <template slot-scope="scope" >
                      <el-switch
                        v-model="scope.row.metadata.servingStatus"
                        active-color="#48916F"
                        inactive-color="#EAEFED"
                        active-value="RUNNING"
                        inactive-value="NOT_RUNNING"
                        @change="changeListStatus(scope.row)">
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="预警详情"
                    width="100px"
                    fixed>
                    <template slot-scope="scope" >
                      <el-button plain size="mini" class="newBtn_orang" @click="warningDetail(scope.row)">详情</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="投放位置">
                    <template slot-scope="scope">
                      <div v-for="item in scope.row.metadata.supplySources">
                        <span v-if="item == 'APPSTORE_SEARCH_RESULTS'">搜索结果</span>
                        <span v-else-if="item == 'APPSTORE_SEARCH_TAB'">搜索标签</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="状态原因"
                    center
                    :show-overflow-tooltip="true"
                    min-width="200">
                    <template slot-scope="scope" >
                      <span v-if="scope.row.metadata.servingStatus == 'NOT_RUNNING'">
                        <span v-for="val in scope.row.metadata.servingStateReasons">
                          <span v-if="val == 'AD_GROUP_MISSING'">广告系列中缺少该广告组&emsp;</span>
                          <span v-else-if="val == 'APP_NOT_CATEGORIZED'">应用没有分配的类别&emsp;</span>
                          <span v-else-if="val == 'APP_NOT_ELIGIBLE'">该应用在 App Store Connect 中不符合条件&emsp;</span>
                          <span v-else-if="val == 'APP_NOT_ELIGIBLE_SEARCHADS'">该应用不符合 Apple Search 广告的条件&emsp;</span>
                          <span v-else-if="val == 'APP_NOT_ELIGIBLE_SUPPLY_SOURCE'">应用不符合使用供应源进行促销的条件&emsp;</span>
                          <span v-else-if="val == 'APP_NOT_LINKED_TO_CAMPAIGN_GROUP'">该应用不再与与广告系列组关联的任何内容提供商相关联&emsp;</span>
                          <span v-else-if="val == 'APP_NOT_PUBLISHED_YET'">该应用尚未在应用商店中发布&emsp;</span>
                          <span v-else-if="val == 'APP_SENSITIVE_CONTENT'">由于敏感内容政策，该应用不符合条件&emsp;</span>
                          <span v-else-if="val == 'BO_START_DATE_IN_FUTURE'">预算订单开始日期为将来的日期&emsp;</span>
                          <span v-else-if="val == 'BO_END_DATE_REACHED'">预算订单结束日期是过去的日期&emsp;</span>
                          <span v-else-if="val == 'BO_EXHAUSTED'">预算订单已用尽&emsp;</span>
                          <span v-else-if="val == 'CAMPAIGN_END_DATE_REACHED'">广告系列的结束日期是过去的日期&emsp;</span>
                          <span v-else-if="val == 'CAMPAIGN_START_DATE_IN_FUTURE'">广告系列的开始日期是将来的日期&emsp;</span>
                          <span v-else-if="val == 'CONTENT_PROVIDER_UNLINKED'">应用的内容提供商未关联到广告系列组&emsp;</span>
                          <span v-else-if="val == 'CREDIT_CARD_DECLINED'">系统已拒绝信用卡收费&emsp;</span>
                          <span v-else-if="val == 'DAILY_CAP_EXHAUSTED'">广告系列的每日上限已用完&emsp;</span>
                          <span v-else-if="val == 'DELETED_BY_USER'">用户已删除广告系列&emsp;</span>
                          <span v-else-if="val == 'FEATURE_NO_LONGER_AVAILABLE'">CPT 的定价模型不可用&emsp;</span>
                          <span v-else-if="val == 'FEATURE_NOT_YET_AVAILABLE'">某项功能尚不可用&emsp;</span>
                          <span v-else-if="val == 'INELIGIBLE_BUSINESS_LOCATION'">帐号商家资料位置不符合条件&emsp;</span>
                          <span v-else-if="val == 'LOC_EXHAUSTED'">信贷额度已用尽&emsp;</span>
                          <span v-else-if="val == 'MISSING_BO_OR_INVOICING_FIELDS'">预算订单缺失或缺少字段&emsp;</span>
                          <span v-else-if="val == 'NO_AVAILABLE_AD_GROUPS'">广告系列未投放任何广告组&emsp;</span>
                          <span v-else-if="val == 'NO_ELIGIBLE_COUNTRIES'">该广告系列没有符合条件的国家/地区可供投放&emsp;</span>
                          <span v-else-if="val == 'NO_PAYMENT_METHOD_ON_FILE'">组织没有付款方式。请参阅预算顺序&emsp;</span>
                          <span v-else-if="val == 'ORG_CHARGE_BACK_DISPUTED'">该组织存在有争议的拒付&emsp;</span>
                          <span v-else-if="val == 'ORG_PAYMENT_TYPE_CHANGED'">组织的付款类型已更改&emsp;</span>
                          <span v-else-if="val == 'ORG_SUSPENDED_POLICY_VIOLATION'">组织无法投放广告系列&emsp;</span>
                          <span v-else-if="val == 'ORG_SUSPENDED_FRAUD'">组织无法投放广告系列&emsp;</span>
                          <span v-else-if="val == 'PAUSED_BY_USER'">用户已暂停广告系列&emsp;</span>
                          <span v-else-if="val == 'PAUSED_BY_SYSTEM'">系统已暂停广告系列&emsp;</span>
                          <span v-else-if="val == 'SAPIN_LAW_AGENT_UNKNOWN'">代理机构尚未通过其 Search Ads 帐号标识为代理机构&emsp;</span>
                          <span v-else-if="val == 'SAPIN_LAW_FRENCH_BIZ_UNKNOWN'">应用归总部位于法国或设有分支机构的企业所有，但尚未在其 Search Ads 帐号中自行标识为代理机构&emsp;</span>
                          <span v-else-if="val == 'SAPIN_LAW_FRENCH_BIZ'">应用归总部位于法国或设有分支机构的企业所有，并且在其 Search Ads 帐号中自称是代理机构&emsp;</span>
                          <span v-else-if="val == 'TAX_VERIFICATION_PENDING'">该组织的税务验证正在等待中&emsp;</span>
                          <span v-else-if="val == 'TOTAL_BUDGET_EXHAUSTED'">竞选预算已用尽&emsp;</span>
                          <span v-else-if="val == 'USER_REQUESTED_ACCOUNT_SUSPENSION'">用户请求暂停帐户</span> 
                        </span>
                      </span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                  </el-table-column>
                  <el-table-column
                    prop="dailyBudget"
                    label="每日预算"
                    width="120"
                    fixed
                    sortable = 'custom'>
                    <template slot-scope="scope">
                      <span v-if="scope.row.metadata.dailyBudget!=null">¥{{fun(scope.row.metadata.dailyBudget.amount)}}</span>
                      <span v-else>-</span>
                      <el-popover
                        placement="right"
                        width="70"
                        :ref="`popover-${scope.$index}`"
                        trigger="click">
                          <el-input v-model="dailyBudgetEvery" ></el-input>
                          <div class="inputBtn" style="text-align:right;">
                            <el-button class="el-icon-check" style="margin:0;" @click="handleConfirmSure(scope,1);handleConfirm(scope)"></el-button>
                            <el-button class="el-icon-close" @click="handleConfirm(scope)" style="margin:0;"></el-button>
                          </div>
                          <i class="el-icon-edit" slot="reference" style="padding-left:6px;color:#F3920B;font-weight:700;" @click="editEveryBut(scope)"></i>
                      </el-popover>
                    </template>
                  </el-table-column>
                  <template v-for="(item, index) in titleList">
                    <el-table-column
                      :key="index"
                      align="right"
                      :prop="item.prop"
                      :label="item.name"
                      width="170px"
                      sortable>
                    </el-table-column>
                  </template>
                  <el-table-column 
                    fixed = 'right'
                    align="center"
                    width='140'
                    :show-overflow-tooltip="true"
                    label="关键词">
                      <template slot-scope="scope">
                        <el-button class="newBtn_hollowgreen" plain size="mini" @click="enterAdvertkw(scope.row)">关键词</el-button>
                        <el-button class="newBtn_orang" plain size="mini" style="margin-left:2px;" @click="editAdvertPlan(scope.row)">修改</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column 
                    fixed = 'right'
                    align="center"
                    width='80'
                    :show-overflow-tooltip="true"
                    label="操作">
                      <template slot-scope="scope">
                        <el-popconfirm
                          title="您是否确认删除，删除后不可恢复？"
                          @confirm="deletePlanFn(scope.row)">
                          <template #reference>
                            <el-button class="newBtn_delRed" data-type = 'delBtn' style="margin-left:15px;" plain size="mini">删除</el-button>
                          </template>
                        </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>

                <!-- 更新 -->
                <el-dialog
                  title="更新账户"
                  :visible.sync="dialogAccountUpdate"
                  :show-close = "false"
                  width="36%">
                  <el-form :model="accountUpdateForm" ref="accountUpdateForm" :rules="accountUpdaterules">
                    <el-form-item label="状态" prop="status" :label-width="formLabelWidth1">
                      <el-switch
                        v-model="accountUpdateForm.status"
                        @change="changeStatus">
                      </el-switch>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogAccountUpdate = false">取 消</el-button>
                    <el-button type="primary" @click="accountUpdateSure">确 定</el-button>
                  </div>
                </el-dialog>

                <!-- 预警详情 -->
                <el-dialog
                  title="预警详情"
                  append-to-body
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :visible.sync="dialogWarningDetail"
                  center
                  class="warnningDialog"
                  width="820px">
                  <div class="warnTableData">
                    <div class="dialogIcon"><img :src='warningIcon'></div>
                    <div style="text-align:right;margin-bottom:15px;margin-top:-35px;">
                      <el-button type="primary" size="mini" @click="accountWarnDialog">预警设置</el-button>
                    </div>
                    <el-table
                      ref="multipleWarnTable"
                      :data="warnInfosTableData"
                      :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}"
                      tooltip-effect="dark">
                      <el-table-column
                        prop=""
                        label="观察指标和执行条件"
                        width="200"
                        fixed>
                        <template slot-scope="scope">
                          <div class="warnInfosList" v-for="(item,i) in scope.row.infos">
                            <span>
                              <i v-if="item.target == 1">余额</i>
                              <i v-else-if="item.target == 2">消耗</i>
                              <i v-else-if="item.target == 3">平均CPT</i>
                              <i v-else-if="item.target == 4">平均CPA </i>
                              <i v-else-if="item.target == 5">曝光</i>
                              <i v-else-if="item.target == 6">点击</i>
                              <i v-else-if="item.target == 7">安装</i>
                              <i v-else-if="item.target == 8">点击率</i>
                              <i v-else-if="item.target == 9">转化率</i>
                            </span>
                            <span>
                              <i v-if="item.op == 1">>=</i>
                              <i v-else-if="item.op == 2"><=</i>
                            </span>
                            <span>{{item.val}}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="operate"
                        label="操作符">
                        <template slot-scope="scope">
                          <span v-if="scope.row.operate == 1">与</span>
                          <span v-else>或</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="startTime"
                        label="开始时间">
                      </el-table-column>
                      <el-table-column
                        prop="endTime"
                        label="结束时间">
                      </el-table-column>
                      <el-table-column
                        prop="executeStrategy"
                        label="执行策略">
                        <template slot-scope="scope">
                          <span v-if="scope.row.executeStrategy == 1">无</span>
                          <span v-else-if="scope.row.executeStrategy == 2">停投</span>
                          <span v-else-if="scope.row.executeStrategy == 3">调日预算</span>
                          <span v-else-if="scope.row.executeStrategy == 4">调CPA出价</span>
                          <span v-else>调点击出价</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label="状态"
                        width="120">
                        <template slot-scope="scope">
                          <el-switch
                            v-model="scope.row.status"
                            active-color="#48916F"
                            inactive-color="#EAEFED"
                            :active-value="0"
                            :inactive-value="1"
                            @change="changeWarnStatus(scope.row)">
                          </el-switch>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="warnCount"
                        label="预警次数">
                      </el-table-column>
                      <el-table-column
                        prop="priority"
                        label="优先级">
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label="创建时间"
                        width="180">
                        <template slot-scope="scope">{{scope.row.createTime | dateTimeFormat}}</template>
                      </el-table-column>
                      <el-table-column
                        prop="updater"
                        label="更新人">
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label="更新时间"
                        width="180">
                        <template slot-scope="scope">
                          <span v-if="scope.row.updateTime == null">暂无</span>
                          <span v-else>{{scope.row.updateTime | dateTimeFormat}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label="操作"
                        width="100"
                        fixed="right">
                        <template slot-scope="scope">
                          <!-- <el-button class="newBtn_darkOrange" plain size="mini" @click="warnEdit(scope.row)">修改预警</el-button> -->
                          <el-button class="newBtn_darkOrange" size="mini" @click="warnEdit(scope.row)"><i class="el-icon-edit" style="padding-right:3px;font-weight:700;"></i>修改</el-button>

                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button class="newDialogCancel" type="primary" @click="dialogWarningDetail = false">取消保存</el-button>
                    <el-button class="newDialog" type="primary" @click="dialogWarningDetail = false">确定保存</el-button>
                  </span>
                </el-dialog>
              <!-- 预警 -->
              <el-dialog 
                title="预警设置" 
                width="600px"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center
                class="warnningForm"
                :visible.sync="dialogFormVisible">
                <div class="dialogIcon"><img :src='warningIcon'></div>
                <el-form 
                  :model="budgetRuleForm"
                  :rules="budgetRules"
                  ref="budgetRuleForm">
                  <!-- 新版 -->
                  <div>
                    <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
                      <el-form-item label="观察指标" prop="newObserveTarget" class="observationTarget" :label-width="warnWidth">
                        <el-select
                            v-model="obj.target">
                            <el-option
                              v-for="(item,i) in newObserveTargetOption"
                              :key="i"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select> 
                      </el-form-item>
                      <el-form-item label="执行条件" prop="" :label-width="warnWidth">
                        <el-select
                          v-model="obj.op"
                          style="width:120px;">
                          <el-option
                            v-for="(item,i) in newSymbolOption"
                            :key="i"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select> 
                        <el-input v-model.number="obj.val"
                          oninput="value=value.replace(/[^\d]/g,'')"
                          style="width:280px;margin-left:20px;"
                          placeholder="请输入预警数值">
                        </el-input>
                      </el-form-item>
                      <div class="deleteBtn">
                        <div v-if="j>0" size="small" @click="removeRowpl(j)">
                          <i class="deleteBtnicon el-icon-error"></i>
                        </div>
                      </div>
                    </div>
                    <div @click="changeChecked" class="addConditions">
                      <i class="el-icon-plus"></i>新增执行条件
                    </div>
                  </div>
                  <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="warnWidth">
                    <el-select
                      v-model="budgetRuleForm.warningPerson"
                      multiple
                      value-key="userId"
                      @change="changeWarning"
                      style="width:300px;">
                      <el-option
                        v-for="(item,i) in warningPersonOption"
                        :key="i"
                        :label="item.string"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="warnWidth">
                    <el-select
                      v-model="budgetRuleForm.newOperator">
                      <el-option
                        v-for="(item,i) in newOperatorOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select> 
                  </el-form-item>
                  <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="warnWidth">
                    <el-time-picker
                      is-range
                      v-model="budgetRuleForm.warningTime"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      value-format="HH:mm"
                      format="HH:mm"
                      style="width:160px;"
                      @change="changeTimes">
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="warnWidth">
                    <el-input v-model="budgetRuleForm.warnNumTimes"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:180px;"
                      placeholder="请输入预警次数">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="warnWidth">
                    <el-input v-model="budgetRuleForm.priority"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:180px;"
                      placeholder="请输入优先级">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="执行策略" :label-width="warnWidth">
                    <el-form-item prop="">
                      <div><el-radio v-model="radio" label="1" @change="changeExecute">无</el-radio></div>
                      <div><el-radio v-model="radio" label="2" @change="changeExecute">停止投放</el-radio></div>
                      <div style="margin-top:5px;">
                        <el-radio v-model="radio" label="3" @change="changeExecute">调日预算
                          <div style="padding-left:30px;">
                            <el-radio v-model="priceRadio" label="1" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                              <el-select v-model="topAndDown" :disabled = "pricetofNumber" style="width:100px;" @change="changeNumber">
                                <el-option
                                  v-for="(item,i) in topAndDownOption"
                                  :key="i"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                              <el-input v-model="topAndDownNum" :disabled = "pricetofNumber" style="width:110px;margin-left:15px;" placeholder="请输入数值"></el-input>
                            </el-radio>
                            <el-radio v-model="priceRadio" label="2" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                              <el-select v-model="topAndDown02" :disabled = "pricetofPercent" style="width:100px;" @change="changePercent">
                                <el-option
                                  v-for="(item,i) in topAndDownOption02"
                                  :key="i"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                              <el-input v-model="topAndDownNum02" :disabled = "pricetofPercent" style="width:110px;margin-left:15px;" placeholder="请输入百分比"></el-input>
                            </el-radio>
                          </div>
                        </el-radio>
                      </div>
                    </el-form-item>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button class="newDialogCancel" type="primary" @click="accountWarningCancel">取消保存</el-button>
                  <el-button class="newDialog" type="primary" @click="accountWarning">确定保存</el-button>
                </div>
              </el-dialog>
              <!-- 修改预警 -->
              <el-dialog 
                title="预警修改" 
                width="600px"
                center
                class="warnningForm"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="dialogEditVisible">
                <el-form 
                  :model="budgetRuleForm"
                  :rules="budgetRules"
                  ref="budgetRuleForm">
                  <div class="dialogIcon"><img :src='warningIcon'></div>
                  <!-- 新版 -->
                  <div>
                    <div v-for="(obj,j) in warnParams.infos" :key="j" class="newAddfpStyle addIputs">
                      <el-form-item label="观察指标" prop="newObserveTarget" :label-width="warnWidth">
                        <el-select
                            v-model="obj.target">
                            <el-option
                              v-for="(item,i) in newObserveTargetOption"
                              :key="i"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select> 
                      </el-form-item>
                      <el-form-item label="执行条件" prop="" :label-width="warnWidth">
                        <el-select
                          v-model="obj.op"
                          style="width:120px;">
                          <el-option
                            v-for="(item,i) in newSymbolOption"
                            :key="i"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select> 
                        <el-input v-model.number="obj.val"
                          oninput="value=value.replace(/[^\d]/g,'')"
                          style="width:280px;margin-left:20px;"
                          placeholder="请输入预警数值">
                        </el-input>
                      </el-form-item>
                      <div class="deleteBtn">
                        <div v-if="j>0" size="small" @click="removeRowpl(j)">
                          <i class="deleteBtnicon el-icon-error"></i>
                        </div>
                      </div>
                    </div>
                    <div @click="changeChecked" class="addConditions">
                      <i class="el-icon-plus"></i>新增执行条件
                    </div>
                  </div>
                  <el-form-item label="预警人" prop="warningPerson" class="otherItemPeople" :label-width="warnWidth">
                    <el-select
                      v-model="budgetRuleForm.warningPerson"
                      multiple
                      value-key="userId"
                      @change="changeWarning"
                      style="width:300px;">
                      <el-option
                        v-for="(item,i) in warningPersonOption"
                        :key="i"
                        :label="item.string"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="操作符" prop="newOperator" class="otherItem" :label-width="warnWidth">
                    <el-select
                      v-model="budgetRuleForm.newOperator">
                      <el-option
                        v-for="(item,i) in newOperatorOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select> 
                  </el-form-item>
                  <el-form-item label="预警时间" prop="warningTime" class="otherItem otherItemRight" :label-width="warnWidth">
                    <el-time-picker
                      is-range
                      v-model="budgetRuleForm.warningTime"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      format="HH:mm"
                      style="width:160px;"
                      @change="changeEditTimes">
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="预警次数" prop="warnNumTimes" class="otherItem" :label-width="warnWidth">
                    <el-input v-model="budgetRuleForm.warnNumTimes"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:180px;"
                      placeholder="请输入预警次数">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="优先级" prop="priority" class="otherItem otherItemRight" :label-width="warnWidth">
                    <el-input v-model="budgetRuleForm.priority"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      style="width:180px;"
                      placeholder="请输入优先级">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="是否清除当日预警次数" label-width="150px">
                    <el-switch
                      v-model="budgetRuleForm.clearHistory"
                      active-color="#13ce66"
                      inactive-color="#ccc"
                      :active-value="1"
                      :inactive-value="2">
                    </el-switch>
                  </el-form-item>
                  <el-form-item label="执行策略" :label-width="warnWidth">
                    <el-form-item prop="">
                      <div><el-radio v-model="radio" label="1" @change="changeExecute">无</el-radio></div>
                      <div><el-radio v-model="radio" label="2" @change="changeExecute">停止投放</el-radio></div>
                      <div style="margin-top:5px;">
                        <el-radio v-model="radio" label="3" @change="changeExecute">调日预算
                          <div style="padding-left:30px;">
                            <el-radio v-model="priceRadio" label="1" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                              <el-select v-model="topAndDown" :disabled = "pricetofNumber" style="width:100px;" @change="changeNumber">
                                <el-option
                                  v-for="(item,i) in topAndDownOption"
                                  :key="i"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                              <el-input v-model="topAndDownNum" :disabled = "pricetofNumber" style="width:110px;margin-left:15px;" placeholder="请输入数值"></el-input>
                            </el-radio>
                            <el-radio v-model="priceRadio" label="2" class="priceStyle" :disabled = "pricetofAll" @change="changeNumOrPercent">
                              <el-select v-model="topAndDown02" :disabled = "pricetofPercent" style="width:100px;" @change="changePercent">
                                <el-option
                                  v-for="(item,i) in topAndDownOption02"
                                  :key="i"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                              <el-input v-model="topAndDownNum02" :disabled = "pricetofPercent" style="width:110px;margin-left:15px;" placeholder="请输入百分比"></el-input>
                            </el-radio>
                          </div>
                        </el-radio>
                      </div>
                    </el-form-item>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" class="newDialogCancel" @click="dialogEditVisible = false">取消保存</el-button>
                  <el-button type="primary" class="newDialog" @click="accountWarningEdit">确定保存</el-button>
                </div>
              </el-dialog>
              </div>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
            <!-- 正在载入 -->
            <el-dialog
              title=""
              :visible.sync="loadingDialogVisible"
              width="20%"
              :destroy-on-close="true"
              :show-close="false"
              :close-on-click-modal="false"
              class="loadingBg">
              <span class="loading">
                <span>正在载入...</span>
                <i class="el-icon-loading"></i>
              </span>
            </el-dialog>
         </template>
      </PocCardTable>
      <zdyApple :visible.sync="showzdyDialog" ref = 'zdyApple' :pubVue="pubVue"></zdyApple>
    </div>
  </template>
  
  <script>
  import warningIcon from '@/assets/img/warningIcon.png';
  import ZDYLicon from '@/assets/img/ZDYLicon.png';
  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import zdyApple from './zdyApple'
  export default {
    components: {
      zdyApple
    },
    data() {
      return {
        warningIcon,
        ZDYLicon,
        pubVue: this,//空vue，用于子组件之间传值
        showzdyDialog:false,
        showDialog:false,
        nowIs:false,
        showEarlyWarningSetDialog:false,
        showSingleDialog:false,
        timeDialog:false,
        startStopDialog:false,
        alertStartStopDialogIs:false,
        tiaojiaDialog:false,
        planStatus:false,//计划状态下拉列表是否展示
        exprtVisible:false,//
        planType:20,
        qdoptions:{goal:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],
                  userStatuList:[{text:'启动中',id:'0'},{text:'暂停中',id:'1'},{text:'余额不足',id:'2'},{text:'达到账户日预算',id:'3'},{text:'达到计划日预算',id:'4'}]},//选择状态
        warningOpenList:[],//['开启','关闭']
        value1: [],//时间
        tableOption:[],
        title:'',
        showStatusList:{
                        status2:[''],
                        startSwitch:['是','否'],
                        warningSwitch:['开启','关闭','未设置']},
        planName:'',//请输入计划名称查找
        planId:'',//请输入计划ID查找
        channel:'',//请选择推广目的
        channeln:'',//请选择状态
        channelm:'',//请选择预警状态
        tableData:[],
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        page: 0,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        pageIndex: 1,//当前页
        dataList:'',
        userId:'',//用户ID
        userName:'',//用户名
        deptId:'',//
        params:{},//入参
        userContent:{},//登录用户信息&弹框默认
        yulanList:[],//批量日限额预览
        disabledIs: false,//switch是否可操作
        warmType:false,//判断是否设置过预警消息推送
        msgContent:'',//确认添加新计划
        msgContent1:'',//确认投放开关关闭/计划状态关闭
        limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
        itemValue:'',
        startSwitch:'',
        ssDetailParams:[],
        typeName:'',
        adPlanIdList:[],
        dayLimitIs:false,
        closePlanList:'',//校验是否有存在的启停计划
        myHeaders: {Authorization:''},
        fileList:{},
        command:'',
        formLabelWidth:'150px',
        //广告系列
        pickerOptions: {
          shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        dateValue:[],//搜索时间
        adplanStatu:'',//搜索状态
        adplanStatuOption:[//状态选项
        {
          value:'RUNNING',
          label:'开启中'
        },{
          value:'PAUSED',
          label:'暂停中'
        },{
          value:'ON_HOLD',
          label:'挂起中'
        },{
          value:'DELETED',
          label:'已删除'
        }],
        adplanUseStatu:'',//搜索启用状态
        adplanUseStatuOption:[//启用状态选项
        {
          value:'false',
          label:'已启用'
        },{
          value:'true',
          label:'已移除'
        }],
        adplanLabel:'',//搜索标签
        adplanLabelOption:[{//启用状态选项
          value:1,
          label:'全部'
        }],
        adplanPosition:'',//搜索投放位置
        adplanPositionOption:[//启用状态选项
        {
          value:'APPSTORE_SEARCH_RESULTS',
          label:'搜索结果'
        },{
          value:'APPSTORE_SEARCH_TAB',
          label:'"搜索"标签'
        }],
        titleList:[],
        materialIdsPara:[],//选中的广告系列
        ownerId:'',
        ownerName:'',
        //预警
        dialogFormVisible:false,//预警预算弹窗
        budgetRuleForm:{
          condition:{
            min:'',
            max:''
          },
          observeTarget:'1',//观察指标
          warningPerson:[],//预警人
          clearHistory:'',//清除预警次数-修改的时候用
          // 新
          newObserveTarget:[],//观察指标-新
          newSymbol:'',//执行条件中的符号，大于等于，小于等于
          newCondition:'',//执行条件中的num
          newOperator:'',//操作符
          warnNumTimes:'',//预警次数
          priority:'',//优先级
          warningTime:[new Date(0,0,0), new Date()],//操作时间
          // warningTime:[],//操作时间
        },
        budgetRules:{},
        newObserveTargetOption:[{//观察指标集合-新
          value:2,
          label:'消耗'
        },{
          value:3,
          label:'平均CPT'
        },{
          value:4,
          label:'平均CPA'
        },{
          value:5,
          label:'曝光'
        },{
          value:6,
          label:'点击'
        },{
          value:7,
          label:'安装'
        },{
          value:8,
          label:'点击率'
        },{
          value:9,
          label:'转化率'
        }],
        newSymbolOption:[{//执行条件中的符号
          value:1,
          label:'>='
        },{
          value:2,
          label:'<='
        }],
        newOperatorOption:[{//操作符
          value:1,
          label:'与'
        },{
          value:2,
          label:'或'
        }],
        radio: '1',
        priceRadio:'1',
        topAndDown:'',
        topAndDownOption:[{
          value:1,
          label:'上调'
        },{
          value:2,
          label:'下调'
        }],
        newWarnStartTime:'',
        newWarnEndTime:'',
        warnParams:{//确定预警-入参
          infos:[{
            target:'',
            op:'',
            val:''
          }]
        },
        topAndDownNum:'',
        topAndDown02:'',
        topAndDownOption02:[{
          value:1,
          label:'上调'
        },{
          value:2,
          label:'下调'
        }],
        topAndDownNum02:'',
        warningPersonOption:[],//预警人的集合
        warnOwnerId:'',//预警的ownerId
        changeWarningPerson:'',//选中的预警人id
        changeWarningPersonName:'',//选中的预警人name
        dialogWarningDetail:false,//预警详情的弹框
        dialogEditVisible:false,//预警修改弹框
        warnInfosTableData:[],//预警详情表格
        warnDeptId:'',//修改预警时候的部门id
        warningId:'',//修改预警id
        row:[],
        dialogAccountUpdate:false,//账户更新弹框
        accountUpdateForm:{//账户更新弹框表单
          balance:'',//余额
          recharge:'',//最近充值
          status:'',//状态
        },
        accountUpdaterules:{//账户更新弹框表单-校验

        },
        formLabelWidth1:'60px',
        appleOwnerIdUpdate:'',//更新的时候入参的ownerid
        appleStatus:'',//更新的时候入参的status
        orderBy:'',//排序
        listParams:{
          selector:{
            conditions:[],
            orderBy:[
            // {field:'localSpend',sortOrder:'ASCENDING'}
            ]
          }
          // conditions:[{
          //   field:'',
          //   operator:'IN',
          //   values:[]
          // }],
        },//列表入参
        field:'',
        inValue:[],
        warnPlanId:'',
        warningData:[],
        value2:false,
        localSpend:'',//默认
        dOrder:'',//默认排序
        campaignId:'',//广告计划系列id
        campaignName:'',//广告计划系列name
        appName:'',
        appId:'',
        loadingDialogVisible:true,//正在载入的弹框
        dailyBudgetEvery:'',//每日预算-修改
        reasons:[],
        reasonsWords:'',
        warnEigth:[],
        warnNine:[],
        pricetofAll:true,//调日预算的all选框
        pricetofNumber:true,//调日预算--输入数值行
        pricetofPercent:true,//调日预算--输入百分比行
        executeValue:'',//change调日预算的值
        executeRate:'',//执行比率 下调10% => -0.1
        executeDiff:'',//执行增减差额 下调100 => -100
        percentDetail:'',
        numberDetail:'',
        myHeaders: {Authorization:''},
        orderByList:[],
        columnOrderBy:'',
        warnWidth:'80px',



      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.ownerName = this.$route.query.name
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      let nowTimes = Date.now();
      let oldTimes = Date.now();
      // let oldTimes = nowTimes - (86400000 * 7);
      let oldDate = this.getDate(oldTimes); //一个月前的时间
      let nowDate = this.getDate(nowTimes);//今天的时间
      this.dateValue = [oldDate, nowDate];
      this.warnDeptId = 328//开发组的，苹果的是328
      this.localSpend = 'localSpend'
      this.dOrder = 'DESCENDING'
      this.budgetRuleForm.newOperator = 1,//预警的操作符默认1-与
      this.budgetRuleForm.warnNumTimes = 3
      this.budgetRuleForm.priority = 1
      // ASCENDING
      this.newWarnStartTime = this.getDateTime(this.budgetRuleForm.warningTime[0])
      this.newWarnEndTime = this.getDateTime(this.budgetRuleForm.warningTime[1])
      this.getAccountPlanListFn()//列表
      this.getFpyjList328()
      this.getFpyjList329()
      this.budgetRuleForm.clearHistory = 1
     
    },
    
    methods: {
      //tab切换
      clickChangeChannel(val){
        let data = this.tableData[0],supplySources = ''
        data.metadata.supplySources.forEach(function(item,i){
          supplySources = item
        })
        if(val == 2){//广告组
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:data.metadata.campaignId,
          campaignName:data.metadata.campaignName,position:supplySources,appId:data.metadata.app.adamId,
          budget:data.metadata.dailyBudget.amount}})
        }else if(val == 3){//关键词
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:data.metadata.campaignId,
          campaignName:data.metadata.campaignName,
          supplySources:supplySources,appId:data.metadata.app.adamId,
          budget:data.metadata.dailyBudget.amount}})
        }else if(val == 4){//广告
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:data.metadata.campaignId,
          campaignName:data.metadata.campaignName,
          supplySources:supplySources,appId:data.metadata.app.adamId,
          budget:data.metadata.dailyBudget.amount}})
        }
      },

      //格式化时间 返回年月日 
      getDate(time) {
        if (time) {
          let val = new Date(time);
          let Y = val.getFullYear();
          let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
          let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
          let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
          let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
          return Y + '-' + M + '-' + D;
        }
        return ''
      },

      // 自适应表格列宽
      flexColumnWidth(str, tableData, flag = 'max') {
        // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
        // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
        // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
        let columnContent = ''
        if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
          return
        }
        if (!str || !str.length || str.length === 0 || str === undefined) {
          return
        }
        if (flag === 'equal') {
          // 获取该列中第一个不为空的数据(内容)
          for (let i = 0; i < tableData.length; i++) {
            if (tableData[i][str].length > 0) {
              columnContent = tableData[i][str]
              break
            }
          }
        } else {
          // 获取该列中最长的数据(内容)
          let index = 0
          for (let i = 0; i < tableData.length; i++) {
            if (tableData[i][str] === null) {
              return
            }
            const now_temp = tableData[i][str]
            const max_temp = tableData[index][str]
            if (now_temp.length >= max_temp.length) {
              index = i
            }
          }
          columnContent = tableData[index][str].toString()
        }
        // 以下分配的单位长度可根据实际需求进行调整
        let flexWidth = 0
        for (const char of columnContent) {
          if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
            // 如果是英文字符，为字符分配8个单位宽度
            flexWidth += 8
          } else if (char >= '\u4e00' && char <= '\u9fa5') {
            // 如果是中文字符，为字符分配18个单位宽度
            flexWidth += 18
          } else {
            // 其他种类字符，为字符分配15个单位宽度
            flexWidth += 15
          }
        }
        if (flexWidth < 120) {
          // 设置最小宽度
          flexWidth = 120
        }
        return flexWidth
      },
        
      //重置
      resetForm(){
        let _this = this
        _this.$router.go(0)
        _this.getAccountPlanListFn()
      },

      changeAdplanStatu(val){
        this.field = 'displayStatus'
      },
      changeAdplanUseStatu(val){
        this.field = 'deleted'
      },
      changeAdplanPosition(val){
        this.field = 'supplySources'
      },
      blurPlanName(){
        this.field = 'campaignName'
      },
      blurPlanId(){
        this.field = 'campaignId'
      },

      //计划列表
      getAccountPlanListFn(){
        let _this = this,conditions = [],orderBy = []
        console.log(_this.$refs.multipleTable)
        _this.listParams.startTime = _this.dateValue[0]//开始时间
        _this.listParams.endTime = _this.dateValue[1]//结束时间
        _this.listParams.returnGrandTotals = true
        _this.listParams.returnRecordsWithNoMetrics = true
        _this.listParams.returnRowTotals = true
        _this.listParams.timeZone = 'ORTZ'
        _this.listParams.selector = {
          'pagination' : {
            'limit':_this.pageCount,//每页条数
            'offset':_this.page//当前页
          },
        }
        orderBy.push({
          'field':_this.localSpend,
          'sortOrder':_this.dOrder
        })
        if(_this.planName != ''){
          if(_this.planName.length<=2){
            conditions.push({'field':'campaignName','operator':'IN','values':[_this.planName]})//判断一下字符大小--小于等于两个字符
          }else{
            conditions.push({'field':'campaignName','operator':'STARTSWITH','values':[_this.planName]})//判断一下字符大小--大于两个字符
          }
        }
        if(_this.planId != ''){
          if(_this.planName.length<=2){
            conditions.push({'field':'campaignId','operator':'IN','values':[_this.planId]})//判断一下字符大小--小于等于两个字符
          }else{
            conditions.push({'field':'campaignId','operator':'STARTSWITH','values':[_this.planId]})//判断一下字符大小--大于两个字符
          }
        }
        _this.listParams.selector.conditions = conditions
        _this.listParams.selector.orderBy = orderBy
        let num = ''
        // num = val*100
        // return num.toFixed(2);   
        $http.axios.post("/api/apple/adPlan/getCampaigns/"+_this.ownerId,_this.listParams).then(res=> {
          if(res.data.code==200){
            _this.tableData = res.data.data.data.reportingDataResponse.row
            _this.totalRow = res.data.data.pagination.totalResults
            _this.loadingDialogVisible = false

            _this.tableData.forEach((obj,index)=>{
              //结束日期
              if(obj.metadata.endTime == null){
                obj.metadata.endTime = '-'
              }else{
                obj.metadata.endTime = _this.getDate(obj.metadata.endTime)
              }
              //开始日期
              if(obj.metadata.startTime == null){
                obj.metadata.startTime = '-'
              }else{
                obj.metadata.startTime = _this.getDate(obj.metadata.startTime)
              }
              //国家或地区
              if(obj.metadata.countriesOrRegions == 'CN'){
                obj.metadata.countriesOrRegions = '中国大陆'
              }
              //广告展示位置
              // if(obj.metadata.supplySources == 'APPSTORE_SEARCH_RESULTS'){
              //   obj.metadata.supplySources = '搜索结果'
              // }else if(obj.metadata.supplySources == 'APPSTORE_SEARCH_TAB'){
              //   obj.metadata.supplySources = '搜索标签'
              // }
              //生命周期预算
              if(obj.metadata.totalBudget != null){
                obj.metadata.totalBudget.amount = '¥'+(Number(obj.metadata.totalBudget.amount).toFixed(2))
              }
              //支出
              if(obj.total.localSpend!=null){
                obj.total.localSpend.amount = '¥'+(Number(obj.total.localSpend.amount).toFixed(2))
              }
              //平均每次点击费用
              if(obj.total.avgCPT!=null){
                obj.total.avgCPT.amount = '¥'+(Number(obj.total.avgCPT.amount).toFixed(2))
              }
              //平均每次转化费用
              if(obj.total.avgCPA!=null){
                obj.total.avgCPA.amount = '¥'+(Number(obj.total.avgCPA.amount).toFixed(2))
              }
              //平均每千次展示费用
              if(obj.total.avgCPM!=null){
                obj.total.avgCPM.amount = '¥'+(Number(obj.total.avgCPM.amount).toFixed(2))
              }
              //点击率
              if(obj.total.ttr!=null){
                obj.total.ttr = ((Number(obj.total.ttr)*100).toFixed(2))+'%'
              }
              //转化率
              if(obj.total.conversionRate!=null){
                obj.total.conversionRate = ((Number(obj.total.conversionRate)*100).toFixed(2))+'%'
              }
            })
            _this.userSetCustom()
          }else{
            let a = res.data.msg
            let b = a.replace("\\","")
            let c = JSON.parse(b)
            _this.$message({
              showClose: true,
              message: c.error.errors,
              type: 'error'
            })    
          }
        })
      },

      //排序
      handlesortChange(column) {
        let _this = this,orderBy = [],localSpend = '',columnOrder = ''
        columnOrder = column.prop
        if(column.prop == 'dailyBudget'){
          this.localSpend = column.prop
        }else{
          _this.orderByList.forEach((val,i)=>{
            if(columnOrder.indexOf(val.orderBy)!=-1){
              _this.localSpend = val.orderBy
            }
          })
        }
        if(column.order == 'descending'){
          this.dOrder = 'DESCENDING'
          orderBy.push({'field':this.localSpend,'sortOrder':this.dOrder})
        }else if(column.order == 'ascending'){
          this.dOrder = 'ASCENDING'
          orderBy.push({'field':this.localSpend,'sortOrder':this.dOrder})
        }
        this.listParams.selector.orderBy = orderBy
        this.getAccountPlanListFn()
      },

      //保留两位小数
      fun(val){
  　　  return Number(val).toFixed(2);
  　　 },

      //率
      funny(val){
        let num = ''
        num = val*100
        return num.toFixed(2);   

        // return Number(val)*100;
  　　 },

      // 删除
      deletePlanFn(row){
        let _this = this
        $http.axios.get("/api/apple/adPlan/deleteCampaigns?orgId="+_this.ownerId+"&campaignId="+row.metadata.campaignId+"&userName="+_this.userName).then(res=> {
          if(res.data.code==200){
            _this.getAccountPlanListFn()
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'success'
            }) 
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            }) 
          }   
        })
      },

      // 分页
      handleSizeChange(val) {
        let _this = this
        _this.pageCount = val
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        this.findAll(val)
      },
      //分页方法
      findAll (val) {
        this.page = val
        //获取数据接口（调用封装的接口）
        this.getAccountPlanListFn()
      },

      //选中素材的materialIds
      chooseTableFn(visible){
        let that = this
        let sendttSelection = that.$refs.multipleTable.selection
        that.materialIdsPara = []
        sendttSelection.forEach(function(e,i){
          that.materialIdsPara.push(e.id)
        })
        if(sendttSelection.length == 0){
          that.$message({
            message: '请勾选广告系列',
            type: 'warning'
          });
          that[visible]=false
        }else{
          that[visible]=true
          return true
        }
      },

      //获取分配人员列表
      getFpyjList328(){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
          if(response.data.code == 200){
            that.warnEigth = response.data.data//预警人
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
      getFpyjList329(){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
          if(response.data.code == 200){
            that.warnNine = response.data.data//预警人
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //change预警人
      changeWarning(event){
        let changeWarningPerson = []
        event.forEach(function(val,i){
          changeWarningPerson.push(val.userId)
        })
        this.changeWarningPerson = changeWarningPerson.toString()
      },

      //取消预警弹框
      accountWarningCancel(){
        this.budgetRuleForm.warningPerson = []
        this.radio = '1'
        this.budgetRuleForm.newOperator = 1//操作符
        this.budgetRuleForm.warnNumTimes = 3//预警次数
        this.budgetRuleForm.priority = 1//优先级
        this.topAndDown = ''//数值的上调下调
        this.topAndDownNum = ''//数值
        this.topAndDown02 = ''//百分比的上调下调
        this.topAndDownNum02 = ''//百分比
        this.pricetofAll = true//调日预算的整个选框
        this.pricetofNumber = true//数值
        this.pricetofPercent = true//百分比
        this.dialogFormVisible = false
      },

      //预警确定
      accountWarning(){
        let _this = this
        _this.warnParams.userId = _this.userId
        _this.warnParams.userName = _this.userName
        _this.warnParams.deptId = _this.deptId
        _this.warnParams.ownerId = _this.warnOwnerId
        _this.warnParams.dataId = Number(_this.warnPlanId) //数据id
        _this.warnParams.adPlanId = _this.warnPlanId//计划系列id
        _this.warnParams.type = 2 //1-系列组 2-系列 3-广告组
        _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
        _this.warnParams.warnId = _this.changeWarningPerson//预警人id
        // _this.newWarnStartTime = _this.getDateTime(_this.budgetRuleForm.warningTime[0])
        // _this.newWarnEndTime = _this.getDateTime(_this.budgetRuleForm.warningTime[1])
        _this.warnParams.startTime = _this.newWarnStartTime//开始时间
        _this.warnParams.endTime = _this.newWarnEndTime//结束时间
        _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
        _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
        _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
        if(_this.numberDetail == 1){
          _this.executeDiff = _this.topAndDownNum
        }else{
          _this.executeDiff = -(_this.topAndDownNum)
        }
        if(_this.percentDetail == 1){
          _this.executeRate = _this.topAndDownNum02/100
        }else{
          _this.executeRate = -(_this.topAndDownNum02/100)
        }
        _this.warnParams.executeRate = _this.executeRate//执行比率 下调10% => -0.1
        _this.warnParams.executeDiff = _this.executeDiff//执行增减差额 下调100 => -100

        $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
          if(response.data.code == 200){
            _this.dialogFormVisible = false
            // _this.warningDetail()
            _this.getAccountPlanListFn()
            _this.accountWarningCancel()
            _this.dialogWarningDetail = false//预警详情的弹窗
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //预警详情
      warningDetail(row){
        console.log(row)
        this.row = row
        this.dialogWarningDetail = true
        this.warnOwnerId = this.ownerId
        this.warnPlanId = row.metadata.campaignId
        let _this = this
        $http.axios.get("/api/apple/warn/query?adType=2&dataId="+row.metadata.campaignId).then(function(response) {
          if(response.data.code == 200){
          _this.warnInfosTableData = response.data.data
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //预警修改
      warnEdit(row){
        //拼接预警人id
        this.getFpyjList328()
        this.getFpyjList329()
        this.warningPersonOption = this.warnEigth.concat(this.warnNine)
        this.budgetRuleForm.clearHistory = 1//默认清除当初预警次数
        this.dialogEditVisible = true
        let warnId = [],warnName = [],_this = this,infos = []
        infos = row.infos//获取观察指标和执行条件
        console.log(infos)
        warnId = row.warnId.split(",")
        _this.budgetRuleForm.warningPerson = []
        //回显数据
        _this.warningPersonOption.forEach(function(val,i){
          warnId.forEach(function(item,index){
            if(val.userId == item){
              _this.budgetRuleForm.warningPerson.push({'userId':item})
            }
          })
        })//预警人
        _this.warnParams.infos = []
        infos.forEach(function(obj,index){
          console.log(obj)
          _this.warnParams.infos.push({
            target:obj.target,
            op:obj.op,
            val:obj.val
          })
        })//观察指标和执行条件
        _this.budgetRuleForm.newOperator = row.operate //操作符
        //开始结束时间回显
        const date = new Date();
        let nowDate = ''
        nowDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;
        _this.budgetRuleForm.warningTime[0] = new Date(nowDate+" "+row.startTime+":00")///开始结束时间
        _this.budgetRuleForm.warningTime[1] = new Date(nowDate+" "+row.endTime+":00")///开始结束时间
        //开始结束时间传参
        _this.newWarnStartTime = row.startTime///开始结束时间
        _this.newWarnEndTime = row.endTime///开始结束时间
        _this.radio = String(row.executeStrategy) //执行策略
        _this.changeWarningPerson = row.warnId
        _this.warningId = row.id
        _this.budgetRuleForm.warnNumTimes = row.warnCount//预警次数
        _this.budgetRuleForm.priority = row.priority//优先级
        console.log(row.executeStrategy)
        console.log(typeof(row.executeStrategy))
        if(String(row.executeStrategy) == '3'){
          _this.executeValue = '3'
          _this.pricetofAll = false//调日预算的整个选框
          _this.pricetofNumber = false//数值
          _this.pricetofPercent = true//百分比
          //数值
          if(row.executeDiff != 0){
            _this.topAndDown02 = ''
            _this.topAndDownNum02 = ''
            _this.pricetofNumber = false//数值
            _this.pricetofPercent = true//百分比
            _this.priceRadio = '1'
            if(row.executeDiff>0){
              _this.topAndDown = 1
              _this.numberDetail = _this.topAndDown
              _this.topAndDownNum = row.executeDiff
              _this.executeDiff = _this.topAndDownNum
            }else{
              
              _this.topAndDown = 2
              _this.numberDetail = _this.topAndDown
              _this.topAndDownNum = -(row.executeDiff)
              _this.executeDiff = -(_this.topAndDownNum)
            }
          }else{
            _this.priceRadio = '2'
            _this.pricetofNumber = true//数值
            _this.pricetofPercent = false//百分比
          }
          //百分比
          if(row.executeRate != 0){
            _this.topAndDown = ''
            _this.topAndDownNum = ''
            _this.pricetofNumber = true//数值
            _this.pricetofPercent = false//百分比
            _this.priceRadio = '2'
            if(row.executeRate>0){
              _this.topAndDown02 = 1
              _this.percentDetail = _this.topAndDown02
              _this.topAndDownNum02 = row.executeRate*100
              _this.executeRate = row.executeRate
            }else{
              _this.topAndDown02 = 2
              _this.percentDetail = _this.topAndDown02
              _this.topAndDownNum02 = -(row.executeRate*100)
              _this.executeRate = -(row.executeRate)
            }
          }else{
            _this.priceRadio = '1'
            _this.pricetofNumber = false//数值
            _this.pricetofPercent = true//百分比
          }
        }else{
          _this.pricetofAll = true//调日预算的整个选框
          _this.pricetofNumber = true//数值
          _this.pricetofPercent = true//百分比
          _this.topAndDown = ''//数值的上调下调
          _this.topAndDownNum = ''//数值
          _this.topAndDown02 = ''//百分比的上调下调
          _this.topAndDownNum02 = ''//百分比
        }
      },

      //预警修改
      accountWarningEdit(){
        let _this = this
        // console.log(_this.topAndDownNum)
        // console.log(_this.executeDiff)
        _this.warnParams.userId = _this.userId
        _this.warnParams.userName = _this.userName
        _this.warnParams.deptId = _this.deptId
        _this.warnParams.ownerId = _this.warnOwnerId
        _this.warnParams.dataId = Number(_this.warnPlanId) //数据id
        _this.warnParams.adPlanId = _this.warnPlanId//计划系列id
        _this.warnParams.type = 2 //1-系列组 2-系列 3-广告组
        _this.warnParams.executeStrategy = Number(_this.radio)//1-停投 2-调出价
        _this.warnParams.warnId = _this.changeWarningPerson//预警人id
        _this.warnParams.startTime = _this.newWarnStartTime//开始时间
        _this.warnParams.endTime = _this.newWarnEndTime//结束时间
        _this.warnParams.operate = _this.budgetRuleForm.newOperator//操作符 1-与 2-或 默认与
        _this.warnParams.warnCount = Number(_this.budgetRuleForm.warnNumTimes) //预警次数 默认3
        _this.warnParams.priority = Number(_this.budgetRuleForm.priority) //优先级
        if(_this.numberDetail == 1){
          _this.executeDiff = _this.topAndDownNum
        }else{
          _this.executeDiff = -(_this.topAndDownNum)
        }
        if(_this.percentDetail == 1){
          _this.executeRate = _this.topAndDownNum02/100
        }else{
          _this.executeRate = -(_this.topAndDownNum02/100)
        }
        _this.warnParams.executeRate = _this.executeRate//执行比率 下调10% => -0.1
        _this.warnParams.executeDiff = _this.executeDiff//执行增减差额 下调100 => -100
        _this.warnParams.id = Number(_this.warningId)//修改时候的传id
        _this.warnParams.clearHistoryPermitsFlag = Number(_this.budgetRuleForm.clearHistory)//是否清空预警次数
        $http.axios.post("/api/apple/warn/insertOrUpdate",_this.warnParams).then(function(response) {
          if(response.data.code == 200){
            _this.dialogEditVisible = false
            // _this.warnInfosTableData()
            // _this.warningDetail()
            _this.getAccountPlanListFn()
            _this.accountWarningCancel()
            _this.dialogWarningDetail = false//预警详情的弹窗
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //修改预警状态
      changeWarnStatus(row){
        let _this = this,params={}
        params={
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.ownerId,
          'dataId':Number(_this.warnPlanId),//账户id
          'type':2,//1-系列组 2-系列 3-广告组
          'infos':row.infos,//监控条件明细
          'executeStrategy':row.executeStrategy,//执行策略
          'warnId':row.warnId,//预警人
          'startTime':row.startTime,//开始时间
          'endTime':row.endTime,//结束时间
          'adPlanId':Number(_this.warnPlanId),
          'operate':row.operate,
          'executeRate':row.executeRate,
          'executeDiff':row.executeDiff,
          'clearHistoryPermitsFlag':1,//修改时候传1-暂时
          'warnCount':row.warnCount,//预警次数 默认3
          'priority':row.priority,//优先级
          'id':Number(row.id),//修改时候的id
          'status':Number(row.status),
        }
        $http.axios.post("/api/apple/warn/insertOrUpdate",params).then(function(response) {
          if(response.data.code == 200){
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'success'
            })
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //进入广告组
      enterAdvertGroup(row){
        console.log(row)
        let supplySources = ''
        row.metadata.supplySources.forEach(function(val,i){
          supplySources = val
        })
        this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:row.metadata.campaignId,
          campaignName:row.metadata.campaignName,position:supplySources,appId:row.metadata.app.adamId,
          budget:row.metadata.dailyBudget.amount}})
      },

      //进入关键词
      enterAdvertkw(row){
        console.log(row)
        let supplySources = ''
        row.metadata.supplySources.forEach(function(val,i){
          supplySources = val
        })
        this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:row.metadata.campaignId,
          campaignName:row.metadata.campaignName,
          supplySources:supplySources,appId:row.metadata.app.adamId,
          budget:row.metadata.dailyBudget.amount}})
      },

      //修改广告系列
      editAdvertPlan(row){
        this.$router.push({path:'/plan-management/editAdvertPlan', query: { id: this.ownerId, name:this.ownerName ,
          campaignId:row.metadata.campaignId,campaignName:row.metadata.campaignName,
          appId:row.metadata.app.appId,appName:row.metadata.app.appName}})
      },

      //账户更新
      accountUpdate(row){
        this.dialogAccountUpdate = true
        this.accountUpdateForm.recharge = row.lastRecharge
        if(row.status == 0){
          this.accountUpdateForm.status = true
        }else{
          this.accountUpdateForm.status = false
        }
        this.appleOwnerIdUpdate = row.ownerId
        this.appleStatus = row.status
      },

      //change状态
      changeStatus(event){
        if(event == true){
          this.appleStatus = 0
        }else{
          this.appleStatus = 1
        }
      },

      //账户更新-确定
      accountUpdateSure(){
        let _this = this,params={}
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.ownerId,
          // 'adPlanIds':,
          // 'balance':_this.accountUpdateForm.balance,
          // 'lastRecharge':_this.accountUpdateForm.recharge,
          'status':_this.appleStatus
        }
        $http.axios.post("/api/apple/adPlan/update/batch",params).then(function(response) {
          if(response.data.code == 200){
            _this.dialogAccountUpdate = false
            // _this.appleAccountList()
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //预警
      accountWarnDialog(){
        this.dialogFormVisible = true
        this.getFpyjList328()
        this.getFpyjList329()
        this.warningPersonOption = this.warnEigth.concat(this.warnNine)
        // this.accountWarningCancel()
      },

      //change列表状态
      changeListStatus(row){
        // PAUSED
        // RUNNING
        let _this = this,params={},status = ''
        if(row.metadata.servingStatus == 'NOT_RUNNING'){
          status = 2
        }else{
          status = 1
        }
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.ownerId,
          'adPlanIds':row.metadata.campaignId,
          'status':status
        }
        $http.axios.post("/api/apple/adPlan/updateCampaignsStatus",params).then(function(response) {
          if(response.data.code == 200){
            _this.getAccountPlanListFn()
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'success'
            })
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //新建广告系列
      createAdvertPlan(){
        this.$router.push({path:'/plan-management/createAdvertPlan',query: { id: this.ownerId, name:this.ownerName }})
      },

      editEveryBut(scope){
        console.log(scope.row.metadata.dailyBudgetAmount.amount)
        this.dailyBudgetEvery = scope.row.metadata.dailyBudgetAmount.amount
      },

      //修改每日预算
      handleConfirm(scope) {
        console.log(scope)
        scope._self.$refs[`popover-${scope.$index}`].doClose()
        scope._self.$refs[`popover-${scope.$index}`].showPopper = false
      },

      handleConfirm() {
        document.body.click() // 模拟点击页面其它部分关掉弹层，因为该页面列表使用懒加载导致doClose无效
      },

      handleConfirmSure(scope,index) {
        console.log(index)
        // 1是预算，2是批量状态
        // dailyBudgetEvery
        let _this = this,params={}
        if(index == 1){
          params = {
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'orgId':_this.ownerId,
            'dailyBudgetAmount':{
              'amount':_this.dailyBudgetEvery,
              'currency':'RMB'
            },
            'campaignId':scope.row.metadata.campaignId,
            'name':scope.row.metadata.campaignName
          }
        }
        $http.axios.post("/api/apple/adPlan/updateCampaigns",params).then(function(response) {
          if(response.data.code == 200){
            scope._self.$refs[`popover-${scope.$index}`].doClose()
            scope._self.$refs[`popover-${scope.$index}`].showPopper = false
            _this.getAccountPlanListFn()
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'success'
            })
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //批量修改状态
      batchEditStatus(command){
        let _this = this,params = {},campaignId = '',campaignIdList = []
        let sendttSelection = _this.$refs.multipleTable.selection
        sendttSelection.forEach(function(e,i){
          console.log(e)
          campaignIdList.push(e.metadata.campaignId)
        })
        campaignId = String(campaignIdList)
        console.log(campaignId)
        if(sendttSelection.length == 0){
          _this.$message({
            message: '请勾选广告系列',
            type: 'warning'
          });
        }else{
          params = {
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'ownerId':_this.ownerId,        
            'adPlanIds':campaignId,
            'status':Number(command)
          }
          $http.axios.post("/api/apple/adPlan/updateCampaignsStatus",params).then(function(response) {
            if(response.data.code == 200){
              _this.getAccountPlanListFn()
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: 'success'
              })
            } else {
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        }
      },

      //预警-选择观察指标-新增
      changeChecked(){
        // this.warnParams.infos.push({})
        this.warnParams.infos.push({
          target:'',
          op:'',
          val:''
        })
      },

      //预警-选择观察指标-删除
      removeRowpl(index) {
        if (index >= 0) {
          this.warnParams.infos.splice(index, 1);
        }
      },

      getDateTime(time) {
        if (time) {
          let val = new Date(time);
          let Y = val.getFullYear();
          let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
          let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
          let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
          let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
          return h + ':' + m;
        }
        return ''
      },

      //change预警-开始结束时间
      changeTimes(val){
        this.newWarnStartTime = val[0]
        this.newWarnEndTime = val[1]
      },
      changeEditTimes(val){
        this.budgetRuleForm.warningTime = val
        this.newWarnStartTime = this.getDateTime(this.budgetRuleForm.warningTime[0])
        this.newWarnEndTime = this.getDateTime(this.budgetRuleForm.warningTime[1])
      },

      //change执行策略-显示隐藏问题
      changeExecute(val){
        this.executeValue = val
        if(val == '3'){
          this.priceRadio = '1'
          this.pricetofAll = false//调日预算的整个选框
          this.pricetofNumber = false//数值
          this.pricetofPercent = true//百分比
        }else{
          this.pricetofAll = true//调日预算的整个选框
          this.pricetofNumber = true//数值
          this.pricetofPercent = true//百分比
          this.topAndDown = ''//数值的上调下调
          this.topAndDownNum = ''//数值
          this.topAndDown02 = ''//百分比的上调下调
          this.topAndDownNum02 = ''//百分比
        }
      },

      //change调日预算中的数值和百分比
      changeNumOrPercent(val){
        if(this.executeValue == '3'){
          if(val == '1'){
            this.pricetofNumber = false//数值
            this.pricetofPercent = true//百分比
            this.topAndDown02 = ''//百分比的上调下调
            this.topAndDownNum02 = ''//百分比
          }else{
            this.pricetofNumber = true//数值
            this.pricetofPercent = false//百分比
            this.topAndDown = ''//数值的上调下调
            this.topAndDownNum = ''//数值
          }
        }
      },

      //change调日预算--上调下调数值
      changeNumber(val){
        // topAndDownNum
        this.numberDetail = val
        if(val == 1){//上调
          this.executeDiff = this.topAndDownNum
        }else{
          this.executeDiff = -(this.topAndDownNum)
        }
        console.log(this.numberDetail)
      },

      //change调日预算--上调下调百分比
      changePercent(val){
        this.percentDetail = val
        if(val == 1){
          this.executeRate = this.topAndDownNum02/100
        }else{
          this.executeRate = -(this.topAndDownNum02/100)
        }
        console.log(this.percentDetail)
      },

      //批量上传否定关键词
      batchUploadNegKw(params){
        let _this = this,data = new FormData();
        $http.axios.defaults.timeout = 500000
        data.append('file',params.file);
        data.append('deptId',_this.deptId);
        data.append('orgId',_this.ownerId);
        data.append('userId',_this.userId);
        data.append('userName',_this.userName);
        $http.axios.post("/api/apple/keyWordFile/uploadPlanNegativekeywordsFile",data).then(function(response) {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'success'
          })
        })
      },

      //自定义
      zdyFn(){
        this.$refs.zdyApple.generateTimes(1,this.ownerId)
      },

      //用户已经设置的自定义列
      userSetCustom(){
        let _this = this,titleList = [],columnName = ''
        $http.axios.post("/api/apple/CustomColumn/getColumnsByOrgId?customType=1&orgId="+Number(_this.ownerId)).then(res=> {
          if(res.data.code == 200){
            titleList = res.data.data
            _this.orderByList = res.data.data
            _this.titleList = []
            if(res.data.data != null){
              titleList.forEach((val,i)=>{
                _this.titleList.push({'name':val.name,'prop':val.columnNameLink}) 
              })
            }
            
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //下载否定词模板
      downLoadExcel(){
        window.location.href="https://oppo-ad.oss-cn-beijing.aliyuncs.com/asakeyword/%E8%AE%A1%E5%88%92%E5%90%A6%E5%AE%9A%E5%85%B3%E9%94%AE%E8%AF%8D.xlsx"
      }


    }
  }
  </script>
  
  <style lang="scss" scoped>
    
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */

     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
        margin-right: 15px;
        font-size: 13px;
        color: #606266;
      }
      .allOperation .totalNum i{
          font-style: initial;
        }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */

    /* 操作按钮样式开始 */
    /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
      display: inline-block;
      margin: 0 10px;
    } */
    /* 操作按钮样式结束 */
    .MBOTTOM{
      margin-bottom:10px;
    }
    .el-button--small{
      margin-left: 10px;
    }
    .line.el-col.el-col-2{
      text-align: center;
    }
    .isSwitch{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background-color: #ccc;
      vertical-align:middle;
      margin-left: 5px;
    }
    .active{
      background-color: #13ce66;
    }
    #uploadBtn{
      float: right;
    }
    /* .el-button.el-button--primary{
      margin: 0 10px 0 0!important;
    } */
    .el-table .el-table__row {
      cursor: pointer!important;
    }
    .copyInput{
      width:120px;
      margin:0 10px;
    }
    ::v-deep .copyInput .el-input__inner{
      border-radius: 30px;
    }
    .copyPlanLength{
      width:300px;
    }
    ::v-deep .required .el-form-item__label:before{
      content: '*';
      color: #FF4949;
      margin-right: 4px;
    }
    ::v-deep .transferBox .el-transfer-panel{
      width: 260px !important;;
    }
    .priceStyle{
      display: block;
      margin:10px 0;
    }
    /* oppo和vivo的tab切换 */
    .chanelTab{
      margin-bottom:20px;
      width:100%;
      border-bottom:1px solid #F1F7F5;
      font-size:14px;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:50px;
      line-height:50px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
      color:#333;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#48916F;
      border-bottom:2px solid #48916F;
    }
    .detail{
      color:#409eff;
    }
    .loading{
      text-align:center;
    }
    .loading span,.loading i{
      display: block;
    }
    .loading span{
      font-size:20px;
      font-weight:700;
      color:#fff;
    }
    .loading i{
      margin-top:10px;
      font-size:60px;
      /* color: #409eff; */
      color:#fff;
    }
    /* .loadingBg{
      margin-top:200px;
    } */
    ::v-deep .loadingBg .el-dialog{
      margin-top:200px;
      background:rgba(255,255,255,0)!important;
      box-shadow: none;
    }
    .listDetail span{
      display: inline-block;
      vertical-align:middle;
      font-size:12px;
      color:#666;
      margin-left:10px;
    }
    .reasonsList{
      width:240px;
      padding:10px;
      border:1px solid #ddd;
      border-radius: 10px;
      background-color: #fff;
      font-size:12px;
      color:#999;
      position: absolute;
    }
    /*点击名称进入下一个列表*/
    .ownerNameColor{
      color:#2F674D;
      font-weight:500;
    }
    /*预警-新*/
    .newAddfpStyle{
      position: relative;
    }
    .newReduceBtnStyle{
      position:absolute;
      top:50%;
      right:0;
      transform: translate(0,-50%);
    }
    .warnInfosList span i{
      font-style: normal;
      font-weight:500;
    }
    /*新版*/
    .queryLeft,.queryRight{
      display: inline-block;
      vertical-align: middle;
    }
    .queryLeft{
      width:65%;
    }
    .queryRight{
      width:35%;
      text-align:right;
    }
    ::v-deep .dateStyle.el-range-editor.el-input__inner{
      width:240px;
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .dateStyle.el-range-editor .el-range-input{
      background:#F5F7F6!important;
    }
    ::v-deep .queryLeft .el-input--mini .el-input__inner{
      width:160px;
      border:none;
      background:#F5F7F6;
    }
    ::v-deep .materialHead .el-form-item--mini.el-form-item{
      margin-bottom:0!important;
    }
    .materialHead{
      padding-bottom:20px;
    }
    .queryRight span{
      padding:0 10px;
      border: 1px solid #EBEBEB;
      border-radius:4px;
    }
    .queryRight span i{
      font-style:normal;
      color:#999;
    }
    /*新版*/
    ::v-deep .distribDialog .el-dialog{
      border-radius:20px;
    }
    .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    ::v-deep .el-dialog .el-dialog__header{
      padding-top:45px;
      font-weight:500;
    }
    ::v-deep .el-dialog__header.el-dialog__title{
      font-size:18px;
      font-weight:500;
    }
    ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
      padding:0 40px;
    }
    ::v-deep .distribDialog .el-form-item--mini.el-form-item{
      width: 100%;
      padding:7px 0;
      background: #F5F7F6;
      border-radius: 4px;
      margin-bottom:10px;
    }
    ::v-deep .el-dialog--center .el-dialog__footer{
      padding:0 16px;
    }
    ::v-deep .distribDialog .el-input--mini .el-input__inner{
      width:240px;
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .distribDialog .el-input__suffix{
      right:65px;
    }
    ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
      color:#333;
    }
    /*新预警*/
    ::v-deep .el-dialog--center .el-dialog__body{
      padding:0 40px!important;
      margin:0 0 30px;
    }
    .warnInfosList{
      color:#2F674D;
      text-align:left;
      padding-left:20px
    }
    .warnInfosList span{
      font-weight:500;
      padding-right:5px;
    }
    .warnningForm .el-form-item--mini.el-form-item{
      margin-bottom:10px;
      width:100%;
      background: #F5F7F6;
      border-radius: 4px;
      padding:7px 0;
    }
    ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
      width:420px;
    }
    ::v-deep .observationTarget{
      margin-bottom:-10px!important;
      padding-bottom:10px!important;
    }
    .deleteBtn{
      position: absolute;
      top:-10px;
      right:-10px;
      cursor: pointer;
    }
    .deleteBtnicon{
      color:#f12323;
      font-size:20px;
    }
    .addIputs{
      padding:5px;
      border:1px dashed #DCEEE8;
      border-radius:4px;
      margin-bottom:10px;
    }
    .addIputs .el-form-item--mini.el-form-item{
      margin-bottom:0!important;
    }
    .addConditions{
      width:100%;
      height: 27px;
      line-height:27px;
      text-align:center;
      border-radius: 4px;
      border: 1px dashed #DCEEE8;
      font-size:12px;
      color:#48916F;
      margin-bottom:15px;
      cursor: pointer;
    }
    .other{
      display:block;
      color:#999;
      font-size:12px;
    }
    .otherItem{
      width:48%!important;
      margin-right:20px;
      display: inline-block;
      vertical-align: middle;
    }
    .otherItemRight{
      margin-right:0;
    }
    ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
      width:160px!important;
    }
    ::v-deep .otherItemradio .el-radio__label{
      font-size:12px!important;
    }
  </style>
