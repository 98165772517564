<template>
  <el-dialog
    :visible.sync="show"
    width="1000px"
    :showClose="false"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog timeStyle"
    :title="title+'模板'"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="名称" prop="name">
        <el-input v-model="ruleForm.name" style="width:40%" :placeholder="fileList.reportName?fileList.reportName:'请输入名称'"></el-input>
      </el-form-item>
      <el-form-item label="文件名" prop="excelName">
        <el-input v-model="ruleForm.excelName" style="width:40%" clearable :placeholder="fileList.title?fileList.title:'请输入文件名'" oninput="value=value.replace(^[^\\\/\.]+$,'')" @clear='handleClear'></el-input>
        <span class="addWord" v-for="(item,i) in cibaoList" :key='i' @click="addWordsFn(item)">[{{item}}]</span>
      </el-form-item>
      <!-- 数据源 -->
      <div v-if="dataSourceIs" class="chuansuo">
        <!-- <el-form-item label="报表维度" prop="adType">
          <el-select v-model="ruleForm.adType" style="width:40%" placeholder="请选择报表维度">
            <el-option label="账户" value="1"></el-option>
            <el-option label="广告系列" value="2"></el-option>
            <el-option label="广告组" value="3"></el-option>
          </el-select>
        </el-form-item> -->
        <span>数据源sheet</span>
        <div v-for="(item,i) in dataSource" :key = i>
          <div style="display: flex">
            <el-form-item label="报表维度" prop="adType" style="width:47.4%">
              <el-select v-model="item.adType" placeholder="请选择报表维度" style="width:100%">
                <el-option label="账户" :value="1"></el-option>
                <el-option label="广告系列" :value="2"></el-option>
                <el-option label="广告组" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="80px" label="时区" prop="timeZone" style="width:43.2%">
              <el-select v-model="item.timeZone" placeholder="请选择时区" :disabled="type == 2 ? true : false" style="width:100%">
                <el-option label="Asia" value="Asia"></el-option>
                <el-option label="UTC" value="UTC"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-form-item  prop="sourceName">
              <el-collapse-item :title="item.sheetName" :name="i">
                <div class="addBtn">
                  <div class="addChecked">
                    <el-button type="primary" size="small" @click="addBtnIndex(item.sheetName,i)">批量生成</el-button>
                  </div>
                  <el-dialog
                    width="300px"
                    ref="addButton"
                    :title="btnTitle"
                    :visible.sync="innerVisible"
                    data-type="btns"
                    append-to-body>
                    <span class="wordsTip">每个ID之间用<i>英文逗号</i>分割，不要有空格，填写在下方输入框中即可</span>
                    <div class="textArea">
                      {{ addTextIds }}
                      <el-input type="textarea" v-model="addTextIds" class="textAreaInput"></el-input>
                    </div>
                    <div class="twoBtns">
                      <el-button type="primary" size="mini" @click="determine(indexNum)">确 定</el-button>
                      <el-button type="primary" size="mini" @click="cancel">取 消</el-button>
                    </div>
                  </el-dialog>
                  <el-dialog
                    width="300px"
                    :visible.sync="innerTip"
                    title="错误ID"
                    append-to-body>
                    <div class="innerFalse">查询到<i>[{{listTip}}]</i>是错误的账户ID，请重新检查账户ID之后再次输入</div>
                    <div class="twoBtns">
                      <el-button type="primary" size="mini" @click="innerTip = false">确 定</el-button>
                    </div>
                  </el-dialog>
                </div>
              <div class="transferBox">
                <el-transfer
                ref="transfer"
                  style="text-align: left; display: inline-block"
                  v-model="ruleForm.sourceName[i]"
                  filterable
                  :render-content="renderFunc"
                  :titles="['未选中', '已选中']"
                  @mouseover.native="addTitle"
                  :data="tableAllData"
                  :format="{
                    noChecked: '${total}',
                    hasChecked: '${checked}/${total}'
                  }"
                  @change="changeFn($event,i,'data')">
                </el-transfer>
              </div>
            </el-collapse-item>
            </el-form-item>
          </el-collapse>
        </div>
     </div>
      <!-- <div v-if="dataSourceIs">
        <el-form-item label="报表维度" prop="adType">
          <el-select disabled v-model="ruleForm.adType" style="width:40%" placeholder="请选择报表维度">
            <el-option label="账户" value="1"></el-option>
            <el-option label="广告系列" value="2"></el-option>
            <el-option label="广告组" value="3"></el-option>
          </el-select>
        </el-form-item>

        <span>数据源sheet</span>
        <el-form-item  v-for="(item,i) in dataSource" :key = i :label="item.sheetName">
          <span v-for="(item1,i) in item.owners" :key="i">{{item1.ownerName}}</span>
        </el-form-item>
      </div> -->

      <div v-if="keywordsIs">
        <span>关键词sheet</span>
          <el-form-item v-for="(item,i) in keywords" :key = i prop="KeyName">
            <span class="sourceClass"><i class="redTip">*</i>{{item.sheetName}}</span>
            <el-select
              v-model="ruleForm.KeyName[i]"
              value-key = "key"
              style="width:40%" 
              multiple 
              placeholder="请选择账户" 
              filterable 
              @change="changeFn($event,i,'keyword')">
              <el-option 
                :label="item1.label"
                :value="item1" 
                v-for="(item1,j) in tableAllData" 
                :key=j></el-option>
            </el-select>
          </el-form-item>
      </div>
      
      
      <el-form-item label="接收人" prop="sendUser">
        <!-- <label>接收人</label> -->
        <el-select v-model="ruleForm.sendUser" value-key ="string" multiple style="width:40%" filterable :placeholder="fileList.sender?fileList.senderName:'请选择接收人'" @change="changeFn($event)">
            <el-option
            v-for="(item,i) in distributionOptions"
            :key="i"
            :label="item.string"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抄送人" prop="copyUser">
        <!-- <label>抄送人</label> -->
        <el-select v-model="ruleForm.copyUser"  value-key ="name" multiple style="width:40%" filterable :placeholder="fileList.copyUserName?fileList.copyUserName:'请选择抄送人'"  @change="changeFn($event)">
          <el-option
            v-for="(item,i) in cc_options"
            :key="i"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
        <!-- <el-button type="text" @click="dialogFormVisible_add = true" style="padding-left:10px;">添加</el-button> -->
        <el-dialog title="添加抄送人" :visible.sync="dialogFormVisible_add" append-to-body :modal-append-to-body="false">
          <el-form :model="addForm">
            <el-form-item label="人员名称" :label-width="formLabelWidth">
              <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入添加人员姓名" style="width:100%!important;"></el-input>
            </el-form-item>
            <el-form-item label="邮箱地址" :label-width="formLabelWidth">
              <el-input v-model="addForm.email" autocomplete="off" placeholder="请输入添加邮箱地址" style="width:100%!important;"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible_add = false">取 消</el-button>
            <el-button type="primary" @click="csrSure">确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>
      <!-- <el-form-item v-if="type==2" label="接收方式" prop="type">
        <el-checkbox-group v-model="ruleForm.type">
          <el-checkbox label="Excel" name="type"></el-checkbox>
          <el-checkbox label="图片" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="type==2" label="是否拉取零点数据" prop="resource">
        <el-radio-group disabled v-model="ruleForm.resource">
          <el-radio label="1" value="1">是</el-radio>
          <el-radio label="0" value="0">否</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item> -->
      <!-- <div v-if="type == 2"  class="sendTIme">
        <span class="sendTimeTitle"><span class="redContent">*</span><span>接收时间：</span></span>
        <span>{{fileList.time}}</span>
      </div> -->
      <el-form-item v-if="type == 2" label="时报时间点" prop="timeSlot">
        <el-select v-model="ruleForm.timeSlot" multiple filterable style="width:40%" placeholder="请选择时报时间点">
          <el-option
            v-for="(item,i) in timeLimitList"
            :key="i"
            :label="item.time"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- <dayTimeUnit v-if="type == 2"></dayTimeUnit> -->
     
      <!-- <div  v-if="type == 2"> 
        <span>是否拉取零时数据:</span> <span>{{needList[fileList.isNeedYesterday-1]}}</span>
      </div> -->
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('ruleForm')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
      <addCopy :visible.sync="addCopyIs"></addCopy>

  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import addCopy from './addCopyDialog';
import getUserList from '@/utils/getUserList'//获取用户列表
import TreeVue from '../../components/Tree.vue';
export default {
  
  components:{
    addCopy
  },
  props: {
    title:String,
    visible: {
      type: Boolean,
      default: false
    },
    type:Number,
    path:String,
    pubVue:Object,
  },
  data() {
    return {
      // 数据源
      renderFunc(h, option) {
        return <span>{ option.key } - { option.label }</span>;
      },
      show: false,
      loading: false,
      dataSourceIs:false,
      keywordsIs:false,
      addCopyIs:false,
      dataSource:[],
      dataSourceName:'',
      keywords:[],
      needList:['否','是'],
      ruleForm: {
        name: '',
        excelName:'',
        adType: '',
        type: [],
        resource: '',
        sourceName:[],
        KeyName:[],
        sendUser:[],
        copyUser:[],
        timeSlot:[]
      },
      addForm: {
          name: '',
          email:''
        },
      formLabelWidth: '120px',
      dialogFormVisible_add: false,
      fileList:{},
      tableDataList:[],
      cc_options:[],
      value1:[],
      reportId:'',
      distributionOptions:[],
      rules: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        excelName:[
          { required: true, message: '请输入发送报表名称', trigger: 'blur' },
        ],
        // adType: [
        //   { required: true, message: '请选择报表维度', trigger: 'change' }
        // ],
        // type: [
        //   { type: 'array', required: true, message: '请至少选择一个接收方式', trigger: 'change' }
        // ],
        // resource: [
        //   { required: true, message: '请选择是或者否', trigger: 'change' }
        // ],
        sendUser:[{ required: true, message: '请选择接收人', trigger: 'change'}],
        sourceName: [{ required: true, message: '请选择账户', trigger: 'change' }],
        KeyName:[{ required: true, message: '请选择账户', trigger: 'change'}],
        timeSlot:[{ type: 'array', required: true, message: '请选择时报时间点', trigger: 'change' }]
      },
      // cibaoList:['代理公司','渠道','报表类型','日期','时段'],
      cibaoList:['日期','时段','编号'],
      wordsA : [],
      warnEigth:[],
      warnNine:[],
      keywordsName:'',
      tableid:'',
      activeNames: [0, 1, 2],
      btnTitle:'',
      addTextIds:'',
      indexNum:'',
      listTip:'',
      innerTip:false,
      tableAllData:[],
      sourceNames:[],
      tableDataList:[],
      innerVisible:false,
timeLimitList:[{'time':'10:00','id':'10'},{'time':'10:30','id':'10.5'},{'time':'11:00','id':'11'},{'time':'11:30','id':'11.5'},
      {'time':'12:00','id':'12'},{'time':'12:30','id':'12.5'},{'time':'13:00','id':'13'},{'time':'13:30','id':'13.5'},{'time':'14:00','id':'14'},{'time':'14:30','id':'14.5'},{'time':'15:00','id':'15'},{'time':'15:30','id':'15.5'},
      {'time':'16:00','id':'16'},{'time':'16:30','id':'16.5'},{'time':'17:00','id':'17'},{'time':'17:30','id':'17.5'},{'time':'18:00','id':'18'},{'time':'18:30','id':'18.5'},{'time':'19:00','id':'19'},{'time':'19:30','id':'19.5'},
      {'time':'20:00','id':'20'},{'time':'20:30','id':'20.5'},{'time':'21:00','id':'21'},{'time':'21:30','id':'21.5'},{'time':'22:00','id':'22'},{'time':'22:30','id':'22.5'},{'time':'23:00','id':'23'},{'time':'23:30','id':'23.5'}],
      
    };
     
  },

  watch: {
    show (v){
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted(){
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    this.cc_options = this.$sessionStorage.getItem('optionSelectCopyUser')
    this.getList()
    console.log('账户')
    getUserList().then(res => {
      this.cc_options = res
    })
    this.getFpyjList328()
    // this.getFpyjList329()
    // this.getFpList()
  },
  methods: {
    //数据源
    dealData() {
      const data = this.tableDataList
      data.forEach((obj,i)=>{
        this.tableAllData.push({
          key:obj.ownerId,
          label:obj.ownerName,
        })
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onConfirm(formName) {
      let _this = this
        _this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('成功2')
            console.log('选中')
            console.log(_this.ruleForm)
              _this.modifyModelFn(_this.dayLimit)
          } else {
            console.log('error submit!!');
            return false;
          }
          
          
        });
    },

//数据源开始
handleChange(val){
  console.log(val)
},

// 添加选中的id
addBtnIndex(name,i){
  let ownersList = [],event = [],addTextIds = []
  this.btnTitle = name 
  this.indexNum = i
  this.addTextIds = this.ruleForm.sourceName[i].toString()
  this.innerVisible = true
},

//确定添加选中的id到选框中
determine(i){
  let sourceNames = [],ownersList = [],addTextIds = [],list= []
  this.addTextIds = this.addTextIds.toString()
  sourceNames = this.addTextIds.split(',')
  this.ruleForm.sourceName[i] = sourceNames   
  this.tableAllData.forEach(obj=>{   
    sourceNames.forEach((val,index)=>{
      if(val == obj.key){
        ownersList.push({"ownerId":obj.key,"ownerName":obj.label})
        addTextIds.push(obj.key)
        list = sourceNames.filter(item=>{
          return addTextIds.indexOf(item) === -1
        })
      }
    })
  })

  sourceNames = sourceNames.filter(item => !list.includes(item))
  this.addTextIds = sourceNames.toString()
  this.dataSource[i].owners = ownersList
  this.addTextIds = JSON.stringify(sourceNames)
  this.sourceNames = sourceNames
  this.innerVisible = false
  this.addTextIds = ''
  this.listTip = list.toString()
  if(list.length != 0){
    this.innerTip = true
  }
  
  this.$message({
    message: '账户ID中'+list.toString()+'错误',
    type: 'warning'
  })
},

//取消添加选中弹框
cancel(){
  this.innerVisible = false
  this.addTextIds = ''
},

addTitle(e) {
  const target = e.target
  if (target.title) return
  target.title = target.innerText
},



    //添加抄送人
    csrSure(){
      let that = this
      that.dialogFormVisible_add = false
      // that.csrParams.accountId = '71754670438'
      // that.csrParams.accountId = String(that.userDetailId)
      // that.csrParams.email = that.addForm.email
      // that.csrParams.userName = that.addForm.name
      let userName = that.addForm.name
      let email = that.addForm.email
      $http.axios.get("/api/system/user/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
        if(response.data.code == 200){
          getUserList().then(res => {
            that.cc_options = res
          })
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    onCancel() {
      this.show= false;
      this.$refs.ruleForm.resetFields()
      this.pubVue.listModelFn()

      console.log('关闭')
    },
    addOrSetFn(type){
      
    },
    handleClear(){
      this.ruleForm.excelName = ''
      this.wordsA = []

    },
    
    //user的channel渠道接口
    getuserChannel(id){
      console.log(id)
      this.tableid = id
      this.getFpList()
      let _this = this,senderName =[],time = [],sendUser =[],copyUserName=[],copyUser=[],type1='',type2=''
      _this.reportId = id
      $http.axios.get("/api/dataReport/reportModel/queryById?id="+id).then(function(res) {
        if(res.data.code == 200)
        _this.fileList = res.data.data
        _this.ruleForm.timeSlot = _this.fileList.time ? _this.fileList.time.split(',') : []
        _this.ruleForm.name= _this.fileList.name//模板名称
        _this.ruleForm.excelName=_this.fileList.title//报表名称
        //报表维度
        if(res.data.data.excelConfig.dataSource.length!=0&&res.data.data.excelConfig.kwDataSource.length==0){
          _this.ruleForm.adType = res.data.data.excelConfig.dataSource[0].adType.toString()
        }
        if(res.data.data.excelConfig.dataSource.length==0&&res.data.data.excelConfig.kwDataSource.length!=0){
          // _this.ruleForm.adType = res.data.data.excelConfig.kwDataSource[0].adType.toString()
          _this.ruleForm.adType = res.data.data.excelConfig.kwDataSource[0].adType.toString()
        }

        _this.dataSource = _this.fileList.excelConfig.dataSource
        _this.keywords = _this.fileList.excelConfig.kwDataSource
        
        if(_this.dataSource.length>0){
          _this.dataSourceIs = true
          _this.dataSource.forEach((element,i) => {
          _this.$nextTick(() => {
            console.log(_this.$refs.transfer)
            _this.$refs.transfer[i].$children["0"].checked = []
          })
            _this.ruleForm.sourceName[i] = []
            if (element.owners) {
            element.owners.forEach((elements,j) => {
              _this.ruleForm.sourceName[i].push(elements.ownerId)
            })
          }
          });
        }
        if(_this.keywords.length>0){
          _this.keywordsIs = true
          _this.keywords.forEach((element,i) => {
            _this.ruleForm.KeyName[i] = []
            if (element.owners) {
            element.owners.forEach((elements,j) => {
              _this.ruleForm.KeyName[i].push({key: elements.ownerId, label: elements.ownerName})
            })
          }
          });
        }
        type1 = _this.fileList.excelFlag==1?'Excel':''
        type2 = _this.fileList.imgFlag==1?'图片':''
        if(type1!=''){
          _this.ruleForm.type.push(type1)
        }
        if(type2!=''){
          _this.ruleForm.type.push(type2)
        }
        _this.ruleForm.resource= _this.fileList.zeroFlag.toString()
        sendUser=_this.fileList.sender.split(',')
        senderName=_this.fileList.senderName.split(',')
        if(_this.fileList.copySender)
        {
          copyUser=_this.fileList.copySender.split(',')
        }
        if(_this.fileList.copySenderName)
        {
          copyUserName=_this.fileList.copySenderName.split(',')
        }
        sendUser.forEach((obj,i)=>{
          _this.ruleForm.sendUser.push({'string':senderName[i],'userId':obj})
        })
        copyUser.forEach((obj,i)=>{
          _this.ruleForm.copyUser.push({'id':obj, 'name': copyUserName[i]})
        })
      })
    },
    

    changeFn(event,i,type){
      let _this = this,ownersList = [],dataSourceList = {},sheetName = ''
      console.log(event,i)
      console.log(this.dataSource)
      console.log(this.keywords)
     
      if(type == "data"&&event.length != 0){
        //数据源
        _this.tableAllData.forEach(obj=>{
          event.forEach(val=>{
            if(val == obj.key){
              console.log(val)
              ownersList.push(
                // {"ownerId":obj.key,"ownerName":obj.label}
                {"ownerId":obj.key,"ownerName":obj.label},
              )
            }
          })
        })
        this.dataSource[i].owners = ownersList
        // this.dataSource[i].adType = Number(_this.ruleForm.adType)

      }else if(type == "keyword"&&event.length != 0){
        console.log(event)
        event.forEach(obj=>{
          ownersList.push({"ownerId":obj.key,"ownerName":obj.label})
        })
        this.keywords[i].owners = ownersList
        // this.keywords[i].adType = Number(_this.ruleForm.adType)
        this.keywords[i].adType = 6
      }

      console.log(this.dataSource)
      console.log(this.keywords)
    },

    //获取分配人员列表
    getFpyjList328(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
        if(response.data.code == 200){
          that.warnEigth = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    getFpyjList329(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
        if(response.data.code == 200){
          that.warnNine = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取分配人员列表
    getFpList(){
      // let that = this
      this.getFpyjList328()
      // this.getFpyjList329()
      this.distributionOptions = this.warnEigth.concat(this.warnNine)
      console.log(this.distributionOptions)
    },
    
    // //获取菜单列表
    getList() {
      let _this = this,params = {}
      params={
        'pageIndex' : _this.page|| 1,
        'pageSize':1000,
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
      }
      $http.axios.post("/api/apple/account/list",params).then(res=> {
        if(res.data.code==200){
          _this.tableDataList = res.data.data.list
          _this.dealData()
          // _this.totalRow = res.data.data.pageInfo.rowCount
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
  
    modifyModelFn(){
      let _this = this,params = {},excelSwitch = 0,imgSwtich = 0,sendUser = {"string":[],"id":[]},copyUser = {"string":[],"id":[]}
      let times = []

      times = _this.ruleForm.timeSlot
      if(_this.ruleForm.type.length>0){
        if(_this.ruleForm.type[0]=="图片"){
          imgSwtich = 1
        } else if(_this.ruleForm.type[0]=="Excel"){
          excelSwitch = 1
        }
        if(_this.ruleForm.type.length == 2){
          excelSwitch = 1
          imgSwtich = 1
        }
      }
      _this.ruleForm.sendUser.forEach(obj=>{
        sendUser.string.push(obj.string)
        sendUser.id.push(obj.userId)
      })
      _this.ruleForm.copyUser.forEach(obj=>{
        copyUser.string.push(obj.name)
        copyUser.id.push(obj.id)
      })
      params = {
        "userId": _this.userId,
        "deptId": _this.deptId,
        "userName":_this.userName,
        "channelId":8,
        "type": _this.type,//报表类型
        "name": _this.ruleForm.name,//模板名称
        "title": _this.ruleForm.excelName,//报表名称，词包
        "path": _this.path,//文件路径
        "excelConfig": {
          "dataSource": _this.dataSource,
          "kwDataSource": _this.keywords
        },
        "sender": sendUser.id.toString(),
        "senderName": sendUser.string.toString(),
        "time": String(times),
        // "excelFlag": excelSwitch,
        // "imgFlag": imgSwtich,
        "copySender": copyUser.id.toString(),
        "copySenderName": copyUser.string.toString(),
        // "zeroFlag": String(_this.fileList.zeroFlag),
        "id":_this.tableid
      }
      $http.axios.post("/api/dataReport/reportModel/edit",params).then(res=> {
        if(res.data.code == 200){
          // _this.tableData = res.data.data.items
          // _this.totalRow = res.data.data.totalCount
          _this.onCancel()
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    addWordsFn(val){
      let _this = this,wordsA = []
      if(_this.wordsA.length!= 0){
        _this.wordsA.forEach((obj,i,arr)=>{
          console.log(obj)
          if(val == obj){
            arr.splice(i,1)
            
          }
        })
        _this.wordsA.push(val)
        wordsA = _this.wordsA
        _this.ruleForm.excelName +='{'+val+'}'
      }else{
        console.log(val)
        _this.ruleForm.excelName +='{'+val+'}'
        _this.wordsA.push(val)
      }
      
      
    },
    
    
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
.addWord{
  cursor: pointer;
  display: inline-block;
  margin: 5px 8px;
  font-size: 12px;
  color: #ccc;
}
.redTip{
      color: #F56C6C;
    margin-right: 4px;

}
.el-form-item__content span.sourceClass{
  font-size: 12px!important;
    margin-left: -130px;
    margin-right: 10px;
    width: 120px;
    display: inline-block;
    text-align: right;
}
/*数据源*/
::v-deep .transferBox .el-transfer-panel{
  width: 240px !important;;
}
.chuansuo .el-collapse{
  border:none;
}
::v-deep .chuansuo .el-collapse-item__header{
  color:#F56C6C;
  margin-left:-60px;
}
::v-deep .chuansuo .el-icon-arrow-right:before{
  color:#666;
}
/* .el-collapse-item{
  position:relative
} */
.addChecked{
  position: absolute;
  text-align:right;
  top:9px;
  right:50px;
}
.twoBtns{
  text-align:center;
  margin-top:30px;
}
::v-deep .el-popup-parent--hidden .el-dialog__body{
  margin:0!important;
}
.el-tree-node__label{
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wordsTip{
  display: block;
  margin-bottom:10px;
  font-size:12px;
}
.wordsTip i{
  font-style: normal;
  color:red;
}
::v-deep .textAreaInput .el-textarea__inner{
  height:100px;
}
.innerFalse{
  text-align:center;
}
.innerFalse i{
  font-style: normal;
  color:red;
}
::v-deep .el-dialog{
  height:700px!important;
  overflow: scroll!important;
}
.sendTIme{
  font-size: 12px;
}
.sendTimeTitle{
  display: inline-block;
  width: 120px;
  text-align: right;
}
.redContent{
    color: #F56C6C;
    margin-right: 4px;
}
::v-deep .timeStyle .el-dialog .el-dialog__body{
  height:600px!important;
  overflow: scroll!important;
}

</style>