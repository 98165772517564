import AccountSelectionHw from '@/views/auth/planManage/accountSelectionHw';//账户选择
import AccountListHw from '@/views/auth/homePage/AccountListHw';//账户列表
import UserDetailHw from '@/views/auth/homePage/UserDetailHw';//用户列表详情
import TimesHw from '@/views/auth/operateManage/dailyOrtimes/TimesHw';//时报
import DailyHw from '@/views/auth/operateManage/dailyOrtimes/DailyHw';//日报
// import ConsumptionSumHw from '@/views/auth/operateManage/ConsumptionSumHw';//素材消耗
import PriceModifyHw from '@/views/auth/planManage/modifyPrice/PriceModifyHw';//调价-账户

import PriceModifyPlanHw from '@/views/auth/planManage/modifyPrice/PriceModifyPlanHw';//调价-计划
import PriceModifyPlanBudgetHw from '@/views/auth/planManage/modifyPrice/PriceModifyPlanBudgetHw';//调价-计划
import PriceModifyAdvertHw from '@/views/auth/planManage/modifyPrice/PriceModifyAdvertHw';//调价-创意
import PriceModifyKeyAdvertHw from '@/views/auth/planManage/modifyPrice/PriceModifyKeyAdvertHw';//调价-创意
import AdPlanHw from '@/views/auth/planManage/adPlanHw';//任务
import AdTeamHw from '@/views/auth/planManage/adTeamHw';//子任务
import AdvertHw from '@/views/auth/planManage/advertHw';//创意
import StartStopHw from '@/views/auth/planManage/modifyPrice/StartStopHw';//启停-计划
import StartStopGroupHw from '@/views/auth/planManage/modifyPrice/StartStopGroupHw';//启停-广告组
// import UploadPageHw from '@/views/auth/planManage/uploadPageHw';//上传记录页



export default {
  routes: [
    {
      path: 'accountSelectionHw',
      name: 'HwAccountManagement.AccountSelectionHw',
      component: AccountSelectionHw
    },
    {
      path: 'accountListHw',
      name: 'HwAccountManagement.AccountListHw',
      component: AccountListHw
    },
    // { 
    //   path: 'consumptionSumHw',
    //   name: 'HwAccountManagement.ConsumptionSumHw',
    //   component: ConsumptionSumHw
    // },
    { 
      path: 'userDetailHw/:userId',
      name: 'HwAccountManagement.UserDetailHw',
      component: UserDetailHw
    },
    {
      path: 'timesHw',
      name: 'HwAccountManagement.TimesHw',
      component: TimesHw
    },
    
    {
      path: 'dailyHw',
      name: 'HwAccountManagement.DailyHw',
      component: DailyHw
    },
    
    {
      path: 'priceModifyHw',
      name: 'HwAccountManagement.PriceModifyHw',
      component: PriceModifyHw
    },
    {
      path: 'priceModifyPlanHw',
      name: 'HwAccountManagement.PriceModifyPlanHw',
      component: PriceModifyPlanHw
    },
    {
      path: 'priceModifyAdvertHw',
      name: 'HwAccountManagement.PriceModifyAdvertHw',
      component: PriceModifyAdvertHw
    },
    {
      path: 'priceModifyPlanBudgetHw',
      name: 'HwAccountManagement.PriceModifyPlanBudgetHw',
      component: PriceModifyPlanBudgetHw
    },
    {
      path: 'priceModifyKeyAdvertHw',
      name: 'HwAccountManagement.PriceModifyKeyAdvertHw',
      component: PriceModifyKeyAdvertHw
    },
    {
      path: 'adPlanHw',//任务
      name: 'HwAccountManagement.AdPlanHw',
      component: AdPlanHw
    },
    {
      path: 'adTeamHw',//子任务
      name: 'HwAccountManagement.AdTeamHw',
      component: AdTeamHw
    },
    {
      path: 'advertHw',//创意
      name: 'HwAccountManagement.AdvertHw',
      component: AdvertHw
    },
    {
      path: 'startStopHw',
      name: 'HwAccountManagement.StartStopHw',
      component: StartStopHw
    },
    {
      path: 'startStopGroupHw',
      name: 'HwAccountManagement.StartStopGroupHw',
      component: StartStopGroupHw
    },
    // {
    //   path: 'uploadPageHw',
    //   name: 'HwAccountManagement.UploadPageHw',
    //   component: UploadPageHw
    // },
  ]
  };