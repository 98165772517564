<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item>计划管理</el-breadcrumb-item>
                <el-breadcrumb-item  :to="{ path: '/yybAccount-management/AccountSelectionYyb' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>调价展示</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>  
          <ul class="modifyTab">
            <li class="modifyActive">广告</li>
          </ul>
            <div class="channelFlowHead">
                <el-form :inline="true" :model="priceForm" ref="priceForm" class="demo-form-inline">
                    <el-form-item label="" prop="adGroup">
                        <el-input v-model="priceForm.adGroupId" placeholder="搜索广告ID"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" class="newQuery" size="small" @click="priceModifyList">查询</el-button>
                      <el-button type="info" size="small" @click="resetFormPriceForm">重置</el-button>
                    </el-form-item>
                </el-form> 
            </div>         
            <div class="channelFlowBody">
                <div class="channelTable">
                    <el-table
                      :data="tableData"
                      border
                      style="width: 100%">
                      <el-table-column
                      type="selection"
                      width="50">
                      </el-table-column>
                      <el-table-column
                        prop="adId"
                        label="广告ID"
                        width="200">
                      </el-table-column>
                      <el-table-column
                        prop="adName"
                        label="广告名称">
                      </el-table-column>
                      <el-table-column
                        prop=""
                        label="调价时间段">
                        <template slot-scope="scope">
                          <span class="priceTimes" @click="timeSlot(scope.row)">{{(scope.row.planIds).split(",").length}}个时间段</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="operation"
                        label="操作">
                        <template>
                          <el-button class="solid-tag-success" plain size="mini" @click="jumpAdvertisingGroup">广告</el-button>
                          <el-button class="solid-tag-harmful" plain size="mini" type="text" @click="ssList">启停</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- 时间段弹框 -->
                    <el-dialog title="" :visible.sync="dialogTableVisible" width="100%">
                      <el-form :inline="true" :model="timeForm" ref="timeForm" class="demo-form-inline">
                          <el-form-item label="" prop="tan1">
                              <el-select v-model="timeForm.tan1" placeholder="请选择执行状态" filterable clearable @change="changeStatus">
                                  <el-option
                                    v-for="(item,i) in implementOptions"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.id">
                                  </el-option>
                                </el-select>
                          </el-form-item>
                          <el-form-item label="" prop="tan2">
                              <el-date-picker
                                  v-model="timeForm.tan2"
                                  type="daterange"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                              </el-date-picker>
                          </el-form-item>
                          <el-form-item>
                            <el-button type="primary" class="newQuery" size="small" @click="getTimeSlotDetail">查询</el-button>
                            <el-button type="info" size="small" @click="resetForm('timeForm')">重置</el-button>
                          </el-form-item>
                      </el-form> 
                      <el-table :data="gridData" ref="multipleTable">
                        <el-table-column
                          type="selection"
                          width="50">
                        </el-table-column>
                        <el-table-column
                          property="dateRange"
                          label="调价日期"
                          width="200">
                        </el-table-column>
                        <el-table-column
                          property="time"
                          label="调价时间段"
                          width="150">
                          <template slot-scope="scope">
                            <div v-for="(time,i) in scope.row.details" :key="i">
                              <span>{{time.time}}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          property="executeTodayFlag"
                          label="当天是否继续执行"
                          width="150">
                        </el-table-column>
                        <el-table-column
                          property="priceStatus"
                          label="目标金额"
                          width="100">
                          <template slot-scope="scope">
                            <div v-for="(time,i) in scope.row.details" :key="i">
                              <span>{{time.price}}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          property="implementStatus"
                          label="执行状态"
                          width="150">
                          <template slot-scope="scope">
                            <span v-if="scope.row.status == 1">待执行</span>
                            <span v-if="scope.row.status == 2">执行中</span>
                            <span v-if="scope.row.status == 3">执行完成</span>
                            <span v-if="scope.row.status == 4">异常</span>
                            <span v-if="scope.row.status == 5">弃用</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          property="operation"
                          label="操作">
                          <template slot-scope="scope">
                            <div class="opBtn">
                              <span v-if="scope.row.status == 1"></span>
                              <span v-else></span>
                            </div>
                            <div class="opBtn">
                              <span v-if="scope.row.status == 1||scope.row.status == 2 ">
                                <el-popconfirm
                                  title="确定要弃用吗？"
                                  @confirm="stopUse(scope.row.id)">
                                  <template #reference>
                                    <el-button class="newBtn_garyWhite" plain size="mini" style="margin-left:10px;">弃用</el-button>
                                  </template>
                                </el-popconfirm>
                              </span>
                              <span v-else></span>
                            </div>
                            <div class="opBtn">
                              <span  v-if="scope.row.status == 5">
                                <el-popconfirm
                                  title="您是否确认删除，删除后不可恢复？"
                                  @confirm="delUse(scope.row.id)">
                                  <template #reference>
                                    <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                                  </template>
                                </el-popconfirm>
                              </span>
                            </div>
                          </template>
                        </el-table-column>
                    </el-table>
                    <div class="pageWrap">
                      <div class="pageBatch">
                        <el-button @click="allDelete" size="small">批量删除</el-button>
                      </div>
                      <el-dialog
                        title=""
                        :visible.sync="dialogVisible"
                        append-to-body
                        width="30%"
                        :modal-append-to-body='false'>
                        <span>确定要删除吗</span>
                        <span slot="footer" class="dialog-footer">
                          <el-button @click="delCancel">取 消</el-button>
                          <el-button type="primary" @click="delSure">确 定</el-button>
                        </span>
                      </el-dialog>
                      <div class="paging">
                        <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :current-page="pageNumdetail"
                          :page-sizes="[10, 20, 30, 40]"
                          :page-size="pageSizedetail"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="this.totalRowdetail">
                        </el-pagination>
                      </div>
                    </div>
                  </el-dialog>
                    <!-- 时间段弹框结束 -->
                </div>
                <div class="pageWrap">
                    <div class="pageBatch"></div>
                    <div class="paging">
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.totalRow">
                      </el-pagination>
                    </div>
                </div>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import Qs from 'qs';

  export default {
    name: 'priceModify',
    data() {
      return {
        userId:'',
        userName:'',
        deptId:'',
        showTheForm:true,//表头是否显示全部表单
        priceForm:{
            planName:'',//搜索计划名称
            adGroup:'',//搜索广告组名称
            adGroupStatus:'',//广告组状态  
            modifyType:'',
            adGroupId:'',
            planId:''
        },
        tableData: [],
        pmParams:{
          userId:'',
          userName:'',
          deptId:'',
          pageIndex:'',
          pageSize:''
        },
        // 时间段分页
        pageNumdetail: 1,//当前页
        pageSizedetail: 10,//每页大小
        totalRowdetail: 0,//总条数
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dialogTableVisible:false,//时间段弹框
        gridData: [],//时间段表格数据
        timeForm:{
          tan1:'',
          tan2:'',
          tan3:'',
          tan4:'',
          tan5:'',
          tan6:'',
          tan7:'',
          tan8:'',
          tan9:''
        },//时间段弹框查询form
        tan9:false,
        implementStatus:'',//时间段弹框执行状态
        implementOptions:[{//时间段弹框执行状态选项{
            name:'待执行',
            id:1
        },{
            name:'执行中',
            id:2
        },{
            name:'执行完成',
            id:3
        },{
            name:'异常',
            id:4
        },{
            name:'弃用',
            id:5
        }],
        dateChoice:'',
        batchOperation:'',
        timeSlotParams:{//时间段详情列表入参
          userId:'',
          userName:'',
          deptId:'',
          pageIndex:'',
          pageSize:'',
          planIds:'',
          status:'',
          startDate:'',
          endDate:''
        },
        delSlotParams:{},   
        giveUpParams:{},//弃用子列表
        dialogVisible:false,
        changeNumber:'',//子列表中的切换数值类型
        ownerId:'',//上一级获取的ownerId
        ownerName:'',//上一级获取的ownerName
        modifyTypeOptions:[{
          name:'账户日预算',
          id:1
        },{
          name:'任务日预算',
          id:2
        },{
          name:'任务出价',
          id:3
        },{
          name:'子任务出价',
          id:4
        },{
          name:'搜索词出价',
          id:5
        }],
        giveUpParamsIds:'',
        giveUpParamsType:''

      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.ownerName = this.$route.query.name
      this.priceForm.modifyType = 4
      this.priceModifyList()
    },
    methods: {
      //表头点击向右三角显示
      showList(){
        this.showTheForm = false;
      },
      //表头点击向上三角隐藏
      hiddenList(){
        this.showTheForm = true;
      },

      //跳转到计划调价列表
      priceModifyPlan(){
        this.$router.push({name:'HwAccountManagement.PriceModifyHw',query:{id:this.ownerId,name:this.ownerName}})
      },
      //跳转到广告组调价列表
      priceModifyGroup(num){
        let routList = {2:'HwAccountManagement.PriceModifyPlanBudgetHw',
                        3:'HwAccountManagement.PriceModifyPlanHw',
                        4:'HwAccountManagement.PriceModifyAdvertHw',
                        5:'HwAccountManagement.PriceModifyKeyAdvertHw'}
        this.$router.push({name:routList[num],query:{id:this.ownerId,name:this.ownerName}})
      },

      resetFormPriceForm(){
        this.priceForm.planId = ''
        this.priceForm.adGroupId = ''
        this.$router.go(0)
      },

      //头部表单重置
      resetForm(timeForm) {
        this.$refs[timeForm].resetFields();
        this.timeForm.tan1 = ''
        this.timeForm.tan2 = ''
        this.timeSlotParams.startDate = this.timeForm.tan2[0]
        this.timeSlotParams.endDate = this.timeForm.tan2[1]
        this.timeSlotParams.planId = this.timeForm.tan1
        this.timeSlotParams.status = ''
        this.getTimeSlotDetail()
      },

      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.adPlanName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
          return (restaurant.adGroupName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

     

      //点击广告组跳转页面
      jumpAdvertisingGroup(){
        this.$router.push({path:'/YybAccount-management/advertYyb',query:{id:this.ownerId,name:this.ownerName}})
      },

      //点击启停跳转页面
      ssList(){
        this.$router.push({name:'YybAccountManagement.StartStopYyb',query:{id:this.ownerId,name:this.ownerName}})
      },

      //选择调价方式
      chengemodifyType(val){
        this.pmParams.type = val
      },

      //广告组调价展示列表
      priceModifyList(){
        let that = this
        that.pmParams.userId = that.userId
        that.pmParams.userName = that.userName
        that.pmParams.deptId = that.deptId
        that.pmParams.pageIndex = that.pageNum
        that.pmParams.pageSize = that.pageSize
        that.pmParams.type = 3
        // that.pmParams.adTaskIds = that.priceForm.planId
        that.pmParams.adIds = that.priceForm.adGroupId
        that.pmParams.ownerId = that.ownerId
        
        $http.axios.post("/api/yyb/modifyPrice/list",that.pmParams).then(res=> {
          if(res.data.code == 200){
            that.tableData = res.data.data.infos
            that.totalRow = res.data.data.pageInfo.rowCount
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //点击时间段
      timeSlot(row){
        this.dialogTableVisible = true
        this.timeSlotParams.planIds = row.planIds
        this.getTimeSlotDetail()
      },

      //子列表中选择数值
      changeNum(event){
        let that = this
        that.changeNumber = event
        if(event == 3){
          that.tan9 = true
        }else{
          that.tan9 = false
        }
      },

      changeStatus(val){
        this.timeSlotParams.status = val
      },

      //获取时间段的详情列表
      getTimeSlotDetail(){
        let that = this
        that.timeSlotParams.userId = that.userId
        that.timeSlotParams.userName = that.userName
        that.timeSlotParams.deptId = that.deptId
        that.timeSlotParams.pageIndex = that.pageNumdetail
        that.timeSlotParams.pageSize = that.pageSizedetail
        that.timeSlotParams.startDate = that.timeForm.tan2[0]
        that.timeSlotParams.endDate = that.timeForm.tan2[1]
        $http.axios.post("/api/yyb/modifyPrice/infoList",that.timeSlotParams).then(res=> {
          if(res.data.code == 200){
            that.gridData = res.data.data.infos
            that.totalRowdetail = res.data.data.pageInfo.rowCount
            that.priceModifyList()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //弃用子列表
      stopUse(id){
        let that = this,ids = []
        ids.push(id)
        that.giveUpParamsIds = String(ids)
        that.giveUpParamsType = 2
        $http.axios.get("/api/yyb/modifyPrice/updateStatus?ids="+that.giveUpParamsIds+"&type="+that.giveUpParamsType).then(res=> {
          if(res.data.code == 200){
            that.getTimeSlotDetail()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //删除子列表
      delUse(id){
        let that = this,ids = []
        ids.push(id)
        that.giveUpParamsIds = String(ids)
        that.giveUpParamsType = 1
        $http.axios.get("/api/yyb/modifyPrice/updateStatus?ids="+that.giveUpParamsIds+"&type="+that.giveUpParamsType).then(res=> {
          if(res.data.code == 200){
            that.getTimeSlotDetail()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //选中的选项
      chooseTableFn(visible){
        let that = this,ids = [],status=''
        let sendttSelection = that.$refs.multipleTable.selection
        sendttSelection.forEach(function(e,i){
          ids.push(e.id)
          status = e.status
        })
        that.giveUpParamsIds = String(ids)
        if(sendttSelection.length == 0){
          that.$message({
            message: '请勾选要删除的调价广告组',
            type: 'warning'
          });
          that[visible]=false
        }else if(status != 5){
          that.$message({
            message: '请勾选执行状态为弃用的行',
            type: 'warning'
          })
          that[visible]=false
        }else{
          that[visible]=true
        }
      },

      //批量删除
      allDelete(){
        this.chooseTableFn('dialogVisible')
      },

      //取消删除
      delCancel(){
        this.dialogVisible = false
      },

      //确定全部删除
      delSure(){
        let that = this 
        that.giveUpParamsIds = String(ids)
        that.giveUpParamsType = 1
        $http.axios.get("/api/yyb/modifyPrice/updateStatus?ids="+that.giveUpParamsIds+"&type="+that.giveUpParamsType).then(res=> {
          if(res.data.code == 200){
            that.getTimeSlotDetail()
            that.dialogVisible = false
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        //获取数据接口（调用封装的接口）
        this.priceModifyList()
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .priceTimes{
      color:cornflowerblue;
      cursor: pointer;
    }
    .pageWrap{
      clear:both;
      margin-top:15px;
      font-size:0;
    }
    .pageBatch,.paging{
        display:inline-block;
        vertical-align:middle;
        width:50%;
        font-size:12px;
    }
    .pageBatch{
      /* float:left; */
    }
    .paging{
      text-align:right;
    }
    .showTheForm,.hideenTheForm{
    display:inline-block;
    vertical-align:top;
  }
  .showTheForm{
    position:relative;
  }
  .showTheForm i{
    position:absolute;
    bottom:-4px;
    left:50%;
    margin-left:-11px;
    font-size:22px;
    color:rgb(36, 132, 168);
    cursor:pointer;
  }
  .hideenTheForm i{
    font-size:22px;
    vertical-align:middle;
    color:rgb(36, 132, 168);
    cursor:pointer;
    margin:0 20px;
  }
  .sonList{
    display:inline-block;
    vertical-align:middle;
    margin-right:10px;
  }
  /* 新版 */
  .modifyTab{
    padding:0;
    margin:0;
    font-size:0;
    margin-bottom:20px;
    margin-top:20px;
    border-bottom:1px solid #F5F7F6;
  }
  .modifyTab li {
    display:inline-block;
    vertical-align:middle;
    width:80px;
    font-size:14px;
    text-align:center;
    cursor: pointer;
    background:#F5F7F6;
    color:#2F674D;
    padding:3px 8px;
    border-radius: 8px 8px 0px 0px;
    margin-right:10px;
    font-weight:500;
  }
  .modifyTab .modifyActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .channelFlowHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  </style>
