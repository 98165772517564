/**
 * 针对 localStorage/sessionStorage 的操作
 */
export default {
  local: {
    getItem(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
    clear() {
      localStorage.clear();
    }
  },
  session: {
    getItem(key) {
      return JSON.parse(sessionStorage.getItem(key));
    },
    setItem(key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      sessionStorage.removeItem(key);
    },
    clear() {
      sessionStorage.clear();
    }
  }
};
