<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/auth-management/HomePage' }">首页管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/auth-management/userList' }">用户列表</el-breadcrumb-item>
                <el-breadcrumb-item>用户详情</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>  

          <div class="userList materialHead">
            <h3 class="hLIne">
              <span class="line"></span>
              <span class="role_headerChecked role_headerChecked_user">用户详情</span>
            </h3>
            <div class="userform">
              <el-form :inline="true" :model="ruleForm" ref="ruleForm" class="demo-form-inline">
                <el-form-item label="" prop="qdvalue">
                  <el-select v-model="ruleForm.qdvalue" filterable clearable placeholder="选择客户名称" @change="changeProduct($event)">
                    <el-option
                      v-for="(item,i) in qdoptions"
                      :key="i"
                      :label="item.productName"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="yhvalue">
                  <el-select v-model="ruleForm.yhvalue" filterable clearable placeholder="选择账户名称" @change="changeAccountTop($event)">
                    <el-option
                      v-for="item in yhoptions"
                      :key="item.value"
                      :label="item.name"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" class="newQuery" @click="queryUserList">查询</el-button>
                  <el-button type="info" size="small" @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="userTable userTable_detail">
              <el-dialog title="分配产品" :visible.sync="dialogFormVisible">
                <el-form :model="product_form">
                  <el-form-item label="公司名称" :label-width="formLabelWidth">
                    <el-input v-model="compony_name" autocomplete="off" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="账户ID" :label-width="formLabelWidth">
                    <el-input v-model="acc_ID" autocomplete="off" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="分配优化师" :label-width="formLabelWidth">
                    <el-select v-model="fpName" filterable placeholder="请选择分配优化师" @change="changeUserId($event)">
                      <el-option
                        v-for="(item,i) in distributionOptions"
                        :key="i"
                        :label="item.string"
                        :value="item">
                        <!-- {{item.depyName}} -->
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="客户名称" :label-width="formLabelWidth">
                    <el-input v-model="productTitle" autocomplete="off" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="项目" :label-width="formLabelWidth">
                    <el-input v-model="projectName" autocomplete="off" :disabled="true"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false">取 消</el-button>
                  <el-button type="primary" @click="sureChange">确 定</el-button>
                </div>
              </el-dialog>
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="accountname"
                  label="公司名称"
                  width="240">
                  <template slot-scope="scope">{{scope.row.company}}</template>
                </el-table-column>
                <el-table-column
                  prop="accountname"
                  label="账户名称"
                  width="240">
                  <template slot-scope="scope">{{scope.row.accountName}}</template>
                </el-table-column>
                <el-table-column
                  prop="channelName"
                  label="渠道名称">
                  <template slot-scope="scope">{{scope.row.channel}}</template>
                </el-table-column>
                <el-table-column
                  prop="accountId"
                  width="100"
                  label="账户ID">
                  <template slot-scope="scope">{{scope.row.accountId}}</template>
                </el-table-column>
                <el-table-column
                  prop="productName"
                  width="240"
                  label="客户名称">
                  <template slot-scope="scope">{{scope.row.prduct}}</template>
                </el-table-column>
                <el-table-column
                  prop="productName"
                  label="项目"
                 >
                  <template slot-scope="scope">{{scope.row.project}}</template>
                </el-table-column>
                <el-table-column
                  prop="distributionDate"
                  label="分配日期"
                  width="240"
                 >
                  <template slot-scope="scope">{{scope.row.date}}</template>
                </el-table-column>
                <!-- <el-table-column
                  prop="userOperation"
                  label="操作"
                  show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-button size="mini" plain class="newBtn_green" @click="changeAccount(scope.row)">变更</el-button>
                      <el-button size="mini" plain class="newBtn_darkPink" style="margin-left:2px;" @click="userModify(scope.row)" v-if="scope.row.type==0">添加</el-button>
                      <el-button size="mini" plain class="newBtn_lightYellow" style="margin-left:2px;" @click="userModify(scope.row)" v-else>编辑</el-button>
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deleteUser(scope.row.accountId)"
                      >
                      <template #reference>
                        <el-button plain size="mini" slot="reference" class="newBtn_orang">删除</el-button>
                        </template>
                      </el-popconfirm>
                  </template>
                </el-table-column> -->
              </el-table>
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
                </el-pagination>
              </div>
            </div>
          </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
      <accessDialog :visible.sync="showDialog" ></accessDialog>
    </div>
  </template>
  
  <script>
  import accessDialog from '@/views/dialog/accountDialog';
  import echarts from  "echarts";
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'authority',
    components: {
      accessDialog
    },
    data() {
      return {
        showDialog: false,
        qdoptions:[],
        yhoptions:[],
        ruleForm:{
          qdvalue:'',
          yhvalue:'',
        },
        sjvalue:'',
        tableData: [],
        multipleSelection:[],
        dialogFormVisible: false,
        product_form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        detailId:'',
        userIdDetail:'',
        compony_name:'',
        acc_ID:'',
        fpName:'',
        productTitle:'',
        projectName:'',
        distributionOptions:[],
        changeParams:{
          id:'',
          dept:'',
          deptName:'',
          userId:''
        },
        prductQuery:'',
        accountIdQuery:'',






      }
    },
    computed: {
       
    },
    mounted() {
      this.getId()
      this.getUserDetailList()
      this.getProduct()
      this.getAccountList()
    },
    // created() {
    //   var params = {
    //     pageNum: this.pageNum,
    //     pageSize: this.pageSize
    //   }
    //   this.findAll(params)
    // },
    methods: {
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
        // this.getUserDetailList()
        this.$router.go(0)
      },

      getId(){
        console.log(222)
        this.detailId = this.$route.params && this.$route.params.userId
        // this.detailId = this.$route.query && this.$route.query.userId
        console.log(this.detailId)
        this.userIdDetail = String(this.detailId)
        console.log(this.userIdDetail)
        // this.userdetailParams.userId = String(this.detailId)
        // console.log(this.userdetailParams.userId)
      },

      //获取用户详情的列表
      getUserDetailList(){
        let that = this
        $http.axios.get("/api/channel/accountUser/infoList?pageNum="+that.pageNum+"&pageSize="+that.pageSize+"&userId="+that.userIdDetail).then(function(response) {
          if(response.data.code == 200){
            // console.log(response)
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取账户名称下拉列表
      getAccountList(){
        let that = this
        that.accountUserId = that.detailId
        $http.axios.get("/api/channel/accountUser/selectList?userId="+that.accountUserId).then(function(response) {
          that.yhoptions = response.data
        })
      },

      //获取分配产品弹框的分配优化师id
      changeUserId(event){
        let that = this
        console.log(event)
        that.changeParams.userId = String(event.userId)
        that.changeParams.dept = String(event.deptId)
        that.changeParams.deptName = String(event.depyName)
        that.fpName = event.string
      },

      //获取分配人员列表
      getFpList(){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
          if(response.data.code == 200){
            that.distributionOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //变更
      changeAccount(row){
        let that = this
        that.fpName = '' //清空
        that.dialogFormVisible = true
        that.getFpList()
        console.log(row)
        that.compony_name = row.accountName
        that.acc_ID = row.accountId
        that.productTitle = row.prduct
        that.projectName = row.project
        that.changeParams.id = String(row.accountId)
        // that.changeParams.id = "71754670438"
        $http.axios.get("/api/channel/accountUser/"+row.accountId).then(function(response) {
          if(response.data.code == 200){
            console.log(response)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //确定变更
      sureChange(){
        let that = this
        $http.axios.put("/api/channel/account",that.changeParams).then(function(response) {
          if(response.data.code == 200){
            that.dialogFormVisible = false
            that.getUserDetailList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //删除用户
      deleteUser(e){
        let that = this
        $http.axios.get("/api/channel/accountUser/delAccountUser?accountId="+e).then(function(response) {
          console.log(response)
          that.getUserDetailList()
        })
      },

       //客户名称下拉框
       getProduct(){
        let that = this
        $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
          if(response.data.code == 200){
            that.qdoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取查询客户名称详情
      changeProduct(event){
        this.ruleForm.qdvalue = event.productName
        // this.prductQuery = event.productName
        this.prductQuery = event.id
      },

      //获取查询账户名称详情
      changeAccountTop(event){
        this.ruleForm.yhvalue = event.name
        // this.accountIdQuery = event.name
        this.accountIdQuery = event.id
        console.log(typeof this.accountIdQuery)
      },

      //查询
      queryUserList(){
        let that = this
        that.userIdDetail = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/channel/accountUser/infoList?pageNum="+that.pageNum+"&pageSize="+that.pageSize+"&userId="+that.userIdDetail+"&product="+that.prductQuery+"&accountId="+that.accountIdQuery).then(function(response) {
          if(response.data.code == 200){
            that.tableData = []
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },


      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      //跳转到编辑页面
      userModify(e){
        console.log(e)
        if(e.channel == '今日头条'){
          this.$router.push({path:'/auth-management/userModify/'+e.accountId})
        }else{
          this.$router.push({path:'/auth-management/userModifyOppo/'+e.accountId})
        }
      },
      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        this.getUserDetailList()
      },
    }
  }
  </script>
  
  <style lang="scss">
    /* .userform .el-input{
      display:inline-block;
      margin:0!important;
      margin-right:1%;
    } */
    .userform .el-select{
      margin-right:10px;
    }
    .userTable{
      margin-top:0!important;
    }
    .userTable .el-table .cell{
      text-align:center;
    }
    .el-table th{
      /* background:rgba(200, 200, 169, 0.5)!important; */
      color:#1b1f23;
      background:$poc-color-primary-deputy;
    }
    .footer_paging{
      margin:20px 0 4px;
    }
    .footer_totalNums{
      font-size:12px;
      color:#666;
      padding-left:5px;
      padding-right:20px;
    }
    .footerPaging {
      float:right;
    }
    .userTable_detail .el-form-item{
      display:block;
    }
    .userTable_detail .el-select{
      width:100%;
    }
     /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
      width:160px;
      height:32px;
      line-height:32px;
      background:#F5F7F6;
      border:none;
    }
    .hLIne{
      border-bottom:1px solid #F1F7F5;
      padding-bottom:15px;
    }
    .role_headerChecked,.line{
      display: inline-block;
      vertical-align: middle;
    }
    .line{
      width:3px;
      height:20px;
      background:#48916F;
    }
    .role_headerChecked{
      font-size:14px;
      font-weight:500;
      color:#333;
      margin-left:13px;
    }
  </style>
