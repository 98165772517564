<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title=""
  >
  <el-form :inline="true" :model="timeForm" ref="timeForm" class="demo-form-inline">
    <el-form-item label="" prop="tan1">
        <el-select v-model="timeForm.tan1" placeholder="请选择执行状态" filterable clearable>
            <el-option
              v-for="(item,i) in implementOptions"
              :key="i"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
    </el-form-item>
    <el-form-item label="" prop="tan2">
        <el-date-picker
            v-model="timeForm.tan2"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
    </el-form-item>
    <el-form-item label="" prop="tan5">
      <el-select v-model="timeForm.tan5" placeholder="请选择调价状态" filterable clearable>
        <el-option
          v-for="(item,i) in tan5Options"
          :key="i"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" class="newQuery" size="small" @click="getTimeSlotDetail">查询</el-button>
      <el-button type="primary" size="small" @click="resetForm('timeForm')">重置</el-button>
    </el-form-item>
</el-form> 
<el-table :data="gridData" ref="multipleTable">
  <el-table-column
    type="selection"
    width="50">
  </el-table-column>
  <el-table-column
    property="priceDate"
    label="调价日期"
    width="150">
    <template slot-scope="scope">
      {{ scope.row.startTime | dateFormat }}至{{ scope.row.endTime | dateFormat }}
    </template>
  </el-table-column>
  <el-table-column
    property="priceDateSlot"
    label="调价时间段"
    width="150">
    <template slot-scope="scope">
      <div v-for="(time,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
        <span>{{time.executeHourt}}</span>
      </div>
    </template>
  </el-table-column>
  <el-table-column
    property="priceStatus"
    label="当天是否继续执行"
    width="100">
    <template slot-scope="scope">
      <div v-for="(item,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
        <span v-if="item.status == 0" style="display: block;">执行</span>
        <span v-if="item.status == 1" style="display: block;">不执行</span>
      </div>
    </template>
  </el-table-column>
  <el-table-column
    property="priceStatus"
    label="目标金额"
    width="100">
    <template slot-scope="scope">
      <div v-for="(item,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
        <span style="display: block;">{{item.newPrice/100}}</span>
      </div>
    </template>
  </el-table-column>
  <el-table-column
    property="priceStatus"
    label="调价状态"
    width="150">
    <template slot-scope="scope">
      <span v-if="scope.row.upPriceStatus == 0">开启</span>
      <span v-if="scope.row.upPriceStatus == 1">关闭</span>
    </template>
  </el-table-column>
  
  <el-table-column
    property="implementStatus"
    label="执行状态"
    >
    <template slot-scope="scope">
      <span v-if="scope.row.executionStatus == 10">待执行</span>
      <span v-if="scope.row.executionStatus == 20">执行中</span>
      <span v-if="scope.row.executionStatus == 30">已执行</span>
      <span v-if="scope.row.executionStatus == 40">异常</span>
      <span v-if="scope.row.executionStatus == 50">弃用</span>
    </template>
  </el-table-column>
  <!-- <el-table-column
    property="operation"
    label="操作">
    <template slot-scope="scope">
      <div class="opBtn">
        <span v-if="scope.row.executionStatus == 10">
        </span>
        <span v-else></span>
      </div>
      <div class="opBtn">
        <span v-if="scope.row.executionStatus == 50 "></span>
        <span v-else>
          <el-popconfirm
          title="确定要弃用吗？"
          @confirm="stopUse(scope.row.id)">
          <template #reference>
            <el-button class="newBtn_garyWhite" plain size="mini" style="margin-left:10px;">弃用</el-button>
          </template>
        </el-popconfirm>
        </span>
      </div>
      <div class="opBtn">
        <span  v-if="scope.row.executionStatus == 50">
          <el-popconfirm
            title="您是否确认删除，删除后不可恢复？"
            @confirm="delUse(scope.row.id)">
            <template #reference>
              <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
            </template>
          </el-popconfirm>
        </span>
      </div>
    </template>
  </el-table-column> -->
</el-table>
    <!-- <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('formData')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';
export default {
  
  name: "startStopDialog",

  props: {
    pubVue:Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      timeForm:{
        tan1:'',
        tan2:'',
        tan3:'',
        tan4:'',
        tan5:'',
        tan6:'',
        tan7:'',
        tan8:'',
        tan9:''
      },//时间段弹框查询form
      tan5Options:[//子列表弹框中的调价状态
        {
          name:'开启中',
          id:'0'
        },{
          name:'关闭中',
          id:'1'
        }
      ],

      implementOptions:[],
      gridData: [],//时间段表格数据
      timeSlotParams:{//时间段详情列表入参
          adGroupId:'',//列表中的广告组id
          id:'',//每一条的id
          currentPage:'',
          pageSize:'',
          userId:'',
          userName:'',
          executionStatus:'',
          stime:'',
          etime:'',
          mathType:'',
          mathStart:'',
          mathEnd:'',
          upPriceStatus:'',
          priceType:'',
          upPriceType:'',
          upPriceMode:'',
          planType:''
        },
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    
  },
  methods: {
   
    onConfirm(formName) {
      let _this = this
     },
    onCancel() {
      this.show= false;
    },
    addOrSetFn(type){
      
    },


    // {
    //   "adGroupId": 0,
    //   "currentPage": 0,
    //   "deptId": 0,
    //   "etime": "",
    //   "executionStatus": 0,
    //   "id": 0,
    //   "mathEnd": 0,
    //   "mathStart": 0,
    //   "mathType": 0,
    //   "ownerId": 0,
    //   "pageIndex": 0,
    //   "pageSize": 0,
    //   "planId": 0,
    //   "planType": 0,
    //   "priceType": 0,
    //   "stime": "",
    //   "upPriceMode": 0,
    //   "upPriceStatus": 0,
    //   "upPriceType": 0,
    //   "userId": 0,
    //   "userName": ""
    // }

 //获取时间段的详情列表
 getTimeSlotDetail(planId,planType,userContent){
      let that = this
      that.timeSlotParams.currentPage = that.pageNumdetail
      that.timeSlotParams.pageSize = that.pageSizedetail
      that.timeSlotParams.ownerId = userContent.ownerId
      that.timeSlotParams.userId = userContent.userId
      that.timeSlotParams.userName = userContent.userName
      that.timeSlotParams.planId =planId
      that.timeSlotParams.planType =planType

      that.timeSlotParams.executionStatus = that.timeForm.tan1
      that.timeSlotParams.stime = that.timeForm.tan2[0]
      that.timeSlotParams.etime = that.timeForm.tan2[1]
      that.timeSlotParams.upPriceStatus = that.timeForm.tan5
      that.timeSlotParams.priceType = that.timeForm.tan6
      that.timeSlotParams.upPriceType = that.timeForm.tan7
      that.timeSlotParams.upPriceMode = that.timeForm.tan8
      console.log(123)

      $http.axios.post("/api/advertisement/adGroup/v1/getGroupModifyPriceSetDetailList",that.timeSlotParams).then(res=> {
      // $http.axios.post("/aji/adGroup/v1/getGroupModifyPriceSetDetailList",that.timeSlotParams).then(res=> {
        if(res.data.code == 200){
          that.gridData = res.data.content.data
          that.totalRowdetail = res.data.content.total
          console.log(456)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
   
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
</style>

