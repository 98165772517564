<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item>计划管理</el-breadcrumb-item>
                <el-breadcrumb-item @click.native="jumpPage" class="jumpPageStyle">调价展示</el-breadcrumb-item>
                <el-breadcrumb-item>调价设置</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>  
            <div class="setPrice">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <div v-if="planType == 30">
                      <el-form-item label="客户名称" prop="customerName">
                        <el-input v-model="ruleForm.customerName" placeholder=""></el-input>
                      </el-form-item>
                      <el-form-item label="计划名称" prop="planName">
                          <el-input v-model="ruleForm.planName" placeholder=""></el-input>
                      </el-form-item>
                      <el-form-item label="广告组名称" prop="advertName">
                          <el-input v-model="ruleForm.advertName" placeholder=""></el-input>
                      </el-form-item>
                    </div>
                    <div v-if="planType == 10">
                      <el-form-item label="客户名称" prop="customerName">
                        <el-input v-model="ruleForm.customerName" placeholder=""></el-input>
                      </el-form-item>
                    </div>
                    <div v-if="planType == 20">
                      <el-form-item label="客户名称" prop="customerName">
                        <el-input v-model="ruleForm.customerName" placeholder=""></el-input>
                      </el-form-item>
                      <el-form-item label="计划名称" prop="planName">
                        <el-input v-model="ruleForm.planName" placeholder=""></el-input>
                      </el-form-item>
                    </div>
                    
                    <span class="baseTitle">调价设置</span>
                    <el-form-item label="调价状态" prop="priceStatus">
                        <el-select v-model="ruleForm.priceStatus" placeholder="请选择调价状态" filterable @change="chengePriceStatus($event)">
                            <el-option
                              v-for="(item,i) in priceStatusOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="调价类型" prop="priceType">
                        <el-select v-model="ruleForm.priceType" placeholder="请选择调价类型" filterable @change="changePriceType($event)">
                            <el-option
                              v-for="(item,i) in priceTypeOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="调价方式" prop="priceMode">
                        <el-select v-model="ruleForm.priceMode" placeholder="请选择调价方式" v-if="changePtype==2" filterable @change="changePriceMode($event)">
                            <el-option
                              v-for="(item,i) in priceModeOptions"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                          <el-select v-model="ruleForm.priceMode" placeholder="请选择调价方式" v-else filterable @change="changePriceMode($event)">
                            <el-option
                              v-for="(item,i) in priceModeOptions2"
                              :key="i"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="修改金额" prop="editPrice" :rules="changePmode == '3'?numberlimitM:numberlimit">
                        <el-input v-model="ruleForm.editPrice" placeholder="请输入调价金额或者百分比" @blur="blurPrice"></el-input>
                    </el-form-item>
                    <el-form-item label="修改最终金额" prop="" v-if="showLastPrice">
                      <span>{{lastPrice}}</span>
                    </el-form-item>
                    <el-form-item label="出价上限" prop="priceTop">
                        <el-input v-model="ruleForm.priceTop" placeholder="请输入出价上限值"></el-input>
                    </el-form-item>
                    <el-form-item label="出价下限" prop="priceBotoom">
                      <el-input v-model="ruleForm.priceBotoom" placeholder="请输入出价下限值"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="出价下限" prop="priceBotoom">
                        <el-input v-model="ruleForm.priceBotoom" placeholder="请输入出价下限值"></el-input>
                    </el-form-item> -->
                    <el-form-item label="执行方式" >
                        <el-form-item label="" prop="dateChecked">
                          <el-checkbox v-model="ruleForm.dateChecked" @change="dateSet">设置执行日期</el-checkbox>
                          <div class="setSureDate" v-show="setDatevalueShow">{{setDatevalueString}}</div>   
                        </el-form-item>
                        <el-form-item label="" prop="timeChecked">
                          <el-checkbox v-model="ruleForm.timeChecked" @change="setCarryTime">设置执行时间</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
                          </el-form-item>
                      </el-form-item>
                    <el-form-item>
                      <el-button type="primary" class="newDialogCancel" size="small" @click="cancleCarryTime">取消保存</el-button>
                      <el-button type="primary" class="newDialog" size="small" @click="submitForm('ruleForm')">确认保存</el-button>
                    </el-form-item>
                </el-form>
                <!-- 设置执行日期弹框 -->
                <el-dialog 
                  title="执行日期"
                  center
                  :show-close = "false"
                  class="setPriceDialog"
                  width="400px"
                  :visible.sync="setDateVisible">
                    <el-date-picker
                        v-model="setDatevalue"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <div slot="footer" class="dialog-footer">
                      <el-button type="primary" class="newDialogCancel" @click="setDateCancel">取消保存</el-button>
                      <el-button type="primary" class="newDialog" @click="setDateCommit">确认保存</el-button>
                    </div>
                </el-dialog>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import TimeUnit from '../../TimeUnit.vue';

  export default {
    name: 'SetPrice',
    components:{
      timeUnit:TimeUnit
    },
    data() {
      return {
        userId:'',
        userName:'',
        deptId:'',
        ruleForm: {
            customerName: '',//要从账户列表带过来客户名称
            planName:'',
            advertName:'',
            priceStatus:'',
            priceType:'',
            priceMode:'',
            editPrice:'',
            priceTop:'',
            priceBotoom:'',
            executionMode:'',
            dateChecked:'',
            timeChecked:''
        },
        rules: {
            priceStatus:[
                { required: true, message: '请选择调价状态', trigger: 'change' }
            ],
            priceType:[
                { required: true, message: '请选择调价类型', trigger: 'change' }
            ],
            priceMode:[
                { required: true, message: '请选择调价方式', trigger: 'change' }
            ],
            editPrice:[
                { required: true, message: '请输入调价金额或者百分比', trigger: 'blur' }
            ],
            priceTop:[
                { required: true, message: '请输入出价上限值', trigger: 'blur' } 
            ],
            priceBotoom:[
                { required: true, message: '请输入出价下限值', trigger: 'blur' }
            ],
            // executionMode:[
            //     { required: true, message: '请选择执行方式', trigger: 'change' }
            // ],
            dateChecked:[
                { required: true, message: '请设置执行日期', trigger: 'change' }
            ],
            timeChecked:[
                { required: true, message: '请设置执行时间', trigger: 'change' }
            ]
        },
        priceStatusOptions:[//调价状态
            {
                name:'开启中',
                id:0
            },
            {
                name:'暂停中',
                id:1
            }
        ],
        priceTypeOptions:[//调价类型
            {
                name:'具体值调价',
                id:1
            },
            {
                name:'百分比调价',
                id:2
            }
        ],
        priceModeOptions:[//调价方式
            {
                name:'统一上调',
                id:1
            },
            {
                name:'统一下调',
                id:2
            }
        ],
        priceModeOptions2:[
            {
                name:'自定义',
                id:3
            }
        ],
        executionModeOptions:[//执行方式
            {
                name:'立即执行',
                id:1
            },
            {
                name:'设置执行日期',
                id:2
            }
        ],
        checked:false,//是否选中设置时间
        modeTime:false,//设置时间的显示隐藏
        setDateVisible:false,//设置执行日期
        setDatevalueString:'',//执行日期的显示
        setDatevalue:[],//执行日期
        setDatevalueShow:false,//执行日期是否显示
        timeUnitShow:false,//设置执行时间
        planName:'',//上一级带过来的计划名称
        adGroupName:'',//上一级带过来的广告组名称
        addParams:[
            {
                adGroupId:'',
                adGroupName:'',
                addGroupModifyPriceSetDetailDtoList: [
                    {
                        executeHourt:'',
                        week:''
                    }
                ],
                deptId:'',
                newPrice:'',
                oldPrice:'',
                ownerId:'',
                ownerName:'',
                planId:'',
                planName:'',
                priceDown:'',
                priceUp:'',
                upPrice:'',
                upPriceMode:'',
                upPriceStatus:'',
                upPriceType:'',
                userId:'',
                userName:'',
                startTime:'',
                endTime:''
            }
        ],
        changePtype:'',//调价类型
        changePmode:'',//调价方式
        oldPrice:'',//广告组列表带过来的原始出价
        ownerId:'',
        ownerName:'',
        planType:'',
        lastPrice:'',//最终金额
        showLastPrice:false,
        numberlimit: { validator: this.checkMoney, trigger: 'blur'},
        numberlimitM: { validator: this.checkMoneyM, trigger: 'blur' },
        
        // numberlimitUp: { validator: this.checkMoneyUp, trigger: 'blur'},
        // numberlimitUps: { validator: this.checkMoneyUpM, trigger: 'blur' },
      }
    },
    computed: {     
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.ownerName = this.$route.query.name
      this.planType = this.$route.query.planType
      this.oldPrice = this.$route.query.oldPrice/100
      console.log(this.oldPrice)
      this.ruleForm.customerName = this.ownerName
      this.getId()
    },
    methods: {
      //跳转到调价展示页面
      jumpPage(){
        this.$router.push('/plan-management/PriceModify?id='+this.ownerId+'&name='+this.ownerName)
      },
        //调价状态
        chengePriceStatus(event){
          // this.showLastPrice = false
          // this.ruleForm.editPrice = ''
          this.addParams[0].upPriceStatus = event
        },
        //调价类型
        changePriceType(event){
          this.showLastPrice = false
          this.ruleForm.editPrice = ''
          this.addParams[0].upPriceType = event
          this.changePtype = event
        },
        //调价方式
        changePriceMode(event){
          this.showLastPrice = false
          this.ruleForm.editPrice = ''
          this.addParams[0].upPriceMode = event
          this.changePmode = event
          console.log(this.changePmode)
        },
        //修改金额
        blurPrice(){
          console.log(this.changePmode)
          
          if(this.changePmode == 3){
            this.addParams[0].newPrice = Number(this.ruleForm.editPrice)*100
            this.lastPrice = Number(this.ruleForm.editPrice)
          } else if (this.changePmode == 1){
            this.addParams[0].newPrice = Number(this.oldPrice+(this.oldPrice*(this.ruleForm.editPrice/100)))*100
            this.lastPrice = Number(this.oldPrice+(this.oldPrice*(this.ruleForm.editPrice/100)))
          } else {
            this.addParams[0].newPrice = Number(this.oldPrice-(this.oldPrice*(this.ruleForm.editPrice/100)))*100
            this.lastPrice = Number(this.oldPrice-(this.oldPrice*(this.ruleForm.editPrice/100)))
          }
        },

        checkMoney (rule, value, callback)  {
          if (!value) {
            return callback(new Error('不能为空'))
          }
          const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
          setTimeout(() => {
            if (!mailReg.test(value)) {
              callback(new Error('请输入数字值'))
            } else {
              if (value > 100) {
                callback(new Error('不能超过100'))
                this.showLastPrice = false
                this.ruleForm.editPrice = ''
              } else {
                callback()
                this.showLastPrice = true
              }
            }
          }, 1000)
        },
        checkMoneyM (rule, value, callback)  {
          if (!value) {    
            return callback(new Error('不能为空'))
          }else{
            if(value<200){
              return callback(new Error('不能小于200'))
              this.showLastPrice = false
              this.ruleForm.editPrice = ''
            }
          }
          const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
          setTimeout(() => {
            if (!mailReg.test(value)) {
              callback(new Error('请输入数字值'))
            } else {
              callback()
              this.showLastPrice = true
            }
          }, 1000)
        },

        // checkMoneyUp (rule, value, callback)  {
        //   if (!value) {
        //     return callback(new Error('不能为空'))
        //   }
        //   const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
        //   setTimeout(() => {
        //     if (!mailReg.test(value)) {
        //       callback(new Error('请输入数字值'))
        //     } else {
        //       if (value > 100) {
        //         callback(new Error('不能超过100'))
        //         this.ruleForm.priceTop = ''
        //       } else {
        //         callback()
        //       }
        //     }
        //   }, 1000)
        // },

        // checkMoneyUpM(rule, value, callback)  {
        //   if (!value) {    
        //     return callback(new Error('不能为空'))
        //   }else{
        //     if(value<200){
        //       return callback(new Error('不能小于200'))
        //       this.showLastPrice = false
        //       this.ruleForm.editPrice = ''
        //     }
        //   }
        //   const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
        //   setTimeout(() => {
        //     if (!mailReg.test(value)) {
        //       callback(new Error('请输入数字值'))
        //     } else {
        //       callback()
        //       this.showLastPrice = true
        //     }
        //   }, 1000)
        // },

      //保存
      submitForm(ruleForm) {
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            let that = this
            that.addParams[0].deptId = that.deptId
            that.addParams[0].userId = that.userId
            that.addParams[0].userName = that.userName
            that.addParams[0].adGroupName = that.adGroupName
            that.addParams[0].planName = that.planName
            that.addParams[0].ownerId = that.ownerId
            that.addParams[0].ownerName = that.ownerName
            that.addParams[0].priceDown = parseInt(Number(that.ruleForm.priceBotoom)*100)
            that.addParams[0].priceUp = Number(that.ruleForm.priceTop)*100
            that.addParams[0].oldPrice = Number(that.oldPrice)*100 || 0
            that.addParams[0].startTime = that.setDatevalue[0]
            that.addParams[0].endTime = that.setDatevalue[1]
            that.addParams[0].upPrice = Number(that.ruleForm.editPrice)*100
            that.addParams[0].planType = that.planType
            
            let timeString = that.$refs.timeStrArray.timeStr
            console.log(that.$refs)
            that.addParams[0].addGroupModifyPriceSetDetailDtoList = []
            timeString.forEach(function(e,index){
              console.log(index)
              if(e!=''){
                that.addParams[0].addGroupModifyPriceSetDetailDtoList.push(
                  {executeHourt:e,week:index+1}
                )
              }
            })
            $http.axios.post("/api/advertisement/adGroup/v1/addGroupModifyPriceSet",that.addParams).then(res=> {
            // $http.axios.post("/aji/adGroup/v1/addGroupModifyPriceSet",that.addParams).then(res=> {
                if(res.data.code == 200){
                    that.$router.go(-1);
                }  else {
                    that.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: 'error'
                    })
                }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      //取消
      cancleCarryTime(){
        this.$router.go(-1);
      },

      //上一页带过来的参数
      getId(){
        this.planName = this.$route.query.planName
        this.adGroupName = this.$route.query.adGroupName
        // this.ruleForm.customerName = this.ownerName//'ominiapi子客户' //要从账户列表带过来客户名称
        this.ruleForm.planName = this.planName
        this.ruleForm.advertName = this.adGroupName
        this.addParams[0].adGroupId = this.$route.query.adGroupId
        this.addParams[0].planId = this.$route.query.planId
      },

      //执行方式---设置时间
      secondMode(val){
        console.log(val)
        if(val == 2){
            this.modeTime = true
        }else{
            this.modeTime = false
        }
      },
      
      //设置执行时间显示弹框
      setCarryTime(){
        let that = this
        if(that.ruleForm.timeChecked == true){
          that.timeUnitShow = true
        }else{
          that.timeUnitShow = false
        }
      },

      //设置执行日期--取消
      setDateCancel(){
        this.setDateVisible = false
        this.ruleForm.dateChecked = false
      },
      //设置执行日期--确定
      setDateCommit(){
        if(this.setDatevalue == null||''){
          this.$message('请选择日期')
        }else{
          this.setDateVisible = false
          this.setDatevalueShow = true
          this.setDatevalueString = String(this.setDatevalue[0]+'至'+this.setDatevalue[1])
        } 
      },

      //设置执行日期
      dateSet(val){
        if(val==true){
          this.setDateVisible = true
          this.setDatevalue = []
        }else{
          this.setDateVisible = false
          this.setDatevalueString = ''
        }
      },

    }
  }
  </script>
  
  <style lang="scss" scoped>
      .baseTitle{
          font-weight:700;
          display:block;
          font-size:14px;
          margin:10px 0 20px;
      }
      .setPrice .el-input{
          width:260px;
      }
      .jumpPageStyle{
        font-weight:700!important;
        cursor: pointer!important;
      }
      .setPrice{
        margin-top:20px;
      }
      ::v-deep .setPriceDialog .el-dialog__body{
        margin:0!important;
      }
  </style>
