<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>计划管理</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>OPPO</el-breadcrumb-item>
          <el-breadcrumb-item>渠道</el-breadcrumb-item> -->
          <el-breadcrumb-item @click.native="jumpPage" class="jumpPageStyle">启停展示</el-breadcrumb-item>
          <el-breadcrumb-item>广告组启停展示</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="channelFlowHead">
                <el-form :inline="true" :model="priceForm" ref="priceForm" class="demo-form-inline">
                    <el-form-item label="" prop="planName">
                        <el-autocomplete
                            class="inline-input"
                            v-model="priceForm.planName"
                            value-key="adGroupName"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入广告组名称"
                            @select="handleSelect"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="startStopList">查询</el-button>
                        <el-button type="primary" size="small" plain @click="resetForm('priceForm')">重置</el-button>
                    </el-form-item>
                </el-form> 
            </div>
            <div class="channelFlowBody">
                <div class="channelTable">
                    <el-table
                      :data="tableData"
                      border
                      style="width: 100%">
                      <!-- <el-table-column
                      type="selection"
                      width="50">
                      </el-table-column> -->
                      <el-table-column
                        prop="planName"
                        label="广告组名称">
                        <template slot-scope="scope">{{ scope.row.adGroupName }}</template>
                      </el-table-column>
                      <el-table-column
                        prop="planId"
                        label="广告组ID"
                        width="200">
                        <template slot-scope="scope">{{ scope.row.adGroupId }}</template>
                      </el-table-column>
                      <el-table-column
                        prop="priceTime"
                        label="执行内容">
                        <template slot-scope="scope">
                          <span class="priceTimes" @click="timeSlot(scope.row.id)">{{ scope.row.planCount }}个时间计划</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="planId"
                        label="计划名称">
                        <template slot-scope="scope">{{ scope.row.planName }}</template>
                      </el-table-column>
                      <el-table-column
                        prop="operation"
                        label="操作">
                        <template slot-scope="scope">
                          <el-button class="newBtn_garyLightBlue" plain size="mini" @click="jumpAdvertisingGroup(scope.row)">创建</el-button>
                          <el-button class="newBtn_garyOrange" plain size="mini" @click="addPriceList">调价</el-button>
                          <el-button class="newBtn_green" plain size="mini">广告组</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- 时间段弹框 -->
                    <el-dialog title="" :visible.sync="dialogTableVisible" width="100%">
                        <el-form :inline="true" :model="timeForm" class="demo-form-inline">
                            <el-form-item label="" prop="">
                                <el-select v-model="implementStatus" placeholder="请选择执行状态" filterable clearable>
                                    <el-option
                                      v-for="(item,i) in implementOptions"
                                      :key="i"
                                      :label="item.name"
                                      :value="item.id">
                                    </el-option>
                                  </el-select>
                            </el-form-item>
                            <el-form-item label="" prop="">
                                <el-date-picker
                                    v-model="dateChoice"
                                    type="daterange"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form> 
                        <el-table 
                          :data="gridData"
                          ref="multipledetailTable">
                            <el-table-column
                              type="selection"
                              width="50">
                            </el-table-column>
                            <el-table-column
                              property="priceDate"
                              label="启停日期">
                              <template slot-scope="scope">
                                {{ scope.row.startTime | dateFormat }}至{{ scope.row.endTime | dateFormat }}
                              </template>
                            </el-table-column>
                            <el-table-column
                              property="priceDateSlot"
                              label="启停时间段">
                              <template slot-scope="scope">
                                <div v-for="(time,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
                                  <span>
                                    <strong v-if="time.week == 1">周一</strong>
                                    <strong v-if="time.week == 2">周二</strong>
                                    <strong v-if="time.week == 3">周三</strong>
                                    <strong v-if="time.week == 4">周四</strong>
                                    <strong v-if="time.week == 5">周五</strong>
                                    <strong v-if="time.week == 6">周六</strong>
                                    <strong v-if="time.week == 7">周日</strong>
                                  </span>
                                  <span>：{{time.executeHourt}}</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              property="implementStatus"
                              label="执行状态">
                              <template slot-scope="scope">
                                <span v-if="scope.row.status == 10">待执行</span>
                                <span v-else-if="scope.row.status == 20">执行中</span>
                                <span v-else-if="scope.row.status == 30">已执行</span>
                                <span v-else-if="scope.row.status == 40">异常</span>
                                <span v-else-if="scope.row.status == 50">弃用</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              property="implementStatus"
                              label="今日是否执行">
                              <template slot-scope="scope">
                                <div v-for="(item,i) in scope.row.getOpenClosePlanWeekDetailDtoList" :key="i">
                                  <span v-if="item.status == 0">是</span>
                                  <span v-else>否</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              property="implementStatus"
                              label="目标状态">
                              <template slot-scope="scope">
                                <span v-if="scope.row.targetStatus == 0">开启</span>
                                <span v-else>关闭</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              property="operation"
                              label="操作">
                              <template slot-scope="scope">
                                <div class="opBtn">
                                  <span v-if="scope.row.status == 10">
                                    <!-- <el-button class="newBtn_Yellow" size="mini" plain @click="editPrice(scope.row.id)">修改</el-button> -->
                                  </span>
                                  <span v-else></span>
                                </div>
                                <div class="opBtn">
                                  <span v-if="scope.row.status == 10 || scope.row.status == 20">
                                    <el-popconfirm
                                    title="确定要弃用吗？"
                                    @confirm="stopUse(scope.row.id)">
                                    <template #reference>
                                      <el-button class="newBtn_garyWhite" plain size="mini" style="margin-left:10px;">弃用</el-button>
                                    </template>
                                  </el-popconfirm>
                                  </span>
                                  <span v-else></span>
                                </div>
                                <div class="opBtn">
                                  <span  v-if="scope.row.status == 50">
                                    <el-popconfirm
                                      title="您是否确认删除，删除后不可恢复？"
                                      @confirm="delUse(scope.row.id)">
                                      <template #reference>
                                        <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                                      </template>
                                    </el-popconfirm>
                                  </span>
                                </div>
                              </template>
                            </el-table-column>
                        </el-table>
                        <div class="pageWrap">
                            <!-- <div class="pageBatch">
                              <el-select v-model="batchOperation" placeholder="批量操作" style="width:95px;">
                                <el-option value="全部删除"></el-option>
                              </el-select>
                            </div> -->
                            <div class="pageBatch">
                              <el-button @click="allTimeDelete" size="small">批量删除</el-button>
                            </div>
                            <el-dialog
                              title=""
                              :visible.sync="timeVisible"
                              width="30%"
                              :modal-append-to-body='false'>
                              <span>确定要删除吗</span>
                              <span slot="footer" class="dialog-footer">
                                <el-button @click="delTimeCancel">取 消</el-button>
                                <el-button type="primary" @click="delTimeSure">确 定</el-button>
                              </span>
                            </el-dialog>
                            <div class="paging">
                              <el-pagination
                              @size-change="handleSizeChange"
                              @current-change="handleCurrentChange"
                              :current-page="detailpageNum"
                              :page-sizes="[10, 20, 30, 40]"
                              :page-size="detailpageSize"
                              layout="total, sizes, prev, pager, next, jumper"
                              :total="this.detailtotalRow">
                            </el-pagination>
                            </div>
                          </div>
                    </el-dialog>
                    <!-- 时间段弹框结束 -->
                </div>
                <div class="pageWrap">
                    <div class="pageBatch">
                      <!-- <el-select v-model="batchOperations" placeholder="批量操作" style="width:95px;">
                        <el-option value="全部删除"></el-option>
                      </el-select> -->
                    </div>
                    <div class="paging">
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.totalRow">
                      </el-pagination>
                    </div>
                </div>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'priceModify',
    data() {
      return {
        userId:'',
        userName:'',
        deptId:'',
        priceForm:{
            planName:'',//搜索计划名称
        },
        tableData: [],
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        detailpageNum: 1,//当前页
        detailpageSize: 10,//每页大小
        detailtotalRow: 0,//总条数
        dialogTableVisible:false,//时间段弹框
        gridData: [],//时间段表格数据
        timeForm:{},//时间段弹框查询form
        implementStatus:'',//时间段弹框执行状态
        implementOptions:[{//时间段弹框执行状态选项
            name:'全部',
            id:1
        },{
            name:'待执行',
            id:2
        },{
            name:'已执行',
            id:3
        },{
            name:'执行失败',
            id:4
        },{
            name:'计划暂停',
            id:5
        },{
            name:'广告组暂停',
            id:6
        },{
            name:'日预算不足',
            id:7
        }],
        dateChoice:'',
        batchOperation:'',
        batchOperations:'',
        ssParams:{//启停列表入参
          userName:'',
          userId:'',
          deptId:'',
          currentPage:'',
          pageSize:'',
          ownerId:'',//上一级带过来的id
          objIdType:'',
          planId:'',
          name:'',//搜索条件，非必传
        },
        timeVisible:false,
        stopParams:{//弃用入参
          deptId:'',
          userId:'',
          userName:'',
          id:''
        },
        delParams:{//批量删除
          timeIdList:[],
          userId:''
        },
        ssDetailParams:{//启停页面点击执行内容，详情入参
          currentPage:'',
          deptId:'',
          openClosePlanId:'',
          pageSize:'',
          userId:'',
          userName:'',
          etime:'',
          stime:'',
          status:''
        },
        ownerId:'',
        ownerName:''


      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.planId = this.$route.query.adplanId
      this.ssParams.ownerId = this.$route.query.ownerId
      this.ownerId = this.$route.query.ownerId
      this.ownerName = this.$route.query.ownerName
      console.log(this.ssParams.ownerId)
      this.startStopList()
    },
    methods: {

      //跳转到启停展示页面
      jumpPage(){
        this.$router.push('/plan-management/StartStop?id='+this.ownerId+'&name='+this.ownerName)
      },
      //头部表单重置
      resetForm(priceForm) {
        this.$refs[priceForm].resetFields();
        this.$router.go(0)
      },

      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
            return (restaurant.adGroupName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      handleSelect(item) {
        console.log(item);
      },

      // //时间段点击显示弹框
      // timeSlot(){
      //   this.dialogTableVisible = true
      // },

      //点击广告组跳转页面
      jumpAdvertisingGroup(row){
        console.log(row)
        // this.$router.push('/plan-management/SetAdgroupPrice')
        this.$router.push({name:'planManagement.SetAdgroupPrice',query:{planId:row.planId,planName:row.planName,id:row.ownerId,name:row.ownerName,adGroupId:row.adGroupId,adGroupName:row.adGroupName}})
      },

      //点击添加按钮跳转到调价设置页面
      addPriceList(){
        this.$router.push('/plan-management/SetPrice')
      },

      //点击修改按钮跳转到调价修改页面
      editPrice(){
        this.$router.push('/plan-management/EditPrice')
      },

      //广告组启停展示列表
      startStopList(){
        let that = this
        that.ssParams.userName = that.userName
        that.ssParams.userId = that.userId
        that.ssParams.deptId = that.deptId
        that.ssParams.currentPage = that.pageNum
        that.ssParams.pageSize = that.pageSize
        // that.ssParams.ownerId = 12345   //上一级带过来的id
        that.ssParams.objIdType = 20    //先默认传10，没有了原来的tab切换
        that.ssParams.planId = that.planId
        that.ssParams.name = that.priceForm.planName

        $http.axios.post("/api/advertisement/adPlan/v1/getOpenClosePlanList",that.ssParams).then(res=> {
          if(res.data.code == 200){
            console.log(res)
            that.tableData = res.data.content.data
            that.totalRow = res.data.content.total
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //时间段点击显示弹框--详情
      timeSlot(id){
        this.dialogTableVisible = true
        this.ssDetailParams.openClosePlanId = id
        this.timeSlotDetail()
        this.listId = id
        console.log(this.listId)
      },

      //点击时间段详情接口
      timeSlotDetail(){
        let that = this
        that.ssDetailParams.currentPage = that.detailpageNum
        that.ssDetailParams.deptId = that.deptId
        that.ssDetailParams.pageSize = that.detailpageSize
        that.ssDetailParams.userId = that.userId
        that.ssDetailParams.userName = that.userName
        
        $http.axios.post("/api/advertisement/adPlan/v1/getOpenClosePlanDetailList",that.ssDetailParams).then(res=> {
          if(res.data.code == 200){
            that.gridData = res.data.content.data
            that.detailtotalRow = res.data.content.total
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //弃用
      stopUse(id){
        let that = this
        that.stopParams.deptId = that.deptId
        that.stopParams.userId = that.userId
        that.stopParams.userName = that.userName
        that.stopParams.id = id
        $http.axios.post("/api/advertisement/adPlan/v1/discardOpenClosePlan",that.stopParams).then(res=> {
          if(res.data.code == 200){
            // that.timeSlot()
            that.timeSlotDetail()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //执行内容弹框中的单个删除
      delUse(i){
        let that = this 
        that.delParams.userId = that.userId
        that.delParams.timeIdList.push(i)
        $http.axios.post("/api/advertisement/adPlan/v1/deleteOpenCloseTimePlan",that.delParams).then(res=> {
          if(res.data.code == 200){
            that.timeSlotDetail()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //选中的选项
      chooseTableFn(visible){
        let that = this
        // let sendttSelection = that.$refs.multipleTable.selection
        let sendttSelection = that.$refs.multipledetailTable.selection
        sendttSelection.forEach(function(e,i){
          console.log(e)
          that.delParams.timeIdList.push(e.id)
        })
        if(sendttSelection.length == 0){
          that.$message({
            message: '请勾选要删除的模板',
            type: 'warning'
          });
          that[visible]=false
        }else{
          that[visible]=true
        }
      },

      //执行内容中的批量删除
      allTimeDelete(){
        this.chooseTableFn('timeVisible')
      },

       //执行内容中的批量删除--取消删除
       delTimeCancel(){
        this.timeVisible = false
        this.$router.go(0)
      },

      //执行内容中的批量删除--确定全部删除
      delTimeSure(){
        let that = this 
        that.delParams.userId = that.userId
        $http.axios.post("/api/advertisement/adPlan/v1/deleteOpenCloseTimePlan",that.delParams).then(res=> {
          if(res.data.code == 200){
            that.timeSlotDetail()
            that.timeVisible = false
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },




      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        //获取数据接口（调用封装的接口）
        this.startStopList()
        this.timeSlotDetail()
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .priceTimes{
      color:cornflowerblue;
      cursor: pointer;
    }
    .pageWrap{
      clear:both;
      margin-top:15px;
      font-size:0;
    }
    .pageBatch,.paging{
        display:inline-block;
        vertical-align:middle;
        width:50%;
        font-size:12px;
    }
    .pageBatch{
      /* float:left; */
    }
    .paging{
      text-align:right;
    }
  </style>
